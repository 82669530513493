import { AxiosInstance } from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { Accordion, Divider, Message, Table } from "semantic-ui-react";
import FormattedNumberTooltip from "../../components/FormattedNumberTooltip";
import { useAuth0TokenOptions } from "../../data";
import { ModelStatus } from "../../types/model/modelStatus";
import { createV1APIServer } from "../../utils/api";
import { SEMANTIC_GREY } from "../../utils/colors";
import { getConfigOutputFeatureNames, modelStatusDisabledCriteria } from "../util";
import { getStaticViz, VizType } from "./util";

function EvalOverallStatsViewer(props: { target: string; vizTestStatistics?: VizTestStatistics }) {
    return (
        <Accordion
            styled
            fluid
            className={"black-accordion"}
            style={{ backgroundColor: "rgba(0, 0, 0, 0.03)" }}
            panels={[
                {
                    key: `${props.target}-overall`,
                    title: `${props.target}: Overall Stats`,
                    content: {
                        content: (
                            <div style={{ overflowX: "auto" }}>
                                <Table basic={"very"} collapsing compact selectable>
                                    <Table.Body>
                                        {Object.entries(
                                            props.vizTestStatistics?.[props.target]?.overall_stats ||
                                                props.vizTestStatistics?.[props.target] ||
                                                {},
                                        ).map((metric) => {
                                            return (
                                                <Table.Row>
                                                    <Table.Cell style={{ borderTop: "none", color: SEMANTIC_GREY }}>
                                                        {metric[0]}
                                                    </Table.Cell>
                                                    <Table.Cell style={{ borderTop: "none" }}>
                                                        <FormattedNumberTooltip value={metric[1]} />
                                                    </Table.Cell>
                                                </Table.Row>
                                            );
                                        })}
                                    </Table.Body>
                                </Table>
                            </div>
                        ),
                    },
                },
            ]}
        />
    );
}

function EvalPerClassViewer(props: { target: string; vizTestStatistics?: VizTestStatistics }) {
    const allClasses = useMemo(
        () => Array.from(new Set(Object.keys(props?.vizTestStatistics?.[props.target]?.per_class_stats || {}))),
        [props.vizTestStatistics],
    );
    const allMetrics = useMemo(
        () =>
            Array.from(
                new Set(
                    Object.values(props?.vizTestStatistics?.[props.target]?.per_class_stats || {}).flatMap((data) => {
                        return Object.keys(data);
                    }),
                ),
            ),
        [props.vizTestStatistics],
    );

    if (allClasses.length === 0) {
        return null;
    }

    return (
        <Accordion
            styled
            fluid
            className={"black-accordion"}
            style={{ backgroundColor: "rgba(0, 0, 0, 0.03)" }}
            panels={[
                {
                    key: `${props.target}-per-class`,
                    title: `${props.target}: Per Class Stats`,
                    content: {
                        content: (
                            <div style={{ overflowX: "auto" }}>
                                <Table
                                    style={{ background: "rgba(248, 247, 247)", border: "none" }}
                                    collapsing
                                    compact
                                    selectable
                                    className={"model-eval-stats-freeze"}
                                >
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.Cell style={{ borderTop: "none", color: SEMANTIC_GREY }}>
                                                <b>Class</b>
                                            </Table.Cell>
                                            {allClasses.map((mclass) => (
                                                <Table.Cell key={`overall-${mclass}`} style={{ borderTop: "none" }}>
                                                    <b>{mclass}</b>
                                                </Table.Cell>
                                            ))}
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {allMetrics.map((metric) => {
                                            return (
                                                <Table.Row key={`per-stats-${metric}`}>
                                                    <Table.Cell
                                                        style={{
                                                            borderTop: "none",
                                                            color: SEMANTIC_GREY,
                                                        }}
                                                    >
                                                        {metric}
                                                    </Table.Cell>
                                                    {allClasses.map((mclass) => {
                                                        return (
                                                            <Table.Cell
                                                                style={{
                                                                    borderTop: "none",
                                                                    minWidth: `${200 / 14}rem`,
                                                                }}
                                                            >
                                                                <FormattedNumberTooltip
                                                                    value={
                                                                        props.vizTestStatistics?.[props.target]
                                                                            ?.per_class_stats?.[mclass]?.[metric]
                                                                    }
                                                                />
                                                            </Table.Cell>
                                                        );
                                                    })}
                                                </Table.Row>
                                            );
                                        })}
                                    </Table.Body>
                                </Table>
                            </div>
                        ),
                    },
                },
            ]}
        ></Accordion>
    );
}

function EvalStatsViewer(props: { model: Model; errorMessage?: string }) {
    const [links, setLinks] = useState<VizLink[]>();
    const [vizTestStatistics, setVizTestStatistics] = useState<VizTestStatistics>();
    const [errorMessage, setErrorMessage] = useState<string | null>(props.errorMessage || null);

    const statusDisabledMsg = modelStatusDisabledCriteria(
        props.model,
        ModelStatus.EVALUATING,
        "all metrics cannot be displayed.",
    );
    const outputFeatures = getConfigOutputFeatureNames(props.model.config);

    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    const [apiServer, setAPIServer] = useState<AxiosInstance | null>(null);
    useEffect(() => {
        const getAPIServer = async () => {
            const v1APIServer = await createV1APIServer(auth0TokenOptions);
            // NOTE: Whoever wrote the axios typings is a moron because the return type of axios.create is not
            // AxiosInstance -- it's a wrap function. And React will see that and treat it as a callback that
            // setState should directly call. FML.
            // See: [1], [2]:
            // [1]: https://github.com/axios/axios/issues/4365
            // [2]: https://stackoverflow.com/questions/64427195/calling-setstate-will-execute-the-function-value-instead-of-passing-it
            setAPIServer(() => v1APIServer);
        };
        getAPIServer();
    }, []);

    useEffect(() => {
        if (!statusDisabledMsg && !props.errorMessage) {
            getStaticViz(
                apiServer,
                setLinks,
                setErrorMessage,
                props.model,
                VizType.EVALUATION_STATISTICS,
                undefined,
                undefined,
                setVizTestStatistics,
            );
        }
    }, [props.model]);

    if (statusDisabledMsg) {
        return <Message info content={statusDisabledMsg} />;
    }

    if (errorMessage) {
        return <Message negative>{errorMessage}</Message>;
    }

    if (vizTestStatistics) {
        return (
            <>
                {outputFeatures.map((target) => {
                    return (
                        <React.Fragment key={target}>
                            <EvalOverallStatsViewer target={target} vizTestStatistics={vizTestStatistics} />
                            <Divider hidden />
                            <EvalPerClassViewer target={target} vizTestStatistics={vizTestStatistics} />
                            <Divider hidden />
                        </React.Fragment>
                    );
                })}
            </>
        );
    } else if (links && links.length === 0) {
        return <Message negative>No evaluation statistics found for model.</Message>;
    }
    return null;
}

export default EvalStatsViewer;
