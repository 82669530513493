import React, { useContext } from "react";
import { useRecoilState } from "recoil";
import { Button, Card, Message, Modal } from "semantic-ui-react";

import metrics from "../../metrics/metrics";
import { USER_STATE } from "../../state/global";
import ConnectorCard from "./ConnectorCard";
import { Connector } from "./util";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InactiveSubscriptionMessage from "../../components/InactiveSubscriptionMessage";
import { track } from "../../metrics/june";
import { getDocsHome } from "../../utils/api";
import { FeatureFlagsContext } from "../../utils/feature-flags";
import { getComputeSubscriptionStatus } from "../../utils/subscription";
import "./ConnectDataModal.css";

function ConnectorCardPanel(props: {
    onClick: (connector: string) => void;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    getBreadcrumb: () => React.ReactNode;
}) {
    const [userContext] = useRecoilState(USER_STATE);
    const subscriptionDisabled = userContext?.isExpired;
    const [user] = useRecoilState(USER_STATE);
    const { featureFlags } = useContext(FeatureFlagsContext);

    return (
        <>
            <Modal.Header>
                Connect Data
                <br />
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingTop: "0.5rem",
                    }}
                >
                    {props.getBreadcrumb()}
                    {
                        // eslint-disable-next-line react/jsx-no-target-blank
                        <a
                            style={{ fontSize: "1rem" }}
                            onClick={() => {
                                user &&
                                    track(user, "docs", {
                                        url: getDocsHome() + "/user-guide/fine-tuning/prepare-data",
                                        clickSource: "connector-card-panel",
                                    });
                            }}
                            href={getDocsHome() + "/user-guide/fine-tuning/prepare-data"}
                            target="_blank"
                            rel="noopener"
                        >
                            What should my data look like? <FontAwesomeIcon icon="arrow-up-right-from-square" />
                        </a>
                    }
                </div>
                {getComputeSubscriptionStatus(userContext?.isComputeLimited, featureFlags["Free Trial Testing"]) && (
                    <Message className={"free-trial-warning"}>
                        <p className={"free-trial-warning"}>
                            <b>During the free trial, we officially support:</b>
                            <ul className={"free-trial-warning"}>
                                <li>Datasets up to 1GB</li>
                                <li>All feature types except audio and timeseries</li>
                            </ul>
                            <br />
                            If you'd like to train on larger datasets, reach out to <b>support@predibase.com</b>.
                        </p>
                    </Message>
                )}
                {subscriptionDisabled && (
                    <InactiveSubscriptionMessage
                        content={"You may no longer connect data."}
                        fontSize={`${14 / 14}rem`}
                    />
                )}
            </Modal.Header>
            <Modal.Content>
                <Card.Group itemsPerRow={5}>
                    <ConnectorCard
                        header={"File"}
                        name={Connector.FILE}
                        onClick={props.onClick}
                        disabled={subscriptionDisabled}
                    />
                    {/* Bring back when we have updated our public datasets */}
                    {/* <ConnectorCard
                        header={"Public Datasets"}
                        name={Connector.PUBLIC_DATASETS}
                        onClick={props.onClick}
                        disabled={subscriptionDisabled}
                    /> */}
                    <ConnectorCard
                        header={"Amazon S3"}
                        name={Connector.S3}
                        onClick={props.onClick}
                        disabled={subscriptionDisabled}
                    />
                    <ConnectorCard
                        header={"BigQuery"}
                        name={Connector.BIGQUERY}
                        onClick={props.onClick}
                        disabled={subscriptionDisabled}
                    />
                    <ConnectorCard
                        header={"Snowflake"}
                        name={Connector.SNOWFLAKE}
                        onClick={props.onClick}
                        disabled={subscriptionDisabled}
                    />
                    <ConnectorCard
                        header={"Databricks"}
                        name={Connector.DATABRICKS}
                        onClick={props.onClick}
                        disabled={subscriptionDisabled}
                    />
                    {/* <ConnectorCard
                        header={"Azure Data Lake"}
                        name={Connector.ADLS}
                        onClick={props.onClick}
                        disabled={subscriptionDisabled}
                    />
                    <ConnectorCard
                        header={"Google Cloud Storage"}
                        name={Connector.GCS}
                        onClick={props.onClick}
                        disabled={subscriptionDisabled}
                    />

                    <ConnectorCard
                        header={"MySQL"}
                        name={Connector.MYSQL}
                        onClick={props.onClick}
                        disabled={subscriptionDisabled}
                    />
                    <ConnectorCard
                        header={"PostgreSQL"}
                        name={Connector.POSTGRESQL}
                        onClick={props.onClick}
                        disabled={subscriptionDisabled}
                    />
                    <ConnectorCard
                        header={"Redshift"}
                        name={Connector.REDSHIFT}
                        onClick={props.onClick}
                        disabled={subscriptionDisabled}
                    />

                    <ConnectorCard header="Dremio" name={Connector.DREMIO} onClick={props.onClick} disabled={true} />
                    <ConnectorCard
                        header="Apache Druid"
                        name={Connector.DRUID}
                        onClick={props.onClick}
                        disabled={true}
                    />
                    <ConnectorCard header="Vertica" name={Connector.VERTICA} onClick={props.onClick} disabled={true} />
                    <ConnectorCard header="Apache Hive" name={Connector.HIVE} onClick={props.onClick} disabled={true} /> */}
                    {/*<ConnectorCard header="Presto" name={Connector.PRESTO} onClick={props.onClick} disabled={true}/>*/}
                </Card.Group>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    className={metrics.BLOCK_AUTO_CAPTURE}
                    style={{ height: "95%" }}
                    onClick={() => {
                        props.setOpen(false);
                    }}
                >
                    Close
                </Button>
            </Modal.Actions>
        </>
    );
}

export default ConnectorCardPanel;
