import { User } from "@auth0/auth0-react";
import { useRecoilState } from "recoil";
import { Header, Table, TableBody, TableCell, TableRow } from "semantic-ui-react";
import { SESSION_STATE, USER_STATE } from "../state/global";
import { SEMANTIC_GREY } from "../utils/colors";
import { isKratosSession, isKratosUserContext } from "../utils/kratos";
import { formatSubscription } from "../utils/subscription";

const UserInfo = () => {
    const [session] = useRecoilState(SESSION_STATE);
    const [userContext] = useRecoilState(USER_STATE);

    let username = "";
    let email = "";
    let firstName = "";
    let lastName = "";
    let emailVerified = true;
    // let isKratosSess;
    let subscriptionTier;
    let shortCode;

    if (session) {
        const isKratosSess = isKratosSession(session);
        username = isKratosSess ? session?.identity?.traits?.username : (session as User)?.nickname;
        email = isKratosSess ? session?.identity?.traits?.email : (session as User)?.email;
        firstName = isKratosSess ? session?.identity?.traits?.name.first : (session as User)?.given_name;
        lastName = isKratosSess ? session?.identity?.traits?.name.last : (session as User)?.family_name;
        emailVerified = isKratosSess ? true : ((session as User)?.email_verified ?? false);
    }

    if (userContext) {
        const isKratosContext = isKratosUserContext(userContext);
        subscriptionTier = isKratosContext ? userContext.tenant.subscription.tier : userContext.tenant.tier;
        shortCode = isKratosContext ? userContext.tenant.shortCode : userContext.tenant.shortcode;
    }

    return (
        <>
            <Header as={"h2"} size={"medium"}>
                Overview
            </Header>
            <Table basic={"very"} collapsing compact>
                <TableBody>
                    <TableRow>
                        <TableCell
                            style={{
                                borderTop: "none",
                                color: SEMANTIC_GREY,
                                paddingRight: "40px",
                            }}
                        >
                            Username
                        </TableCell>
                        <TableCell style={{ borderTop: "none" }}>{username}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ borderTop: "none", color: SEMANTIC_GREY, paddingRight: "40px" }}>
                            Email Address
                        </TableCell>
                        <TableCell style={{ borderTop: "none" }}>
                            {email}
                            {/* TODO: Show user status of email verification when we start using it. */}
                            {/* {!isKratosSess && <Popup
                            trigger={<Icon name={emailVerified ? "check circle" : "times circle"} />}
                            content={emailVerified ? "Email is verified." : "Your email needs to be verified. Please check your inbox."}
                        />} */}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ borderTop: "none", color: SEMANTIC_GREY, paddingRight: "40px" }}>
                            First Name
                        </TableCell>
                        <TableCell style={{ borderTop: "none" }}>{firstName}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ borderTop: "none", color: SEMANTIC_GREY, paddingRight: "40px" }}>
                            Last Name
                        </TableCell>
                        <TableCell style={{ borderTop: "none" }}>{lastName}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ borderTop: "none", color: SEMANTIC_GREY, paddingRight: "40px" }}>
                            Team
                        </TableCell>
                        <TableCell style={{ borderTop: "none" }}>{userContext?.tenant?.name}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ borderTop: "none", color: SEMANTIC_GREY, paddingRight: "40px" }}>
                            Tenant ID
                        </TableCell>
                        <TableCell style={{ borderTop: "none" }}>{shortCode}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ borderTop: "none", color: SEMANTIC_GREY, paddingRight: "40px" }}>
                            Subscription
                        </TableCell>
                        <TableCell style={{ borderTop: "none" }}>{formatSubscription(subscriptionTier)}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </>
    );
};

export default UserInfo;
