import _ from "lodash";
import { modelStatusValueMap } from "./sort";

export enum Direction {
    ASCENDING = "ascending",
    DESCENDING = "descending",
}

// Have to declare this type in this file rather than d.ts because it uses an enum.
type ReducerState = {
    column: string;
    data: any[];
    direction: Direction;
};

const moveNullsToEnd = (data: any[], column: string, reverse?: boolean) => {
    let firstPart = data.filter((x: any) => x[column] != null);
    if (reverse) {
        firstPart = firstPart.reverse();
    }
    return firstPart.concat(data.filter((x: any) => x[column] == null));
};

const sortTableData = (data: any[], column: string) => {
    if (column === "status") {
        return _.sortBy(data, (x) => modelStatusValueMap[x[column]]);
    }
    return _.sortBy(data, column);
};

export const TableSortingReducer = (
    state: ReducerState,
    action: { type: string; column: string; newData?: any[] },
): ReducerState => {
    switch (action.type) {
        case "CHANGE_DATA":
            const column = state.column || action.column;
            const newData = moveNullsToEnd(
                sortTableData(action.newData!, column),
                column,
                state.direction === Direction.DESCENDING,
            );
            return {
                ...state,
                data: newData,
            };
        case "CHANGE_SORT":
            const reverseDirection =
                state.direction === Direction.ASCENDING ? Direction.DESCENDING : Direction.ASCENDING;
            if (state.column === action.column) {
                return {
                    ...state,
                    // Always move nulls/undefined to the end of the data.
                    data: moveNullsToEnd(state.data, state.column, true),
                    direction: reverseDirection,
                };
            }

            return {
                column: action.column,
                data: sortTableData(state.data, action.column),
                direction: Direction.ASCENDING,
            } as ReducerState;
        default:
            throw new Error();
    }
};
