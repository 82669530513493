export enum ModelStatus {
    QUEUED = "queued",
    PREPROCESSING = "preprocessing",
    TRAINING = "training",
    EVALUATING = "evaluating",
    EXPLAINING = "explaining",
    VISUALIZING = "visualizing",
    READY = "ready",
    DEPLOYING = "deploying",
    DEPLOYED = "deployed",
    UNDEPLOYING = "undeploying",
    STOPPING = "stopping",
    CANCELED = "canceled",
    FAILED = "failed",
}
