import { DeploymentStatus } from "../types/deployment/deploymentStatus";
import { ModelStatus } from "../types/model/modelStatus";
import { QueryStatus } from "../types/model/queryStatus";
import {
    SEMANTIC_BLUE,
    SEMANTIC_BLUE_ACTIVE,
    SEMANTIC_GREEN,
    SEMANTIC_GREEN_ACTIVE,
    SEMANTIC_GREY,
    SEMANTIC_OLIVE,
    SEMANTIC_OLIVE_ACTIVE,
    SEMANTIC_RED,
    SEMANTIC_RED_ACTIVE,
} from "../utils/colors";
import { capitalize } from "../utils/strings";
import Chip from "./Chip";

export const getStatusChipForModelsAndQueries = (
    status: ModelStatus | QueryStatus | DeploymentStatus,
    width?: string,
) => {
    let color = SEMANTIC_GREY;
    let textColor = undefined;
    let opacity = 0.1;
    switch (status) {
        case QueryStatus.RUNNING:
        case DeploymentStatus.INITIALIZING:
        case DeploymentStatus.UPDATING:
        case DeploymentStatus.TERMINATING:
            color = SEMANTIC_OLIVE;
            textColor = SEMANTIC_OLIVE_ACTIVE;
            break;
        case QueryStatus.COMPLETED:
            color = SEMANTIC_BLUE;
            textColor = SEMANTIC_BLUE_ACTIVE;
            break;
        case ModelStatus.DEPLOYED:
        case DeploymentStatus.ACTIVE:
            color = SEMANTIC_GREEN;
            textColor = SEMANTIC_GREEN_ACTIVE;
            break;
        case ModelStatus.FAILED:
        case DeploymentStatus.FAILED:
            color = SEMANTIC_RED;
            textColor = SEMANTIC_RED_ACTIVE;
            break;

        case ModelStatus.PREPROCESSING:
        case ModelStatus.TRAINING:
        case ModelStatus.EVALUATING:
        case ModelStatus.DEPLOYING:
        case ModelStatus.UNDEPLOYING:
        case DeploymentStatus.TERMINATING:
            color = SEMANTIC_OLIVE;
            textColor = SEMANTIC_OLIVE_ACTIVE;
            break;

        case ModelStatus.EXPLAINING:
        case ModelStatus.VISUALIZING:
        case ModelStatus.READY:
            color = SEMANTIC_BLUE;
            textColor = SEMANTIC_BLUE_ACTIVE;
            break;
        case ModelStatus.STOPPING:
        case ModelStatus.CANCELED:
        case DeploymentStatus.CANCELED:
        case DeploymentStatus.INACTIVE:
            opacity = 0.3;
            break;
    }
    return <Chip color={color} text={capitalize(status)} textColor={textColor} opacity={opacity} width={width} />;
};
