import React from "react";
import { Link } from "react-router-dom";
import { Icon, Image, Popup, Radio, Table } from "semantic-ui-react";
import LiveTimeAgo from "../../components/LiveTimeAgo";
import NoResultsTableRow from "../../components/NoResultsTableRow";
import metrics from "../../metrics/metrics";
import { getImageForConnector } from "../../utils/connectors";
import { capitalize } from "../../utils/strings";
import { connectionDisplayTitle, getDataStatusColor } from "../utils";

const getTableRow = (
    selectedConnectionID: number | undefined,
    handleConnectionChange: ((connectionID: number) => void) | undefined,
    connection: Connection,
    i: number,
    disabled = false,
) => {
    let firstCell = null;
    if (handleConnectionChange !== undefined) {
        firstCell = (
            <Radio
                name="connectionGroup"
                value={connection.id}
                checked={selectedConnectionID === connection.id}
                onChange={(e, { value }) => handleConnectionChange(value as number)}
            />
        );
    }

    return (
        <Table.Row key={connection.id}>
            {firstCell && <Table.Cell collapsing>{firstCell}</Table.Cell>}
            <Table.Cell collapsing>
                <Popup
                    className={"transition-scale"}
                    position={"top center"}
                    content={connection.type}
                    trigger={
                        <Image
                            src={getImageForConnector(connection.type as string)}
                            avatar
                            style={{ borderRadius: "0" }}
                        />
                    }
                />
            </Table.Cell>
            <Table.Cell>
                <Link
                    className={metrics.BLOCK_AUTO_CAPTURE}
                    onClick={() => {
                        metrics.capture("Data.ConnectionsTable.ConnectionDetails.click", {
                            connectionID: connection.id,
                            connectionName: connection.name,
                        });
                    }}
                    to={`/data/connections/${connection.id}`}
                >
                    {connectionDisplayTitle(connection.name)}
                </Link>
            </Table.Cell>
            <Table.Cell>{connection.user?.username || ""}</Table.Cell>
            <Table.Cell>
                <Icon name="circle" color={getDataStatusColor(connection.status)} /> {capitalize(connection.status)}
            </Table.Cell>
            <Table.Cell>
                <LiveTimeAgo fromDate={new Date(connection.created)} />
            </Table.Cell>
            <Table.Cell>
                <LiveTimeAgo fromDate={new Date(connection.updated)} />
            </Table.Cell>
        </Table.Row>
    );
};

function ConnectionsTable(props: {
    searchbar: React.ReactNode | null;
    scopeUserToggle: React.ReactNode;
    connections?: Connection[];
    deniedConnections?: Connection[];
    selectedConnectionID?: number;
    handleConnectionChange?: (connectionID: number) => void;
}) {
    return (
        <Table selectable>
            <Table.Header>
                <Table.Row>
                    {props.handleConnectionChange && (
                        <Table.HeaderCell collapsing textAlign={"center"}></Table.HeaderCell>
                    )}
                    <Table.HeaderCell collapsing textAlign={"center"}>
                        Type
                    </Table.HeaderCell>
                    <Table.HeaderCell>Connection&emsp;{props.searchbar}</Table.HeaderCell>
                    <Table.HeaderCell>Author&nbsp; {props.scopeUserToggle}</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>Created</Table.HeaderCell>
                    <Table.HeaderCell>Updated</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {props.connections != null && props.connections.length === 0 ? <NoResultsTableRow colSpan={7} /> : null}
                {(props.connections || []).map((connection: Connection, i: number) => {
                    return getTableRow(props.selectedConnectionID, props.handleConnectionChange, connection, i);
                })}
            </Table.Body>
        </Table>
    );
}

export default ConnectionsTable;
