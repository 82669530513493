import { useState } from "react";
import { Form } from "semantic-ui-react";
import { SideBySideField, SingularField, StackedFormGroup } from "../../../components/StackedForm";
import ConnectorPanel from "../ConnectorPanel";
import { Connector, CreateConnectionEndpoint } from "../util";
import { getAlreadyConnectedMessage } from "./util";

const nameFieldPlaceholder = (connectionType?: string) => {
    switch (connectionType) {
        case Connector.POSTGRESQL:
            return "postresql_connection";
        case Connector.MYSQL:
            return "mysql_connection";
        case Connector.REDSHIFT:
            return "redshift_connection";
        default:
            return "my_database_connection";
    }
};

const databaseNameFieldDescription = (connectionType?: string) => {
    switch (connectionType) {
        case Connector.POSTGRESQL:
            return "Database in PostgreSQL that contains the tables you'd like to use";
        case Connector.MYSQL:
            return "Database in MySQL that contains the tables you'd like to use";
        case Connector.REDSHIFT:
            return "Database in Redshift that contains the tables you'd like to use";
        default:
            return undefined;
    }
};

/**
 * Used for all connectors that follow [address, database, username, password] config
 * @param props
 * @constructor
 */
function DBConnector(props: DBConnectorProps) {
    const [connectionName, setConnectionName] = useState(
        props.connectorConfig.connectionName ? props.connectorConfig.connectionName : "",
    );

    const [address, setAddress] = useState(props.connectorConfig.address ? props.connectorConfig.address : "");
    const [database, setDatabase] = useState(props.connectorConfig.database ? props.connectorConfig.database : "");
    const [username, setUsername] = useState(props.connectorConfig.username ? props.connectorConfig.username : "");
    const [password, setPassword] = useState(props.connectorConfig.password ? props.connectorConfig.password : "");

    const alreadyConnected = props.connection !== undefined;

    const reset = () => {
        setAddress("");
        setDatabase("");
        setUsername("");
        setPassword("");
    };

    const getAddRequest = () => {
        // const name = database;
        const config = {
            address: address.trim(),
            database: database.trim(),
            username: username.trim(),
            password: password.trim(),
        };

        return {
            endpoint: CreateConnectionEndpoint,
            body: {
                connection: {
                    type: props.name,
                    name: connectionName.trim(),
                },
                secret: {
                    secret: config,
                },
            },
            headers: {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            },
        };
    };

    const getUpdateRequest = () => {
        // const name = database;
        const config = {
            address: address,
            database: database,
            username: username,
            password: password,
        };

        return {
            endpoint: "sources/update",
            body: {
                connection: {
                    id: props.connection!.id,
                    type: props.connection!.type,
                    name: connectionName,
                },
                secret: {
                    secret: config,
                },
            },
            headers: {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            },
        };
    };

    const isDisabled = () => {
        return !address || !database || !connectionName;
    };
    const getModalContent = () => {
        return (
            <>
                {getAlreadyConnectedMessage(alreadyConnected, props.isEditView, props.onStepChange)}
                <Form>
                    <SingularField
                        autoFocus={true}
                        disabled={alreadyConnected && !props.isEditView}
                        name="name"
                        label="Connection Name"
                        placeholder={nameFieldPlaceholder(props.name)}
                        description="Choose a name for your connection in Predibase"
                        value={connectionName}
                        setState={setConnectionName}
                    />
                    <StackedFormGroup>
                        <SideBySideField
                            disabled={alreadyConnected && !props.isEditView}
                            name="address"
                            label="Address"
                            placeholder="acme.rds.amazonaws.com:1234 OR 127.0.0.1:1234"
                            description="URL:port OR hostname:port of the instance"
                            value={address}
                            setState={setAddress}
                        />
                        <SideBySideField
                            disabled={alreadyConnected && !props.isEditView}
                            name="database"
                            label="Database Name"
                            placeholder="database"
                            description={databaseNameFieldDescription(props.name)}
                            value={database}
                            setState={setDatabase}
                        />
                    </StackedFormGroup>
                    <StackedFormGroup>
                        <SideBySideField
                            disabled={alreadyConnected && !props.isEditView}
                            name="username"
                            label="Username"
                            placeholder="predibase"
                            description="User to authenticate as"
                            value={username}
                            setState={setUsername}
                        />
                        <SideBySideField
                            disabled={alreadyConnected && !props.isEditView}
                            name="password"
                            label="Password"
                            password={true}
                            placeholder="********"
                            description="Password used to authenticate"
                            value={password}
                            setState={setPassword}
                        />
                    </StackedFormGroup>
                </Form>
            </>
        );
    };

    return (
        <ConnectorPanel
            isDisabled={isDisabled}
            getModalContent={getModalContent}
            getAddRequest={getAddRequest}
            getUpdateRequest={getUpdateRequest}
            reset={reset}
            {...props}
        />
    );
}

export default DBConnector;
