import { metricsRegex } from "./version/modelmetrics/util";

export const getMetricKeysFromRuns = (runs: ModelRun[]) => {
    return Array.from(new Set(runs.flatMap((x) => (x.data.metrics || []).map((metric) => metric.key))));
};

/**
 * Returns data subset-specific metrics, like for train, validation, and test.
 * @param metrics
 * @returns
 */
export const getSpecificMetricsFromRawMetrics = (metrics: RawModelMetrics | Partial<RawCompareModelMetrics>) => {
    const specificMetrics: SpecificModelMetrics = {};

    Object.entries(metrics).forEach((entry) => {
        const [metric_name, metric_value] = entry;
        const res = metricsRegex.exec(metric_name);
        if (res !== null) {
            const [, metricType, target, metricName] = res;
            if (specificMetrics[target] === undefined) {
                specificMetrics[target] = {};
            }
            if (specificMetrics[target][metricName] === undefined) {
                specificMetrics[target][metricName] = {} as Record<MetricType, number>;
            }

            specificMetrics[target][metricName][metricType as MetricType] = metric_value ?? null;
        }
    });
    return specificMetrics;
};

/**
 * Returns best-eval-related metrics from raw model metrics, like best_eval_metric_checkpoint_number.
 * @param metrics
 * @returns
 */
export const getBestModelMetricsFromRawMetrics = (metrics: RawModelMetrics | Partial<RawCompareModelMetrics>) => {
    const bestModelMetrics: BestModelMetrics = {};
    Object.entries(metrics).forEach((entry) => {
        const [metric_name, metric_value] = entry;
        if (metric_name.includes("best")) {
            bestModelMetrics[metric_name] = metric_value || null;
        }
    });
    return bestModelMetrics;
};
