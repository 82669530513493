/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum tier {
    FREE = 'free',
    PREMIUM = 'premium',
    ENTERPRISE_VPC = 'enterprise_vpc',
    ENTERPRISE_SAAS = 'enterprise_saas',
}
