import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useState } from "react";
import { Button, Checkbox } from "semantic-ui-react";

import { SEMANTIC_BLACK, SEMANTIC_WHITE } from "../utils/colors";
import { isNotProduction } from "../utils/environment";
import { FeatureFlagsContext, getFeatureFlags, setFeatureFlag } from "../utils/feature-flags";

const FeatureFlagsEditor = () => {
    const { featureFlags, setFeatureFlags } = useContext(FeatureFlagsContext);
    const [open, setOpen] = useState<boolean>(false);

    const handleOnOpenCloseClick = () => {
        setOpen((open) => !open);
        setFeatureFlags(getFeatureFlags());
    };

    const handleOnFeatureFlagToggle = (flagName: string, value: boolean) => {
        setFeatureFlag(flagName, !featureFlags[flagName]);
        setFeatureFlags(getFeatureFlags());
    };

    if (!isNotProduction()) {
        return null;
    }

    return (
        <div
            style={{
                position: "fixed",
                zIndex: "10000",
                bottom: "4px",
                right: "10px",
                background: SEMANTIC_WHITE,
                border: "1px solid rgba(34,36,38,.15)",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "4px",
                padding: "1rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
            }}
        >
            {!open && (
                <Button
                    onClick={handleOnOpenCloseClick}
                    aria-label={`${open ? "Close" : "Open"} Feature Flags Editor`}
                    style={{ padding: 0, background: "none", color: SEMANTIC_BLACK }}
                >
                    <FontAwesomeIcon icon={"flask-vial"} style={{ marginRight: "4px" }} />
                    Feature Flags
                </Button>
            )}
            {open && (
                <>
                    <Button
                        onClick={handleOnOpenCloseClick}
                        aria-label="Close Feature Flags Editor"
                        style={{
                            width: "35",
                            height: "35",
                            background: "none",
                            marginLeft: "auto",
                            marginBottom: "8px",
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={"xmark"} style={{ fontSize: "20px" }} />
                    </Button>
                    <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
                        {Object.keys(featureFlags).map((flagName) => (
                            <li key={flagName}>
                                <Checkbox
                                    checked={Boolean(featureFlags[flagName])}
                                    label={flagName}
                                    toggle
                                    onClick={(event, data) => {
                                        handleOnFeatureFlagToggle(flagName, !data.checked as boolean);
                                    }}
                                />
                            </li>
                        ))}
                    </ul>
                </>
            )}
        </div>
    );
};

export default FeatureFlagsEditor;
