import { Connector } from "../util";
import DBConnector from "./DBConnector";

function RedshiftConnector(props: ConnectorProps) {
    return (
        <DBConnector
            modalHeader="Connect to Redshift"
            header="Redshift"
            editModalHeader="Edit Redshift Connection"
            submitLabel="Connect"
            name={Connector.REDSHIFT}
            errorHeader="Error in Redshift connection"
            {...props}
        />
    );
}

export default RedshiftConnector;
