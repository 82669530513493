import { Connector } from "../util";
import DBConnector from "./DBConnector";

function PostgresConnector(props: ConnectorProps) {
    return (
        <DBConnector
            modalHeader="Connect to PostgreSQL"
            header="PostgreSQL"
            editModalHeader="Edit PostgreSQL Connection"
            submitLabel="Connect"
            name={Connector.POSTGRESQL}
            errorHeader="Error in PostgreSQL connection"
            {...props}
        />
    );
}

export default PostgresConnector;
