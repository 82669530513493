import { useQueryClient } from "@tanstack/react-query";
import Convert from "ansi-to-html";
import { useRef } from "react";
import { Button, Tab } from "semantic-ui-react";
import { deployment, deploymentType } from "../../../../api_generated";
import { SEMANTIC_GREY_DISABLED, SEMANTIC_WHITE } from "../../../../utils/colors";
import { GET_DEPLOYMENT_LOGS_QUERY_KEY, useDeploymentLogsWithTimestampQuery } from "../../../data/query";
import { TERMINAL_BLACK_BACKGROUND } from "../../../misc/utils";

const AnsiConvert = new Convert();

const formatDateTime = (timestamp: number) => {
    let date = new Date(timestamp);
    const dateString = date.toLocaleDateString("en-US", { month: "long", day: "numeric" });
    const timezoneString = date.toLocaleTimeString("en-US", { timeZoneName: "short" });
    return `${dateString} ${timezoneString}`;
};

const LogsStatusAndRefresh = (props: { deploymentUUID: string; logsUpdatedAt: number; loadingLogs: boolean }) => {
    const { deploymentUUID, logsUpdatedAt, loadingLogs } = props;

    // Query state:
    const queryClient = useQueryClient();

    return (
        <div style={{ padding: "5px 0 10px" }}>
            Last updated at {formatDateTime(logsUpdatedAt)}
            {loadingLogs ? (
                <>
                    <span style={{ padding: "0 10px", color: SEMANTIC_GREY_DISABLED }}>(Refresh)</span>
                    <div className="ui active inline tiny loader" />
                </>
            ) : (
                <button
                    className="button-reset"
                    style={{ padding: "0 10px" }}
                    onClick={() =>
                        queryClient.invalidateQueries({ queryKey: GET_DEPLOYMENT_LOGS_QUERY_KEY(deploymentUUID!) })
                    }
                >
                    (Refresh)
                </button>
            )}
        </div>
    );
};

const DeploymentLogsTab = (props: { deploymentUUID?: string; deployment?: deployment }) => {
    // Parent state:
    const { deploymentUUID, deployment } = props;

    // Refs:
    const bottomRef = useRef<HTMLDivElement>(null);
    const scrollToBottom = () => {
        bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    // Query state:
    const { data: logsData, isFetching: loadingLogs } = useDeploymentLogsWithTimestampQuery(deploymentUUID ?? "", {
        enabled: Boolean(deploymentUUID) && deployment?.type === deploymentType.DEDICATED,
        retry: (failureCount, error) => {
            const errorMessage = error?.message ?? (error as unknown as string);
            if (errorMessage === "record not found" || errorMessage?.includes("No logs found")) {
                return false;
            }
            return failureCount < 3;
        },
    });
    // Derived state:
    const logs = logsData?.data ?? [];
    const logsUpdatedAt = logsData?.timestamp ?? 0;

    return (
        <>
            <div style={{ display: "flex", padding: "0 0 5px" }}>
                <div style={{ flexGrow: "1" }}>
                    <LogsStatusAndRefresh
                        deploymentUUID={deploymentUUID ?? ""}
                        logsUpdatedAt={logsUpdatedAt}
                        loadingLogs={loadingLogs}
                    />
                </div>
                <Button
                    className="small"
                    onClick={() => {
                        scrollToBottom();
                    }}
                >
                    Scroll to bottom
                </Button>
            </div>
            <Tab.Pane
                key={"deploymentEvents"}
                as={"div"}
                style={{
                    minHeight: "40rem",
                    background: TERMINAL_BLACK_BACKGROUND,
                    paddingTop: "5px",
                    paddingBottom: "5px",
                }}
            >
                {Array.isArray(logs) && logs.length > 0 && (
                    <ul style={{ listStyleType: "none", padding: 0 }}>
                        {logs.map((log, _) => {
                            const logStr = log[1];
                            const convertedStr = AnsiConvert.toHtml(logStr);
                            if (logStr.length === 0) {
                                return <></>;
                            }
                            return (
                                <div style={{ padding: "0 20px", color: SEMANTIC_WHITE }}>
                                    <code>
                                        <span dangerouslySetInnerHTML={{ __html: convertedStr }}></span>
                                    </code>
                                </div>
                            );
                        })}
                    </ul>
                )}
            </Tab.Pane>
            <div ref={bottomRef}></div>
            <LogsStatusAndRefresh
                deploymentUUID={deploymentUUID ?? ""}
                logsUpdatedAt={logsUpdatedAt}
                loadingLogs={loadingLogs}
            />
        </>
    );
};

export default DeploymentLogsTab;
