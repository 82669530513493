import { AxiosInstance } from "axios";
import { useEffect, useMemo, useState } from "react";
import { Divider, Header, Loader, Message, Segment, Table } from "semantic-ui-react";
import TargetSelector from "../../components/TargetSelector";
import { useAuth0TokenOptions } from "../../data";
import { createV1APIServer } from "../../utils/api";
import { sliceIntoChunks } from "../../utils/arrays";
import { SEMANTIC_GREY } from "../../utils/colors";
import {
    calibrationPlotsDisabledCriteria,
    checkIfHyperoptEnabled,
    getConfigOutputFeatureNames,
    modelStatusDisabledCriteria,
} from "../util";
import { getStaticViz, VizType } from "./util";

const basicRegex = "calibration_1_vs_all_(.+).svg";
const distRegex = "calibration_1_vs_all_prediction_distribution_(.+).svg";

const BRIER = "brier";

function CalibrationPlotsViewer(props: { model: Model; plotsPerRow?: number; errorMessage?: string }) {
    const outputFeatures = getConfigOutputFeatureNames(props.model.config);

    const [links, setLinks] = useState<VizLink[]>();
    const [vizMetadata, setVizMetadata] = useState<VizTrainingMetadata>();
    const [errorMessage, setErrorMessage] = useState<string | null>(props.errorMessage || null);

    const [target, setTarget] = useState<string>(outputFeatures[0]);

    const statusDisabledMsg = modelStatusDisabledCriteria(props.model);
    const specificDisabledMsg = calibrationPlotsDisabledCriteria(props.model);

    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    const [apiServer, setAPIServer] = useState<AxiosInstance | null>(null);
    useEffect(() => {
        const getAPIServer = async () => {
            const v1APIServer = await createV1APIServer(auth0TokenOptions);
            // NOTE: Whoever wrote the axios typings is a moron because the return type of axios.create is not
            // AxiosInstance -- it's a wrap function. And React will see that and treat it as a callback that
            // setState should directly call. FML.
            // See: [1], [2]:
            // [1]: https://github.com/axios/axios/issues/4365
            // [2]: https://stackoverflow.com/questions/64427195/calling-setstate-will-execute-the-function-value-instead-of-passing-it
            setAPIServer(() => v1APIServer);
        };
        getAPIServer();
    }, []);

    useEffect(() => {
        if (!(statusDisabledMsg || specificDisabledMsg) && target && !props.errorMessage && apiServer) {
            getStaticViz(
                apiServer,
                setLinks,
                setErrorMessage,
                props.model,
                VizType.CALIBRATION_1_VS_ALL,
                target,
                setVizMetadata,
            );
        }
    }, [props.model, target, props.errorMessage, apiServer]);

    const groupedLinks: Record<string, string[]> = useMemo(() => {
        if (links) {
            const grouped: Record<string, string[]> = {};
            const addToGrouped = (key: string, link: VizLink) => {
                if (!grouped[key]) {
                    grouped[key] = [];
                }
                grouped[key].push(link.url);
            };

            links.forEach((link) => {
                const distMatch = link.name.match(distRegex);
                if (distMatch) {
                    addToGrouped(distMatch[1], link);
                } else {
                    const basicMatch = link.name.match(basicRegex);
                    if (basicMatch) {
                        addToGrouped(basicMatch[1], link);
                    } else {
                        addToGrouped("other", link);
                    }
                }
            });
            return grouped;
        }
        return {};
    }, [links]);

    const getClassFromIndex = (index: string) => {
        if (vizMetadata?.idx2str && !isNaN(Number(index)) && Number(index) < vizMetadata?.idx2str.length) {
            return vizMetadata?.idx2str[Number(index)];
        }
        return index;
    };

    const getFrequencyFromClass = (className: string) => vizMetadata?.str2freq?.[className] || null;

    if (statusDisabledMsg) {
        return <Message info content={statusDisabledMsg} />;
    } else if (specificDisabledMsg) {
        return <Message info content={specificDisabledMsg} />;
    }

    if (errorMessage) {
        return <Message negative>{errorMessage}</Message>;
    }

    if (!links) {
        return <Loader active />;
    }

    if (checkIfHyperoptEnabled(props.model.config)) {
        return <Message info>Calibration plots are not available for hyperopt models.</Message>;
    }

    if (links.length === 0) {
        return (
            <Message negative>
                No calibration plots found for model. This model was likely trained before this feature was added.
                <br />
                <br />
                To view, train a new version.
            </Message>
        );
    }

    const chunkSize = props.plotsPerRow || 4;
    const keys = sliceIntoChunks(
        Object.keys(groupedLinks).filter((x) => x !== BRIER),
        chunkSize,
    );
    return (
        <>
            {outputFeatures.length > 1 && (
                <TargetSelector outputFeatures={outputFeatures} target={target} setTarget={setTarget} />
            )}
            <Table basic={"very"}>
                <Table.Body>
                    {groupedLinks[BRIER] ? (
                        <Table.Row key={BRIER}>
                            <Table.Cell style={{ borderTop: "none" }} key={BRIER} colSpan={chunkSize}>
                                <Segment>
                                    <Header as={"h4"}>Brier Scores</Header>
                                    <Divider style={{ margin: "2px" }} />
                                    {groupedLinks[BRIER].map((url) => (
                                        <img
                                            key={url}
                                            src={url}
                                            alt={"Brier Scores"}
                                            style={{
                                                maxWidth: "100%",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => window.open(url, "_blank")}
                                        />
                                    ))}
                                </Segment>
                            </Table.Cell>
                        </Table.Row>
                    ) : null}
                    {keys.map((chunk, i) => {
                        return (
                            <Table.Row key={i}>
                                {chunk.map((key) => {
                                    const className = getClassFromIndex(key);
                                    return (
                                        <Table.Cell style={{ borderTop: "none" }} key={key}>
                                            <Segment>
                                                <Header as={"h4"}>
                                                    Class: {className}&ensp;
                                                    <span style={{ fontSize: "14px", color: SEMANTIC_GREY }}>
                                                        (Frequency: {getFrequencyFromClass(className)})
                                                    </span>
                                                </Header>
                                                <Divider style={{ margin: "2px" }} />
                                                {groupedLinks[key].map((url) => (
                                                    <img
                                                        key={url}
                                                        src={url}
                                                        alt={key}
                                                        style={{
                                                            maxWidth: "100%",
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => window.open(url, "_blank")}
                                                    />
                                                ))}
                                            </Segment>
                                        </Table.Cell>
                                    );
                                })}
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
        </>
    );
}

export default CalibrationPlotsViewer;
