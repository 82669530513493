import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
    Button,
    Divider,
    Dropdown,
    Header,
    Loader,
    Popup,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from "semantic-ui-react";
import urlJoin from "url-join";
import { finetuningJobStatus, role, tier } from "../../../api_generated";
import { BadLink } from "../../../components/BadLink/BadLink";
import CopyButton from "../../../components/CopyButton";
import { SubscriptionButton } from "../../../components/GlobalHeader/SubscriptionButtons";
import PromptInstructionsModal from "../../../deployments/views/modals/PromptInstructionsModal";
import metrics from "../../../metrics/metrics";
import { useUserRole } from "../../../settings/query";
import { USER_STATE } from "../../../state/global";
import { getClientEndpoint, getV2APIEndpoint } from "../../../utils/api";
import { SEMANTIC_BLUE, SEMANTIC_GREY, SEMANTIC_WHITE } from "../../../utils/colors";
import updateFavicon from "../../../utils/favicon";
import { isKratosUserContext } from "../../../utils/kratos";
import AdapterBreadcrumb from "../../AdapterBreadcrumb";
import AdapterStatusChip from "../../misc/AdapterStatusChip";
import JobStatusChip from "../../misc/JobStatusChip";
import { isTerminalJobStatus } from "../../misc/utils";
import {
    GET_FINETUNING_JOB_TIMELINE_QUERY_KEY,
    useAdapterRepoQuery,
    useAdapterVersionQuery,
    useDeploymentCompatibleWithAdapterVersion,
    useFinetuningJobTimelineQuery,
} from "../../query";
import { adapterTypes } from "../create/utils";
import CancelFinetuningJobModal from "../modals/CancelFinetuningJobModal";
import RetrainAdapterModal from "../modals/RetrainAdapterModal";
import AdapterSummary from "./AdapterSummary";
import AdapterVersionViewSubmenu from "./AdapterVersionViewSubmenu";

import "./AdapterVersionView.css";
import {formatAdapterType} from "../../../deployments/misc/utils";

const AdapterVersionView = () => {
    // Local state:
    const [promptInstructionsModalOpen, setPromptInstructionsModalOpen] = useState(false);
    const [retrainModalOpen, setRetrainModalOpen] = useState<boolean>(false);
    const [cancelTrainingJobModalOpen, setCancelTrainingJobModalOpen] = useState<boolean>(false);

    // Meta state:
    const match = useMatch("/adapters/repo/:repoUUID/version/:tag");
    const repoUUID = match?.params.repoUUID;
    const versionTag = Number(match?.params.tag);

    const navigate = useNavigate();

    // User state:
    const [userContext] = useRecoilState(USER_STATE);
    // Derived user state:
    let userTier: tier | undefined;
    if (userContext) {
        const isKratosContext = isKratosUserContext(userContext);
        userTier = isKratosContext ? userContext.tenant.subscription.tier : userContext?.tenant.tier;
    }
    const userIsInEnterpriseTier = userTier && [tier.ENTERPRISE_SAAS, tier.ENTERPRISE_VPC].includes(userTier);

    // Query state:
    const queryClient = useQueryClient();

    const userRole = useUserRole();

    const {
        data: adapterVersion,
        isFetching: adapterVersionIsFetching,
        error: getAdapterVersionQueryError,
    } = useAdapterVersionQuery(repoUUID!, versionTag, {
        enabled: repoUUID !== undefined && versionTag !== undefined && versionTag > 0,
        refetchInterval: (query) => {
            if (isTerminalJobStatus(query.state.data?.status)) {
                return false;
            }
            return 30 * 1000; // 30 seconds
        },
        refetchIntervalInBackground: true,
        refetchOnWindowFocus: false,
    });

    const {
        data: repo,
        isFetching: repoIsFetching,
        error: getReppQueryError,
    } = useAdapterRepoQuery(repoUUID ?? "", {
        enabled: repoUUID !== undefined,
        refetchOnWindowFocus: false,
    });

    const {
        data: finetuningJobTimeline,
        // TODO: Let's prefetch this data and actualy fetch it when needed in the component.
        // isFetching: finetuningJobTimelineIsFetching,
        // TODO: Let's show this as an error message instead of completely failing.
        // error: getFinetuningJobTimelineQueryError,
    } = useFinetuningJobTimelineQuery(adapterVersion?.finetuningJob?.uuid ?? "", {
        enabled:
            adapterVersion?.finetuningJob?.uuid !== undefined &&
            !isTerminalJobStatus(adapterVersion?.finetuningJob.status),
        refetchOnWindowFocus: false,
        refetchInterval: () => {
            if (isTerminalJobStatus(adapterVersion?.status)) {
                return false;
            }
            return 30 * 1000; // 30 seconds
        },
        refetchIntervalInBackground: true,
    });

    const compatibleDeployment = useDeploymentCompatibleWithAdapterVersion(adapterVersion) ?? undefined;

    // Keep timeline in sync with the adapter version query
    useEffect(() => {
        if (isTerminalJobStatus(adapterVersion?.status)) {
            queryClient.invalidateQueries({
                queryKey: GET_FINETUNING_JOB_TIMELINE_QUERY_KEY(adapterVersion?.finetuningJob?.uuid ?? ""),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adapterVersion]);

    // Update the favicon based on the adapter version status
    useEffect(() => {
        switch (adapterVersion?.status) {
            case finetuningJobStatus.ERRORED:
            case finetuningJobStatus.CANCELED:
            case finetuningJobStatus.STOPPING:
                updateFavicon("failed");
                break;
            case finetuningJobStatus.COMPLETED:
                updateFavicon("success");
                break;
            case finetuningJobStatus.PENDING:
            case finetuningJobStatus.QUEUED:
            case finetuningJobStatus.TRAINING:
                updateFavicon("processing");
                break;
        }
    }, [adapterVersion]);

    if (getAdapterVersionQueryError || getReppQueryError) {
        return <BadLink authenticated={true} />;
    }

    // Derived adapter state:
    const dataIsMissing =
        (adapterVersionIsFetching && adapterVersion === undefined) || (repoIsFetching && repo === undefined);
    const adapterName = `${repo?.name}/${adapterVersion?.tag}`;
    const isReady = Boolean(adapterVersion?.adapterPath);
    const isLegacyAdapter = adapterVersion?.legacyModelId !== undefined;
    const isSolarAdapter = adapterVersion?.baseModel.includes("solar-1-mini-chat");
    const isLoRAAdapter = (adapterVersion?.adapterType as adapterTypes) === "lora";

    return (
        <>
            {dataIsMissing && (
                <div className="loading-overlay">
                    <Loader active />
                </div>
            )}
            {adapterVersion && repoUUID && repo && (
                <div style={{ padding: "20px" }}>
                    <div className={"version-header"}>
                        <AdapterBreadcrumb
                            adapterRepo={repo}
                            adapterVersionTag={adapterVersion.tag}
                            archived={adapterVersion.archived}
                        />
                        <SubscriptionButton isExpired={userContext?.isExpired} currentTier={userTier} />
                    </div>

                    <Divider hidden />

                    <div className="adapter-header-layout">
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div style={{ display: "flex", alignItems: "center", marginBottom: "0.47rem" }}>
                                <AdapterStatusChip adapterPath={adapterVersion.adapterPath} />
                                <Header as="h2" style={{ margin: 0 }}>
                                    {adapterName}
                                </Header>
                                <CopyButton text={adapterName} />
                            </div>
                            <div style={{ display: "flex", alignItems: "center", color: SEMANTIC_GREY }}>
                                <span style={{ marginRight: "1.71rem" }}>{adapterVersion.baseModel}</span>
                                <span style={{ marginRight: "1.71rem" }}>
                                    {adapterVersion.finetuningJob ? (
                                        <>
                                            Training Status: <JobStatusChip status={adapterVersion.status} />
                                        </>
                                    ) : (
                                        <>Imported Adapter</>
                                    )}
                                </span>
                                {adapterVersion.baseVersionRepoName &&
                                    adapterVersion.baseVersionTag &&
                                    adapterVersion.baseVersionRepoUUID && (
                                        <span style={{ marginRight: "1.71rem" }}>
                                            Continued training from{" "}
                                            <a
                                                href={urlJoin(
                                                    getClientEndpoint(),
                                                    `/adapters/repo/${adapterVersion.baseVersionRepoUUID}/version/${adapterVersion?.baseVersionTag}`,
                                                )}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {adapterVersion.baseVersionRepoName}/{adapterVersion.baseVersionTag}
                                            </a>
                                        </span>
                                    )}
                                <span>
                                    Type: {formatAdapterType(adapterVersion.adapterType)}
                                </span>
                            </div>
                        </div>
                        <div style={{ display: "flex", marginLeft: "auto", maxHeight: `${36 / 14}rem` }}>
                            {isReady && (
                                <Popup
                                    disabled={userIsInEnterpriseTier}
                                    className={"transition-scale"}
                                    position={"top left"}
                                    trigger={
                                        // To enable the popup when the button is disabled we have to wrap it with a
                                        // div, see: https://github.com/Semantic-Org/Semantic-UI-React/issues/2804
                                        <div>
                                            <Button
                                                icon="download"
                                                aria-label="Download Adapter"
                                                style={{ marginRight: `${8 / 14}rem`, minWidth: `${42 / 14}rem` }}
                                                disabled={
                                                    !userIsInEnterpriseTier &&
                                                    !isSolarAdapter &&
                                                    new Date(adapterVersion.createdAt) >
                                                        new Date("31 May 2024 00:00:00 UTC")
                                                }
                                                onClick={() => {
                                                    // Purposely updated to match PostHog's suggested naming convention
                                                    metrics.capture("adapter_exported");
                                                    window.open(
                                                        `${getV2APIEndpoint()}/repos/${repo.uuid}/version/${
                                                            adapterVersion?.tag
                                                        }/download`,
                                                        "_blank",
                                                    );
                                                }}
                                            />
                                        </div>
                                    }
                                    content={
                                        <>
                                            Contact us at{" "}
                                            <a href="mailto:support@predibase.com">support@predibase.com</a> to download
                                            your adapter in the Developer tier. Downloading adapters is available for
                                            Enterprise users by default.
                                        </>
                                    }
                                />
                            )}
                            {isReady && (
                                <Dropdown
                                    button
                                    text="Prompt"
                                    style={{
                                        marginRight: `${8 / 14}rem`,
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            onClick={() =>
                                                navigate(
                                                    `/prompt?version=${adapterVersion?.tag}&name=${encodeURIComponent(
                                                        repo.name,
                                                    )}&model=${encodeURIComponent(adapterVersion?.baseModel ?? "")}`,
                                                )
                                            }
                                        >
                                            Prompt via UI
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => setPromptInstructionsModalOpen(true)}>
                                            Prompt via REST API/SDK/CLI
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            )}
                            {userRole !== role.READONLY && (
                                <>
                                    {!isLegacyAdapter && (
                                        <Dropdown
                                            text="New Adapter"
                                            button
                                            direction="left"
                                            style={{
                                                color: SEMANTIC_WHITE,
                                                backgroundColor: SEMANTIC_BLUE,
                                                whiteSpace: "nowrap",
                                            }}
                                            disabled={!adapterVersion.finetuningJob}
                                        >
                                            <Dropdown.Menu>
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        window.open(
                                                            `/adapters/create/${repo?.uuid}?adapterVersionID=${adapterVersion?.tag}`,
                                                            "_blank",
                                                        );
                                                    }}
                                                >
                                                    New adapter from #{adapterVersion.tag}
                                                </Dropdown.Item>

                                                <Dropdown.Item
                                                    onClick={() => {
                                                        // TODO: Grab the entire repo to get the latest tag.
                                                        setRetrainModalOpen(true);
                                                    }}
                                                >
                                                    Retrain
                                                </Dropdown.Item>
                                                {isReady && isLoRAAdapter && (
                                                    <Dropdown.Item
                                                        onClick={() => {
                                                            window.open(
                                                                `/adapters/create/${repo?.uuid}/continue?adapterVersionID=${adapterVersion?.tag}`,
                                                                "_blank",
                                                            );
                                                        }}
                                                    >
                                                        (Beta) Continue Training Adapter
                                                    </Dropdown.Item>
                                                )}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    )}

                                    {adapterVersion.finetuningJob &&
                                        !isTerminalJobStatus(adapterVersion.status) &&
                                        !isLegacyAdapter && (
                                            <Button
                                                color={"black"}
                                                style={{ marginRight: 0 }}
                                                disabled={adapterVersion.status === finetuningJobStatus.STOPPING}
                                                onClick={() => setCancelTrainingJobModalOpen(true)}
                                            >
                                                <span style={{ display: "flex" }}>
                                                    {adapterVersion.status === finetuningJobStatus.STOPPING && (
                                                        <Loader active inline size="tiny" />
                                                    )}
                                                    &nbsp;Stop
                                                </span>
                                            </Button>
                                        )}
                                </>
                            )}
                        </div>
                    </div>

                    <Divider hidden />
                    <AdapterSummary
                        repo={repo}
                        adapterVersion={adapterVersion}
                        finetuningJobTimeline={finetuningJobTimeline}
                    />
                    <Divider hidden />
                    {/* TODO: Probably want repo uuid on the adapterVersionListItem so we don't have to plumb it: */}
                    {adapterVersion.finetuningJob ? (
                        <AdapterVersionViewSubmenu
                            adapterVersion={adapterVersion}
                            repoUUID={repoUUID}
                            job={adapterVersion.finetuningJob}
                        />
                    ) : (
                        <Tab
                            style={{ position: "relative" }}
                            menu={{ secondary: true, pointing: true }}
                            panes={[
                                {
                                    menuItem: "Details",
                                    render: () => {
                                        return (
                                            <Table collapsing>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>
                                                            <b>Base model</b>
                                                        </TableCell>
                                                        <TableCell>{adapterVersion.baseModel}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            <b>Rank</b>
                                                        </TableCell>
                                                        <TableCell>
                                                            {adapterVersion.importedAdapterProperties?.rank || "--"}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            <b>Target modules</b>
                                                        </TableCell>
                                                        <TableCell>
                                                            {adapterVersion.importedAdapterProperties?.targetModules?.join(
                                                                ", ",
                                                            ) || "--"}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        );
                                    },
                                },
                            ]}
                            activeIndex={0}
                        />
                    )}
                    <Divider hidden />
                    <PromptInstructionsModal
                        deployment={compatibleDeployment}
                        adapter={adapterVersion}
                        open={promptInstructionsModalOpen}
                        setOpen={setPromptInstructionsModalOpen}
                    />
                    <RetrainAdapterModal
                        adapterRepo={repo}
                        adapterVersion={adapterVersion}
                        open={retrainModalOpen}
                        setOpen={setRetrainModalOpen}
                    />
                    <CancelFinetuningJobModal
                        adapterVersion={adapterVersion}
                        open={cancelTrainingJobModalOpen}
                        setOpen={setCancelTrainingJobModalOpen}
                    />
                </div>
            )}
        </>
    );
};

export default AdapterVersionView;
