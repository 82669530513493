import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link, useMatch } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
    Button,
    Divider,
    Dropdown,
    Header,
    Icon,
    Label,
    Loader,
    Menu,
    Message,
    Tab,
    TabProps,
    Table,
} from "semantic-ui-react";
import { adapterVersionListItem, repo, role, tier } from "../../../api_generated";
import { BadLink } from "../../../components/BadLink/BadLink";
import { SubscriptionButton } from "../../../components/GlobalHeader/SubscriptionButtons";
import LiveTimeAgo from "../../../components/LiveTimeAgo";
import { Auth0TokenOptions } from "../../../data";
import { useUserRole } from "../../../settings/query";
import { USER_STATE } from "../../../state/global";
import { SEMANTIC_BLUE, SEMANTIC_GREY, SEMANTIC_WHITE } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/errors";
import { isKratosUserContext } from "../../../utils/kratos";
import AdapterBreadcrumb from "../../AdapterBreadcrumb";
import { updateAdapterRepoDescription } from "../../data";
import EditableDescription from "../../misc/EditableDescription";
import RepoContributors from "../../misc/RepoContributors";
import { getlatestAdapterInRepo } from "../../misc/utils";
import { GET_ADAPTER_REPOS_QUERY_KEY, useAdapterRepoQuery } from "../../query";
import UploadAdapterVersionModal from "../modals/UploadAdapterModal";
import AdapterVersionsTable from "./AdapterVersionsTable";

enum MenuTabEnum {
    AdapterVersions,
    Settings,
}

const AdapterVersionsTabPane = (props: { repo: repo }) => {
    // Parent state:
    const { repo } = props;

    // Derived state:
    const latestAdapterVersion = getlatestAdapterInRepo(repo);

    // Local state:
    const [uploadAdapterModalOpen, setUploadAdapterModalOpen] = useState<boolean>(false);

    // Query state:
    const userRole = useUserRole();

    return (
        <>
            <Tab.Pane key={"adapterVersionsTab"} as={"div"}>
                <div style={{ minHeight: "500px" }}>
                    {userRole !== role.READONLY && (
                        <div
                            style={{
                                display: "flex",
                            }}
                        >
                            <Dropdown
                                button
                                icon={"plus"}
                                text="New Adapter"
                                labeled
                                className="icon"
                                style={{ color: SEMANTIC_WHITE, backgroundColor: SEMANTIC_BLUE }}
                            >
                                <Dropdown.Menu>
                                    {latestAdapterVersion && (
                                        <Dropdown.Item
                                            as={Link}
                                            to={`/adapters/create/${repo.uuid}?adapterVersionID=${latestAdapterVersion.tag}`}
                                        >
                                            New Adapter Version From Latest
                                        </Dropdown.Item>
                                    )}
                                    <Dropdown.Item as={Link} to={`/adapters/create/${repo.uuid}`}>
                                        New Adapter Version
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Button
                                icon="upload"
                                content="Upload Adapter"
                                onClick={() => setUploadAdapterModalOpen(true)}
                            />
                        </div>
                    )}
                    <AdapterVersionsTable repo={repo} />
                </div>
            </Tab.Pane>
            <UploadAdapterVersionModal repo={repo} open={uploadAdapterModalOpen} setOpen={setUploadAdapterModalOpen} />
        </>
    );
};

const SettingsTabPane = (props: { repo: repo }) => {
    // Parent state:
    const { repo } = props;

    // Derived state:
    const latestAdapterVersion = getlatestAdapterInRepo(repo);

    return (
        <Tab.Pane key={"settings"} as={"div"}>
            <Header as={"h2"} size={"small"}>
                Overview
            </Header>
            <Table basic={"very"} collapsing compact>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell style={{ borderTop: "none", color: SEMANTIC_GREY, paddingRight: `${100 / 14}rem` }}>
                            Name
                        </Table.Cell>
                        <Table.Cell>{repo.name}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell
                            style={{
                                borderTop: "none",
                                color: SEMANTIC_GREY,
                                paddingRight: `${100 / 14}rem`,
                            }}
                        >
                            Description
                        </Table.Cell>
                        <Table.Cell style={{ borderTop: "none" }}>
                            <EditableDescription
                                description={repo.description}
                                inputMinWidth={`${500 / 14}rem`}
                                updateDescriptionFunction={(text: string, auth0TokenOptions?: Auth0TokenOptions) =>
                                    updateAdapterRepoDescription(repo.uuid, text, auth0TokenOptions)
                                }
                                queryKeyToInvalidate={GET_ADAPTER_REPOS_QUERY_KEY()}
                            />
                        </Table.Cell>
                    </Table.Row>
                    {latestAdapterVersion && (
                        <Table.Row>
                            <Table.Cell
                                style={{ borderTop: "none", color: SEMANTIC_GREY, paddingRight: `${100 / 14}rem` }}
                            >
                                Last Modified
                            </Table.Cell>
                            <Table.Cell style={{ borderTop: "none" }}>
                                <LiveTimeAgo fromDate={new Date(latestAdapterVersion.createdAt)} />
                            </Table.Cell>
                        </Table.Row>
                    )}
                    <Table.Row>
                        <Table.Cell style={{ borderTop: "none", color: SEMANTIC_GREY, paddingRight: `${100 / 14}rem` }}>
                            Contributors
                        </Table.Cell>
                        <Table.Cell style={{ borderTop: "none" }}>
                            <RepoContributors repo={repo} />
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
            <Divider />
        </Tab.Pane>
    );
};

const AdapterRepoView = () => {
    // User state:
    const [userContext] = useRecoilState(USER_STATE);
    // Derived user state:
    let userTier: tier | undefined;
    if (userContext) {
        const isKratosContext = isKratosUserContext(userContext);
        userTier = isKratosContext ? userContext.tenant.subscription.tier : userContext?.tenant.tier;
    }

    // Meta state:
    const match = useMatch("/adapters/repo/:uuid");
    const repoUUID = match?.params.uuid;

    // Local state:
    const [tabIndex, setTabIndex] = useState(MenuTabEnum.AdapterVersions);

    // Query state:
    const {
        data: repo,
        isFetching: repoIsFetching,
        error: getReppQueryError,
    } = useAdapterRepoQuery(repoUUID ?? "", {
        enabled: Boolean(repoUUID),
        refetchOnWindowFocus: false,
        refetchInterval: 30 * 1000, // 30 seconds
    });

    if (getReppQueryError) {
        return <BadLink authenticated={true} />;
    }

    // Derived state:
    const latestAdapterVersion = repo && getlatestAdapterInRepo(repo);

    return (
        <>
            {repoIsFetching && repo === undefined && (
                <div className="loading-overlay">
                    <Loader active />
                </div>
            )}
            {repo && (
                <div style={{ padding: "1.71rem" }}>
                    <div className={"repo-header"}>
                        <AdapterBreadcrumb adapterRepo={repo} />
                        <SubscriptionButton isExpired={userContext?.isExpired} currentTier={userTier} />
                    </div>
                    <Divider hidden />

                    <div className="row">
                        <Header as={"h2"} className={"header"} content={repo.name} />
                        <span style={{ color: SEMANTIC_GREY }}>{repo.description}</span>
                        {latestAdapterVersion && (
                            <div style={{ marginTop: "10px", fontSize: "0.9em" }}>
                                Last modified: <LiveTimeAgo fromDate={new Date(latestAdapterVersion.createdAt)} />{" "}
                                <span style={{ color: SEMANTIC_GREY }}>({latestAdapterVersion.createdBy})</span>
                            </div>
                        )}
                        <br />
                        <Tab
                            panes={[
                                {
                                    menuItem: (
                                        <Menu.Item style={{ height: "40px" }} key={"adapterVersionsMenuItem"}>
                                            <FontAwesomeIcon
                                                icon="circle-nodes"
                                                style={{ margin: "0 .35714286em 0 0", opacity: 0.9, float: "none" }}
                                            />
                                            Adapter Versions
                                            <Label
                                                circular
                                                horizontal
                                                size={"tiny"}
                                                style={{
                                                    background: "#e0e1e2 none",
                                                    color: SEMANTIC_GREY,
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {/* TODO: Remove null when we fix the backend: */}
                                                {(repo.versions as null | adapterVersionListItem[])?.length}
                                            </Label>
                                        </Menu.Item>
                                    ),
                                    render: () => <AdapterVersionsTabPane repo={repo} />,
                                },
                                {
                                    menuItem: (
                                        <Menu.Item style={{ height: "40px" }} key={"settings"}>
                                            <Icon name="settings" /> Settings
                                        </Menu.Item>
                                    ),
                                    render: () => <SettingsTabPane repo={repo} />,
                                },
                            ]}
                            activeIndex={tabIndex}
                            onTabChange={(e: React.MouseEvent<HTMLDivElement>, { activeIndex }: TabProps) => {
                                const idx = Number(activeIndex);
                                setTabIndex(idx);
                            }}
                            renderActiveOnly={true}
                            menu={{ secondary: true, pointing: true }}
                        />
                    </div>
                    <Divider hidden />
                    {getReppQueryError && (
                        <div className="row">
                            {
                                <Message negative>
                                    <Message.Header>Error in model training</Message.Header>
                                    <p>{getErrorMessage(getReppQueryError)}</p>
                                </Message>
                            }
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default AdapterRepoView;
