/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type validateUserResponse = {
    status: validateUserResponse.status;
};
export namespace validateUserResponse {
    export enum status {
        RECOGNIZED = 'recognized',
        NEW_USER = 'newUser',
        SWITCH_TENANT = 'switchTenant',
        ACCOUNTS_MERGED = 'accountsMerged',
    }
}

