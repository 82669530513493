import { finetuningJobStatus } from "../../api_generated";
import {
    SEMANTIC_BLUE_ACTIVE,
    SEMANTIC_GREY,
    SEMANTIC_GREY_DISABLED,
    SEMANTIC_OLIVE_ACTIVE,
    SEMANTIC_RED_ACTIVE,
} from "../../utils/colors";
import { capitalize } from "../../utils/strings";

const JobStatusChip = (props: { status?: finetuningJobStatus | null; width?: string }) => {
    let textColor = undefined;
    let opacity = 0.1;
    switch (props.status) {
        case finetuningJobStatus.ERRORED:
            textColor = SEMANTIC_RED_ACTIVE;
            break;
        case finetuningJobStatus.TRAINING:
            textColor = SEMANTIC_OLIVE_ACTIVE;
            break;
        case finetuningJobStatus.COMPLETED:
        case finetuningJobStatus.STOPPED:
            textColor = SEMANTIC_BLUE_ACTIVE;
            opacity = 0.3;
            break;
        case finetuningJobStatus.QUEUED:
        case finetuningJobStatus.PENDING:
        case finetuningJobStatus.STOPPING:
            textColor = SEMANTIC_GREY_DISABLED;
            break;
        case finetuningJobStatus.CANCELED:
            textColor = SEMANTIC_GREY;
            break;
        default:
            textColor = SEMANTIC_GREY;
            break;
    }
    return (
        // TODO: Add loading spinner for training status
        <span style={{ color: textColor }}>{capitalize(props.status ?? "—")}</span>
    );
};

export default JobStatusChip;
