import { getTraceId } from "../api/trace";
import {
    adapterVersion,
    beginAdapterUploadRequest,
    beginAdapterUploadResponse,
    completeAdapterUploadRequest,
    createFinetuningJobRequest,
    createRepoRequest,
    finetuningJob,
    finetuningJobTimeline,
    listReposResponse,
    repo,
    updateAdapterVersionRequest,
    updateRepoRequest,
} from "../api_generated";
import { APIResponse, Auth0TokenOptions } from "../data";
import metrics from "../metrics/metrics";
import { createV2APIServer, redirectIfSessionInvalid } from "../utils/api";
import { getErrorMessage } from "../utils/errors";

// TODO: Should be autogenerated:
export interface listAdapterReposParams {
    name?: string;
    baseModel?: string;
    limit?: number;
    offset?: number;
}

export interface versionArchivalParams {
    repoUUID: string;
    versionTag: number;
    archive: boolean;
}

// CRUD operations around adapter repos:
export const createAdapterRepo = async (config: createRepoRequest, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = "/repos";
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return v2APIServer
        .post<repo>(endpoint, config)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "POST",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const getAdapterRepo = async (uuid: string, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `/repos/${uuid}`;
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return v2APIServer
        .get<repo>(endpoint)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const updateAdapterRepoDescription = async (
    repoUUID: string,
    description: string,
    auth0TokenOptions?: Auth0TokenOptions,
) => {
    const endpoint = `/repos/${repoUUID}`;
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return v2APIServer
        .put<updateRepoRequest>(endpoint, { description })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "PUT",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const getAdapterRepos = async (params?: listAdapterReposParams, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = "/repos";
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return (
        v2APIServer
            // TODO: Makes me think: should we let this endpoint accept no params, or do we always have to pass at least
            // an empty set of params?
            .get<APIResponse<listReposResponse>>(endpoint, { params: { ...params } })
            .then((res) => {
                return res.data.data;
            })
            .catch((error) => {
                const errorMsg = getErrorMessage(error) ?? "";
                metrics.captureError("api_error", errorMsg, {
                    method: "GET",
                    endpoint,
                    trace_id: getTraceId(error),
                });
                redirectIfSessionInvalid(errorMsg);
                throw errorMsg;
            })
    );
};

// CRUD operations around adapters (incomplete):
export const getAdapterVersion = async (
    repoUUID: string,
    versionTag: number,
    auth0TokenOptions?: Auth0TokenOptions,
) => {
    const endpoint = `/repos/${repoUUID}/version/${versionTag}`;
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return v2APIServer
        .get<adapterVersion>(endpoint)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const beginAdapterVersionUpload = async (
    req: beginAdapterUploadRequest,
    auth0TokenOptions?: Auth0TokenOptions,
) => {
    const endpoint = `/adapters/upload`;
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return v2APIServer
        .post<beginAdapterUploadResponse>(endpoint, req)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "POST",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const completeAdapterVersionUpload = async (
    req: completeAdapterUploadRequest,
    auth0TokenOptions?: Auth0TokenOptions,
) => {
    const endpoint = `/adapters/upload`;
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return v2APIServer
        .put<adapterVersion>(endpoint, req)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "PUT",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const updateAdapterVersionDescription = async (
    repoUUID: string,
    versionTag: number,
    description: string,
    auth0TokenOptions?: Auth0TokenOptions,
) => {
    const endpoint = `/repos/${repoUUID}/version/${versionTag}`;
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return v2APIServer
        .put<updateAdapterVersionRequest>(endpoint, { description })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "PUT",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

// CRUD operations around jobs (incomplete):
export const createFinetuningJob = async (
    config: createFinetuningJobRequest,
    auth0TokenOptions?: Auth0TokenOptions,
) => {
    const endpoint = "/finetuning/jobs";
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return v2APIServer
        .post<finetuningJob>(endpoint, config)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "POST",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const getFinetuningJob = async (uuid: string, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `/finetuning/jobs/${uuid}`;
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return v2APIServer
        .get<finetuningJob>(endpoint)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const getFinetuningJobTimeline = async (uuid: string, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `/finetuning/jobs/${uuid}/timeline`;
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return v2APIServer
        .get<finetuningJobTimeline>(endpoint)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const cancelFinetuningJob = async (uuid: string, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `/finetuning/jobs/${uuid}/cancel`;
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return v2APIServer
        .post<undefined>(endpoint)
        .then((res) => {
            return res.data;
        })
        .catch((error: any) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "POST",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const archiveOrUnarchiveVersion = async (
    params: versionArchivalParams,
    auth0TokenOptions?: Auth0TokenOptions,
) => {
    const { repoUUID, versionTag, archive } = params;
    const endpoint = `/repos/${repoUUID}/version/${versionTag}`;
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return v2APIServer.put<updateAdapterVersionRequest>(endpoint, { archived: archive });
};

export interface versionDeleteParams {
    repoUUID: string;
    versionTag: number;
}

export const deleteAdapterVersion = async (params: versionDeleteParams, auth0TokenOptions?: Auth0TokenOptions) => {
    const { repoUUID, versionTag } = params;
    const endpoint = `/repos/${repoUUID}/version/${versionTag}`;
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return v2APIServer
        .delete(endpoint)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "DELETE",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};
