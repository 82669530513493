import React, { useState } from "react";
import { Button, Icon, Popup, SemanticCOLORS, SemanticICONS } from "semantic-ui-react";
import { BLOCK_POSTHOG_AUTO_CAPTURE } from "../metrics/metrics";

function CopyButton(props: {
    text: string;
    copyText?: string;
    icon?: SemanticICONS;
    iconElement?: React.ReactElement;
    iconColor?: SemanticCOLORS;
    style?: React.CSSProperties;
    disabled?: boolean;
}) {
    const [clipboardCopied, setClipboardCopied] = useState(false);
    return (
        <Popup
            className="transition-scale"
            content={
                clipboardCopied ? (
                    <span>
                        <b>Copied</b>
                    </span>
                ) : (
                    props.copyText || "Copy"
                )
            }
            position={"top center"}
            onClose={(event, data) => setClipboardCopied(false)}
            closeOnTriggerClick={false}
            trigger={
                <Button
                    icon
                    className={`${BLOCK_POSTHOG_AUTO_CAPTURE} button-reset`}
                    aria-label={props.copyText || "Copy"}
                    style={{ padding: `${7 / 14}rem`, marginRight: 0, ...props.style }}
                    onClick={() => {
                        setClipboardCopied(true);
                        navigator.clipboard.writeText(props.text);
                    }}
                    disabled={props?.disabled}
                >
                    {props.iconElement ? (
                        props.iconElement
                    ) : (
                        <Icon color={props.iconColor ? props.iconColor : "blue"} name={props.icon || "copy outline"} />
                    )}
                </Button>
            }
        />
    );
}

export default CopyButton;
