import { JSONSchema7, JSONSchema7Type } from "json-schema";
import _ from "lodash";
import Input from "../../../Input";
import Markdown from "../../../Markdown";
import { DualInput } from "../custom-components";
import { defaultTextStyling } from "../styling";
import { FieldTitle } from "./markup";

export const ArrayField = (props: {
    path: string;
    title?: string;
    invalidFields: any;
    value: string;
    description: string | undefined;
    defaultValue?: JSONSchema7Type;
    config: any;
    setConfig: (path: string, typedValue: any) => void;
    setLocalState: (localState: any, path: string) => void;
    schema: JSONSchema7;
    schemaPath: string;
    readOnly?: boolean;
}) => {
    const { config, defaultValue, description, path, schema, schemaPath, setConfig, setLocalState, title, value } =
        props;

    if (!schema) {
        return null;
    }

    if (path.endsWith("entity_1") || path.endsWith("entity_2")) {
        return (
            <DualInput
                path={path}
                title={title}
                error={_.has(props.invalidFields, schemaPath)}
                description={description}
                value={_.isObject(value) ? Object.values(value) : undefined}
                defaultValue={undefined}
                config={config}
                setConfig={setConfig}
                setLocalState={setLocalState}
                schemaPath={schemaPath}
                readOnly={props.readOnly}
            />
        );
    }

    if (!Array.isArray(schema?.items) && !Array.isArray(schema?.default)) {
        return null;
    }

    // TODO: we need some kind of dynamic logic for class weights - how do I know how many fields to display?
    // For now, just use the number of items or the number default values.
    // @ts-expect-error
    const itemsCount = schema?.items.length;
    const arrayItemCount =
        !Array.isArray(schema?.items) && Array.isArray(defaultValue) ? defaultValue.length : itemsCount;
    const renderStacked = arrayItemCount > 3 ? true : false;

    const rows: any[] = [];
    for (let index = 0; index < arrayItemCount; index++) {
        const itemPath = path + `[${index}]`;
        const itemValue = value[index];
        // @ts-expect-error
        const itemDefaultValue = defaultValue ? String(defaultValue[index]) : undefined;
        rows.push(
            <div style={{ display: "flex", marginRight: "40px" }} key={`${itemPath}_field`}>
                <Input
                    type={"text"}
                    path={itemPath}
                    title={title}
                    error={_.has(props.invalidFields, itemPath)}
                    value={itemValue}
                    description={description}
                    defaultValue={itemDefaultValue}
                    setConfig={setConfig}
                    setLocalState={setLocalState}
                    style={{ marginBottom: "16px" }}
                    schemaPath={schemaPath}
                    readOnly={props.readOnly}
                />
                {itemDefaultValue && <p style={{ ...defaultTextStyling }}>Default: {itemDefaultValue}</p>}
            </div>,
        );
    }

    const titlePrefix = _.get(schema, "parameter_metadata.prefix", "");
    return (
        <div style={{ marginBottom: `${24 / 14}rem` }} key={`${path}_array`}>
            <FieldTitle title={title} prefix={titlePrefix} />
            <Markdown children={description} secondary={true} />
            <div style={{ display: "flex", flexDirection: renderStacked ? "column" : "row" }}>{rows}</div>
        </div>
    );
};
