import { InputNumber, Slider } from "antd";
import { Popup } from "semantic-ui-react";
import { ActionType, useDispatch, usePromptState } from "./utils/reducer";

import "./Sliders.css";

// TODO: Let's get rid of antd if we can!
export const TemperatureSlider = () => {
    const dispatch = useDispatch();
    const { temperature, doSample } = usePromptState();

    return (
        <div>
            <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <label>
                    <Popup
                        className="transition-scale"
                        hoverable
                        wide={"very"}
                        position={"right center"}
                        trigger={<b>Temperature</b>}
                        content={
                            <span>
                                Temperature is used to modulate the randomness of the output. Values &lt;1 make the
                                model more deterministic, while values &gt;1 make the model more random.
                            </span>
                        }
                    />
                </label>
                <span>
                    <InputNumber
                        min={0}
                        max={2}
                        step={0.1}
                        value={temperature}
                        disabled={!doSample}
                        onChange={(val) => dispatch({ type: ActionType.UPDATE, temperature: val?.valueOf() })}
                    />
                </span>
            </label>
            <div className="icon-wrapper">
                <Slider
                    value={temperature}
                    disabled={!doSample}
                    onChange={(val) => dispatch({ type: ActionType.UPDATE, temperature: val })}
                    styles={{
                        rail: { height: `{6/14}rem` },
                        track: { backgroundColor: "black", height: `{6/14}rem` },
                    }}
                    step={0.1}
                    min={0}
                    max={2}
                />
            </div>
        </div>
    );
};

export const TopPSlider = () => {
    const dispatch = useDispatch();
    const { topP, doSample } = usePromptState();

    return (
        <div>
            <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <label>
                    <Popup
                        className="transition-scale"
                        hoverable
                        wide={"very"}
                        position={"right center"}
                        trigger={<b>Top P</b>}
                        content={
                            <span>
                                Only sample from the most likely tokens with cumulative probability &#8805; Top P.
                                Values closer to 0 will exclude more low probability tokens and make the output more
                                deterministic.
                            </span>
                        }
                    />
                </label>
                <span>
                    <InputNumber
                        min={0.05}
                        max={1}
                        step={0.05}
                        value={topP}
                        disabled={!doSample}
                        onChange={(val) => dispatch({ type: ActionType.UPDATE, topP: val?.valueOf() })}
                    />
                </span>
            </label>
            <div className="icon-wrapper">
                <Slider
                    value={topP}
                    disabled={!doSample}
                    onChange={(val) => dispatch({ type: ActionType.UPDATE, topP: val })}
                    styles={{
                        rail: { height: `{6/14}rem` },
                        track: { backgroundColor: "black", height: `{6/14}rem` },
                    }}
                    step={0.05}
                    min={0.05}
                    max={1}
                />
            </div>
        </div>
    );
};

export const MaxTokensSlider = () => {
    const dispatch = useDispatch();
    const { selectedDeployment, maxNewTokens } = usePromptState();

    return (
        <div>
            <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <label>
                    <Popup
                        className="transition-scale"
                        hoverable
                        wide={"very"}
                        position={"right center"}
                        trigger={<b>Max New Tokens</b>}
                        content={
                            <span>
                                Maximum number of tokens that can be generated in a single request. This is capped by
                                the deployment's maximum total tokens. The model may stop generating tokens before this
                                limit is reached if it encounters a stop token.
                            </span>
                        }
                    />
                </label>
                <span>
                    <InputNumber
                        min={1}
                        max={selectedDeployment?.model.maxTotalTokens}
                        value={maxNewTokens}
                        onChange={(val) => dispatch({ type: ActionType.UPDATE, maxNewTokens: val?.valueOf() })}
                    />
                </span>
            </label>
            <Slider
                value={maxNewTokens}
                onChange={(val) => dispatch({ type: ActionType.UPDATE, maxNewTokens: val })}
                styles={{
                    rail: { height: `{6/14}rem` },
                    track: { backgroundColor: "black", height: `{6/14}rem` },
                }}
                step={1}
                min={1}
                max={selectedDeployment?.model.maxTotalTokens}
            />
        </div>
    );
};
