import { JSONSchema7, JSONSchema7Type } from "json-schema";
import { CurrentUser, UserContext } from "../../../../types/user";
import { Of } from "./Of";

export const OneOf = (props: {
    description: string | undefined;
    defaultValue?: JSONSchema7Type;
    schema: JSONSchema7;
    config: CreateModelConfig;
    setConfig: (path: string, typedValue: any) => void;
    localState: any;
    setLocalState: (localState: any, path: string) => void;
    invalidFields: any;
    featureIndex?: number;
    level: number;
    path: string;
    schemaPath: string;
    readOnly?: boolean;
    userContext?: UserContext | CurrentUser;
}) => {
    return <Of selector="oneOf" {...props} />;
};
