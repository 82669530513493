import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { SemanticToastContainer, toast } from "react-semantic-toasts";
import { Form, Loader, Radio } from "semantic-ui-react";
import Chip from "../../components/Chip";
import { useAuth0TokenOptions } from "../../data";
import { SelectedDefaults } from "../../types/model/selectedDefaults";
import { SEMANTIC_BLUE } from "../../utils/colors";
import { detectAutoMLConfig } from "../data";
import { useConfigState, useDispatch } from "./store";

import "react-semantic-toasts/styles/react-semantic-alert.css";
import "./SelectDefaults.css";

const iconStyle = {
    width: "1.1428rem",
    marginRight: "1.1428rem",
};
const IconPlaceholder = (props: { checked: boolean }) => (
    <FontAwesomeIcon
        icon={"check-circle"}
        style={iconStyle}
        color={props.checked ? SEMANTIC_BLUE : "rgb(222, 222, 223, 0.1)"}
    />
);

const SelectedRadioItem = (props: {
    title: string;
    description: string;
    selectedDefault: SelectedDefaults;
    radioOption: SelectedDefaults;
    dirtyDefault: boolean;
    onChange: () => void;
    loading?: boolean;
}) => {
    return (
        <Form.Field>
            <Radio
                label={
                    <label style={{ position: "relative" }}>
                        {props.loading ? (
                            <Loader active inline size="tiny" style={iconStyle} />
                        ) : (
                            <IconPlaceholder checked={props.selectedDefault === props.radioOption} />
                        )}
                        <div style={{ display: "block" }}>
                            <strong>{props.title}</strong>
                            <br />
                            <span className="description">{props.description}</span>
                            <br />
                            {props.radioOption === SelectedDefaults.BASIC && (
                                <Chip
                                    color={SEMANTIC_BLUE}
                                    opacity={0.1}
                                    text="Recommended for your first model"
                                    size="small"
                                    width="15.6rem"
                                />
                            )}
                        </div>
                        {props.selectedDefault === props.radioOption &&
                            props.dirtyDefault &&
                            props.loading !== true && (
                                <span
                                    style={{
                                        position: "absolute",
                                        top: "8px",
                                        right: "8px",
                                        color: SEMANTIC_BLUE,
                                        fontSize: "12px",
                                        fontWeight: 700,
                                    }}
                                >
                                    Modified
                                </span>
                            )}
                    </label>
                }
                name="selectedDefault"
                value={props.radioOption}
                checked={props.selectedDefault === props.radioOption}
                onClick={() => props.onChange()}
            />
        </Form.Field>
    );
};

const ToastErrorMessage = () => {
    toast({
        type: "error",
        title: "Suggested Configuration Error",
        description: "Unable to generate a suggested configuration at this time.",
        animation: "fade",
        time: 5000,
    });
};

const SelectDefaults = (props: { dataset?: Dataset }) => {
    const [loadingAutoML, setLoadingAutoML] = useState(false);
    const dispatch = useDispatch();
    const { config, dirtyDefault, selectedDefault } = useConfigState();

    const currentSelectedDefault = loadingAutoML ? SelectedDefaults.AUTOML : selectedDefault;

    const auth0TokenOptions = useAuth0TokenOptions();

    const { mutate: detect } = useMutation({
        mutationFn: () => detectAutoMLConfig(props.dataset?.id, config, auth0TokenOptions),
        onMutate: () => {
            dispatch({ type: "USE_SUGGESTED_CONFIG" });
            setLoadingAutoML(true);
        },
        onSuccess: (data) => {
            const errorMessage = data.errorMessage || "";
            if (errorMessage) {
                console.error(errorMessage);
                ToastErrorMessage();
                return;
            }

            dispatch({ type: "USE_SUGGESTED_CONFIG", suggestedConfig: data?.config });
        },
        onError: () => {
            ToastErrorMessage();
        },
        onSettled: () => {
            setLoadingAutoML(false);
        },
    });

    return (
        <>
            <SemanticToastContainer position="top-right" maxToasts={1} />
            <Form className="select-defaults-component" style={{ minWidth: "50%" }}>
                <fieldset>
                    <legend style={{ fontSize: "1rem", marginBottom: "0.5714rem", fontWeight: "700" }}>
                        Populate config with
                    </legend>
                    <div style={{ display: "flex" }}>
                        <SelectedRadioItem
                            title="Basic defaults"
                            description="Lightweight model architecture with generic defaults."
                            selectedDefault={currentSelectedDefault}
                            radioOption={SelectedDefaults.BASIC}
                            dirtyDefault={dirtyDefault}
                            onChange={() => {
                                dispatch({ type: "USE_DEFAULT_CONFIG" });
                            }}
                        />
                        <SelectedRadioItem
                            title="Suggested Configuration (Beta)"
                            description="Infers the best performing configuration via heuristics about the dataset and task."
                            selectedDefault={currentSelectedDefault}
                            radioOption={SelectedDefaults.AUTOML}
                            dirtyDefault={dirtyDefault}
                            loading={loadingAutoML}
                            onChange={() => {
                                detect();
                            }}
                        />
                        {/* TODO: Bring this back once we resolve Hyperopt Issues with large models */}
                        {/* https://predibase.slack.com/archives/C03HRSHQBA6/p1670892800028029 */}
                        {/* <SelectedRadioItem
                            title="AutoML with Hyperopt"
                            description="Trains many models at once to find the highest performing hyperparameters. Requires significantly more resources."
                            selectedDefault={selectedDefault}
                            radioOption={SelectedDefaults.HYPEROPT}
                            dirtyDefault={dirtyDefault}
                        /> */}
                    </div>
                </fieldset>
            </Form>
        </>
    );
};

export default SelectDefaults;
