/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum deploymentStatus {
    PENDING = 'pending',
    INITIALIZING = 'initializing',
    READY = 'ready',
    STANDBY = 'standby',
    STOPPED = 'stopped',
    ERRORED = 'errored',
    UPDATING = 'updating',
    DELETED = 'deleted',
}
