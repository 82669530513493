import { AxiosInstance } from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { Divider, Grid, Loader, Message } from "semantic-ui-react";
import TargetSelector from "../../components/TargetSelector";
import { useAuth0TokenOptions } from "../../data";
import { createV1APIServer } from "../../utils/api";
import { SEMANTIC_GREY } from "../../utils/colors";
import { alphabeticalCollator } from "../../utils/sort";
import { modelStatusDisabledCriteria, outputFeatureDisabledCriteria, rocDisabledCriteria } from "../util";
import { getDynamicViz, VizType } from "./util";

function ROCCompareViewer(props: { models: Model[] }) {
    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    const [apiServer, setAPIServer] = useState<AxiosInstance | null>(null);
    useEffect(() => {
        const getAPIServer = async () => {
            const v1APIServer = await createV1APIServer(auth0TokenOptions);
            // NOTE: Whoever wrote the axios typings is a moron because the return type of axios.create is not
            // AxiosInstance -- it's a wrap function. And React will see that and treat it as a callback that
            // setState should directly call. FML.
            // See: [1], [2]:
            // [1]: https://github.com/axios/axios/issues/4365
            // [2]: https://stackoverflow.com/questions/64427195/calling-setstate-will-execute-the-function-value-instead-of-passing-it
            setAPIServer(() => v1APIServer);
        };
        getAPIServer();
    }, []);

    const [target, setTarget] = useState<string>("");

    const outputFeaturesForMultiModels = useMemo(() => {
        const res = Array.from(
            new Set(props.models.flatMap((model) => model.config.output_features.map((x: any) => x.name))),
        ).sort((a: string, b: string) => alphabeticalCollator.compare(a, b));
        if (res.length > 0 && !res.includes(target)) {
            setTarget(res[0]);
        }
        return res;
    }, [props.models]);

    const [links, setLinks] = useState<VizLink[]>();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const errorMap: Record<string, JSX.Element | string> = {};

    props.models.forEach((x) => {
        const errorMsg =
            modelStatusDisabledCriteria(x) || rocDisabledCriteria(x) || outputFeatureDisabledCriteria(x, target);
        if (errorMsg) {
            errorMap[x.repoVersion] = errorMsg;
        }
    });

    useEffect(() => {
        const supportedModels = props.models.filter((x) => !errorMap[x.repoVersion]);
        if (supportedModels.length > 0 && target) {
            getDynamicViz(
                apiServer,
                setLinks,
                setErrorMessage,
                supportedModels,
                VizType.ROC_CURVES_FROM_TEST_STATISTICS,
                target,
                setLoading,
            );
        } else {
            setLinks([]);
        }
    }, [props.models, target]);

    if (loading) {
        return <Loader active />;
    }

    if (errorMessage) {
        return <Message negative content={errorMessage} />;
    }

    if (props.models.length === 0) {
        return (
            <div
                style={{
                    height: "200px",
                    textAlign: "center",
                    lineHeight: "100px",
                    color: SEMANTIC_GREY,
                }}
            >
                Select models below to display ROC curves
            </div>
        );
    }

    return (
        <>
            {Object.keys(errorMap).length > 0 && (
                <Message info>
                    The following models are not supported for this visualization:
                    <Divider hidden />
                    <dl style={{ display: "grid", gridTemplateColumns: "7.4286rem 1fr" }}>
                        {Object.keys(errorMap)
                            .sort((a, b) => Number(b) - Number(a))
                            .map((tag) => {
                                return (
                                    <React.Fragment key={tag}>
                                        <dt key={tag}>{tag}</dt>
                                        <dd>{errorMap[tag]}</dd>
                                    </React.Fragment>
                                );
                            })}
                    </dl>
                </Message>
            )}
            {outputFeaturesForMultiModels.length > 0 ? (
                <div style={{ width: "90%" }}>
                    <Grid>
                        <Grid.Column computer={3}>
                            <TargetSelector
                                outputFeatures={outputFeaturesForMultiModels}
                                target={target}
                                setTarget={setTarget}
                            />
                        </Grid.Column>
                        <Grid.Column computer={13}>
                            {links &&
                                links.map((link) => (
                                    <img key={link.name} src={link.url} alt={link.name} width="600px" />
                                ))}
                        </Grid.Column>
                    </Grid>
                </div>
            ) : (
                links && links.map((link) => <img key={link.name} src={link.url} alt={link.name} width="600px" />)
            )}
        </>
    );
}

export default ROCCompareViewer;
