import { Dropdown, Icon } from "semantic-ui-react";

import metrics from "../../../metrics/metrics";
import { AllFeatureTypes, FreeTrialBlockedFeatureTypes } from "../../../types/model/featureTypes";
import { ModelTypes } from "../../../types/model/modelTypes";
import { hasConfigurableFieldMode, snakeToTitle } from "../../../utils/config";
import { computeRestrictedFeature, isFieldDisabled } from "./util";

const FieldDropdown = (props: {
    field: CreateModelField;
    fieldIndex: number;
    modelType: ModelTypes;
    isComputeLimited?: boolean;
    isFeatureLimited?: boolean;
    setType: (field: CreateModelField, fieldIndex: number, type: string) => void;
}) => {
    const { field, fieldIndex, isComputeLimited, modelType, setType } = props;

    const getDisabledStatus = (option: string) => {
        let disabled = false;

        // Disable if field is not supported by model type or field mode (input/output)
        if (isFieldDisabled(modelType, option, field.mode)) {
            disabled = true;
        }

        // Disable if compute is limited and field type is restricted
        if (isComputeLimited && computeRestrictedFeature(option)) {
            disabled = true;
        }

        return disabled;
    };

    const getOptionText = (option: string) => {
        // // If field is not supported by model type or field mode (input/output), disable without lock icon
        // if (isFieldDisabled(isDecisionTree, option, field.mode)) {
        //     return <p>{snakeToTitle(option)}</p>
        // }

        // If field is supported by model type and field mode (input/output), but compute restricted, disable with lock icon
        if (
            isComputeLimited &&
            Object.values(FreeTrialBlockedFeatureTypes).includes(option as FreeTrialBlockedFeatureTypes)
        ) {
            return (
                <p>
                    {snakeToTitle(option)}&nbsp;
                    <Icon name="lock" size="small" />
                </p>
            );
        }

        // If field is supported by model type and field mode (input/output), and compute not restricted, return regular text
        return <p>{snakeToTitle(option)}</p>;
    };

    const getDropdownOptions = (field: CreateModelField) => {
        let typeOptions: any[] = [];
        if (hasConfigurableFieldMode(field)) {
            typeOptions = AllFeatureTypes.map((option) => ({
                key: option,
                text: getOptionText(option),
                value: option,
                disabled: getDisabledStatus(option),
            }));
            return typeOptions;
        }
    };

    return (
        <Dropdown
            className={metrics.BLOCK_AUTO_CAPTURE}
            options={getDropdownOptions(field)}
            onChange={(e, { value }) => {
                setType(field, fieldIndex, value as string);
            }}
            value={field.config.type}
        />
    );
};

export default FieldDropdown;
