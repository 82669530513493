import { AxiosInstance } from "axios";
import { useEffect, useMemo, useState } from "react";
import { Divider, Header, Loader, Message, Segment, Table } from "semantic-ui-react";
import { useAuth0TokenOptions } from "../../data";
import { createV1APIServer } from "../../utils/api";
import { sliceIntoChunks } from "../../utils/arrays";
import { modelStatusDisabledCriteria } from "../util";
import { getStaticViz, VizType } from "./util";

const pairPlot = "hyperopt_pair_plot.svg";
const singleRegex = "hyperopt_(.+).svg";

function HyperoptReportViewer(props: { model: Model; plotsPerRow?: number; errorMessage?: string }) {
    const [links, setLinks] = useState<VizLink[]>();
    const [errorMessage, setErrorMessage] = useState<string | null>(props.errorMessage || null);

    const statusDisabledMsg = modelStatusDisabledCriteria(props.model);

    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    const [apiServer, setAPIServer] = useState<AxiosInstance | null>(null);
    useEffect(() => {
        const getAPIServer = async () => {
            const v1APIServer = await createV1APIServer(auth0TokenOptions);
            // NOTE: Whoever wrote the axios typings is a moron because the return type of axios.create is not
            // AxiosInstance -- it's a wrap function. And React will see that and treat it as a callback that
            // setState should directly call. FML.
            // See: [1], [2]:
            // [1]: https://github.com/axios/axios/issues/4365
            // [2]: https://stackoverflow.com/questions/64427195/calling-setstate-will-execute-the-function-value-instead-of-passing-it
            setAPIServer(() => v1APIServer);
        };
        getAPIServer();
    }, []);

    useEffect(() => {
        if (!statusDisabledMsg && apiServer) {
            getStaticViz(apiServer, setLinks, setErrorMessage, props.model, VizType.HYPEROPT_REPORT);
        }
    }, [props.model, apiServer]);

    const groupedLinks: Record<string, string[]> = useMemo(() => {
        if (links) {
            const grouped: Record<string, string[]> = {};
            const addToGrouped = (key: string, link: VizLink) => {
                if (!grouped[key]) {
                    grouped[key] = [];
                }
                grouped[key].push(link.url);
            };

            links.forEach((link) => {
                if (link.name.includes(pairPlot)) {
                    addToGrouped(link.name, link);
                } else {
                    const basicMatch = link.name.match(singleRegex);
                    if (basicMatch) {
                        addToGrouped(basicMatch[1], link);
                    } else {
                        addToGrouped("other", link);
                    }
                }
            });
            return grouped;
        }
        return {};
    }, [links]);

    if (statusDisabledMsg) {
        return <Message info content={statusDisabledMsg} />;
    }

    if (!links) {
        return <Loader active />;
    }

    if (errorMessage) {
        return <Message negative>{errorMessage}</Message>;
    }

    if (links.length === 0) {
        return (
            <Message negative>
                No Hyperopt Report visualizations found for model. This model was likely trained before this feature was
                added.
                <br />
                <br />
                To view, train a new version.
            </Message>
        );
    }

    const chunkSize = props.plotsPerRow || 3;
    const singleKeys = sliceIntoChunks(
        Object.keys(groupedLinks).filter((x) => !x.includes(pairPlot)),
        chunkSize,
    );
    const pairKeys = sliceIntoChunks(
        Object.keys(groupedLinks).filter((x) => x.includes(pairPlot)),
        chunkSize,
    );

    return (
        <>
            <Segment>
                <Header as={"h4"} style={{ marginBottom: 0 }}>
                    Single Parameter Loss Analysis
                </Header>
                <Table basic={"very"} style={{ marginTop: 0 }}>
                    <Table.Body>
                        {singleKeys.map((chunk, i) => {
                            return (
                                <Table.Row key={i}>
                                    {chunk.map((key) => {
                                        return (
                                            <Table.Cell style={{ borderTop: "none" }} key={key}>
                                                <Divider style={{ margin: "2px" }} />
                                                {groupedLinks[key].map((url) => (
                                                    <img
                                                        key={url}
                                                        src={url}
                                                        alt={key}
                                                        style={{
                                                            maxWidth: "100%",
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => window.open(url, "_blank")}
                                                    />
                                                ))}
                                            </Table.Cell>
                                        );
                                    })}
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            </Segment>
            {pairKeys.length > 0 ? (
                <Segment>
                    <Header as={"h4"} style={{ marginBottom: 0 }}>
                        Paired Parameter Loss Analysis
                    </Header>
                    <Table basic={"very"} style={{ marginTop: 0 }}>
                        <Table.Body>
                            {pairKeys.map((chunk, i) => {
                                return (
                                    <Table.Row key={i}>
                                        {chunk.map((key) => {
                                            return (
                                                <Table.Cell style={{ borderTop: "none" }} key={key}>
                                                    <Divider style={{ margin: "2px" }} />
                                                    {groupedLinks[key].map((url) => (
                                                        <img
                                                            key={url}
                                                            src={url}
                                                            alt={key}
                                                            style={{
                                                                maxWidth: "100%",
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() => window.open(url, "_blank")}
                                                        />
                                                    ))}
                                                </Table.Cell>
                                            );
                                        })}
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                </Segment>
            ) : null}
        </>
    );
}

export default HyperoptReportViewer;
