import Fuse from "fuse.js";
import { SyntheticEvent, useMemo, useState } from "react";
import { Form, Header, Search, SearchProps, SearchResultData, Segment } from "semantic-ui-react";
import DismissibleMessage from "../../../components/DismissibleMessage";
import { FieldType } from "../../../types/model/fieldType";
import { SEMANTIC_BLUE, SEMANTIC_GREY_DISABLED } from "../../../utils/colors";
import { useConfigState, useDispatch } from "../store";
import ParameterOption from "./ParameterOption";

const findPhrasesInSearchTerm = (searchTerm?: string) => {
    if (!searchTerm) {
        return [];
    }

    const phrases: string[] = [];
    let currentTerm = "";
    for (let char of searchTerm) {
        if (char === " " || char === "." || char === "-") {
            // If the user enters multiple delimiters, ignore them.
            if (currentTerm.length > 0) {
                phrases.push(currentTerm);
            }
            currentTerm = "";
        } else {
            currentTerm += char;
        }
    }

    if (currentTerm.trim().length > 0) {
        phrases.push(currentTerm.trim());
    }

    return phrases;
};

const hightlightStringMatch = (text: string, phrases: string[]) => {
    let highlightedText = text;
    phrases.forEach((phrase) => {
        highlightedText = highlightedText.replaceAll(RegExp(phrase, "ig"), (match) => `\\${match}/`);
    });

    return highlightedText
        .replaceAll("\\", `<span style="color: ${SEMANTIC_BLUE}; text-decoration: underline">`)
        .replaceAll("/", "</span>");
};

const renderSearchResults = (
    props: {
        title: string;
        description?: string;
        enum?: string[];
        matches?: [number[]];
    },
    phrasesInSearchTerm: string[],
) => {
    return (
        <div className="content">
            {props.title && (
                <div
                    className="title"
                    dangerouslySetInnerHTML={{ __html: hightlightStringMatch(props.title, phrasesInSearchTerm) }}
                ></div>
            )}
            {props.description && (
                <div
                    className="description"
                    dangerouslySetInnerHTML={{ __html: hightlightStringMatch(props.description, phrasesInSearchTerm) }}
                ></div>
            )}
            {props.enum && props.enum.length > 0 && (
                <div
                    className="description"
                    dangerouslySetInnerHTML={{
                        __html: hightlightStringMatch(`Options: ${props.enum.join(", ")}`, phrasesInSearchTerm),
                    }}
                ></div>
            )}
        </div>
    );
};

const HyperoptParametersEditor = () => {
    const [searchResults, setSearchResults] = useState<any[]>([]);
    const [searchValue, setSearchValue] = useState<string>("");
    const [isParameterHelpMessageVisible, setIsParameterHelpMessageVisible] = useState(true);
    const dispatch = useDispatch();
    const { config, hyperoptParameterSchema } = useConfigState();
    const hyperoptConfig = config?.hyperopt;

    const parameterOptions: HyperoptSearchParameterSchemaOption[] = useMemo(() => {
        let options = hyperoptParameterSchema?.static_options ? [...hyperoptParameterSchema.static_options] : [];

        const createFeatureOptions = (
            features?: CreateModelIOFeature[],
            featureTypeOptions?: HyperoptSearchParamFeatureSchema,
        ) => {
            const featureOptions: HyperoptSearchParameterSchemaOption[] = [];
            const featureTypes = new Set();
            const DEFAULTS = "defaults.";

            if (!Array.isArray(features) || !featureTypeOptions) return featureOptions;

            features.forEach((feature) => {
                featureTypes.add(feature.type);
                // @ts-ignore
                featureTypeOptions[feature.type].forEach((featureOption: HyperoptSearchParameterSchemaOption) => {
                    // @TODO Remove this once the following issue is fixed in Ludwig:
                    // https://github.com/ludwig-ai/ludwig/issues/2392
                    if (featureOption.value.toLowerCase().includes("encoder")) return;

                    // For each input/output feature, we need to provide the user with a list of
                    // Hyperopt parameters they can specify. For example, A user should be able to
                    // specify "category_feature.combiner" or "category.preprocessing.computed_fill_value"
                    // for a category feature.

                    // Since the input and output features are modified by the user in the UI, the
                    // FE has transform the feature type's options (text, category, binary, etc) to
                    // be specific to each feature.
                    featureOptions.push({
                        ...featureOption,
                        title: featureOption.title.replace(feature.type, feature.name),
                        value: featureOption.value.replace(feature.type, feature.name),
                    });
                });
            });

            featureTypes.forEach((featureType) => {
                // @ts-ignore
                featureTypeOptions[featureType].forEach((featureOption: HyperoptSearchParameterSchemaOption) => {
                    // @TODO Remove this once the following PR is merged:
                    // https://github.com/ludwig-ai/ludwig/pull/2355
                    if (featureOption.value.toLowerCase().includes("encoder")) return;

                    // Allow users to specify default for all features of a certain type.
                    // If this property is set at the feature level, it overrides this default.
                    featureOptions.push({
                        ...featureOption,
                        title: DEFAULTS + featureOption.title,
                        value: DEFAULTS + featureOption.value,
                    });
                });
            });

            return featureOptions;
        };

        return [
            ...options,
            ...createFeatureOptions(config?.input_features, hyperoptParameterSchema?.input_features),
            ...createFeatureOptions(config?.output_features, hyperoptParameterSchema?.output_features),
        ];
    }, [config, hyperoptParameterSchema]);

    const parameters: HyperoptParameterOption[] = useMemo(() => {
        const validParameters: HyperoptParameterOption[] = [];
        if (!hyperoptConfig?.parameters) return validParameters;

        Object.keys(hyperoptConfig?.parameters).forEach((parameter) => {
            const settings = parameterOptions.find((parameterOption) => parameterOption.value === parameter);

            if (!settings) return;

            validParameters.push({
                ...settings,
                config: hyperoptConfig?.parameters[parameter],
                title: parameter,
                value: parameter,
            });
        });

        return validParameters;
    }, [parameterOptions, hyperoptConfig?.parameters]);

    const searcher = useMemo(() => {
        const searchOptions = parameterOptions.map((option) => ({
            title: option.title,
            value: option.value,
            key: option.value,
            description: option.description,
            // @ts-ignore
            // Auto and Null are relevant for integers and floats.
            // The focus of the UI is to expose limited options a user
            // can select.
            enum: option.enum?.filter(
                (enumerator) => enumerator !== null && String(enumerator).toLowerCase() !== "auto",
            ),
            disabled: parameters.some((parameter) => parameter.value === option.value),
        }));

        return new Fuse(searchOptions, {
            keys: ["title", { name: "enum", weight: 2 }, { name: "description", weight: 3 }],
            includeMatches: true,
            threshold: 0.3,
        });
    }, [parameters, parameterOptions]);

    const handleSearchResultSelect = (event: SyntheticEvent, data: SearchResultData) => {
        // Disabled results have already been added as a
        // Hyperopt parameter:
        if (data.result.disabled) return false;

        const selection = data.result.value;
        // Add to list of select parameters
        const newParameter = parameterOptions.find(
            (parameter: HyperoptSearchParameterSchemaOption) => parameter.value === selection,
        );
        if (!newParameter) return;

        dispatch({
            type: "UPDATE_CONFIG_PROPERTY",
            field: `hyperopt.parameters['${newParameter.value}']`,
            value: {
                space: "choice",
                categories: newParameter?.fieldTypes.includes(FieldType.BOOLEAN) ? [true, false] : [],
            },
        });

        // Clear search field
        setSearchResults([]);
        setSearchValue("");

        // Dismiss parameters help message:
        setIsParameterHelpMessageVisible(false);

        // Focus on the last parameter added
        setTimeout(() => {
            // @ts-ignore
            document.querySelector(".parameters-options .parameter-option:last-child .parameter-option-legend").focus();
        }, 100);
    };

    const handleSearchChange = (event: SyntheticEvent, data: SearchProps) => {
        const searchTerm = data?.value ? data.value : "";
        setSearchValue(searchTerm);
        setSearchResults(
            searcher.search(searchTerm)?.map((searchResult) => ({
                ...searchResult.item,
                // @ts-ignore
                matches: searchResult?.matches[0]?.indices,
            })),
        );
    };

    const removeParameter = (parameterOption: string) => {
        dispatch({ type: "REMOVE_CONFIG_PROPERTY", field: `hyperopt.parameters['${parameterOption}']` });

        setIsParameterHelpMessageVisible(false);
    };

    const updateParameter = (parameterOption: string, parameter: HyperoptParameter) => {
        dispatch({
            type: "UPDATE_CONFIG_PROPERTY",
            field: `hyperopt.parameters['${parameterOption}']`,
            value: parameter,
        });
    };

    return (
        <Segment raised style={{ padding: "1.75rem" }}>
            <Header className="header" as="h3">
                Parameters
            </Header>
            <p style={{ marginBottom: "28px", color: SEMANTIC_GREY_DISABLED }}>
                Choose the parameters you'd like to optimize for and their corresponding search spaces. Ray supports a
                number of different search spaces depending on the type of parameter selected.{" "}
                <a
                    href="https://docs.ray.io/en/master/tune/api_docs/search_space.html#random-distributions-api"
                    target="_blank"
                    rel="noreferrer"
                >
                    See the full list of Ray search spaces.
                </a>
            </p>
            <Search
                className="full-width-square-search-results"
                style={{ marginBottom: "16px" }}
                fluid
                minCharacters={0}
                noResultsMessage=""
                noResultsDescription={
                    <span>
                        Type a specific <b>parameter</b> or <b>feature</b> to see available options, or type{" "}
                        <b>trainer</b> or <b>combiner</b> to see all params in that category.
                    </span>
                }
                input={{ icon: "search plus", iconPosition: "left" }}
                onResultSelect={handleSearchResultSelect}
                onSearchChange={handleSearchChange}
                resultRenderer={(props) => renderSearchResults(props, findPhrasesInSearchTerm(searchValue))}
                placeholder="Add parameters"
                scrolling="true"
                results={searchResults}
                value={searchValue}
            />
            <DismissibleMessage
                message="We've added a couple of commonly used parameter(s) by default! Feel free to tweak or remove them as you please."
                isVisible={isParameterHelpMessageVisible}
                setIsVisible={setIsParameterHelpMessageVisible}
            />
            <Form className="parameters-options">
                {parameters.length === 0 ? (
                    <Segment style={{ borderRadius: 0, minHeight: "10.571em" }} placeholder>
                        <Header style={{ display: "block", textAlign: "center" }} as="h4">
                            No parameters added.
                        </Header>
                        <p style={{ display: "block", textAlign: "center" }}>
                            You must have at least one parameter to perform Hyperopt. Search for a parameter above to
                            add it.
                        </p>
                    </Segment>
                ) : (
                    <Segment.Group style={{ borderRadius: 0 }}>
                        {parameters.map((parameter) => (
                            <ParameterOption
                                key={parameter.value}
                                // @ts-ignore
                                parameter={parameter}
                                updateParameter={updateParameter}
                                removeParameter={removeParameter}
                            />
                        ))}
                    </Segment.Group>
                )}
            </Form>
        </Segment>
    );
};

export default HyperoptParametersEditor;
