import ReactMarkdown from "react-markdown";
import { SEMANTIC_GREY_DISABLED } from "../utils/colors";
import "./Markdown.css";

type MarkdownProps = {
    children?: any;
    secondary?: boolean;
};

const descriptionTextStyling = {
    fontSize: `${12 / 14}rem`,
    lineHeight: `${18 / 14}rem`,
};

const Markdown = (props: MarkdownProps) => (
    <ReactMarkdown
        components={
            props.secondary
                ? {
                      p: ({ node, ...props }) => (
                          <p
                              style={{ marginBottom: "8px", color: SEMANTIC_GREY_DISABLED, ...descriptionTextStyling }}
                              {...props}
                          />
                      ),
                  }
                : undefined
        }
        children={props.children}
        className={`markdown-body${props.secondary ? " secondary" : ""}`}
    />
);

export default Markdown;
