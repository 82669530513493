import React from "react";
import { SemanticCOLORS } from "semantic-ui-react";
import { snakeToTitle } from "../utils/config";
import AzureDataConnector from "./connect/connectors/AzureDataConnector";
import BigQueryConnector from "./connect/connectors/BigQueryConnector";
import FileConnector from "./connect/connectors/FileConnector";
import GCSConnector from "./connect/connectors/GCSConnector";
import MySQLConnector from "./connect/connectors/MySQLConnector";
import PostgresConnector from "./connect/connectors/PostgresConnector";
import RedshiftConnector from "./connect/connectors/RedshiftConnector";
import S3Connector from "./connect/connectors/S3Connector";
import SnowflakeConnector from "./connect/connectors/SnowflakeConnector";

const connectors: Record<string, React.FC<ConnectorProps>> = {
    adls: AzureDataConnector,
    file: FileConnector,
    gcs: GCSConnector,
    mysql: MySQLConnector,
    postgresql: PostgresConnector,
    s3: S3Connector,
    snowflake: SnowflakeConnector,
    redshift: RedshiftConnector,
    bigquery: BigQueryConnector,
};

export function getConnector(connectorType: string) {
    return connectors[connectorType];
}

export function connectionDisplayTitle(connectionName: string) {
    if (connectionName === "file_uploads" || connectionName === "public_datasets") {
        return snakeToTitle(connectionName);
    }
    return connectionName;
}

export const dataStatusProps: Record<string, Record<string, SemanticCOLORS>> = {
    connecting: {
        color: "olive",
    },
    connected: {
        color: "green",
    },
    errored: {
        color: "red",
    },
    refreshing: {
        color: "yellow",
    },
};

export const getDataStatusColor = (status: string) => {
    return dataStatusProps[status]?.color || "grey";
};
