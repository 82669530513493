import { DeploymentStatus } from "../types/deployment/deploymentStatus";

export const isTerminalStatus = (status?: string) => {
    if (!status) {
        return false;
    }

    if (
        status === DeploymentStatus.ACTIVE ||
        status === DeploymentStatus.INACTIVE ||
        status === DeploymentStatus.FAILED
    ) {
        return true;
    }

    return false;
};

/**
 * Checks if the model version is the latest failed attempt.
 * We determine if a model version is a failed deployment attempt by checking
 * (1) if the deployment has a head version that is out of sync with its latest
 * version and (2) if the latest version is associated with this model version
 * @param deployment the deployment to check against
 * @param modelVersion the model version to check
 * @returns boolean indicating if the model version is the latest failed attempt
 */
export const isModelVersionLatestFailedAttempt = (deployment: ModelDeployment | undefined, modelVersion: number) => {
    return (
        deployment?.HeadVersion.ModelVersion !== modelVersion &&
        deployment?.LatestVersion.ModelVersion === modelVersion &&
        isTerminalStatus(deployment.Status)
    );
};
