const releaseEnvironment = () => {
    return process.env.REACT_APP_PREDIBASE_RELEASE_STAGE;
};

export const isStaging = () => {
    return releaseEnvironment() === "staging";
};

export const isProduction = () => {
    return releaseEnvironment() === "production";
};

export const isNotProduction = () => {
    return isStaging() || releaseEnvironment() === undefined;
};

export const isLocal = () => {
    return releaseEnvironment() === undefined;
};
