import type { JSONSchema7 } from "json-schema";
import { SyntheticEvent, useState } from "react";
import { CheckboxProps, Form } from "semantic-ui-react";
import { handleLocalState } from "../../../../models/create/forms/utils";
import { CurrentUser, UserContext } from "../../../../types/user";
import { SEMANTIC_RED_LIGHT } from "../../../../utils/colors";
import { snakeToTitle } from "../../../../utils/config";
import Markdown from "../../../Markdown";
import { Field, FieldTitle, ObjectField } from "../fields";
import { defaultTextStyling } from "../styling";
import { genericOfSchemaPath, getFieldType, getLudwigPath, getMatchingSchemaForValue } from "../utils";

export const RadioOf = (props: {
    selector: string;
    path: any;
    ofSchemaOptions: JSONSchema7[];
    level: number;
    title?: string;
    titlePrefix?: string;
    error: boolean;
    value: any;
    defaultValue: any;
    description?: string;
    config: any;
    setConfig: (path: string, typedValue: any) => void;
    localState: any;
    setLocalState: (localState: any, path: string) => void;
    invalidFields: any;
    schemaPath: string;
    featureIndex?: number;
    readOnly?: boolean;
    userContext?: UserContext | CurrentUser;
}) => {
    const {
        selector,
        ofSchemaOptions,
        title,
        titlePrefix,
        error,
        value,
        defaultValue,
        description,
        config,
        setConfig,
        localState,
        setLocalState,
        invalidFields,
        schemaPath,
        featureIndex,
        readOnly,
        userContext,
    } = props;
    const [selectedOptionSchema, setSelectedOptionSchema] = useState(getMatchingSchemaForValue(ofSchemaOptions, value));
    const radioStyle = {
        color: error ? SEMANTIC_RED_LIGHT : "inherit",
    };

    const getSchemaPathToSelectedOptionSchema = (selectedOptionSchema: JSONSchema7) => {
        return genericOfSchemaPath(
            selector,
            schemaPath,
            ofSchemaOptions.findIndex((ofSchema) => ofSchema.title === selectedOptionSchema.title),
        );
    };

    const getLabelFromSchema = (ofSchema?: JSONSchema7) => {
        if (ofSchema) {
            // @ts-expect-error(2339) yes, parameter_metadata does exist on the schema
            return snakeToTitle(ofSchema.parameter_metadata?.ui_display_name ?? ofSchema.title);
        }
        return "";
    };

    return (
        <>
            <FieldTitle title={title ? snakeToTitle(title) : undefined} prefix={titlePrefix} />
            <Markdown children={description} secondary={true} />

            <Form.Group key={props.schemaPath + "_radio_group"} grouped style={{ marginBottom: 0 }}>
                {ofSchemaOptions.map((ofSchema, idx) => {
                    return (
                        ofSchema.title && (
                            <Form.Field
                                key={
                                    props.schemaPath +
                                    "_radio_group_field" +
                                    ofSchema.title +
                                    "_" +
                                    ofSchema.type +
                                    "_" +
                                    idx
                                }
                            >
                                <Form.Radio
                                    disabled={readOnly}
                                    key={
                                        props.schemaPath +
                                        "_radio_option" +
                                        ofSchema.title +
                                        "_" +
                                        ofSchema.type +
                                        "_" +
                                        idx
                                    }
                                    label={<label style={radioStyle}>{getLabelFromSchema(ofSchema)}</label>}
                                    checked={selectedOptionSchema?.title === ofSchema.title}
                                    onChange={(event: SyntheticEvent, data: CheckboxProps) => {
                                        setSelectedOptionSchema(
                                            ofSchemaOptions.filter((option) => option.title === ofSchema.title)[0],
                                        );
                                        const path = getLudwigPath(
                                            // @ts-expect-error(2339) yes, parameter_metadata does exist on the schema
                                            ofSchema.parameter_metadata?.ludwig_path,
                                            featureIndex,
                                        );
                                        if (ofSchema.type === "null") {
                                            handleLocalState(path, null, setLocalState, setConfig);
                                        }
                                    }}
                                    error={
                                        error &&
                                        idx === 0 && {
                                            content: "Please choose one of the options",
                                            pointing: "left",
                                        }
                                    }
                                />
                            </Form.Field>
                        )
                    );
                })}
                {/* NOTE: defaultValue can be null, semantically different from undefined */}
                {defaultValue !== undefined ? (
                    <p
                        style={{ ...defaultTextStyling, marginLeft: "28px", marginTop: 0 }}
                    >{`Default: ${getLabelFromSchema(getMatchingSchemaForValue(ofSchemaOptions, defaultValue))}`}</p>
                ) : null}
            </Form.Group>

            {/* TODO(ksbrar): I really don't like this custom logic bro */}
            {selectedOptionSchema ? (
                selectedOptionSchema.type !== "object" && selectedOptionSchema.type !== "null" ? (
                    <Field
                        fieldType={getFieldType(selectedOptionSchema.type)}
                        schema={selectedOptionSchema}
                        config={config}
                        setConfig={setConfig}
                        localState={localState}
                        setLocalState={setLocalState}
                        invalidFields={invalidFields}
                        featureIndex={featureIndex}
                        // @ts-expect-error(2339) yes, parameter_metadata does exist on the schema
                        path={getLudwigPath(selectedOptionSchema.parameter_metadata?.ludwig_path, featureIndex)}
                        schemaPath={getSchemaPathToSelectedOptionSchema(selectedOptionSchema)}
                        readOnly={readOnly}
                        userContext={userContext}
                        // NOTE: If it's just a single field, hide it's individual title for a less cluttered UI:
                        hideTitle
                    />
                ) : (
                    <ObjectField
                        schema={selectedOptionSchema}
                        config={config}
                        setConfig={setConfig}
                        localState={localState}
                        setLocalState={setLocalState}
                        invalidFields={invalidFields}
                        featureIndex={featureIndex}
                        level={props.level + 1}
                        // @ts-expect-error(2339) yes, parameter_metadata does exist on the schema
                        path={getLudwigPath(selectedOptionSchema.parameter_metadata?.ludwig_path, featureIndex)}
                        schemaPath={schemaPath}
                        skipDivider={true}
                        readOnly={readOnly}
                        userContext={userContext}
                        hideTitle
                    />
                )
            ) : null}
        </>
    );
};
