import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { Button, Header, Loader, Message } from "semantic-ui-react";
import ResultsTable from "../../components/unsorted/results/ResultsTable";
import { track } from "../../metrics/june";
import { USER_STATE } from "../../state/global";
import { defaultDatasetPreviewParams } from "../data";
import { GET_DATASET_PREVIEW_QUERY_KEY, commonGetDatasetPreviewQueryOptions, useDatasetPreviewQuery } from "../query";

const DatasetPreviewer = (props: { dataset?: Dataset; previewSize?: number }) => {
    const [user] = useRecoilState(USER_STATE);
    const [refresh, setRefresh] = useState<boolean>(false);
    const previewSize = props.previewSize || defaultDatasetPreviewParams.previewSize;
    const params = { refresh, previewSize };
    const datasetID = props.dataset?.id;
    const queryClient = useQueryClient();

    const { status, data, error, isFetching } = useDatasetPreviewQuery(
        props.dataset?.id ?? -1,
        params,
        commonGetDatasetPreviewQueryOptions(props.dataset),
    );

    if (props.dataset === undefined) {
        return null;
    }

    if (status === "pending" || isFetching) {
        return (
            <div style={{ textAlign: "center", marginTop: "4em", marginBottom: "4em" }}>
                <Loader active inline>
                    Fetching Data
                </Loader>
            </div>
        );
    }

    if (status === "error") {
        return <Message error>{error.message}</Message>;
    }

    if (typeof data === "undefined") {
        return <Message error>Unable to load preview.</Message>;
    }

    return (
        <>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
                <Header> Previewing {previewSize} rows</Header>
                <Button
                    onClick={() => {
                        setRefresh(true);
                        queryClient.invalidateQueries({
                            queryKey: GET_DATASET_PREVIEW_QUERY_KEY(props.dataset?.id!),
                        });
                        user &&
                            track(user, "dataset_previewer_shuffle", {
                                datasetId: datasetID,
                            });
                    }}
                >
                    <FontAwesomeIcon icon={"shuffle"} style={{ marginRight: "4px" }} /> Shuffle
                </Button>
            </div>
            <div className="tableContainer">
                <ResultsTable
                    query={undefined}
                    datasetID={props.dataset.id}
                    // Legacy datasets may not have dataset profile attributes.
                    datasetProfile={data?.datasetProfile ? data.datasetProfile : null}
                    dataset={data.samples}
                    // Set flags to false for all cells.
                    flags={(data.samples as ResultsDataset).data?.map((row: any[], rowIndex: number) => {
                        return row.map((value, valueIndex) => {
                            return 0;
                        });
                    })}
                />
            </div>
        </>
    );
};

export default DatasetPreviewer;
