import { useEffect, useState } from "react";
import { Popup } from "semantic-ui-react";
import dayjsExtended from "../utils/dayjs";

const LiveTimeAgo = (props: { fromDate: Date; color?: string }) => {
    const { fromDate, color } = props;
    const [timeAgo, setTimeAgo] = useState(dayjsExtended(fromDate).fromNow());

    // Refreshes the timer every 60 seconds:
    useEffect(() => {
        const interval = setInterval(() => {
            setTimeAgo(dayjsExtended(fromDate).fromNow());
        }, 60 * 1000); //set your time here. repeat every 5 seconds
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Popup
            className={"transition-scale"}
            position={"top center"}
            content={dayjsExtended(fromDate).format("YYYY MMMM DD, h:mm a")}
            trigger={<time style={{ color }}>{timeAgo}</time>}
        />
    );
};

export default LiveTimeAgo;
