import { Popup } from "semantic-ui-react";

interface TextProps {
    text: string;
    popoverText?: string;
}

const TruncatedText = (props: TextProps) => {
    const { text, popoverText } = props;

    return (
        <Popup
            className="transition-scale"
            position="right center"
            trigger={
                <p style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", lineHeight: "1rem" }}>
                    {text}
                </p>
            }
            // Use explicit popover text if defined, else use text
            content={popoverText ? popoverText : text}
        />
    );
};

export default TruncatedText;
