import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { Button, Form, Header, Message, Popup } from "semantic-ui-react";
import { getTraceId } from "../api/trace";
import { Auth0TokenOptions, useAuth0TokenOptions } from "../data";
import metrics from "../metrics/metrics";
import { createV1APIServer, redirectIfSessionInvalid } from "../utils/api";
import { getErrorMessage } from "../utils/errors";

const saveEditLMAO = async (endpoint: string, request: any, auth0TokenOptions?: Auth0TokenOptions) => {
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .put(endpoint, request, {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "PUT",
                endpoint,
                request,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

// TODO: omg...
const EditHeader = (props: {
    ogText: string;
    img?: JSX.Element;
    ogLabel?: JSX.Element;
    headerStyle?: any;
    triggerStyle?: any;
    inputStyle?: any;
    placeholder?: string;
    endpoint: string;
    position?: string;
    textToRequest: (x: string) => any;
    callback?: Function;
}) => {
    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    // Local state:
    const [popupOpen, setPopupOpen] = useState(false);
    const [inEdit, setInEdit] = useState(false);
    const [text, setText] = useState(props.ogText || "");
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const { mutate: saveEdit } = useMutation({
        mutationFn: () => saveEditLMAO(props.endpoint, props.textToRequest(text), auth0TokenOptions),
        onSuccess: () => {
            props.callback?.();
            setText(text);
            setErrorMessage(null);
            setInEdit(false);
        },
        onError: (error) => {
            setErrorMessage(getErrorMessage(error));
        },
    });

    if (!inEdit) {
        return (
            <Header
                as={"h2"}
                style={props.headerStyle}
                className={"header"}
                onMouseEnter={() => setPopupOpen(true)}
                onMouseLeave={() => setPopupOpen(false)}
            >
                {props.img ? <>{props.img}&ensp;</> : null}
                <Popup
                    basic
                    open={popupOpen}
                    style={{ padding: "0px", border: "none", boxShadow: "none", background: "none" }}
                    hoverable
                    offset={[0, -10]}
                    className={"transition-scale"}
                    position={props.ogText ? "right center" : "right center"}
                    trigger={props.ogLabel || <span style={props.triggerStyle}>{props.ogText}</span>}
                    content={
                        <Button
                            icon={"pencil"}
                            size={"medium"}
                            basic
                            style={{ boxShadow: "none" }}
                            onClick={() => setInEdit(true)}
                        />
                    }
                />
            </Header>
        );
    }

    return (
        <div style={{ display: "flex", paddingBottom: "5px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <Form style={{ width: "80%" }} onSubmit={() => saveEdit()}>
                    <Form.Input
                        style={props.inputStyle}
                        placeholder={props.placeholder}
                        value={text}
                        onChange={(event, data) => setText(data.value)}
                    />
                </Form>
            </div>
            &emsp;
            <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                    className={"no-outline"}
                    icon={"check"}
                    color={"blue"}
                    size={"mini"}
                    basic
                    circular
                    onClick={() => {
                        saveEdit();
                    }}
                />
                <Button
                    className={"no-outline"}
                    icon={"cancel"}
                    color={"grey"}
                    size={"mini"}
                    basic
                    circular
                    onClick={() => {
                        setText(props.ogText);
                        setInEdit(false);
                    }}
                />
            </div>
            {errorMessage ? <Message negative>{errorMessage}</Message> : null}
        </div>
    );
};

export default EditHeader;
