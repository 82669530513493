import { useMutation } from "@tanstack/react-query";
import { CSSProperties, useState } from "react";
import { Button, Form, Message, Popup, SemanticSIZES, Table } from "semantic-ui-react";
import { getTraceId } from "../api/trace";
import { Auth0TokenOptions, useAuth0TokenOptions } from "../data";
import metrics from "../metrics/metrics";
import { createV1APIServer, redirectIfSessionInvalid } from "../utils/api";
import { getErrorMessage } from "../utils/errors";
import GrayDash from "./GrayDash";

const saveEditLMAO = async (endpoint: string, request: any, auth0TokenOptions?: Auth0TokenOptions) => {
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .put(endpoint, request, {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "PUT",
                endpoint,
                request,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

// TODO: omg...
const EditDiv = (props: {
    hideClose?: boolean;
    defaultEdit?: boolean;
    ogText: string;
    ogLabel?: JSX.Element;
    emptyLabel?: JSX.Element;
    buttonSize?: SemanticSIZES;
    fitted?: boolean;
    placeholder?: string;
    endpoint?: string;
    setStateFunc?: Function;
    exitEditFunc?: Function;
    position?: string;
    textToRequest?: (x: string) => any;
    callback?: Function;
    asTableCell?: boolean;
    tableStyle?: CSSProperties;
    inputMinWidth?: string;
}) => {
    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    // Local state:
    const [popupOpen, setPopupOpen] = useState(false);
    const [inEdit, setInEdit] = useState(props.defaultEdit || false);
    const [text, setText] = useState(props.ogText || "");
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const { mutate: mutateSaveEdit } = useMutation({
        mutationFn: () => saveEditLMAO(props.endpoint!, props.textToRequest!(text), auth0TokenOptions),
        onSuccess: () => {
            props.callback?.();
            setText(text);
            setErrorMessage(null);
            setInEdit(false);
        },
        onError: (error) => {
            setErrorMessage(getErrorMessage(error));
        },
    });

    const saveEdit = () => {
        if (props.setStateFunc) {
            props.setStateFunc(text);
            setInEdit(false);
        }

        if (props.endpoint && props.textToRequest) {
            mutateSaveEdit();
        }
    };

    if (!inEdit) {
        let trigger = null;
        if (props.ogLabel) {
            trigger = props.ogLabel;
        } else if (props.ogText) {
            trigger = <span>{props.ogText}</span>;
        } else if (props.emptyLabel) {
            trigger = props.emptyLabel;
        } else {
            trigger = <GrayDash />;
        }

        const popup = (
            <Popup
                basic
                open={popupOpen}
                style={{ padding: "0px", border: "none", boxShadow: "none", background: "none" }}
                offset={[0, -10]}
                hoverable
                className={"transition-scale"}
                position={"right center"}
                trigger={trigger}
                content={
                    <Button
                        icon={"pencil"}
                        size={props.buttonSize || "medium"}
                        className={"no-background"}
                        basic
                        style={{ boxShadow: "none", background: "none" }}
                        onClick={() => setInEdit(true)}
                    />
                }
            />
        );

        if (props.asTableCell) {
            return (
                <Table.Cell
                    style={props.tableStyle}
                    onMouseEnter={() => setPopupOpen(true)}
                    onMouseLeave={() => setPopupOpen(false)}
                >
                    {popup}
                </Table.Cell>
            );
        }

        return (
            <div
                onMouseEnter={() => setPopupOpen(true)}
                onMouseLeave={() => setPopupOpen(false)}
                style={{ width: "100%" }}
            >
                {popup}
            </div>
        );
    }

    let Buttons = null;
    if (props.hideClose) {
        Buttons = (
            <Button
                className={"no-outline"}
                icon={"check"}
                color={"blue"}
                size={"mini"}
                basic
                circular
                onClick={() => {
                    saveEdit();
                }}
            />
        );
    } else {
        Buttons = (
            <>
                <Button
                    className={"no-outline"}
                    icon={"check"}
                    color={"blue"}
                    size={"mini"}
                    basic
                    circular
                    onClick={() => {
                        saveEdit();
                    }}
                />
                <Button
                    className={"no-outline"}
                    icon={"cancel"}
                    color={"grey"}
                    size={"mini"}
                    basic
                    circular
                    onClick={() => {
                        setText(props.ogText);
                        setInEdit(false);
                        props.exitEditFunc?.();
                    }}
                />
            </>
        );
    }

    const editContent = props.fitted ? (
        <div style={{ display: "flex", alignItems: "center" }}>
            <Form style={{ width: "80%", minWidth: props.inputMinWidth }} onSubmit={saveEdit}>
                <Form.Input
                    autoFocus
                    // autoFocus={props.autofocus}
                    placeholder={props.placeholder}
                    value={text}
                    onChange={(event, data) => setText(data.value)}
                />
            </Form>
            &emsp;
            {Buttons}
            {errorMessage ? <Message negative>{errorMessage}</Message> : null}
        </div>
    ) : (
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div style={{ display: "flex", flexGrow: "1", justifyContent: "flex-start" }}>
                <Form onSubmit={saveEdit} style={{ marginRight: "8px", width: "100%" }}>
                    <Form.Input
                        autoFocus
                        placeholder={props.placeholder}
                        value={text}
                        onChange={(event, data) => setText(data.value)}
                        style={{ marginRight: "8px" }}
                    />
                </Form>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>{Buttons}</div>
            {errorMessage ? <Message negative>{errorMessage}</Message> : null}
        </div>
    );

    if (props.asTableCell) {
        return <Table.Cell style={props.tableStyle}>{editContent}</Table.Cell>;
    }

    return editContent;
};

export default EditDiv;
