import React from "react";
import { useRecoilState } from "recoil";
import { Checkbox, CheckboxProps, Popup } from "semantic-ui-react";

import metrics from "../metrics/metrics";
import { USER_STATE } from "../state/global";

function EngineCheckbox(props: {
    checked?: boolean;
    disabled?: boolean;
    onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, data: CheckboxProps) => void;
}) {
    const { checked, disabled, onClick } = props;
    const [userContext] = useRecoilState(USER_STATE);

    return userContext?.isExpired ? (
        <Popup
            content={
                <p>
                    Your trial has ended. <a href="https://predibase.com/contact-us">Contact us to upgrade.</a>
                </p>
            }
            position="right center"
            flowing={true}
            hoverable={true}
            trigger={<Checkbox slider className={metrics.BLOCK_AUTO_CAPTURE} checked={checked} disabled={true} />}
        />
    ) : (
        <Checkbox
            slider
            className={metrics.BLOCK_AUTO_CAPTURE}
            checked={checked}
            disabled={disabled}
            onClick={onClick}
        />
    );
}

export default EngineCheckbox;
