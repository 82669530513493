export const FORM_LABELS: {
    [key: string]: {
        label: string;
        priority: number;
        icon?: string;
    };
} = {
    to_verify: {
        label: "Email",
        priority: 100,
    },
    csrf_token: {
        label: "",
        priority: 100,
    },
    identifier: {
        label: "Email Address",
        icon: "user",
        priority: 90,
    },
    "traits.email": {
        label: "Email",
        icon: "mail",
        priority: 88,
    },
    email: {
        label: "Email",
        icon: "mail",
        priority: 87,
    },
    password: {
        label: "Password",
        icon: "lock",
        priority: 86,
    },
    "traits.username": {
        label: "Username",
        icon: "user",
        priority: 85,
    },
    "traits.name.first": {
        label: "First name",
        icon: "user",
        priority: 76,
    },
    "traits.name.last": {
        label: "Last name",
        icon: "user",
        priority: 75,
    },
    "traits.code": {
        label: "Registration code",
        icon: "qrcode",
        priority: 70,
    },
};

export const FORM_TYPE_PASSWORD = "password";
export const FORM_TYPE_OIDC = "oidc";
export const FORM_TYPE_LINK = "link";
