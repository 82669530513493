import { Form } from "semantic-ui-react";
import { SEMANTIC_RED_LIGHT } from "../utils/colors";
import Markdown from "./Markdown";
import { FieldTitle } from "./forms/json-schema-form/fields/markup/FieldTitle";

const RadioGroup = (props: {
    path: string;
    label?: string;
    description?: string;
    error: boolean;
    value: boolean;
    onChange: (checked?: boolean) => void;
    readOnly?: boolean;
}) => {
    const radioStyle = {
        color: props.error ? SEMANTIC_RED_LIGHT : "inherit",
    };

    return (
        <Form.Group key={props.path + "_radio_group"} grouped style={{ marginBottom: 0 }}>
            {props.label && <FieldTitle title={props.label} />}
            {props.description && <Markdown children={props.description} secondary={true} />}
            <Form.Field>
                <Form.Radio
                    disabled={props.readOnly}
                    label={<label style={radioStyle}>True</label>}
                    value="true"
                    checked={!props.error && props.value === true}
                    onChange={() => props.onChange(true)}
                    error={
                        props.error && {
                            content: "Please choose either true or false.",
                            pointing: "left",
                        }
                    }
                />
            </Form.Field>
            <Form.Field>
                <Form.Radio
                    disabled={props.readOnly}
                    label={<label style={radioStyle}>False</label>}
                    value="false"
                    checked={!props.error && props.value === false}
                    onChange={() => props.onChange(false)}
                />
            </Form.Field>
        </Form.Group>
    );
};

export default RadioGroup;
