import React from "react";
import { Icon, Popup, Table } from "semantic-ui-react";
import { SEMANTIC_GREY } from "../utils/colors";

export const GetEngineNodeReplicasSpan = (group: EngineNodeGroup): JSX.Element => {
    if (group.minReplicas === group.maxReplicas) {
        return <span>{group.replicas}</span>;
    }
    return (
        <span>
            {group.replicas}{" "}
            <span style={{ color: SEMANTIC_GREY }}>
                ({group.minReplicas} → {group.maxReplicas})
            </span>
        </span>
    );
};

const EngineNodeRow = (group: EngineNodeGroup, key: any, isWorker: boolean, index: number, filteredCloud?: string) => {
    const workerBorderStyle = { borderTop: isWorker && index > 0 ? "none" : undefined };
    return Object.keys(group.nodeType.clouds || {})
        .filter((cloud) => (filteredCloud ? cloud === filteredCloud : true))
        .map((cloud) => {
            return (
                <React.Fragment key={"fragment-" + key}>
                    <Table.Row key={key}>
                        <Table.Cell style={workerBorderStyle}>
                            {isWorker ? (index === 0 ? "workers" : null) : "head"}
                        </Table.Cell>
                        <Table.Cell style={workerBorderStyle}>{GetEngineNodeReplicasSpan(group)}</Table.Cell>
                        <Table.Cell style={workerBorderStyle}>
                            {group.nodeType.clouds[cloud].publicLabel ||
                                group.nodeType.clouds[cloud].label ||
                                group.nodeType.name}
                        </Table.Cell>
                        <Table.Cell style={workerBorderStyle}>{group.nodeType.clouds[cloud].gpu}</Table.Cell>
                        <Table.Cell style={workerBorderStyle}>{group.nodeType.clouds[cloud].cpu}</Table.Cell>
                        <Table.Cell style={workerBorderStyle}>{group.nodeType.clouds[cloud].memory}</Table.Cell>
                        <Table.Cell style={workerBorderStyle}>{group.nodeType.clouds[cloud].storage}</Table.Cell>
                    </Table.Row>
                </React.Fragment>
            );
        });
};

function EngineNodeTable(props: { template: EngineTemplate; filteredCloud?: string }) {
    return (
        <Table basic={"very"} compact>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell>
                        Quantity &nbsp;
                        <Popup
                            className="transition-scale"
                            hoverable
                            position={"right center"}
                            trigger={<Icon name={"question circle"} color={"blue"} />}
                            content={
                                <span>
                                    Workers that include a range automatically scale capacity from{" "}
                                    <span style={{ color: SEMANTIC_GREY }}>(minReplicas → maxReplicas)</span> based on
                                    load.
                                </span>
                            }
                        />
                    </Table.HeaderCell>
                    <Table.HeaderCell>Type</Table.HeaderCell>
                    <Table.HeaderCell>GPU</Table.HeaderCell>
                    <Table.HeaderCell>CPU</Table.HeaderCell>
                    <Table.HeaderCell>Memory</Table.HeaderCell>
                    <Table.HeaderCell>Storage</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {EngineNodeRow(props.template.headNodeGroup, "head", false, 0, props.filteredCloud)}
                {props.template.workerNodeGroups
                    ?.filter((x) => x.maxReplicas !== 0)
                    .map((group, index) => EngineNodeRow(group, index, true, index, props.filteredCloud))}
            </Table.Body>
        </Table>
    );
}

export default EngineNodeTable;
