import { memo } from "react";
import { ReactFlowProvider } from "react-flow-renderer";
import ModelProgressFlow from "./ModelProgressFlow";

function ModelProgressView(props: { model: Model; timeline?: ModelTimeline }) {
    return (
        <div
            style={{
                height: "15vh",
                minHeight: "120px",
                background: "white",
                border: "1px solid rgba(34,36,38,.15)",
                borderRadius: "4px",
            }}
        >
            <ReactFlowProvider>
                <ModelProgressFlow model={props.model} timeline={props.timeline} />
            </ReactFlowProvider>
        </div>
    );
}

export default memo(ModelProgressView);
