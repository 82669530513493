import { AxiosInstance } from "axios";
import React, { useCallback, useEffect, useState } from "react";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer-continued";
import { Button, Form, Label } from "semantic-ui-react";
import { DropdownItemProps } from "semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem";
import CopyButton from "../../components/CopyButton";
import InfoMessage from "../../components/InfoMessage";
import LiveTimeAgo from "../../components/LiveTimeAgo";
import { getStatusChipForModelsAndQueries } from "../../components/statusChip";
import { useAuth0TokenOptions } from "../../data";
import { createV1APIServer, redirectIfSessionInvalid } from "../../utils/api";
import { SEMANTIC_GREY } from "../../utils/colors";
import { toYAML } from "../../utils/config";
import { getErrorMessage } from "../../utils/errors";

const modelLineageSearch = (options: DropdownItemProps[], value: string) => {
    return options.filter(
        (x) =>
            x.rawtext.toLowerCase().includes(value.toLowerCase()) ||
            x.modelDescription.toLowerCase().includes(value.toLowerCase()) ||
            x.author.toLowerCase().includes(value.toLowerCase()),
    );
};

function ModelDiffView(props: {
    isRepo?: boolean;
    selectedModels: (ModelDiffNode | Model | null)[];

    // Diff from ModelRepoView uses minimal ModelDiffNodes[]
    setModelDiffs?: React.Dispatch<React.SetStateAction<(ModelDiffNode | null)[]>>;

    // Diff from ModelCompareView uses full Model[]
    setModels?: React.Dispatch<React.SetStateAction<(Model | null)[]>>;
    allModels?: Model[];

    options: ModelLineageNode[];
}) {
    const [firstModel, setFirstModel] = useState<ModelRepo | Model | null>(null);
    const getFirstYAML = useCallback(
        () => toYAML(props.isRepo ? (firstModel as ModelRepo)?.latestConfig : (firstModel as Model)?.config),
        [firstModel],
    );

    const [secondModel, setSecondModel] = useState<ModelRepo | Model | null>(null);
    const getSecondYAML = useCallback(
        () => toYAML(props.isRepo ? (secondModel as ModelRepo)?.latestConfig : (secondModel as Model)?.config),
        [secondModel],
    );

    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    const [apiServer, setAPIServer] = useState<AxiosInstance | null>(null);
    useEffect(() => {
        const getAPIServer = async () => {
            const v1APIServer = await createV1APIServer(auth0TokenOptions);
            // NOTE: Whoever wrote the axios typings is a moron because the return type of axios.create is not
            // AxiosInstance -- it's a wrap function. And React will see that and treat it as a callback that
            // setState should directly call. FML.
            // See: [1], [2]:
            // [1]: https://github.com/axios/axios/issues/4365
            // [2]: https://stackoverflow.com/questions/64427195/calling-setstate-will-execute-the-function-value-instead-of-passing-it
            setAPIServer(() => v1APIServer);
        };
        getAPIServer();
    }, []);

    // const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        if (props.selectedModels[0]?.id) {
            fetchConfig(props.selectedModels[0]?.id, setFirstModel);
        } else {
            setFirstModel(null);
        }
    }, [props.selectedModels[0]?.id]);

    useEffect(() => {
        if (props.selectedModels[1]?.id) {
            fetchConfig(props.selectedModels[1]?.id, setSecondModel);
        } else {
            setSecondModel(null);
        }
    }, [props.selectedModels[1]?.id]);

    const fetchConfig = (
        id: string | number,
        setRepo: React.Dispatch<React.SetStateAction<ModelRepo | Model | null>>,
    ) => {
        if (props.isRepo) {
            apiServer
                ?.get("models/repo/" + id)
                .then((res) => {
                    setRepo(res.data.modelRepo);
                })
                .catch((error) => {
                    const errorMsg = getErrorMessage(error) ?? "";
                    redirectIfSessionInvalid(errorMsg);
                    // setErrorMessage(errorMsg);
                });
        } else {
            apiServer
                ?.get("models/version/" + id)
                .then((res) => {
                    setRepo(res.data.modelVersion);
                })
                .catch((error) => {
                    const errorMsg = getErrorMessage(error) ?? "";
                    redirectIfSessionInvalid(errorMsg);
                    // setErrorMessage(errorMsg);
                });
        }
    };

    const dropdownOptions = props.options.map((x) => {
        return {
            key: x.id,
            text: (
                <span>
                    {x.data.name}&ensp;<span style={{ color: SEMANTIC_GREY }}>{x.data.description}</span>
                </span>
            ),
            rawtext: x.data.name,
            modelDescription: x.data.description,
            description: (
                <span>
                    {x.data.author} (
                    <span style={{ fontSize: "0.9em" }}>
                        <LiveTimeAgo fromDate={new Date(x.data.updated || x.data.created)} />
                    </span>
                    )
                </span>
            ),
            value: x.id,
            author: x.data.author,
        };
    });

    const getTitle = (config: any, first = false) => {
        if (config) {
            const idx = first ? 0 : 1;
            const author = props.setModelDiffs
                ? (props.selectedModels[idx] as ModelDiffNode)?.author
                : (props.selectedModels[idx] as Model)?.user?.username;
            return (
                <div style={{ textAlign: "center", height: "100%", display: "inline-flex", verticalAlign: "middle" }}>
                    {config.status ? getStatusChipForModelsAndQueries(config.status, `${120 / 14}rem`) : null}
                    <Label color={"blue"}>
                        Author: <span style={{ fontWeight: "normal" }}>{author}</span>
                    </Label>
                    <Label color={"purple"}>
                        Created:{" "}
                        <span style={{ fontWeight: "normal" }}>
                            <LiveTimeAgo fromDate={new Date(config.created)} />
                        </span>
                    </Label>
                </div>
            );
        }
        return null;
    };

    const firstYAML = getFirstYAML();
    const secondYAML = getSecondYAML();

    const getDiffLink = (firstModel: Model, secondModel: Model) =>
        window.origin.toString() + `/models/repo/${firstModel.repoID}/diff/${firstModel.id}/${secondModel.id}`;

    const header = (
        <div
            style={{
                display: "flex",
                paddingBottom: "10px",
                position: "sticky",
                backgroundColor: "white",
                zIndex: 1,
                borderBottom: "1px solid rgb(228 230 230)",
                top: 0,
            }}
        >
            <div
                style={{ flex: 1, display: "flex", alignSelf: "center", alignItems: "center", flexDirection: "column" }}
            >
                <div style={{ width: "100%", paddingLeft: "40px", paddingRight: "40px" }}>
                    <Form.Select
                        options={dropdownOptions}
                        search={modelLineageSearch}
                        style={{ width: "100%", marginBottom: "10px" }}
                        onChange={(event, data) => {
                            const selected = dropdownOptions.find((x) => x.value === data.value);
                            if (props.setModelDiffs) {
                                const newDiff = {
                                    id: selected?.value,
                                    name: selected?.rawtext,
                                    author: selected?.author,
                                } as ModelDiffNode;
                                props.setModelDiffs((x) => [newDiff, x[1]]);
                            } else {
                                const newModel = props.allModels!.find((x) => x.id === Number(selected?.value));
                                props.setModels!((x) => [newModel || null, x[1]]);
                            }
                        }}
                        value={String(props.selectedModels[0]?.id) || ""}
                    />
                </div>
                {getTitle(firstModel, true)}
            </div>
            <div style={{ display: "flex", justifyContent: "center", alignSelf: "top", flexDirection: "column" }}>
                <Button
                    icon={"arrows alternate horizontal"}
                    basic
                    style={{ height: "100%" }}
                    onClick={() =>
                        props.setModelDiffs
                            ? props.setModelDiffs((x) => [x[1], x[0]])
                            : props.setModels!((x) => [x[1], x[0]])
                    }
                />
                {firstModel && secondModel && (
                    <CopyButton
                        text={getDiffLink(firstModel as Model, secondModel as Model)}
                        copyText={"Copy link"}
                        icon={"linkify"}
                        style={{ height: "100%" }}
                    />
                )}
            </div>
            <div style={{ flex: 1, display: "flex", alignItems: "center", flexDirection: "column" }}>
                <div style={{ width: "100%", paddingLeft: "40px", paddingRight: "40px" }}>
                    <Form.Select
                        options={dropdownOptions}
                        search={modelLineageSearch}
                        style={{ width: "100%", marginBottom: "10px" }}
                        onChange={(event, data) => {
                            const selected = dropdownOptions.find((x) => x.value === data.value);
                            if (props.setModelDiffs) {
                                const newDiff = {
                                    id: selected?.value,
                                    name: selected?.rawtext,
                                    author: selected?.author,
                                } as ModelDiffNode;
                                props.setModelDiffs((x) => [x[0], newDiff]);
                            } else {
                                const newModel = props.allModels!.find((x) => x.id === Number(selected?.value));
                                props.setModels!((x) => [x[0], newModel || null]);
                            }
                        }}
                        value={String(props.selectedModels[1]?.id) || ""}
                    />
                </div>
                {getTitle(secondModel, false)}
            </div>
        </div>
    );

    return (
        <div style={{ width: "100%", height: "80vh", overflow: "auto" }}>
            {header}
            <div>
                {firstYAML || secondYAML ? (
                    <ReactDiffViewer
                        oldValue={firstYAML ? firstYAML : ""}
                        newValue={secondYAML ? secondYAML : ""}
                        compareMethod={DiffMethod.LINES}
                        showDiffOnly={false}
                        splitView={true}
                    />
                ) : (
                    <InfoMessage header={"Select models to compare from dropdown"} />
                )}
            </div>
        </div>
    );
}

export default ModelDiffView;
