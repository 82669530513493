import { useState } from "react";
import { Form } from "semantic-ui-react";
import { SingularField } from "../../../components/StackedForm";

import ConnectorPanel from "../ConnectorPanel";
import { Connector, CreateConnectionEndpoint } from "../util";
import { getAlreadyConnectedMessage } from "./util";

function getName(url: string) {
    const filename = url.substring(url.lastIndexOf("/") + 1);
    // remove any extensions
    const basename = filename.replace(/\.[^/.]+$/, "");
    return basename;
}

function S3Connector(props: ConnectorProps) {
    const [connectionName, setConnectionName] = useState(
        props.connectorConfig.connectionName ? props.connectorConfig.connectionName : "",
    );
    const [key, setKey] = useState(props.connectorConfig.key ? props.connectorConfig.key : "");
    const [secret, setSecret] = useState(props.connectorConfig.secret ? props.connectorConfig.secret : "");

    const alreadyConnected = props.connection !== undefined;

    const reset = () => {
        setConnectionName("");
        setKey("");
        setSecret("");
    };

    const getAddRequest = () => {
        const config = {
            protocol: "s3",
            key: key.trim(),
            secret: secret.trim(),
        };

        return {
            endpoint: CreateConnectionEndpoint,
            body: {
                connection: {
                    type: "s3",
                    name: connectionName.trim(),
                },
                secret: {
                    secret: config,
                },
            },
            headers: {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            },
        };
    };

    const getUpdateRequest = () => {
        const config = {
            protocol: "s3",
            key: key,
            secret: secret,
        };

        return {
            endpoint: "sources/update",
            body: {
                connection: {
                    id: props.connection!.id,
                    type: props.connection!.type,
                    name: connectionName,
                },
                secret: {
                    secret: config,
                },
            },
            headers: {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            },
        };
    };

    const isDisabled = () => {
        return !key || !secret || !connectionName;
    };

    const getModalContent = () => {
        return (
            <>
                {getAlreadyConnectedMessage(alreadyConnected, !props.isEditView, props.onStepChange)}
                <Form>
                    <SingularField
                        autoFocus={true}
                        disabled={alreadyConnected && !props.isEditView}
                        name="name"
                        placeholder="s3_datasets"
                        value={connectionName}
                        setState={setConnectionName}
                        description="Choose a name for your connection in Predibase"
                        label="Connection Name"
                    />
                    <SingularField
                        disabled={alreadyConnected && !props.isEditView}
                        name="key"
                        label="AWS Access Key ID"
                        value={key}
                        setState={setKey}
                        description={
                            <>
                                Both the Access Key and Secret Access key are associated with your&nbsp;
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://docs.aws.amazon.com/general/latest/gr/aws-sec-cred-types.html#access-keys-and-secret-access-keys"
                                >
                                    IAM role
                                </a>
                            </>
                        }
                    />
                    <SingularField
                        autoComplete="off"
                        disabled={alreadyConnected && !props.isEditView}
                        name="secret"
                        label="AWS Secret Access Key ID"
                        value={secret}
                        setState={setSecret}
                        description={
                            <>
                                Both the Access Key and Secret Access key are associated with your&nbsp;
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://docs.aws.amazon.com/general/latest/gr/aws-sec-cred-types.html"
                                >
                                    IAM role
                                </a>
                            </>
                        }
                    />
                </Form>
            </>
        );
    };

    return (
        <ConnectorPanel
            modalHeader="Connect to Amazon S3"
            header="Amazon S3"
            editModalHeader="Edit Amazon S3 Connection"
            submitLabel="Connect"
            name={Connector.S3}
            errorHeader="Error in Amazon S3 connection"
            isDisabled={isDisabled}
            getModalContent={getModalContent}
            getAddRequest={getAddRequest}
            getUpdateRequest={getUpdateRequest}
            reset={reset}
            {...props}
        />
    );
}

export default S3Connector;
