export const capitalize = (s: any, restLowercase?: boolean) => {
    if (typeof s !== "string") return "";
    const rest = restLowercase ? s.slice(1).toLowerCase() : s.slice(1);
    return s.charAt(0).toUpperCase() + rest;
};

export const isJSONString = (v: any) => {
    if (typeof v !== "string") {
        return false;
    }
    try {
        JSON.parse(v);
    } catch (e) {
        return false;
    }
    return true;
};

export const templateVariableRegex = /{(.*?)}/g;

export const centsToDollars = (cents: number) =>
    (cents / 100).toLocaleString("en-US", { style: "currency", currency: "USD" });
