import { AxiosInstance, AxiosResponse } from "axios";
import React, { useMemo } from "react";
import { ModelStatus } from "../../types/model/modelStatus";
import { getErrorMessage } from "../../utils/errors";
import { alphabeticalCollator } from "../../utils/sort";
import { modelStatusFilter } from "../util";

export enum VizType {
    CONFUSION_MATRIX = "confusion_matrix",
    ROC_CURVES_FROM_TEST_STATISTICS = "roc_curves_from_test_statistics",
    PRECISION_RECALL_CURVES_FROM_TEST_STATISTICS = "precision_recall_curves_from_test_statistics",
    FREQUENCY_VS_F1 = "frequency_vs_f1",
    CALIBRATION_1_VS_ALL = "calibration_1_vs_all",
    HYPEROPT_REPORT = "hyperopt_report",
    EVALUATION_STATISTICS = "evaluation_statistics",
}

export const UseOutputFeaturesForSelectedModels = (
    target: string,
    setTarget: React.Dispatch<React.SetStateAction<string>>,
    selectedModels: (Model | null)[],
) => {
    return useMemo(() => {
        const res = Array.from(
            new Set(
                selectedModels
                    .filter((x) => x !== null)
                    .flatMap((model) => (model ? model.config.output_features.map((x: any) => x.name) : [])),
            ),
        ).sort((a: string, b: string) => alphabeticalCollator.compare(a, b));
        if (res.length > 0 && !res.includes(target)) {
            setTarget(res[0]);
        }
        return res;
    }, [selectedModels]);
};

export const getStaticViz = (
    apiServer: AxiosInstance | null,
    // TODO(hungcs): See if we should just setVizResponse instead of setting links and trainingSetMetadata separately
    setLinks: React.Dispatch<React.SetStateAction<VizLink[] | undefined>>,
    setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>,
    model: Model,
    visualizeType: VizType,
    outputFeature?: string,
    setTrainingMetadata?: React.Dispatch<React.SetStateAction<VizTrainingMetadata | undefined>>,
    setTestStatistics?: React.Dispatch<React.SetStateAction<VizTestStatistics | undefined>>,
) => {
    // Only fetch metrics for models that have at least made it to the explaining stage:
    const modelStatus = model?.status || "";
    if (!(modelStatus === ModelStatus.EXPLAINING || modelStatusFilter(modelStatus))) {
        return;
    }
    const params: any = { modelID: model.id, visualizeType: visualizeType };
    if (outputFeature) {
        params.outputFeature = outputFeature;
    }
    apiServer
        ?.get("visualize", { params })
        .then((res: AxiosResponse<VizResponse>) => {
            setLinks(res.data.links || []);
            setTrainingMetadata?.(res.data.trainingMetadata || {});
            setTestStatistics?.(res.data.testStatistics);
            setErrorMessage(null);
        })
        .catch((err) => {
            const errorMsg = getErrorMessage(err);
            setErrorMessage(errorMsg);
        });
};

export const getDynamicViz = (
    apiServer: AxiosInstance | null,
    setLinks: React.Dispatch<React.SetStateAction<VizLink[] | undefined>>,
    setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>,
    models: Model[],
    visualizeType: VizType,
    outputFeature?: string,
    setLoading?: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    setLoading?.(true);
    apiServer
        ?.post(
            "visualize",
            {
                // modelIDs: models.map(x => x.id),
                // modelNames: models.map(x => x.tag),
                models: models.sort((a, b) => a.id - b.id),
                visualizeType,
                outputFeature,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            },
        )
        .then((res) => {
            setLinks(res.data.links || []);
            setErrorMessage(null);
        })
        .catch((err) => {
            const errorMsg = getErrorMessage(err);
            setErrorMessage(errorMsg);
        })
        .finally(() => setLoading?.(false));
};
