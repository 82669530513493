const absoluteZeroValue = (value: string) => (value === "-0" ? "0" : value);
/**
 * A function to convert valid numbers to Strings and non-numerical numbers (NaN, Infinity, etc).
 * @param value The value to be converted.
 * @param maxFractionDigits The maximum number of fractional digits to be return. Minimum 0.
 * @param maxSignificantDigits The maximum number of significant digits (aka digits before the separater / .)
 * @param showInvalid When true, returns the non-numerical value as a string ("null", "true", "false", "undefined")
 * @param roundingPriority The rounding priority to be used when both maximumFractionDigits and
 *                         maximumSignificantDigits are set.
 *
 * @return String representing the formatted value.
 */
export const formatValueToNumericString = (
    value: any,
    maxFractionDigits: number = 4,
    maxSignificantDigits: number = 6,
    showInvalid: boolean = true,
    roundingPriority?: Intl.NumberFormatOptions["roundingPriority"],
): string => {
    const local = "en-US"; // PUX-950
    const typeOfValue = typeof value;
    // null has a typeof object in JS. Adding special case:
    if (value === null) {
        return showInvalid ? String(value) : "";
    }

    // We don't support complex data types:
    if (typeOfValue === "object" || typeOfValue === "function" || Array.isArray(value)) {
        return "";
    }

    if (typeOfValue !== "number" && typeOfValue !== "string") {
        return showInvalid ? String(value) : "";
    }

    const numberValue = typeOfValue === "number" ? value : Number(value);
    const stringValue = typeOfValue === "string" ? value : String(value);
    if (isNaN(numberValue) || !isFinite(numberValue)) {
        return showInvalid ? stringValue : "";
    }

    // Break up the number into it's parts:
    const negativeSymbol = stringValue.indexOf("-") === 0 ? "-" : "";
    const absoluteStringValue = negativeSymbol.length ? stringValue.substring(1) : stringValue;
    let integer = absoluteStringValue;
    let fraction = "";
    const decimalPosition = absoluteStringValue.indexOf(".");
    if (decimalPosition > -1) {
        integer = absoluteStringValue.substring(0, decimalPosition);
        fraction = absoluteStringValue.substring(decimalPosition + 1);
    }

    /**
     * Whole Numbers
     */
    if (maxFractionDigits <= 0 || fraction.length === 0) {
        // For large numbers, return a shortened version:
        if (integer.length > maxSignificantDigits) {
            return absoluteZeroValue(String(numberValue.toExponential(0)));
        }

        return absoluteZeroValue(
            new Intl.NumberFormat(local, {
                notation: "standard",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
                maximumSignificantDigits: maxSignificantDigits,
                roundingPriority: roundingPriority,
            }).format(Number(`${negativeSymbol + integer}`)),
        );
    }

    /**
     * Fractional numbers less than zero
     */
    if (integer.length === 0 || Number(integer) === 0) {
        const fractionNumber = Number(`${negativeSymbol}.${fraction}`);

        // Very small numbers should be formatted in scientific notation:
        const extremelySmallNumber = Number(
            `0.${Array(maxFractionDigits - 1)
                .fill(0)
                .join("")}1`,
        );
        if (Math.abs(numberValue) < extremelySmallNumber) {
            return absoluteZeroValue(
                new Intl.NumberFormat(local, {
                    notation: "scientific",
                    maximumFractionDigits: maxFractionDigits,
                })
                    .format(fractionNumber)
                    .toLowerCase(),
            );
        }

        // Remove trailing zeros:
        return absoluteZeroValue(
            new Intl.NumberFormat(local, {
                notation: "standard",
                maximumFractionDigits: maxFractionDigits,
            }).format(fractionNumber),
        );
    }

    /**
     * Fractional numbers larger than 1
     */
    if (integer.length > maxSignificantDigits) {
        return absoluteZeroValue(String(numberValue.toExponential(2)));
    }

    return absoluteZeroValue(
        new Intl.NumberFormat(local, {
            notation: "standard",
            maximumFractionDigits: maxFractionDigits,
            maximumSignificantDigits: maxSignificantDigits,
            roundingPriority: roundingPriority,
        }).format(numberValue),
    );
};

/**
 * 0.12 -> "12.0%"
 * 0.123456 -> "12.3%"
 */
export function getFormattedPercentage(value: number): string {
    const percentageValue = value * 100;
    if (Number.isInteger(percentageValue)) {
        return percentageValue.toFixed(0) + "%";
    }
    return percentageValue.toFixed(1) + "%";
}
