import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Button, Divider, Icon, Message, Modal } from "semantic-ui-react";
import { StringInputField } from "../../../components/modal-utils";
import { useAuth0TokenOptions } from "../../../data";
import { USER_STATE } from "../../../state/global";
import { getErrorMessage } from "../../../utils/errors";
import { createAdapterRepo } from "../../data";
import { GET_ADAPTER_REPOS_QUERY_KEY } from "../../query";

// import "./CreateModelRepoModal.css";

const CreateAdapterRepoModal = (props: { open: boolean; setOpen: React.Dispatch<React.SetStateAction<boolean>> }) => {
    // Parent state:
    const { open, setOpen } = props;

    // Recoil state:
    const [userContext] = useRecoilState(USER_STATE);

    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    // Local state:
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    // Required variables:
    const [repoName, setRepoName] = useState<string>("");
    const [description, setDescription] = useState<string>("");

    const navigate = useNavigate();

    // Query state:
    const queryClient = useQueryClient();

    // Mutations:
    const { mutate: createRepo, reset: resetMutation } = useMutation({
        mutationFn: () =>
            createAdapterRepo(
                {
                    name: repoName,
                    description,
                },
                auth0TokenOptions,
            ),
        onSuccess: (repo) => {
            setOpen(false);
            resetMutation();
            queryClient.invalidateQueries({ queryKey: GET_ADAPTER_REPOS_QUERY_KEY() });
            navigate(`/adapters/create/${repo.uuid}`);
        },
        onError: (error) => {
            setErrorMessage(getErrorMessage(error));
        },
    });

    return (
        <Modal
            name="createAdapterRepoModal"
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
                resetMutation();
            }}
            open={open}
        >
            <Modal.Header>New Adapter Repository</Modal.Header>
            <Modal.Content>
                <span>
                    An adapter repository helps you organize your adapter iterations and manage the operationalization
                    of your adapters. It contains all your adapter versions and metadata, and enables visualization and
                    comparison of all your edits and experimentation. Your repository will be viewable and editable by
                    anyone in your organization.
                </span>
                <Divider hidden />

                <StringInputField
                    name="repoName"
                    placeholder="Name"
                    value={repoName}
                    // TODO: guard values?
                    setValue={setRepoName}
                    header="Repository name"
                    description="Great repository names are short and easily recognizable. The name will be used in the adapter ID."
                />

                <StringInputField
                    name="description"
                    placeholder="Description"
                    value={description}
                    setValue={setDescription}
                    header="Description"
                    description="Briefly describe the use case or intent of your repository."
                />

                {errorMessage ? <Message negative>{errorMessage}</Message> : null}
            </Modal.Content>
            <Modal.Actions>
                <Button
                    onClick={() => {
                        setOpen(false);
                        resetMutation();
                    }}
                >
                    Cancel
                </Button>
                <Button
                    icon
                    color={"green"}
                    labelPosition={"right"}
                    size={"small"}
                    disabled={!repoName || userContext?.isExpired}
                    onClick={() => {
                        createRepo();
                    }}
                >
                    Next: Train an adapter
                    <Icon name="checkmark" />
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default CreateAdapterRepoModal;
