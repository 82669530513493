import { Switch } from "antd";
import { Popup } from "semantic-ui-react";
import { ActionType, useDispatch, usePromptState } from "./utils/reducer";

export const DoSampleSwitch = () => {
    const dispatch = useDispatch();
    const { doSample } = usePromptState();

    return (
        <>
            <label style={{ display: "flex", justifyContent: "space-between" }}>
                <label>
                    <Popup
                        className="transition-scale"
                        hoverable
                        wide={"very"}
                        position={"right center"}
                        trigger={<b>Enable Sampling</b>}
                        content={
                            <span>
                                Sampling is used to introduce randomness and variety into the output. When sampling is
                                disabled, the model output will be deterministic for a given prompt.
                            </span>
                        }
                    />
                </label>
                <span>
                    <Switch
                        size="small"
                        checked={doSample}
                        onChange={(val) => dispatch({ type: ActionType.UPDATE, doSample: val?.valueOf() })}
                    />
                </span>
            </label>
        </>
    );
};
