import { Route, Routes } from "react-router-dom";
import DataView from "./DataView";
import ConnectionDetailsView from "./connections/ConnectionDetailsView";
import DatasetDetailsView from "./datasets/DatasetDetailsView";

const DataViewRouter = () => {
    return (
        <Routes>
            <Route path="/connections/:id" element={<ConnectionDetailsView />} />
            <Route path="/datasets/:id" element={<DatasetDetailsView />} />
            <Route path="/" element={<DataView />} />
        </Routes>
    );
};

export default DataViewRouter;
