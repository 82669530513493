// https://developer.mozilla.org/en-US/docs/Web/API/Server-sent_events/Using_server-sent_events#event_stream_format
type MessageEvent = {
    id?: string;
    data?: string;
    event?: string;
    retry?: string;
};

/**
 * A function to parse the raw string response from a server-sent event stream
 * @param value The raw string value streamed from the server
 * @returns An array of MessageEvent objects
 */
export const parseStreamResponse = (value: string) => {
    const events: MessageEvent[] = [];

    // We may get multiple events in a single response
    // Split the response into individual events and process each one
    // Trim removes trailing \n\n; then split on \n\n to get individual events
    const eventStrings = value.trim().split("\n\n");

    eventStrings.forEach((event) => {
        const eventProperties = {} as MessageEvent;
        // Split the event into individual properties
        const lines = event.split("\n");
        lines.forEach((line) => {
            // Original code:
            // https://github.com/mattkrick/event-source-polyfill/blob/master/src/EventSourcePolyfill.ts#L157-L167
            const firstColonIdx = line.indexOf(":");
            // Ignore comments (:) and empty lines
            if (firstColonIdx === 0 || firstColonIdx === -1 || line === "") {
                return;
            }

            const field = line.substring(0, firstColonIdx);
            const untrimmedVal = line.substring(firstColonIdx + 1);
            const value = untrimmedVal.indexOf(" ") === 0 ? untrimmedVal.slice(1) : untrimmedVal;
            // @ts-expect-error - TS doesn't know what properties are on eventProperties
            // Per the spec, data should be combined across multiple lines
            if (eventProperties[field]) {
                // @ts-expect-error - TS doesn't know what properties are on eventProperties
                eventProperties[field] = eventProperties[field] + value;
            } else {
                // @ts-expect-error - TS doesn't know what properties are on eventProperties
                eventProperties[field] = value;
            }
        });

        if (Object.keys(eventProperties).length > 0) {
            events.push(eventProperties);
        }
    });

    return events;
};
