/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum deploymentType {
    SERVERLESS = 'serverless',
    DEDICATED = 'dedicated',
    SHARED = 'shared',
    PRIVATE = 'private',
}
