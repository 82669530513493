import { Table } from "semantic-ui-react";
import { predifineMetricsPayload } from "../../../../api_generated";
import FormattedNumberTooltip from "../../../../components/FormattedNumberTooltip";
import { SEMANTIC_GREY } from "../../../../utils/colors";
import { CHART_METRIC_NAMES, metricNameMap } from "./util";

const tooltipStyle = { color: SEMANTIC_GREY };

const MetricsTable = (props: { latestCheckpoint: predifineMetricsPayload | undefined }) => {
    // Parent state:
    const { latestCheckpoint } = props;

    // Derived state:
    const bestCheckpointNumber = latestCheckpoint?.data.best_eval_metric_checkpoint_number;

    return (
        <Table
            basic
            style={{
                background: "#F7F7F7",
                paddingLeft: "1rem",
                paddingRight: "1rem",
                border: "1px solid rgb(228 230 230)",
                borderRadius: ".28571429rem",
            }}
            collapsing
        >
            <Table.Body>
                {bestCheckpointNumber && bestCheckpointNumber !== -1 && (
                    <Table.Row>
                        <Table.Cell style={{ paddingTop: "16px", paddingBottom: "16px", width: "100%" }}>
                            <b>Best Checkpoint</b>
                        </Table.Cell>
                        <Table.Cell>{bestCheckpointNumber}</Table.Cell>
                    </Table.Row>
                )}
                {CHART_METRIC_NAMES.map((name) => {
                    const metricName = metricNameMap[name];
                    const latestValue = latestCheckpoint?.data[name];

                    if (latestValue === undefined) {
                        return null;
                    }

                    return (
                        <Table.Row key={`${name}-metric-table-row`}>
                            <Table.Cell collapsing style={{ borderTop: "none", width: "100%" }}>
                                {metricName}
                            </Table.Cell>
                            <Table.Cell style={{ borderTop: "none" }}>
                                <FormattedNumberTooltip
                                    value={latestValue}
                                    style={tooltipStyle}
                                    truncatedStyle={tooltipStyle}
                                />
                            </Table.Cell>
                        </Table.Row>
                    );
                })}
            </Table.Body>
        </Table>
    );
};

export default MetricsTable;
