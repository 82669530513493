import _ from "lodash";
import { createContext, SetStateAction } from "react";
import { getLocalStorageKey, setLocalStorageKey } from "../browser/storage";
import { isProduction } from "./environment";

export enum POSTHOG_FEATURE_FLAG {
    Auth0 = "auth0",
    ShowReplicasCount = "showReplicasCount",
}

export interface FeatureFlags {
    [key: string]: Boolean;
}

let posthogFeatureFlags: string[] | undefined;

export const getPosthogFeatureFlag = (flagName: POSTHOG_FEATURE_FLAG, posthogFFs?: string[]) => {
    if (!posthogFFs) return false;

    return posthogFFs.includes(flagName);
};

const featureFlagStorageKeyName = "PFFs";
// @TODO: Work with Julian and Travis to figure out real service
// to provide this initial data.
const featureFlagsDefaults = () => {
    // For Production, usually want new features disabled:
    if (isProduction()) {
        return {
            "Model Editor - Disable Validation": false,
            "Free Trial Testing": false,
            "Engine Environment Show/Hide": false,
            "Kratos Hack": false,
        } as FeatureFlags;
    }

    return {
        "Model Editor - Disable Validation": false,
        "Free Trial Testing": false,
        "Engine Environment Show/Hide": false,
        "Kratos Hack": false,
    } as FeatureFlags;
};

export const getFeatureFlags = () => {
    const storedFlags = getLocalStorageKey(featureFlagStorageKeyName);
    if (!storedFlags) return featureFlagsDefaults();

    const flags = JSON.parse(storedFlags);
    return {
        ...featureFlagsDefaults(),
        ...flags,
    } as FeatureFlags;
};

export const setFeatureFlag = (flagName: string, enabled: boolean) => {
    if (!flagName) return;

    const flags = getFeatureFlags();
    flags[flagName] = enabled;
    setLocalStorageKey(featureFlagStorageKeyName, JSON.stringify(flags));
};

export const getFeatureFlag = (flagName: string) => {
    if (!flagName) return;

    const flags = getFeatureFlags();
    if (_.has(flags, flagName)) {
        return flags[flagName] as Boolean | undefined;
    }

    const defaultFlagValues = featureFlagsDefaults();
    if (_.has(defaultFlagValues, flagName)) {
        return defaultFlagValues[flagName] as Boolean | undefined;
    }

    // If we don't know, assume it's not supported and should not be shown to the customer.
    return false;
};

export const clearFeatureFlags = () => {
    setLocalStorageKey(featureFlagStorageKeyName, JSON.stringify({}));
};

export const FeatureFlagsContext = createContext({
    featureFlags: {} as FeatureFlags,
    posthogFeatureFlags,
    setFeatureFlags: (_: SetStateAction<FeatureFlags>) => {},
});
