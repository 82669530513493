// TODO: remove
export enum DeploymentStatus {
    QUEUED = "queued",
    INITIALIZING = "initializing",
    UPDATING = "updating",
    TERMINATING = "terminating",
    CANCELED = "canceled",
    ACTIVE = "active",
    INACTIVE = "inactive",
    FAILED = "failed",
}
