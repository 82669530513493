import { AxiosInstance, AxiosResponse } from "axios";
import { useEffect, useMemo, useRef, useState } from "react";
import { Link, useMatch } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Breadcrumb, Divider, Icon } from "semantic-ui-react";
import Sockette from "sockette";
import { BadLink } from "../../../components/BadLink/BadLink";
import { SubscriptionButton } from "../../../components/GlobalHeader/SubscriptionButtons";
import { useAuth0TokenOptions } from "../../../data";
import metrics from "../../../metrics/metrics";
import { USER_STATE } from "../../../state/global";
import { createV1APIServer, getWebSocketEndpoint, redirectIfSessionInvalid } from "../../../utils/api";
import { getErrorMessage } from "../../../utils/errors";
import { checkIfHyperoptEnabled, isMetricsStreamingStatus, isTerminalStatus } from "../../util";
import {
    getBestModelMetricsFromRawMetrics,
    getMetricKeysFromRuns,
    getSpecificMetricsFromRawMetrics,
} from "../../utilLearningCurves";
import ModelSummaryGrid from "../ModelSummaryGrid";
import ModelVersionSubmenu from "../ModelVersionSubmenu";
import ModelMetricsTrialsTable from "../modelmetrics/ModelMetricsTrialsTable";

import { isKratosUserContext } from "../../../utils/kratos";
import "./ModelVersionView.css";

const endpoint = getWebSocketEndpoint();

/**
 * Converts ModelRunMetrics into RawModelMetrics. Notably, maps data subset metrics to their best values.
 * @param metrics
 * @returns
 */
const convertActiveMetricsArrayToMap = (metrics?: ModelRunMetrics[]) => {
    const d: any = {};
    if (metrics === undefined) {
        return d;
    }

    metrics?.forEach((x) => {
        const best_metric = metrics.find((y) => y.key === "best." + x.key);
        if (best_metric !== undefined) {
            d[x.key] = best_metric.value;
        } else {
            d[x.key] = x.value;
        }
    });
    return d as RawModelMetrics;
};

// For some reason, mlflow can have duplicate metrics for the same step.
// https://linear.app/predibase/issue/MLX-1402/duplicate-metrics-being-published-for-the-same-step-in-mlflow
const modelRunMetricsWithoutDuplicates = (modelRunMetrics: ModelRunMetrics[]) => {
    const stepSet = new Set<number>();
    const arrWithoutDuplicates = [];
    for (const metric of modelRunMetrics) {
        if (!stepSet.has(metric.step)) {
            stepSet.add(metric.step);
            arrWithoutDuplicates.push(metric);
        }
    }
    return arrWithoutDuplicates;
};

function ModelVersionView() {
    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    const [apiServer, setAPIServer] = useState<AxiosInstance | null>(null);
    useEffect(() => {
        const getAPIServer = async () => {
            const v1APIServer = await createV1APIServer(auth0TokenOptions);
            // NOTE: Whoever wrote the axios typings is a moron because the return type of axios.create is not
            // AxiosInstance -- it's a wrap function. And React will see that and treat it as a callback that
            // setState should directly call. FML.
            // See: [1], [2]:
            // [1]: https://github.com/axios/axios/issues/4365
            // [2]: https://stackoverflow.com/questions/64427195/calling-setstate-will-execute-the-function-value-instead-of-passing-it
            setAPIServer(() => v1APIServer);
        };
        getAPIServer();
    }, []);

    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [modelVersion, setModelVersion] = useState<Model>();
    const [userContext] = useRecoilState(USER_STATE);

    let tenantTier;
    if (userContext) {
        const isKratosContext = isKratosUserContext(userContext);
        tenantTier = isKratosContext ? userContext?.tenant.subscription.tier : userContext?.tenant.tier;
    }

    // Total runs
    const [experimentRuns, setExperimentRuns] = useState<ModelRun[]>([]);
    // Selected runs for metrics
    const [selectedRuns, setSelectedRuns] = useState<string[]>([]);
    // Need ref because of websockets race conditions when adding new runs
    const selectedRunsHyp = useRef<Set<string>>(new Set());
    // Single active run for non-hyperopt
    const [deployedRun, setDeployedRun] = useState<ModelRun>();

    const [selectedMetrics, setSelectedMetrics] = useState<string[]>([]);

    // Used to convert trial id (a9fd31b80c4c4281b) to readable # (1) in graph and trials table. Note that
    // during streaming the run ids are the ray tune trial ids, but after deployment they are the mlflow run ids.
    const runToNumberMap = useRef<Record<string, number>>({});

    // Whether model is in progress
    const [inProgress, setInProgress] = useState(false);

    // Streaming
    const [ipLatestMetrics, setIpLatestMetrics] = useState<RawModelMetrics>();
    const fullMetricHistory = useRef<StreamingModelMetrics | null>(null);
    const socketReconnected = useRef(false);
    const [metricHistory, setMetricHistory] = useState<MetricHistory>({});
    const [llmSampleOutputs, setLlmSampleOutputs] = useState<LlmSampleOutput[]>();
    const [modelSteps, setModelSteps] = useState<ModelSteps>({
        steps: 0,
        totalSteps: 0,
        stepsPerEpoch: 0,
    } as ModelSteps);

    const [timeline, setTimeline] = useState<ModelTimeline>();

    const match = useMatch("/models/version/:id");
    // TODO: Unsafe bang access:
    const versionID = parseInt(match!.params?.id!);

    // const metricsWS = useRef<W3CWebSocket | null>(null);
    const metricsWS = useRef<Sockette | null>(null);
    const experimentsInterval = useRef<NodeJS.Timeout | null>(null);

    const specificMetrics = useMemo(() => {
        if (inProgress && ipLatestMetrics) {
            return getSpecificMetricsFromRawMetrics(ipLatestMetrics);
        } else if (!inProgress && deployedRun) {
            const metrics = convertActiveMetricsArrayToMap(deployedRun?.data.metrics);
            return getSpecificMetricsFromRawMetrics(metrics);
        }
    }, [deployedRun, inProgress, ipLatestMetrics]);

    const bestModelMetrics = useMemo(() => {
        if (inProgress && ipLatestMetrics) {
            return getBestModelMetricsFromRawMetrics(ipLatestMetrics);
        } else if (!inProgress && deployedRun) {
            const metrics = convertActiveMetricsArrayToMap(deployedRun?.data.metrics);
            return getBestModelMetricsFromRawMetrics(metrics);
        }
    }, [deployedRun, inProgress, ipLatestMetrics]);

    const defaultMetrics = useMemo(() => {
        const repoDefaultMetric = modelVersion?.repo?.defaultRunMetricName;
        if (repoDefaultMetric) {
            const modelMetrics = modelVersion?.modelMetrics
                ? modelVersion?.modelMetrics?.map((x) => x.runMetricName)
                : experimentRuns
                  ? getMetricKeysFromRuns(experimentRuns)
                  : [];

            if (repoDefaultMetric.startsWith("best.") && modelMetrics.includes(repoDefaultMetric.substring(5))) {
                return [repoDefaultMetric.substring(5)];
            } else if (modelMetrics.includes(repoDefaultMetric)) {
                return [repoDefaultMetric];
            }
        }
        return ["train_metrics.combined.loss", "validation_metrics.combined.loss", "test_metrics.combined.loss"];
    }, [modelVersion?.repo?.defaultRunMetricName, modelVersion?.modelMetrics, experimentRuns]);

    useEffect(() => {
        if (modelVersion) {
            apiServer
                ?.get(`/models/version/${modelVersion.id}/timeline`, {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                })
                .then((res: AxiosResponse<ModelTimeline>) => {
                    setTimeline(res.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [modelVersion?.status, apiServer]);

    useEffect(() => {
        if (deployedRun || Object.keys(runToNumberMap.current).length == 0) {
            runToNumberMap.current = {};

            experimentRuns.forEach((run, i) => {
                runToNumberMap.current[run.info.run_id] = i + 1;
            });
        }
    }, [experimentRuns, deployedRun]);

    useEffect(() => {
        return () => {
            runToNumberMap.current = {};
            if (metricsWS.current) {
                metricsWS.current.close();
            }
        };
    }, []);

    useEffect(() => {
        if (deployedRun) {
            setSelectedRuns([deployedRun.info.run_id]);
            fetchMetricHistory(selectedMetrics, [deployedRun.info.run_id], {});
        }
    }, [deployedRun]);

    useEffect(() => {
        if (!apiServer) {
            return;
        }

        getModelVersion(true, false);
        return stopExperimentRuns;
    }, [apiServer]);

    useEffect(() => {
        if (!experimentRuns) {
            return;
        }
        setMetricsFromExperiments();
    }, [experimentRuns]);

    const startExperimentRuns = () => {
        experimentsInterval.current = setInterval(() => getModelVersion(false, false), 5000);
    };

    const stopExperimentRuns = () => {
        if (experimentsInterval.current) {
            clearInterval(experimentsInterval.current);
        }
        experimentsInterval.current = null;
    };

    const maxLengthOfRawMetricHistory = () => {
        const rawMH = fullMetricHistory.current?.data;
        if (rawMH === undefined) {
            return 0;
        }
        return Math.max(...Object.values(rawMH).map((x) => x.length));
    };

    const setNewMetricHistory = () => {
        const rawMH = fullMetricHistory.current?.data;
        setMetricHistory(rawMH || {});
        if (rawMH === undefined) {
            return {};
        }

        setSelectedMetrics((x) => {
            if (x.length === 0) {
                return defaultMetrics;
            }
            return x;
        });
    };

    const updateRawMetrics = (resp: StreamingModelMetrics) => {
        if (fullMetricHistory.current === null) {
            fullMetricHistory.current = resp;
            return;
        }

        Object.keys(resp.data || {}).forEach((trial) => {
            if (fullMetricHistory.current && fullMetricHistory.current.data[trial] == undefined) {
                fullMetricHistory.current.data[trial] = [];
            }
        });

        Object.keys(fullMetricHistory.current.data || {}).forEach((trial) => {
            if (fullMetricHistory.current && resp.data[trial] != undefined) {
                fullMetricHistory.current.data[trial] = fullMetricHistory.current.data[trial].concat(resp.data[trial]);
            }
        });
    };

    const initializeMetricsStream = () => {
        if (metricsWS.current) {
            return;
        }

        metricsWS.current = new Sockette(endpoint + "/models/metrics/history/stream/" + versionID, {
            timeout: 5e3,
            maxAttempts: 3,
            onopen: (e) => console.log("ws metrics opened", e),
            onreconnect: (e) => {
                console.log("ws metrics reconnecting", e);
                socketReconnected.current = true;
            },
            onmaximum: (e) => console.log("ws metrics stop attempting!", e),
            onclose: (e) => console.log("ws metrics closed", e),
            onerror: (e) => console.error("ws metrics error", e),
            onmessage: (e) => {
                if (e.data != null) {
                    const resp: StreamingModelMetrics = JSON.parse(e.data as string);
                    console.log("Streaming metrics:\t", resp);
                    // When the cilent connects, the server will return all of the previous metrics.
                    // We could ignore this data since the client already has it, but if an epoch
                    // finished training while the client was disconnected, then the client would
                    // be missing that data. So we just update the client with all of the data.
                    if (socketReconnected.current === true) {
                        fullMetricHistory.current = null;
                        socketReconnected.current = false;
                    }
                    updateRawMetrics(resp);
                    setNewMetricHistory();

                    if (resp.meta.steps > 0) {
                        setModelSteps({
                            steps: resp.meta.steps,
                            totalSteps: resp.meta.total_steps,
                            stepsPerEpoch: resp.meta.steps_per_epoch,
                        } as ModelSteps);
                    }

                    if (!resp.meta.is_hyperopt) {
                        const runIds = Object.keys(resp.data || []);
                        if (runIds.length > 0) {
                            const selectedKey = runIds[0]; // In the case of model retry, we grab the first run
                            const metricData = resp.data[selectedKey];
                            if (metricData && metricData.length !== 0) {
                                const latest = metricData[metricData?.length - 1];
                                setIpLatestMetrics((_) => latest);
                                const llmEvalExamples: LlmSampleOutput[] = [];
                                metricData.forEach((epochMetrics) => {
                                    if (epochMetrics.llm_eval_examples) {
                                        llmEvalExamples.push(epochMetrics.llm_eval_examples);
                                    }
                                });
                                setLlmSampleOutputs((llmSampleOutputs) => {
                                    if (Array.isArray(llmSampleOutputs)) {
                                        return [...llmSampleOutputs, ...llmEvalExamples];
                                    }
                                    return llmEvalExamples;
                                });
                            }
                            setSelectedRuns((_) => [selectedKey]);
                        }
                    } else {
                        setSelectedRuns((old) => {
                            const keys = Object.keys(fullMetricHistory.current?.data || []);

                            let longest = 0;
                            let longestKey = undefined;
                            for (const k of keys) {
                                const arr = fullMetricHistory.current?.data[k];
                                if (arr != undefined && arr.length > longest) {
                                    longest = arr.length;
                                    longestKey = k;
                                }
                            }

                            if (longestKey) {
                                const arr = fullMetricHistory.current?.data[longestKey] as any[];

                                // TODO (hungcs): Make it more obvious that this just sets the
                                //  latest metrics to the newest metrics of the longest trial.
                                setIpLatestMetrics((x) => arr[arr.length - 1]);
                            }

                            let i = Object.keys(runToNumberMap.current).length;
                            for (const run of keys) {
                                if (runToNumberMap.current[run] === undefined) {
                                    i += 1;
                                    runToNumberMap.current[run] = i;
                                    selectedRunsHyp.current.add(run);
                                }
                            }
                            // Use ref as a source of truth because of race conditions with setState
                            return Array.from(selectedRunsHyp.current);
                        });
                    }

                    if (resp.meta.is_completed) {
                        const isOpen = (e.target as WebSocket).readyState === WebSocket.OPEN;
                        if (metricsWS.current && isOpen) {
                            metricsWS.current.close();
                        }
                        metricsWS.current = null;
                        return;
                    }
                }
            },
        });
    };

    const getModelVersion = (startInterval = false, inStreaming = false) => {
        apiServer
            ?.get("models/version/" + versionID + "?withRuns=true")
            .then((res: AxiosResponse<GetModelWithRunsResponse>) => {
                const resModelVersion = res.data.modelVersion;

                if (isTerminalStatus(resModelVersion.status)) {
                    runToNumberMap.current = {};
                    setInProgress(false);
                    setModelVersion(resModelVersion);
                    setExperimentRuns((x) => res.data.runs || x);
                    stopExperimentRuns();
                    return;
                }

                if (!inStreaming) {
                    runToNumberMap.current = {};
                    setExperimentRuns((x) => res.data.runs || x);
                    setModelVersion(res.data.modelVersion);
                    setInProgress(true);
                    stopExperimentRuns();
                    experimentsInterval.current = setInterval(() => getModelVersion(false, true), 5000);
                } else {
                    setModelVersion((x) => (x?.status !== resModelVersion.status ? resModelVersion : x));
                }
                if (!fullMetricHistory.current && isMetricsStreamingStatus(resModelVersion.status)) {
                    initializeMetricsStream();
                }

                if (startInterval && experimentsInterval.current === null) {
                    startExperimentRuns();
                }
            })
            .catch((error) => {
                const errorMsg = getErrorMessage(error) ?? "";
                redirectIfSessionInvalid(errorMsg);
                setErrorMessage(errorMsg);
            });
    };

    const fetchMetricHistory = (metricKeys: string[], runKeys: string[], mh = metricHistory) => {
        const numMetrics = metricKeys.length;
        Promise.all(
            runKeys.flatMap((run) =>
                metricKeys.map((metricKey) => apiServer?.get("models/metrics/history/" + run + "/" + metricKey)),
            ),
        )
            .then((responses) => {
                let newMetricHistory: MetricHistory = { ...mh };
                // TODO: No types for anything? lol...
                responses.forEach((runMetric, i) => {
                    if (runMetric?.data.metrics) {
                        const sortedRunMetrics = modelRunMetricsWithoutDuplicates(
                            // TODO: types missing...
                            runMetric.data.metrics.sort((a: any, b: any) => a.step - b.step),
                        );
                        const run_id = runKeys[Math.floor(i / numMetrics)];
                        if (!newMetricHistory[run_id]) {
                            newMetricHistory[run_id] = [];
                        }

                        newMetricHistory[run_id] = ((sortedRunMetrics as ModelRunMetrics[]) || [])?.map((metric, i) => {
                            let currentMetric = newMetricHistory[run_id][i] ? newMetricHistory[run_id][i] : {};
                            return { ...currentMetric, [metric.key]: metric.value };
                        });
                    }
                });
                setMetricHistory(newMetricHistory);
            })
            .catch((error) => {
                const errorMsg = getErrorMessage(error) ?? "";
                redirectIfSessionInvalid(errorMsg);
                setErrorMessage(errorMsg);
            });
    };

    const handleRunClick = (runID: string) => {
        if (selectedRuns.includes(runID)) {
            setSelectedRuns((old) => old.filter((x) => x !== runID));
            if (!deployedRun) {
                selectedRunsHyp.current.delete(runID);
            }
        } else {
            setSelectedRuns([...selectedRuns, runID]);
            if (deployedRun) {
                fetchMetricHistory(selectedMetrics, [runID], metricHistory);
            } else {
                selectedRunsHyp.current.add(runID);
            }
        }
    };

    const handleRunClickBatch = (checked: boolean) => {
        if (checked) {
            setSelectedRuns([]);
            selectedRunsHyp.current = new Set();
        } else {
            if (deployedRun) {
                const allRuns = experimentRuns.map((x) => x.info.run_id);
                fetchMetricHistory(selectedMetrics, allRuns, metricHistory);
                setSelectedRuns(allRuns);
            } else {
                const allRuns = Object.keys(runToNumberMap.current);
                setSelectedRuns(allRuns);
                selectedRunsHyp.current = new Set(allRuns);
            }
        }
    };

    const handleMetricClick = (metricKey: string) => {
        const metricName = "Model.ModelVersion.Metric";

        if (selectedMetrics.includes(metricKey)) {
            // remove
            let newSelectedMetrics = selectedMetrics.filter((x) => x !== metricKey);
            setSelectedMetrics(newSelectedMetrics);

            if (deployedRun) {
                const newMetricHistory = { ...metricHistory };
                newMetricHistory[deployedRun.info.run_id]?.map((item) => {
                    delete item[metricKey];
                    return item;
                });
                setMetricHistory(newMetricHistory);
            }
            metrics.captureRemove(metricName, { name: metricKey, value: newSelectedMetrics, metricType: "normal" });
        } else {
            // add
            setSelectedMetrics([...selectedMetrics, metricKey]);
            metrics.captureAdd(metricName, { name: metricKey, value: selectedMetrics, metricType: "normal" });
            if (deployedRun) {
                fetchMetricHistory([metricKey], selectedRuns);
            }
        }
    };

    const batchHandleMetricClick = (metricKeys: string[], force = false) => {
        const metricName = "Model.ModelVersion.Metric.Batch";

        const existingMetrics = new Set(selectedMetrics);
        let addedMetrics = new Set<string>();
        let removedMetrics = new Set<string>();

        if (force === true) {
            addedMetrics = new Set<string>(metricKeys.filter((x) => !existingMetrics.has(x)));
        } else if (force === false) {
            removedMetrics = new Set<string>(metricKeys.filter((x) => existingMetrics.has(x)));
        } else {
            metricKeys.forEach((x) => {
                if (selectedMetrics.includes(x)) {
                    removedMetrics.add(x);
                } else {
                    addedMetrics.add(x);
                }
            });
        }

        const addedMetricsArr = Array.from(addedMetrics);
        const removedMetricsArr = Array.from(removedMetrics);

        let newSelectedMetrics = selectedMetrics.filter((item) => !removedMetrics.has(item));
        newSelectedMetrics = newSelectedMetrics.concat(addedMetricsArr);
        setSelectedMetrics(newSelectedMetrics);

        metrics.captureRemove(metricName, {
            added: addedMetricsArr,
            removed: removedMetricsArr,
            value: newSelectedMetrics,
            metricType: "normal",
        });

        if (deployedRun) {
            let newMetricHistory = { ...metricHistory };
            removedMetrics.forEach((metric) => {
                Object.keys(newMetricHistory).forEach((trial) => {
                    newMetricHistory[trial].forEach((epoch) => {
                        delete epoch[metric];
                    });
                });
            });

            fetchMetricHistory(addedMetricsArr, selectedRuns, newMetricHistory);
        }
    };

    const selectDefaultMetricsForRun = (runMetrics: ModelRunMetrics[]) => {
        let selected = [];
        for (let metric of runMetrics || []) {
            if (defaultMetrics.includes(metric.key)) {
                selected.push(metric.key);
            }
        }
        setSelectedMetrics(selected);
    };

    const setMetricsFromExperiments = () => {
        if (isTerminalStatus(modelVersion?.status as string)) {
            setInProgress(false);
            let activeFinishedRun = experimentRuns.find((run) => run.info.run_id === modelVersion?.activeRunID);

            // If there's no active run (i.e. model was canceled), use first run
            if (!activeFinishedRun && experimentRuns.length > 0) {
                activeFinishedRun = experimentRuns[0];
            }

            if (activeFinishedRun) {
                setDeployedRun(activeFinishedRun);
                setSelectedRuns([activeFinishedRun.info.run_id]);
            }

            if (selectedMetrics.length === 0) {
                selectDefaultMetricsForRun(activeFinishedRun?.data?.metrics || []);
            }
        } else {
            // model is in progress
            setInProgress(true);
            runToNumberMap.current = {};
            experimentRuns.forEach((run) => {
                if (selectedMetrics.length === 0) {
                    selectDefaultMetricsForRun(run?.data?.metrics || []);
                }
            });
        }
    };

    let body = null;
    if (modelVersion != null) {
        let breadcrumb = null;
        if (modelVersion?.repo) {
            breadcrumb = (
                <div className={"version-header"}>
                    <Breadcrumb>
                        <Breadcrumb.Section>
                            <Link to="/models">Models</Link>
                        </Breadcrumb.Section>
                        <Breadcrumb.Divider />
                        <Breadcrumb.Section>
                            <Link className={metrics.BLOCK_AUTO_CAPTURE} to={"/models/repo/" + modelVersion.repoID}>
                                <Icon name={"folder outline"} />
                                {modelVersion.repo?.modelName}
                            </Link>
                        </Breadcrumb.Section>
                        <Breadcrumb.Divider />
                        <Breadcrumb.Section active style={{ fontWeight: "normal" }}>
                            Version {modelVersion.repoVersion}
                            {checkIfHyperoptEnabled(modelVersion.config) ? ": Hyperopt Run" : null}
                        </Breadcrumb.Section>
                    </Breadcrumb>
                    <SubscriptionButton isExpired={userContext?.isExpired} currentTier={tenantTier} />
                </div>
            );
        }

        let details = null;

        const trials = (
            <ModelMetricsTrialsTable
                modelVersion={modelVersion}
                activeRun={deployedRun}
                bestRunID={modelVersion?.bestRunID}
                inProgress={inProgress}
                metricHistory={metricHistory}
                experimentRuns={experimentRuns}
                selectedRuns={selectedRuns}
                runToNumberMap={runToNumberMap}
                handleRunClick={handleRunClick}
                handleRunClickBatch={handleRunClickBatch}
                isHyperopt={checkIfHyperoptEnabled(modelVersion?.config)}
            />
        );

        details = (
            <div>
                {Object.keys(metricHistory).length > 0 ? (
                    <>
                        {trials}
                        <Divider hidden />
                    </>
                ) : null}
                <ModelVersionSubmenu
                    modelVersion={modelVersion}
                    inProgress={inProgress}
                    metricHistory={metricHistory}
                    selectedMetrics={selectedMetrics}
                    handleMetricClick={handleMetricClick}
                    batchHandleMetricClick={batchHandleMetricClick}
                    specificMetrics={specificMetrics || {}}
                    bestModelMetrics={bestModelMetrics}
                    selectedRuns={selectedRuns}
                    experimentRuns={experimentRuns}
                    runToNumberMap={runToNumberMap}
                    timeline={timeline}
                    modelSteps={modelSteps}
                    llmSampleOutputs={llmSampleOutputs}
                />
                <Divider hidden />
            </div>
        );

        body = (
            <div>
                {breadcrumb}
                <Divider hidden />
                <ModelSummaryGrid
                    getModelVersion={getModelVersion}
                    modelRepo={modelVersion.repo}
                    modelVersion={modelVersion}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    refreshFunc={getModelVersion}
                    timeline={timeline}
                />
                <br />
                {details}
            </div>
        );
    }

    if (errorMessage && !modelVersion) {
        return <BadLink authenticated={true} />;
    }

    return (
        <div style={{ padding: "20px" }}>
            {body}
            <Divider hidden />
        </div>
    );
}

export default ModelVersionView;
