import type { JSONSchema7 } from "json-schema";
import _ from "lodash";
import { useMemo } from "react";
import { JSONSchemaForm } from "../../../components/forms";
import { findAllOfSchemaIndex } from "../../../utils/jsonSchema";
import { filterParams } from "../utils";

const FieldConfigForm = (props: {
    config: CreateModelConfig;
    setConfig: (path: string, value: any) => void;
    invalidFields: any;
    metricRoot: string;
    featureIndex: number;
    featureType: string;
    readOnly?: boolean;
    schema: JSONSchema7;
    expectedImpactFilter?: number;
}) => {
    const formSchema = useMemo(() => {
        const newSchema = _.cloneDeep(_.get(props.schema, `properties.${props.featureType}.items`)) as
            | JSONSchema7
            | undefined;

        if (!newSchema) return {};

        delete newSchema.properties?.name;
        delete newSchema.properties?.column;
        newSchema?.allOf?.map((allOfItem: any) => {
            const { type, preprocessing, ...restofProperties } = allOfItem.then.properties;
            return _.set(allOfItem, "then.properties", {
                preprocessing: {
                    ...preprocessing,
                    // Since the title of the Modal includes Preprocessing, no need to duplicate it
                    title: "",
                },
            });
        });

        return newSchema;
    }, [props.schema]);

    const setConfig = (path: string, value: any) => {
        // Wipe previous parameters when changing the type:
        if (path.endsWith(".type")) {
            // Remove encoder, deodoer and preprocessing options when we change the feature type.
            const featureSchema = _.get(props.config, `${props.featureType}[${props.featureIndex}]`) as
                | CreateModelIOFeature
                | undefined;
            // @ts-expect-error
            const { encoder, decoder, preprocessing, ...restOfFeature } = featureSchema;
            props.setConfig(`${props.featureType}[${props.featureIndex}]`, { ...restOfFeature, ...{ type: value } });
        } else {
            props.setConfig(path, value);
        }
    };

    return (
        <JSONSchemaForm
            data={props.config}
            schema={filterParams(formSchema, props.expectedImpactFilter)}
            invalidFields={props.invalidFields}
            setConfig={setConfig}
            featureIndex={props.featureIndex}
            schemaPathPrefix={`${props.featureType}.items${findAllOfSchemaIndex(
                _.get(props.schema, `properties.${props.featureType}.items`),
                props.featureType,
            )}`}
            readOnly={props.readOnly}
        />
    );
};

export const InputPreprocessingForm = (props: any) => {
    return <FieldConfigForm {...props} featureType={"input_features"} />;
};

export const OutputPostprocessingForm = (props: any) => {
    return <FieldConfigForm {...props} featureType={"output_features"} />;
};
