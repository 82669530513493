import { useMutation, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import { Dispatch, memo, SetStateAction, useEffect, useReducer, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Divider, Dropdown, Header, Icon, Label, Popup, Table } from "semantic-ui-react";
import EditDiv from "../../components/EditDiv";
import FormattedNumberTooltip from "../../components/FormattedNumberTooltip";
import GrayDash from "../../components/GrayDash";
import LiveTimeAgo from "../../components/LiveTimeAgo";
import ShowMoreLabels from "../../components/ShowMoreLabels";
import { getStatusChipForModelsAndQueries } from "../../components/statusChip";
import { useAuth0TokenOptions } from "../../data";
import { track } from "../../metrics/june";
import metrics from "../../metrics/metrics";
import { GET_USER_CREDITS_QUERY_KEY } from "../../query";
import { USER_STATE } from "../../state/global";
import { ModelStatus } from "../../types/model/modelStatus";
import { SEMANTIC_GREY, SEMANTIC_RED } from "../../utils/colors";
import { deletedUser } from "../../utils/constants";
import dayjsExtended, { durationAsString } from "../../utils/dayjs";
import { getErrorMessage } from "../../utils/errors";
import { Direction, TableSortingReducer } from "../../utils/table";
import { retrainModel, starOrArchiveModel } from "../data";
import { GET_MODEL_REPO_QUERY_KEY } from "../query";
import { getConfigOutputFeatureNames, isLLMModel, isTerminalStatus, isTrainingStatus } from "../util";
import { CancelModelButtonWithModal } from "../utilApis";
import { bestMetricsRegex } from "../version/modelmetrics/util";
import RecommendNextExperimentsModal from "./RecommendedNextExperimentsModal";
import RetrainModelModal from "./RetrainModelModal";

const mapActiveFields = (model: Model) =>
    model.activeFields.map((activeField) => {
        const field = model.features.find((field) => field.fieldID === activeField.fieldID);
        return field?.featureName ?? "";
    });

const DatasetAndTargetLabels = (model: Model) => {
    const outputFeatures = getConfigOutputFeatureNames(model.config);
    const activeFields = mapActiveFields(model);
    const datasetName = model.dataset?.name ?? "";

    const RowFunc = (field: string) => {
        const isActive = activeFields.includes(field);
        if (isActive) {
            return (
                <Popup
                    content="This model version will used for this dataset and target for PQL queries."
                    trigger={
                        <Label
                            key={field}
                            color={isActive ? "blue" : undefined}
                            as={"a"}
                            href={`/data/datasets/${model.datasetID}`}
                            className={"centered-label black-link"}
                        >
                            {datasetName}.{field}
                        </Label>
                    }
                />
            );
        }

        return (
            <Label
                key={field}
                color={isActive ? "blue" : undefined}
                as={"a"}
                href={`/data/datasets/${model.datasetID}`}
                className={"centered-label black-link"}
            >
                {datasetName}.{field}
            </Label>
        );
    };

    return <ShowMoreLabels data={outputFeatures} limit={2} RowComponent={RowFunc} />;
};

const DescriptionLabel = (model: Model) => {
    const versionLink = "/models/version/" + model.id;
    if (model.description) {
        return (
            <Link
                to={versionLink}
                className={"black-link"}
                style={{ color: model.archived ? SEMANTIC_GREY : undefined }}
            >
                {model.description}
                {model.starred ? (
                    <>
                        &nbsp;
                        <Icon name={"star"} color={"yellow"} />
                    </>
                ) : null}
                {model.archived ? (
                    <>
                        &nbsp;
                        <Icon name={"archive"} />
                    </>
                ) : null}
            </Link>
        );
    }
    if (model.starred) {
        return (
            <Link to={versionLink} aria-label={`Star Model Version ${model.repoVersion}`} className={"back-link"}>
                <span style={{ color: SEMANTIC_GREY, opacity: 0.3 }}>—</span> <Icon name={"star"} color={"yellow"} />
            </Link>
        );
    }
    if (model.archived) {
        return (
            <Link
                to={versionLink}
                aria-label={`Archive Version ${model.repoVersion}`}
                className={"back-link"}
                style={{ color: SEMANTIC_GREY }}
            >
                <span style={{ color: SEMANTIC_GREY, opacity: 0.3 }}>—</span> <Icon name={"archive"} />
            </Link>
        );
    }
    return undefined;
};

function metricNameLabel(metricName?: string) {
    // Older models don't have a default run metric:
    if (typeof metricName !== "string" || metricName.length === 0) {
        return "";
    }

    let metricParts = metricName.split(".");
    let metric = metricParts[metricParts.length - 1];

    if (metric === "roc_auc") {
        // one-off, since the capitalization rule below doesn't work for abbreviations
        return "ROC AUC";
    }

    // Replace snake case with a human-readable label, e.g.:
    // mean_absolute_error -> Mean Absolute Error
    return metric
        .split("_")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
}

const getModelDuration = (model: Model) => {
    const created = dayjsExtended(new Date(model.created));
    if (model.completed) {
        const completed = dayjsExtended(new Date(model.completed));
        const duration = dayjsExtended.duration(completed.diff(created));
        return duration;
    }
    return null;
};

// If repo default metric is roc_auc, the full metric name will be "best.test_metrics.Survived.roc_auc".
// However, we still want to display metrics for model versions that have all the metric parts matching, even if the version target
// is not the same as the repo target (i.e. Survived vs Survived2: "best.test_metrics.Survived2.roc_auc")
export const getBestModelVersionMetricFromRepoDefaultRunMetricName = (
    model: Model,
    repo: ModelRepo,
): ModelMetric | undefined => {
    const repoMetric = repo.defaultRunMetricName;
    if (!repoMetric) {
        return;
    }
    const repoMatches = bestMetricsRegex.exec(repoMetric);
    if (repoMatches === null) {
        return;
    }

    // Exact metric match
    const exactModelMetricMatch = model.modelMetrics?.find((x) => x.runMetricName === repoMetric);
    if (exactModelMetricMatch) {
        return exactModelMetricMatch;
    }

    // Partial match
    // TODO: Convert repoMatches to an Object?
    const [, repoBestOrTest, repoMetricType, , repoMetricName] = repoMatches;

    // Combined match
    const combinedMatch = model.modelMetrics?.find((x) => {
        const versionMatches = bestMetricsRegex.exec(x.runMetricName);
        if (versionMatches === null) {
            return false;
        }
        const [, versionBestOrTest, versionMetricType, versionTarget, versionMetricName] = versionMatches;
        return (
            versionBestOrTest === repoBestOrTest &&
            versionTarget === "combined" &&
            versionMetricType === repoMetricType &&
            versionMetricName === repoMetricName
        );
    });
    if (combinedMatch) {
        return combinedMatch;
    }

    // Any target match
    return model.modelMetrics?.find((x) => {
        const versionMatches = bestMetricsRegex.exec(x.runMetricName);
        if (versionMatches === null) {
            return false;
        }
        // TODO: Convert versionMatches to an Object?
        const [, versionBestOrTest, versionMetricType, , versionMetricName] = versionMatches;
        return (
            versionBestOrTest === repoBestOrTest &&
            versionMetricType === repoMetricType &&
            versionMetricName === repoMetricName
        );
    });
};

const ModelVersionsTable = (props: {
    modelVersions: Model[];
    repo: ModelRepo;
    setErrorMessage: Dispatch<SetStateAction<string | null>>;
    setLoading: Dispatch<SetStateAction<boolean>>;
}) => {
    // User state:
    const [user] = useRecoilState(USER_STATE);

    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    // Parent state:
    const { modelVersions, repo, setErrorMessage, setLoading } = props;

    const [retrainModelSelection, setRetrainModelSelection] = useState<Model | null>(null);
    const [retrainModelModalOpen, setRetrainModelModalOpen] = useState(false);

    const [recommendedExperiementsModalModel, setRecommendedExperiementsModalModel] = useState<Model>();
    const injectedModelVersions: ModelForTable[] = modelVersions.map((model) => {
        return {
            ...model,
            datasetName: model.dataset?.name,
            engineName: model.engine?.name,
            duration: getModelDuration(model)?.asMilliseconds(),
            bestMetricValue: getBestModelVersionMetricFromRepoDefaultRunMetricName(model, repo)?.metricValue,
        };
    });

    const [state, dispatch] = useReducer(TableSortingReducer, {
        column: "id",
        data: injectedModelVersions,
        direction: Direction.DESCENDING,
    });

    const { column, data, direction } = state;

    useEffect(() => {
        dispatch({ type: "CHANGE_DATA", column: column, newData: injectedModelVersions });
    }, [modelVersions]); // eslint-disable-line react-hooks/exhaustive-deps

    const metricLabel = metricNameLabel(repo.defaultRunMetricName);

    // Query state:
    const queryClient = useQueryClient();

    const { mutate: mutateStarOrArchiveModel, reset: resetMutation } = useMutation({
        mutationFn: ({
            modelID,
            description,
            star,
            archive,
        }: {
            modelID: number;
            description: string;
            star: boolean;
            archive: boolean;
        }) => starOrArchiveModel(modelID, description, star, archive, auth0TokenOptions),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: GET_MODEL_REPO_QUERY_KEY(repo.id) });
        },
    });

    const retrainModelMutation = useMutation({
        mutationFn: () => retrainModel(retrainModelSelection!, auth0TokenOptions),
        onMutate: () => {
            setLoading(true);
        },
        onSuccess: (res) => {
            queryClient.invalidateQueries({ queryKey: GET_USER_CREDITS_QUERY_KEY });
            queryClient.invalidateQueries({ queryKey: GET_MODEL_REPO_QUERY_KEY(repo.id) });
        },
        onError: (error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            setErrorMessage(errorMsg);
        },
        onSettled: () => {
            setLoading(false);
        },
    });

    return (
        <>
            <Table selectable={modelVersions.length > 0} sortable>
                <Table.Header className={"sortable-no-border"}>
                    <Table.Row>
                        <Table.HeaderCell
                            sorted={column === "id" ? direction : undefined}
                            onClick={() => dispatch({ type: "CHANGE_SORT", column: "id" })}
                        >
                            #
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing></Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === "description" ? direction : undefined}
                            onClick={() => dispatch({ type: "CHANGE_SORT", column: "description" })}
                        >
                            Description
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === "datasetName" ? direction : undefined}
                            onClick={() => dispatch({ type: "CHANGE_SORT", column: "datasetName" })}
                        >
                            Datasets and Targets
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === "engineName" ? direction : undefined}
                            onClick={() => dispatch({ type: "CHANGE_SORT", column: "engineName" })}
                        >
                            Engine
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === "bestMetricValue" ? direction : undefined}
                            onClick={() => dispatch({ type: "CHANGE_SORT", column: "bestMetricValue" })}
                        >
                            {metricLabel}
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === "duration" ? direction : undefined}
                            onClick={() => dispatch({ type: "CHANGE_SORT", column: "duration" })}
                        >
                            Duration
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === "created" ? direction : undefined}
                            onClick={() => dispatch({ type: "CHANGE_SORT", column: "created" })}
                        >
                            Created
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            textAlign={"center"}
                            sorted={column === "status" ? direction : undefined}
                            onClick={() => dispatch({ type: "CHANGE_SORT", column: "status" })}
                        >
                            Status
                        </Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {modelVersions.length === 0 ? (
                        <Table.Row>
                            <Table.Cell colSpan={9} textAlign={"center"} verticalAlign={"middle"}>
                                <Divider hidden />
                                <img src={"/model/emptyRepos.svg"} alt="" />
                                <Header as="h2" size={"medium"} style={{ marginBottom: "0.5rem" }}>
                                    Looks like you don't have any models yet!
                                </Header>
                                <Link style={{ fontSize: "0.9em" }} to={"/models/edit/train/repo/" + repo.id}>
                                    Train your first model version
                                </Link>
                                <Divider hidden />
                            </Table.Cell>
                        </Table.Row>
                    ) : null}
                    {data.map((model: ModelForTable) => {
                        const createdString = <LiveTimeAgo fromDate={new Date(model.created)} />;

                        const duration = getModelDuration(model);
                        const durationString = durationAsString(duration);

                        const metricValue = _.isNumber(model.bestMetricValue) ? (
                            <FormattedNumberTooltip value={model.bestMetricValue} />
                        ) : (
                            <GrayDash />
                        );

                        return (
                            <Table.Row key={model.id} className={model.archived ? "archived-model-row" : undefined}>
                                <Table.Cell>
                                    <Link
                                        className={metrics.BLOCK_AUTO_CAPTURE}
                                        onClick={() =>
                                            metrics.captureClick("Link.ModelVersion", {
                                                value: model.id,
                                            })
                                        }
                                        to={"/models/version/" + model.id}
                                    >
                                        {model.repoVersion}
                                    </Link>
                                </Table.Cell>
                                <Table.Cell>
                                    {repo.bestModelID === model.id && (
                                        <Popup
                                            content={"Model with the best " + metricLabel}
                                            trigger={<Icon name={"trophy"} color={"yellow"} />}
                                        />
                                    )}
                                </Table.Cell>
                                <EditDiv
                                    ogText={model.description}
                                    ogLabel={DescriptionLabel(model)}
                                    placeholder={"(No description)"}
                                    endpoint={"models/version/" + model.id}
                                    fitted
                                    textToRequest={(desc: string) => {
                                        return {
                                            id: model.id,
                                            description: desc,
                                            starred: model.starred,
                                            archived: model.archived,
                                        };
                                    }}
                                    callback={() =>
                                        queryClient.invalidateQueries({ queryKey: GET_MODEL_REPO_QUERY_KEY(repo.id) })
                                    }
                                    asTableCell={true}
                                />
                                <Table.Cell verticalAlign={"middle"}>{DatasetAndTargetLabels(model)}</Table.Cell>
                                <Table.Cell>{model.engine?.name}</Table.Cell>
                                <Table.Cell>{metricValue}</Table.Cell>
                                <Table.Cell>{durationString}</Table.Cell>
                                <Table.Cell>
                                    {createdString}
                                    <br />
                                    <span style={{ color: SEMANTIC_GREY, fontSize: "0.9em" }}>
                                        {model.user?.username || deletedUser}
                                    </span>
                                </Table.Cell>
                                <Table.Cell collapsing>
                                    {getStatusChipForModelsAndQueries(model.status as ModelStatus)}
                                </Table.Cell>
                                <Table.Cell collapsing>
                                    <Dropdown
                                        closeOnBlur
                                        direction="left"
                                        icon="ellipsis vertical"
                                        className="icon mini basic"
                                        style={{ color: SEMANTIC_GREY }}
                                    >
                                        <Dropdown.Menu style={{ borderStyle: "solid" }}>
                                            <Dropdown.Item
                                                onClick={() => {
                                                    metrics.capture("new_version_from_kebab");
                                                    user && track(user, "new_version_from_kebab");
                                                    window.open(`/models/edit/train/version/${model.id}`, "_blank");
                                                }}
                                            >
                                                New model from #{model.repoVersion}
                                            </Dropdown.Item>
                                            {
                                                // Trained, Explaining, Failed, - RNE dropdown appears.
                                                // Queued, Training, Preprocessing, Evaluating - RNE dropdown doesn't appear.
                                                !isTrainingStatus(model.status) && (
                                                    <Dropdown.Item
                                                        onClick={() => {
                                                            metrics.capture("recommend_next_experiments");
                                                            user && track(user, "recommend_next_experiments");
                                                            setRecommendedExperiementsModalModel(model);
                                                        }}
                                                    >
                                                        Recommend Next Experiments
                                                    </Dropdown.Item>
                                                )
                                            }
                                            {!(isLLMModel(model.config) && user?.isComputeLimited) && (
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        setRetrainModelSelection(model);
                                                        setRetrainModelModalOpen(true);
                                                    }}
                                                >
                                                    Retrain
                                                </Dropdown.Item>
                                            )}
                                            {model.starred ? (
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        resetMutation();
                                                        mutateStarOrArchiveModel({
                                                            modelID: model.id,
                                                            description: model.description,
                                                            star: false,
                                                            archive: false,
                                                        });
                                                    }}
                                                >
                                                    Unstar
                                                </Dropdown.Item>
                                            ) : (
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        resetMutation();
                                                        mutateStarOrArchiveModel({
                                                            modelID: model.id,
                                                            description: model.description,
                                                            star: true,
                                                            archive: false,
                                                        });
                                                    }}
                                                >
                                                    Star <Icon name={"star"} color={"yellow"} />
                                                </Dropdown.Item>
                                            )}
                                            {model.archived ? (
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        resetMutation();
                                                        mutateStarOrArchiveModel({
                                                            modelID: model.id,
                                                            description: model.description,
                                                            star: false,
                                                            archive: false,
                                                        });
                                                    }}
                                                >
                                                    Unarchive
                                                </Dropdown.Item>
                                            ) : (
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        resetMutation();
                                                        mutateStarOrArchiveModel({
                                                            modelID: model.id,
                                                            description: model.description,
                                                            star: false,
                                                            archive: true,
                                                        });
                                                    }}
                                                >
                                                    Archive
                                                </Dropdown.Item>
                                            )}
                                            {!isTerminalStatus(model.status) ? (
                                                <CancelModelButtonWithModal
                                                    model={model}
                                                    setErrorMessage={setErrorMessage}
                                                    callback={() =>
                                                        queryClient.invalidateQueries({
                                                            queryKey: GET_MODEL_REPO_QUERY_KEY(repo.id),
                                                        })
                                                    }
                                                    trigger={
                                                        <Dropdown.Item>
                                                            <span style={{ color: SEMANTIC_RED }}>Stop</span>
                                                        </Dropdown.Item>
                                                    }
                                                />
                                            ) : null}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
            {retrainModelSelection && (
                <RetrainModelModal
                    open={retrainModelModalOpen}
                    setOpen={setRetrainModelModalOpen}
                    header={`Retrain Model #${retrainModelSelection.repoVersion}`}
                    engineID={retrainModelSelection.engineID}
                    mutationFn={retrainModelMutation.mutate}
                />
            )}
            <RecommendNextExperimentsModal
                model={recommendedExperiementsModalModel}
                setModel={setRecommendedExperiementsModalModel}
                refreshFunc={() => queryClient.invalidateQueries({ queryKey: GET_MODEL_REPO_QUERY_KEY(repo.id) })}
            />
        </>
    );
};

export default memo(ModelVersionsTable);
