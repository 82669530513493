import React from "react";
import {
    CartesianGrid,
    Label,
    Legend,
    Line,
    LineChart,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { getColor } from "../../../../utils/constants";
import { formatValueToNumericString } from "../../../../utils/numbers";
import { isGBMModel } from "../../../util";

function ModelMetricsLineGraph(props: {
    models: Model[];
    isHyperopt: boolean;
    selectedRuns: string[];
    selectedMetrics: string[];
    chartData: ModelChartHistory;
    bestModelMetrics?: BestModelMetrics;
    runToNumberMap: React.MutableRefObject<Record<string, number>>;
    getMetricKey: (runID: string, metricName: string, shortened?: boolean) => string;
}) {
    const getLines = () => {
        return props.selectedRuns?.flatMap((runID, i) => {
            return props.selectedMetrics?.map((metricKey, j) => {
                const x = i * (j + i) + j;
                const key = props.getMetricKey(runID, metricKey, true);
                const dataKey = props.getMetricKey(runID, metricKey, false);
                return (
                    <Line
                        key={key}
                        type="monotone"
                        dataKey={dataKey}
                        name={key}
                        stroke={getColor(x)}
                        strokeWidth={2}
                        dot={false}
                    />
                );
            });
        });
    };

    if (!props.chartData) {
        return null;
    }

    const gbmModels = props.models.filter((m) => isGBMModel(m.config));
    const ecdModels = props.models.filter((m) => !isGBMModel(m.config));

    const xLabel =
        gbmModels.length > 0 && ecdModels.length > 0
            ? `Evaluation Checkpoints (neural network ${ecdModels.length == 1 ? "model" : "models"} ${ecdModels
                  .map((x) => x.repoVersion)
                  .join(",")}) and
        Evaluation Checkpoints (tree ${gbmModels.length == 1 ? "model" : "models"} ${gbmModels
            .map((x) => x.repoVersion)
            .join(",")})`
            : "Evaluation Checkpoints";

    // https://github.com/recharts/recharts/issues/172#issuecomment-307858843
    return (
        <ResponsiveContainer width={"99.8%"} aspect={2}>
            <LineChart data={props.chartData} style={{ overflow: "none" }}>
                <XAxis dataKey={(v) => props.chartData.indexOf(v) + 1}>
                    <Label value={xLabel} position={"bottom"} />
                </XAxis>
                <YAxis tickFormatter={(value, _) => formatValueToNumericString(value, 2)} />
                <Tooltip />
                <Legend wrapperStyle={{ bottom: -20, paddingBottom: "20px" }} />
                <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                {/** Don't render the valdiation line with a label if the very first epoch is the best. */}
                {props.bestModelMetrics?.best_eval_metric_checkpoint_number != null && (
                    <ReferenceLine
                        key="best_eval_reference_line"
                        x={Number(props.bestModelMetrics.best_eval_metric_checkpoint_number)}
                        stroke="red"
                        label={
                            props.bestModelMetrics.best_eval_metric_checkpoint_number === 1
                                ? ""
                                : "Best validation score"
                        }
                        strokeWidth={1}
                        strokeDasharray="5 5"
                    />
                )}
                {getLines()}
            </LineChart>
        </ResponsiveContainer>
    );
}

export default ModelMetricsLineGraph;
