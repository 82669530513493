import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { Form, FormField, Grid, Label, Radio, StrictDropdownProps } from "semantic-ui-react";
import { baseModel } from "../../../api_generated";
import Checkbox from "../../../components/Checkbox";
import Dropdown from "../../../components/Dropdown";
import Input from "../../../components/Input";
import Markdown from "../../../components/Markdown";
import FieldWrapper from "../../../components/forms/json-schema-form/fields/FieldWrapper";
import { titleInlineStyling } from "../../../components/forms/json-schema-form/styling";
import { handleLocalState } from "../../../models/create/forms/utils";
import { SEMANTIC_BLUE, SEMANTIC_WHITE } from "../../../utils/colors";
import { AdapterConfig } from "./schema";
import { useConfigState, useDispatch } from "./store";
import {
    adapterTypes,
    getAdapterDropdownOptions,
    getAdapterRankDropdownOptions,
    getLLMDropdownOptions,
    getSelectedLLM,
    getTargetModulesDefaultValue,
    getTargetModulesOptions,
    getTaskDropdownOptions,
} from "./utils";

const getInputValue = (
    path: string,
    localState: any,
    config?: AdapterConfig,
    defaultValue: string | boolean | number | undefined | null = "",
) => {
    if (_.has(localState, path)) {
        return _.get(localState, path);
    }

    if (_.has(config, path)) {
        return _.get(config, path);
    }

    return defaultValue;
};

const AdapterTypeRadioGroup = (props: {
    path: string;
    value: StrictDropdownProps["value"] | undefined;
    setConfig: (path: string, typedValue: any) => void;
    setLocalState: (localState: any, path: string) => void;
    selectedBaseModel: baseModel | undefined;
    continueFromAdapterType?: adapterTypes;
    readonly?: boolean;
}) => {
    // Parent props:
    const { path, value, setConfig, setLocalState, selectedBaseModel, continueFromAdapterType, readonly } = props;

    // Local state:
    const [selectedValue, setSelectedValue] = useState(value);
    // ! NOTE: On first render, the value prop is sometimes undefined when it logically shouldn't be (e.g. when viewing
    // trained adapters there is a delay in the context receving the up-to-date value), so we cannot reliably use the
    // setter inside of the onChange handler. Instead, we must use a useEffect that listens for changes to the prop:
    useEffect(() => {
        setSelectedValue(value);
    }, [value]);

    // Derived state:
    const options = getAdapterDropdownOptions(continueFromAdapterType, selectedBaseModel, readonly);

    return (
        <>
            {options.map((item) => (
                <FormField key={`radioGroupAlt_FormField_${item.key as string}`}>
                    <div style={{ display: "flex" }}>
                        <Radio
                            checked={selectedValue === item.value}
                            onChange={() => {
                                handleLocalState(path, item.value, setLocalState, setConfig);
                            }}
                            disabled={readonly || item.disabled}
                        />
                        <>{item.trigger}</>
                    </div>
                </FormField>
            ))}
        </>
    );
};

const ParametersForm = (props: {
    baseModels?: baseModel[];
    modelDescription: string;
    nextVersion?: number;
    readOnly?: boolean;
    setModelDescription: React.Dispatch<React.SetStateAction<string>>;
    continueFromAdapterType?: adapterTypes;
    hideParametersUnsupportedByContinuedTraining?: boolean;
    continueDatasetsMatch?: boolean;
}) => {
    // Parent state:
    const {
        baseModels,
        modelDescription,
        nextVersion,
        readOnly,
        setModelDescription,
        continueFromAdapterType,
        hideParametersUnsupportedByContinuedTraining,
        continueDatasetsMatch,
    } = props;
    const { config, schema } = useConfigState();
    const dispatch = useDispatch();

    // In order to communicate to React Tracked that this component always
    // needs to be re-rendered when the config object changes, we create this
    // unused variable that stringifies the entire object.
    // The bug we were running into was that when submitting the config to the
    // server, we were submitting a stale version of the config that did not get
    // updated until the component was re-rendered.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const configJSON = JSON.stringify(config); // DO NOT DELETE!

    // Local state:
    const [localState, setLocalState] = useState({});

    // Event listeners
    const updateLocalState = (
        path: string,
        value: string | boolean | (string | number | boolean | undefined | null)[],
    ) => {
        if (path === "base_model") {
            const newSelectedBaseModel = getSelectedLLM(value as string, baseModels);
            _.set(localState, "target_modules", getTargetModulesDefaultValue(newSelectedBaseModel));
        }
        setLocalState(_.set(localState, path, value));
    };

    const updateConfigValue = (path: string, convertedValue: string | boolean) => {
        if (path === "base_model") {
            const newSelectedBaseModel = getSelectedLLM(convertedValue as string, baseModels);
            dispatch({
                type: "UPDATE_CONFIG_PROPERTY",
                field: "target_modules",
                value: getTargetModulesDefaultValue(newSelectedBaseModel),
            });
        }
        // If adapter is turbo, completely remove target_modules as a field
        if (path === "adapter" && convertedValue === "turbo") {
            dispatch({ type: "REMOVE_CONFIG_PROPERTY", field: "target_modules" });
        }
        dispatch({ type: "UPDATE_CONFIG_PROPERTY", field: path, value: convertedValue });
    };

    let selectedBaseModel = useMemo(
        () => getSelectedLLM(config?.base_model, baseModels),
        [config?.base_model, baseModels],
    );
    let targetModulesOptions = useMemo(
        () => getTargetModulesOptions(selectedBaseModel, config?.target_modules),
        [selectedBaseModel],
    );
    let targetModulesDefaultValue = useMemo(() => getTargetModulesDefaultValue(selectedBaseModel), [selectedBaseModel]);

    const isInstructModel = Boolean(selectedBaseModel?.canonicalName.toLowerCase().includes("instruct"));
    const continueMode = Boolean(continueFromAdapterType);
    const hideLearningRate = continueMode && continueDatasetsMatch;

    return (
        <Grid>
            <Grid.Row columns={2} style={{ paddingBottom: 0, marginTop: `${16 / 14}rem` }}>
                <Grid.Column>
                    <Form>
                        {!continueMode && (
                            <FieldWrapper
                                title="Large Language Model to Fine-tune"
                                description="Pick from a set of popular LLMs of different sizes across a variety of architecture types."
                                path="base_model"
                                schema={{}}
                                style={{ display: "inherit" }}
                            >
                                <Dropdown
                                    path="base_model"
                                    title="Large Language Model to Fine-tune"
                                    error={false}
                                    multiple={false}
                                    fluid={true}
                                    options={getLLMDropdownOptions(config?.base_model, baseModels, config)}
                                    value={getInputValue("base_model", localState, config)}
                                    defaultValue=""
                                    readOnly={readOnly}
                                    setConfig={updateConfigValue}
                                    setLocalState={updateLocalState}
                                />
                            </FieldWrapper>
                        )}
                        <FieldWrapper
                            title="Adapter Type"
                            description=""
                            path="adapter"
                            schema={{}}
                            style={{ display: "inherit" }}
                        >
                            {/* ? NOTE: For spacing: */}
                            <div style={{ marginBottom: "1rem" }} />
                            <AdapterTypeRadioGroup
                                path="adapter"
                                value={getInputValue("adapter", localState, config)}
                                setConfig={updateConfigValue}
                                setLocalState={updateLocalState}
                                selectedBaseModel={selectedBaseModel}
                                continueFromAdapterType={continueFromAdapterType}
                                readonly={readOnly}
                            />
                        </FieldWrapper>
                        <FieldWrapper
                            title="Fine-Tuning Task"
                            description="Choose the type of task to fine-tune the base model to complete. [Learn more about the available task types in the docs.](https://docs.predibase.com/sdk-guide/SDKv2/ConfigClasses/FineTuningConfig#task)"
                            path="task"
                            schema={{}}
                            style={{ display: "inherit" }}
                        >
                            <Dropdown
                                path="task"
                                title="Fine-Tuning Task"
                                error={false}
                                multiple={false}
                                fluid={true}
                                options={getTaskDropdownOptions(selectedBaseModel)}
                                value={getInputValue("task", localState, config)}
                                defaultValue={schema?.properties.task.default}
                                readOnly={readOnly}
                                setConfig={updateConfigValue}
                                setLocalState={updateLocalState}
                            />
                        </FieldWrapper>
                        {isInstructModel && (
                            <FieldWrapper path="apply_chat_template" schema={{}} style={{ display: "inherit" }}>
                                <div style={{ display: "inline-block" }}>
                                    <Checkbox
                                        path="apply_chat_template"
                                        error={false}
                                        value={getInputValue("apply_chat_template", localState, config) === true}
                                        readOnly={readOnly}
                                        onChange={(clicked) => {
                                            const newValue = clicked ? true : false;
                                            updateLocalState("apply_chat_template", newValue);
                                            updateConfigValue("apply_chat_template", newValue);
                                        }}
                                        style={{ width: "100%" }}
                                    />
                                    <Label
                                        size="small"
                                        style={{ color: SEMANTIC_WHITE, backgroundColor: SEMANTIC_BLUE }}
                                    >
                                        {" "}
                                        New{" "}
                                    </Label>{" "}
                                    &nbsp;
                                    <span style={{ ...titleInlineStyling }}> Apply Chat Template</span>
                                </div>
                                <div style={{ marginLeft: "2em", marginTop: "0.5em" }}>
                                    <Markdown
                                        secondary={true}
                                        children="Model-specific chat templates are known to boost performance. You may include chat templates in the prompts in your dataset, or we can apply the chat template for you during fine-tuning. Note that you will need include the chat template at inference time. [Learn more about the chat templates.](https://docs.predibase.com/user-guide/fine-tuning/instruction_formats)"
                                    />
                                </div>
                            </FieldWrapper>
                        )}
                        {!continueMode && !hideParametersUnsupportedByContinuedTraining && (
                            <FieldWrapper
                                title="Adapter Rank"
                                description="Increasing the adapter rank increases the capacity of your fine-tuned model. Higher model capacity may improve training performance, but may also increase GPU memory requirements and training duration."
                                path="rank"
                                schema={{}}
                                style={{ display: "inherit" }}
                            >
                                <Dropdown
                                    path="rank"
                                    title="Adapter Rank"
                                    error={false}
                                    multiple={false}
                                    fluid={true}
                                    options={getAdapterRankDropdownOptions(config)}
                                    value={getInputValue("rank", localState, config)}
                                    defaultValue={schema?.properties.rank.default}
                                    readOnly={readOnly}
                                    setConfig={updateConfigValue}
                                    setLocalState={updateLocalState}
                                />
                            </FieldWrapper>
                        )}
                        {!continueMode && !hideParametersUnsupportedByContinuedTraining && (
                            <FieldWrapper
                                title="Target Modules"
                                description="Adding target modules increases the capacity of your fine-tuned model. More target modules may improve training performance, but may also increase GPU memory requirements and training duration."
                                path="target_modules"
                                schema={{}}
                                style={{ display: "inherit" }}
                            >
                                <Dropdown
                                    path="target_modules"
                                    title="Target Modules"
                                    error={false}
                                    multiple={true}
                                    fluid={true}
                                    options={targetModulesOptions}
                                    value={getInputValue("target_modules", localState, config)}
                                    defaultValue={targetModulesDefaultValue}
                                    readOnly={readOnly}
                                    setConfig={updateConfigValue}
                                    setLocalState={updateLocalState}
                                />
                            </FieldWrapper>
                        )}
                        <FieldWrapper
                            title="Epochs"
                            description="Number of times the adapter sees the entire dataset."
                            path="epochs"
                            schema={{}}
                            style={{ display: "inherit" }}
                        >
                            <Input
                                path="epochs"
                                title="Epochs"
                                error={false}
                                value={getInputValue("epochs", localState, config)}
                                defaultValue={schema?.properties.epochs.default}
                                readOnly={readOnly}
                                setConfig={updateConfigValue}
                                setLocalState={updateLocalState}
                                schemaPath="epochs"
                                style={{ width: "100%" }}
                            />
                        </FieldWrapper>
                        {!hideLearningRate && (
                            <FieldWrapper
                                title="Learning Rate"
                                description="Size of the adapter weights update at each batch."
                                path="learning_rate"
                                schema={{}}
                                style={{ display: "inherit" }}
                            >
                                <Input
                                    path="learning_rate"
                                    title="Learning Rate"
                                    error={false}
                                    value={getInputValue("learning_rate", localState, config)}
                                    defaultValue={schema?.properties.learning_rate.default}
                                    readOnly={readOnly}
                                    setConfig={updateConfigValue}
                                    setLocalState={updateLocalState}
                                    schemaPath="learning_rate"
                                    style={{ width: "100%" }}
                                />
                            </FieldWrapper>
                        )}
                        <FieldWrapper
                            title="Enable Early Stopping"
                            description="Enable early stopping of training if validation loss plateaus."
                            path="enable_early_stopping"
                            schema={{}}
                            style={{ display: "inherit" }}
                        >
                            <Checkbox
                                path="enable_early_stopping"
                                label="Enable Early Stopping"
                                error={false}
                                value={getInputValue("enable_early_stopping", localState, config, true)}
                                readOnly={readOnly}
                                onChange={(checked) => {
                                    const newValue = Boolean(checked);
                                    updateLocalState("enable_early_stopping", newValue);
                                    updateConfigValue("enable_early_stopping", newValue);
                                }}
                                style={{ width: "100%" }}
                            />
                        </FieldWrapper>
                        <FieldWrapper
                            title={nextVersion ? `Description for Version ${nextVersion}` : "Description"}
                            description="What you intend to try in this experiment (ex. “first model” or “learning rate auto”)"
                            path="description"
                            schema={{}}
                            style={{ display: "inherit" }}
                        >
                            <Input
                                path="description"
                                title="Description"
                                error={false}
                                value={modelDescription}
                                defaultValue=""
                                readOnly={readOnly}
                                setConfig={() => {}}
                                setLocalState={(path, value) => setModelDescription(value)}
                                schemaPath="description"
                                style={{ width: "100%" }}
                            />
                        </FieldWrapper>
                    </Form>
                </Grid.Column>
                <Grid.Column></Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default ParametersForm;
