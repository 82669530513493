import { QueryClient, useMutation } from "@tanstack/react-query";
import { Button, Modal } from "semantic-ui-react";
import { getTraceId } from "../api/trace";
import { Auth0TokenOptions, useAuth0TokenOptions } from "../data";
import metrics from "../metrics/metrics";
import { RecommendedExperiment } from "../predibase_api/iterml/v1/iterml";
import { GET_USER_CREDITS_QUERY_KEY } from "../query";
import { createV1APIServer, redirectIfSessionInvalid } from "../utils/api";
import { getErrorMessage } from "../utils/errors";
import { cancelModelVersion } from "./data";
import { isTerminalStatus } from "./util";

export const CancelModelButtonWithModal = (props: {
    model: Model;
    setErrorMessage: Function;
    callback: Function;
    trigger?: JSX.Element;
}) => {
    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    // Query state:
    const { mutate: cancelVersion, reset: resetMutation } = useMutation({
        mutationFn: () => cancelModelVersion(props.model.id, auth0TokenOptions),
        onSuccess: () => {
            props.callback();
        },
        onError: (error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            props.setErrorMessage(errorMsg);
        },
    });

    return (
        <Modal
            className={metrics.BLOCK_AUTO_CAPTURE}
            trigger={
                props.trigger || (
                    <Button
                        className={metrics.BLOCK_AUTO_CAPTURE}
                        disabled={isTerminalStatus(props.model.status)}
                        content="Cancel Latest"
                        icon="delete"
                        size="tiny"
                        basic
                        negative
                    />
                )
            }
            header={"Stop model #" + props.model.repoVersion}
            content="Are you sure?"
            size="mini"
            actions={[
                { key: "close", content: "Close", onClick: () => resetMutation() },
                {
                    key: "delete",
                    content: "Confirm",
                    negative: true,
                    onClick: () => {
                        resetMutation();
                        cancelVersion();
                    },
                },
            ]}
        />
    );
};

const trainModel = async (
    auth0TokenOptions: Auth0TokenOptions,
    model: Model,
    config: CreateModelConfig,
    description: string,
    queryClient: QueryClient,
) => {
    const modelType = "ludwig";
    const endpoint = "models/train";

    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .post(
            endpoint,
            {
                modelType,
                description: description,
                datasetID: model.datasetID,
                config: config,
                repoID: model.repoID,
                parentID: model.id,
                engineID: model.engineID, // TODO: Use engine selected from from dropdown.
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            },
        )
        .then((res) => {
            // const errorMessage = res.data.errorMessage || "";
            // setErrorMessage(errorMessage);
            // Training uses credits; invalidate the credit cache.
            queryClient.invalidateQueries({ queryKey: GET_USER_CREDITS_QUERY_KEY });
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            // setErrorMessage(errorMsg);
            metrics.captureError("api_error", errorMsg, {
                method: "POST",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
        });
};

/**
 * Given a list of recommended experiments, creates and makes API calls to the server to kick off each experiement.
 * @param recommendedExperiments
 * @param model
 * @returns Promise
 */
export const trainRecommendedExperiments = (
    auth0TokenOptions: Auth0TokenOptions,
    recommendedExperiments: RecommendedExperiment[],
    model: Model,
    queryClient: QueryClient,
) => {
    const trainModelPromises: any[] = [];
    recommendedExperiments.forEach((recommendedExperiment: RecommendedExperiment) => {
        const parsedConfig = JSON.parse(recommendedExperiment.newConfigJsonString);
        trainModelPromises.push(
            trainModel(
                auth0TokenOptions,
                model,
                parsedConfig,
                `Trained from #${model.repoVersion}: ${recommendedExperiment.modelVersionDescription}`,
                queryClient,
            ),
        );
    });

    return Promise.all(trainModelPromises);
};
