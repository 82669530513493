import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { Button, Divider, Form, Icon, Message, Modal } from "semantic-ui-react";
import CopyButton from "../components/CopyButton";
import { Environment, useAuth0TokenOptions } from "../data";
import metrics from "../metrics/metrics";
import { useEnvironmentsQuery } from "../query";
import { EngineServiceType } from "../types/engineServiceType";
import { isNotProduction } from "../utils/environment";
import { getErrorMessage } from "../utils/errors";
import { FeatureFlagsContext } from "../utils/feature-flags";
import { createSystemAPIToken, deleteEngine, updateEngineSpec } from "./data";
import EngineNodeTable from "./EngineNodeTable";
import { EngineTooltip } from "./EngineTooltips";
import {
    onAutoResumeChange,
    onAutoSuspendBlur,
    onAutoSuspendChange,
    onChangeForGenericFormInput,
    onEnvironmentChange,
    onTemplateChange,
} from "./modal-utils";
import { GET_ENGINES_QUERY_KEY, useEngineSchemaQuery } from "./query";

const NestedModalDeleteButton = (props: {
    engineSpec: EngineSpec;
    setErrorMessage: Dispatch<SetStateAction<string | null>>;
    setLoading: Dispatch<SetStateAction<boolean>>;
}) => {
    // Parent state:
    const { engineSpec, setErrorMessage, setLoading } = props;

    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    // Query state:
    const { mutate: mutateDeleteEngine, reset: resetMutation } = useMutation({
        mutationFn: () => deleteEngine(engineSpec.id, auth0TokenOptions),
        onSuccess: (res) => {
            setErrorMessage(null);
        },
        onError: (error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            setErrorMessage(errorMsg);
        },
        onSettled: () => {
            setLoading(false);
        },
    });

    return (
        <Modal
            trigger={
                <Button
                    style={{ float: "left" }}
                    className={metrics.BLOCK_AUTO_CAPTURE}
                    content="Delete"
                    labelPosition="right"
                    icon="trash"
                    negative
                />
            }
            header={"Delete " + engineSpec.name}
            content="Are you sure?"
            size="mini"
            actions={[
                { key: "cancel", content: "Cancel", onClick: () => resetMutation() },
                { key: "delete", content: "Delete", negative: true, onClick: () => mutateDeleteEngine() },
            ]}
        />
    );
};

const ModalContent = (props: {
    engineSpec: EngineSpec;
    engineSchema: EngineSchema;
    envs: Environment[];
    setEngineSpec: Dispatch<SetStateAction<EngineSpec | undefined | null>>;
    engineServiceType: EngineServiceType;
}) => {
    // Parent state:
    const { engineSchema, envs, engineSpec, setEngineSpec, engineServiceType } = props;

    // Context state:
    const { featureFlags } = useContext(FeatureFlagsContext);

    // Dropdown options:
    const templateDropdownOptions = engineSchema.templates
        .filter((template) => template.serviceType === engineServiceType)
        .map((template) => {
            return { text: template.name, value: template.id };
        });
    const envDropdownOptions = envs.map((env) => {
        return { text: env.name, value: env.id };
    });

    let template = null;
    if (engineSpec != null) {
        let templates = engineSchema.templates.reduce((r: Map<number, EngineTemplate>, a: EngineTemplate) => {
            r.set(a.id, a);
            return r;
        }, new Map());
        const templateID = engineSpec.templateID;
        if (templateID != null) {
            template = templates.get(templateID);
        }
    }

    return (
        <Form>
            <Form.Input
                autoFocus
                name="name"
                label="Name"
                placeholder="Name"
                value={engineSpec?.name || ""}
                onChange={(event) => onChangeForGenericFormInput(event, engineSpec, setEngineSpec)}
            />

            <Form.Group widths="equal">
                <Form.Select
                    name="template"
                    label="Template"
                    options={templateDropdownOptions}
                    value={engineSpec?.templateID}
                    placeholder="Template"
                    onChange={(_, data) => onTemplateChange(data, engineSpec, setEngineSpec)}
                />
            </Form.Group>
            {featureFlags["Engine Environment Show/Hide"] ? (
                <Form.Group widths="equal">
                    <Form.Select
                        name="environment"
                        label="Environment"
                        options={envDropdownOptions}
                        value={engineSpec?.environmentID}
                        placeholder="Environment"
                        onChange={(_, data) => onEnvironmentChange(data, engineSpec, setEngineSpec)}
                    />
                </Form.Group>
            ) : null}
            <Message info header={template?.name}>
                {template?.description}
                <Divider hidden style={{ padding: "10px" }} />
                {template && (
                    <EngineNodeTable
                        template={template}
                        filteredCloud={envs.find((x) => x.id === engineSpec?.environmentID)?.cloud}
                    />
                )}
            </Message>
            {engineServiceType === EngineServiceType.RAY && (
                <div>
                    <Divider />
                    <Form.Input
                        type="number"
                        min="0"
                        name="autoSuspendSeconds"
                        label={<EngineTooltip name="autoSuspendSeconds" label="Auto Suspend Seconds" />}
                        placeholder={engineSpec?.autoSuspendSeconds || "3600"}
                        value={engineSpec?.autoSuspendSeconds ? engineSpec?.autoSuspendSeconds : "3600"}
                        onChange={(event) => onAutoSuspendChange(event, engineSpec, setEngineSpec)}
                        onBlur={(event: React.ChangeEvent<HTMLInputElement>) =>
                            onAutoSuspendBlur(event, engineSpec, setEngineSpec)
                        }
                    />
                    <Form.Checkbox
                        toggle
                        name={"autoResume"}
                        label={<EngineTooltip name="autoResume" label="Auto Resume" />}
                        onClick={(_, data) => onAutoResumeChange(data, engineSpec, setEngineSpec)}
                        defaultChecked={engineSpec?.autoResume}
                    />
                </div>
            )}
        </Form>
    );
};

const UpdateEngineModal = (props: {
    engine: Engine | null;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
}) => {
    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    // Parent state:
    const { engine, open, setOpen } = props;

    // Local state:
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [engineSchema, setEngineSchema] = useState<EngineSchema>();
    const [envs, setEnvs] = useState<Environment[]>([]);
    const [apiToken, setApiToken] = useState<string | null>(null);

    // Initialized from parent state:
    const [engineSpec, setEngineSpec] = useState<EngineSpec | undefined | null>(engine);
    const engineServiceType = engine?.serviceType;

    // Query state:
    const queryClient = useQueryClient();
    const { data: engineSchemaResponse, error: engineSchemaError } = useEngineSchemaQuery();
    const { data: environments, error: environmentsError } = useEnvironmentsQuery();
    useEffect(() => {
        if (!engineSchemaResponse || !environments) {
            return;
        }

        setEngineSchema(engineSchemaResponse);
        setEnvs(environments);
        setErrorMessage(null);
    }, [engineSchemaResponse, environments]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (engineSchemaError) {
            const errorMsg = getErrorMessage(engineSchemaError);
            setErrorMessage(errorMsg);
        } else if (environmentsError) {
            const errorMsg = getErrorMessage(environmentsError);
            setErrorMessage(errorMsg);
        }
    }, [engineSchemaError, environmentsError]);

    // Clear out internal state since component is always mounted
    useEffect(() => {
        setEngineSpec(engine);
    }, [open]);

    const { mutate: mutateCreateSystemAPIToken, reset: resetAPITokenMutation } = useMutation({
        mutationFn: () => createSystemAPIToken(engine?.id ?? -1, auth0TokenOptions),
        onSuccess: (res) => {
            setApiToken(res);
            setErrorMessage(null);
        },
        onError: (error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            setErrorMessage(errorMsg);
        },
    });

    const { mutate: mutateEngineSpec, reset: resetEngineSpecMutation } = useMutation({
        mutationFn: () => updateEngineSpec(engineSpec!, auth0TokenOptions),
        onMutate: () => {
            setLoading(true);
        },
        onSuccess: (res) => {
            setErrorMessage(null);
            // const final = res || engineSpec; // TODO: Huh?
            // props.onSubmit(res, EngineAction.UPDATE);
            setOpen(false);
            queryClient.invalidateQueries({ queryKey: GET_ENGINES_QUERY_KEY });
        },
        onError: (error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            setErrorMessage(errorMsg);
        },
        onSettled: () => {
            setLoading(false);
        },
    });

    return (
        <Modal
            className={metrics.BLOCK_AUTO_CAPTURE}
            onClose={() => {
                setOpen(false);
                setApiToken(null);
                resetAPITokenMutation();
                resetEngineSpecMutation();
            }}
            onOpen={() => setOpen(true)}
            open={open}
        >
            <Modal.Header>Update Engine</Modal.Header>
            <Modal.Content>
                {engineSchema && engineSpec && (
                    <ModalContent
                        engineSpec={engineSpec}
                        engineSchema={engineSchema}
                        envs={envs}
                        setEngineSpec={setEngineSpec}
                        engineServiceType={engineServiceType}
                    />
                )}
                {errorMessage ? (
                    <Message negative>
                        <Message.Header>Error in engine request</Message.Header>
                        <p>{errorMessage}</p>
                    </Message>
                ) : null}
                <Divider hidden />
                {isNotProduction() && (
                    <Button
                        className={metrics.BLOCK_AUTO_CAPTURE}
                        id="generateSystemAPIToken"
                        icon
                        labelPosition="left"
                        size="small"
                        onClick={() => {
                            resetAPITokenMutation();
                            mutateCreateSystemAPIToken();
                        }}
                    >
                        <Icon name="key" /> Generate System API token
                    </Button>
                )}
                {apiToken && (
                    <Message>
                        <Message.Header>
                            API token &ensp;
                            <CopyButton text={apiToken} />
                        </Message.Header>
                        <p style={{ wordBreak: "break-all" }}>{apiToken}</p>
                    </Message>
                )}
            </Modal.Content>
            <Modal.Actions>
                {engineSpec && (
                    <NestedModalDeleteButton
                        engineSpec={engineSpec}
                        setErrorMessage={setErrorMessage}
                        setLoading={setLoading}
                    />
                )}
                <Button
                    className={metrics.BLOCK_AUTO_CAPTURE}
                    onClick={() => {
                        setOpen(false);
                        resetEngineSpecMutation();
                        resetAPITokenMutation();
                    }}
                >
                    Cancel
                </Button>
                <Button
                    content="Update"
                    labelPosition="right"
                    icon="checkmark"
                    onClick={() => {
                        resetEngineSpecMutation();
                        mutateEngineSpec();
                    }}
                    loading={loading}
                    disabled={engineSpec?.name === undefined || engineSpec?.name === ""}
                    positive
                />
            </Modal.Actions>
        </Modal>
    );
};

export default UpdateEngineModal;
