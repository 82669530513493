import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import {
    Button,
    Divider,
    Dropdown,
    Grid,
    Header,
    Icon,
    Input,
    Message,
    Modal,
    Popup,
    Segment,
} from "semantic-ui-react";
import { activateToken, createToken, deleteToken, expireToken, updateToken } from "../api/data";
import { GET_API_TOKENS_QUERY_KEY, useTokensQuery } from "../api/query";
import CopyButton from "../components/CopyButton";
import { useAuth0TokenOptions } from "../data";
import { track } from "../metrics/june";
import metrics from "../metrics/metrics";
import { USER_STATE } from "../state/global";
import { SEMANTIC_GREY } from "../utils/colors";
import dayjsExtended from "../utils/dayjs";
import { getErrorMessage } from "../utils/errors";

const CreateTokenButton = (props: { disabled: boolean; setOpen: React.Dispatch<React.SetStateAction<boolean>> }) => {
    const { disabled, setOpen } = props;

    if (disabled) {
        return (
            <Popup
                content="You can only have two API tokens at a time."
                trigger={
                    <div style={{ display: "inline-block" }}>
                        <Button primary size="small" className={metrics.BLOCK_AUTO_CAPTURE} disabled>
                            Create API Token
                        </Button>
                    </div>
                }
            />
        );
    }

    return (
        <Button
            primary
            size="small"
            className={metrics.BLOCK_AUTO_CAPTURE}
            disabled={disabled}
            onClick={() => setOpen(true)}
        >
            Create API Token
        </Button>
    );
};

const CreateTokenModal = (props: { disabled: boolean }) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    const [open, setOpen] = useState(false);
    const [tokenDescription, setTokenDescription] = useState("");
    const [userContext] = useRecoilState(USER_STATE);

    const queryClient = useQueryClient();
    const {
        mutate: mutateCreateToken,
        reset: resetMutation,
        isPending: createIsPending,
        isError: createIsErrored,
        error: createTokenError,
    } = useMutation({
        mutationFn: () => createToken(tokenDescription, auth0TokenOptions),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: GET_API_TOKENS_QUERY_KEY });
            setOpen(false);
        },
    });

    useEffect(() => {
        resetMutation();
        setTokenDescription("");
    }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <CreateTokenButton disabled={props.disabled} setOpen={setOpen} />
            <Modal open={open} size="small">
                <Modal.Header>Create API Token</Modal.Header>
                <Modal.Content>
                    {createIsErrored && (
                        <Message negative>
                            <Message.Header>Error Editing Token</Message.Header>
                            <p>{getErrorMessage(createTokenError)}</p>
                        </Message>
                    )}
                    <p>
                        Describe the purpose of this API token and where it will be used. A good description will help
                        you rotate this API token confidently later.
                    </p>
                    <Input
                        fluid
                        autoFocus
                        placeholder="e.g. Predibase CLI"
                        value={tokenDescription}
                        onChange={(e, { value }) => setTokenDescription(value)}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={() => {
                            setOpen(false);
                            resetMutation();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        positive
                        disabled={createIsPending}
                        loading={createIsPending}
                        onClick={() => {
                            // Purposely updated to match PostHog's suggested naming convention
                            metrics.capture("api_token_generated", { page: "settings_page" });
                            userContext && track(userContext, "generate_api_token", { page: "settings_page" });
                            resetMutation();
                            mutateCreateToken();
                        }}
                    >
                        Create
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};

const EditTokenModal = (props: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    token: Token;
}) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    const { open, setOpen, token } = props;
    const [tokenDescription, setTokenDescription] = useState(token?.description ?? "");
    const queryClient = useQueryClient();

    const {
        mutate: editToken,
        reset: resetMutation,
        isError: editIsErrored,
        error: editTokenError,
        isPending: editIsPending,
    } = useMutation({
        mutationFn: () => updateToken(token.token, tokenDescription, auth0TokenOptions),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: GET_API_TOKENS_QUERY_KEY });
            setOpen(false);
        },
    });

    useEffect(() => {
        resetMutation();
        setTokenDescription(token?.description ?? "");
    }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Modal open={open} onClose={() => setOpen(false)} size="small">
            <Modal.Header>Edit API Token</Modal.Header>
            <Modal.Content>
                {editIsErrored && (
                    <Message negative>
                        <Message.Header>Error Editing Token</Message.Header>
                        <p>{getErrorMessage(editTokenError)}</p>
                    </Message>
                )}
                <p>
                    Describe the purpose of this API token and where it will be used. A good description will help you
                    rotate this API token confidently later.
                </p>
                <Input
                    fluid
                    autoFocus
                    placeholder="e.g. Predibase CLI"
                    value={tokenDescription}
                    onChange={(e, { value }) => setTokenDescription(value)}
                />
            </Modal.Content>
            <Modal.Actions>
                <Button
                    onClick={() => {
                        setOpen(false);
                        resetMutation();
                    }}
                >
                    Cancel
                </Button>
                <Button
                    positive
                    disabled={editIsPending}
                    loading={editIsPending}
                    onClick={() => {
                        resetMutation();
                        editToken();
                    }}
                >
                    Edit
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

const ExpireTokenModal = (props: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    token: Token;
}) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    const { open, setOpen, token } = props;
    const queryClient = useQueryClient();

    const {
        mutate: expire,
        isPending: expireIsPending,
        isError: expireIsErrored,
        reset: resetMutation,
        error: expireTokenError,
    } = useMutation({
        mutationFn: () => expireToken(token.token, auth0TokenOptions),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: GET_API_TOKENS_QUERY_KEY });
            setOpen(false);
        },
    });

    useEffect(() => {
        resetMutation();
    }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

    const onExpireTokenClick = () => {
        resetMutation();
        expire();
    };

    return (
        <Modal open={open} onClose={() => setOpen(false)} size="small">
            <Modal.Header>Expire API Token</Modal.Header>
            <Modal.Content>
                {expireIsErrored && (
                    <Message negative>
                        <Message.Header>Error Expiring Token</Message.Header>
                        <p>{getErrorMessage(expireTokenError)}</p>
                    </Message>
                )}
                <p>
                    Deactivate API token <strong>{token.token}</strong>? You can't use an inactive key to make AWS API
                    calls but you can activate it again later.
                </p>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <Button negative disabled={expireIsPending} loading={expireIsPending} onClick={onExpireTokenClick}>
                    Expire
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

const DeleteTokenModal = (props: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    token: Token;
}) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    const { open, setOpen, token } = props;
    const [deleteTokenConfirmation, setDeleteTokenConfirmation] = useState<string>("");
    const [tokenIdMatches, setTokenIdMatches] = useState<boolean>(false);
    const queryClient = useQueryClient();

    const {
        mutate: mutateDeleteToken,
        isPending: deleteIsPending,
        isError: deleteIsErrored,
        reset: resetMutation,
        error: deleteTokenError,
    } = useMutation({
        mutationFn: () => deleteToken(token.token, auth0TokenOptions),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: GET_API_TOKENS_QUERY_KEY });
        },
    });

    useEffect(() => {
        resetMutation();
        setDeleteTokenConfirmation("");
        setTokenIdMatches(false);
    }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

    const onDeleteTokenClick = () => {
        resetMutation();
        mutateDeleteToken();
    };

    return (
        <Modal open={open} onClose={() => setOpen(false)} size="small">
            <Modal.Header>Delete API Token</Modal.Header>
            <Modal.Content>
                {deleteIsErrored && (
                    <Message negative>
                        <Message.Header>Error Expiring Token</Message.Header>
                        <p>{getErrorMessage(deleteTokenError)}</p>
                    </Message>
                )}
                <p>
                    Delete API token <strong>{token.token}</strong>? This will immediately revoke access to all
                    Predibase APIs.
                </p>
                {/* TODO: Show when last used? */}
                <Divider />
                <p>To confirm deletion, enter the API token ID in the text input field.</p>
                <Input
                    fluid
                    autoFocus
                    placeholder={token.token}
                    value={deleteTokenConfirmation}
                    onChange={(e, { value }) => {
                        setDeleteTokenConfirmation(value);
                        setTokenIdMatches(value === token.token);
                    }}
                />
            </Modal.Content>
            <Modal.Actions>
                <Button
                    onClick={() => {
                        setOpen(false);
                        resetMutation();
                    }}
                >
                    Cancel
                </Button>
                <Button
                    negative
                    disabled={!tokenIdMatches || deleteIsPending}
                    loading={deleteIsPending}
                    onClick={onDeleteTokenClick}
                >
                    Delete
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

const APITokenCard = (props: { token: Token }) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    const { token } = props;
    const redactedToken = token.token.substring(0, 7) + token.token.substring(7).replaceAll(/\w/g, "*");

    const [showFullToken, setShowFullToken] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [expireModalOpen, setExpireModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const queryClient = useQueryClient();
    const {
        mutate: activate,
        reset: resetMutation,
        isError: activateIsErrored,
        error: activateTokenError,
    } = useMutation({
        mutationFn: () => activateToken(token.token, auth0TokenOptions),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: GET_API_TOKENS_QUERY_KEY });
        },
    });

    const tokenExpired = Boolean(token.expires_at && dayjsExtended(token.expires_at).isBefore(dayjsExtended()));

    return (
        <>
            {activateIsErrored && (
                <Message negative>
                    <Message.Header>Error Activating Token</Message.Header>
                    <p>{getErrorMessage(activateTokenError)}</p>
                </Message>
            )}
            <Segment>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ display: "flex" }}>
                        <Header as="h3" size="small" style={{ minWidth: "16.357rem" }}>
                            {showFullToken ? token.token : redactedToken}
                        </Header>
                        <Button
                            size="mini"
                            icon={showFullToken ? "eye slash" : "eye"}
                            style={{ marginTop: "-15px", background: "none", marginRight: 0 }}
                            onClick={() => setShowFullToken((showFullToken) => !showFullToken)}
                            aria-label={`${showFullToken ? "hide" : "show"} redacted token`}
                        />
                        <CopyButton
                            text={token.token}
                            style={{ padding: 0, paddingLeft: "0.5rem", marginTop: "-15px" }}
                        />
                    </div>
                    <Dropdown text="Actions">
                        <Dropdown.Menu>
                            <Dropdown.Item
                                text="Edit description"
                                disabled={tokenExpired}
                                onClick={() => {
                                    resetMutation();
                                    setEditModalOpen(true);
                                }}
                            />
                            <Dropdown.Item
                                text="Deactivate"
                                disabled={tokenExpired}
                                onClick={() => {
                                    resetMutation();
                                    setExpireModalOpen(true);
                                }}
                            />
                            <Dropdown.Item
                                text="Activate"
                                disabled={!tokenExpired}
                                onClick={() => {
                                    resetMutation();
                                    activate();
                                }}
                            />
                            <Dropdown.Item
                                text="Delete"
                                onClick={() => {
                                    resetMutation();
                                    setDeleteModalOpen(true);
                                }}
                            />
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <Grid stackable columns={2}>
                    <Grid.Column>
                        <strong>Description</strong>
                        <p>{token?.description}</p>
                    </Grid.Column>
                    <Grid.Column>
                        <strong>Created</strong>
                        <p>{dayjsExtended(token.created_at).format("YYYY MMMM DD, h:mm a")}</p>
                        {token.expires_at ? (
                            <>
                                <strong>Expired</strong>
                                <p>{dayjsExtended(token.expires_at).format("YYYY MMMM DD, h:mm a")}</p>
                            </>
                        ) : null}
                    </Grid.Column>
                </Grid>
            </Segment>
            <EditTokenModal open={editModalOpen} setOpen={setEditModalOpen} token={token} />
            <ExpireTokenModal open={expireModalOpen} setOpen={setExpireModalOpen} token={token} />
            <DeleteTokenModal open={deleteModalOpen} setOpen={setDeleteModalOpen} token={token} />
        </>
    );
};

const APITokensWrapper = (props: { children: React.ReactNode; tokenCount?: number }) => {
    const { children, tokenCount } = props;

    return (
        <>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div style={{ display: "block" }}>
                    <Header as="h2" size="medium" style={{ marginBottom: 0, marginTop: `${16 / 14}rem` }}>
                        API Tokens {tokenCount ? `(${tokenCount})` : null}
                    </Header>
                    <span
                        style={{
                            color: SEMANTIC_GREY,
                            paddingBottom: `${10 / 14}rem`,
                            display: "block",
                            fontSize: "0.9em",
                        }}
                    >
                        Access keys are used to authenticate with Predibase APIs. They are used by the Predibase CLI and
                        SDKs. Limit 2 per user.
                    </span>
                </div>
                <CreateTokenModal disabled={Boolean(tokenCount && tokenCount >= 10)} />
            </div>
            <div style={{ marginBottom: `${24 / 14}rem` }}>{children}</div>
        </>
    );
};

const APITokens = () => {
    const { data: tokens, error: tokensError, isLoading: isTokensLoading } = useTokensQuery();

    const errorMessage = getErrorMessage(tokensError);

    if (tokensError) {
        return (
            <APITokensWrapper>
                <Message negative>
                    <Message.Header>Error</Message.Header>
                    <p>{errorMessage}</p>
                </Message>
            </APITokensWrapper>
        );
    }

    if (isTokensLoading) {
        return (
            <APITokensWrapper>
                {/* <Loader active inline="centered" /> */}
                <Segment placeholder loading />
            </APITokensWrapper>
        );
    }

    if (Array.isArray(tokens) && tokens.length === 0) {
        return (
            <APITokensWrapper tokenCount={tokens.length}>
                <Segment placeholder>
                    <Header icon>
                        <Icon name="key" />
                        You have not created any API tokens yet.
                    </Header>
                </Segment>
            </APITokensWrapper>
        );
    }

    return (
        <APITokensWrapper tokenCount={tokens?.length}>
            <div style={{ maxWidth: "85.714rem" }}>
                {tokens?.map((token: Token) => <APITokenCard key={token.token} token={token} />)}
            </div>
        </APITokensWrapper>
    );
};

export default APITokens;
