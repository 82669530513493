import React from "react";
import { Edge, Node } from "react-flow-renderer";
import { Icon, Message, Popup } from "semantic-ui-react";
import CountUpTimer from "../../../components/unsorted/history/CountUpTimer";
import { ModelStatus } from "../../../types/model/modelStatus";
import { ModelTypes } from "../../../types/model/modelTypes";
import { SEMANTIC_BLACK, SEMANTIC_BLUE, SEMANTIC_GREEN, SEMANTIC_RED } from "../../../utils/colors";
import dayjsExtended, { durationAsString } from "../../../utils/dayjs";
import { modelStatusValueMap } from "../../../utils/sort";
import { capitalize } from "../../../utils/strings";
import { isFailedOrCanceledStatus, isFineTunedModel, isTerminalStatus } from "../../util";

const position = { x: 0, y: 0 };

const getNode = (step: string, idx: number, currStatus: ModelStatus, progress?: ModelProgress): Node => {
    const stepName = capitalize(step);

    let label: React.ReactNode = (
        <span>
            <b>{stepName}</b>
        </span>
    );
    let style: React.CSSProperties = { opacity: 0.2 };

    if (progress) {
        const created = dayjsExtended(new Date(progress.created));
        // Ready is the last step, so we don't need to show the duration
        if (step === ModelStatus.READY) {
            if (isTerminalStatus(currStatus) && !isFailedOrCanceledStatus(currStatus)) {
                label = (
                    <>
                        <Icon name="check" style={{ color: "white" }} />
                        <span style={{ color: "white" }}>
                            <b>{stepName}</b>
                        </span>
                    </>
                );
                style = { backgroundColor: SEMANTIC_GREEN };
            } else if (currStatus === ModelStatus.FAILED) {
                label = (
                    <>
                        <Icon name="close" style={{ color: "white" }} />
                        <span style={{ color: "white" }}>
                            <b>{stepName}</b>
                        </span>
                    </>
                );
                style = { backgroundColor: SEMANTIC_RED };
            }
        } else if (progress.errorMessage) {
            const completed = dayjsExtended(new Date(progress.completed));
            const duration = dayjsExtended.duration(completed.diff(created));
            const durationString = durationAsString(duration);
            let error = progress.errorMessage;

            // Visualization error can be JSON
            if (progress.status === ModelStatus.VISUALIZING) {
                try {
                    const errorJson = JSON.parse(progress.errorMessage);
                    error = JSON.stringify(errorJson, null, 2);
                } catch (e) {}
            }
            label = (
                <Popup
                    hoverable
                    wide={"very"}
                    offset={[0, 10]}
                    className={"transition-scale"}
                    position={"top center"}
                    content={
                        <Message negative style={{ overflowY: "auto", maxHeight: "30vh" }}>
                            {error}
                        </Message>
                    }
                    trigger={
                        <div>
                            <Icon name={"close"} style={{ color: "white" }} />
                            <span style={{ color: "white" }}>
                                <b>{stepName}</b>
                            </span>
                            <div className={"model-progress-subtext"}>{durationString}</div>
                        </div>
                    }
                ></Popup>
            );
            style = { backgroundColor: SEMANTIC_RED };
        } else if (progress.completed) {
            const completed = dayjsExtended(new Date(progress.completed));
            const duration = dayjsExtended.duration(completed.diff(created));
            const durationString = durationAsString(duration);
            label = (
                <>
                    <Icon name={"check"} style={{ color: "white" }} />
                    <span style={{ color: "white" }}>
                        <b>{stepName}</b>
                    </span>
                    <div className={"model-progress-subtext"}>{durationString}</div>
                </>
            );
            style = { backgroundColor: SEMANTIC_GREEN };
        } else {
            if (step === currStatus) {
                label = (
                    <span style={{ color: SEMANTIC_BLUE }}>
                        <b>{stepName}</b>
                    </span>
                );
                style = { borderColor: SEMANTIC_BLUE };
            }
            label = (
                <>
                    {label}
                    <div className={"model-progress-subtext"}>
                        <CountUpTimer start={created} color={SEMANTIC_BLACK} posLoaderLeft={true} />
                    </div>
                </>
            );
        }
    }
    return {
        id: String(idx),
        data: { label: label },
        style: style,
        // type: nodeType,
        position,
    };
};

export const timelineGeneralFilter = (x: ModelStatus) => {
    switch (x) {
        case ModelStatus.DEPLOYED:
        case ModelStatus.DEPLOYING:
        case ModelStatus.UNDEPLOYING:
        case ModelStatus.FAILED:
        case ModelStatus.STOPPING:
        case ModelStatus.CANCELED:
            return false;
        default:
            return true;
    }
};

export const modelTypeFilter = (x: ModelStatus, config: CreateModelConfig): boolean => {
    switch (config.model_type) {
        case ModelTypes.LARGE_LANGUAGE_MODEL:
            if (isFineTunedModel(config)) {
                return x !== ModelStatus.EVALUATING && x !== ModelStatus.VISUALIZING && x !== ModelStatus.EXPLAINING;
            } else {
                return x !== ModelStatus.TRAINING && x !== ModelStatus.VISUALIZING && x !== ModelStatus.EXPLAINING;
            }
        default:
            return true;
    }
};

export const getModelProgressNodes = (model: Model, timeline?: ModelTimeline): Node[] => {
    return Object.values(ModelStatus)
        .filter(timelineGeneralFilter)
        .filter((x) => modelTypeFilter(x, model.config))
        .sort((a, b) => (modelStatusValueMap[a] || 0) - (modelStatusValueMap[b] || 0))
        .map((step, idx) => getNode(step, idx, model.status as ModelStatus, timeline?.[step]));
};

export const getModelProgressEdges = (model: Model): Edge[] => {
    return Object.values(ModelStatus)
        .filter(timelineGeneralFilter)
        .filter((x) => modelTypeFilter(x, model.config))
        .sort((a, b) => (modelStatusValueMap[a] || 0) - (modelStatusValueMap[b] || 0))
        .map((_, stepValue) => {
            const source = String(stepValue);
            const target = String(stepValue + 1);
            return {
                id: source + "-" + target,
                source: source,
                target: target,
                markerEnd: { type: "arrow" },
                style: { strokeWidth: "2px" },
                position,
            } as Edge;
        });
};
