import type { JSONSchema7 } from "json-schema";
import _ from "lodash";

/**
 * allOf schemas are arrays where each index describes a conditional pattern based on a type.
 * This function returns the correct sub-schema based on the given type.
 * @param searchSchemaProperty The allOf schemas to search through.
 * @param key The type to search for.
 * @returns JSONSchema7 | Object
 */
export const findAllOfSchema = (searchSchemaProperty?: JSONSchema7, key?: string) => {
    let result = searchSchemaProperty?.allOf?.filter(
        (allOfSchemaOption: any) => allOfSchemaOption?.if?.properties?.type?.const === key,
    );
    if (!result || result.length === 0) {
        // For now, we only need to handle simple items use cases.
        const allOfItems = searchSchemaProperty?.items;
        if (!allOfItems || typeof allOfItems === "boolean" || Array.isArray(allOfItems)) return {};

        result = allOfItems?.allOf?.filter(
            (allOfSchemaOption: any) => allOfSchemaOption?.if?.properties?.type?.const === key,
        );
    }

    if (!result || result.length === 0 || typeof result[0] === "boolean") return {};

    return result[0]?.then as JSONSchema7;
};

/**
 * allOf schemas are arrays where each index describes a conditional pattern based on a type.
 * This function returns the correct index of the sub-schema based on the given type.
 * @param searchSchemaProperty The allOf schemas to search through.
 * @param key The type to search for.
 * @returns number
 */
export const findAllOfSchemaIndex = (searchSchemaProperty?: JSONSchema7, key?: string) => {
    let index = searchSchemaProperty?.allOf?.findIndex(
        (allOfSchemaOption: any) => allOfSchemaOption?.if?.properties?.type?.const === key,
    );
    if (!index || index === -1) {
        // For now, we only need to handle simple items use cases.
        const allOfItems = searchSchemaProperty?.items;
        if (!allOfItems || typeof allOfItems === "boolean" || Array.isArray(allOfItems)) return {};

        index = allOfItems?.allOf?.findIndex(
            (allOfSchemaOption: any) => allOfSchemaOption?.if?.properties?.type?.const === key,
        );
    }

    return index;
};

const prependPropertiesToPath = (property: string) => `properties.${property}`;

/**
 * Given a path in the config object, this function navigates through
 * the JSON Schema object and returns the corresponding schema.
 * @param configPath The config object path to return the schema for.
 * @param config The config object. Used when navigating through allOfs.
 * @param schema The schema to navigate through.
 * @returns JSONSchema7 | Object
 */
export const findConfigPropertySchema = (configPath: string, config: CreateModelConfig, schema: JSONSchema7) => {
    if (configPath === "" || typeof schema === "undefined") {
        return {};
    }

    let configPropertyComponents = configPath.split(".");
    let traversedPropertyPath = "";
    let nodeSchema = schema;

    // Full dot-delimited paths to values inside of the config do not exactly correspond to their respective sub-schema paths because
    // they both contain and omit certain information. For example, a nested encoder value's path within the config will contain array
    // indices that do not correspond to anything in its respective schema but at the same time it will not contain precise context about
    // which parts of its relatively complex schema will be applied at validation time (e.g. `allOf`/conditional logic will be missing).
    // For example, a config value located at `input_features[0].encoder.size` might correspond to a schema path that roughly looks like
    // `input_features\items\encoder\allOf\0\size`.
    // Here we traverse through the nodes of the given config property's path and perform various checks at each step to trace it to the
    // correct sub-schema.
    while (configPropertyComponents.length) {
        let configPropertyComponent = configPropertyComponents.shift();
        if (typeof configPropertyComponent === "undefined") {
            return {};
        }
        // Clean up array items, such as input_features
        let sanitizedPropertyComponent = configPropertyComponent;
        if (sanitizedPropertyComponent.indexOf("[") > -1) {
            sanitizedPropertyComponent = sanitizedPropertyComponent.substring(
                0,
                sanitizedPropertyComponent.indexOf("["),
            );
        }

        // Handle arrays of items, such as input_features
        if (nodeSchema.type === "array") {
            // @ts-expect-error
            nodeSchema = nodeSchema.items;
        }

        if (_.has(nodeSchema, prependPropertiesToPath(sanitizedPropertyComponent))) {
            nodeSchema = _.get(nodeSchema, prependPropertiesToPath(sanitizedPropertyComponent)) as JSONSchema7;
        } else if (_.has(nodeSchema, "allOf")) {
            // Need to determine which allOf Schema to use:
            let siblingTypeProperty = _.get(config, `${traversedPropertyPath}.type`) as unknown as string;

            // Use the schema default if the type property has not been set in the config object:
            if (!siblingTypeProperty) {
                const typeSchemaDefaultPath = "properties.type.default";
                // Shouldn't happen, but bail out if the schema doesn't have a type property
                if (!_.has(nodeSchema, typeSchemaDefaultPath)) {
                    return {};
                }

                siblingTypeProperty = _.get(nodeSchema, typeSchemaDefaultPath) as string;
            }

            nodeSchema = findAllOfSchema(nodeSchema, siblingTypeProperty);
            if (!_.has(nodeSchema, prependPropertiesToPath(sanitizedPropertyComponent))) {
                return {};
            }
            nodeSchema = _.get(nodeSchema, prependPropertiesToPath(sanitizedPropertyComponent)) as JSONSchema7;
        } else {
            return {};
        }

        traversedPropertyPath = traversedPropertyPath.length
            ? `${traversedPropertyPath}.${configPropertyComponent}`
            : configPropertyComponent;
    }

    return nodeSchema;
};

/**
 * Ensure that when a property in a deeply nested config property is set, the entity type
 * (if applicable) is set as well. For example, if the learning rate is set on the Optimizer,
 * the Optimizer type is set as well.
 * @param configPath The config object path to return the schema for.
 * @param config The config object. Used when navigating through allOfs.
 * @param schema The schema to navigate through.
 * @returns CreateModelConfig
 */
export const setSiblingTypeProperty = (configPath: string, config: CreateModelConfig, schema: JSONSchema7) => {
    const updatedConfig = _.cloneDeep(config);

    // Check to see if there's a sibling property called type in the Schema
    const parentConfigProperty = configPath.substring(0, configPath.lastIndexOf("."));
    const parentSchema = findConfigPropertySchema(parentConfigProperty, config, schema);
    if (_.has(parentSchema, "properties.type")) {
        // Has the type been set in the config object yet?
        const siblingTypeConfigPath = `${parentConfigProperty}.type`;
        if (!_.has(config, `${siblingTypeConfigPath}`)) {
            // Set the type value to its default based on the schema
            const siblingTypeSchema = _.get(parentSchema, "properties.type");
            _.set(updatedConfig, siblingTypeConfigPath, _.get(siblingTypeSchema, "default"));
        }
    }

    return updatedConfig;
};
