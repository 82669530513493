import { RecoveryFlow } from "@ory/kratos-client";
import { UpdateRecoveryFlowBody } from "@ory/kratos-client/api";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Grid, Header, Loader, Message } from "semantic-ui-react";
import { kratosSdk, useSDKError } from "../../utils/kratos";
import KratosRecoveryForm from "./form/KratosRecoveryForm";

const KratosRecoveryView = () => {
    const [flow, setFlow] = useState<RecoveryFlow | null>(null);
    const { search } = useLocation();
    const params = useMemo(() => new URLSearchParams(search), [search]);

    const navigate = useNavigate();

    const getFlow = useCallback(
        (flowId: string) =>
            kratosSdk
                .getRecoveryFlow({ id: flowId })
                .then(({ data: flow }) => setFlow(flow))
                .catch(sdkErrorHandler),
        [],
    );

    // initialize the sdkError for generic handling of errors
    const sdkErrorHandler = useSDKError(getFlow, setFlow, "/recovery");

    // create a new recovery flow
    const createFlow = () => {
        kratosSdk
            .createBrowserRecoveryFlow()
            // flow contains the form fields, error messages and csrf token
            .then(({ data: flow }) => {
                // Update URI query params to include flow id
                params.set("flow", flow.id);
                navigate({ search: [params].toString() }, { replace: true });
                // Set the flow data
                setFlow(flow);
            })
            .catch(sdkErrorHandler);
    };

    const submitFlow = (body: UpdateRecoveryFlowBody) => {
        // something unexpected went wrong and the flow was not set
        if (!flow) return navigate("/auth/signin", { replace: true });

        return kratosSdk
            .updateRecoveryFlow({ flow: flow.id, updateRecoveryFlowBody: body })
            .then(({ data: flow }) => {
                // Form submission was successful, show the message to the user!
                setFlow(flow);
                navigate("/auth/signin", { replace: true });
            })
            .catch(sdkErrorHandler);
    };

    useEffect(() => {
        // we might redirect to this page after the flow is initialized, so we check for the flowId in the URL
        const flowId = params.get("flow");
        // the flow already exists
        if (flowId) {
            getFlow(flowId).catch(createFlow); // if for some reason the flow has expired, we need to get a new one
            return;
        }
        // we assume there was no flow, so we create a new one
        createFlow();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Grid textAlign="center" style={{ height: "100vh" }} verticalAlign="middle">
            <Grid.Column style={{ maxWidth: 450 }}>
                <Header as="h2" color="grey" textAlign="center">
                    Recover account
                </Header>
                {flow ? (
                    <KratosRecoveryForm
                        submitLabel="Reset"
                        action={flow.ui.action}
                        fields={flow.ui.nodes}
                        messages={flow.ui.messages}
                        onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                            event.preventDefault();
                            const data = new FormData(event.currentTarget);
                            const flowBody = {} as UpdateRecoveryFlowBody;

                            for (const pair of data.entries()) {
                                // @ts-expect-error
                                flowBody[pair[0]] = pair[1];
                            }
                            flowBody.method = "link";
                            return submitFlow(flowBody);
                        }}
                    />
                ) : (
                    <Loader active inline="centered" />
                )}
                <Message>
                    <Link to={"/auth/signin"}>Go Back</Link>
                </Message>
            </Grid.Column>
        </Grid>
    );
};

export default KratosRecoveryView;
