import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { capitalize } from "lodash";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import { Button, Form, Header, Icon, Message, Modal, Tab } from "semantic-ui-react";
import { TabProps } from "semantic-ui-react/dist/commonjs/modules/Tab/Tab";
import { track } from "../../metrics/june";
import metrics from "../../metrics/metrics";
import { USER_STATE } from "../../state/global";
import { Role } from "../../types/user";
import { getClientEndpoint } from "../../utils/api";
import { getErrorMessage } from "../../utils/errors";
import { useInviteLinkQuery } from "./query";

const formatRole = (role: Role) => ({ key: role.name, text: capitalize(role.name), value: role.name });

const InviteUserModal = (props: {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    allPossibleRoles: Role[];
    requestorRoles: Role[];
}) => {
    // Parent state:
    const { open, setOpen, allPossibleRoles, requestorRoles } = props;

    // Local state:
    const [tabIndex, setTabIndex] = useState(1);
    const [link, setLink] = useState("");
    const [copied, setCopied] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [role, setRole] = useState<Role | undefined>(allPossibleRoles.find((x) => x.name === "USER"));

    // User state:
    const [userContext] = useRecoilState(USER_STATE);

    // Derived state:
    const roleOptions = useMemo(() => {
        // If there are no requestorRoles (old accounts), fallback to USER
        if (requestorRoles.length === 0) {
            return [allPossibleRoles.find((x) => x.name === "USER") as Role].map(formatRole);
        }

        const filtRoles = requestorRoles.map((x) => x.name).includes("ADMIN")
            ? allPossibleRoles
            : allPossibleRoles.filter((x) => x.name !== "ADMIN");
        return filtRoles.map(formatRole);
    }, [allPossibleRoles, requestorRoles]);

    // Query state:
    const {
        data: inviteLink,
        isLoading: inviteLinkIsLoading,
        error: inviteLinkError,
    } = useInviteLinkQuery(role?.id || -1, {
        enabled: role !== undefined,
        // TODO: Added to imitate current behavior (invite links aren't cached), but is this necessary?
        gcTime: 0,
    });
    useEffect(() => {
        if (inviteLink) {
            setLink(getClientEndpoint() + "/auth/signup?token=" + inviteLink);
        }
    }, [inviteLink]);
    useEffect(() => {
        if (inviteLinkError) {
            setErrorMessage(getErrorMessage(inviteLinkError));
        }
    }, [inviteLinkError]);

    const panes = [
        {
            key: "inviteEmail",
            menuItem: "Invite by email",
            render: () => (
                <Tab.Pane className={"model-tab"}>
                    <div>Inviting by email is coming soon!</div>
                </Tab.Pane>
            ),
        },
        {
            key: "inviteLink",
            menuItem: "Invite with link",
            render: () => (
                <Tab.Pane className={"model-tab"} style={{ paddingBottom: 0 }}>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "space-between",
                            // display: "grid",
                            // gridTemplateColumns: "auto auto auto",
                            gap: "10px",
                        }}
                    >
                        <div style={{ width: "100%" }}>
                            <Form>
                                <Form.Group>
                                    <Form.Input
                                        width={11}
                                        readOnly={!link}
                                        fluid
                                        label="Invite Link"
                                        value={link}
                                        loading={inviteLinkIsLoading}
                                        placeholder="Generating link..."
                                    />
                                    <Form.Select
                                        fluid
                                        width={5}
                                        label="Role"
                                        options={roleOptions}
                                        value={role?.name}
                                        onChange={(e, { value }) => {
                                            setRole(allPossibleRoles.find((x) => x.name === value));
                                        }}
                                        placeholder="Role"
                                    />
                                </Form.Group>
                            </Form>
                        </div>
                        <div>
                            {copied ? (
                                <Button
                                    style={{ width: "112px", marginBottom: "1em" }}
                                    onClick={() => {
                                        navigator.clipboard.writeText(link);
                                        setCopied(true);
                                    }}
                                    color={"green"}
                                >
                                    Copied!
                                </Button>
                            ) : (
                                <Button
                                    style={{ width: "112px", marginBottom: "1em" }}
                                    onClick={() => {
                                        // Purposely updated to match PostHog's suggested naming convention
                                        metrics.capture("team_member_invited");
                                        userContext && track(userContext, "invite_team_members");
                                        navigator.clipboard.writeText(link);
                                        setCopied(true);
                                    }}
                                    primary
                                >
                                    Copy
                                </Button>
                            )}
                        </div>
                    </div>
                </Tab.Pane>
            ),
        },
    ];

    return (
        <Modal
            trigger={
                <Button primary size="small">
                    <Icon name="user plus" /> Invite
                </Button>
            }
            size={"small"}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => {
                setOpen(false);

                // Reset state:
                setErrorMessage("");
                setLink("");
                setCopied(false);
            }}
        >
            <Modal.Content style={{ padding: "30px" }}>
                <div style={{ marginBottom: "20px" }}>
                    <Header as={"h2"} size="medium" style={{ paddingTop: "10px", display: "inline" }}>
                        Invite Team Members
                    </Header>
                    <FontAwesomeIcon
                        style={{ float: "right", cursor: "pointer" }}
                        icon="x"
                        onClick={() => setOpen(false)}
                    />
                </div>
                {errorMessage !== null ? (
                    <Message negative>
                        <Message.Header>Error with Invite Flow</Message.Header>
                        <p>{errorMessage}</p>
                    </Message>
                ) : (
                    <div>
                        <div style={{ marginBottom: "20px" }}>
                            Invite others to join your team, {userContext?.tenant.name}. All <b>users</b> in your team
                            share models, datasets, and engines, but only <b>admins</b> can create new engines and
                            manage permissions.
                        </div>
                        <Tab
                            style={{ position: "relative" }}
                            menu={{ secondary: true, pointing: true }}
                            panes={panes}
                            activeIndex={tabIndex}
                            onTabChange={(e: React.MouseEvent<HTMLDivElement>, { activeIndex }: TabProps) => {
                                const idx = Number(activeIndex);
                                setTabIndex(idx);
                            }}
                        />
                    </div>
                )}
            </Modal.Content>
        </Modal>
    );
};

export default InviteUserModal;
