import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { useRecoilState } from "recoil";
import { integration, integrationToken } from "../api_generated";
import { useAuth0TokenOptions } from "../data";
import { USER_STATE } from "../state/global";
import { MetronomeDashboardType } from "../types/billing";
import { CurrentUser } from "../types/user";
import { useRoleAssignmentsQuery } from "./admin/query";
import { getIntegrationToken, getMetronomeDashboard } from "./data";

// Constants:
export const DASHBOARDS_CONSTANT = "dashboards";
export const INTEGRATIONS_CONSTANT = "integrations";

// Defaults:
export const commonGetMetronomeDashboardQuerySettings = <T>(): Partial<UseQueryOptions<T>> => ({
    // Per Convo with Metronome, dashboard link is valid for 1 hour
    // https://predibase.slack.com/archives/C062V7BKB44/p1702318732279829
    staleTime: 1000 * 60 * 55, // 55 minutes
    gcTime: 1000 * 60 * 55, // 55 minutes
    refetchOnWindowFocus: false,
    refetchOnMount: false,
});

// Queries:
export const GET_METRONOME_DASHBOARD_QUERY_KEY = (type: MetronomeDashboardType) => [DASHBOARDS_CONSTANT, { type }];
export const useMetronomeDashboardQuery = (
    type: MetronomeDashboardType,
    options?: Partial<UseQueryOptions<string>>,
) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<string>({
        queryKey: GET_METRONOME_DASHBOARD_QUERY_KEY(type),
        queryFn: () => getMetronomeDashboard(type, auth0TokenOptions),
        ...options,
    });
};

export const USE_INTEGRATION_TOKEN_QUERY_KEY = (integration: integration) => [
    INTEGRATIONS_CONSTANT,
    `${integration}/token`,
];
export const useIntegrationTokenQuery = (
    integration: integration,
    options?: Partial<UseQueryOptions<integrationToken>>,
) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<integrationToken>({
        queryKey: USE_INTEGRATION_TOKEN_QUERY_KEY(integration),
        queryFn: () => getIntegrationToken(integration, auth0TokenOptions),
        retry: false,
        ...options,
    });
};

// Custom hooks:
// Note: the Kratos situation is messed up, because a user can have multiple "roles", but the one that matters (at
// least for now) is the last assigned one, or the last one in the array. A lot of code simultaneously assumes that
// a user has a list of roles but also that certain ones (e.g. admin) trump other ones, so we still need to be able
// to fetch the full list of roles.
export const useAllRolesForUserUUIDTemporary = (userContextUUID?: string) => {
    const { data: roles } = useRoleAssignmentsQuery(userContextUUID ? [userContextUUID] : [], {
        enabled: !!userContextUUID,
    });
    return userContextUUID ? roles?.[userContextUUID] : undefined;
};

// Continuing from above, for the purposes of redacting information for readonly users, this hook determines what
// role a user has, JUST based on the last assigned role. I am putting it here because this check has to be
// performed in multiple places, and after the Auth0 migration is complete this will be the only function we need:
export const useUserRole = () => {
    const { auth0Enabled } = useAuth0TokenOptions();
    const [userContext] = useRecoilState(USER_STATE);
    const allRoles = useAllRolesForUserUUIDTemporary(userContext?.uuid);

    if (auth0Enabled) {
        return (userContext as CurrentUser | undefined)?.role?.toUpperCase(); // uppercase to match case of generated role types
    }
    return allRoles?.[allRoles.length - 1].name?.toUpperCase(); // uppercase to match case of generated role types
};
