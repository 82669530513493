import { Connector } from "../util";
import DBConnector from "./DBConnector";

function MySQLConnector(props: JSX.IntrinsicAttributes & ConnectorProps) {
    return (
        <DBConnector
            modalHeader="Connect to MySQL"
            header="MySQL"
            editModalHeader="Edit MySQL Connection"
            submitLabel="Connect"
            name={Connector.MYSQL}
            errorHeader="Error in MySQL connection"
            {...props}
        />
    );
}

export default MySQLConnector;
