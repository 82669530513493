import ecdFeatureTypes from "../../assets/ecd-feature-types.json";
import gbmFeatureTypes from "../../assets/gbm-feature-types.json";
import llmFeatureTypes from "../../assets/llm-feature-types.json";

export const ECDInputFeatures = ecdFeatureTypes.inputFeatureTypes;
export const ECDOutputFeatures = ecdFeatureTypes.outputFeatureTypes;
export const GBMInputFeatures = gbmFeatureTypes.inputFeatureTypes;
export const GBMOutputFeatures = gbmFeatureTypes.outputFeatureTypes;
export const LLMInputFeatures = llmFeatureTypes.inputFeatureTypes;
export const LLMOutputFeatures = llmFeatureTypes.outputFeatureTypes;
export const AllFeatureTypes = [
    ...new Set([
        ...ECDInputFeatures,
        ...ECDOutputFeatures,
        ...GBMInputFeatures,
        ...GBMOutputFeatures,
        ...LLMInputFeatures,
        ...LLMOutputFeatures,
    ]),
].sort();

export enum FreeTrialBlockedFeatureTypes {
    AUDIO = "audio",
    TIMESERIES = "timeseries",
}
