import { Label } from "semantic-ui-react";
import { adapterVersionListItem, repo } from "../../api_generated";
import { SEMANTIC_GREY } from "../../utils/colors";
import { deletedUser } from "../../utils/constants";

const RepoContributors = (props: { repo: repo }) => {
    // Parent state:
    const { repo } = props;

    // TODO: Remove "null" union type when we fix the backend...
    const repoAdapters = repo.versions as null | adapterVersionListItem[];
    if (repoAdapters === null) {
        return null;
    }

    const contributorCount: Record<string, number> = {};
    for (const adapter of repoAdapters) {
        const username = adapter.createdBy || deletedUser;
        if (contributorCount[username] === undefined) {
            contributorCount[username] = 0;
        }
        contributorCount[username] += 1;
    }
    return (
        <Label.Group style={{ gap: "0.5em" }}>
            {Object.entries(contributorCount)
                .sort((a, b) => b[1] - a[1])
                .map((contributor) => {
                    const [username, count] = contributor;
                    return (
                        <Label className={"centered-label"} basic key={username} style={{ whiteSpace: "nowrap" }}>
                            {username}
                            <Label.Detail
                                style={{
                                    color: SEMANTIC_GREY,
                                    marginLeft: "0.5em",
                                    fontWeight: "normal",
                                }}
                            >
                                {count}
                            </Label.Detail>
                        </Label>
                    );
                })}
        </Label.Group>
    );
};

export default RepoContributors;
