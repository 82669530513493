import { useQueryClient } from "@tanstack/react-query";
import { MouseEvent, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { Button, Divider, Icon, Loader, Message, Pagination } from "semantic-ui-react";
import { PaginationProps } from "semantic-ui-react/dist/commonjs/addons/Pagination/Pagination";
import { role } from "../../api_generated";
import { useAuth0TokenOptions } from "../../data";
import { USERS_OFFSET_STATE, USERS_TOTAL_STATE } from "../../state/settings";
import { KratosUser, Role } from "../../types/user";
import { getErrorMessage } from "../../utils/errors";
import { getActivePage, getTotalPages } from "../../utils/pagination";
import { useUserRole } from "../query";
import InviteUserModal from "./InviteUserModal";
import InviteUserModalV2 from "./InviteUserModalV2";
import MembersTable from "./MembersTable";
import MembersTableV2 from "./MembersTableV2";
import {
    ASSIGNMENTS_CONSTANT,
    GET_USERS_QUERY_KEY,
    useRoleAssignmentsQuery,
    useRolesQuery,
    useUsersQuery,
    useUsersQueryV2,
} from "./query";

const USERS_PER_PAGE = 10;

const MembersView = (props: { requestorRoles?: Role[] }) => {
    const { auth0Enabled } = useAuth0TokenOptions();
    const { requestorRoles } = props;

    return auth0Enabled ? <MembersViewAuth0 /> : <MembersViewKratos requestorRoles={requestorRoles} />;
};

const MembersViewAuth0 = () => {
    // Local state:
    const [inviteModalOpen, setInviteModalOpen] = useState(false);
    const [offset, setOffset] = useState(0);
    const [total, setTotal] = useState(0);

    // Users Query and State
    const queryParams = {
        offset,
        limit: USERS_PER_PAGE,
    };
    const { data: userList, isLoading: userListLoading, error: userListError } = useUsersQueryV2(queryParams);
    const userRole = useUserRole();

    useEffect(() => {
        if (userList) {
            setOffset(userList.offset);
            setTotal(userList.total);
        }
    }, [userList, setOffset, setTotal]);

    const errorMessage = getErrorMessage(userListError);

    if (userListLoading) {
        return <Loader inline />;
    }

    return (
        <>
            {errorMessage && (
                <Message negative>
                    <Message.Header>Error</Message.Header>
                    <p>{errorMessage}</p>
                </Message>
            )}
            {userRole !== role.READONLY && (
                <Button primary size="small" onClick={() => setInviteModalOpen(true)}>
                    <Icon name="user plus" /> Invite
                </Button>
            )}
            <MembersTableV2 users={userList?.data} />
            <div style={{ justifyContent: "center", display: "flex" }}>
                <Pagination
                    activePage={getActivePage(offset, USERS_PER_PAGE)}
                    totalPages={getTotalPages(total, USERS_PER_PAGE)}
                    onPageChange={(e: MouseEvent<HTMLAnchorElement>, { activePage }: PaginationProps) => {
                        const offset = ((activePage as number) - 1) * USERS_PER_PAGE;
                        setOffset(offset);
                    }}
                    firstItem={{ content: <Icon name="angle double left" />, icon: true }}
                    lastItem={{ content: <Icon name="angle double right" />, icon: true }}
                    prevItem={{ content: <Icon name="angle left" />, icon: true }}
                    nextItem={{ content: <Icon name="angle right" />, icon: true }}
                />
            </div>
            <InviteUserModalV2 open={inviteModalOpen} setOpen={setInviteModalOpen} />
        </>
    );
};

const MembersViewKratos = (props: { requestorRoles?: Role[] }) => {
    // Parent state:
    const { requestorRoles } = props;

    // Recoil state:
    // TODO: These don't need to be atoms:
    const [usersOffset, setUsersOffset] = useRecoilState(USERS_OFFSET_STATE);
    const [usersTotal, setUsersTotal] = useRecoilState(USERS_TOTAL_STATE);

    // Local state:
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    // TODO: Types needs to be updated:
    const [users, setUsers] = useState<KratosUser[]>();
    const [allPossibleRoles, setAllPossibleRoles] = useState<Role[]>();
    const [roleAssignments, setRoleAssignments] = useState<Record<string, Role[]>>();
    const [inviteUserModalOpen, setInviteUserModalOpen] = useState(false);

    // Query state:
    const queryClient = useQueryClient();

    const userRole = useUserRole();

    const { data: getUsersResponse, error: getUsersError } = useUsersQuery({
        offset: usersOffset,
        limit: USERS_PER_PAGE,
    });
    useEffect(() => {
        if (getUsersResponse) {
            setUsers(getUsersResponse.identities);
            setUsersOffset(getUsersResponse.offset);
            setUsersTotal(getUsersResponse.total);
        }
    }, [getUsersResponse, setUsersOffset, setUsersTotal]);

    const { data: rolesResponse, error: rolesResponseError } = useRolesQuery();
    useEffect(() => {
        if (rolesResponse) {
            setAllPossibleRoles(rolesResponse);
        }
    }, [rolesResponse]);

    // Get roles for the paginated set of paginated users (to appear in the table):
    const { data: roleAssignmentsResponse, error: roleAssignmentsError } = useRoleAssignmentsQuery(
        users ? users.map((user) => user.id) : [],
        {
            enabled: !!users,
        },
    );
    useEffect(() => {
        if (roleAssignmentsResponse) {
            setRoleAssignments(roleAssignmentsResponse);
        }
    }, [roleAssignmentsResponse]);

    // Set error header if there is one:
    useEffect(() => {
        const error = getUsersError || rolesResponseError || roleAssignmentsError;
        if (error) {
            setErrorMessage(error.message);
        }
    }, [getUsersError, rolesResponseError, roleAssignmentsError]);

    return (
        <>
            <div className="row">
                {errorMessage && (
                    <Message negative>
                        <Message.Header>Error</Message.Header>
                        <p>{errorMessage}</p>
                    </Message>
                )}
            </div>
            <div>
                {allPossibleRoles && requestorRoles && userRole !== role.READONLY && (
                    <InviteUserModal
                        open={inviteUserModalOpen}
                        setOpen={setInviteUserModalOpen}
                        allPossibleRoles={allPossibleRoles}
                        requestorRoles={requestorRoles}
                    />
                )}
                {allPossibleRoles && roleAssignments && (
                    <MembersTable
                        users={users}
                        allPossibleRoles={allPossibleRoles}
                        roleAssignments={roleAssignments}
                        onUserSubmit={() => {
                            queryClient.invalidateQueries({
                                queryKey: GET_USERS_QUERY_KEY({ offset: usersOffset, limit: USERS_PER_PAGE }),
                            });
                        }}
                        refreshFunc={() => {
                            queryClient.invalidateQueries({ queryKey: [ASSIGNMENTS_CONSTANT] });
                        }}
                        setErrorMessage={setErrorMessage}
                    />
                )}
                <div style={{ justifyContent: "center", display: "flex" }}>
                    <Pagination
                        activePage={getActivePage(usersOffset, USERS_PER_PAGE)}
                        totalPages={getTotalPages(usersTotal, USERS_PER_PAGE)}
                        onPageChange={(e: MouseEvent<HTMLAnchorElement>, { activePage }: PaginationProps) => {
                            const offset = ((activePage as number) - 1) * USERS_PER_PAGE;
                            setUsersOffset(offset);
                        }}
                        firstItem={{ content: <Icon name="angle double left" />, icon: true }}
                        lastItem={{ content: <Icon name="angle double right" />, icon: true }}
                        prevItem={{ content: <Icon name="angle left" />, icon: true }}
                        nextItem={{ content: <Icon name="angle right" />, icon: true }}
                    />
                </div>
            </div>
            <Divider hidden />
        </>
    );
};

export default MembersView;
