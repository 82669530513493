import React, { useState } from "react";
import { Label, Menu, Popup, TabProps } from "semantic-ui-react";
import metrics from "../../metrics/metrics";
import { getModelType } from "../util";
import { useConfigState, useDispatch, useIsHyperoptEnabled } from "./store";

import DatasetPreviewer from "../../data/datasets/DatasetPreviewer";
import { ModelTypes } from "../../types/model/modelTypes";
import ModelGraph from "../viz/ModelGraph";
import AdvancedConfigManager from "./AdvancedConfigManager";
import ComputeConfigManager from "./ComputeConfigManager";
import ModelConfigHyperoptView from "./ModelConfigHyperoptView";
import ParameterGuidance from "./parameters/ParameterGuidance";

const hyperoptButtonStyle = {
    marginBottom: `-${3 / 14}rem`,
    marginTop: `-${3 / 14}rem`,
    paddingLeft: `${4 / 14}rem`,
    paddingRight: `${4 / 14}rem`,
    borderRadius: `-${8 / 14}rem`,
};

const MenuItem = (props: {
    name: string;
    content: string;
    activeItem: string;
    handleMenuItemClick: (event: React.SyntheticEvent<HTMLElement, Event>, data: TabProps) => void;
}) => {
    const isHyperoptEnabled = useIsHyperoptEnabled();
    return (
        <Menu.Item
            style={props.name === "hyperopt" ? { height: `${40 / 14}rem` } : undefined}
            key={props.name}
            content={props.content}
            name={props.name}
            active={props.activeItem === props.name}
            onClick={props.handleMenuItemClick}
        >
            {props.name === "hyperopt" ? (
                <span>
                    Hyperopt (Beta)
                    {isHyperoptEnabled ? (
                        <>
                            &ensp;
                            <Label color="blue" style={hyperoptButtonStyle}>
                                ON
                            </Label>
                        </>
                    ) : null}
                </span>
            ) : null}
        </Menu.Item>
    );
};

const ModelTabPanel = (props: { dataset?: Dataset; engine?: Engine; setEngine: (engine: Engine) => void }) => {
    const { config, validator, schema } = useConfigState();
    const modelType = getModelType(config);
    const dispatch = useDispatch();
    const [activeTabPanel, setActiveTabPanel] = useState("overview");
    const handleMenuItemClick = (event: React.SyntheticEvent<HTMLElement, Event>, data: TabProps) => {
        setActiveTabPanel(data.name);
    };

    // Default to filtering HIGH impact parameters
    const [expectedImpactFilter, setExpectedImpactFilter] = useState(2);
    return (
        <>
            <Menu className={metrics.BLOCK_AUTO_CAPTURE} pointing secondary>
                <MenuItem
                    content="Model Graph"
                    name="overview"
                    activeItem={activeTabPanel}
                    handleMenuItemClick={handleMenuItemClick}
                />
                <MenuItem
                    content="Parameters"
                    name="parameters"
                    activeItem={activeTabPanel}
                    handleMenuItemClick={handleMenuItemClick}
                />
                {modelType === ModelTypes.NEURAL_NETWORK ? (
                    <MenuItem
                        content="Hyperopt"
                        name="hyperopt"
                        activeItem={activeTabPanel}
                        handleMenuItemClick={handleMenuItemClick}
                    />
                ) : getModelType(config) === ModelTypes.DECISION_TREE ? (
                    <Menu.Item content="Hyperopt" name="hyperopt" disabled style={{ height: `${40 / 14}rem` }}>
                        <Popup
                            className={"transition-scale"}
                            position={"top center"}
                            content={"Hyperopt is not available for gradient boosted trees."}
                            trigger={<span>{"Hyperopt (Beta)"}</span>}
                        />
                    </Menu.Item>
                ) : null}
                <MenuItem
                    content="Config"
                    name="advanced"
                    activeItem={activeTabPanel}
                    handleMenuItemClick={handleMenuItemClick}
                />
                <MenuItem
                    content="Dataset Preview"
                    name="preview"
                    activeItem={activeTabPanel}
                    handleMenuItemClick={handleMenuItemClick}
                />
                <MenuItem
                    content="Compute"
                    name="compute"
                    activeItem={activeTabPanel}
                    handleMenuItemClick={handleMenuItemClick}
                />
            </Menu>
            {activeTabPanel === "overview" && (
                <ModelGraph
                    applyConfig={(config?: CreateModelConfig) => dispatch({ type: "UPDATE_CONFIG", config })}
                    config={config}
                    modelType={getModelType(config)}
                    readOnly={false}
                    schema={schema}
                    validator={validator}
                    expectedImpactFilter={expectedImpactFilter}
                    setExpectedImpactFilter={setExpectedImpactFilter}
                />
            )}
            {activeTabPanel === "parameters" && (
                <ParameterGuidance
                    expectedImpactFilter={expectedImpactFilter}
                    setExpectedImpactFilter={setExpectedImpactFilter}
                />
            )}
            {activeTabPanel === "hyperopt" && <ModelConfigHyperoptView />}
            {activeTabPanel === "advanced" && <AdvancedConfigManager />}
            {activeTabPanel === "preview" && <DatasetPreviewer dataset={props.dataset} />}
            {activeTabPanel === "compute" && (
                <ComputeConfigManager dataset={props.dataset} engine={props.engine} setEngine={props.setEngine} />
            )}
        </>
    );
};

export default ModelTabPanel;
