import dayjs from "dayjs";
import type { Duration } from "dayjs/plugin/duration";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { timestamp } from "../api_generated";

// Import plugins:
const dayjsExtended = dayjs;
dayjs.extend(duration);
dayjs.extend(relativeTime);

const stringFormatForDuration = (duration: Duration) => {
    const seconds = duration.asSeconds();
    if (seconds > 60 * 60 * 24) {
        return "D[d] H[h] m[m] s[s]";
    }
    if (seconds > 60 * 60) {
        return "H[h] m[m] s[s]";
    }
    if (seconds > 60) {
        return "m[m] s[s]";
    }
    return "s[s]";
};

export const durationAsString = (duration: Duration | null | undefined) => {
    return duration?.format(stringFormatForDuration(duration));
};
export const durationCalculation = (createdAt: timestamp, endedAt?: timestamp) => {
    const created = dayjsExtended(new Date(createdAt));
    const ended = endedAt ? dayjsExtended(new Date(endedAt)) : null;

    return ended ? dayjsExtended.duration(ended.diff(created)) : null;
};

export default dayjsExtended;
