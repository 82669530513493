import { Message } from "semantic-ui-react";

function FeaturesDocsMessage() {
    return (
        <Message className="docsMessage" floating info>
            <p className="docsMessage">
                <strong>Turn on/off the features you'd like to use for model training.</strong>
                &nbsp; Predibase automatically infers data types for your features, and you can make adjustments as
                needed.
            </p>
        </Message>
    );
}

export { FeaturesDocsMessage };
