import { getTraceId } from "../api/trace";
import { completeSignupRequest, validateUserRequest, validateUserResponse } from "../api_generated";
import { Auth0TokenOptions } from "../data";
import metrics from "../metrics/metrics";
import { createV1APIServer, createV2APIServer, redirectIfSessionInvalid } from "../utils/api";
import { getErrorMessage } from "../utils/errors";

// TODO: Should be autogenerated:
export interface AzureCredentials {
    appID: string;
    password: string;
    tenantID: string;
    subscriptionID: string;
    resourceGroup: string;
}
export const submitAzureCredentials = async (
    shortCode: string,
    azureCredentials: AzureCredentials,
    auth0TokenOptions?: Auth0TokenOptions,
) => {
    const endpoint = `environments/${shortCode}`;
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .patch(endpoint, {
            cloud: "azure",
            cloudCredentialsAzure: azureCredentials,
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "PATCH",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const submitAWSCredentials = async (
    shortCode: string,
    roleArn: string,
    auth0TokenOptions?: Auth0TokenOptions,
) => {
    const endpoint = `environments/${shortCode}`;
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .patch(endpoint, {
            cloud: "aws",
            cloudCredentialsAWS: { roleArn },
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "PATCH",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const submitCloudAndRegion = async (
    shortCode: string,
    cloud: string,
    region: string,
    auth0TokenOptions?: Auth0TokenOptions,
) => {
    const endpoint = `environments/${shortCode}`;
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .patch(endpoint, {
            cloud,
            region,
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "PATCH",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const provisionEnvironment = async (shortCode: string, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `environments/${shortCode}/provision`;
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer.post(endpoint).catch((error) => {
        const errorMsg = getErrorMessage(error) ?? "";
        metrics.captureError("api_error", errorMsg, {
            method: "POST",
            endpoint,
            trace_id: getTraceId(error),
        });
        redirectIfSessionInvalid(errorMsg);
        throw errorMsg;
    });
};

export const completeSignup = async (signupRequest: completeSignupRequest, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = "auth/complete-signup";
    // TODO: This actually won't work without auth0 lol
    const apiServer = await createV2APIServer(auth0TokenOptions);

    return apiServer.post(endpoint, signupRequest).catch((error) => {
        const errorMsg = getErrorMessage(error) ?? "";
        metrics.captureError("api_error", errorMsg, {
            method: "POST",
            endpoint,
            trace_id: getTraceId(error),
        });
        redirectIfSessionInvalid(errorMsg);
        throw errorMsg;
    });
};

export const validateUser = async (inviteToken?: string, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = "/auth/validate-user";
    const apiServer = await createV2APIServer(auth0TokenOptions);

    const body: validateUserRequest = { inviteToken };
    return apiServer
        .post<validateUserResponse>(endpoint, body)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "POST",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};
