import { FetchQueryOptions, QueryClient, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { Auth0TokenOptions, useAuth0TokenOptions } from "../data";
import {
    DatasetModelsResponse,
    DatasetPreviewParams,
    getConnection,
    getConnections,
    getConnectionSchemaConfig,
    getDatasetModels,
    getDatasetPreview,
    getDatasets,
    getDatasetsForConnection,
    getFileDatasetNameValid,
} from "./data";

// Constants:
export const DATASETS_CONSTANT = "datasets";
export const CONNECTIONS_CONSTANT = "connections";

// Defaults:
export const commonGetDatasetsOptions = <T>(): Partial<UseQueryOptions<T>> => ({
    staleTime: 1000 * 20, // 20 seconds
});

export const commonGetDatasetPreviewQueryOptions = <T>(dataset?: Dataset): Partial<UseQueryOptions<T>> => ({
    staleTime: 1000 * 60 * 20, // 20 minutes
    enabled: dataset?.status === "connected",
    retry: false, // TODO Kabir remove this once the API fails until dataset is connected
});

// Custom functions:
export const prefetchDatasetPreviewQuery = (
    queryClient: QueryClient,
    datasetID: number,
    params?: DatasetPreviewParams,
    options?: Partial<FetchQueryOptions<any>>,
    auth0TokenOptions?: Auth0TokenOptions,
) => {
    queryClient.prefetchQuery({
        queryKey: GET_DATASET_PREVIEW_QUERY_KEY(datasetID),
        queryFn: () => getDatasetPreview(datasetID, params, auth0TokenOptions),
        ...options,
    });
};

// Queries:
export const GET_DATASETS_QUERY_KEY = (params?: PaginationParams) =>
    params ? [DATASETS_CONSTANT, params] : [DATASETS_CONSTANT];
export const useDatasetsQuery = (
    params?: PaginationParams,
    options?: Partial<UseQueryOptions<FetchDatasetsResponse>>,
) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<FetchDatasetsResponse>({
        queryKey: GET_DATASETS_QUERY_KEY(params),
        queryFn: () => getDatasets(params, auth0TokenOptions),
        ...options,
    });
};

export const GET_DATASETS_FOR_CONNECTION_QUERY_KEY = (connectionName: string) => [
    CONNECTIONS_CONSTANT,
    connectionName,
    DATASETS_CONSTANT,
];
export const useDatasetsForConnectionQuery = (
    connection?: Connection,
    options?: Partial<UseQueryOptions<Dataset[] | undefined>>,
) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<Dataset[] | undefined>({
        queryKey: GET_DATASETS_FOR_CONNECTION_QUERY_KEY(connection?.name ?? ""),
        queryFn: () => getDatasetsForConnection(connection!, auth0TokenOptions),
        ...options,
    });
};

export const GET_CONNECTIONS_QUERY_KEY = (params?: PaginationParams) => [CONNECTIONS_CONSTANT, params];
export const useConnectionsQuery = (
    params?: PaginationParams,
    options?: Partial<UseQueryOptions<FetchConnectionsResponse>>,
) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<FetchConnectionsResponse>({
        queryKey: GET_CONNECTIONS_QUERY_KEY(params),
        queryFn: () => getConnections(params, auth0TokenOptions),
        ...options,
    });
};

export const GET_DATASET_PREVIEW_QUERY_KEY = (datasetID: number) => ["dataset-preview", datasetID];
// TODO: update types when we can (see data.ts)
export const useDatasetPreviewQuery = (
    datasetID: number,
    params?: DatasetPreviewParams,
    options?: Partial<UseQueryOptions<any>>,
) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<any>({
        queryKey: GET_DATASET_PREVIEW_QUERY_KEY(datasetID),
        queryFn: () => getDatasetPreview(datasetID, params, auth0TokenOptions),
        ...options,
    });
};

export const GET_CONNECTION_SCHEMA_CONFIG = (connectionID: number) => [
    CONNECTIONS_CONSTANT,
    connectionID,
    "schema",
    "config",
];
export const useConnectionSchemaConfigQuery = (
    connectionID: number,
    options?: Partial<UseQueryOptions<ConnectionSchemaConfig>>,
) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<ConnectionSchemaConfig>({
        queryKey: GET_CONNECTION_SCHEMA_CONFIG(connectionID),
        queryFn: () => getConnectionSchemaConfig(connectionID, auth0TokenOptions),
        ...options,
    });
};

export const GET_FILE_DATASET_NAME_VALID_QUERY_KEY = (name: string) => ["file-dataset-name-valid", name];
export const useFileDatasetNameValidQuery = (name: string, options?: Partial<UseQueryOptions<any>>) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<boolean>({
        // TODO: Weird caching case here (since the query is only triggered manually):
        queryKey: GET_FILE_DATASET_NAME_VALID_QUERY_KEY(name),
        queryFn: () => getFileDatasetNameValid(name, auth0TokenOptions),
        ...options,
    });
};

export const GET_CONNECTION_QUERY_KEY = (connectionID: number) => [CONNECTIONS_CONSTANT, connectionID];
export const useConnectionQuery = (connectionID: number, options?: Partial<UseQueryOptions<Connection>>) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<Connection>({
        queryKey: GET_CONNECTION_QUERY_KEY(connectionID),
        queryFn: () => getConnection(connectionID, auth0TokenOptions),
        ...options,
    });
};

export const GET_DATASET_MODELS_QUERY_KEY = (datasetID: number) => ["dataset-models", datasetID];
export const useDatasetModelsQuery = (datasetID: number, options?: Partial<UseQueryOptions<DatasetModelsResponse>>) => {
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<DatasetModelsResponse>({
        queryKey: GET_DATASET_MODELS_QUERY_KEY(datasetID),
        queryFn: () => getDatasetModels(datasetID, auth0TokenOptions),
        ...options,
    });
};
