import { JSONSchema7, JSONSchema7Definition, JSONSchema7Type } from "json-schema";
import _ from "lodash";
import React from "react";
import { Form } from "semantic-ui-react";
import { getFieldState, handleLocalState } from "../../../../models/create/forms/utils";
import { CurrentUser, UserContext } from "../../../../types/user";
import { snakeToTitle } from "../../../../utils/config";
import Checkbox from "../../../Checkbox";
import Input from "../../../Input";
import Markdown from "../../../Markdown";
import { RadioOf } from "../custom-components";
import { Field, FieldAnnotations, FieldTitle } from "../fields";
import { titleStyling } from "../styling";
import { genericOfSchemaPath, getAlternativeTitle, getLudwigPath, hiddenNames } from "../utils";
import { GenericOf } from "./GenericOf";
import { Properties } from "./Properties";

// TODO: Generalize and simplify this component more:
export const Of = (props: {
    selector: string;
    description: string | undefined;
    defaultValue?: JSONSchema7Type;
    schema: JSONSchema7;
    config: CreateModelConfig;
    setConfig: (path: string, typedValue: any) => void;
    localState: any;
    setLocalState: (localState: any, path: string) => void;
    invalidFields: any;
    featureIndex?: number;
    level: number;
    path: string;
    schemaPath: string;
    readOnly?: boolean;
    userContext?: UserContext | CurrentUser;
}) => {
    const {
        selector,
        invalidFields,
        defaultValue,
        config,
        setConfig,
        localState,
        setLocalState,
        featureIndex,
        schema,
        level,
        schemaPath,
    } = props;

    if (!schema || !_.has(schema, selector)) {
        return null;
    }

    // Don't render fields that the UI does not support:
    if (schema.title && hiddenNames.includes(schema?.title)) {
        return null;
    }

    // @ts-expect-error(2339) yes, parameter_metadata does exist on the schema
    if (schema?.parameter_metadata?.ui_component_type === "radio_string_combined") {
        // @ts-expect-error(2339) yes, parameter_metadata does exist on the schema
        const path = getLudwigPath(schema?.parameter_metadata?.ludwig_path, featureIndex);
        let value = getFieldState(path, localState, config);
        // @ts-expect-error(2339) yes, parameter_metadata does exist on the schema
        let title = schema?.parameter_metadata?.ui_display_name
            ? // @ts-expect-error(2339) yes, parameter_metadata does exist on the schema
              schema?.parameter_metadata?.ui_display_name
            : snakeToTitle(schema?.title ?? "");
        // @ts-expect-error(2339) yes, parameter_metadata does exist on the schema
        const description = schema?.parameter_metadata?.short_description
            ? // @ts-expect-error(2339) yes, parameter_metadata does exist on the schema
              schema?.parameter_metadata?.short_description
            : schema.description;

        // Various tweaks

        // When the radio component loads it will try to match the value given to it to one of the option schemas
        // and then mark that radio button accordingly. In certain cases, an option schema may successfully
        // validate an empty string (e.g. `base_model`) - and by the semantic conventions of the model builder
        // an empty string also represents empty or unset fields. So to prevent the aforementioned option schema
        // from being selected by default when this field hasn't actually been set yet, we set the value to
        // undefined (forcing all radio buttons to remain clear).
        value = value === "" ? undefined : value;

        // There are exceptions (e.g. the `adapter`) where instead of rendering a normal (small) label we want
        // to force-insert a larger section header:
        let sectionTitle = null;
        if (schema.title === "adapter_options" || schema.title === "quantization") {
            sectionTitle = snakeToTitle(schema.title);
            title = undefined;
        }

        return (
            <Form.Field
                style={{ marginBottom: `${24 / 14}rem` }}
                key={`radio_field_${path}_${schema?.title}_${selector}`}
            >
                <legend
                    style={{
                        ...titleStyling,
                        fontSize: `${18 / 14}rem`,
                        marginBottom: `${24 / 14}rem`,
                    }}
                >
                    {sectionTitle}
                </legend>
                <RadioOf
                    selector={selector}
                    path={path}
                    ofSchemaOptions={_.get(schema, selector) as JSONSchema7[]}
                    level={level}
                    title={title}
                    error={_.has(invalidFields, path)}
                    value={value} // NOTE: custom tweak so that custom option not chosen by default
                    defaultValue={schema?.default}
                    description={description}
                    config={config}
                    setConfig={setConfig}
                    localState={localState}
                    setLocalState={setLocalState}
                    invalidFields={invalidFields}
                    featureIndex={featureIndex}
                    schemaPath={schemaPath}
                    userContext={props.userContext}
                />
            </Form.Field>
        );
    }

    // Filter out `null`s since we allow the user to empty the field
    // and sort for easier logic later on:
    const isValidType = (ofSchema: JSONSchema7Definition): ofSchema is JSONSchema7 =>
        typeof ofSchema === "object" && ofSchema?.type !== "null";
    const leftoverOfs = (_.get(schema, selector) as JSONSchema7Definition[])?.filter(isValidType) as JSONSchema7[];

    // If only one option left, render it
    if (leftoverOfs.length === 1) {
        const ofSchema = leftoverOfs[0];
        const ofSchemaIndex = (_.get(schema, selector) as JSONSchema7Definition[]).findIndex(
            (schemaOption: JSONSchema7Definition) =>
                typeof schemaOption !== "boolean" && schemaOption.type === ofSchema.type,
        );
        if (typeof ofSchema === "boolean") {
            return null;
        }

        // @ts-expect-error(2339) yes, parameter_metadata does exist on the schema
        const path = getLudwigPath(schema?.parameter_metadata?.ludwig_path, featureIndex);

        // Example: trainer.gradient_clipping
        if (ofSchema.type === "object") {
            return (
                <React.Fragment key={`of_fragment_${schema?.title}_${selector}`}>
                    {Properties(
                        ofSchema,
                        config,
                        setConfig,
                        localState,
                        setLocalState,
                        invalidFields,
                        level + 1,
                        path,
                        genericOfSchemaPath(selector, schemaPath, ofSchemaIndex),
                        featureIndex,
                        props.readOnly,
                        props.userContext,
                    )}
                </React.Fragment>
            );
        }

        // Example: bias_initializer
        return (
            <Field
                fieldType={String(ofSchema.type)}
                schema={ofSchema}
                featureIndex={featureIndex}
                config={config}
                setConfig={setConfig}
                localState={localState}
                setLocalState={setLocalState}
                invalidFields={invalidFields}
                path={path}
                schemaPath={genericOfSchemaPath(selector, schemaPath, ofSchemaIndex)}
                readOnly={props.readOnly}
                userContext={props.userContext}
            />
        );
    }

    // If two options left:
    if (leftoverOfs.length === 2) {
        // Object and not object; then use not object
        if (leftoverOfs[0].type === "object" || leftoverOfs[1].type === "object") {
            const fieldSchema = leftoverOfs[0].type !== "object" ? leftoverOfs[0] : leftoverOfs[1];
            const fieldSchemaIndex = leftoverOfs[0].type !== "object" ? 0 : 1;
            // @ts-expect-error(2339) yes, parameter_metadata does exist on the schema
            const path = getLudwigPath(fieldSchema?.parameter_metadata?.ludwig_path, featureIndex);

            return (
                <Field
                    fieldType={String(fieldSchema.type)}
                    schema={fieldSchema}
                    featureIndex={featureIndex}
                    config={config}
                    setConfig={setConfig}
                    localState={localState}
                    setLocalState={setLocalState}
                    invalidFields={invalidFields}
                    path={path}
                    schemaPath={genericOfSchemaPath(selector, schemaPath, fieldSchemaIndex)}
                    readOnly={props.readOnly}
                    userContext={props.userContext}
                />
            );
        }

        // Enum with singular enum value and number
        // Examples: trainer.batch_size, trainer.learning_rate
        if (
            (leftoverOfs[0].type === "string" && leftoverOfs[0].enum && leftoverOfs[0].enum.length === 1) ||
            (leftoverOfs[1].type === "string" && leftoverOfs[1].enum && leftoverOfs[1].enum.length === 1)
        ) {
            const fieldSchema = leftoverOfs[0].type !== "string" ? leftoverOfs[0] : leftoverOfs[1];
            const fieldSchemaIndex = leftoverOfs[0].type !== "string" ? 0 : 1;
            const enumSchema = leftoverOfs[0].type === "string" ? leftoverOfs[0] : leftoverOfs[1];
            const enumValue = enumSchema?.enum?.[0];
            // @ts-expect-error(2339) yes, parameter_metadata does exist on the schema
            const path = getLudwigPath(fieldSchema?.parameter_metadata?.ludwig_path, featureIndex);
            const value = getFieldState(path, localState, config);
            const title = schema.title ? schema?.title : getAlternativeTitle(path);
            const titlePrefix = _.get(schema, "parameter_metadata.prefix", "");

            return (
                <Form.Field
                    style={{ marginBottom: `${24 / 14}rem` }}
                    key={`of_field_enum-number_${schema?.title}_${selector}`}
                >
                    <FieldTitle title={snakeToTitle(title)} prefix={(titlePrefix as string) ?? undefined} />
                    <Markdown children={props.description} secondary={true} />
                    <div style={{ display: "flex", marginBottom: `${8 / 14}rem` }}>
                        <Input
                            type={"text"}
                            path={path}
                            title={title}
                            error={_.has(invalidFields, path)}
                            value={value}
                            description={props.description}
                            defaultValue={defaultValue}
                            setConfig={setConfig}
                            setLocalState={setLocalState}
                            schemaPath={genericOfSchemaPath(selector, schemaPath, fieldSchemaIndex)}
                            readOnly={props.readOnly}
                        />
                        <FieldAnnotations path={path} defaultValue={defaultValue} schema={schema} />
                    </div>
                    <Checkbox
                        path={path}
                        label={`Use ${enumValue}`}
                        error={_.has(invalidFields, path)}
                        value={value === enumValue ? true : false}
                        onChange={(checked?: boolean) => {
                            const newValue = checked ? enumValue : defaultValue;
                            // @ts-expect-error
                            handleLocalState(path, newValue, setLocalState, setConfig);
                        }}
                        readOnly={props.readOnly}
                    />
                </Form.Field>
            );
        }
    }

    // @ts-expect-error
    const path = getLudwigPath(schema?.parameter_metadata?.ludwig_path, featureIndex);
    const titlePrefix = _.get(schema, "parameter_metadata.prefix", "");
    const value = getFieldState(path, localState, config);
    return (
        <Form.Field style={{ marginBottom: `${24 / 14}rem` }} key={`of_field_multi_${schema?.title}_${selector}`}>
            <GenericOf
                selector={selector}
                path={path}
                ofSchemas={leftoverOfs}
                title={schema?.title}
                titlePrefix={(titlePrefix as string) ?? undefined}
                error={_.has(invalidFields, path)}
                value={value}
                defaultValue={schema?.default}
                description={schema?.description}
                config={config}
                setConfig={setConfig}
                localState={localState}
                setLocalState={setLocalState}
                invalidFields={invalidFields}
                featureIndex={featureIndex}
                schemaPath={schemaPath}
            />
        </Form.Field>
    );
};
