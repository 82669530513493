import { FlowError } from "@ory/kratos-client";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Header, Message } from "semantic-ui-react";
import { getErrorMessage } from "../../utils/errors";
import { kratosSdk } from "../../utils/kratos";

const KratosErrorView = () => {
    const { search } = useLocation();
    const params = new URLSearchParams(search);

    // TODO: When is error id ever set?
    const errorID = params.get("id") ?? "";

    const [response, setResponse] = useState<FlowError>();
    const [metaErrorMessage, setMetaErrorMessage] = useState<string | null>("");

    // TODO: Ummmmm
    useEffect(() => {
        kratosSdk
            .getFlowError({ id: errorID })
            .then((res) => {
                console.log("ERROR", res);
                setResponse(res.data);
            })
            .catch((err) => {
                console.log(err);
                setMetaErrorMessage(getErrorMessage(err));
            });
    }, [errorID]);

    // @ts-expect-error
    const message = response?.error?.message || metaErrorMessage;
    const status = (
        <span>
            {/*
            // @ts-expect-error */}
            {response?.error?.code} {response?.error?.status ?? ""}
        </span>
    );

    return (
        <div style={{ padding: "20px" }}>
            <Header as={"h2"}>An error was encountered while trying to sign you up</Header>
            <Message negative>
                <Message.Header>{status}</Message.Header>
                {message}
            </Message>
            <Link to={"/auth/signup"} style={{ display: "block", textAlign: "center" }}>
                Try Signing Up Again
            </Link>
        </div>
    );
};

export default KratosErrorView;
