import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { Button, Checkbox, Header, Loader, Message, Modal, Table } from "semantic-ui-react";
import { useAuth0TokenOptions } from "../../data";
import EngineSelector, { EngineSortOrder } from "../../engines/EngineSelector";
import metrics from "../../metrics/metrics";
import { RecommendedExperiment, RecommendedExperimentList } from "../../predibase_api/iterml/v1/iterml";
import { EngineServiceType } from "../../types/engineServiceType";
import { getErrorMessage } from "../../utils/errors";
import { useRecommendedNextExperimentsQuery } from "../query";
import { trainRecommendedExperiments } from "../utilApis";

const RecommendNextExperimentsModal = (props: {
    model?: Model;
    setModel: React.Dispatch<React.SetStateAction<Model | undefined>>;
    refreshFunc: () => void;
}) => {
    const { model, setModel, refreshFunc } = props;
    const open = Boolean(model);
    const queryClient = useQueryClient();

    const [engineID, setEngineID] = useState(model?.engineID);
    const [checkedModels, setCheckedModels] = useState<number[]>([]);
    const [trainingError, setTrainingError] = useState<string>();

    const {
        status,
        data,
        error: loadingError,
        fetchStatus,
    } = useRecommendedNextExperimentsQuery(model, { enabled: open });
    const recommendedList: RecommendedExperimentList = RecommendedExperimentList.fromJson(data ?? {});

    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    const { mutate, reset } = useMutation({
        mutationFn: () => {
            if (!model) {
                return Promise.reject("Missing Model");
            }

            const recommendedListToTrain: RecommendedExperiment[] = [];
            checkedModels.forEach((checkedModelIndex) =>
                recommendedListToTrain.push(recommendedList?.recommendedExperiments[checkedModelIndex]),
            );
            const modelWithEngine = { ...model, engineID: engineID } as Model;
            return trainRecommendedExperiments(auth0TokenOptions, recommendedListToTrain, modelWithEngine, queryClient);
        },
        onSuccess: () => {
            setModel(undefined);
            refreshFunc();
        },
        onError: () => {
            setTrainingError("Unable to train some recommended experiments.");
        },
    });

    const error = trainingError ? trainingError : status === "error" ? loadingError : undefined;

    if (!open) {
        return null;
    }

    return (
        <Modal
            open={open}
            onClose={() => {
                setModel(undefined);
                setCheckedModels([]);
                reset();
            }}
            size={"large"}
            className={metrics.BLOCK_AUTO_CAPTURE}
        >
            <Modal.Header>
                <span style={{ fontSize: `${18 / 14}rem` }}>
                    <img src={"/logos/icon/ds_copilot.svg"} alt="" /> Recommended Next Experiments
                </span>
            </Modal.Header>
            <Modal.Content style={{ minHeight: `${60 / 14}rem` }}>
                {status === "pending" && <Loader active className="workaround" size="large" />}
                {error && <Message error>{getErrorMessage(error)}</Message>}
                {(status === "success" || fetchStatus === "idle") && (
                    <>
                        <Table basic={"very"} collapsing compact>
                            <Table.Body>
                                {recommendedList?.recommendedExperiments.map((recommended_experiment, index) => {
                                    return (
                                        <Table.Row>
                                            <Table.Cell style={{ verticalAlign: "top", borderTop: "none" }}>
                                                <Checkbox
                                                    onChange={(event, data) => {
                                                        data.checked
                                                            ? setCheckedModels((x) => [...x, index])
                                                            : setCheckedModels((x) => x.filter((y) => y !== index));
                                                    }}
                                                />
                                            </Table.Cell>
                                            <Table.Cell key={index} style={{ borderTop: "none" }}>
                                                <Header as={"h2"} size={"tiny"}>
                                                    Recommendation {index + 1}: {recommended_experiment.title}
                                                </Header>
                                                <p>{recommended_experiment.description}</p>
                                                {recommended_experiment.parameterChanges?.map(
                                                    (parameter_change, index) => {
                                                        return (
                                                            <ul>
                                                                <li>
                                                                    Parameter: <b>{parameter_change.parameterName}</b>
                                                                </li>
                                                                <li>
                                                                    {parameter_change.originalValue} →{" "}
                                                                    {parameter_change.newValue}
                                                                </li>
                                                            </ul>
                                                        );
                                                    },
                                                )}
                                                <br />
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </Table>
                        <Header as={"h2"} size={"tiny"}>
                            Engine to train with
                        </Header>
                        <EngineSelector
                            label={null}
                            engineID={engineID}
                            onEngineSelect={(e) => setEngineID(e.id)}
                            width={"100%"}
                            sortOrder={EngineSortOrder.TRAINING}
                            serviceTypes={[EngineServiceType.BATCH, EngineServiceType.RAY]}
                            onlyAllowTrainableEngines
                        />
                    </>
                )}
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="Close"
                    color="black"
                    onClick={() => {
                        setModel(undefined);
                        setCheckedModels([]);
                        reset();
                    }}
                />
                <Button
                    style={{ whiteSpace: "nowrap" }}
                    content="Train models"
                    disabled={checkedModels.length === 0}
                    positive
                    icon="check"
                    labelPosition="right"
                    onClick={(e) => {
                        e.preventDefault();
                        setCheckedModels([]);

                        if (!model) {
                            return;
                        }

                        reset();
                        mutate();
                    }}
                />
            </Modal.Actions>
        </Modal>
    );
};

export default RecommendNextExperimentsModal;
