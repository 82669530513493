import { Icon } from "semantic-ui-react";

function PredictionCell(props: { value: string; callback: (value: any) => void; flaggedValue: any }) {
    const isFlagged = props.flaggedValue && props.flaggedValue !== props.value;

    return (
        <>
            {isFlagged ? <Icon name="attention" /> : null}
            {props.flaggedValue || props.value}
        </>
    );
}

export default PredictionCell;
