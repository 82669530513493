import { useEffect, useRef } from "react";

import "ace-builds/src-min-noconflict/ext-language_tools";
import "ace-builds/src-min-noconflict/ext-searchbox";
import "ace-builds/src-min-noconflict/mode-yaml";
import "ace-builds/src-min-noconflict/theme-chrome";
import type { JSONSchema7 } from "json-schema";
import AceEditor from "react-ace";
import metrics from "../../metrics/metrics";
import { getCompletionsFromSchema } from "./utils";

function AdvancedConfigEditor(props: {
    onEditorChange: (value: string, event?: any) => void;
    schema?: JSONSchema7;
    yaml: string;
}) {
    const editorRef = useRef<AceEditor | null>(null);

    useEffect(() => {
        editorRef.current?.editor.completers.push({
            getCompletions: getCompletionsFromSchema(props?.schema),
        });
    }, []);

    return (
        <AceEditor
            className={metrics.BLOCK_AUTO_CAPTURE}
            ref={editorRef}
            width="100%"
            height="inherit"
            mode="yaml"
            theme="chrome"
            fontSize={16}
            // fontFamily='"Fira code", "Fira Mono", monospace'
            showPrintMargin={false}
            showGutter={true}
            tabSize={4}
            highlightActiveLine={true}
            value={props.yaml}
            onChange={props.onEditorChange}
            setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: false,
                showLineNumbers: true,
                tabSize: 2,
            }}
            style={{
                minHeight: "500px",
            }}
        />
    );
}

export default AdvancedConfigEditor;
