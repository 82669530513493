import { LoginFlow, UpdateLoginFlowBody } from "@ory/kratos-client";
import { useQueryClient } from "@tanstack/react-query";
import { get } from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Button, Divider, Header, Loader } from "semantic-ui-react";
import metrics from "../../metrics/metrics";
import { GET_USER_CONTEXT_QUERY_KEY } from "../../query";
import { SESSION_STATE } from "../../state/global";
import { PREDIBASE_LIGHT_GRAY } from "../../utils/colors";
import { kratosSdk, useSDKError } from "../../utils/kratos";
import KratosSigninForm from "./form/KratosSigninForm";

const KratosSigninView = () => {
    const [flow, setFlow] = useState<LoginFlow>();
    const [, setSession] = useRecoilState(SESSION_STATE);
    const { search } = useLocation();
    const params = useMemo(() => new URLSearchParams(search), [search]);
    const signInSubmitButton = useRef<Button>(null);
    const queryClient = useQueryClient();

    const navigate = useNavigate();

    // Get the flow based on the flowId in the URL (.e.g redirect to this page after flow initialized)
    const getFlow = useCallback(
        (flowId: string) =>
            kratosSdk
                // the flow data contains the form fields, error messages and csrf token
                .getLoginFlow({ id: flowId })
                .then(({ data: flow }) => {
                    setFlow(flow);

                    // TODO Do we even need this?
                    const messages = flow?.ui?.messages;
                    if (Array.isArray(messages) && messages.some((m) => m.type === "error")) {
                        metrics.captureError("api_error", "Error", {
                            endpoint: "kratos-login-flow",
                            messages: messages,
                        });
                    }

                    // If the flow has expired, automatically re-submit the form to sign the user in:
                    if (get(messages, "[0].id") === 4010001) {
                        // @ts-expect-error
                        signInSubmitButton?.current?.ref?.current.click();
                    }
                })
                .catch(sdkErrorHandler),
        [], // eslint-disable-line react-hooks/exhaustive-deps
    );

    // initialize the sdkError for generic handling of errors
    const sdkErrorHandler = useSDKError(getFlow, setFlow, "/auth/signin", true);

    // Create a new login flow
    const createFlow = () => {
        const aal2 = params.get("aal2");
        kratosSdk
            // aal2 is a query parameter that can be used to request Two-Factor authentication
            // aal1 is the default authentication level (Single-Factor)
            .createBrowserLoginFlow({ aal: aal2 ? "aal2" : "aal1" })
            // flow contains the form fields and csrf token
            .then(({ data: flow }) => {
                // Update URI query params to include flow id
                params.set("flow", flow.id);
                navigate({ search: [params].toString() }, { replace: true });
                // Set the flow data
                setFlow(flow);
            })
            .catch(sdkErrorHandler);
    };

    // submit the login form data to Ory
    const submitFlow = (body: UpdateLoginFlowBody) => {
        // something unexpected went wrong and the flow was not set
        if (!flow) return navigate("/auth/signup", { replace: true });

        // we submit the flow to Ory with the form data
        return kratosSdk
            .updateLoginFlow({ flow: flow.id, updateLoginFlowBody: body })
            .then(({ data }) => {
                // we successfully submitted the login flow, save the state and redirect to the dashboard
                setSession(data.session);
                const returnTo = params.get("return_to") || "/";
                navigate(returnTo, { replace: true });
                queryClient.invalidateQueries({ queryKey: GET_USER_CONTEXT_QUERY_KEY });
                queryClient.clear();
            })
            .catch(sdkErrorHandler);
    };

    useEffect(() => {
        // we might redirect to this page after the flow is initialized, so we check for the flowId in the URL
        const flowId = params.get("flow");
        // the flow already exists
        if (flowId) {
            getFlow(flowId).catch(createFlow); // if for some reason the flow has expired, we need to get a new one
            return;
        }

        // we assume there was no flow, so we create a new one
        createFlow();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const form = flow?.ui;

    return (
        <div
            style={{
                minHeight: "100vh",
                background: PREDIBASE_LIGHT_GRAY,
                display: "flex",
                justifyContent: "center",
                padding: "20px",
            }}
        >
            <div
                style={{
                    textAlign: "center",
                    width: "50vw",
                    maxWidth: "400px",
                    alignSelf: "center",
                }}
            >
                <img src={"/logos/predibase/predibase.svg"} width={70} height={70} alt="" />
                {flow ? (
                    <>
                        <Header as="h2" textAlign="center">
                            Sign in to Predibase
                        </Header>
                        {form && (
                            <KratosSigninForm
                                submitLabel="Sign in"
                                action={form.action}
                                fields={form.nodes}
                                messages={form.messages}
                                onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                                    event.preventDefault();
                                    const data = new FormData(event.currentTarget);
                                    const flowBody = {} as UpdateLoginFlowBody;

                                    for (const pair of data.entries()) {
                                        // @ts-expect-error
                                        flowBody[pair[0]] = pair[1];
                                    }
                                    flowBody.method = "password";
                                    return submitFlow(flowBody);
                                }}
                                ref={signInSubmitButton}
                            />
                        )}
                        <Divider hidden />
                        New to Predibase?{" "}
                        {
                            // eslint-disable-next-line react/jsx-no-target-blank
                            <a href="https://predibase.com/free-trial" target="_blank" rel="noopener">
                                Sign up
                            </a>
                        }
                    </>
                ) : (
                    <Loader active inline="centered" />
                )}
            </div>
            <Divider hidden />
        </div>
    );
};

export default KratosSigninView;
