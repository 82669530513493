import { PaymentMethod } from "@stripe/stripe-js";
import React, { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Button, Header, Loader, Message, Modal, Segment } from "semantic-ui-react";
import { tier } from "../../api_generated/models/tier";
import UpgradeFreeTrialModalContents from "../../components/UpgradeFreeTrialModalContents";
import { USER_STATE } from "../../state/global";
import { BillingInfo, MetronomeDashboardType, TaxIDData } from "../../types/billing";
import { PREDIBASE_LIGHT_GRAY, SEMANTIC_GREY_ACTIVE } from "../../utils/colors";
import { getErrorMessage } from "../../utils/errors";
import { isKratosUserContext } from "../../utils/kratos";
import { commonGetMetronomeDashboardQuerySettings, useMetronomeDashboardQuery } from "../query";
import PaymentForm from "./PaymentForm";
import { useStripeCustomerQuery, useStripeSetupIntent } from "./query";

const PaymentConfirmation = () => {
    const { search } = useLocation();
    const params = useMemo(() => new URLSearchParams(search), [search]);
    // https://stripe.com/docs/payments/save-and-reuse?platform=web&ui=elements#submit-payment-details
    const setupIntentClientSecret = params.get("payment_intent_client_secret");
    const { data, isLoading, isError, error: apiError } = useStripeSetupIntent(setupIntentClientSecret);
    const setupIntentSucceeded = data?.setupIntent?.status === "succeeded";

    if (isLoading) {
        return (
            <Message>
                <Message.Header>Verifying Payment Method</Message.Header>
                <Message.Content>
                    <Loader active inline="centered" />
                </Message.Content>
            </Message>
        );
    }

    if (isError || !setupIntentSucceeded) {
        // An error either occurs during the setup intent (and will be captured inside of the Stripe API's response,
        // i.e. `data` -- see the type of `SetupIntentResult`), or somewhere else in the networking stack (in which
        // case it will be inside of the `apiError`):
        const errorMessage = getErrorMessage(apiError) || data?.error?.message;
        return (
            <Message negative>
                <Message.Header>Error Processing Payment Method</Message.Header>
                <Message.Content>{errorMessage}</Message.Content>
            </Message>
        );
    }

    return (
        <Message positive={setupIntentSucceeded}>
            <Message.Header>Payment Method Added</Message.Header>
            <Message.Content>
                Your credit card was successfully saved to your account! Enjoy using Predibase!
            </Message.Content>
        </Message>
    );
};

const CreditCardModal = (props: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    billingDetails: BillingInfo;
    taxIds?: TaxIDData[];
}) => {
    const [submitting, setSubmitting] = useState(false);
    const [userContext] = useRecoilState(USER_STATE);

    let currentTier;
    if (userContext) {
        const isKratosContext = isKratosUserContext(userContext);
        currentTier = isKratosContext ? userContext.tenant.subscription.tier : userContext.tenant.tier;
    }

    // Just show the payment form so users can update info:
    return (
        <Modal
            open={props.open}
            onClose={() => {
                props.setOpen(false);
                setSubmitting(false);
            }}
            size={currentTier === tier.FREE ? "large" : "tiny"}
        >
            <Modal.Header>Add Payment Method</Modal.Header>
            <Modal.Content style={{ textAlign: "center" }}>
                {currentTier === tier.FREE ? (
                    <UpgradeFreeTrialModalContents
                        setOpen={props.setOpen}
                        submitting={submitting}
                        setSubmitting={setSubmitting}
                    />
                ) : (
                    <PaymentForm
                        submitting={submitting}
                        setSubmitting={setSubmitting}
                        setOpen={props.setOpen}
                        billingDetails={props.billingDetails}
                        taxIds={props.taxIds}
                    />
                )}
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => props.setOpen(false)}>Close</Button>
                <Button type="submit" form="credit-card-form" primary disabled={submitting}>
                    {currentTier === tier.FREE ? "Upgrade to Developer Tier" : "Save"}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

const CreditCardDisplay = (props: {
    number: string | undefined;
    expiration: string;
    type: string | undefined;
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => (
    <>
        <div
            style={{
                display: "flex",
                alignItems: "flex-start",
                width: "22.85714rem",
                height: "6.57143rem",
                backgroundColor: PREDIBASE_LIGHT_GRAY,
                padding: "1.71rem",
                borderRadius: "0.42857rem",
            }}
        >
            <img
                src={`/credit-cards/${props.type}.svg`}
                alt={props.type}
                style={{ width: "2.64286rem", marginRight: "1.14286rem" }}
            />
            <div>
                <p style={{ marginBottom: 0 }}>****{props.number}</p>
                <p style={{ color: SEMANTIC_GREY_ACTIVE }}>Expires {props.expiration}</p>
            </div>
        </div>
        <button
            onClick={() => {
                props.setModalOpen(true);
            }}
            className="button-reset"
            style={{
                marginTop: 0,
                fontWeight: 400,
            }}
        >
            Change Payment Method
        </button>
    </>
);

const BillingView = (props: { showConfirmation: boolean }) => {
    const { showConfirmation } = props;
    const [userContext] = useRecoilState(USER_STATE);
    const [openCreditCardModal, setOpenCreditCardModal] = useState(false);

    let subscriptionTier;
    if (userContext) {
        const isKratosContext = isKratosUserContext(userContext);
        subscriptionTier = isKratosContext ? userContext.tenant.subscription.tier : userContext.tenant.tier;
    }

    const {
        data: customerInformationUndiscriminated,
        error: customerError,
        isLoading: isCustomerLoading,
        isError: isCustomerError,
    } = useStripeCustomerQuery({
        retry: false,
    });

    // TODO: Perhaps we handle the case if (somehow) the customer is deleted?
    // Discriminate the union response type on whether customer is deleted or not:
    const customerIsNotDeleted = !customerInformationUndiscriminated?.deleted;
    const customerInformation = customerIsNotDeleted ? customerInformationUndiscriminated : undefined;

    // A 404 is returned when the user does not have a Stripe account
    // However, their first attempt to save a payment method may fail,
    // so we still want to show the CTA to add their first payment method.
    const isFirstTimePayer =
        // TODO: Perhaps using lodash to access this error prop would be better...
        (isCustomerError && (customerError as unknown as { status: number })?.status === 204) ||
        !customerInformation?.invoice_settings?.default_payment_method;
    const invoicePaymentMethod = customerInformation?.invoice_settings?.default_payment_method as
        | PaymentMethod
        | undefined;
    const billingDetails = {
        name: customerInformation?.name,
        phone: customerInformation?.phone,
        address: customerInformation?.address,
    };

    const {
        data: invoiceDashboardUrl,
        error: invoiceError,
        isLoading: isInvoiceLoadig,
        isError: isInvoiceError,
    } = useMetronomeDashboardQuery(MetronomeDashboardType.INVOICES, commonGetMetronomeDashboardQuerySettings());

    const {
        data: creditsDashboardUrl,
        error: creditsError,
        isLoading: isCreditsLoadig,
        isError: isCreditsError,
    } = useMetronomeDashboardQuery(MetronomeDashboardType.CREDITS, commonGetMetronomeDashboardQuerySettings());

    return (
        <div
            style={{
                maxWidth: `${1024 / 14}rem`,
                margin: "0 auto",
            }}
        >
            {isCreditsError && (
                <Message negative>
                    <Message.Header>Error</Message.Header>
                    <Message.Content>{creditsError.message}</Message.Content>
                </Message>
            )}
            <Segment loading={isCreditsLoadig} disabled={isCreditsError} basic>
                <iframe
                    src={creditsDashboardUrl}
                    width="100%"
                    height="256px"
                    title="Credits Dashboard"
                    seamless
                    style={{
                        border: "none",
                    }}
                />
            </Segment>
            {/* TODO: Does this need to change when we update our subscription tiers? */}
            {subscriptionTier !== tier.ENTERPRISE_VPC && subscriptionTier !== tier.ENTERPRISE_SAAS && (
                <>
                    <Header as="h2">Payment Details</Header>
                    {showConfirmation && <PaymentConfirmation />}
                    {isCustomerError && !isFirstTimePayer && (
                        <Message negative>
                            <Message.Header>Payments unavailable at this time</Message.Header>
                            <Message.Content>{(customerError as { message: string })?.message}</Message.Content>
                        </Message>
                    )}
                    <Segment loading={isCustomerLoading} disabled={isCustomerError && !isFirstTimePayer} basic>
                        {isFirstTimePayer ? (
                            <>
                                <p>No payment method has been added.</p>
                                <Button
                                    primary
                                    size="large"
                                    onClick={() => {
                                        setOpenCreditCardModal(true);
                                    }}
                                >
                                    Add Payment Method
                                </Button>
                            </>
                        ) : (
                            <>
                                <CreditCardDisplay
                                    number={invoicePaymentMethod?.card?.last4}
                                    type={invoicePaymentMethod?.card?.brand}
                                    expiration={`${invoicePaymentMethod?.card?.exp_month}/${invoicePaymentMethod?.card?.exp_year}`}
                                    setModalOpen={setOpenCreditCardModal}
                                />
                            </>
                        )}
                    </Segment>
                </>
            )}
            <Header as="h2">Invoices</Header>
            {isInvoiceError && (
                <Message negative>
                    <Message.Header>Error</Message.Header>
                    <Message.Content>{invoiceError.message}</Message.Content>
                </Message>
            )}
            <Segment loading={isInvoiceLoadig} disabled={isInvoiceError} basic>
                <iframe
                    src={invoiceDashboardUrl}
                    width="100%"
                    height="900px"
                    title="Invoices Dashboard"
                    seamless
                    style={{
                        border: "none",
                    }}
                />
            </Segment>
            <CreditCardModal
                open={openCreditCardModal}
                setOpen={setOpenCreditCardModal}
                billingDetails={billingDetails}
                taxIds={customerInformation?.tax_ids?.data}
            />
        </div>
    );
};

export default BillingView;
