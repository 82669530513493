import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Message, Modal } from "semantic-ui-react";
import { useAuth0TokenOptions } from "../../../data";
import { getErrorMessage } from "../../../utils/errors";
import { deleteAdapterVersion, versionDeleteParams } from "../../data";
import { GET_ADAPTER_REPO_QUERY_KEY } from "../../query";

const DeleteAdapterVersionModal = (props: {
    adapterRepoUuid?: string;
    adapterVersionNumber?: number;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    // Parent state:
    const { adapterRepoUuid, adapterVersionNumber, open, setOpen } = props;

    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    // Query state:
    const queryClient = useQueryClient();

    const {
        mutate: deleteVersion,
        error: deletionError,
        reset: resetMutation,
    } = useMutation({
        mutationFn: (params: versionDeleteParams) => deleteAdapterVersion(params, auth0TokenOptions),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: GET_ADAPTER_REPO_QUERY_KEY(params.repoUUID) });
            setOpen(false);
            resetMutation();
        },
    });

    const params: versionDeleteParams = {
        repoUUID: adapterRepoUuid ?? "",
        versionTag: adapterVersionNumber ?? 0,
    };

    const errorMessage = getErrorMessage(deletionError);

    return (
        <Modal
            open={open}
            onClose={() => {
                resetMutation();
            }}
            size="small"
        >
            <Modal.Header>
                Delete adapter version {adapterVersionNumber ? `#${adapterVersionNumber}` : null}
            </Modal.Header>
            <Modal.Content>
                {errorMessage && (
                    <Message negative>
                        <Message.Header>Failed to delete adapter version</Message.Header>
                        <p>{errorMessage}</p>
                    </Message>
                )}
                <span>
                    Are you sure you'd like to delete adapter version <b>{adapterVersionNumber ?? 0}</b>? This action
                    cannot be undone.
                </span>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    onClick={() => {
                        setOpen(false);
                        resetMutation();
                    }}
                >
                    Cancel
                </Button>
                <Button
                    negative
                    onClick={() => {
                        deleteVersion(params);
                    }}
                >
                    Delete
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default DeleteAdapterVersionModal;
