import React from "react";
import { Link } from "react-router-dom";
import { Icon, Table } from "semantic-ui-react";
import ConnectionImagePopup from "../../components/ConnectionImagePopup";
import LiveTimeAgo from "../../components/LiveTimeAgo";
import NoResultsTableRow from "../../components/NoResultsTableRow";
import metrics from "../../metrics/metrics";
import { capitalize } from "../../utils/strings";
import { connectionDisplayTitle, getDataStatusColor } from "../utils";

function DatasetsTableBase(props: {
    searchbar: React.ReactNode | null;
    scopeUserToggle: React.ReactNode;
    datasets?: Dataset[];
}) {
    const getTableRow = (dataset: Dataset, i: number) => {
        return (
            <Table.Row key={dataset.id}>
                <Table.Cell>
                    <Link
                        className={metrics.BLOCK_AUTO_CAPTURE}
                        onClick={() => {
                            metrics.capture("Data.DatasetsTable.DatasetDetails.click", {
                                datasetID: dataset.id,
                                datasetName: dataset.name,
                            });
                        }}
                        to={`/data/datasets/${dataset.id}`}
                    >
                        {dataset.name}
                    </Link>
                </Table.Cell>

                <Table.Cell collapsing>
                    <ConnectionImagePopup connectionType={dataset.connection.type} />
                </Table.Cell>
                <Table.Cell style={{ borderLeft: "none", paddingLeft: 0 }}>
                    <Link
                        className={metrics.BLOCK_AUTO_CAPTURE}
                        onClick={() => {
                            metrics.capture("Data.DatasetsTable.ConnectionDetails.click", {
                                connectionID: dataset.connection.id,
                                connectionName: dataset.connection.name,
                            });
                        }}
                        to={`/data/connections/${dataset.connection.id}`}
                    >
                        {connectionDisplayTitle(dataset.connection.name)}
                    </Link>
                </Table.Cell>
                <Table.Cell>{dataset.user?.username || ""}</Table.Cell>
                <Table.Cell>
                    <Icon name="circle" color={getDataStatusColor(dataset.status)} /> {capitalize(dataset.status)}
                </Table.Cell>
                <Table.Cell>
                    <LiveTimeAgo fromDate={new Date(dataset.created)} />
                </Table.Cell>
                <Table.Cell>
                    <LiveTimeAgo fromDate={new Date(dataset.updated)} />
                </Table.Cell>
            </Table.Row>
        );
    };

    return (
        <Table selectable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Dataset&emsp;{props.searchbar}</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} collapsing textAlign={"center"}>
                        Connection
                    </Table.HeaderCell>
                    <Table.HeaderCell>Author&nbsp; {props.scopeUserToggle}</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>Created</Table.HeaderCell>
                    <Table.HeaderCell>Updated</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {props.datasets != null && props.datasets.length === 0 ? <NoResultsTableRow colSpan={8} /> : null}
                {(props.datasets || []).map((dataset: Dataset, i: number) => getTableRow(dataset, i))}
            </Table.Body>
        </Table>
    );
}

export default DatasetsTableBase;
