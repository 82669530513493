const unavailableVH = 9;

export const deletedUser = "Deleted User";

export function toVH(percent: number) {
    return percent - unavailableVH + "vh";
}

export function getColor(seed: number) {
    seed = seed + 5;
    return "#" + Math.floor(Math.abs(Math.sin(seed) * 16777215) % 16777215).toString(16);
}

// NOTE: Keep this in sync with engine/profiles/profiles.py.
export const IMAGE_EXTENSIONS_REGEX = /\.(apng|avif|gif|jpeg|jpg|png|svg|webp|bmp|ico|tiff)($|\?.+)/;
export const AUDIO_EXTENSIONS_REGEX = /\.(wav|amb|mp3|ogg|vorbis|flac|opus|sphere)($|\?.+)/;
