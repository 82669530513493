import { AxiosError } from "axios";

// ! NOTE: If we errors were standardized (i.e. JSON objects) across all API services, we wouldn't have to do this:
// Try to get the error message from the given error payload using a variety of extraction methods for known error
// types. If the error is empty, return null.
export const getErrorMessage = (error: null | undefined | string | Error | AxiosError<any>): string | null => {
    if (error === null || error === undefined) {
        return null;
    }

    if (typeof error === "string") {
        return error !== "" ? error : null;
    }
    if (error instanceof AxiosError) {
        const possibleNestedError = error.response?.data?.error as undefined | Error;
        const possibleMessage = possibleNestedError?.message as string | undefined;
        return possibleMessage !== undefined && possibleMessage !== "" ? possibleMessage : null;
    }
    // TODO: What is maybe missing here is a true generic fallback, such as:
    // if (error instanceof Object) {
    //     return JSON.stringify(error); // Obviously not acceptable, but you get the idea
    // }
    return error.message !== "" ? error.message : null;
};
