import { atom } from "recoil";

export const USERS_OFFSET_STATE = atom({
    key: "USERS_OFFSET_STATE",
    default: 0,
});

export const USERS_TOTAL_STATE = atom({
    key: "USERS_TOTAL_STATE",
    default: 0,
});

export const SETTINGS_TAB_STATE = atom({
    key: "SETTINGS_TAB_STATE",
    default: 0,
});
