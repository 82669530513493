export enum SearchSpace {
    UNIFORM = "uniform",
    QUNIFORM = "quniform",
    LOGUNIFORM = "loguniform",
    QLOGUNIFORM = "qloguniform",
    RANDN = "randn",
    QRANDN = "qrandn",
    RANDINT = "randint",
    QRANDINT = "qrandint",
    LOGRANDINT = "lograndint",
    QLOGRANDINT = "qlograndint",
    CHOICE = "choice",
    GRID_SEARCH = "grid_search",
}
