import yaml from "js-yaml";
import { ReactNode, useState } from "react";
import { Message } from "semantic-ui-react";

import { renderValidationErrorList, toYAML } from "../../utils/config";
import AdvancedConfigEditor from "./AdvancedConfigEditor";
import { useConfigState, useDispatch } from "./store";

function AdvancedConfigManager(props: {}) {
    const [editedYAML, setEditedYAML] = useState<string | null>(null);
    const [yamlErrorMessage, setYAMLErrorMessage] = useState<ReactNode | undefined>();
    const dispatch = useDispatch();
    const { config, invalidFields, schema } = useConfigState();

    const errorMessage = yamlErrorMessage
        ? yamlErrorMessage
        : Object.keys(invalidFields).length > 0
          ? renderValidationErrorList([], invalidFields)
          : undefined;

    const onEditorChange = (newYaml: string) => {
        setEditedYAML(newYaml);
        try {
            const updatedConfig = yaml.load(newYaml) as CreateModelConfig;
            setYAMLErrorMessage(undefined);
            dispatch({ type: "UPDATE_CONFIG", config: updatedConfig });
        } catch (error: any) {
            setYAMLErrorMessage(error.message);
            dispatch({ type: "UPDATE_CONFIG_YAML" });
        }
    };

    return (
        <div>
            {!!errorMessage && (
                <Message positive={!errorMessage} warning={!!errorMessage}>
                    {errorMessage ? <>Configuration has errors: {errorMessage}</> : "Configuration is up to date."}
                </Message>
            )}
            <Message warning>
                <Message.Content>Modifying this file may cause your model not to train properly.</Message.Content>
            </Message>
            <AdvancedConfigEditor
                yaml={editedYAML != null ? editedYAML : toYAML(config)}
                schema={schema}
                onEditorChange={onEditorChange}
            />
        </div>
    );
}

export default AdvancedConfigManager;
