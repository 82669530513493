import { adapterVersionListItem, finetuningJobStatus, repo } from "../../api_generated";

export const isTerminalJobStatus = (status?: finetuningJobStatus): boolean => {
    return (
        status === finetuningJobStatus.ERRORED ||
        status === finetuningJobStatus.COMPLETED ||
        status === finetuningJobStatus.CANCELED ||
        status === finetuningJobStatus.STOPPED
    );
};

export const isFailedOrCanceledJobStatus = (status?: string): boolean => {
    return status === finetuningJobStatus.ERRORED || status === finetuningJobStatus.CANCELED;
};

export const getNextAdapterVersion = (adapterRepo?: repo) => {
    const adapterVersions = Array.isArray(adapterRepo?.versions) ? adapterRepo?.versions : [];
    const versions = adapterVersions.map((v) => v.tag);
    return Math.max(...versions, 0) + 1;
};

export const getlatestAdapterInRepo = (repo: repo) => {
    // TODO: Remove "null" union type when we fix the backend :(
    const versions = repo.versions as null | adapterVersionListItem[];
    if (versions === null || versions?.length === 0) {
        return undefined;
    }

    const sortedVersions = versions.sort((v1, v2) => {
        const tag1 = Number(v1.tag);
        const tag2 = Number(v2.tag);
        if (tag1 < tag2) {
            return -1;
        }
        if (tag1 > tag2) {
            return 1;
        }
        return 0;
    });

    return sortedVersions.at(-1);
};

export const removeEmptyParameters = (config?: Record<string, any>) => {
    const result: Record<string, any> = {};
    for (const key in config) {
        if (config[key] !== undefined && config[key] !== null) {
            result[key] = config[key];
        }
    }
    return result;
};

export const initRemoveParametersUnsupportedByContinuedTraining = (
    config?: Record<string, any>,
    continueDatasetsMatch?: boolean,
) => {
    const parameters = ["rank", "target_modules"];
    const result: Record<string, any> = {};

    for (const key in config) {
        if (!parameters.includes(key) || (continueDatasetsMatch && key === "learning_rate")) {
            result[key] = config[key];
        }
    }
    return result;
};
