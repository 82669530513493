import { PREDIBASE_LIGHT_GRAY, SEMANTIC_PINK_ACTIVE } from "../utils/colors";

const CodeBlock = (props: { children: any }) => (
    <code
        style={{
            color: SEMANTIC_PINK_ACTIVE,
            background: PREDIBASE_LIGHT_GRAY,
            border: "1px solid var(--utility-secondary, #E0E1E2)",
            borderRadius: `{4/14}rem`,
            padding: `0.21rem 0.14rem`,
        }}
    >
        {props.children}
    </code>
);

export default CodeBlock;
