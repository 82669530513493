import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Button, Divider, Header, Icon, Input, Message, Modal } from "semantic-ui-react";
import { useAuth0TokenOptions } from "../../data";
import { track } from "../../metrics/june";
import metrics from "../../metrics/metrics";
import { USER_STATE } from "../../state/global";
import { SEMANTIC_GREY } from "../../utils/colors";
import { getErrorMessage } from "../../utils/errors";
import { createModelRepo } from "../data";

import "./CreateModelRepoModal.css";

function CreateModelRepoModal(props: { isFork?: boolean; parentRepoID?: number; trigger?: JSX.Element }) {
    const [open, setOpen] = useState<boolean>();
    const [repoName, setRepoName] = useState<string>();
    const [repoDescription, setRepoDescription] = useState<string>();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [userContext] = useRecoilState(USER_STATE);

    const navigate = useNavigate();

    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    const { mutate: createRepo, reset: resetMutation } = useMutation({
        mutationFn: () =>
            createModelRepo(repoName!, repoDescription!, "", {}, "ludwig", props.parentRepoID, auth0TokenOptions),
        onSuccess: (data) => {
            setErrorMessage(null);
            navigate(props.isFork ? `/models/edit/fork/repo/${data.id}` : `/models/edit/train/repo/${data.id}`);
        },
        onError: (error) => {
            setErrorMessage(getErrorMessage(error));
        },
    });

    const triggerButton = props.trigger ? (
        props.trigger
    ) : (
        <Button
            className={metrics.BLOCK_AUTO_CAPTURE}
            onClick={() => {
                // Purposely updated to match PostHog's suggested naming convention
                metrics.capture("model_repo_create_attempt");
                userContext && track(userContext, "attempt_new_model_repo");
            }}
            size="small"
            primary
            style={{ marginRight: "10px" }}
        >
            <Icon name="plus square" />
            New Model Repository
        </Button>
    );

    return (
        <Modal
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
                resetMutation();
            }}
            trigger={triggerButton}
        >
            <Modal.Header>{props.isFork ? "Fork Model Repository" : "New Model Repository"}</Modal.Header>
            <Modal.Content>
                <span>
                    A model repository helps you organize your model iterations and manage the operationalization of
                    your models. It contains all your model versions and metadata, and enables visualization and
                    comparison of all your edits and experimentation.
                </span>
                <span style={{ marginTop: "10px", display: "block" }}>
                    Your model repository will be viewable and editable by anyone in your organization.
                </span>
                {userContext?.isExpired && (
                    <Message warning color={"yellow"} className={"disabled-warning"}>
                        <p className={"message-text"}>
                            <Icon name={"warning sign"} />
                            <b>You may no longer create model repositories. </b>
                            <a href="https://predibase.com/contact-us" className={"contact-link"}>
                                Contact us{" "}
                            </a>
                            to upgrade.
                        </p>
                    </Message>
                )}
                <Divider />
                <Header as={"h2"} size={"small"} style={{ marginBottom: "5px" }}>
                    Repository Name
                </Header>
                <span style={{ color: SEMANTIC_GREY, fontSize: "0.9em" }}>
                    Great model repository names are short and easily recognizable.
                </span>
                <br />
                <Input
                    placeholder={"Name"}
                    style={{ width: "40%", marginTop: "10px" }}
                    autoFocus
                    value={repoName}
                    onChange={(event) => {
                        const data = event.target.value;
                        const sanitizedValue = data.replace(/\s+/g, "-");
                        setRepoName(sanitizedValue);
                    }}
                />
                <Header as={"h2"} size={"small"} style={{ marginBottom: "5px" }}>
                    Description (optional)
                </Header>
                <span style={{ color: SEMANTIC_GREY, fontSize: "0.9em" }}>
                    Briefly describe the use case or intent of your model repository.
                </span>
                <br />
                <Input
                    placeholder={"Description"}
                    style={{ width: "80%", marginTop: "10px" }}
                    value={repoDescription}
                    onChange={(event, data) => setRepoDescription(data.value as string)}
                />
                {errorMessage ? <Message negative>{errorMessage}</Message> : null}
            </Modal.Content>
            <Modal.Actions>
                <Button
                    className={metrics.BLOCK_AUTO_CAPTURE}
                    onClick={() => {
                        setOpen(false);
                        resetMutation();
                    }}
                >
                    Cancel
                </Button>
                <Button
                    icon
                    color={"green"}
                    labelPosition={"right"}
                    size={"small"}
                    disabled={!repoName || userContext?.isExpired}
                    className={metrics.BLOCK_AUTO_CAPTURE}
                    onClick={() => {
                        resetMutation();
                        createRepo();
                    }}
                >
                    Next: Train a model
                    <Icon name="checkmark" />
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

export default CreateModelRepoModal;
