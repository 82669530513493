import { RefObject } from "react";
import { escapeSQLSearchQuery } from "./search";

export const getActivePage = (offset: number, limit: number) => Math.floor(offset / limit) + 1;
export const getTotalPages = (total: number, limit: number) => Math.max(1, Math.ceil(total / limit));

export const generateParams = (
    datasetsOffset: number,
    limit: number,
    scopeUser: boolean,
    searchKeys: string[],
    searchValRef: RefObject<string>,
    indexVals?: string | number,
    indexKeys?: string,
) => {
    // TODO: Not great, this struct can omit props (indexVals/Keys) that are required by `PaginationParams`
    const parameters = {
        offset: datasetsOffset,
        scopeUser,
        limit,
        filter: "all",
    } as PaginationParams;

    if (searchValRef.current !== null) {
        // Replace if we support more search columns
        parameters.searchKeys = searchKeys;
        parameters.searchVals = new Array(searchKeys.length).fill(escapeSQLSearchQuery(searchValRef.current));
    }

    if (indexVals && indexKeys) {
        parameters.indexVals = [indexVals];
        parameters.indexKeys = [indexKeys];
    }

    return parameters;
};

export const generateParamsNoRef = (
    datasetsOffset: number,
    limit: number,
    scopeUser: boolean,
    searchKeys: string[],
    searchVal: string,
    indexVals?: string | number,
    indexKeys?: string,
) => {
    // TODO: Not great, this struct can omit props (indexVals/Keys) that are required by `PaginationParams`
    const parameters = {
        offset: datasetsOffset,
        scopeUser,
        limit,
        filter: "all",
    } as PaginationParams;

    // Replace if we support more search columns
    parameters.searchKeys = searchKeys;
    parameters.searchVals = new Array(searchKeys.length).fill(escapeSQLSearchQuery(searchVal));

    if (indexVals && indexKeys) {
        parameters.indexVals = [indexVals];
        parameters.indexKeys = [indexKeys];
    }

    return parameters;
};
