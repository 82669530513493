import { ValidateFunction } from "ajv";
import type { JSONSchema7 } from "json-schema";
import _ from "lodash";
import { useReducer } from "react";
import { createContainer } from "react-tracked";
import ecdSchema from "../../assets/ecd-minified.json";
import gbmSchema from "../../assets/gbm-minified.json";
import hyperoptParameterSchema from "../../assets/hyperopt-ecd-minified.json";
import llmSchema from "../../assets/llm-minified.json";
import {
    ECDInputFeatures,
    ECDOutputFeatures,
    GBMInputFeatures,
    GBMOutputFeatures,
    LLMInputFeatures,
    LLMOutputFeatures,
} from "../../types/model/featureTypes";
import { LLMTemplates } from "../../types/model/llmTemplates";
import { ModelTypes } from "../../types/model/modelTypes";
import { SelectedDefaults } from "../../types/model/selectedDefaults";
import { compileJSONSchema, validateConfigAgainstSchema } from "../../utils/ajv";
import { FeatureFlags } from "../../utils/feature-flags";
import { setSiblingTypeProperty } from "../../utils/jsonSchema";
import { templateVariableRegex } from "../../utils/strings";
import { checkIfHyperoptEnabled, getModelType, isECDModel, isGBMModel, isLLMModel } from "../util";
import lora_bf16 from "./llms/lora_bf16.json";
import qlora_4bit from "./llms/qlora_4bit.json";
import qlora_8bit from "./llms/qlora_8bit.json";
import { removeEmptyConfigProperties } from "./utils";

/**
 * Performs basic validation on the first page of the Model Builder.
 * Since we don't have a fully formed Ludwig config and the user
 * can take actions that the schema would consider valid (no input_features),
 * we use this simple function to communicate meaningful feedback to the user.
 * @param fields The list of fields in the dataset.
 * @param config The Ludwig config object.
 * @returns
 */
const validateModelConfig = (fields: CreateModelField[], config?: CreateModelConfig) => {
    const errors = [];
    const promptTemplateFields = getFieldsInPromptTemplate(fields, config);

    if (!fields || fields.length === 0) {
        errors.push("No fields found in model.");
        return errors;
    }

    if (config?.input_features === undefined || config?.input_features?.length === 0) {
        errors.push("Model input required.");
    }
    if (config?.output_features?.length === 0) {
        // Create a nice error message to help customers figure out how to proceed.
        if (fields.some((field) => field.mode === "output" && field.excluded)) {
            errors.push("Model target(s) must be active.");
        } else {
            errors.push("Model target required.");
        }
    }

    if (
        config?.model_type === ModelTypes.LARGE_LANGUAGE_MODEL &&
        promptTemplateFields &&
        promptTemplateFields.length === 0
    ) {
        errors.push("At least one column from the dataset is required in the Prompt Template field.");
    }

    return errors;
};

const validateCombinerConfig = (config?: CreateModelConfig) => {
    const errors: string[] = [];
    const inputFeaturesLength = config?.input_features?.length || 0;
    const entity1Selections = config?.combiner?.entity_1?.length || 0;
    const entity2Selections = config?.combiner?.entity_2?.length || 0;

    // We don't want to overload the user with errors here, so we will trigger each error individually.

    // This error will be shown by default with the comparator combiner.
    if (
        config?.combiner?.type === "comparator" &&
        (config?.combiner?.entity_1 === undefined || config?.combiner?.entity_2 === undefined)
    ) {
        errors.push(
            "Comparator combiner requires `entity_1` and `entity_2` to each have at least one feature selected.",
        );
        return errors;
    }

    // This error will happen once the user selects features for both entities, but hasn't selected all available features.
    if (config?.combiner?.type === "comparator" && entity1Selections + entity2Selections !== inputFeaturesLength) {
        errors.push("Comparator combiner requires all features to be selected by either `entity_1` or `entity_2`.");
        return errors;
    }

    // No errors present.
    return errors;
};

/**
 * Applies a template to the Model Config.
 * @param template the template to apply.
 * @param config the Ludwig config object.
 */
const applyTemplate = (template: string, config?: CreateModelConfig) => {
    let baseConfig = {} as CreateModelConfig;
    switch (template) {
        default:
            return config;
        case LLMTemplates.QLORA_4BIT:
            baseConfig = qlora_4bit;
            break;
        case LLMTemplates.QLORA_8BIT:
            baseConfig = qlora_8bit;
            break;
        case LLMTemplates.LORA_BF16:
            baseConfig = lora_bf16;
            break;
    }

    return {
        ...baseConfig,
        base_model: config?.base_model ?? baseConfig?.base_model,
        prompt: config?.prompt,
        output_features: config?.output_features?.map((feature) => ({
            ...feature,
            preprocessing: { max_sequence_length: null },
        })),
    } as CreateModelConfig;
};

/**
 * Update the state of the fields to match the user's Ludwig config object.
 * For example, if a user removes a feature in the config, it should be
 * excluded in the list of fields.
 * @param config The Ludwig config object.
 * @param fields The list of fields in the dataset.
 * @returns The list of fields.
 */
const updateFields = (config?: CreateModelConfig, fields?: CreateModelField[]) => {
    if (!Array.isArray(fields)) {
        return [];
    }

    if (!config) {
        return fields.map((field) => ({
            ...field,
            excluded: true,
        }));
    }

    let field;
    const configMap: { [index: string]: any } = {};
    const modeMap: { [index: string]: any } = {};
    // Need to be able to handle these items not being an array
    // when a user is editing in the advanced editor:
    const inputFeatures = _.get(config, "input_features", []);
    const outputFeatures = _.get(config, "output_features", []);

    // When a use makes changes in the advanced editor
    // they can create config states that aren't completely
    // valid.
    if (!_.isEmpty(inputFeatures) && Array.isArray(inputFeatures)) {
        for (field of inputFeatures) {
            if (!_.has(field, "name")) continue;

            configMap[field.name] = field;
            modeMap[field.name] = "input";
        }
    }

    // When a use makes changes in the advanced editor
    // they can create config states that aren't completely
    // valid.
    if (!_.isEmpty(outputFeatures) && Array.isArray(outputFeatures)) {
        for (field of outputFeatures) {
            if (!_.has(field, "name")) continue;

            configMap[field.name] = field;
            modeMap[field.name] = "output";
        }
    }

    return fields.map((field) => {
        let newField = { ...field };
        if (configMap[field.name] === undefined) {
            newField.excluded = true;
            return newField;
        }

        newField.config = { ...configMap[field.name] };
        let mode = modeMap[field.name];
        if (mode) {
            newField.mode = mode;
            newField.excluded = false;
        } else {
            newField.excluded = true;
        }

        return newField;
    });
};

const setHyperoptDefaultGoalMetricOutput = (output_features?: CreateModelIOFeature[]) => {
    const output_feature = output_features?.length === 1 ? output_features[0]?.name : "combined";

    // Set minimize loss as the default goal and metric and the output feature
    return {
        goal: "minimize",
        metric: "loss",
        output_feature,
    };
};

const generateBasicConfig = (config: CreateModelConfig) => {
    // Remove any AutoML feature parameters, such as bert encoder:
    let inputFeatures = [] as CreateModelIOFeature[];
    if (Array.isArray(config.input_features)) {
        inputFeatures = config.input_features?.map((feature) => ({
            name: feature.name,
            column: feature.column,
            type: feature.type,
        }));
    }

    let outputFeatures = [] as CreateModelIOFeature[];
    if (Array.isArray(config.output_features)) {
        outputFeatures = config.output_features.map((feature) => ({
            name: feature.name,
            column: feature.column,
            type: feature.type,
        }));
    }

    return {
        input_features: inputFeatures,
        output_features: outputFeatures,
    };
};

const toggleHyperopt = (
    config?: CreateModelConfig,
    hyperoptConfig?: CreateModelHyperoptProps,
    defaultHyperoptConfig?: CreateModelHyperoptProps,
) => {
    if (!config) return {};

    if (checkIfHyperoptEnabled(config)) {
        const { hyperopt, ...restOfConfig } = config;
        return {
            config: restOfConfig,
            hyperoptConfig: hyperopt,
        };
    }

    const updatedConfig = _.cloneDeep(config) as CreateModelConfig;

    /**
     * User has previously used Hyperopt.
     * Restore to their previous settings.
     */
    if (hyperoptConfig) {
        updatedConfig.hyperopt = _.cloneDeep(hyperoptConfig);
        return { config: updatedConfig, hyperoptConfig: undefined };
    }

    /**
     * User has not used Hyperopt on this model.
     * Create a reasonable config based on their selected default.
     */
    updatedConfig.hyperopt = _.cloneDeep(defaultHyperoptConfig);

    // TODO(travis): move this back into the backend as a separate field in the response like:
    // "defaultHyperConfig" or similar. Note that this should probably be tailored to the features
    // and model type, though luckily learning rate applies to both ECD and GBMs.
    if (updatedConfig.hyperopt === undefined) {
        updatedConfig.hyperopt = {
            parameters: {
                "trainer.learning_rate": {
                    space: "choice",
                    categories: [0.005, 0.01, 0.02, 0.025],
                },
            },
        };
    }

    updatedConfig.hyperopt = {
        ...updatedConfig.hyperopt,
        ...setHyperoptDefaultGoalMetricOutput(updatedConfig?.output_features),
    };

    return { config: updatedConfig, hyperoptConfig: undefined };
};

/**
 * Remove parameters from a config that are not supported for a given model type. This is used
 * when a user switches between model types (i.e. GBM to LLM) and needs to have the config updated to match
 * the new model's default config state.
 * @param config The Ludwig config object.
 * @param modelType The type of the model from the `ModelTypes` enum.
 * @returns Config updated to the new model type's default config state.
 */
const removeUnsupportedConfigParameters = (config?: CreateModelConfig, modelType?: ModelTypes) => {
    if (!config) return config;

    let inputFeatures: string[] = [];
    let outputFeatures: string[] = [];

    if (modelType === ModelTypes.DECISION_TREE) {
        inputFeatures = GBMInputFeatures;
        outputFeatures = GBMOutputFeatures;
    } else if (modelType === ModelTypes.LARGE_LANGUAGE_MODEL) {
        inputFeatures = LLMInputFeatures;
        outputFeatures = LLMOutputFeatures;
    } else if (modelType === ModelTypes.NEURAL_NETWORK) {
        inputFeatures = ECDInputFeatures;
        outputFeatures = ECDOutputFeatures;
    }

    const updatedConfig = _.cloneDeep(config);
    // Filter input features to supported types
    updatedConfig.input_features = updatedConfig?.input_features?.filter((input) => inputFeatures.includes(input.type));

    // Filter output features to supported types and pick first one
    let filteredOutputFeatures = updatedConfig?.output_features?.filter((output) =>
        outputFeatures.includes(output.type),
    );
    if (modelType === ModelTypes.LARGE_LANGUAGE_MODEL) {
        updatedConfig.output_features = filteredOutputFeatures;
    } else {
        updatedConfig.output_features = filteredOutputFeatures?.length > 0 ? [filteredOutputFeatures[0]] : [];
    }

    // Loop through Features to remove Encoders and Decoders
    updatedConfig.input_features = updatedConfig.input_features?.map((inputFeature) => {
        const { encoder, ...restOfInputFeature } = inputFeature;
        return restOfInputFeature;
    });

    updatedConfig.output_features = updatedConfig.output_features?.map((outputFeature) => {
        const { decoder, ...restOfOutputFeature } = outputFeature;
        return restOfOutputFeature;
    });

    return updatedConfig;
};

/**
 * Converts a Ludwig config from one model type to another.
 * @param config The Ludwig config object.
 * @param modelType The model type to convert to.
 * @returns config Converted config object.
 */
const updateModelType = (config?: CreateModelConfig, modelType?: ModelTypes) => {
    if (!config) return config;

    let updatedConfig = _.cloneDeep(config);

    // Common properties that should be removed:
    // ECDs
    delete updatedConfig.hyperopt;
    delete updatedConfig.combiner;
    delete updatedConfig.trainer;
    // LLMs
    delete updatedConfig.model_name;
    delete updatedConfig.generation;
    delete updatedConfig.prompt;
    delete updatedConfig.adapter;

    // Return to Neural Network
    if (modelType === ModelTypes.NEURAL_NETWORK) {
        updatedConfig.model_type = ModelTypes.NEURAL_NETWORK;

        return removeUnsupportedConfigParameters(updatedConfig, ModelTypes.NEURAL_NETWORK);
    }

    // Convert to Decision Tree Model
    if (modelType === ModelTypes.DECISION_TREE) {
        updatedConfig.model_type = ModelTypes.DECISION_TREE;

        delete updatedConfig.defaults;

        return removeUnsupportedConfigParameters(updatedConfig, ModelTypes.DECISION_TREE);
    }

    // LLM Model
    if (modelType === ModelTypes.LARGE_LANGUAGE_MODEL) {
        updatedConfig.model_type = ModelTypes.LARGE_LANGUAGE_MODEL;

        delete updatedConfig.defaults;

        return removeUnsupportedConfigParameters(updatedConfig, ModelTypes.LARGE_LANGUAGE_MODEL);
    }

    return updatedConfig;
};

const updateSchema = (state: State, config?: CreateModelConfig) => {
    switch (getModelType(config)) {
        case ModelTypes.DECISION_TREE:
            return state.gbmSchema;
        case ModelTypes.LARGE_LANGUAGE_MODEL:
            return state.llmSchema;
        default:
        case ModelTypes.NEURAL_NETWORK:
            return state.ecdSchema;
    }
};

const updateValidator = (state: State, config?: CreateModelConfig) => {
    switch (getModelType(config)) {
        case ModelTypes.DECISION_TREE:
            return state.gbmValidator;
        case ModelTypes.LARGE_LANGUAGE_MODEL:
            return state.llmValidator;
        default:
        case ModelTypes.NEURAL_NETWORK:
            return state.ecdValidator;
    }
};

const updateCombinerEntities = (config: CreateModelConfig) => {
    if (_.get(config, "combiner.type") === "comparator" && config.input_features) {
        config.combiner.entity_1 = config.combiner?.entity_1?.filter((entity: string) =>
            (config.input_features as CreateModelIOFeature[])
                .map((feature: CreateModelIOFeature) => feature.name)
                .includes(entity),
        );

        config.combiner.entity_2 = config.combiner?.entity_2?.filter((entity: string) =>
            (config.input_features as CreateModelIOFeature[])
                .map((feature: CreateModelIOFeature) => feature.name)
                .includes(entity),
        );
    }
};

const validateConfigAgainstSchemaWrapper = (
    featureFlags: FeatureFlags,
    validator?: ValidateFunction,
    config?: CreateModelConfig,
) => {
    const modelErrors = featureFlags["Model Editor - Disable Validation"]
        ? {}
        : validateConfigAgainstSchema(validator, config);

    // Check for entity mismatch errors
    const entityErrors = validateCombinerConfig(config);

    if (entityErrors.length > 0) {
        // @ts-ignore
        modelErrors["combiner"] = {
            name: "comparator combiner",
            configPath: ["combiner.entity_1", "combiner.entity_2"],
            translatedConfigPath: ["combiner.entity_1", "combiner.entity_2"],
            schemaPaths: ["combiner.allOf[0].then.properties.entity_1", "combiner.allOf[0].then.properties.entity_2"],
            errorKeywords: ["entity_mismatch"],
            errorMessages: entityErrors,
        };
    }

    return modelErrors;
};

const getVariablesInTemplate = (template: string | undefined) =>
    template?.match(templateVariableRegex)?.map((variable) => variable.replaceAll(/\{|\}/g, "")) ?? [];

const getFieldsInPromptTemplate = (fields: CreateModelField[], config?: CreateModelConfig) => {
    const promptTemplate = config?.prompt?.template;
    if (typeof promptTemplate !== "string") {
        return [];
    }
    const variables = getVariablesInTemplate(promptTemplate);
    return fields
        .filter((field) => variables.includes(field.name) && field.mode === "input")
        .map((field) => field.name);
};

type State = {
    config?: CreateModelConfig;
    configKey: number;
    validator?: ValidateFunction;
    defaultConfig?: CreateModelConfig;
    schema?: JSONSchema7;
    ecdSchema: any; // wrong type?
    ecdValidator?: ValidateFunction;
    gbmSchema: any; // wrong type?
    gbmValidator?: ValidateFunction;
    llmSchema: any; // wrong type?
    llmValidator?: ValidateFunction;
    fields: CreateModelField[];
    dirtyDefault: boolean;
    selectedDefault: SelectedDefaults;
    llmTemplate: LLMTemplates;
    hyperoptConfig?: CreateModelHyperoptProps;
    hyperoptParameterSchema: HyperoptSearchParameterSchema;
    modelErrors: string[];
    invalidFields: InvalidFields;
    previousConfigs: (CreateModelConfig | undefined)[];
    featureFlags: FeatureFlags;
    promptTemplateFields: string[];
};

type Action =
    | {
          type: "INIT";
          config?: CreateModelConfig;
          defaultConfig?: CreateModelConfig;
          fields?: CreateModelField[];
          featureFlags: FeatureFlags;
      }
    | { type: "USE_SUGGESTED_CONFIG"; suggestedConfig?: CreateModelConfig }
    | { type: "USE_DEFAULT_CONFIG" }
    | { type: "APPLY_TEMPLATE"; template: LLMTemplates }
    | { type: "UPDATE_CONFIG"; config?: CreateModelConfig; isDirty?: boolean }
    | { type: "UPDATE_CONFIG_YAML" }
    | { type: "UPDATE_CONFIG_PROPERTY"; field: string; value: string | number | boolean | HyperoptParameter }
    | { type: "REMOVE_CONFIG_PROPERTY"; field: string }
    | { type: "TOGGLE_HYPEROPT" }
    | { type: "UPDATE_MODEL_TYPE"; modelType: ModelTypes }
    | { type: "UPDATE_FIELD"; index: number; value: string | number | boolean | CreateModelField }
    | { type: "UPDATE_FIELDS"; checked: boolean }
    | { type: "UPDATE_FEATURE_FLAGS"; featureFlags: FeatureFlags }
    | { type: "UNDO" };

export const initialState = {
    config: qlora_4bit,
    configKey: 0,
    defaultConfig: undefined,
    schema: undefined,
    validator: undefined,
    ecdSchema,
    ecdValidator: undefined,
    gbmSchema,
    gbmValidator: undefined,
    llmSchema,
    llmValidator: undefined,
    fields: [],
    dirtyDefault: false,
    selectedDefault: SelectedDefaults.BASIC,
    llmTemplate: LLMTemplates.QLORA_4BIT,
    hyperoptConfig: undefined,
    hyperoptParameterSchema,
    modelErrors: [],
    invalidFields: {},
    previousConfigs: [],
    featureFlags: {},
    promptTemplateFields: [],
};

export const reducer = (state: State, action: Action): State => {
    let schema: JSONSchema7;
    let validator: any;
    let config: CreateModelConfig | undefined;
    let fields;
    let invalidFields = {};
    let inputFeatures: Array<CreateModelIOFeature> = [];
    let outputFeatures: Array<CreateModelIOFeature> = [];

    switch (action.type) {
        // INIT is only used for seeding the model state with the result of the
        // Config Detect API call. If you need to load a custom model config,
        // use the UPDATE_CONFIG action after using the INIT action to set
        // your custom config.
        case "INIT":
            // Use default LLM config if no config is provided
            config = action.config;
            if (!config) {
                config = _.cloneDeep(qlora_4bit);
                config.output_features = [];
            }

            /**
             * Compile the schema to validation functions using AJV.
             * This is expensive (~2s on the client) and the schema
             * is the same for every single model. Compile it one time
             * and save it.
             */
            let ecdValidator = state.ecdValidator;
            let gbmValidator = state.gbmValidator;
            let llmValidator = state.llmValidator;
            if (typeof ecdValidator !== "function") {
                ecdValidator = compileJSONSchema(state.ecdSchema);
                gbmValidator = compileJSONSchema(state.gbmSchema);
                llmValidator = compileJSONSchema(state.llmSchema);
            }

            /**
             * Validate using the initial config.
             */
            schema = updateSchema(state, config);
            // @ts-expect-error
            validator = updateValidator({ ecdValidator, gbmValidator, llmValidator }, config);
            invalidFields = validateConfigAgainstSchemaWrapper(action.featureFlags, validator, config);

            /**
             * Make sure the fields matches the Ludwig config.
             */
            fields = updateFields(config, action.fields);

            return {
                ...state,
                schema,
                validator,
                ecdValidator,
                gbmValidator,
                llmValidator,
                config,
                fields,
                dirtyDefault: false,
                defaultConfig: _.cloneDeep(action.defaultConfig), // This is the AutoML suggested config by Ludwig
                hyperoptConfig: undefined, // This only exists to restore previous state when a user toggles hyperopt.
                modelErrors: validateModelConfig(fields, config),
                invalidFields,
                featureFlags: action.featureFlags,
                promptTemplateFields: getFieldsInPromptTemplate(fields, config),
            };
        case "USE_SUGGESTED_CONFIG":
            if (!Boolean(action.suggestedConfig)) {
                return state;
            }

            // Reset existing validation errors since we're switching to a new (known valid) config.
            invalidFields = {};

            return {
                ...state,
                configKey: state.configKey + 1,
                config: action.suggestedConfig,
                selectedDefault: SelectedDefaults.AUTOML,
                invalidFields,
                dirtyDefault: false,

                previousConfigs: [...state.previousConfigs, ...[state.config]],
            };
        case "USE_DEFAULT_CONFIG":
            if (!state.config) {
                return state;
            }

            let basicConfig = generateBasicConfig(state.config);

            // Reset existing validation errors since we're switching to a new (known valid) config.
            invalidFields = {};

            return {
                ...state,
                config: basicConfig,
                configKey: state.configKey + 1,
                selectedDefault: SelectedDefaults.BASIC,
                dirtyDefault: false,
                invalidFields,
                previousConfigs: [...state.previousConfigs, ...[state.config]],
            };
        // Apply template to config
        case "APPLY_TEMPLATE":
            config = applyTemplate(action.template, state.config);
            schema = updateSchema(state, config);
            validator = updateValidator(state, config);
            invalidFields = validateConfigAgainstSchemaWrapper(state.featureFlags, validator, config);
            fields = updateFields(config, state.fields);

            return {
                ...state,
                config,
                schema,
                validator,
                invalidFields,
                fields,
                modelErrors: validateModelConfig(fields, config),
                dirtyDefault: false,
                promptTemplateFields: getFieldsInPromptTemplate(fields, config),
                llmTemplate: action.template,
            };
        // This is used by the Advanced Editor:
        case "UPDATE_CONFIG":
            config = action.config;
            schema = updateSchema(state, config);
            validator = updateValidator(state, config);
            invalidFields = validateConfigAgainstSchemaWrapper(state.featureFlags, validator, config);
            fields = updateFields(config, state.fields);

            return {
                ...state,
                schema,
                validator,
                config,
                fields,
                dirtyDefault: action.isDirty ?? true,
                modelErrors: validateModelConfig(fields, config),
                invalidFields,
                promptTemplateFields: getFieldsInPromptTemplate(fields, config),
            };
        // This is just for the Advanced Editor to be able to show an invalid YAML error message.
        case "UPDATE_CONFIG_YAML":
            // @ts-expect-error
            invalidFields["advancedConfig: navigate to advanced editor to reset or fix"] = {};

            return { ...state, invalidFields };
        case "UPDATE_CONFIG_PROPERTY":
            config = _.cloneDeep(state.config ? state.config : {}) as CreateModelConfig;
            if (action.value === "" || action.value === undefined) {
                _.unset(config, action.field);
                removeEmptyConfigProperties(action.field, config);
            } else {
                _.set(config, action.field, action.value);
            }

            // @ts-expect-error
            config = setSiblingTypeProperty(action.field, config, state.schema);

            schema = updateSchema(state, config);
            validator = updateValidator(state, config);
            invalidFields = validateConfigAgainstSchemaWrapper(state.featureFlags, validator, config);

            fields = state.fields;
            if (action.field.includes("input_features") || action.field.includes("output_features")) {
                fields = updateFields(config as CreateModelConfig, state.fields);
            }

            // @TODO: if a feature type is changed, the encoder or decoder should be wiped out
            // and changed to the default.

            // Special validation for Hyperopt Parameters
            // if (action.field.indexOf("hyperopt.parameters") > -1) {
            //     const parameter = action.value as HyperoptParameter;
            //     // @ts-ignore
            //     Object.keys(parameter).forEach((configItem:keyof HyperoptParameter) => {
            //         if (configItem === 'space') {
            //             return;
            //         }

            //         const defaultValue = ['categories', 'values'].includes(configItem) ? [parameter.default] : parameter.default;
            //         validateConfig(
            //             convertAtomicToNumber(parameter[configItem]),
            //             defaultValue,
            //             markInvalidField,
            //             null,
            //             `hyperopt.parameters.${action.field}.${configItem}`
            //         );
            //     });
            // }

            if (isLLMModel(config)) {
                // HACK(Arnav) Check if base model name is Mixtral or Mixtral Instruct. If so, set the adapter's
                // target_modules to q_proj and v_proj. This is a hack to get around the fact that the Mixtral models
                // don't have a default PEFT mapping for LoRA target_modules. This should be removed once the Mixtral
                // models have a default PEFT mapping for LoRA target_modules. See the following Linear issue
                // (MLX-1679) for details: https://linear.app/predibase/issue/MLX-1679/remove-peftlora-mixtral-hack-in-the-predibase-client
                if (
                    config?.base_model === "mistralai/Mixtral-8x7B-v0.1" ||
                    config?.base_model === "mistralai/Mixtral-8x7B-Instruct-v0.1"
                ) {
                    _.set(config, "adapter.target_modules", ["q_proj", "v_proj"]);
                } else {
                    // Delete the target_modules property if it exists
                    if (config?.adapter?.target_modules) {
                        delete config.adapter.target_modules;
                    }
                }
            }

            let isDirty = true;
            if (isLLMModel(config) && (action.field === "prompt.template" || action.field === "base_model")) {
                isDirty = false;
            }

            return {
                ...state,
                schema,
                validator,
                config,
                fields,
                dirtyDefault: isDirty,
                modelErrors: validateModelConfig(fields, config),
                invalidFields,
                promptTemplateFields: getFieldsInPromptTemplate(fields, config),
            };
        case "REMOVE_CONFIG_PROPERTY":
            config = _.cloneDeep(state.config) as CreateModelConfig;
            _.unset(config, action.field);
            removeEmptyConfigProperties(action.field, config);

            schema = updateSchema(state, config);
            validator = updateValidator(state, config);
            invalidFields = validateConfigAgainstSchemaWrapper(state.featureFlags, validator, config);

            fields = state.fields;
            if (action.field.includes("input_features") || action.field.includes("output_features")) {
                fields = updateFields(config as CreateModelConfig, state.fields);
            }

            return {
                ...state,
                schema,
                validator,
                config,
                fields,
                dirtyDefault: true,
                modelErrors: validateModelConfig(fields, config),
                invalidFields,
                promptTemplateFields: getFieldsInPromptTemplate(fields, config),
            };
        case "TOGGLE_HYPEROPT":
            const updatedState = toggleHyperopt(state.config, state?.hyperoptConfig, state.defaultConfig?.hyperopt);
            invalidFields = validateConfigAgainstSchemaWrapper(
                state.featureFlags,
                state.validator,
                updatedState?.config,
            );

            return {
                ...state,
                ...updatedState,
                dirtyDefault: true,
                modelErrors: validateModelConfig(state.fields, updatedState?.config),
                invalidFields,
            };
        case "UPDATE_MODEL_TYPE":
            config = updateModelType(state.config, action.modelType) as CreateModelConfig;
            schema = updateSchema(state, config);
            validator = updateValidator(state, config);
            invalidFields = validateConfigAgainstSchemaWrapper(state.featureFlags, validator, config);
            const isModelECD = isECDModel(config);
            const isModelTree = isGBMModel(config);

            fields = state.fields;
            if (isModelTree || isModelECD) {
                fields = fields.map((field) => ({
                    ...field,
                    // If the field is a split field; continue to use it as a split.
                    // For output features, pick the first one and then make everything else an input.
                    // At most 1 output feature will be chosen.
                    // If there are no supported output features, for example if all output features have an unsupported
                    // type, then config.output_features is empty, and no fields will have mode=="output".
                    mode:
                        field.mode === "split"
                            ? "split"
                            : field.mode === "output" && config?.output_features[0]?.name === field.name
                              ? "output"
                              : "input",
                }));
            }

            fields = updateFields(config as CreateModelConfig, fields);

            return {
                ...state,
                schema,
                validator,
                config,
                fields,
                dirtyDefault: false,
                modelErrors: validateModelConfig(fields, config),
                invalidFields,
                promptTemplateFields: getFieldsInPromptTemplate(fields, config),
            };
        case "UPDATE_FIELD":
            fields = _.cloneDeep(state.fields);
            const updatedField = action.value as CreateModelField;
            _.set(fields, action.index, updatedField);
            config = _.cloneDeep(state.config ? state.config : ({} as CreateModelConfig));

            // Only one output feature for Tree Models
            if (isGBMModel(config)) {
                // In some scenarios, such as when a user selects an excluded field as
                // their output field, we need to set all fields but one to input.
                fields = fields.map((field) => ({
                    ...field,
                    mode:
                        field.mode === "split"
                            ? "split"
                            : field.mode === "output" && field.name === updatedField.name
                              ? "output"
                              : "input",
                }));
                // Now, we can roll through the fields updating the Ludwig config object:
                fields.forEach((field, index) => {
                    if (field.excluded) {
                        return;
                    }

                    if (field.mode === "input") {
                        inputFeatures.push(field.config as CreateModelIOFeature);
                    } else if (field.mode === "output") {
                        // There can only be 1 output feature in a Tree.
                        // If the field being updated is now the output feature,
                        // the existing output feature should become an input feature:
                        if (
                            updatedField.mode === "input" ||
                            (updatedField.mode === "output" && updatedField.name === field.name)
                        ) {
                            outputFeatures.push(field.config as CreateModelIOFeature);
                        } else {
                            inputFeatures.push(field.config as CreateModelIOFeature);
                        }
                    }
                });
                // Multiple output features for Neural Networks and LLMs
            } else {
                fields.forEach((field) => {
                    if (field.excluded) {
                        return;
                    }

                    if (field.mode === "input") {
                        inputFeatures.push(field.config as CreateModelIOFeature);
                    } else if (field.mode === "output") {
                        outputFeatures.push(field.config as CreateModelIOFeature);
                    }
                });
            }

            config.input_features = inputFeatures;
            config.output_features = outputFeatures;

            // Update fields to match new config state:
            fields = updateFields(config as CreateModelConfig, fields);

            // Update combiner entities to match available fields
            updateCombinerEntities(config);

            invalidFields = validateConfigAgainstSchemaWrapper(state.featureFlags, state.validator, config);

            return {
                ...state,
                config,
                fields,
                modelErrors: validateModelConfig(fields, config),
                invalidFields,
                promptTemplateFields: getFieldsInPromptTemplate(fields, config),
            };
        case "UPDATE_FIELDS":
            fields = _.cloneDeep(state.fields);
            config = _.cloneDeep(state.config ? state.config : ({} as CreateModelConfig));

            // Toggle all input fields
            fields.forEach((field) => {
                if (field.mode === "input") {
                    field.excluded = !action.checked;
                }
            });

            if (action.checked) {
                const inputs = fields.map((field) => field.mode === "input");
                inputFeatures = fields
                    .map((field) => field.config as CreateModelIOFeature)
                    .filter((input, i) => inputs[i]);
            }

            config.input_features = inputFeatures;

            // Update combiner entities to match available fields
            updateCombinerEntities(config);

            invalidFields = validateConfigAgainstSchemaWrapper(state.featureFlags, state.validator, config);

            return {
                ...state,
                config,
                fields,
                modelErrors: validateModelConfig(fields, config),
                invalidFields,
                promptTemplateFields: getFieldsInPromptTemplate(fields, config),
            };
        case "UPDATE_FEATURE_FLAGS":
            invalidFields = validateConfigAgainstSchemaWrapper(action.featureFlags, state.validator, state.config);

            return {
                ...state,
                invalidFields,
                featureFlags: action.featureFlags,
            };
        case "UNDO":
            if (state.previousConfigs.length === 0) {
                return state;
            }

            invalidFields = validateConfigAgainstSchemaWrapper(
                state.featureFlags,
                state.validator,
                state.previousConfigs[0],
            );
            return {
                ...state,
                config: state.previousConfigs[0],
                previousConfigs: state.previousConfigs.slice(1),
                invalidFields,
            };
        default:
            return state;
    }
};

const useValue = () => useReducer(reducer, initialState);

const { Provider, useTrackedState, useUpdate } = createContainer(useValue);

const useIsHyperoptEnabled = () => {
    const { config } = useTrackedState();
    return checkIfHyperoptEnabled(config);
};

const useIsDecisionTree = () => {
    const { config } = useTrackedState();
    return isGBMModel(config);
};

const useIsLLM = () => {
    const { config } = useTrackedState();
    return isLLMModel(config);
};

const useModelConversionLoss = (modelType: ModelTypes) => {
    const { config } = useTrackedState();
    const convertedConfig = removeUnsupportedConfigParameters(config, modelType);
    const missingInputFeatures = config?.input_features?.filter(
        (oldFeature) => !convertedConfig?.input_features?.some((newFeature) => newFeature.name === oldFeature.name),
    );
    const missingOutputFeatures = config?.output_features?.filter(
        (oldFeature) => !convertedConfig?.output_features.some((newFeature) => newFeature.name === oldFeature.name),
    );
    return {
        isMissingFields: missingInputFeatures?.length || missingOutputFeatures?.length,
        missingInputFeatures,
        missingOutputFeatures,
    };
};

export {
    Provider as ConfigProvider,
    useTrackedState as useConfigState,
    useUpdate as useDispatch,
    useIsDecisionTree,
    useIsHyperoptEnabled,
    useIsLLM,
    useModelConversionLoss,
};
