/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum finetuningJobStatus {
    PENDING = 'pending',
    QUEUED = 'queued',
    TRAINING = 'training',
    STOPPING = 'stopping',
    STOPPED = 'stopped',
    CANCELED = 'canceled',
    COMPLETED = 'completed',
    ERRORED = 'errored',
}
