import React from "react";
import { Link } from "react-router-dom";
import { Divider, Header, Table } from "semantic-ui-react";
import ConnectionImagePopup from "../../components/ConnectionImagePopup";
import LiveTimeAgo from "../../components/LiveTimeAgo";
import NoResultsTableRow from "../../components/NoResultsTableRow";
import ShowMoreTable from "../../components/ShowMoreTable";
import { getStatusChipForModelsAndQueries } from "../../components/statusChip";
import metrics from "../../metrics/metrics";
import { ModelStatus } from "../../types/model/modelStatus";
import { SEMANTIC_GREY } from "../../utils/colors";
import { deletedUser } from "../../utils/constants";
import { cssTruncate } from "../../utils/overflow";
import { getLatestModel, ModelContributors } from "../util";
import CreateModelRepoModal from "./CreateModelRepoModal";

const getLatestModelDesc = (latestModel: Model) => {
    const chip = getStatusChipForModelsAndQueries(latestModel.status as ModelStatus, `${120 / 14}rem`);
    return (
        <Table basic={"very"} compact className={"no-hover default-padding"}>
            <Table.Body>
                <Table.Row>
                    <Table.Cell collapsing>{chip}</Table.Cell>
                    <Table.Cell style={{ paddingLeft: 0 }}>
                        <span style={{ ...cssTruncate, display: "block", maxWidth: "200px" }}>
                            <Link to={`/models/version/${latestModel.id}`} className={"blue-link"}>
                                #{latestModel.repoVersion}
                                {latestModel.description ? (
                                    <>
                                        &ensp;
                                        <span className={"blue-link"} style={{ color: SEMANTIC_GREY }}>
                                            {latestModel.description}
                                        </span>
                                    </>
                                ) : null}
                            </Link>
                        </span>
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    );
};

const getDatasets = (modelRepo: ModelRepo) => {
    const datasets = new Map<number, Dataset>();
    for (const model of modelRepo.models || []) {
        if (!datasets.has(model.datasetID) && model?.dataset !== undefined) {
            datasets.set(model.datasetID, model.dataset);
        }
    }

    const RowComponent = (dataset: Dataset | null) => {
        if (dataset === null) {
            return null;
        }

        return (
            <Table.Row key={dataset.id}>
                <Table.Cell collapsing style={{ paddingRight: 0, borderTop: "none" }}>
                    <ConnectionImagePopup
                        connectionType={dataset.connection.type}
                        style={{ marginRight: 0, width: "1.5em", height: "1.5em" }}
                    />
                </Table.Cell>
                <Table.Cell style={{ borderLeft: "none", paddingLeft: 5, borderTop: "none" }}>
                    <Link
                        className={metrics.BLOCK_AUTO_CAPTURE + " black-link"}
                        style={{ ...cssTruncate, verticalAlign: "middle", maxWidth: "300px" }}
                        to={`/data/datasets/${dataset!.id}`}
                    >
                        {dataset!.name}
                    </Link>
                </Table.Cell>
            </Table.Row>
        );
    };
    const datasetsArr = Array.from(datasets.values()).sort((a, b) =>
        a.name.localeCompare(b.name, undefined, { sensitivity: "base" }),
    );

    return (
        <ShowMoreTable
            data={datasetsArr}
            limit={2}
            cols={2}
            RowComponent={RowComponent}
            style={{ paddingTop: "16px", paddingBottom: "16px" }}
        />
    );
};

const ModelsTableRow = (repo: ModelRepo, i: number) => {
    const updated = <LiveTimeAgo fromDate={new Date(repo.updated)} />;
    const latestModel = getLatestModel(repo.models);
    return (
        <Table.Row key={repo.id}>
            <Table.Cell style={{ maxWidth: "30vw", wordBreak: "break-all" }}>
                <Header
                    as={"h2"}
                    size={"small"}
                    className={"blue-link"}
                    style={{ margin: 0, whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}
                >
                    <Link
                        className={`${metrics.BLOCK_AUTO_CAPTURE} blue-link`}
                        onClick={() => {
                            metrics.capture("Model.ModelView.click", {
                                modelID: repo.id,
                                modelName: repo.modelName,
                            });
                        }}
                        to={"/models/repo/" + repo.id}
                    >
                        {repo.modelName}
                    </Link>
                </Header>
                <span style={{ color: SEMANTIC_GREY }}>{repo.description}</span>
            </Table.Cell>
            <Table.Cell style={{ paddingTop: 5, paddingBottom: 5 }}>{getDatasets(repo)}</Table.Cell>
            <Table.Cell>{ModelContributors(repo.models || [])}</Table.Cell>
            <Table.Cell>{latestModel ? getLatestModelDesc(latestModel) : null}</Table.Cell>
            <Table.Cell style={{ whiteSpace: "nowrap" }}>
                {latestModel ? (
                    <>
                        {updated}
                        <br />
                        <span style={{ color: SEMANTIC_GREY, fontSize: "0.9em" }}>
                            {latestModel.user?.username || deletedUser}
                        </span>
                    </>
                ) : (
                    updated
                )}
            </Table.Cell>
        </Table.Row>
    );
};

function ModelRepoTableBase(props: { searchbar: React.ReactNode; models?: ModelRepo[]; empty: boolean }) {
    if (!Array.isArray(props?.models)) {
        return null;
    }
    return (
        <Table selectable={props.models.length > 0} className={"padding-sixteen"}>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Name&emsp;{props.searchbar}</Table.HeaderCell>
                    <Table.HeaderCell>Dataset(s)</Table.HeaderCell>
                    <Table.HeaderCell>
                        Contributor(s){" "}
                        <span
                            style={{
                                color: SEMANTIC_GREY,
                                fontWeight: "normal",
                            }}
                        >
                            (# models)
                        </span>
                    </Table.HeaderCell>
                    <Table.HeaderCell>Latest version</Table.HeaderCell>
                    <Table.HeaderCell collapsing>Last Modified</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {!props.empty && props.models.length === 0 ? <NoResultsTableRow colSpan={8} /> : null}
                {!props.empty ? (
                    props.models.map((model: ModelRepo, i: number) => ModelsTableRow(model, i))
                ) : (
                    <Table.Row>
                        <Table.Cell colSpan={7} textAlign={"center"} verticalAlign={"middle"}>
                            <Divider hidden />
                            <img src={"/model/emptyRepos.svg"} alt="" />
                            <Header as="h2" size={"medium"} style={{ marginBottom: "0.5rem" }}>
                                Looks like you don't have any models yet!
                            </Header>
                            <CreateModelRepoModal
                                trigger={
                                    <button className="button-reset" style={{ fontSize: "0.9em" }}>
                                        Create a model repository
                                    </button>
                                }
                            />
                            <span style={{ fontSize: "0.9em" }}> to begin training your first model.</span>
                            <Divider hidden />
                        </Table.Cell>
                    </Table.Row>
                )}
            </Table.Body>
        </Table>
    );
}

export default ModelRepoTableBase;
