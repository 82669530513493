import { getTraceId } from "../api/trace";
import { Auth0TokenOptions } from "../data";
import metrics from "../metrics/metrics";
import { MetronomeDashboardType } from "../types/billing";
import { createV1APIServer, createV2APIServer, redirectIfSessionInvalid } from "../utils/api";
import { getErrorMessage } from "../utils/errors";

// TODO: resp needs a type...
export const getMetronomeDashboard = async (type: MetronomeDashboardType, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `/tenants/dashboard?type=${type}`;
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .get(endpoint)
        .then((res) => {
            return res.data.url as string;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const createApiToken = async (auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = "users/token/create";
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .post(endpoint)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "POST",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const getIntegrationToken = async (integration: string, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `integrations/${integration}/token`;
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return v2APIServer
        .get(endpoint)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const setIntegrationToken = async (
    integration: string,
    token: string,
    auth0TokenOptions?: Auth0TokenOptions,
) => {
    const endpoint = `integrations/${integration}/token`;
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return v2APIServer
        .post(endpoint, { token })
        .then(() => {})
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "POST",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const deleteIntegrationToken = async (integration: string, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `integrations/${integration}/token`;
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return v2APIServer
        .delete(endpoint)
        .then(() => {})
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "DELETE",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const deleteAccount = async (id: string, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `/users/${id}`;
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .delete(endpoint)
        .then(() => {})
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "DELETE",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const deleteAccountV2 = async (id: string, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `/users/${id}`;
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return v2APIServer
        .delete(endpoint)
        .then(() => {})
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "DELETE",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const deprovisionAccount = async (envShortcode: string, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `/environments/${envShortcode}/deprovision`;
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .post(endpoint)
        .then(() => {
            window.location.href = "/";
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "POST",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};
