import type { JSONSchema7 } from "json-schema";
import _ from "lodash";
import { getFieldState, handleLocalState } from "../../../../models/create/forms/utils";
import { CurrentUser, UserContext } from "../../../../types/user";
import { snakeToTitle } from "../../../../utils/config";
import { ColumnSelector } from "../custom-components";
import { getAlternativeTitle, getErrorPath, getLudwigPath, hiddenNames } from "../utils";
import { ArrayField } from "./ArrayField";
import { BooleanField } from "./BooleanField";
import { StringField } from "./StringField";

export const Field = (props: {
    fieldType?: string;
    schema: JSONSchema7;
    featureIndex?: number;
    config: any;
    setConfig: (parameterPath: string, parameterValue: any) => void;
    localState: any;
    setLocalState: (parameterPath: string, parameterValue: any) => void;
    invalidFields: any;
    path: string;
    schemaPath: string;
    hideTitle?: boolean;
    readOnly?: boolean;
    userContext?: UserContext | CurrentUser;
}) => {
    const { fieldType, schema, featureIndex, config, setConfig, localState, setLocalState, invalidFields, schemaPath } =
        props;
    // Don't render fields that the UI does not support:
    if (schema.title && hiddenNames.includes(schema?.title)) {
        return null;
    }

    // TODO: Replace with props.path ?
    // @ts-expect-error(2339) yes, parameter_metadata does exist on the schema
    const path = getLudwigPath(schema?.parameter_metadata?.ludwig_path, featureIndex);
    const errorPath = getErrorPath(path, config);
    // TODO: Perhaps we need a more distinct separation between semantic props and markup props, or at least to apply them more carefully. I want to hide
    // the title above a particular field but by setting `hideTitle` I end up hiding the title and wiping out the placeholder text too.
    const title = props.hideTitle
        ? undefined
        : schema.title && typeof schema.title === "string"
          ? snakeToTitle(schema.title)
          : getAlternativeTitle(path);
    const value = getFieldState(path, localState, config);
    const error = _.has(invalidFields, errorPath);
    const componentType = _.get(schema, "parameter_metadata.ui_component_type") ?? fieldType;

    switch (componentType) {
        case "column_selector":
            return (
                <ColumnSelector
                    path={path}
                    title={title}
                    error={error}
                    value={value}
                    description={schema.description}
                    defaultValue={typeof schema.default !== "undefined" ? String(schema.default) : undefined}
                    config={config}
                    setConfig={setConfig}
                    setLocalState={setLocalState}
                    schema={schema}
                    schemaPath={schemaPath}
                    readOnly={props.readOnly}
                />
            );
        case "boolean":
            return (
                <BooleanField
                    path={path}
                    title={title}
                    error={error}
                    value={value}
                    description={schema.description}
                    defaultValue={schema.default}
                    onChange={(checked?: boolean) => handleLocalState(path, checked, setLocalState, setConfig)}
                    schema={schema}
                    readOnly={props.readOnly}
                />
            );
        case "array":
            return (
                <ArrayField
                    path={path}
                    title={title}
                    invalidFields={invalidFields}
                    value={value}
                    description={schema.description}
                    defaultValue={schema.default}
                    config={config}
                    setConfig={setConfig}
                    setLocalState={setLocalState}
                    schema={schema}
                    schemaPath={schemaPath}
                    readOnly={props.readOnly}
                />
            );
        case "string":
        case "number":
        case "integer":
        default:
            return (
                <StringField
                    path={path}
                    title={title}
                    error={error}
                    value={value}
                    description={schema.description}
                    defaultValue={typeof schema.default !== "undefined" ? String(schema.default) : undefined}
                    setConfig={setConfig}
                    setLocalState={setLocalState}
                    schema={schema}
                    schemaPath={schemaPath}
                    readOnly={props.readOnly}
                    userContext={props.userContext}
                    hideTitle={props.hideTitle}
                />
            );
    }
};
