import { memo, MutableRefObject, useRef, useState } from "react";
import { RecoilState, useRecoilState } from "recoil";
import { Input } from "semantic-ui-react";

function SimpleSearch(props: {
    // Deprecated once we migrate QueryView to WebSockets and React Query:
    searchKey?: string;
    scopeUser?: boolean;
    offset?: number;
    debouncedFunction?: (params: any) => void;
    // End deprecation
    searchValRef: MutableRefObject<string>;
    searchValRecoilState: RecoilState<any>;
    setOffset?: React.Dispatch<React.SetStateAction<number>>;
    placeholder: string;
    ignoreRecoilState?: boolean;
}) {
    const [searchKeyRecoil, setSearchKeyRecoil] = useRecoilState<string>(props.searchValRecoilState);
    const [searchKeyLocal, setSearchKeyLocal] = useState("");

    const [loading, setLoading] = useState(false);
    const searchTimer = useRef<NodeJS.Timeout>();

    const onSearchChange = (searchVal: string) => {
        if (searchTimer.current) {
            clearTimeout(searchTimer.current);
        }
        setLoading(true);

        props.searchValRef.current = searchVal;
        props.ignoreRecoilState ? setSearchKeyLocal(searchVal) : setSearchKeyRecoil(searchVal);

        searchTimer.current = setTimeout(() => {
            // TODO: Always use the setOffset once QueryView is refactored
            if (props.setOffset) {
                props.setOffset(0);
            }
            if (props.debouncedFunction !== undefined) {
                const params = { searchKeys: [props.searchKey], searchVals: [searchVal], offset: 0 };
                props.debouncedFunction(params);
            }
            setLoading(false);
        }, 500);
    };

    return (
        <Input
            placeholder={props.placeholder}
            autoFocus
            value={props.ignoreRecoilState ? searchKeyLocal : searchKeyRecoil}
            size={"small"}
            loading={loading}
            icon={"search"}
            onChange={(event, data) => {
                onSearchChange(data.value);
            }}
        />
    );
}

export default memo(SimpleSearch);
