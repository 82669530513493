import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { Button, Container, Divider, Form, Message, Modal } from "semantic-ui-react";
import { Environment, useAuth0TokenOptions } from "../data";
import metrics from "../metrics/metrics";
import { useEnvironmentsQuery } from "../query";
import { EngineServiceType } from "../types/engineServiceType";
import { getErrorMessage } from "../utils/errors";
import { FeatureFlagsContext } from "../utils/feature-flags";
import { createEngine } from "./data";
import EngineNodeTable from "./EngineNodeTable";
import { EngineTooltip } from "./EngineTooltips";
import {
    generateEnvironmentDropdownOptions,
    generateTemplateDropdownOptionsForServiceType,
    getTemplateForSpec,
    onAutoResumeChange,
    onAutoSuspendBlur,
    onAutoSuspendChange,
    onChangeForGenericFormInput,
    onEnvironmentChange,
    onTemplateChange,
} from "./modal-utils";
import { GET_ENGINES_QUERY_KEY, useEngineSchemaQuery } from "./query";

const serviceTypes = [
    { displayName: "General", value: EngineServiceType.RAY },
    { displayName: "Training", value: EngineServiceType.BATCH },
    { displayName: "Serving", value: EngineServiceType.SERVING },
];

const ModalContent = (props: {
    engineSpec: EngineSpec;
    engineSchema: EngineSchema;
    envs: Environment[];
    setEngineSpec: Dispatch<SetStateAction<EngineSpec | undefined | null>>;
    engineServiceType: EngineServiceType;
    setEngineServiceType: React.Dispatch<React.SetStateAction<EngineServiceType>>;
}) => {
    const { engineSchema, envs, engineSpec, setEngineSpec, engineServiceType, setEngineServiceType } = props;

    // Context state:
    const { featureFlags } = useContext(FeatureFlagsContext);

    // Dropdown options:
    const templateDropdownOptions = generateTemplateDropdownOptionsForServiceType(
        engineSchema.templates,
        engineServiceType,
    );
    const envDropdownOptions = generateEnvironmentDropdownOptions(envs);

    // Derived state:
    const selectedTemplate = getTemplateForSpec(engineSchema.templates, engineSpec);

    return (
        <Form>
            <Form.Input
                autoFocus
                name="name"
                label="Name"
                placeholder="Name"
                value={engineSpec?.name || ""}
                onChange={(event) => onChangeForGenericFormInput(event, engineSpec, setEngineSpec)}
            />
            <fieldset>
                <legend style={{ fontSize: ".92857143em", marginBottom: "0.75rem", fontWeight: "700" }}>
                    What will you use this engine for?
                </legend>
                <Container style={{ paddingLeft: "1em" }}>
                    {serviceTypes.map((serviceType) => {
                        return (
                            <Form.Group style={{ marginBottom: "0.75rem" }}>
                                <Form.Radio
                                    label={serviceType.displayName}
                                    value={serviceType.value}
                                    checked={engineServiceType === serviceType.value}
                                    onChange={() => {
                                        setEngineServiceType(serviceType.value);

                                        const templateOptions = engineSchema.templates.filter(
                                            (template) => template.serviceType === serviceType.value,
                                        );
                                        if (templateOptions.length === 0) {
                                            return;
                                        }

                                        const templateID = templateOptions[0].id;
                                        const environmentID = envs.find((env) => env.name === "default")?.id;
                                        if (environmentID) {
                                            const newEngine = {
                                                ...engineSpec,
                                                templateID: templateID,
                                                environmentID: environmentID,
                                            } as EngineSpec;
                                            setEngineSpec(newEngine);
                                        }
                                    }}
                                />
                            </Form.Group>
                        );
                    })}
                    <Divider hidden />
                </Container>
            </fieldset>

            <Form.Group widths="equal">
                <Form.Select
                    name="template"
                    label="Template"
                    options={templateDropdownOptions}
                    value={engineSpec?.templateID}
                    placeholder="Template"
                    onChange={(_, data) => onTemplateChange(data, engineSpec, setEngineSpec)}
                />
            </Form.Group>
            {featureFlags["Engine Environment Show/Hide"] && (
                <Form.Group widths="equal">
                    <Form.Select
                        name="environment"
                        label="Environment"
                        options={envDropdownOptions}
                        value={engineSpec?.environmentID}
                        placeholder="Environment"
                        onChange={(_, data) => onEnvironmentChange(data, engineSpec, setEngineSpec)}
                    />
                </Form.Group>
            )}
            {selectedTemplate && (
                <Message info header={selectedTemplate.name}>
                    {selectedTemplate.description}
                    <Divider hidden style={{ padding: "10px" }} />
                    <EngineNodeTable
                        template={selectedTemplate}
                        filteredCloud={envs.find((x) => x.id === engineSpec?.environmentID)?.cloud}
                    />
                </Message>
            )}
            {engineServiceType === EngineServiceType.RAY && (
                <div>
                    <Divider />
                    <Form.Input
                        type="number"
                        min="0"
                        name="autoSuspendSeconds"
                        label={<EngineTooltip name="autoSuspendSeconds" label="Auto Suspend Seconds" />}
                        placeholder={engineSpec?.autoSuspendSeconds || "3600"}
                        value={engineSpec?.autoSuspendSeconds ? engineSpec?.autoSuspendSeconds : "3600"}
                        onChange={(event) => onAutoSuspendChange(event, engineSpec, setEngineSpec)}
                        onBlur={(event: React.ChangeEvent<HTMLInputElement>) =>
                            onAutoSuspendBlur(event, engineSpec, setEngineSpec)
                        }
                    />
                    <Form.Checkbox
                        toggle
                        name={"autoResume"}
                        label={<EngineTooltip name="autoResume" label="Auto Resume" />}
                        onClick={(_, data) => onAutoResumeChange(data, engineSpec, setEngineSpec)}
                        defaultChecked={true}
                    />
                </div>
            )}
        </Form>
    );
};

const CreateEngineModal = (props: { open: boolean; setOpen: Dispatch<SetStateAction<boolean>> }) => {
    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    // Parent state:
    const { open, setOpen } = props;

    // Local state:
    // TODO: Maybe not ideal to essentially hold all form state in this one var, but whatever:
    const [engineSpec, setEngineSpec] = useState<EngineSpec | undefined | null>();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [engineSchema, setEngineSchema] = useState<EngineSchema>();
    const [envs, setEnvs] = useState<Environment[]>([]);
    const [engineServiceType, setEngineServiceType] = useState<EngineServiceType>(EngineServiceType.RAY);

    // Query state:
    const queryClient = useQueryClient();

    const { data: engineSchemaResponse, error: engineSchemaError } = useEngineSchemaQuery();
    const { data: environments, error: environmentsError } = useEnvironmentsQuery();
    useEffect(() => {
        if (!engineSchemaResponse || !environments) {
            return;
        }

        setEngineSchema(engineSchemaResponse);
        setEnvs(environments);

        // Set default template and environment:
        const templateOptions = engineSchemaResponse.templates.filter(
            (template) => template.serviceType === engineServiceType,
        );
        if (templateOptions.length > 0) {
            const templateID = templateOptions[0].id;
            const environmentID = environments.find((env) => env.name === "default")?.id;
            if (environmentID) {
                const newEngine = {
                    ...engineSpec,
                    templateID: templateID,
                    environmentID: environmentID,
                } as EngineSpec;
                setEngineSpec(newEngine);
            }
        }

        setErrorMessage(null);
    }, [engineSchemaResponse, environments]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (engineSchemaError) {
            const errorMsg = getErrorMessage(engineSchemaError);
            setErrorMessage(errorMsg);
        } else if (environmentsError) {
            const errorMsg = getErrorMessage(environmentsError);
            setErrorMessage(errorMsg);
        }
    }, [engineSchemaError, environmentsError]);

    const { mutate: mutateCreateEngine, reset: resetMutation } = useMutation({
        mutationFn: () => createEngine(engineSpec!, auth0TokenOptions),
        onSuccess: (res) => {
            setErrorMessage(null);
            // const final = res || engineSpec; // TODO: Huh?
            // props.onSubmit(res, EngineAction.ADD);
            setOpen(false);
            queryClient.invalidateQueries({ queryKey: GET_ENGINES_QUERY_KEY });
        },
        onError: (error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            setErrorMessage(errorMsg);
        },
        onSettled: () => {
            setLoading(false);
        },
    });

    return (
        <Modal
            className={metrics.BLOCK_AUTO_CAPTURE}
            onClose={() => {
                setOpen(false);
                resetMutation();
            }}
            onOpen={() => {
                setOpen(true);
            }}
            open={open}
        >
            <Modal.Header>Create Engine</Modal.Header>
            <Modal.Content>
                {engineSchema && envs && (
                    <ModalContent
                        engineSpec={engineSpec!}
                        engineSchema={engineSchema}
                        envs={envs}
                        setEngineSpec={setEngineSpec}
                        engineServiceType={engineServiceType}
                        setEngineServiceType={setEngineServiceType}
                    />
                )}
                {errorMessage ? (
                    <Message negative>
                        <Message.Header>Error in engine request</Message.Header>
                        <p>{errorMessage}</p>
                    </Message>
                ) : null}
            </Modal.Content>
            <Modal.Actions>
                <Button
                    className={metrics.BLOCK_AUTO_CAPTURE}
                    onClick={() => {
                        setOpen(false);
                    }}
                >
                    Cancel
                </Button>
                <Button
                    content="Create"
                    labelPosition="right"
                    icon="checkmark"
                    onClick={() => {
                        setLoading(true);
                        resetMutation();
                        mutateCreateEngine();
                    }}
                    loading={loading}
                    disabled={engineSpec?.name === undefined || engineSpec?.name === ""}
                    positive
                />
            </Modal.Actions>
        </Modal>
    );
};

export default CreateEngineModal;
