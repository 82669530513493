// In dropdown menus, we render {text: overflow(text)} instead of the raw text. This breaks dropdown search unless
// we explicitly search {rawtext: text}

export const rawtextSearch = (options: DropdownOptionsWithRawtext, query: string) => {
    return options.filter((x) => (x.rawtext as string).toLowerCase().includes(query.toLowerCase()));
};

export const escapeSQLSearchQuery = (query?: string) => {
    return query ? query.replace("'", "''") : query;
};
export const filterListOnSelectedAttributeWithSearchText = <T>(options: T[], selector: keyof T, query: string) => {
    return options.filter((x) => (x[selector] as string).toLowerCase().includes(query.toLowerCase()));
};
