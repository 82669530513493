import { adapter, deployment } from "../../api_generated";
import { TemplateVariable } from "./reducer";

const injectStringIntoTemplateIfMatch = (stringToInject: string, template: string, regex: RegExp) => {
    const match = regex.exec(template);
    if (match !== null) {
        const matchInTemplateString = match[0];

        const wrappedPrompt =
            template.substring(0, match.index) +
            stringToInject +
            template.substring(match.index + matchInTemplateString.length);
        return wrappedPrompt;
    }
    return stringToInject;
};

export const wrapPromptWithSystemTemplate = (
    prompt: string,
    selectedDeployment: deployment | null,
    selectedAdapter: adapter | null,
) => {
    let finalizedPrompt = prompt;

    // Wrap the current state of the prompt with the system (LLM's) prompt if it exists
    // and there is no selected adapter. Wrapping the fine-tuned adapter template with the
    // system template leads to degraded model quality.
    const systemTemplatePath = selectedDeployment?.model.promptTemplate;
    if (systemTemplatePath && selectedAdapter === null) {
        const systemTemplate = systemTemplatePath;
        const regex = /%s/g;
        finalizedPrompt = injectStringIntoTemplateIfMatch(finalizedPrompt, systemTemplate, regex);
    }

    return finalizedPrompt;
};

export const injectVariablesIntoPromptTemplate = (
    promptTemplateVariables: { [key: string]: TemplateVariable },
    promptTemplate: string | null,
) => {
    let combinedPrompt = "";
    if (promptTemplate) {
        const orderedVariables = Object.entries(promptTemplateVariables).sort((pair1, pair2) => {
            if (pair1[1].index < pair2[1].index) {
                return -1;
            }
            if (pair1[1].index > pair2[1].index) {
                return 1;
            }
            return 0;
        });

        let lastIndex = 0;
        orderedVariables.forEach((pair) => {
            combinedPrompt += promptTemplate.substring(lastIndex, pair[1].index);
            combinedPrompt += pair[1].value;
            lastIndex = pair[1].index + pair[1].skipLength;
        });
        combinedPrompt += promptTemplate.substring(lastIndex);
    }

    return combinedPrompt;
};

export const userHasEnteredTemplateValue = (promptTemplateVariables: { [key: string]: TemplateVariable }) => {
    for (const pair of Object.entries(promptTemplateVariables)) {
        if (pair[1].value !== "") {
            return true;
        }
    }
    return false;
};
