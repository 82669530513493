import { Checkbox, Form, Header, Segment } from "semantic-ui-react";

function TargetSelector(props: {
    outputFeatures: any[];
    target: string;
    // setTarget: React.Dispatch<React.SetStateAction<string>>;
    setTarget: (target: string) => void;
}) {
    return (
        <Segment style={{ backgroundColor: "rgba(0, 0, 0, 0.03)" }}>
            <Header as="h5">Output Feature</Header>
            <Form>
                {props.outputFeatures.map((name: string) => (
                    <Form.Field key={name}>
                        <Checkbox
                            radio
                            label={name}
                            checked={name === props.target}
                            onClick={(e, data) => props.setTarget(name)}
                        />
                    </Form.Field>
                ))}
            </Form>
        </Segment>
    );
}

export default TargetSelector;
