import { Route, Routes } from "react-router-dom";
import CreateAdapterView from "./views/create/CreateAdapterView";
import AdapterRepoView from "./views/repo/AdapterRepoView";
import AdapterReposView from "./views/repos/AdapterReposView";
import AdapterVersionView from "./views/version/AdapterVersionView";

const AdaptersViewRouter = () => {
    return (
        <Routes>
            {/* Individual adapter "version" view: */}
            <Route path="/repo/:repoUUID/version/:versionTag" element={<AdapterVersionView />} />

            {/* Adapter builder view: */}
            <Route path="/create/*" element={<CreateAdapterView />} />

            {/* Individual repo view: */}
            <Route path="/repo/:repoUUID" element={<AdapterRepoView />} />

            {/* Default view (all adapter repos): */}
            <Route
                path="/"
                element={
                    <div style={{ overflowX: "auto" }}>
                        <AdapterReposView />
                    </div>
                }
            />
        </Routes>
    );
};

export default AdaptersViewRouter;
