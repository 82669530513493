import { useEffect, useState } from "react";
import { UserContext } from "../types/user";

/**
 * Returns whether a given storage type is available in the current browser context. Necessary in scenarios like Safari
 * incognito, under which e.g. localStorage is not available.
 *
 * Adapted from MDN: https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#feature-detecting_localstorage
 *
 * @param storageType Type of storage to ask the browser for
 * @returns Whether the requested storage is available in current context
 */
export const storageAvailable = (storageType: string): boolean => {
    let storage;
    try {
        // @ts-expect-error Try to directly index the desired storage attribute, if it exists:
        storage = window[storageType];
        const x = "__storage_test__";
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    } catch (e) {
        return (
            e instanceof DOMException &&
            // everything except Firefox
            (e.code === 22 ||
                // Firefox
                e.code === 1014 ||
                // test name field too, because code might not be present
                // everything except Firefox
                e.name === "QuotaExceededError" ||
                // Firefox
                e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
            // acknowledge QuotaExceededError only if there's something already stored
            storage &&
            storage.length !== 0
        );
    }
};

export const localStorageAvailable = storageAvailable("localStorage");

export const getLocalStorageKey = (key: string) => {
    if (localStorageAvailable) {
        return localStorage.getItem(key);
    }
    return null;
};

export const getLocalStorageKeyForUser = (user: UserContext | undefined, key: string) => {
    const uuid = user?.uuid;
    const searchKey = [uuid, key].join("_");
    return getLocalStorageKey(searchKey);
};

export const setLocalStorageKey = (key: string, value: string | null) => {
    if (!localStorageAvailable) {
        return;
    }
    // ! WARNING: May cause issues with the `useLocalStorage` effect...
    if (value == null) {
        localStorage.removeItem(key);
    } else {
        localStorage.setItem(key, value);
    }
};

export const setLocalStorageKeyForUser = (user: UserContext | undefined, key: string, value: string | null) => {
    const uuid = user?.uuid;
    const searchKey = [uuid, key].join("_");
    setLocalStorageKey(searchKey, value);
};

export const useLocalStorage = (
    key: string,
    user?: UserContext,
): [string | null, React.Dispatch<React.SetStateAction<string | null>>] => {
    const [value, setValue] = useState(() => {
        if (user) {
            return getLocalStorageKeyForUser(user, key);
        }
        return getLocalStorageKey(key);
    });

    useEffect(() => {
        if (user) {
            setLocalStorageKeyForUser(user, key, value);
        } else {
            setLocalStorageKey(key, value);
        }
    }, [key, value, user]);

    return [value, setValue];
};
