import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation } from "@tanstack/react-query";
import { PropsWithChildren, useEffect, useState } from "react";
import AceEditor from "react-ace";
import { Route, Routes, useMatch } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Button, Divider, Form, Header, Loader, Message } from "semantic-ui-react";
import { useAuth0TokenOptions } from "../data";
import { track } from "../metrics/june";
import { useEnvironmentQuery } from "../query";
import { USER_STATE } from "../state/global";
import { getDocsHome } from "../utils/api";
import { PREDIBASE_LIGHT_GRAY, SEMANTIC_BLUE, SEMANTIC_GREY } from "../utils/colors";
import { isProduction } from "../utils/environment";
import { getErrorMessage } from "../utils/errors";
import { useGetSignOut } from "../utils/signout";
import { provisionEnvironment, submitAWSCredentials, submitAzureCredentials, submitCloudAndRegion } from "./data";

import "ace-builds/src-noconflict/ace"; // TODO: Why tho... (https://stackoverflow.com/questions/69545499/referenceerror-ace-is-not-defined)
import "ace-builds/src-noconflict/mode-sh";
import "ace-builds/src-noconflict/theme-chrome";

import "./EnvironmentView.css";

export enum EnvironmentStatus {
    CREATED = "created",
    READY = "ready",
    ERRORED = "errored",
    PROVISIONING = "provisioning",
    AUTHORIZING = "authorizing",
    AUTHORIZED = "authorized",
    DEPROVISIONING = "deprovisioning",
    DEPROVISIONED = "deprovisioned",
    UPDATING = "updating",
    PENDING = "pending",
    CANCELLED = "cancelled", // Note: British english
}

export const isRetryable = (status?: string): boolean => {
    return (
        status === EnvironmentStatus.ERRORED ||
        status === EnvironmentStatus.CANCELLED ||
        status === EnvironmentStatus.DEPROVISIONED
    );
};

function getAWSLink(shortCode: string, envRegion: string) {
    if (isProduction()) {
        return `https://${envRegion}.console.aws.amazon.com/cloudformation/home?region=${envRegion}#/stacks/quickcreate?templateUrl=https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fpredibase-public-us-west-2%2Finfrastructure%2Fdataplane-preprovision.yaml&stackName=predibase-dataplane-${shortCode}&param_EnvironmentID=${shortCode}&param_PredibaseDomain=${window.location.host}`;
    } else {
        return `https://${envRegion}.console.aws.amazon.com/cloudformation/home?region=${envRegion}#/stacks/quickcreate?templateUrl=https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fpredibase-public-us-west-2%2Finfrastructure%2Fstaging-dataplane-preprovision.yaml&stackName=predibase-dataplane-${shortCode}&param_EnvironmentID=${shortCode}&param_PredibaseDomain=${window.location.host}`;
    }
}

function refreshPage(delay: number) {
    // TODO: This seems to be breaking
    // history.push("/");
    // Refresh the page with some delay
    setTimeout(() => {
        window.location.href = "/";
    }, delay);
}

function cleanUpErrorMessage(errorMsg: string) {
    if (errorMsg.includes("unable to parse arn, arn: invalid prefix")) {
        return "The provided arn has an invalid prefix. Please make sure you copied the full resource arn.";
    }
    if (errorMsg.includes("not authorized to perform: sts:AssumeRole on resource")) {
        return "Unable to assume the provided role to access the account. Please ensure that the Predibase Provisioner Role ARN was set correctly to arn:aws:iam::437263316939:user/predibase-provisioner.";
    }
    if (errorMsg.includes("unable to validate azure credentials")) {
        return "Unable to access the subcription/resource group. Please ensure that the provided credentials are correct and the resource group was created successfully.";
    }
    return errorMsg;
}

const SignOutButton = (props: { signout: any }) => (
    <button className="button-reset nav-button top-right" onClick={props.signout}>
        Sign Out
    </button>
);

const BackButton = (props: { action: any }) => (
    <button className="button-reset nav-button top-left" onClick={props.action}>
        &lt; Select your cloud provider
    </button>
);

const FormLogoWrapper = (props: PropsWithChildren<any>) => (
    <div
        style={{
            minHeight: "100vh",
            background: PREDIBASE_LIGHT_GRAY,
            display: "flex",
            justifyContent: "center",
            fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
            padding: "20px",
        }}
    >
        <div style={{ textAlign: "center", alignSelf: "center", ...props.widthStyle }}>
            <img src={"/logos/predibase/predibase.svg"} width={70} height={70} alt="" />
            {props.children}
        </div>
    </div>
);

const CloudSetupButton = (props: PropsWithChildren<any>) => {
    let style: React.CSSProperties = {
        margin: "8px",
        padding: "15px",
        border: "1px solid #dededf",
        borderRadius: "4px",
        position: "relative",
        cursor: "pointer",
    };
    let disabledStyle: React.CSSProperties = {};
    if (props.disabled) {
        disabledStyle = { filter: "grayscale(100%)", opacity: "0.5" };
    }
    let selected = props.selectedCloud === props.cloud;
    let selectedStyle: React.CSSProperties = {};
    if (selected) {
        selectedStyle = { background: "rgba(33,133,208,.1)", border: "1px solid var(--semantic-blue)" };
    }

    const iconStyle: React.CSSProperties = {
        width: "1.1428rem",
        marginRight: "1.1428rem",
        position: "absolute",
        top: "16px",
        right: "0",
    };

    return (
        // @ts-ignore
        <div
            style={{ ...style, ...disabledStyle, ...selectedStyle }}
            onClick={() => {
                if (!props.disabled) {
                    props.setSelectedCloud(props.cloud);
                    props.setSelectedRegion("");
                }
            }}
        >
            {/* @ts-ignore */}
            <FontAwesomeIcon
                icon={"check-circle"}
                style={iconStyle}
                color={selected ? SEMANTIC_BLUE : "rgb(222, 222, 223, 0.1)"}
            />
            <div style={{ display: "inline-flex" }}>
                <div style={{ width: "25px" }} />
                <img src={props.imageLocation} width={100} height={100} alt={props.title} title={props.title} />
                <div style={{ width: "25px" }} />
            </div>
            <br />
            <div style={{ flexGrow: "1" }}>
                <b>{props.title}</b>
            </div>
            {props.disabled && <div style={{ color: SEMANTIC_GREY, fontSize: "12" }}>Coming soon</div>}
        </div>
    );
};

const AWS_REGIONS: string[] = ["us-west-2", "us-east-1"];

const AZURE_REGIONS: string[] = ["West US 2"];

const CloudRegionSelector = (props: PropsWithChildren<any>) => {
    const [querySearchText, setQuerySearchText] = useState<string>("");
    return (
        <Form style={{ padding: "16px", display: "flex", justifyContent: "center" }}>
            <Form.Select
                label={"Cloud Region"}
                options={props.regions.map((e: string) => ({ key: e, text: e, value: e }))}
                // @ts-ignore
                text={
                    querySearchText
                        ? querySearchText
                        : props.selectedRegion
                          ? props.selectedRegion
                          : "Select cloud region"
                }
                onChange={(e, { value }) => {
                    props.setSelectedRegion(value);
                }}
                onSearchChange={(e, data) => {
                    // @ts-ignore
                    setQuerySearchText(e.target.value);
                }}
                onClose={(event, data) => setQuerySearchText("")}
                value={props.selectedRegion}
                search={(options, query) =>
                    options.filter((x) => {
                        // @ts-ignore
                        return x.text.includes(query);
                    })
                }
                selection
            />
        </Form>
    );
};

const EnvironmentCloudSelectorView = (props: PropsWithChildren<any>) => {
    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    // Query state:
    const { mutate: mutateSubmitCloudAndRegion, reset: resetMutation } = useMutation({
        mutationFn: () =>
            submitCloudAndRegion(props.shortCode, props.selectedCloud, props.selectedRegion, auth0TokenOptions),
        onError: (error) => {
            props.setErrorMessage(getErrorMessage(error));
        },
        onSuccess: () => {
            props.setRenderSetup(true);
        },
        onSettled: () => {
            setLoading(false);
        },
    });

    // Local state:
    const [loading, setLoading] = useState<boolean>(false);

    return (
        <>
            <Header as="h3" textAlign="center">
                Select your cloud provider
            </Header>
            <div style={{ display: "inline-flex" }}>
                <CloudSetupButton
                    setSelectedRegion={props.setSelectedRegion}
                    selectedCloud={props.selectedCloud}
                    setSelectedCloud={props.setSelectedCloud}
                    cloud="aws"
                    imageLocation={"/amazon-web-services-2.svg"}
                    title={"Amazon Web Services"}
                />
                <CloudSetupButton
                    setSelectedRegion={props.setSelectedRegion}
                    selectedCloud={props.selectedCloud}
                    setSelectedCloud={props.setSelectedCloud}
                    cloud="azure"
                    imageLocation={"/azure.svg"}
                    title={"Microsoft Azure"}
                />
                <CloudSetupButton
                    selectedCloud={props.selectedCloud}
                    setSelectedCloud={props.setSelectedCloud}
                    cloud="gcp"
                    imageLocation={"/google-cloud-1.svg"}
                    title={"Google Cloud Platform"}
                    disabled={true}
                />
            </div>
            <br />
            {props.selectedCloud === "aws" && (
                <CloudRegionSelector
                    regions={AWS_REGIONS}
                    selectedRegion={props.selectedRegion}
                    setSelectedRegion={props.setSelectedRegion}
                />
            )}
            {props.selectedCloud === "azure" && (
                <CloudRegionSelector
                    regions={AZURE_REGIONS}
                    selectedRegion={props.selectedRegion}
                    setSelectedRegion={props.setSelectedRegion}
                />
            )}
            <Button
                primary
                size="large"
                loading={loading}
                onClick={() => {
                    setLoading(true);
                    resetMutation();
                    mutateSubmitCloudAndRegion();
                }}
                disabled={props.selectedCloud === null || props.selectedRegion === ""}
            >
                Setup Authorization
            </Button>
        </>
    );
};

// TODO: Needs better type:
const EnvironmentAzureSetupView = (props: PropsWithChildren<any>) => {
    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    // User state:
    const [user] = useRecoilState(USER_STATE);

    // Local state:
    const [appID, setAppID] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [tenantID, setTenantID] = useState<string>("");
    const [subscriptionID, setSubscriptionID] = useState<string>("");
    const [resourceGroup, setResourceGroup] = useState<string>("predibase-" + props.shortCode);
    const [errorMsg, setErrorMsg] = useState<string | null>("");
    const [validated, setValidated] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    // Query state:
    const { mutate: mutateSubmitAzureCredentials, reset: resetSubmitCredentialsMutation } = useMutation({
        mutationFn: () =>
            submitAzureCredentials(
                props.shortCode,
                { appID, password, tenantID, subscriptionID, resourceGroup },
                auth0TokenOptions,
            ),
        onError: (error) => {
            setValidated(false);
            setErrorMsg(getErrorMessage(error));
        },
        onSuccess: () => {
            setValidated(true);
        },
        onSettled: () => {
            setLoading(false);
        },
    });

    const { mutate: mutateProvisionEnvironment, reset: resetProvisionMutation } = useMutation({
        mutationFn: () => provisionEnvironment(props.shortCode, auth0TokenOptions),
        onSuccess: () => {
            refreshPage(100);
        },
    });

    const titleStyling = {
        display: "block",
        fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
        fontSize: "1rem",
        fontWeight: "700",
    };

    return (
        <>
            <Header as="h3" textAlign="center">
                Configure your Azure account
            </Header>
            <b>Selected region:</b> {props.envRegion}
            <Divider hidden />
            Please follow the steps below to create a Service Principal that will be used to deploy a Predibase
            environment within your Azure Account
            <br />
            <b>NOTE: All the listed steps must be executed in the same terminal session</b>
            <Form style={{ padding: "24px 0" }}>
                <ol style={{ textAlign: "left", paddingLeft: "20px", marginTop: "16px" }}>
                    <li>
                        Download and install the Azure CLI by following these{" "}
                        {
                            // eslint-disable-next-line react/jsx-no-target-blank
                            <a
                                href="https://learn.microsoft.com/en-us/cli/azure/install-azure-cli"
                                target="_blank"
                                rel="noopener"
                            >
                                instructions
                            </a>
                        }
                    </li>
                    <br />
                    <li>
                        Log onto the Azure CLI
                        <AceEditor
                            mode="sh"
                            style={{ marginTop: "16px" }}
                            theme="chrome"
                            name="azure-step-2"
                            value="az login"
                            height="2em"
                            width="40em"
                            fontSize="14px"
                            showGutter={true}
                            highlightActiveLine={false}
                            showPrintMargin={true}
                            readOnly={true}
                            setOptions={{
                                showLineNumbers: false,
                                tabSize: 4,
                            }}
                        />
                    </li>
                    <br />
                    <li>
                        Fetch the default subscription ID and store it into a local variable
                        <AceEditor
                            mode="sh"
                            style={{ marginTop: "16px" }}
                            theme="chrome"
                            name="azure-step-3"
                            value={`# Fetch the subscription ID \n export AZ_SUBSCRIPTION_ID="$(az account list --query "[?isDefault].id" -o tsv)" \n\n #NOTE: If you'd like to use a different subscription ID, uncomment the line below and export that ID instead.\n #export AZ_SUBSCRIPTION_ID="YOUR_ID" \n\n # View the subscription ID \n echo $AZ_SUBSCRIPTION_ID`}
                            height="11em"
                            width="40em"
                            fontSize="14px"
                            showGutter={true}
                            highlightActiveLine={false}
                            showPrintMargin={true}
                            readOnly={true}
                            setOptions={{
                                showLineNumbers: false,
                                tabSize: 4,
                            }}
                        />
                    </li>
                    <br />
                    <label style={{ ...titleStyling }}>Paste the Subscription ID</label>
                    <Form.Input
                        name="SubscriptionID"
                        style={{ width: "100%" }}
                        onChange={(event) => setSubscriptionID(event.target.value)}
                    />
                    <li>
                        Create a new resource group where Predibase will provision resources
                        <AceEditor
                            mode="sh"
                            style={{ marginTop: "16px" }}
                            theme="chrome"
                            name="azure-step-4"
                            value={`# Set the resource group name \n export RESOURCE_GROUP_NAME="predibase-${
                                props.shortCode
                            }" \n\n # Create the resource group \n az group create --name $RESOURCE_GROUP_NAME --location ${props.envRegion
                                .toLowerCase()
                                .replaceAll(" ", "")} --subscription $AZ_SUBSCRIPTION_ID`}
                            height="8em"
                            width="40em"
                            fontSize="14px"
                            showGutter={true}
                            highlightActiveLine={false}
                            showPrintMargin={true}
                            readOnly={true}
                            setOptions={{
                                showLineNumbers: false,
                                tabSize: 4,
                            }}
                        />
                        <br />
                        <strong>
                            NOTE: If you would like to use an existing resource group, export the env variable with that
                            value instead
                        </strong>
                    </li>
                    <br />
                    <label style={{ ...titleStyling }}>Paste the Resource Group (if changed)</label>
                    <Form.Input
                        name="ResourceGroup"
                        defaultValue={"predibase-" + props.shortCode}
                        style={{ width: "100%" }}
                        onChange={(event) => setResourceGroup(event.target.value)}
                    />
                    <li>
                        Create a new service principal with scoped access to the resource group created in step 4
                        <AceEditor
                            mode="sh"
                            theme="chrome"
                            style={{ marginTop: "16px" }}
                            name="azure-step-5"
                            value={`# Set the permissions scope to be limited to the predibase resource group \n export USER_SCOPE="/subscriptions/$AZ_SUBSCRIPTION_ID/resourceGroups/$RESOURCE_GROUP_NAME" \n\n # Create the service principal \n az ad sp create-for-rbac --name predibase-${props.shortCode} --role Contributor --scopes $USER_SCOPE > creds.json \n\n # View the credentials JSON \n cat creds.json`}
                            height="12em"
                            width="40em"
                            fontSize="14px"
                            showGutter={true}
                            highlightActiveLine={false}
                            showPrintMargin={true}
                            readOnly={true}
                            setOptions={{
                                showLineNumbers: false,
                                tabSize: 4,
                            }}
                        />
                    </li>
                    <br />
                    <label style={{ ...titleStyling }}>Paste the "appId" value from the JSON</label>
                    <Form.Input
                        name="AppID"
                        style={{ width: "100%" }}
                        onChange={(event) => setAppID(event.target.value)}
                    />
                    <label style={{ ...titleStyling }}>Paste the "password" value from the JSON</label>
                    <Form.Input
                        name="Password"
                        type="password"
                        style={{ width: "100%" }}
                        onChange={(event) => setPassword(event.target.value)}
                    />
                    <label style={{ ...titleStyling }}>Paste the "tenant" value from the JSON</label>
                    <Form.Input
                        name="TenantID"
                        style={{ width: "100%" }}
                        onChange={(event) => setTenantID(event.target.value)}
                    />
                </ol>
            </Form>
            <div style={{ color: SEMANTIC_GREY, fontSize: "12" }}>
                {validated ? (
                    <Button
                        primary
                        style={{ width: "100%" }}
                        fluid
                        size="large"
                        name="method"
                        type="submit"
                        onClick={() => {
                            resetProvisionMutation();
                            mutateProvisionEnvironment();
                        }}
                    >
                        Create Predibase Environment
                    </Button>
                ) : (
                    <Button
                        secondary
                        loading={loading}
                        style={{ width: "100%" }}
                        fluid
                        size="large"
                        name="method"
                        type="submit"
                        onClick={() => {
                            setLoading(true);
                            resetSubmitCredentialsMutation();
                            mutateSubmitAzureCredentials();
                        }}
                    >
                        Validate Azure Credentials
                    </Button>
                )}
                {errorMsg && !validated && (
                    <Message negative>
                        <Message.Header>Error</Message.Header>
                        <div>{cleanUpErrorMessage(errorMsg)}</div>
                    </Message>
                )}
            </div>
            <Divider hidden />
            For more detailed instructions/screenshots, see our{" "}
            {
                // eslint-disable-next-line react/jsx-no-target-blank
                <a
                    href={getDocsHome() + "/user-guide/admin/vpc-provisioning/azure"}
                    target="_blank"
                    rel="noopener"
                    onClick={() => {
                        user &&
                            track(user, "docs", {
                                url: getDocsHome() + "/user-guide/admin/vpc-provisioning/azure",
                                clickSource: "environments-view-azure",
                            });
                    }}
                >
                    docs
                </a>
            }
            .
        </>
    );
};

const EnvironmentAwsSetupView = (props: PropsWithChildren<any>) => {
    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    // Local state:
    const [roleArn, setRoleArn] = useState<string>("");
    const [errorMsg, setErrorMsg] = useState<string | null>(null);
    const [validated, setValidated] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    // User state:
    const [user] = useRecoilState(USER_STATE);

    // Query state:
    const { mutate: mutateSubmitCredentials, reset: resetSubmitCredentialsMutation } = useMutation({
        mutationFn: () => submitAWSCredentials(props.shortCode, roleArn, auth0TokenOptions),
        onError: (error) => {
            setValidated(false);
            setErrorMsg(getErrorMessage(error));
        },
        onSuccess: () => {
            setValidated(true);
        },
        onSettled: () => {
            setLoading(false);
        },
    });

    const { mutate: mutateProvisionEnvironment, reset: resetProvisionMutation } = useMutation({
        mutationFn: () => provisionEnvironment(props.shortCode, auth0TokenOptions),
        onSuccess: () => {
            refreshPage(100);
        },
    });

    const titleStyling = {
        display: "block",
        fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
        fontSize: "1rem",
        fontWeight: "700",
    };

    return (
        <>
            <Header as="h3" textAlign="center">
                Configure your AWS account
            </Header>
            <b>Selected region:</b> {props.envRegion}
            <Divider hidden />
            Please follow the steps below to create a Predibase environment that is deployed within your Amazon Web
            Services (AWS) account.
            <Divider hidden />
            <ol style={{ textAlign: "left", paddingLeft: "20px" }}>
                <li>
                    Navigate to this{" "}
                    <a href={getAWSLink(props.shortCode, props.envRegion)} target="_blank" rel="noreferrer">
                        direct link
                    </a>{" "}
                    to create a Predibase-enabled CloudFormation stack in your AWS account. The required fields are
                    pre-populated so no additional input is needed.
                </li>
                <li style={{ paddingTop: "10px" }}>
                    Scroll down to the bottom of that page and click the checkbox to confirm the CloudFormation
                    acknowledgment.
                </li>
                <li style={{ paddingTop: "10px" }}>
                    On that page, click <strong>“Create stack”</strong>.
                </li>
                <li style={{ paddingTop: "10px" }}>
                    After your CloudFormation stack has been created (about 2 minutes), navigate to the “Outputs” tab
                    and copy the value for <strong>CustomerEnvironmentRoleArn</strong>. Paste that value below.
                </li>
            </ol>
            <div style={{ color: SEMANTIC_GREY, fontSize: "12" }}>
                For more detailed instructions/screenshots, see our{" "}
                {
                    // eslint-disable-next-line react/jsx-no-target-blank
                    <a
                        href={getDocsHome() + "/user-guide/admin/vpc-provisioning/aws"}
                        target="_blank"
                        rel="noopener"
                        onClick={() => {
                            user &&
                                track(user, "docs", {
                                    url: getDocsHome() + "/user-guide/admin/vpc-provisioning/aws",
                                    clickSource: "environments-view-aws",
                                });
                        }}
                    >
                        docs
                    </a>
                }
                .
            </div>
            <div style={{ textAlign: "left" }}>
                <Form style={{ padding: "24px 0" }}>
                    <label style={{ ...titleStyling }}>RoleArn</label>
                    <Form.Input
                        name={"RoleArn"}
                        style={{ width: "100%" }}
                        onChange={(event) => setRoleArn(event.target.value)}
                    />
                </Form>
                {validated ? (
                    <Button
                        primary
                        style={{ width: "100%" }}
                        fluid
                        size="large"
                        name="method"
                        type="submit"
                        onClick={() => {
                            resetProvisionMutation();
                            mutateProvisionEnvironment();
                        }}
                    >
                        Create Predibase Environment
                    </Button>
                ) : (
                    <Button
                        secondary
                        loading={loading}
                        style={{ width: "100%" }}
                        fluid
                        size="large"
                        name="method"
                        type="submit"
                        onClick={() => {
                            setLoading(true);
                            resetSubmitCredentialsMutation();
                            mutateSubmitCredentials();
                        }}
                    >
                        Validate Role Authorization
                    </Button>
                )}
                {errorMsg && !validated && (
                    <Message negative>
                        <Message.Header>Error</Message.Header>
                        <div>{cleanUpErrorMessage(errorMsg)}</div>
                    </Message>
                )}
            </div>
            <Divider hidden />
        </>
    );
};

const EnvironmentSetupView = () => {
    const [selectedCloud, setSelectedCloud] = useState<string>("");
    const [selectedRegion, setSelectedRegion] = useState<string>("");
    const [renderSetup, setRenderSetup] = useState<boolean>(false);
    const match = useMatch("/environment/:envShortCode/setup");
    const shortCode = match?.params.envShortCode ?? "";
    const signout = useGetSignOut();
    const { data } = useEnvironmentQuery(shortCode, { enabled: shortCode !== "" });
    useEffect(() => {
        if (data) {
            setSelectedRegion(data.region);
            setSelectedCloud(data.cloud);
            if (data.region && data.cloud) {
                setRenderSetup(true);
            }
        }
    }, [data]);

    let widthStyle = {};
    if (renderSetup) {
        widthStyle = { width: "50vw", maxWidth: `${576 / 14}rem` };
    }

    if (selectedCloud && selectedRegion && renderSetup) {
        switch (selectedCloud) {
            case "aws":
                return (
                    <>
                        <SignOutButton signout={signout} />
                        {renderSetup && <BackButton action={() => setRenderSetup(false)} />}
                        <FormLogoWrapper widthStyle={widthStyle}>
                            <EnvironmentAwsSetupView shortCode={shortCode} envRegion={selectedRegion} />
                        </FormLogoWrapper>
                    </>
                );
            case "azure":
                return (
                    <>
                        <SignOutButton signout={signout} />
                        {renderSetup && <BackButton action={() => setRenderSetup(false)} />}
                        <FormLogoWrapper widthStyle={widthStyle}>
                            <EnvironmentAzureSetupView shortCode={shortCode} envRegion={selectedRegion} />
                        </FormLogoWrapper>
                    </>
                );
        }
    }

    return (
        <>
            <SignOutButton signout={signout} />
            {renderSetup && <BackButton action={() => setRenderSetup(false)} />}
            <FormLogoWrapper widthStyle={widthStyle}>
                <EnvironmentCloudSelectorView
                    shortCode={shortCode}
                    selectedCloud={selectedCloud}
                    setSelectedCloud={setSelectedCloud}
                    selectedRegion={selectedRegion}
                    setSelectedRegion={setSelectedRegion}
                    setRenderSetup={setRenderSetup}
                />
            </FormLogoWrapper>
        </>
    );
};

const EnvironmentStatusView = () => {
    const match = useMatch("/environment/:envShortCode/status");
    const shortCode = match?.params.envShortCode ?? "";
    const [reftechInterval, setRefetchInterval] = useState<number | false>(5000); // TODO: lol why?

    const signout = useGetSignOut();
    const {
        status: requestStatus,
        data,
        error,
    } = useEnvironmentQuery(shortCode, {
        refetchInterval: reftechInterval,
        enabled: shortCode !== "",
    });

    useEffect(() => {
        if (error) {
            setRefetchInterval(false);
        } else if (data) {
            if (data.status === EnvironmentStatus.READY) {
                // Wait for 100ms before refreshing the page
                refreshPage(100);
            }
        }
    }, [data, error]);

    const envStatus = data?.status || "";
    const envSteps = data?.provisioningSteps || [];
    let renderErrorBox = (errorMsg: any) => (
        <Message negative>
            <Message.Header>Error</Message.Header>
            <div>{errorMsg || "Request to backend failed"}</div>
        </Message>
    );
    let counterStyle = {
        marginRight: "10px",
        borderRadius: "50%",
        background: SEMANTIC_BLUE,
        width: "24px",
        height: "24px",
        color: "white",
        borderColor: SEMANTIC_BLUE,
    };

    const retryButton = (
        <Button
            style={{ width: "100%" }}
            fluid
            size="large"
            name="method"
            type="submit"
            onClick={() => {
                provisionEnvironment(shortCode);
            }}
        >
            Retry
        </Button>
    );

    return (
        <>
            <SignOutButton signout={signout} />
            <FormLogoWrapper widthStyle={{ width: "50vw", maxWidth: `${576 / 14}rem` }}>
                <Header as="h3" textAlign="center">
                    Setting up your Predibase environment...
                </Header>
                <div style={{ color: SEMANTIC_GREY, fontSize: "12", marginBottom: "20px" }}>
                    Approximately 20 minutes total.
                </div>
                {envStatus === EnvironmentStatus.PROVISIONING && (
                    <div>
                        You're done for now so just hang tight, grab a coffee, and when we're done, we'll send you an
                        email notification.
                    </div>
                )}
                <Divider hidden />
                {!!envStatus && (
                    <div style={{ textTransform: "capitalize" }}>
                        <b>Status: {envStatus}</b>
                    </div>
                )}
                <Divider hidden />
                {envSteps &&
                    envSteps.map((step: string, index: number) => (
                        <div style={{ display: "flex", marginBottom: "16px" }}>
                            <div style={counterStyle}>{index + 1}</div>
                            <div> {step.charAt(0).toUpperCase() + step.slice(1)}</div>
                        </div>
                    ))}
                <Divider hidden />
                {!isRetryable(envStatus) && requestStatus !== "error" && <Loader inline active />}
                {isRetryable(envStatus) && retryButton}
                {envStatus === EnvironmentStatus.ERRORED &&
                    renderErrorBox("The environment creation process failed. Please retry or contact your admin")}
                {requestStatus === "error" && renderErrorBox(error)}
            </FormLogoWrapper>
        </>
    );
};

// TODO: This is a huge fucking file
const EnvironmentViewRouter = () => {
    return (
        <Routes>
            <Route path="/:shortCode/setup" element={<EnvironmentSetupView />} />
            <Route path="/:shortCode/status" element={<EnvironmentStatusView />} />
        </Routes>
    );
};

export default EnvironmentViewRouter;
