import React from "react";

import { Card, Header, Image } from "semantic-ui-react";
import metrics from "../../metrics/metrics";
import { getImageForConnector } from "../../utils/connectors";

function ConnectorCard(props: {
    disabled?: boolean;
    onClick: (connector: string) => void;
    name: string;
    header: React.ReactNode;
}) {
    return (
        <Card
            link={!props.disabled}
            className={metrics.BLOCK_AUTO_CAPTURE}
            onClick={!props.disabled ? () => props.onClick(props.name) : undefined}
        >
            <Card.Content className="centered" style={{ textAlign: "center" }}>
                <Card.Header>
                    <Image src={getImageForConnector(props.name)} size="tiny" disabled={props.disabled} />
                </Card.Header>
                <Card.Description>
                    <Header disabled={props.disabled}>{props.header}</Header>
                </Card.Description>
            </Card.Content>
        </Card>
    );
}

export default ConnectorCard;
