import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { memo, useMemo } from "react";
import { Checkbox, Dimmer, Loader, Popup, Table } from "semantic-ui-react";
import { SEMANTIC_BLACK, SEMANTIC_BLUE } from "../../../utils/colors";
import { formatValueToNumericString } from "../../../utils/numbers";
import { useHyperoptTrialsQuery } from "../../query";
import { formatMetricName, generalMetricsKeys, isGBMModel } from "../../util";

const modelMetricsSort = (a: ModelRunMetrics, b: ModelRunMetrics) => {
    // TODO: Why...
    if (a.key == b.key) {
        return 0;
    } else if (a.key < b.key) {
        return -1;
    } else {
        return 1;
    }
};

/**
 * Gets the best test metrics from the ModelRunMetrics. Handles old ModelRunMetrics, which may not have "best" keys.
 *
 * @param metrics
 * @returns Test metrics, truncated to 4 significant digits.
 */
const getBestTestMetricsFromModelRunMetrics = (metrics: ModelRunMetrics[]) => {
    const specificMetrics = metrics
        .filter((metric: any) => metric.key.startsWith("best") && metric.key.includes("test_metrics"))
        .sort(modelMetricsSort);
    if (!specificMetrics.length) {
        // Old progress tracker, no explicit best metrics.
        return metrics.filter((metric: any) => metric.key.includes("test_metrics")).sort(modelMetricsSort);
    }

    // Truncate precision to 4 significant digits.
    for (const metric of specificMetrics) {
        metric.value = formatValueToNumericString(metric.value);
    }

    return specificMetrics;
};

const getBestTestMetrics = (metrics: { [key: string]: number | string }) => {
    const specificMetrics = Object.entries(metrics)
        .filter(([metric_name]) => metric_name.startsWith("best") && metric_name.includes("test_metrics"))
        .sort()
        .map(([metric_name, metric_value]) => ({
            key: metric_name,
            value: formatValueToNumericString(metric_value),
        }));
    if (!specificMetrics.length) {
        // Old progress tracker, no explicit best metrics.
        return Object.keys(metrics)
            .filter((x) => x.startsWith("test_metrics"))
            .sort()
            .map((x) => ({ key: x, value: formatValueToNumericString(metrics[x]) }));
    }
    return specificMetrics;
};

/**
 * A function to return the general metrics from a ModelRun.
 * @param run
 * @param modelConfig
 * @returns
 */
const filterGeneralMetrics = (run?: ModelRun, modelConfig?: CreateModelConfig) => {
    const metrics = run?.data.metrics || [];
    const generalMetrics = metrics
        .filter((metric) => generalMetricsKeys.includes(metric.key))
        .filter((metric) => (isGBMModel(modelConfig) ? metric.key !== "batch_size" : metric))
        .sort((a, b) => generalMetricsKeys.indexOf(a.key) - generalMetricsKeys.indexOf(b.key));

    return generalMetrics;
};

const filterMetrics = (run?: ModelRun, modelConfig?: CreateModelConfig) => {
    const metrics = run?.data.metrics || [];
    const generalMetrics = metrics
        .filter((metric) => generalMetricsKeys.includes(metric.key))
        .filter((metric) => (isGBMModel(modelConfig) ? metric.key !== "batch_size" : metric))
        .sort((a, b) => generalMetricsKeys.indexOf(a.key) - generalMetricsKeys.indexOf(b.key));
    const specificMetrics = getBestTestMetricsFromModelRunMetrics(metrics);
    return generalMetrics.concat(specificMetrics);
};

const truncateTrialName = (name: string) => {
    if (name === "default") {
        return name;
    }
    return name.substring(0, 5);
};

const filterMetricsIP = (runID: string, metricHistory: MetricHistory, modelConfig: CreateModelConfig) => {
    const metricArr = metricHistory[runID];
    if (metricArr && metricArr.length > 0) {
        const latest = metricArr[metricArr.length - 1];
        const generalMetrics = Object.keys(latest)
            .filter((x) => generalMetricsKeys.includes(x))
            .filter((metric) => (isGBMModel(modelConfig) ? metric !== "batch_size" : metric))
            .sort((a, b) => generalMetricsKeys.indexOf(a) - generalMetricsKeys.indexOf(b))
            .map((x) => ({ key: x, value: latest[x] }));
        const specificMetrics = getBestTestMetrics(latest);
        return generalMetrics.concat(specificMetrics);
    }
};

const mapTrialData = (trials: Trial[]) => {
    const runIdToTrialMap: Record<string, Trial> = {};
    trials.forEach((trial) => {
        trial.attempts.forEach((attempt) => {
            runIdToTrialMap[attempt.runID] = trial;
        });
    });
    return runIdToTrialMap;
};

function TrialParameterConfigs(props: { trialMap: Record<string, any>; runID: string }) {
    return (
        <Popup
            className="transition-scale"
            position={"top center"}
            trigger={<FontAwesomeIcon icon="magnifying-glass-plus" style={{ color: SEMANTIC_BLUE }} />}
            content={
                props.trialMap?.[props.runID]?.hyperoptParams ? (
                    <div style={{ overflowX: "auto" }}>
                        <Table basic={"very"} collapsing compact>
                            <Table.Body>
                                {Object.entries(props.trialMap[props.runID].hyperoptParams || {}).map((metric) => {
                                    return (
                                        <Table.Row>
                                            <Table.Cell style={{ borderTop: "none" }}>
                                                <b>{metric[0]}</b>
                                            </Table.Cell>
                                            <Table.Cell style={{ borderTop: "none" }}>
                                                {typeof metric[1] === "string"
                                                    ? metric[1].replace(/['"]+/g, "")
                                                    : (metric[1] as string)}
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </Table>
                    </div>
                ) : (
                    <div style={{ overflowX: "auto", padding: "1em" }}>
                        <Dimmer active inverted>
                            <Loader inline="centered" inverted indeterminate />
                        </Dimmer>
                    </div>
                )
            }
        />
    );
}

const HyperoptTable = (props: {
    modelVersion: Model;
    inProgress: boolean;
    activeRun?: ModelRun;
    bestRunID?: string;
    metricHistory: MetricHistory;
    experimentRuns: ModelRun[];
    selectedRuns: string[];
    runToNumberMap: React.MutableRefObject<Record<string, number>>;
    handleRunClick: (run: string) => void;
    handleRunClickBatch: (checked: boolean) => void;
}) => {
    const hasMultipleTrials = props.inProgress
        ? Object.keys(props.metricHistory).length > 1
        : props.experimentRuns.length > 1;

    // (TODO) Arnav: Once we feel confident that this works as intended (and is useful), add handling
    // for status and error so that we can fail when data is invalid or status is error
    const { data } = useHyperoptTrialsQuery(props.modelVersion.id, { enabled: hasMultipleTrials });

    const trialMap: Record<string, Trial> = useMemo(() => mapTrialData(data || []), [data]);

    const formatTrialNameOrID = (runID: string, text: string | number, icon?: boolean) => {
        if (props.bestRunID === runID && hasMultipleTrials) {
            return (
                <Popup
                    className="transition-scale"
                    position={"top center"}
                    trigger={
                        icon ? (
                            <span>
                                <b>{truncateTrialName(String(text))}</b> <FontAwesomeIcon icon="crown" color={"gold"} />
                            </span>
                        ) : (
                            <b>{truncateTrialName(String(text))}</b>
                        )
                    }
                    content={"Best trial"}
                />
            );
        }
        return truncateTrialName(String(text));
    };

    // Generates names of metrics for the trials table. Returned result must be in accordance with filterMetrics() and
    // filterMetricsIP(). One notable detail is that we exclude batch_size for GBM models as GBM models don't use this
    // concept.
    const getMetricNames = (metricHistory: MetricHistory, modelConfig?: any) => {
        for (const k of Object.keys(metricHistory)) {
            if (metricHistory[k].length > 0) {
                const generalMetrics = Object.keys(metricHistory[k][0])
                    .filter((x) => generalMetricsKeys.includes(x))
                    .filter((x) => (isGBMModel(modelConfig) ? x !== "batch_size" : x))
                    .sort((a, b) => generalMetricsKeys.indexOf(a) - generalMetricsKeys.indexOf(b));
                const specificMetrics = Object.keys(metricHistory[k][0]).filter((x) => x.startsWith("test_metrics"));
                return generalMetrics.concat(specificMetrics);
            }
        }
        return [];
    };

    const TrialsParentCheckbox = () => {
        if (hasMultipleTrials) {
            const totalRuns = props.inProgress
                ? Object.keys(props.runToNumberMap.current).length
                : props.experimentRuns.length;
            return (
                <Table.HeaderCell>
                    <Checkbox
                        style={{ verticalAlign: "middle", display: "block" }}
                        checked={props.selectedRuns.length === totalRuns}
                        indeterminate={props.selectedRuns.length > 0 && props.selectedRuns.length < totalRuns}
                        onClick={(event, data) => {
                            props.handleRunClickBatch(!data.checked as boolean);
                        }}
                    />
                </Table.HeaderCell>
            );
        }
        return null;
    };

    return (
        <div style={{ overflowX: "auto" }}>
            <Table compact>
                <Table.Header>
                    <Table.Row>
                        <TrialsParentCheckbox />
                        <Table.HeaderCell>#</Table.HeaderCell>
                        <Table.HeaderCell>Trial ID</Table.HeaderCell>
                        {hasMultipleTrials && (
                            <Table.HeaderCell>
                                <Popup
                                    className="transition-scale"
                                    position={"top center"}
                                    trigger={
                                        <span
                                            style={{
                                                color: SEMANTIC_BLACK,
                                                textDecorationLine: "underline",
                                                textDecorationStyle: "dotted",
                                                textUnderlineOffset: "2px",
                                            }}
                                        >
                                            Hyperopt Parameters
                                        </span>
                                    }
                                    content={"Parameter values used by the trial from the hyperopt search space"}
                                />
                            </Table.HeaderCell>
                        )}
                        {props.inProgress
                            ? getMetricNames(props.metricHistory, props.modelVersion.config).map((metricName) => (
                                  <Table.HeaderCell key={metricName}>{formatMetricName(metricName)}</Table.HeaderCell>
                              ))
                            : filterMetrics(props.activeRun, props.modelVersion.config).map((metric) => (
                                  <Table.HeaderCell key={metric.key}>{formatMetricName(metric.key)}</Table.HeaderCell>
                              ))}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {props.inProgress
                        ? Object.keys(props.metricHistory).map((runID) => {
                              const disabled = props.metricHistory.default !== undefined;
                              return (
                                  <Table.Row key={runID}>
                                      {hasMultipleTrials && (
                                          <Table.Cell>
                                              <Checkbox
                                                  style={{ verticalAlign: "middle", display: "block" }}
                                                  disabled={disabled}
                                                  checked={props.selectedRuns.includes(runID)}
                                                  onClick={(event, data) => {
                                                      return disabled ? {} : props.handleRunClick(runID);
                                                  }}
                                              />
                                          </Table.Cell>
                                      )}
                                      <Table.Cell>{props.runToNumberMap.current?.[runID]}</Table.Cell>
                                      <Table.Cell>{truncateTrialName(runID)}</Table.Cell>
                                      {hasMultipleTrials && (
                                          <Table.Cell>
                                              {<TrialParameterConfigs trialMap={trialMap} runID={runID} />}
                                          </Table.Cell>
                                      )}
                                      {filterMetricsIP(runID, props.metricHistory, props.modelVersion.config)?.map(
                                          (metric) => {
                                              return <Table.Cell key={metric.key}>{metric.value}</Table.Cell>;
                                          },
                                      )}
                                  </Table.Row>
                              );
                          })
                        : props.experimentRuns.map((run) => {
                              const runID = run.info.run_id;
                              const disabled = props.experimentRuns.length == 1;
                              return (
                                  <Table.Row key={runID}>
                                      {hasMultipleTrials && (
                                          <Table.Cell>
                                              <Checkbox
                                                  style={{ verticalAlign: "middle", display: "block" }}
                                                  disabled={disabled}
                                                  checked={props.selectedRuns.includes(runID)}
                                                  onClick={(event, data) => {
                                                      return disabled ? {} : props.handleRunClick(runID);
                                                  }}
                                              />
                                          </Table.Cell>
                                      )}
                                      <Table.Cell>
                                          {formatTrialNameOrID(runID, props.runToNumberMap.current?.[runID])}
                                      </Table.Cell>
                                      <Table.Cell>{formatTrialNameOrID(runID, runID, true)}</Table.Cell>
                                      {hasMultipleTrials && (
                                          <Table.Cell>
                                              {<TrialParameterConfigs trialMap={trialMap} runID={runID} />}
                                          </Table.Cell>
                                      )}
                                      {filterMetrics(run, props.modelVersion.config).map((metric, j) => {
                                          return (
                                              <Table.Cell key={j}>
                                                  {formatValueToNumericString(metric.value)}
                                              </Table.Cell>
                                          );
                                      })}
                                  </Table.Row>
                              );
                          })}
                </Table.Body>
            </Table>
        </div>
    );
};

const ModelMetricsTrialsTable = (props: {
    modelVersion: Model;
    inProgress: boolean;
    activeRun?: ModelRun;
    bestRunID?: string;
    metricHistory: MetricHistory;
    experimentRuns: ModelRun[];
    selectedRuns: string[];
    runToNumberMap: React.MutableRefObject<Record<string, number>>;
    handleRunClick: (run: string) => void;
    handleRunClickBatch: (checked: boolean) => void;
    isHyperopt: boolean;
}) => {
    if (props.isHyperopt) {
        return <HyperoptTable {...props} />;
    }

    return null;
};

export default memo(ModelMetricsTrialsTable);
