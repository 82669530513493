import React, { memo, useEffect, useState } from "react";
import { Form } from "semantic-ui-react";
import { DropdownProps } from "semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown";
import metrics from "../metrics/metrics";
import { getDropdownOverflowItem, getDropdownOverflowSelection } from "../utils/overflow";
import { noFunctionCompare } from "../utils/reactUtils";
import { rawtextSearch } from "../utils/search";
import { useConnectionsQuery } from "./query";

const collator = new Intl.Collator("en", { numeric: true, sensitivity: "base" });

const getConnectionFullName = (connection: Connection) => connection.type + ": " + connection.name;

const ConnectionSelector = (props: {
    connection?: Connection;
    selectConnection: (connection: Connection) => void;
    onConnectionsLoaded?: (connections: Connection[] | undefined) => void;
    disabled?: boolean;
}) => {
    // Parent state:
    const { connection, selectConnection, onConnectionsLoaded, disabled } = props;

    // Local state:
    const [connections, setConnections] = useState<Connection[]>();

    // Query state:
    const { data: connectionsResponse, error: connectionsError } = useConnectionsQuery(undefined, {
        refetchInterval: false,
    });
    useEffect(() => {
        if (connectionsResponse) {
            const connections: Connection[] = (connectionsResponse.connections?.sort() || [])
                .filter((x: Connection) => x.status === "connected")
                .sort((a: Connection, b: Connection) => {
                    const first = a.type + "_" + a.name;
                    const second = b.type + "_" + b.name;
                    return collator.compare(first, second);
                });

            setConnections(connections);

            if (onConnectionsLoaded) {
                onConnectionsLoaded(connections);
            }
        }
    }, [connectionsResponse, onConnectionsLoaded]);
    useEffect(() => {
        if (connectionsError) {
            setConnections([]);
        }
    }, [connectionsError]);

    // Dropdown options:
    const connectionOptions = connections?.map((connection, index) => {
        const fullName = getConnectionFullName(connection);
        return { rawtext: fullName, text: getDropdownOverflowItem(fullName), value: index };
    });

    let connectionSelection = undefined;
    let connectionSelectionName = null;
    if (connections && connections.length > 0 && connection != null) {
        const idx = connections.findIndex((x) => x.id === connection?.id);
        if (idx !== -1) {
            connectionSelection = idx;
            connectionSelectionName = getConnectionFullName(connections[idx]);
        }
    }

    return (
        <Form.Select
            error={connections && connections.length === 0}
            className={metrics.BLOCK_AUTO_CAPTURE}
            onClick={() => metrics.captureClick("Data.ConnectionSelector")}
            name="connection"
            label="Connection"
            options={connectionOptions || []}
            value={connectionSelection}
            // @ts-ignore
            text={getDropdownOverflowSelection(connectionSelectionName)}
            fluid
            placeholder="Connection"
            onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                if (!connections) {
                    return;
                }
                const connection = connections[data.value as number];
                selectConnection(connection);
            }}
            disabled={disabled}
            search={rawtextSearch}
            selection
        />
    );
};

export default memo(ConnectionSelector, noFunctionCompare);
