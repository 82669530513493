import React, { memo, useMemo } from "react";
import { Checkbox, Popup, Table } from "semantic-ui-react";
import FormattedNumberTooltip from "../../../components/FormattedNumberTooltip";
import Markdown from "../../../components/Markdown";
import { SEMANTIC_BLACK, SEMANTIC_GREY } from "../../../utils/colors";
import { formatValueToNumericString } from "../../../utils/numbers";
import { noFunctionCompare } from "../../../utils/reactUtils";
import { alphabeticalCollator } from "../../../utils/sort";
import { capitalize } from "../../../utils/strings";
import { constructMetricNameFromParts } from "./util";

const abbreviatedMetrics = [
    "mean_squared_error",
    "mean_absolute_error",
    "root_mean_squared_error",
    "root_mean_squared_percentage_error",
];
const abbreviateMetric = (metric: string) => {
    if (abbreviatedMetrics.includes(metric)) {
        const parts = metric.split("_");
        return (
            <Popup
                className={"transition-scale"}
                position={"right center"}
                flowing
                trigger={<b>{parts.map((x) => capitalize(x[0])).join("")}</b>}
                content={parts.map((x) => capitalize(x)).join(" ")}
            />
        );
    }
    return <b>{metric}</b>;
};

const metricTargetComparator = (a: string, b: string) => {
    if (a === "combined") {
        return -1;
    }
    if (b === "combined") {
        return 1;
    }
    return alphabeticalCollator.compare(a, b);
};

const metricsStyle = { color: SEMANTIC_GREY };

/**
 *
 * @param props
 *   selectedMetrics: List of metrics that are checked in the UI to be shown to the user.
 *   defaultMetrics: List of metrics that by default shown in the UI to be shown to the user.
 *   metrics: Map of field name (feature, Combined) -> metric_name -> map of train, validation, and test metric values.
 * @returns
 */
function ModelMetricsTable(props: {
    metricHistory: MetricHistory;
    selectedMetrics: string[];
    handleMetricClick: (metricKey: string) => void;
    batchHandleMetricClick: (metricKeys: string[], force?: boolean) => void;
    metrics: SpecificModelMetrics;
    bestModelMetrics?: BestModelMetrics;
    isCompare?: boolean;
    inProgress?: boolean;
    steps?: number;
    totalSteps?: number;
}) {
    const hasValidationMetrics = useMemo(() => {
        if (props.bestModelMetrics) {
            return Object.keys(props.bestModelMetrics).some((metricName) => metricName.includes("validation_metrics"));
        }
        return false;
    }, [props.bestModelMetrics]);

    if (!props.metrics) {
        return null;
    }

    const getMetricAction = (metricName: string, metricValue: number | null) => {
        if (props.isCompare || metricValue != null) {
            return () => props.handleMetricClick(metricName);
        }
        return undefined;
    };

    const getSpecificModelMetricsTable = (metrics: SpecificModelMetrics) => {
        return (
            <Table compact basic style={{ background: "#F7F7F7", paddingLeft: "8px", paddingRight: "8px" }} collapsing>
                <Table.Body>
                    {/* First section for best eval information. */}
                    <Table.Row className={"extra-compact"} key={"best-metrics"}>
                        <Table.Cell style={{ paddingTop: "16px" }}></Table.Cell>
                        <Table.Cell style={{ paddingTop: "16px", paddingBottom: "16px" }}>
                            <Popup
                                trigger={
                                    <b
                                        style={{
                                            color: SEMANTIC_BLACK,
                                            textDecorationLine: "underline",
                                            textDecorationStyle: "dotted",
                                            textUnderlineOffset: "2px",
                                        }}
                                    >
                                        Best Checkpoint
                                    </b>
                                }
                                position="top center"
                            >
                                <Markdown
                                    children="Evaluation is run for every model checkpoint. By default, model checkpoints are
                                    created once per epoch. Checkpoint frequency can be configured using `checkpoints_per_epoch` or `steps_per_checkpoint`."
                                />
                            </Popup>
                        </Table.Cell>
                        <Table.Cell>
                            {formatValueToNumericString(
                                props.bestModelMetrics?.best_eval_metric_checkpoint_number,
                                undefined,
                                undefined,
                                false,
                            )}
                        </Table.Cell>
                    </Table.Row>
                    {Object.keys(metrics)
                        .sort(metricTargetComparator)
                        .map((target) => {
                            const targetValues = metrics[target];
                            return Object.keys(targetValues)
                                .sort((a, b) => alphabeticalCollator.compare(a, b))
                                .map((metricName) => {
                                    const metricTypes = targetValues[metricName];

                                    const trainMetricName = constructMetricNameFromParts([
                                        "train_metrics",
                                        target,
                                        metricName,
                                    ]);
                                    const testMetricName = constructMetricNameFromParts([
                                        "test_metrics",
                                        target,
                                        metricName,
                                    ]);
                                    const valiMetricName = constructMetricNameFromParts([
                                        "validation_metrics",
                                        target,
                                        metricName,
                                    ]);

                                    const fullName = target + "." + metricName;

                                    const trainChecked = props.selectedMetrics.includes(trainMetricName);
                                    const testChecked = props.selectedMetrics.includes(testMetricName);
                                    const valiChecked = props.selectedMetrics.includes(valiMetricName);

                                    const numChecked = Number(trainChecked) + Number(testChecked) + Number(valiChecked);
                                    const totalScore = hasValidationMetrics ? 3 : 2;

                                    return (
                                        <React.Fragment key={fullName + "-fragment"}>
                                            <Table.Row className={"extra-compact"} key={fullName}>
                                                <Table.Cell style={{ paddingTop: "16px" }}>
                                                    <Checkbox
                                                        style={{ verticalAlign: "middle", display: "block" }}
                                                        checked={numChecked === totalScore}
                                                        indeterminate={numChecked > 0 && numChecked < totalScore}
                                                        onClick={(event, data) => {
                                                            const selectedMetrics = [];
                                                            if (props.isCompare || metricTypes.train_metrics != null) {
                                                                selectedMetrics.push(trainMetricName);
                                                            }
                                                            if (
                                                                props.isCompare ||
                                                                metricTypes.validation_metrics != null
                                                            ) {
                                                                selectedMetrics.push(valiMetricName);
                                                            }
                                                            if (props.isCompare || metricTypes.test_metrics != null) {
                                                                selectedMetrics.push(testMetricName);
                                                            }

                                                            props.batchHandleMetricClick(selectedMetrics, data.checked);
                                                        }}
                                                    />
                                                </Table.Cell>
                                                <Table.Cell style={{ paddingTop: "16px" }}>
                                                    <b style={{ color: SEMANTIC_GREY }}>{target}</b>
                                                    &nbsp;
                                                    {abbreviateMetric(metricName)}
                                                </Table.Cell>
                                                <Table.Cell></Table.Cell>
                                            </Table.Row>
                                            <Table.Row className={"extra-compact"} key={`${target}-train`}>
                                                <Table.Cell collapsing style={{ borderTop: "none" }}>
                                                    <Checkbox
                                                        style={{ verticalAlign: "middle", display: "block" }}
                                                        checked={trainChecked}
                                                        onClick={getMetricAction(
                                                            trainMetricName,
                                                            metricTypes.train_metrics,
                                                        )}
                                                    />
                                                </Table.Cell>
                                                <Table.Cell collapsing style={{ borderTop: "none" }}>
                                                    Train
                                                </Table.Cell>
                                                <Table.Cell style={{ borderTop: "none" }}>
                                                    {!props.isCompare && metricTypes.train_metrics && (
                                                        <FormattedNumberTooltip
                                                            value={metricTypes.train_metrics}
                                                            style={metricsStyle}
                                                            truncatedStyle={metricsStyle}
                                                        />
                                                    )}
                                                </Table.Cell>
                                            </Table.Row>
                                            {metricTypes.validation_metrics !== undefined ? (
                                                <Table.Row className={"extra-compact"} key={`${target}-validation`}>
                                                    <Table.Cell collapsing style={{ borderTop: "none" }}>
                                                        <Checkbox
                                                            style={{ verticalAlign: "middle", display: "block" }}
                                                            checked={valiChecked}
                                                            onClick={getMetricAction(
                                                                valiMetricName,
                                                                metricTypes.validation_metrics,
                                                            )}
                                                        />
                                                    </Table.Cell>
                                                    <Table.Cell collapsing style={{ borderTop: "none" }}>
                                                        Validate
                                                    </Table.Cell>
                                                    <Table.Cell style={{ borderTop: "none" }}>
                                                        {!props.isCompare && metricTypes.validation_metrics && (
                                                            <FormattedNumberTooltip
                                                                value={metricTypes.validation_metrics}
                                                                style={metricsStyle}
                                                                truncatedStyle={metricsStyle}
                                                            />
                                                        )}
                                                    </Table.Cell>
                                                </Table.Row>
                                            ) : null}
                                            <Table.Row className={"extra-compact"} key={`${target}-test`}>
                                                <Table.Cell
                                                    collapsing
                                                    style={{ borderTop: "none", paddingBottom: "16px" }}
                                                >
                                                    <Checkbox
                                                        style={{ verticalAlign: "middle", display: "block" }}
                                                        checked={testChecked}
                                                        onClick={getMetricAction(
                                                            testMetricName,
                                                            metricTypes.test_metrics,
                                                        )}
                                                    />
                                                </Table.Cell>
                                                <Table.Cell
                                                    collapsing
                                                    style={{ borderTop: "none", paddingBottom: "10px" }}
                                                >
                                                    Test
                                                </Table.Cell>
                                                <Table.Cell style={{ borderTop: "none", paddingBottom: "16px" }}>
                                                    {!props.isCompare && metricTypes.test_metrics && (
                                                        <FormattedNumberTooltip
                                                            value={metricTypes.test_metrics}
                                                            style={metricsStyle}
                                                            truncatedStyle={metricsStyle}
                                                        />
                                                    )}
                                                </Table.Cell>
                                            </Table.Row>
                                        </React.Fragment>
                                    );
                                });
                        })}
                </Table.Body>
            </Table>
        );
    };

    return (
        <div
            style={{
                overflowY: "auto",
                maxHeight: "80vh",
                border: "1px solid rgb(228 230 230)",
                borderRadius: ".28571429rem",
            }}
        >
            {getSpecificModelMetricsTable(props.metrics)}
        </div>
    );
}

export default memo(ModelMetricsTable, noFunctionCompare);
