import { Header, Message, Segment } from "semantic-ui-react";

import ExecutorForm from "./hyperopt/ExecutorForm";
import GoalAndMetricForm from "./hyperopt/GoalAndMetricForm";
import HyperoptParametersEditor from "./hyperopt/HyperoptParametersEditor";
import SamplerSchedulerForm from "./hyperopt/SamplerSchedulerForm";

import { useRecoilState } from "recoil";
import { USER_STATE } from "../../state/global";
import { SEMANTIC_BLACK } from "../../utils/colors";
import HyperoptCheckbox from "./hyperopt/HyperoptCheckbox";
import { useIsHyperoptEnabled } from "./store";

const ModelConfigHyperoptView = () => {
    const [user] = useRecoilState(USER_STATE);

    return (
        <div>
            <Message floating info style={{ color: SEMANTIC_BLACK }}>
                <b>What is Hyperopt?</b> Hyperparameter optimization enables you to train many models in one go to find
                the most optimal configuration of parameters for your model.
            </Message>
            <Segment raised style={{ padding: "1.75rem" }}>
                <div style={{ display: "flex", marginBottom: "-1em" }}>
                    <Header className="header" as="h3" style={{ marginRight: "1em" }}>
                        Train with Hyperopt
                    </Header>
                    <HyperoptCheckbox />
                </div>
                {useIsHyperoptEnabled() && <GoalAndMetricForm />}
            </Segment>
            {useIsHyperoptEnabled() ? (
                <>
                    <ExecutorForm />
                    <SamplerSchedulerForm />
                    <HyperoptParametersEditor />
                </>
            ) : null}
        </div>
    );
};

export default ModelConfigHyperoptView;
