import React, { useState } from "react";
import { Dropdown, Grid } from "semantic-ui-react";
import { DropdownItemProps } from "semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem";
import TargetSelector from "../../components/TargetSelector";
import ConfusionMatrix from "./ConfusionMatrixViewer";
import { UseOutputFeaturesForSelectedModels } from "./util";

function ConfusionMatrixCompareViewer(props: {
    modelDropdownOptions: any[];
    modelSearch: (options: DropdownItemProps[], value: string) => DropdownItemProps[];
    allModels: Model[];
    selectedModels: (Model | null)[];
    setSelectedModels: React.Dispatch<React.SetStateAction<(Model | null)[]>>;
    noModelSelected: JSX.Element;
}) {
    const [target, setTarget] = useState<string>("");

    const outputFeatures = UseOutputFeaturesForSelectedModels(target, setTarget, props.selectedModels);

    return (
        <Grid columns={"equal"} style={{ width: "100%", height: "100%" }} divided>
            {outputFeatures.length > 1 && (
                <Grid.Column width={2}>
                    <TargetSelector outputFeatures={outputFeatures} target={target} setTarget={setTarget} />
                </Grid.Column>
            )}
            <Grid.Column style={{ overflowX: "auto" }}>
                <Dropdown
                    fluid
                    search={props.modelSearch}
                    selection
                    options={props.modelDropdownOptions}
                    onChange={(event, data) => {
                        const selected = props.allModels.find((x) => x.id === data.value);
                        if (selected) {
                            props.setSelectedModels((x) => [selected, x[1]]);
                        }
                    }}
                    value={props.selectedModels[0]?.id || ""}
                />
                {props.selectedModels[0] ? (
                    <ConfusionMatrix
                        model={props.selectedModels[0]}
                        displayMatrixOnly
                        target={target}
                        setTarget={setTarget}
                    />
                ) : (
                    props.noModelSelected
                )}
            </Grid.Column>
            <Grid.Column style={{ overflowX: "auto" }}>
                <Dropdown
                    fluid
                    search={props.modelSearch}
                    selection
                    options={props.modelDropdownOptions}
                    onChange={(event, data) => {
                        const selected = props.allModels.find((x) => x.id === data.value);
                        if (selected) {
                            props.setSelectedModels((x) => [x[0], selected]);
                        }
                    }}
                    value={props.selectedModels[1]?.id || ""}
                />
                {props.selectedModels[1] ? (
                    <ConfusionMatrix
                        model={props.selectedModels[1]}
                        displayMatrixOnly
                        target={target}
                        setTarget={setTarget}
                    />
                ) : (
                    props.noModelSelected
                )}
            </Grid.Column>
        </Grid>
    );
}

export default ConfusionMatrixCompareViewer;
