import { useEffect } from "react";
import ReactFlow, { Edge, isNode, Node, Position, useEdgesState, useNodesState } from "react-flow-renderer";
import { getModelProgressEdges, getModelProgressNodes } from "./ModelProgressElements";
// @ts-ignore
import dagre from "dagre";

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const getLayoutedElements = (nodes: Node[], edges: Edge[], direction = "LR") => {
    const isHorizontal = direction === "LR";
    let nodeWidth = isHorizontal ? 160 : 120;
    let nodeHeight = isHorizontal ? 120 : 140;

    dagreGraph.setGraph({ rankdir: direction });

    nodes.forEach((node) => dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight }));
    edges.forEach((edge) => dagreGraph.setEdge(edge.source, edge.target));
    dagre.layout(dagreGraph);

    nodes.forEach((node) => {
        if (isNode(node)) {
            const nodeWithPosition = dagreGraph.node(node.id);
            node.targetPosition = isHorizontal ? Position.Left : Position.Top;
            node.sourcePosition = isHorizontal ? Position.Right : Position.Bottom;

            node.position = {
                x: nodeWithPosition.x - nodeWidth / 2,
                y: nodeWithPosition.y - nodeHeight / 2,
            };
            return node;
        }
    });
    return { nodes, edges };
};

function ModelProgressFlow(props: { model: Model; timeline?: ModelTimeline }) {
    const [nodes, setNodes, onNodesChange] = useNodesState(getModelProgressNodes(props.model, props.timeline));
    const [edges, setEdges, onEdgesChange] = useEdgesState(getModelProgressEdges(props.model));

    useEffect(() => {
        const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
            getModelProgressNodes(props.model, props.timeline),
            edges,
        );
        setNodes(layoutedNodes);
        setEdges(layoutedEdges);
    }, [props.timeline]);

    if (nodes === undefined || edges === undefined) {
        return null;
    }

    return (
        <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            nodesDraggable={false}
            zoomOnPinch={false}
            zoomOnDoubleClick={false}
            zoomOnScroll={false}
            preventScrolling={false}
            panOnDrag={false}
            fitView
            fitViewOptions={{ maxZoom: 1 }}
        ></ReactFlow>
    );
}

export default ModelProgressFlow;
