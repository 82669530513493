import { Dispatch, ReactNode, SetStateAction } from "react";
import { useRecoilState } from "recoil";
import { Button, Header, Loader, Message, Modal } from "semantic-ui-react";
import { useAPIToken } from "../../../api/useToken";
import { adapterVersion, deployment, deploymentStatus } from "../../../api_generated";
import CopyButton from "../../../components/CopyButton";
import { USER_STATE } from "../../../state/global";
import { getDocsHome, getServingAPIEndpoint } from "../../../utils/api";

import { CurrentUser, UserContext } from "../../../types/user";
import { isKratosUserContext } from "../../../utils/kratos";
import "./PromptInstructionsModal.css";

const CodeBlock = (props: { rawCode: string; children: ReactNode }) => {
    const { children, rawCode } = props;

    return (
        <div style={{ position: "relative", marginBottom: "1.71rem" }}>
            <code
                style={{
                    display: "block",
                    whiteSpace: "pre-wrap",
                    borderRadius: "0.42857rem",
                    padding: "1.14rem 5.07rem 1.14rem 1.14rem",
                    border: "1px solid var(--Light-Stroke, #DEDEDF)",
                    background: "var(--Utility-Light-background, #F7F7F7)",
                }}
            >
                {children}
            </code>
            <CopyButton
                text={rawCode}
                copyText=""
                iconColor="grey"
                style={{ position: "absolute", right: "calc(1.14rem - 7px)", top: "calc(1.14rem - 7px)" }}
            />
        </div>
    );
};

const rawCurlRequest = (
    deployment: deployment,
    userCtx: UserContext | CurrentUser,
    token?: Token,
    adapterId?: string,
) => {
    let shortCode;
    if (userCtx) {
        const isKratosContext = isKratosUserContext(userCtx);
        shortCode = isKratosContext ? userCtx.tenant.shortCode : userCtx?.tenant.shortcode;
    }

    if (adapterId) {
        return `curl -d '{"inputs": "What is your name?", "parameters": { \\
        "adapter_id": "${adapterId}", "adapter_source": "pbase", \\
        "max_new_tokens": 20, "temperature": 0.1}}' \\
            -H "Content-Type: application/json" \\
            -X POST ${getServingAPIEndpoint()}/${shortCode}/deployments/v2/llms/${deployment.name}/generate \\
            -H "Authorization: Bearer ${token?.token}"`;
    }
    return `curl -d '{"inputs": "What is your name?", "parameters": {"max_new_tokens": 20, "temperature": 0.1}}' \\
        -H "Content-Type: application/json" \\
        -X POST ${getServingAPIEndpoint()}/${shortCode}/deployments/v2/llms/${deployment.name}/generate \\
        -H "Authorization: Bearer ${token?.token}"`;
};
const CurlRequest = (props: {
    deployment: deployment;
    userCtx: UserContext | CurrentUser;
    token?: Token;
    adapterId?: string;
}) => {
    const { deployment, userCtx, token, adapterId } = props;
    let shortCode;
    if (userCtx) {
        const isKratosContext = isKratosUserContext(userCtx);
        shortCode = isKratosContext ? userCtx.tenant.shortCode : userCtx?.tenant.shortcode;
    }

    if (adapterId) {
        return (
            <>
                {`curl -d '{"inputs": "What is your name?", "parameters": {"adapter_id": "${adapterId}" \\`}
                <br />
                {`          "adapter_source": "pbase", "max_new_tokens": 20, "temperature": 0.1}' \\`}
                <br />
                {`     -H "Content-Type: application/json" \\`}
                <br />
                {`     -X POST ${getServingAPIEndpoint()}/${shortCode}/deployments/v2/llms/${
                    deployment.name
                }/generate \\`}
                <br />
                {`     -H "Authorization: Bearer ${token?.token}"`}
            </>
        );
    }

    return (
        <>
            {`curl -d '{"inputs": "What is your name?", "parameters": {"max_new_tokens": 20, "temperature": 0.1}}' \\`}
            <br />
            {`     -H "Content-Type: application/json" \\`}
            <br />
            {`     -X POST ${getServingAPIEndpoint()}/${shortCode}/deployments/v2/llms/${deployment.name}/generate \\`}
            <br />
            {`     -H "Authorization: Bearer ${token?.token}"`}
        </>
    );
};

const rawPythonSDKRequest = (deployment: deployment, adapterId?: string) => {
    if (adapterId) {
        return `lorax_client = pb.deployments.client("${deployment.name}")
print(lorax_client.generate("What is your name?", adapter_id="${adapterId}", max_new_tokens=100).generated_text)`;
    }

    return `lorax_client = pb.deployments.client("${deployment.name}")
print(lorax_client.generate("What is your name?", max_new_tokens=100).generated_text)`;
};
const PythonSDKRequest = (props: { deployment: deployment; adapterId?: string }) => {
    const { deployment, adapterId } = props;

    if (adapterId) {
        return (
            <>
                {`lorax_client = pb.deployments.client("${deployment.name}")`}
                <br />
                {`print(lorax_client.generate("What is your name?", adapter_id="${adapterId}", max_new_tokens=100).generated_text)`}
            </>
        );
    }

    return (
        <>
            {`lorax_client = pb.deployments.client("${deployment.name}")`}
            <br />
            {`print(lorax_client.generate("What is your name?", max_new_tokens=100).generated_text)`}
        </>
    );
};

const rawCLIRequest = (deployment: deployment, adapterId?: string) =>
    `pbase prompt llm "What is your name?" --deployment-name ${deployment?.name}${
        adapterId ? ` --adapter-name ${adapterId}` : ""
    }`;
const CLIRequest = (props: { deployment: deployment; adapterId?: string }) => {
    const { deployment, adapterId } = props;

    return (
        <>
            {`pbase prompt llm "What is your name?" --deployment-name ${deployment.name}${
                adapterId ? ` --adapter-name ${adapterId}` : ""
            }`}
        </>
    );
};

const PromptInstructionsContent = (props: { deployment: deployment; adapter?: adapterVersion; open: boolean }) => {
    // Parent props:
    const { deployment, adapter, open } = props;

    // User state:
    const [userCtx] = useRecoilState(USER_STATE);

    // Query state:
    const { error, token, isLoading } = useAPIToken("Autogenerated from the Deployment Page", open);

    // Derived state:
    const adapterId = adapter ? `${adapter.repo}/${adapter.tag}` : undefined;

    return (
        <>
            {error ? <div className="error-message">{error}</div> : null}
            {isLoading ? (
                <Loader />
            ) : (
                <div>
                    {deployment?.status !== deploymentStatus?.READY && (
                        <Message info>
                            <Message.Content>
                                The deployment is not ready. When prompting, expect some waiting time for it to finish
                                scaling up.
                            </Message.Content>
                        </Message>
                    )}
                    <div style={{ display: "flex" }}>
                        <Header as="h3">Curl</Header>
                        <p className="prompt-instructions-help-text">
                            &nbsp;(Using API token <b>{token?.description ? token?.description : token?.token}</b>)
                        </p>
                    </div>
                    <CodeBlock rawCode={rawCurlRequest(deployment, userCtx!, token, adapterId)}>
                        <CurlRequest deployment={deployment} userCtx={userCtx!} token={token} adapterId={adapterId} />
                    </CodeBlock>
                    {/* TODO: Dynamically add this note if the deployment is a fine-tuned model instead of a base model: */}
                    {/* <p>Note: Because your model was fine-tuned using a template with more than one input, you must specify at least one input via the column name.</p> */}
                    <div style={{ display: "flex" }}>
                        <Header as="h3">Python SDK</Header>
                        <p className="prompt-instructions-help-text">
                            &nbsp;(Assumes you've already installed Predibase)
                        </p>
                    </div>
                    <CodeBlock rawCode={rawPythonSDKRequest(deployment, adapterId)}>
                        <PythonSDKRequest deployment={deployment} adapterId={adapterId} />
                    </CodeBlock>
                    <div style={{ display: "flex" }}>
                        <Header as="h3">CLI</Header>
                        <p className="prompt-instructions-help-text">
                            &nbsp;(Assumes you've already installed Predibase)
                        </p>
                    </div>
                    <CodeBlock rawCode={rawCLIRequest(deployment, adapterId)}>
                        <CLIRequest deployment={deployment} adapterId={adapterId} />
                    </CodeBlock>
                </div>
            )}
        </>
    );
};

const PromptInstructionsModal = (props: {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    deployment?: deployment;
    adapter?: adapterVersion;
    parentStateIsLoading?: boolean;
}) => {
    const { open, setOpen, deployment, adapter, parentStateIsLoading } = props;

    if (!open) {
        return null;
    }

    return (
        <Modal open={open} onClose={() => setOpen(false)} size="large">
            {adapter ? (
                <Modal.Header>
                    Prompt Instructions {adapter.repo}/{adapter.tag}
                </Modal.Header>
            ) : (
                <Modal.Header>Prompt Instructions{deployment?.name ? ` - ${deployment.name}` : ""}</Modal.Header>
            )}
            <Modal.Content>
                {parentStateIsLoading ? (
                    <Loader />
                ) : (
                    <>
                        {deployment ? (
                            <PromptInstructionsContent deployment={deployment} adapter={adapter} open={open} />
                        ) : (
                            <div className="error-message">
                                {adapter !== undefined ? (
                                    <>
                                        Unable to find a deployment with a matching base model for your adapter. To run
                                        inference on your adapter,{" "}
                                        <a href={`${getDocsHome()}/user-guide/inference/dedicated_deployments`}>
                                            create a private serverless deployment.
                                        </a>
                                    </>
                                ) : (
                                    <>
                                        Unable to find serverless deployment for your model. Please contact support or{" "}
                                        <a href={`${getDocsHome()}/user-guide/inference/dedicated_deployments`}>
                                            create a private serverless deployment.
                                        </a>
                                    </>
                                )}
                            </div>
                        )}
                    </>
                )}
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => setOpen(false)}>Close</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default PromptInstructionsModal;
