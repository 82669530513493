import {
    ECDInputFeatures,
    ECDOutputFeatures,
    FreeTrialBlockedFeatureTypes,
    GBMInputFeatures,
    GBMOutputFeatures,
    LLMInputFeatures,
    LLMOutputFeatures,
} from "../../../types/model/featureTypes";
import { ModelTypes } from "../../../types/model/modelTypes";

export const computeRestrictedFeature = (option: string) =>
    Object.values(FreeTrialBlockedFeatureTypes).includes(option as FreeTrialBlockedFeatureTypes);

export const isFieldDisabled = (modelType: ModelTypes, fieldType: string, fieldMode: string) => {
    // Disable fields due to ecd supported/non-supported fields
    if (modelType === ModelTypes.NEURAL_NETWORK) {
        if (fieldMode === "input" && !ECDInputFeatures.includes(fieldType)) {
            return true;
        }

        if (fieldMode === "output" && !ECDOutputFeatures.includes(fieldType)) {
            return true;
        }
    }

    // Disable fields due to gbm supported/non-supported fields
    if (modelType === ModelTypes.DECISION_TREE) {
        if (fieldMode === "input" && !GBMInputFeatures.includes(fieldType)) {
            return true;
        }

        if (fieldMode === "output" && !GBMOutputFeatures.includes(fieldType)) {
            return true;
        }
    }

    // Disable fields due to llm supported/non-supported fields
    if (modelType === ModelTypes.LARGE_LANGUAGE_MODEL) {
        if (fieldMode === "input" && !LLMInputFeatures.includes(fieldType)) {
            return true;
        }

        if (fieldMode === "output" && !LLMOutputFeatures.includes(fieldType)) {
            return true;
        }
    }

    return false;
};
