import { Ace } from "ace-builds";
import type { JSONSchema7 } from "json-schema";
import _ from "lodash";

import llmSchema from "../../assets/llm-minified.json";

/**
 * Grabs the schema elements specific to LLMs.
 * @returns a JSONSchema7 object for the LLM form
 */
export const getLLMFormSchema = () =>
    ({
        properties: {
            base_model: llmSchema?.properties?.base_model,
            prompt_settings: {
                title: "Prompt",
                ...llmSchema?.properties?.prompt,
            },
            generation: {
                title: "Generation",
                ...llmSchema?.properties?.generation,
            },
            adapter: llmSchema?.properties?.adapter,
            quantization: llmSchema?.properties?.quantization,
        },
    }) as JSONSchema7;

export const getCompletionsFromSchema = (schema?: JSONSchema7) => {
    return (
        editor: Ace.Editor,
        session: Ace.EditSession,
        pos: Ace.Point,
        prefix: string,
        callback: Ace.CompleterCallback,
    ) => {
        const completions: Ace.Completion[] = [];

        if (schema) {
            _.get(schema, "properties.input_features.items.allOf", []).forEach((featureTypeSchema: any) => {
                completions.push({
                    value: featureTypeSchema?.if?.properties?.type?.const,
                    meta: "input_features",
                } as Ace.Completion);

                const encoders = featureTypeSchema?.then?.properties?.encoder?.properties?.type?.enum || [];
                encoders.forEach((encoder: string) =>
                    completions.push({ value: encoder, meta: "encoder" } as Ace.Completion),
                );
            });

            _.get(schema, "properties.output_features.items.allOf", []).forEach((featureTypeSchema: any) => {
                completions.push({
                    value: featureTypeSchema?.if?.properties?.type?.const,
                    meta: "output_features",
                } as Ace.Completion);

                const decoders = featureTypeSchema?.then?.properties?.decoder?.properties?.type?.enum || [];
                decoders.forEach((decoder: string) =>
                    completions.push({ value: decoder, meta: "decoder" } as Ace.Completion),
                );
            });

            _.get(schema, "properties.combiner.properties.type.enum", []).forEach((combinerType: string) => {
                completions.push({ value: combinerType, meta: "combiner" } as Ace.Completion);
            });

            _.get(schema, "properties.model_type.enum", []).forEach((modelType: string) => {
                completions.push({ value: modelType, meta: "model_type" } as Ace.Completion);
            });

            _.get(schema, "properties.trainer.properties.type.enum", []).forEach((trainerType: string) => {
                completions.push({ value: trainerType, meta: "trainer" } as Ace.Completion);
            });
        }

        // Pass the autocomplete suggestions back to ACE:
        callback(null, completions);
    };
};

// @TODO: Refactor to use lodash recursive strategy once schema is fully populated
// Branch Name: expected-impact-stash    Issue Number: PUX-475
/**
 * This function is used to filter out unwanted parameters from the schema. This includes parameters that are above a
 * certain expected impact value and parameters that are internal only.
 *
 * @param inputForm: The input form to filter (recursive)
 * @param expectedImpactFilter The expected impact filter to use - filter out all parameters with expected impact below
 * this value
 */
export const filterParams = (inputForm: any, expectedImpactFilter?: number) => {
    var form: any = _.cloneDeep(inputForm);

    if (expectedImpactFilter === undefined) {
        return form;
    }

    const getBranchLevel = (path: any) => {
        return (
            path?.schema?.properties?.oneOf ||
            path?.schema?.properties?.allOf ||
            path?.properties?.oneOf ||
            path?.properties?.allOf ||
            path?.oneOf ||
            path?.allOf
        );
    };
    const branchLevel: any = getBranchLevel(form);
    const leafLevel: any = form?.properties || form?.schema?.properties;

    if (branchLevel) {
        if (Array.isArray(branchLevel)) {
            branchLevel.forEach((branch: any, i: number) => {
                if (branch?.then) {
                    branchLevel[i].then = filterParams(branch.then, expectedImpactFilter);
                }
                branchLevel[i] = filterParams(branch, expectedImpactFilter);
            });
        }
        Object.keys(branchLevel).forEach((key: any) => {
            branchLevel[key].then = filterParams(branchLevel[key].then, expectedImpactFilter);
        });
    }

    const filterExpectedImpact = (leafLevel: any, key: string) => {
        return (
            _.has(leafLevel, `${key}.parameter_metadata.expected_impact`) &&
            leafLevel[key].parameter_metadata?.expected_impact < expectedImpactFilter
        );
    };

    const filterInternalOnly = (leafLevel: any, key: string) => {
        return (
            _.has(leafLevel, `${key}.parameter_metadata.internal_only`) &&
            leafLevel[key].parameter_metadata?.internal_only
        );
    };

    const filterDropdownOptions = (options: any, filter: string | undefined) => {
        if (filter === "tokenizer") {
            return options.filter((option: any) => !tokenizers.has(option));
        }
        // To set up a filter for another field, just add/export a new block list from `dropdownBlocks.ts` and add a new
        // condition here.

        return options;
    };

    if (leafLevel) {
        Object.keys(leafLevel).forEach((key: any) => {
            if (filterExpectedImpact(leafLevel, key) || filterInternalOnly(leafLevel, key)) {
                delete leafLevel[key];
            }

            if (leafLevel[key]?.hasOwnProperty("enum")) {
                leafLevel[key].enum = filterDropdownOptions(leafLevel[key].enum, key);
            }

            if (
                leafLevel.hasOwnProperty(key) &&
                !leafLevel[key]?.parameter_metadata &&
                getBranchLevel(leafLevel[key])
            ) {
                leafLevel[key] = filterParams(leafLevel[key], expectedImpactFilter);
            }

            if (leafLevel.hasOwnProperty(key) && !leafLevel[key]?.parameter_metadata && leafLevel[key]?.properties) {
                leafLevel[key] = filterParams(leafLevel[key], expectedImpactFilter);
            }
        });
    }

    return form;
};

export const isComputeDisabled = (computeTier: any) => {
    return computeTier !== undefined && computeTier >= 2;
};

/**
 * A function to recursively go through the updated config property and remove any empty leafs.
 * @param field the field being updated.
 * @param config the config object to validate.
 * @returns a pruned config object
 */
export const removeEmptyConfigProperties = (field: string, config?: CreateModelConfig) => {
    const configProperties = field.split(".");

    while (configProperties.length) {
        let configProperty = configProperties.pop();
        // Make sure we handle arrays, such as input_features and hyperopt search parameters.
        if (configProperty?.includes("[")) {
            configProperties.push(configProperty.substring(0, configProperty.indexOf("[")));
            configProperty = configProperty.substring(configProperty.indexOf("[") + 1, configProperty.length - 1);
        }

        if (!_.isEmpty(_.get(config, configProperties.join(".")))) {
            break;
        }

        _.unset(config, configProperties.join("."));
    }

    return config;
};

const tokenizers = new Set([
    "english_tokenize",
    "english_tokenize_filter",
    "english_tokenize_remove_stopwords",
    "english_lemmatize",
    "english_lemmatize_filter",
    "english_lemmatize_remove_stopwords",
    "italian_tokenize",
    "italian_tokenize_filter",
    "italian_tokenize_remove_stopwords",
    "italian_lemmatize",
    "italian_lemmatize_filter",
    "italian_lemmatize_remove_stopwords",
    "spanish_tokenize",
    "spanish_tokenize_filter",
    "spanish_tokenize_remove_stopwords",
    "spanish_lemmatize",
    "spanish_lemmatize_filter",
    "spanish_lemmatize_remove_stopwords",
    "german_tokenize",
    "german_tokenize_filter",
    "german_tokenize_remove_stopwords",
    "german_lemmatize",
    "german_lemmatize_filter",
    "german_lemmatize_remove_stopwords",
    "french_tokenize",
    "french_tokenize_filter",
    "french_tokenize_remove_stopwords",
    "french_lemmatize",
    "french_lemmatize_filter",
    "french_lemmatize_remove_stopwords",
    "portuguese_tokenize",
    "portuguese_tokenize_filter",
    "portuguese_tokenize_remove_stopwords",
    "portuguese_lemmatize",
    "portuguese_lemmatize_filter",
    "portuguese_lemmatize_remove_stopwords",
    "dutch_tokenize",
    "dutch_tokenize_filter",
    "dutch_tokenize_remove_stopwords",
    "dutch_lemmatize",
    "dutch_lemmatize_filter",
    "dutch_lemmatize_remove_stopwords",
    "greek_tokenize",
    "greek_tokenize_filter",
    "greek_tokenize_remove_stopwords",
    "greek_lemmatize",
    "greek_lemmatize_filter",
    "greek_lemmatize_remove_stopwords",
    "norwegian_tokenize",
    "norwegian_tokenize_filter",
    "norwegian_tokenize_remove_stopwords",
    "norwegian_lemmatize",
    "norwegian_lemmatize_filter",
    "norwegian_lemmatize_remove_stopwords",
    "lithuanian_tokenize",
    "lithuanian_tokenize_filter",
    "lithuanian_tokenize_remove_stopwords",
    "lithuanian_lemmatize",
    "lithuanian_lemmatize_filter",
    "lithuanian_lemmatize_remove_stopwords",
    "danish_tokenize",
    "danish_tokenize_filter",
    "danish_tokenize_remove_stopwords",
    "danish_lemmatize",
    "danish_lemmatize_filter",
    "danish_lemmatize_remove_stopwords",
    "polish_tokenize",
    "polish_tokenize_filter",
    "polish_tokenize_remove_stopwords",
    "polish_lemmatize",
    "polish_lemmatize_filter",
    "polish_lemmatize_remove_stopwords",
    "romanian_tokenize",
    "romanian_tokenize_filter",
    "romanian_tokenize_remove_stopwords",
    "romanian_lemmatize",
    "romanian_lemmatize_filter",
    "romanian_lemmatize_remove_stopwords",
    "japanese_tokenize",
    "japanese_tokenize_filter",
    "japanese_tokenize_remove_stopwords",
    "japanese_lemmatize",
    "japanese_lemmatize_filter",
    "japanese_lemmatize_remove_stopwords",
    "chinese_tokenize",
    "chinese_tokenize_filter",
    "chinese_tokenize_remove_stopwords",
    "chinese_lemmatize",
    "chinese_lemmatize_filter",
    "chinese_lemmatize_remove_stopwords",
    "multi_tokenize",
    "multi_tokenize_filter",
    "multi_tokenize_remove_stopwords",
    "multi_lemmatize",
    "multi_lemmatize_filter",
    "multi_lemmatize_remove_stopwords",
]);
