import { Icon, SemanticCOLORS } from "semantic-ui-react";
import { EngineServiceType } from "../types/engineServiceType";
import { cssTruncate, getOverflowItem } from "../utils/overflow";

const collator = new Intl.Collator("en", { numeric: true, sensitivity: "base" });

export const EngineStatusMap: EngineStatusMap = {
    active: {
        color: "green",
        active: true,
        busy: false,
        rank: 0,
    },
    initializing: {
        color: "olive",
        active: true,
        busy: true,
        rank: 1,
    },
    suspended: {
        color: "orange",
        active: false,
        busy: false,
        rank: 2,
    },
    suspending: {
        color: "yellow",
        active: false,
        busy: true,
        rank: 3,
    },
    errored: {
        color: "red",
        active: false,
        busy: false,
        rank: 4,
    },
    terminating: {
        color: "grey",
        active: false,
        busy: true,
        rank: 5,
    },
    inactive: {
        color: "black",
        active: false,
        busy: false,
        rank: 6,
    },
    updating: {
        color: "blue",
        active: true,
        busy: true,
        rank: 7,
    },
    linked: {
        color: "teal",
        active: true,
        busy: false,
        rank: 7,
    },
};

export const engineStatusSort = (a: Partial<Engine>, b: Partial<Engine>) => {
    if (a.engineStatus === b.engineStatus) {
        return collator.compare(a.name!, b.name!);
    }
    return EngineStatusMap[a.engineStatus!]?.rank - EngineStatusMap[b.engineStatus!]?.rank;
};

export const engineTrainingSort = (a: Partial<Engine>, b: Partial<Engine>) => {
    if (a.serviceType === b.serviceType) {
        return collator.compare(a.name!, b.name!);
    }

    let aRank = a.serviceType === EngineServiceType.BATCH ? 0 : 1;
    let bRank = b.serviceType === EngineServiceType.BATCH ? 0 : 1;
    return aRank - bRank;
};

export const getEngineStatusInfo = (status?: string) => (status ? EngineStatusMap[status as EngineStatus] : undefined);

export const engineStatusCircle = (engine: Engine) => {
    return (
        <Icon
            className={"engineCircle"}
            style={{ float: "right" }}
            name="circle"
            color={getEngineStatusInfo(engine.engineStatus)?.color as SemanticCOLORS}
        />
    );
};

export const getEngineDropdownItemCSS = (engine: Engine) => {
    // Used when width of dropdown itself can change
    const name = <div style={{ ...cssTruncate, width: "80%" }}>{engine?.name}</div>;
    return (
        <>
            {name}
            {engineStatusCircle(engine)}
        </>
    );
};

// TODO: These all need to be components, not functions:
export const getEngineDropdownItemWithStatus = (engine: Engine, current?: boolean, usePreloadedName?: boolean) => {
    if (engine === undefined) {
        return "";
    }
    return getOverflowItem(
        usePreloadedName ? engine?.preloadModelPath : engine?.name,
        current || false,
        70,
        "inline-block",
    );
};

export const getEngineDropdownSelectionWithStatus = (engine: Engine, usePreloadedName?: boolean) => {
    if (engine === undefined) {
        return "";
    }

    // @ts-ignore
    const name = getOverflowItem(usePreloadedName ? engine?.preloadModelPath : engine?.name, false, 85, "inline-block");
    return (
        <>
            {name}
            {engineStatusCircle(engine)}
        </>
    );
};
