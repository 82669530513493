import React, { useEffect, useState } from "react";
import { Checkbox, Divider, Form, Grid, Header, Loader, Message, Segment, Tab } from "semantic-ui-react";
import ExplanationArray from "../../components/unsorted/results/ExplanationArray";
import { TextExplanationsTable } from "../../components/unsorted/results/ExplanationText";
import metrics from "../../metrics/metrics";
import { SEMANTIC_GREY } from "../../utils/colors";
import { loadResultsDataset } from "../../utils/results";
import { useVizExplanationsQuery } from "../query";
import {
    featureImportanceDisabledCriteria,
    getConfigOutputFeatureNames,
    getNumInputFeaturesForModelConfig,
    getNumTextInputFeaturesForModelConfig,
    modelStatusDisabledCriteria,
} from "../util";

function FeatureImportanceViewer(props: {
    model: Model;
    className?: string;
    target?: string;
    setTarget?: React.Dispatch<React.SetStateAction<string>>;
    errorMessage?: string | null;
    setErrorMessage?: React.Dispatch<React.SetStateAction<string | null>>;
    loading?: boolean;
    setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
    dataset?: ResultsDataset | null;
    activeIndex?: number;
    indexSelector?: React.ReactNode;
}) {
    // Dataset be passed in as a prop (for comparison) or managed internally (for single model)
    const [internalDataset, setInternalDataset] = useState<ResultsDataset | null | undefined>();
    const [internalActiveIndex, setInternalActiveIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const [internalErrorMessage, setInternalErrorMessage] = useState<string | null>(props.errorMessage || null);

    const dataset = props.dataset || internalDataset;

    const [internalTarget, setInternalTarget] = useState<string>();

    // Target can either be passed in as a prop (for ModelCompareView) or managed internally (for ModelVersionView)
    const [target, setTarget] =
        props.target && props.setTarget ? [props.target, props.setTarget] : [internalTarget, setInternalTarget];
    const [activeIndex, setActiveIndex] = props.activeIndex
        ? [props.activeIndex, null]
        : [internalActiveIndex, setInternalActiveIndex];
    // const [loading, setLoading] = props.loading && props.setLoading ? [props.loading, props.setLoading] : [internalLading, setInternalLoading];
    const [errorMessage, setErrorMessage] =
        props.errorMessage && props.setErrorMessage
            ? [props.errorMessage, props.setErrorMessage]
            : [internalErrorMessage, setInternalErrorMessage];
    const output_features = getConfigOutputFeatureNames(props.model.config);

    const [tabIndex, setTabIndex] = useState(0);

    useEffect(() => {
        setActiveIndex?.(0);
    }, [props.target]);

    const statusDisabledMsg = modelStatusDisabledCriteria(props.model);
    const specificDisabledMsg = featureImportanceDisabledCriteria(props.model);

    const {
        data: vizExplanations,
        isLoading: vizIsLoading,
        error: vizError,
    } = useVizExplanationsQuery(props.model.id, target, {
        enabled: Boolean(!props.dataset && target && !(statusDisabledMsg || specificDisabledMsg)),
    });
    useEffect(() => {
        if (vizIsLoading) {
            setLoading(true);
        }
    }, [vizIsLoading]);
    useEffect(() => {
        if (vizExplanations) {
            setInternalDataset(loadResultsDataset(vizExplanations));
            setErrorMessage(null);
            setLoading(false);
        }
    }, [vizExplanations]);
    useEffect(() => {
        if (vizError) {
            setInternalDataset(null);
            setErrorMessage(vizError.toString());
            setLoading(false);
        }
    }, [vizError]);

    useEffect(() => {
        if ((target === undefined && output_features.length > 0) || props.errorMessage) {
            setTarget(output_features[0]);
            return;
        }
    }, [props.model, target, props.errorMessage]);

    if (errorMessage) {
        if (errorMessage.includes("No results found")) {
            return (
                <Message negative>
                    No feature importance found for model. This model was likely trained before this feature was added.
                    <br />
                    <br />
                    To view, train a new version.
                </Message>
            );
        }
        return <Message negative>{errorMessage}</Message>;
    }

    if (props.loading || props.className === null) {
        return <Loader active content={"Loading feature importance..."} />;
    }

    if (statusDisabledMsg) {
        return <Message info content={statusDisabledMsg} />;
    } else if (specificDisabledMsg) {
        return <Message info content={specificDisabledMsg} />;
    } else if (props.model && target && !getConfigOutputFeatureNames(props.model.config).includes(target)) {
        return (
            <Message
                info
                content={
                    <span>
                        Model does not contain output feature: [{target}]
                        <Divider hidden />
                        Model contains output features: [{getConfigOutputFeatureNames(props.model.config).join(", ")}]
                    </span>
                }
            />
        );
    }

    if (!target || dataset === undefined) {
        return <Loader active content={"Loading feature importance..."} />;
    }

    if (activeIndex === -1) {
        return <span>Class {props.className} not found in model</span>;
    }

    if (dataset === null) {
        return <Message info>No results found for model</Message>;
    }

    const targetSelector =
        !props.target && output_features.length > 1 ? (
            <>
                <Header as="h5">Output Feature</Header>
                <Form>
                    {output_features.map((name: string) => (
                        <Form.Field key={name}>
                            <Checkbox
                                radio
                                label={name}
                                checked={name === target}
                                onClick={(e, data) => setTarget(name)}
                            />
                        </Form.Field>
                    ))}
                </Form>
            </>
        ) : null;

    const col = dataset.columns[0];
    const metadata = dataset?.metadata?.[col];

    const idx2str = metadata.targetIdx2str;
    const indexSelector =
        setActiveIndex && idx2str ? (
            <>
                <Header as="h5">Class</Header>
                {loading ? (
                    <span
                        style={{
                            textAlign: "center",
                            display: "block",
                            color: SEMANTIC_GREY,
                            fontSize: "0.9rem",
                        }}
                    >
                        Loading classes...
                    </span>
                ) : (
                    <Form>
                        {idx2str.map((label: string, labelIndex: number) => (
                            <Form.Field key={label}>
                                <Checkbox
                                    radio
                                    label={label}
                                    checked={labelIndex === activeIndex}
                                    onChange={(e, data) => setActiveIndex(labelIndex)}
                                />
                            </Form.Field>
                        ))}
                    </Form>
                )}
            </>
        ) : null;

    const FeatureImportanceContent = () => {
        const Body = loading ? (
            <Loader active content={"Loading feature importance..."} />
        ) : (
            <ExplanationArray
                metadata={metadata}
                data={dataset?.data!}
                colIndex={0}
                activeIndex={activeIndex}
                name={col}
                target={target}
            />
        );

        if (props.activeIndex !== undefined) {
            return Body;
        }
        if (targetSelector || indexSelector) {
            return (
                <Grid>
                    <Grid.Column computer={3}>
                        <Segment style={{ backgroundColor: "rgba(0, 0, 0, 0.03)" }}>
                            {targetSelector}
                            {indexSelector}
                        </Segment>
                    </Grid.Column>
                    <Grid.Column computer={13}>{Body}</Grid.Column>
                </Grid>
            );
        }
        return Body;
    };

    const numTextFeatures = getNumTextInputFeaturesForModelConfig(props.model.config);
    const numInputFeatures = getNumInputFeaturesForModelConfig(props.model.config);
    const featureImportancePane = {
        key: "featureImportanceBody",
        menuItem: "Feature Importance",
        render: () => (
            <Tab.Pane style={{ overflowX: "auto" }}>
                <FeatureImportanceContent />
            </Tab.Pane>
        ),
    };

    const textExplanations: ShapResult[] = dataset?.data?.map((row) => row[0]) || [];
    const textExplanationsPane = {
        key: "textExplanations",
        menuItem: "Text Explanations",
        render: () => (
            <Tab.Pane style={{ overflowX: "auto" }}>
                <TextExplanationsTable
                    results={textExplanations}
                    metadata={metadata}
                    outputFeatures={getConfigOutputFeatureNames(props.model.config)}
                />
            </Tab.Pane>
        ),
    };

    const panes =
        numTextFeatures === 0
            ? [featureImportancePane]
            : numTextFeatures === 1 && numInputFeatures === 1
              ? [textExplanationsPane]
              : [featureImportancePane, textExplanationsPane];

    return (
        <Tab
            className={metrics.BLOCK_AUTO_CAPTURE}
            panes={panes}
            defaultActiveIndex={0}
            onTabChange={(event, data) => setTabIndex(data.activeIndex as number)}
            renderActiveOnly={true}
            menu={{ secondary: true, pointing: true }}
        />
    );
}

export default FeatureImportanceViewer;
