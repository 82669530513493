import { Button, Modal } from "semantic-ui-react";

import { GBMInputFeatures, GBMOutputFeatures, LLMOutputFeatures } from "../../../types/model/featureTypes";
import { ModelTypes } from "../../../types/model/modelTypes";
import { useDispatch, useModelConversionLoss } from "../store";

const ModelConfirmationModal = (props: {
    open: boolean;
    setShowConfirmationModal: (show: boolean) => void;
    modelType: ModelTypes;
    children: any;
}) => {
    const dispatch = useDispatch();
    const { open, modelType, children, setShowConfirmationModal } = props;

    return (
        <Modal open={open}>
            <Modal.Content>{children}</Modal.Content>
            <Modal.Actions>
                <Button content="Never mind" onClick={() => setShowConfirmationModal(false)} />
                <Button
                    content="Continue"
                    labelPosition="right"
                    icon="checkmark"
                    onClick={() => {
                        setShowConfirmationModal(false);
                        dispatch({ type: "UPDATE_MODEL_TYPE", modelType: modelType });
                    }}
                    positive
                />
            </Modal.Actions>
        </Modal>
    );
};

const GBMConfirmationModal = (props: { open: boolean; setShowConfirmationModal: (show: boolean) => void }) => {
    const GBMConversionLoss = useModelConversionLoss(ModelTypes.DECISION_TREE);

    return (
        <ModelConfirmationModal
            open={props.open}
            setShowConfirmationModal={props.setShowConfirmationModal}
            modelType={ModelTypes.DECISION_TREE}
        >
            <Modal.Description>
                <p>
                    <b>Warning:</b> Gradient Boosted Trees only support {GBMInputFeatures.slice(0, -1).join(", ")} and{" "}
                    {GBMInputFeatures.at(-1)} input features.
                    {GBMConversionLoss.missingInputFeatures &&
                        GBMConversionLoss.missingInputFeatures.length > 0 &&
                        " Therefore, the following input feature(s) will become inactive:"}
                </p>
                {GBMConversionLoss.missingInputFeatures && GBMConversionLoss.missingInputFeatures.length > 0 && (
                    <ul>
                        {GBMConversionLoss.missingInputFeatures.map((field) => (
                            <li key={field.name}>{`${field.name} (${field.type})`}</li>
                        ))}
                    </ul>
                )}
                <p>
                    Also, please note that tree models only support a <strong>single target</strong> of type{" "}
                    {GBMOutputFeatures.slice(0, -1).join(", ")} or {GBMOutputFeatures.at(-1)}.
                    {GBMConversionLoss.missingOutputFeatures &&
                        GBMConversionLoss.missingOutputFeatures.length > 0 &&
                        " Therefore, the following targets will be removed:"}
                </p>
                {GBMConversionLoss.missingOutputFeatures && GBMConversionLoss.missingOutputFeatures.length > 0 && (
                    <ul>
                        {GBMConversionLoss.missingOutputFeatures.map((field) => (
                            <li key={field.name}>{`${field.name} (${field.type})`}</li>
                        ))}
                    </ul>
                )}
            </Modal.Description>
        </ModelConfirmationModal>
    );
};

/**
 * The modal that is shown on the ModelBuilder page if the model type is switched to ECD and there are feature selection
 * incompatibilities.
 * @param props
 * @returns
 */
const ECDConfirmationModal = (props: { open: boolean; setShowConfirmationModal: (show: boolean) => void }) => {
    const ECDConversionLoss = useModelConversionLoss(ModelTypes.NEURAL_NETWORK);

    return (
        <ModelConfirmationModal
            open={props.open}
            setShowConfirmationModal={props.setShowConfirmationModal}
            modelType={ModelTypes.NEURAL_NETWORK}
        >
            <Modal.Description>
                <p>
                    <b>Warning:</b> Text output features are currently not supported when choosing Neural Network as the
                    model type.
                </p>
                <p>
                    {ECDConversionLoss.missingOutputFeatures &&
                        ECDConversionLoss.missingOutputFeatures.length > 0 &&
                        " Therefore, the following targets will be removed:"}
                </p>
                {ECDConversionLoss.missingOutputFeatures && ECDConversionLoss.missingOutputFeatures.length > 0 && (
                    <ul>
                        {ECDConversionLoss.missingOutputFeatures.map((field) => (
                            <li key={field.name}>{`${field.name} (${field.type})`}</li>
                        ))}
                    </ul>
                )}
            </Modal.Description>
        </ModelConfirmationModal>
    );
};

const LLMConfirmationModal = (props: { open: boolean; setShowConfirmationModal: (show: boolean) => void }) => {
    const LLMConversionLoss = useModelConversionLoss(ModelTypes.LARGE_LANGUAGE_MODEL);

    return (
        <ModelConfirmationModal
            open={props.open}
            setShowConfirmationModal={props.setShowConfirmationModal}
            modelType={ModelTypes.LARGE_LANGUAGE_MODEL}
        >
            <Modal.Description>
                {LLMConversionLoss.missingInputFeatures && LLMConversionLoss.missingInputFeatures.length > 0 && (
                    <>
                        <p>
                            <b>Warning:</b> Large Language Models only support text input features. Therefore, the
                            following input feature(s) will become inactive:
                        </p>
                        <ul>
                            {LLMConversionLoss.missingInputFeatures.map((field) => (
                                <li key={field.name}>{`${field.name} (${field.type})`}</li>
                            ))}
                        </ul>
                    </>
                )}
                {LLMConversionLoss.missingOutputFeatures && LLMConversionLoss.missingOutputFeatures.length > 0 && (
                    <>
                        <p>
                            Also, Large Language Models only support {LLMOutputFeatures.slice(0, -1).join(", ")} and{" "}
                            {LLMOutputFeatures.at(-1)} output features. Therefore, the following output feature(s) will
                            become inactive:
                        </p>
                        <ul>
                            {LLMConversionLoss.missingOutputFeatures.map((field) => (
                                <li key={field.name}>{`${field.name} (${field.type})`}</li>
                            ))}
                        </ul>
                    </>
                )}
            </Modal.Description>
        </ModelConfirmationModal>
    );
};

export { ECDConfirmationModal, GBMConfirmationModal, LLMConfirmationModal };
