import { JSONSchema7, JSONSchema7Type } from "json-schema";
import { CSSProperties } from "react";
import { Popup } from "semantic-ui-react";
import { defaultTextStyling } from "../../styling";

export const FieldAnnotations = (props: { path: string; defaultValue?: JSONSchema7Type; schema?: JSONSchema7 }) => {
    const { path, defaultValue, schema } = props;
    const annotations: Array<JSX.Element> = [];

    // If there is no default value, return no extra field annotations:
    if (defaultValue === undefined || schema === undefined) {
        return null;
    }

    // @ts-expect-error: parameter_metadata is a valid property of the schema, but the type definition is not up to date.
    const defaultValueReasoning = schema.parameter_metadata?.default_value_reasoning;
    // @ts-expect-error: parameter_metadata is a valid property of the schema, but the type definition is not up to date.
    const suggestedValues = schema.parameter_metadata?.suggested_values;
    // @ts-expect-error: parameter_metadata is a valid property of the schema, but the type definition is not up to date.
    const suggestedValuesReasoning = schema.parameter_metadata?.suggested_values_reasoning;

    // Create the default text element with appropriate spacing if there is also going to be a suggested values element,
    // and add a popup if default value reasoning is provided:
    const defaultTextElementStyle: CSSProperties = {
        ...defaultTextStyling,
        marginRight: suggestedValues ? "1em" : undefined,
        textDecorationLine: defaultValueReasoning ? "underline" : undefined,
        textDecorationStyle: defaultValueReasoning ? "dotted" : undefined,
        textUnderlineOffset: defaultValueReasoning ? "2px" : undefined,
    };
    let defaultTextElement = (
        <p key={`${path}_default_text_element`} style={defaultTextElementStyle}>
            Default: {String(defaultValue)}
        </p>
    );
    if (defaultValueReasoning) {
        defaultTextElement = (
            <Popup
                key={`${path}_default_text_element_popup`}
                position={"top center"}
                trigger={defaultTextElement}
                content={String(defaultValueReasoning)}
            />
        );
    }
    annotations.push(defaultTextElement);

    // Create the suggested values element if applicable:
    if (suggestedValues) {
        const suggestedValuesTextElementStyle: CSSProperties = {
            ...defaultTextStyling,
            textDecorationLine: suggestedValuesReasoning ? "underline" : undefined,
            textDecorationStyle: suggestedValuesReasoning ? "dotted" : undefined,
            textUnderlineOffset: suggestedValuesReasoning ? "2px" : undefined,
        };

        let suggestedValuesTextElement = (
            <p key={`${path}_suggested_values_text_element`} style={suggestedValuesTextElementStyle}>
                Suggested: {String(suggestedValues)}
            </p>
        );
        if (suggestedValuesReasoning) {
            suggestedValuesTextElement = (
                <Popup
                    key={`${path}_suggested_values_text_element`}
                    position={"top center"}
                    trigger={suggestedValuesTextElement}
                    content={String(suggestedValuesReasoning)}
                />
            );
        }
        annotations.push(suggestedValuesTextElement);
    }

    return <>{annotations}</>;
};
