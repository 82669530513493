import { Dispatch, memo, SetStateAction, useEffect, useState } from "react";
import { Form, Popup } from "semantic-ui-react";
import { engineCanTrainModels } from "../models/util";
import { EngineServiceType } from "../types/engineServiceType";
import { noFunctionCompare } from "../utils/reactUtils";
import { rawtextSearch } from "../utils/search";
import {
    engineStatusCircle,
    engineStatusSort,
    engineTrainingSort,
    getEngineDropdownItemWithStatus,
    getEngineDropdownSelectionWithStatus,
} from "./EngineStatus";
import { useEngineSchemaQuery, useEnginesQuery } from "./query";

export enum EngineSortOrder {
    STATUS = "status",
    TRAINING = "training",
}

// TODO: Not going to both to actually improve things here, just fixing auth for now.
export const useEnginesLMAO = (
    setEngines: Dispatch<SetStateAction<Engine[] | undefined>>,
    onEngineSelect: (engine: Engine) => void,
    setCurrentEngineID: (engineID: number) => void,
    setNoEnginesError: (error: string | null) => void,
    currentEngineID?: number,
    engineID?: number,
    requestedServiceTypes?: EngineServiceType[],
    sortOrder?: EngineSortOrder,
    onlyAllowTrainableEngines?: boolean, // Only allow batch engines or ray engines that are linked to lambda
    onlyAllowLLMEngines?: boolean,
) => {
    const serviceTypes = requestedServiceTypes || [EngineServiceType.RAY];
    let sortFn = sortOrder === EngineSortOrder.TRAINING ? engineTrainingSort : engineStatusSort;

    const { data: enginesResponse } = useEnginesQuery({
        refetchInterval: 2 * 1000, // 2 seconds`
    });
    useEffect(() => {
        // TODO(travis): hardcoding the engine name for now for new deployment flow. will generalize as a fast-follow
        const resEngines = enginesResponse?.engines
            .filter((engine) => serviceTypes.includes(engine.serviceType))
            .filter((engine) => (onlyAllowTrainableEngines ? engineCanTrainModels(engine) : true))
            .filter((engine) => (onlyAllowLLMEngines ? engine.preloadModelPath === "eachadea/vicuna-13b-1.1" : true))
            .sort(sortFn);
        setEngines(resEngines);

        if (resEngines?.length === 0) {
            if (onlyAllowTrainableEngines) {
                setNoEnginesError(
                    "No batch engines available. Only batch engines can be used for training. " +
                        "Ask an admin to create a batch engine on the engines page.",
                );
            } else if (onlyAllowLLMEngines) {
                setNoEnginesError("No LLM engines available. Ask an admin to create an LLM engine.");
            } else {
                setNoEnginesError(
                    `No engines available with filters [${serviceTypes.join(
                        ", ",
                    )}]. Ask an admin to create an engine on the engines page.`,
                );
            }
            return;
        }

        if (currentEngineID) {
            return;
        }

        if (engineID === undefined || resEngines?.find((engine) => engine.id === engineID) === undefined) {
            if (resEngines?.length !== undefined && resEngines.length > 0 && sortOrder === EngineSortOrder.TRAINING) {
                let firstEngine = resEngines[0];
                setCurrentEngineID(firstEngine.id);
                onEngineSelect(firstEngine);
            } else if (enginesResponse?.currentEngines != null) {
                if (onlyAllowTrainableEngines && enginesResponse.currentEngines.train) {
                    setCurrentEngineID(enginesResponse.currentEngines.train.id);
                    onEngineSelect(enginesResponse.currentEngines.train);
                } else if (enginesResponse.currentEngines.query) {
                    setCurrentEngineID(enginesResponse.currentEngines.query.id);
                    onEngineSelect(enginesResponse.currentEngines.query);
                }
            }
        } else if (engineID !== undefined) {
            setCurrentEngineID(engineID);
        }
    }, [currentEngineID]); // eslint-disable-line react-hooks/exhaustive-deps -- TODO: idk why, copied from old code
};

const EngineSelector = (props: {
    engineID: number | undefined;
    onEngineSelect: (engine: Engine) => void;
    label?: JSX.Element | null;
    validate?: boolean;
    disabled?: boolean;
    width?: string;
    serviceTypes?: EngineServiceType[];
    sortOrder?: EngineSortOrder;
    onlyAllowTrainableEngines?: boolean; // Only allow batch engines or ray engines that are linked to lambda
    onlyAllowLLMEngines?: boolean;
}) => {
    // Parent state:
    const {
        engineID,
        onEngineSelect,
        label,
        validate,
        disabled,
        width,
        serviceTypes,
        sortOrder,
        onlyAllowTrainableEngines,
        onlyAllowLLMEngines,
    } = props;

    // Local state:
    const [engines, setEngines] = useState<Engine[]>();
    const [templates, setTemplates] = useState<Map<number, string>>();
    const [currentEngineID, setCurrentEngineID] = useState<number>();
    const [noEnginesError, setNoEnginesError] = useState<string | null>(null);

    // We have to manage searchText and clear the dropdown selection when the search text changes
    // since dropdown library doesn't play nice with JSX.
    const [searchText, setSearchText] = useState<string>("");

    // Query state:
    const { data: engineSchema } = useEngineSchemaQuery({
        refetchInterval: false,
    });
    useEffect(() => {
        if (engineSchema) {
            setTemplates(new Map(engineSchema.templates.map((template) => [template.id, template.name])));
        }
    }, [engineSchema]);
    useEnginesLMAO(
        setEngines,
        onEngineSelect,
        setCurrentEngineID,
        setNoEnginesError,
        currentEngineID,
        engineID,
        serviceTypes,
        sortOrder,
        onlyAllowTrainableEngines,
        onlyAllowLLMEngines,
    );

    const currID = engineID || currentEngineID;

    const engineOptions: any[] = engines
        ? engines.map((engine, idx) => {
              const templateName = templates && engine.templateID ? templates.get(engine.templateID) : null;
              return {
                  rawtext: onlyAllowLLMEngines ? engine.preloadModelPath : engine.name,
                  text: (
                      <Popup
                          className={"transition-scale"}
                          trigger={getEngineDropdownItemWithStatus(engine, engine.id === currID, onlyAllowLLMEngines)}
                          position={"right center"}
                          content={templateName}
                      />
                  ),
                  status: engine.engineStatus,
                  description: engineStatusCircle(engine),
                  value: engine.id,
                  key: idx,
              };
          })
        : [];

    const engineIDMap = new Map(engines?.map((engine) => [engine.id, engine]));

    return (
        <Form.Select
            style={{ width }}
            error={noEnginesError || (validate && (currID === undefined || engineIDMap.get(currID) === undefined))}
            name="engine"
            label={label !== undefined ? label : "Engine"}
            options={engineOptions}
            value={currID}
            // @ts-ignore
            text={
                searchText
                    ? searchText
                    : // TODO: Unsafe access:
                      getEngineDropdownSelectionWithStatus(engineIDMap.get(currID!)!, onlyAllowLLMEngines)
            }
            onSearchChange={(e, data) => {
                // @ts-ignore
                setSearchText(e.target.value);
            }}
            placeholder="Engine"
            onClose={() => setSearchText("")}
            onChange={(_, { value }) => {
                setCurrentEngineID(value as number);
                const engine = engineIDMap.get(value as number);
                if (engine) {
                    onEngineSelect(engine);
                    setCurrentEngineID(engine.id);
                }
            }}
            disabled={disabled || !engines}
            search={rawtextSearch}
            selection
        />
    );
};

export default memo(EngineSelector, noFunctionCompare);
