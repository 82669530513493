import { useState } from "react";
import { getModelSchema, getModelType } from "../util";
import ModelGraph from "./ModelGraph";

function ModelGraphViewer(props: { model: Model }) {
    // Default to filtering MEDIUM impact parameters
    const [expectedImpactFilter, setExpectedImpactFilter] = useState(2);
    const modelType = getModelType(props.model.config);

    if (props.model.config) {
        return (
            <ModelGraph
                applyConfig={() => {}}
                config={props.model.config}
                modelType={modelType}
                readOnly={true}
                // audio_file_length_limit_in_s as undefined does not conform to JSONSchema7.
                schema={getModelSchema(props.model.config)}
                expectedImpactFilter={expectedImpactFilter}
                setExpectedImpactFilter={setExpectedImpactFilter}
            />
        );
    }

    return null;
}

export default ModelGraphViewer;
