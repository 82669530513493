import { Auth0Provider } from "@auth0/auth0-react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { withProfiler } from "@sentry/react";
import { Elements } from "@stripe/react-stripe-js";
import { StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useActiveFeatureFlags, usePostHog } from "posthog-js/react";
import { ReactNode, Suspense, lazy, useEffect, useState } from "react";
import { BrowserRouter, useNavigate, useSearchParams } from "react-router-dom";
import { RecoilRoot } from "recoil";
import Predibase from "./Predibase";
import { PredibaseURLState, welcomeUrl } from "./auth/auth0/utils";
import FeatureFlagsEditor from "./components/FeatureFlagsEditor";
import SubscriptionExpirationModal from "./components/GlobalHeader/SubscriptionExpirationModal";
import { PostHogPageviewTracker } from "./metrics/utils";
import { FeatureFlags, FeatureFlagsContext, getFeatureFlags } from "./utils/feature-flags";

import "github-markdown-css/github-markdown-light.css";
import "./App.css";

library.add(fas);

const queryClient = new QueryClient();

// Give Stripe the ability to monitor for fraud prevention
// NOTE: Force-cast env var to string to get rid of missing `apiKey` warning, see [1].
// [1]: https://stackoverflow.com/questions/63059353/invalid-value-for-stripe-apikey-should-be-a-string-you-specified-undefined
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);

const stripeElementOptions: StripeElementsOptions = {
    mode: "setup",
    setupFutureUsage: "off_session",
    currency: "usd",
};

const ReactQueryDevtoolsProductionLazyLoader = lazy(() =>
    import("@tanstack/react-query-devtools").then((d) => ({
        default: d.ReactQueryDevtools,
    })),
);

const Auth0ProviderWithRouterHookAccess = (props: { children: ReactNode }) => {
    const { children } = props;
    const navigate = useNavigate();
    const [, setSearchParams] = useSearchParams();
    const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
    const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
    const auth0Audience = process.env.REACT_APP_AUTH0_AUDIENCE;

    return (
        <Auth0Provider
            // TODO: Move to environment variables:
            domain={auth0Domain || ""}
            clientId={auth0ClientId || ""}
            authorizationParams={{
                // * NOTE: react-router does not expose the origin of the URL, so we use window.location.origin:
                redirect_uri: window.location.origin,
                audience: auth0Audience,
            }}
            onRedirectCallback={(incomingAppState: PredibaseURLState | undefined) => {
                // Preserve the invite token in the URL after the redirect:
                // * NOTE: After empirical testing, search params have to be set *after* the navigate call to persist:
                navigate(incomingAppState?.returnTo || welcomeUrl);
                if (incomingAppState && incomingAppState.inviteToken) {
                    setSearchParams({
                        inviteToken: encodeURIComponent(incomingAppState.inviteToken),
                    });
                }
            }}
            useRefreshTokens
            useRefreshTokensFallback
        >
            {children}
        </Auth0Provider>
    );
};

const App = () => {
    const [featureFlags, setFeatureFlags] = useState<FeatureFlags>(getFeatureFlags());
    const posthog = usePostHog();
    const posthogFeatureFlags = useActiveFeatureFlags();

    // Toggle for react query devtools in prod:
    // Source: https://twitter.com/tannerlinsley/status/1392857647037091849
    const [showRQDevtoolsProd, setShowRQDevtoolsProd] = useState(false);
    useEffect(() => {
        // @ts-ignore
        window.toggleRQDevtools = () => setShowRQDevtoolsProd((old) => !old);
    }, []);

    // Reload PostHog feature flags every 5 minutes:
    useEffect(() => {
        const refreshPHFFInterval = setInterval(
            () => {
                posthog?.reloadFeatureFlags();
            },
            1000 * 60 * 5,
        ); // 5 minutes

        return () => clearInterval(refreshPHFFInterval);
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <RecoilRoot>
                <FeatureFlagsContext.Provider value={{ featureFlags, posthogFeatureFlags, setFeatureFlags }}>
                    {/*
                     * IMPORTANT: we include Stripe globally so that their Fraud Prevention system (Radar) can catch
                     * bots more easily
                     */}
                    <Elements options={stripeElementOptions} stripe={stripePromise}>
                        {/* TODO: At some point we need to migrate to createBrowserRouter */}
                        <BrowserRouter>
                            {/*
                             * NOTE: Important to keep this order of components, as this gives Auth0Provider access to
                             * react-router's hooks:
                             */}
                            <Auth0ProviderWithRouterHookAccess>
                                <PostHogPageviewTracker />
                                <SubscriptionExpirationModal />
                                <Predibase />
                                <FeatureFlagsEditor />
                            </Auth0ProviderWithRouterHookAccess>
                        </BrowserRouter>
                    </Elements>
                </FeatureFlagsContext.Provider>
            </RecoilRoot>

            {/* React Query Devtools: */}
            {/* Automatically shows up in non-production builds: */}
            <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
            {/* Lazily loads devtools, even in production builds: */}
            {showRQDevtoolsProd ? (
                <Suspense fallback={null}>
                    <ReactQueryDevtoolsProductionLazyLoader initialIsOpen={false} buttonPosition="bottom-left" />
                </Suspense>
            ) : null}
        </QueryClientProvider>
    );
};

export default withProfiler(App);
