import { CSSProperties } from "react";
import { Form } from "semantic-ui-react";

import { handleLocalState } from "../models/create/forms/utils";

const Input = (props: {
    type?: string;
    path: string;
    title?: string;
    error: boolean;
    value?: string;
    description?: string;
    defaultValue: any;
    setConfig: (path: string, typedValue: any) => void;
    setLocalState: (localState: any, path: string) => void;
    schemaPath: string;
    style?: CSSProperties;
    readOnly?: boolean;
}) => (
    <Form.Input
        disabled={props.readOnly}
        type={props.type ? props.type : "string"}
        key={props.path + "_input"}
        name={props.title}
        style={{
            width: `${160 / 14}rem`,
            marginRight: "8px",
            ...props.style,
        }}
        placeholder={props.defaultValue ? String(props.defaultValue) : props.title}
        error={props.error}
        value={typeof props.value === "undefined" ? "" : String(props.value)}
        onChange={(event, { value }) => {
            handleLocalState(props.path, value, props.setLocalState, props.setConfig);
        }}
    />
);

export default Input;
