/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum role {
    ADMIN = 'ADMIN',
    USER = 'USER',
    SUPPORT = 'SUPPORT',
    READONLY = 'READONLY',
}
