import { AxiosInstance } from "axios";
import { useEffect, useState } from "react";
import { Loader, Message } from "semantic-ui-react";
import { useAuth0TokenOptions } from "../../data";
import { createV1APIServer } from "../../utils/api";
import { modelStatusDisabledCriteria, rocDisabledCriteria } from "../util";
import { getStaticViz, VizType } from "./util";

function ROCViewer(props: { model: Model; errorMessage?: string }) {
    const [links, setLinks] = useState<VizLink[]>();
    const [errorMessage, setErrorMessage] = useState<string | null>(props.errorMessage || null);

    const statusDisabledMsg = modelStatusDisabledCriteria(props.model);
    const specificDisabledMsg = rocDisabledCriteria(props.model);

    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    const [apiServer, setAPIServer] = useState<AxiosInstance | null>(null);
    useEffect(() => {
        const getAPIServer = async () => {
            const v1APIServer = await createV1APIServer(auth0TokenOptions);
            // NOTE: Whoever wrote the axios typings is a moron because the return type of axios.create is not
            // AxiosInstance -- it's a wrap function. And React will see that and treat it as a callback that
            // setState should directly call. FML.
            // See: [1], [2]:
            // [1]: https://github.com/axios/axios/issues/4365
            // [2]: https://stackoverflow.com/questions/64427195/calling-setstate-will-execute-the-function-value-instead-of-passing-it
            setAPIServer(() => v1APIServer);
        };
        getAPIServer();
    }, []);

    useEffect(() => {
        if (!(statusDisabledMsg || specificDisabledMsg) && !props.errorMessage && apiServer) {
            getStaticViz(apiServer, setLinks, setErrorMessage, props.model, VizType.ROC_CURVES_FROM_TEST_STATISTICS);
        }
    }, [props.model, props.errorMessage, apiServer]);

    if (statusDisabledMsg) {
        return <Message info content={statusDisabledMsg} />;
    } else if (specificDisabledMsg) {
        return <Message info content={specificDisabledMsg} />;
    }

    if (errorMessage) {
        return <Message negative>{errorMessage}</Message>;
    }

    if (!links) {
        return <Loader active />;
    }

    if (links.length === 0) {
        return (
            <Message negative>
                No ROC curves found for model. This model was likely trained before this feature was added.
                <br />
                <br />
                To view, train a new version.
            </Message>
        );
    }

    return (
        <>
            {links.map((link) => (
                <img key={link.name} src={link.url} alt={link.name} width="600px" />
            ))}
        </>
    );
}

export default ROCViewer;
