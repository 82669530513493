import { getTraceId } from "../../../../api/trace";
import { predifineMetricsPayload } from "../../../../api_generated";
import { APIResponse, Auth0TokenOptions } from "../../../../data";
import metrics from "../../../../metrics/metrics";
import { createV2APIServer, redirectIfSessionInvalid } from "../../../../utils/api";
import { getErrorMessage } from "../../../../utils/errors";

export const getHistoricalJobMetrics = async (jobUUID: string, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `/finetuning/jobs/${jobUUID}/metrics`;
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return v2APIServer
        .get<APIResponse<predifineMetricsPayload[]>>(endpoint)
        .then((res) => {
            return res.data.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};
