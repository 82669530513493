import React from "react";
import { Button } from "semantic-ui-react";

function ScopeUserToggle(props: {
    scopeUser: boolean;
    setScopeUser: React.Dispatch<React.SetStateAction<boolean>>;
    dataFunction?: (params: any) => void;
}) {
    return (
        <Button
            compact
            color={props.scopeUser ? "blue" : undefined}
            circular
            size={"mini"}
            basic
            onClick={() =>
                props.setScopeUser((x: boolean) => {
                    props.dataFunction && props.dataFunction({ scopeUser: !x });
                    return !x;
                })
            }
        >
            {props.scopeUser ? "ME" : "ALL"}
        </Button>
    );
}

export default ScopeUserToggle;
