import Ajv, { Schema, ValidateFunction } from "ajv";
import addFormats from "ajv-formats";
import keywords from "ajv-keywords";
import type { JSONSchema7 } from "json-schema";
import _ from "lodash";

// TODO: Set strict to true when Ludwig is updated to include ParameterMetadata JSON update!:
// By default supports draft-07 JSON spec:

export const createAJVValidator = () => {
    const ajv = keywords(
        new Ajv({
            strict: false,
            allErrors: true,
        }),
        "uniqueItemProperties",
    );
    addFormats(ajv);

    return ajv;
};

const ajv = createAJVValidator();

export const compileJSONSchema = (schema: Schema | JSONSchema7) => ajv.compile(schema);

export const validateConfigAgainstSchema = (validate?: ValidateFunction, config?: CreateModelConfig) => {
    if (!validate) {
        return {};
    }

    // Run the validation with the given schema and config:
    const configIsValid = validate(config);
    const skipKeywords = ["if", "oneOf"];

    if (configIsValid) {
        return {};
    }

    // If the config is invalid, then return a dict containing the invalid fields.
    // Interface used for parsing the list of errors returned by Ajv and removing duplicates, combining messages, etc.:

    let errorsMap: InvalidFields = {};
    /**
     * Example raw error from Ajv:
       {
        "instancePath": ".trainer.batch_size",
        "schemaPath": "#/properties/trainer/allOf/0/then/properties/batch_size/oneOf/0/type",
        "keyword": "type",
        "params": {
            "type": "integer"
        },
        "message": "must be integer"
       }
     */
    validate.errors?.forEach((error) => {
        if (skipKeywords.includes(error.keyword)) {
            return;
        }

        const parameterPath = error.instancePath.substring(1); // ignore first dot '.' in path
        const parameterName = error.instancePath.split(".").at(-1) as string;
        // Uses lastIndexOf in case nested parameters in the same branch of the config tree have the same name:
        const parameter_leaf_index_in_schema_path = error.schemaPath.lastIndexOf(parameterName);
        const commonSchemaPath = error.schemaPath.substring(parameter_leaf_index_in_schema_path);

        // For input/output features the array index of a particular feature should be replaced by the feature's name:
        let translatedParameterPath: string | null = null;
        if (parameterPath.includes("_features[")) {
            // Use regex to match where the array slicing appears in the name:
            const regexMatchForFeature = /features\[[0-9]+\]/.exec(parameterPath) as RegExpExecArray;
            const indexOfMatch = regexMatchForFeature.index;
            const indexAfterMatch = indexOfMatch + regexMatchForFeature[0].length;

            // Grab the feature's name
            const feature_name = (_.get(config, parameterPath.slice(0, indexAfterMatch)) as any)?.name;

            // Create a new path to the parameter with the array slice replaced with the feature name:
            const indexOfArraySlice = indexOfMatch + "features".length;
            translatedParameterPath = `${parameterPath.slice(
                0,
                indexOfArraySlice,
            )}.${feature_name}${parameterPath.slice(indexAfterMatch)}`;
        }

        // For each error, if we have seen it before and it is not a deeply nested duplicate, then update that entry in our map:
        if (parameterPath in errorsMap) {
            let em = errorsMap[parameterPath];
            if (em.schemaPaths.includes(commonSchemaPath)) {
                return;
            }
            em.schemaPaths.push(commonSchemaPath);
            em.errorKeywords.push(error.keyword);
            em.errorMessages.push(error.message as string);
        }
        // Otherwise, add a new entry to the map:
        else {
            const em = {
                name: parameterName,
                configPath: parameterPath,
                translatedConfigPath: translatedParameterPath,
                schemaPaths: [commonSchemaPath],
                errorKeywords: [error.keyword],
                errorMessages: [error.message as string],
            };
            errorsMap[parameterPath] = em;
        }
    });

    // Switch the keys of the map to be the translated paths if they exist (e.g. for features):
    let simpleInvalidErrorsMap: InvalidFields = {};
    Object.keys(errorsMap).forEach((key) => {
        const newKey =
            errorsMap[key].translatedConfigPath !== null
                ? (errorsMap[key].translatedConfigPath as string)
                : (errorsMap[key].configPath as string);
        simpleInvalidErrorsMap[newKey] = errorsMap[key];
    });

    return simpleInvalidErrorsMap;
};
