import { SEMANTIC_BLUE, SEMANTIC_GREY_DISABLED } from "../../../utils/colors";

const baseTitleStyling = {
    fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
    fontSize: "1rem",
    fontWeight: "700",
};

export const titleStyling = {
    ...baseTitleStyling,
    display: "block",
};

export const titleInlineStyling = {
    ...baseTitleStyling,
    display: "inline-block",
};

export const titlePrefixStyling = {
    color: SEMANTIC_GREY_DISABLED,
};

export const defaultTextStyling = {
    marginTop: `${10 / 14}rem`,
    color: SEMANTIC_BLUE,
    fontSize: `${12 / 14}rem`,
};

export enum dropDownWidths {
    normal = 160 / 14,
    wide = 320 / 14,
}
