import { SyntheticEvent } from "react";
import { DropdownProps, Form, Header } from "semantic-ui-react";

import metrics from "../../../metrics/metrics";
import { SEMANTIC_GREY_DISABLED } from "../../../utils/colors";
import { convertAtomicToNumber } from "../../../utils/config";
import { useConfigState, useDispatch } from "../store";

const MetricRoot = "Model.Config.Hyperopt";
const InputGroupScheduler = ".Scheduler";

// Taken from https://github.com/ray-project/ray/blob/137a1b12c3b31a3622fa5f721a05a64e9b559b05/python/ray/tune/result.py#L67
const trainingResultAttrOptions = [
    "training_iteration",
    "time_total_s",
    "timesteps_total",
    "mean_accuracy",
    "mean_loss",
];
let trainingResultAttrOptionsFormatted: any[] = trainingResultAttrOptions.map((result) => ({
    text: result,
    value: result,
}));

const hyperbandDocsLink = (
    <a
        href="https://docs.ray.io/en/latest/tune/api_docs/schedulers.html#asha-tune-schedulers-ashascheduler"
        target="_blank"
        rel="noreferrer"
    >
        Async HyperBand
    </a>
);

function SchedulerForm() {
    const dispatch = useDispatch();
    const { config, invalidFields } = useConfigState();
    const hyperoptConfig = config?.hyperopt;

    return (
        <>
            <Header className="header" as="h3">
                Scheduler
            </Header>
            <p style={{ marginBottom: "28px", color: SEMANTIC_GREY_DISABLED }}>
                Terminate bad trials early, pause trials, clone trials, and alter hyperparameters of a running trial.
                Predibase uses Ray Tune's {hyperbandDocsLink} scheduler.
            </p>

            <Form>
                <Form.Select
                    className={metrics.BLOCK_AUTO_CAPTURE}
                    name={"time_attr"}
                    label={"Time attribute"}
                    error={invalidFields["hyperopt/executor/scheduler/time_attr"]}
                    options={trainingResultAttrOptionsFormatted}
                    value={hyperoptConfig?.executor?.scheduler?.time_attr}
                    style={{ width: "197px" }}
                    placeholder="Select a valid attribute."
                    onChange={(event: SyntheticEvent, data: DropdownProps) => {
                        let selection = data.value as string;
                        dispatch({
                            type: "UPDATE_CONFIG_PROPERTY",
                            field: "hyperopt.executor.scheduler.time_attr",
                            value: selection,
                        });

                        const metricName = MetricRoot + InputGroupScheduler + ".TimeAttr";
                    }}
                />

                <Form.Input
                    className={metrics.BLOCK_AUTO_CAPTURE}
                    error={invalidFields["hyperopt/executor/scheduler/max_t"]}
                    name={"maxTimeSeconds"}
                    label={"Max time (seconds)"}
                    style={{ width: "197px" }}
                    value={hyperoptConfig?.executor?.scheduler?.max_t}
                    onClick={() => metrics.captureClick(MetricRoot + InputGroupScheduler + ".MaxT")}
                    onChange={(event) => {
                        const newValue = event.target.value;
                        const convertedValue = convertAtomicToNumber(newValue);
                        dispatch({
                            type: "UPDATE_CONFIG_PROPERTY",
                            field: "hyperopt.executor.scheduler.max_t",
                            value: convertedValue,
                        });
                    }}
                />

                <Form.Input
                    className={metrics.BLOCK_AUTO_CAPTURE}
                    error={invalidFields["hyperopt/executor/scheduler/grace_period"]}
                    name={"graceTimeSeconds"}
                    label={"Grace period time (seconds)"}
                    style={{ width: "197px" }}
                    value={hyperoptConfig?.executor?.scheduler?.grace_period}
                    onClick={() => metrics.captureClick(MetricRoot + InputGroupScheduler + ".GracePeriod")}
                    onChange={(event) => {
                        const newValue = event.target.value;
                        const convertedValue = convertAtomicToNumber(newValue);
                        dispatch({
                            type: "UPDATE_CONFIG_PROPERTY",
                            field: "hyperopt.executor.scheduler.grace_period",
                            value: convertedValue,
                        });
                    }}
                />

                <Form.Input
                    className={metrics.BLOCK_AUTO_CAPTURE}
                    error={invalidFields["hyperopt/executor/scheduler/reduction_factor"]}
                    name={"reductionFactor"}
                    label={"Reduction factor"}
                    style={{ width: "197px" }}
                    value={hyperoptConfig?.executor?.scheduler?.reduction_factor}
                    onClick={() => metrics.captureClick(MetricRoot + InputGroupScheduler + ".ReductionFactor")}
                    onChange={(event) => {
                        const newValue = event.target.value;
                        const convertedValue = convertAtomicToNumber(newValue);
                        dispatch({
                            type: "UPDATE_CONFIG_PROPERTY",
                            field: "hyperopt.executor.scheduler.reduction_factor",
                            value: convertedValue,
                        });
                    }}
                />
            </Form>
        </>
    );
}

export default SchedulerForm;
