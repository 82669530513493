import { AxiosError, AxiosResponse } from "axios";
import { getTraceId } from "../api/trace";
import { augmentDatasetRequest, augmentDatasetResponse } from "../api_generated";
import { Auth0TokenOptions } from "../data";
import metrics from "../metrics/metrics";
import { createV1APIServer, createV2APIServer, redirectIfSessionInvalid } from "../utils/api";
import { getErrorMessage } from "../utils/errors";
import { loadResultsDataset } from "../utils/results";

export const getDatasets = async (params?: PaginationParams, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = "datasets";
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .get(endpoint, { params: { limit: 999999, ...params } })
        .then((res: AxiosResponse<FetchDatasetsResponse>) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

// TODO: Possible problem with undefined?
export const getDatasetsForConnection = async (connection: Connection, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `connections/${connection?.id}?with_datasets=true`;
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .get(endpoint)
        .then((res: AxiosResponse<Connection>) => {
            return res.data.datasets;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const getConnections = async (params?: PaginationParams, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = "connections";
    const apiServer = await createV1APIServer(auth0TokenOptions);

    const config = params ? { params } : undefined;

    return apiServer
        .get(endpoint, config)
        .then((res: AxiosResponse<FetchConnectionsResponse>) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export interface DatasetPreviewParams {
    previewSize: number;
    refresh?: boolean;
}
export const defaultDatasetPreviewParams: DatasetPreviewParams = { previewSize: 10, refresh: false };
/**
 * Returns dataset preview data as a map with two keys:
 *      1) "samples": ResultsDataset
 *      2) "datasetProfile": DatasetProfile
 * datasetProfile may be undefined if there was no DatasetProfile available for the given dataset.
 * @param datasetID
 * @param params
 * @returns
 */
export const getDatasetPreview = async (
    datasetID: number,
    params?: DatasetPreviewParams,
    auth0TokenOptions?: Auth0TokenOptions,
) => {
    const queryParams = { ...defaultDatasetPreviewParams, ...params };
    const endpoint = `datasets/${datasetID}/preview`;
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .get(endpoint, { params: queryParams })
        .then((res: AxiosResponse) => {
            // TODO: really needs a type...
            // From the go code... fml:
            // type DatasetPreviewResponse struct {
            // 	DatasetProfile *dataset_profilev1.DatasetProfile `json:"datasetProfile"`
            // 	Samples        *string                           `json:"samples"`
            // }
            var retData: any = {};
            retData.samples = loadResultsDataset(JSON.parse(res.data.samples));
            // Legacy datasets may not have a datasetProfile.
            retData.datasetProfile = res.data?.datasetProfile;
            return retData;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const getDatasetThumbnails = async (
    queryID: number | undefined,
    datasetID: number | undefined,
    data: any,
    auth0TokenOptions?: Auth0TokenOptions,
) => {
    const endpoint = `datasets/get_thumbnails`;
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .post(
            endpoint,
            { queryID, datasetID, data },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            },
        )
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "POST",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const getConnectionSchemaConfig = async (connectionID: number, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `connections/${connectionID}/schema`;
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .get<ConnectionSchemaConfig>(endpoint)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const createDatasets = async (
    connectionID: number,
    datasetNames: {
        objectName: string;
        datasetName: any;
    }[],
    auth0TokenOptions?: Auth0TokenOptions,
) => {
    const endpoint = `datasets`;
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .post(endpoint, { connectionID, createDatasetsNames: datasetNames })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "POST",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const getFileDatasetNameValid = async (name: string, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = "datasets/check_file_dataset_exists/" + name;
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .head(endpoint)
        .then((res) => {
            return res.data;
        })
        .catch((error: AxiosError) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "HEAD",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);

            // TODO: ehhhhhhh....
            if (error.response?.status === 400) {
                throw Object.assign(new Error("dataset already exists with this name"), {
                    code: error.response.status,
                });
            }
            throw errorMsg;
        });
};

export const getConnection = async (connectionID: number, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `connections/${connectionID}`;
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .get(endpoint)
        .then((res: AxiosResponse<Connection>) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const deleteConnection = async (connectionID: number, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `connections/${connectionID}`;
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .delete(endpoint)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "DELETE",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const deleteDataset = async (datasetID: number, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `datasets/${datasetID}`;
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .delete(endpoint, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "DELETE",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

// TODO: Should be autogenerated:
export interface DatasetModelsResponse {
    dataset: Dataset;
}
// TODO: This terminology is so confusing... and yet direct from our gateway...
export const getDatasetModels = async (datasetID: number, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `datasets/${datasetID}/models`;
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .get(endpoint)
        .then((res: AxiosResponse<DatasetModelsResponse>) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const createExternalConnection = async (
    request: {
        id: number;
        extConnectionID: number;
    },
    auth0TokenOptions?: Auth0TokenOptions,
): Promise<any> => {
    const endpoint = "data/fields/connections/create";
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .post(endpoint, request, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "POST",
                endpoint,
                properties: request,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const deleteExternalConnection = async (
    request: {
        id: number;
    },
    auth0TokenOptions?: Auth0TokenOptions,
): Promise<any> => {
    const endpoint = "data/fields/connections/delete";
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .post(endpoint, request, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "POST",
                endpoint,
                properties: request,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const augmentDataset = async (
    datasetUUID: string,
    request: augmentDatasetRequest,
    auth0TokenOptions?: Auth0TokenOptions,
) => {
    const endpoint = `/datasets/${datasetUUID}/augment`;
    const apiServer = await createV2APIServer(auth0TokenOptions);

    return apiServer
        .post<augmentDatasetResponse>(endpoint, request)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "POST",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};
