import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosInstance } from "axios";
import axiosRetry from "axios-retry";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
    Accordion,
    Button,
    Checkbox,
    Divider,
    Dropdown,
    Form,
    Header,
    Icon,
    Message,
    Popup,
    Table,
} from "semantic-ui-react";
import { getTraceId } from "../../api/trace";
import EditDiv from "../../components/EditDiv";
import LiveTimeAgo from "../../components/LiveTimeAgo";
import { getStatusChipForModelsAndQueries } from "../../components/statusChip";
import CountUpTimer from "../../components/unsorted/history/CountUpTimer";
import { useAuth0TokenOptions } from "../../data";
import { track } from "../../metrics/june";
import metrics from "../../metrics/metrics";
import { GET_USER_CREDITS_QUERY_KEY } from "../../query";
import { USER_STATE } from "../../state/global";
import { ModelStatus } from "../../types/model/modelStatus";
import { createV1APIServer, redirectIfSessionInvalid } from "../../utils/api";
import {
    SEMANTIC_BLUE,
    SEMANTIC_GREY,
    SEMANTIC_GREY_ACTIVE,
    SEMANTIC_OLIVE,
    SEMANTIC_OLIVE_ACTIVE,
    SEMANTIC_WHITE,
} from "../../utils/colors";
import dayjsExtended, { durationAsString } from "../../utils/dayjs";
import { getErrorMessage } from "../../utils/errors";
import updateFavicon from "../../utils/favicon";
import { retrainModel } from "../data";
import { GET_MODEL_REPO_QUERY_KEY } from "../query";
import RetrainModelModal from "../repo/RetrainModelModal";
import {
    checkIfHyperoptEnabled,
    isCancelableStatus,
    isLLMModel,
    isTerminalStatus,
    isTrainingStatus,
    modelStatusFilter,
} from "../util";
import { CancelModelButtonWithModal } from "../utilApis";
import Deployment from "./deployment/ModelVersionDeployment";
import ModelProgressView from "./progress/ModelProgressView";

const modelDownloadFramework = "ludwig";

/**
 * A function to handle polling for the Model Export status.
 * @param endpoint the endpoint to use for polling
 * @param properties metric properties
 * @param setErrorMessage method to set the error message
 * @param setExporting method to set the exporting state
 */
const pollModelExport = (
    apiServer: AxiosInstance | null,
    endpoint: string,
    properties: any,
    setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>,
    setExporting: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    const interval = setInterval(() => {
        const stopInterval = () => {
            setExporting(false);
            clearInterval(interval);
        };

        setExporting(true);
        if (apiServer) {
            axiosRetry(apiServer, { retries: 2 });
        }
        apiServer
            ?.get(endpoint)
            .then((res) => {
                switch (res.data?.status) {
                    case "exporting":
                        // metrics.capture(`${metricName}.exporting`, properties);
                        break;
                    default:
                    case "failed":
                        stopInterval();
                        setErrorMessage(res.data?.failure_reason);
                        metrics.captureError("export_model_failure", res.data.error, properties);
                        break;
                    case "complete":
                        stopInterval();
                        window.open(res.data.url, "_blank");
                        break;
                }
            })
            .catch((error) => {
                stopInterval();
                const errorMsg = getErrorMessage(error) ?? "";
                setErrorMessage(errorMsg);
                metrics.captureError("api_error", errorMsg, {
                    method: "GET",
                    endpoint,
                    properties,
                    trace_id: getTraceId(error),
                });
                redirectIfSessionInvalid(errorMsg);
            });
    }, 2000);
};

/**
 * A function to start exporting a model and poll until it's complete.
 * @param endpoint the endpoint to use for polling
 * @param properties metric properties
 * @param setErrorMessage method to set the error message
 * @param setExporting method to set the exporting state
 */
const startNewModelExport = (
    apiServer: AxiosInstance | null,
    endpoint: string,
    properties: any,
    setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>,
    setExporting: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    setExporting(true);
    if (apiServer) {
        axiosRetry(apiServer, { retries: 2 });
    }
    apiServer
        ?.post(endpoint)
        .then((res) => {
            // TODO: Why is there error handling here?
            if (res.data?.error) {
                setErrorMessage(res.data.error);
                metrics.captureError("api_error", res.data.error, {
                    method: "POST",
                    endpoint,
                    properties,
                    trace_id: getTraceId(res.data.error),
                });
                return;
            }

            // Start polling for the export status
            pollModelExport(apiServer, endpoint, properties, setErrorMessage, setExporting);
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            setErrorMessage(errorMsg);
            metrics.captureError("api_error", errorMsg, {
                method: "POST",
                endpoint,
                properties,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
        })
        .finally(() => {
            setExporting(false);
        });
};

const downloadModel = (
    apiServer: AxiosInstance | null,
    setExporting: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>,
    modelVersionId?: number,
) => {
    const endpoint = `models/download/${modelVersionId}`;
    const properties = {
        id: modelVersionId,
    };

    setExporting(true);
    setErrorMessage("");
    if (apiServer) {
        axiosRetry(apiServer, { retries: 2 });
    }
    apiServer
        ?.get(endpoint, { "axios-retry": { retries: 2 } })
        .then((res) => {
            if (res.data?.error) {
                setErrorMessage(res.data.error);
                metrics.captureError("api_error", res.data.error, {
                    method: "GET",
                    endpoint,
                    properties,
                    trace_id: getTraceId(res),
                });
                return;
            }

            switch (res.data?.status) {
                case "complete":
                    // If the model is already exported; download it
                    window.open(res.data.url, "_blank");
                    break;
                case "exporting":
                    // If the model is exporting, poll for the export status
                    pollModelExport(apiServer, endpoint, properties, setErrorMessage, setExporting);
                    break;
                default: // If we're missing the model status, start the export process
                case "failed":
                    // Restart export process for previously failed job.
                    startNewModelExport(apiServer, endpoint, properties, setErrorMessage, setExporting);
                    break;
            }
        })
        .catch((error) => {
            // Model has never been exported; start the export process
            if (error?.response?.data?.error === "record not found") {
                startNewModelExport(apiServer, endpoint, properties, setErrorMessage, setExporting);
                return;
            }

            const errorMsg = getErrorMessage(error) ?? "";
            setErrorMessage(errorMsg);
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                properties,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
        })
        .finally(() => {
            setExporting(false);
        });
};

const setActiveModel = (
    apiServer: AxiosInstance | null,
    target: ActiveModel,
    getModelVersion: (startInterval?: boolean, initializeStream?: boolean) => void,
    setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>,
    modelVersionId?: number,
) => {
    const endpoint = "models/activate";
    const properties = {
        modelID: modelVersionId,
        fieldIDs: [target.field?.id],
    };

    if (apiServer) {
        axiosRetry(apiServer, { retries: 2 });
    }
    apiServer
        ?.post(endpoint, properties, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        })
        .then((res) => {
            if (res.data.errorMessage) {
                // TODO: Why is there error handling here?
                setErrorMessage(res.data.errorMessage);
                metrics.captureError("api_error", res.data.errorMessage, {
                    method: "POST",
                    endpoint,
                    properties,
                    // TODO: This error object doesn't exist?
                    // trace_id: getTraceId(error),
                });
            } else {
                getModelVersion(false, false);
            }
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            setErrorMessage(errorMsg);
            metrics.captureError("api_error", errorMsg, {
                method: "POST",
                endpoint,
                properties,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
        });
};

const getTargets = (modelVersion: Model) => {
    let features = modelVersion?.features || [];
    return features.filter((feature) => feature.type === "output" || feature.type === "multi");
};

const getActiveModelTargets = (modelVersion: Model) => {
    let activeFields = modelVersion?.activeFields || [];
    return activeFields.map((field) => field.field?.id);
};

const Targets = (props: {
    modelVersion: Model;
    getModelVersion: (startInterval?: boolean, initializeStream?: boolean) => void;
    setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>;
}) => {
    // Parent state:
    const { modelVersion, getModelVersion, setErrorMessage } = props;

    let activeTargets = getActiveModelTargets(modelVersion);

    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    const [apiServer, setAPIServer] = useState<AxiosInstance | null>(null);
    useEffect(() => {
        const getAPIServer = async () => {
            const v1APIServer = await createV1APIServer(auth0TokenOptions);
            // NOTE: Whoever wrote the axios typings is a moron because the return type of axios.create is not
            // AxiosInstance -- it's a wrap function. And React will see that and treat it as a callback that
            // setState should directly call. FML.
            // See: [1], [2]:
            // [1]: https://github.com/axios/axios/issues/4365
            // [2]: https://stackoverflow.com/questions/64427195/calling-setstate-will-execute-the-function-value-instead-of-passing-it
            setAPIServer(() => v1APIServer);
        };
        getAPIServer();
    }, []);

    return (
        <Form>
            {getTargets(modelVersion).map((target) => {
                const fullName = modelVersion?.dataset!.name + "." + target.field.name;
                let checked = activeTargets.indexOf(target.field.id) !== -1;
                if (checked) {
                    return (
                        <Popup
                            className={"transition-scale"}
                            position={"bottom right"}
                            key={fullName}
                            trigger={
                                <Form.Field
                                    key={target.id}
                                    control={Checkbox}
                                    slider
                                    name={fullName}
                                    label={
                                        <label
                                            style={{
                                                paddingLeft: "4.25rem",
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            {fullName}
                                        </label>
                                    }
                                    width={3}
                                    checked={checked}
                                />
                            }
                            content={
                                <span>
                                    Default model for this field cannot be deactivated. To change, navigate to a
                                    different model trained on [<b>{modelVersion?.dataset!.name}</b>] and activate it
                                    there.
                                </span>
                            }
                        />
                    );
                }

                return (
                    <Form.Field
                        key={target.id}
                        control={Checkbox}
                        slider
                        name={fullName}
                        label={<label style={{ paddingLeft: "4.25rem" }}>{fullName}</label>}
                        width={3}
                        onClick={() =>
                            setActiveModel(apiServer, target, getModelVersion, setErrorMessage, modelVersion?.id)
                        }
                        checked={checked}
                    />
                );
            })}
        </Form>
    );
};

const OverviewTable = (props: {
    modelVersion: Model;
    getModelVersion: (startInterval?: boolean, initializeStream?: boolean) => void;
    setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>;
    refreshFunc: Function;
}) => {
    // Parent state:
    const { modelVersion, getModelVersion, setErrorMessage, refreshFunc } = props;

    let TrainingDurationComponent: null | string | JSX.Element = null;
    if (modelVersion.trainingCreated) {
        const trainingCreated = dayjsExtended(new Date(modelVersion.trainingCreated));
        if (modelVersion.trainingCompleted) {
            const trainingCompleted = dayjsExtended(new Date(modelVersion.trainingCompleted));
            const duration = dayjsExtended.duration(trainingCompleted.diff(trainingCreated));
            TrainingDurationComponent = durationAsString(duration)!;
        } else {
            TrainingDurationComponent = <CountUpTimer start={trainingCreated} color={SEMANTIC_OLIVE} />;
        }
    }

    const created = dayjsExtended(new Date(modelVersion.created));
    let TotalDurationComponent: null | string | JSX.Element = null;
    if (modelVersion.completed) {
        const completed = dayjsExtended(new Date(modelVersion.completed));
        const duration = dayjsExtended.duration(completed.diff(created));
        TotalDurationComponent = durationAsString(duration)!;
    } else {
        TotalDurationComponent = <CountUpTimer start={created} color={SEMANTIC_OLIVE} />;
    }

    return (
        <div style={{ paddingLeft: "20px" }}>
            <Table basic={"very"} collapsing compact>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell style={{ borderTop: "none", color: SEMANTIC_GREY }}>Description</Table.Cell>
                        <EditDiv
                            ogText={modelVersion.description}
                            placeholder={"(No description)"}
                            endpoint={"models/version/" + modelVersion.id}
                            inputMinWidth={`${500 / 14}rem`}
                            fitted
                            textToRequest={(desc: string) => {
                                return {
                                    id: modelVersion.id,
                                    description: desc,
                                    starred: modelVersion.starred,
                                };
                            }}
                            callback={refreshFunc}
                            asTableCell={true}
                        />
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell style={{ borderTop: "none", color: SEMANTIC_GREY }}>Created</Table.Cell>
                        <Table.Cell style={{ borderTop: "none" }}>
                            <LiveTimeAgo fromDate={new Date(modelVersion.created)} />
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell style={{ borderTop: "none", color: SEMANTIC_GREY }}>
                            <Popup
                                className={"transition-scale"}
                                content={
                                    <span>
                                        Total Duration includes
                                        <br />
                                        <b style={{ color: SEMANTIC_GREY_ACTIVE }}>Queued</b>,&nbsp;
                                        <b style={{ color: SEMANTIC_OLIVE_ACTIVE }}>Preprocessing</b>,&nbsp;
                                        <b style={{ color: SEMANTIC_OLIVE_ACTIVE }}>Training</b>, and&nbsp;
                                        <b style={{ color: SEMANTIC_OLIVE_ACTIVE }}>Evaluating.</b>
                                    </span>
                                }
                                trigger={<span>Total Duration</span>}
                            />
                        </Table.Cell>
                        <Table.Cell style={{ borderTop: "none" }}>{TotalDurationComponent}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell style={{ borderTop: "none", color: SEMANTIC_GREY }}>
                            <Popup
                                className={"transition-scale"}
                                content={
                                    <span>
                                        Training Duration includes
                                        <br />
                                        <b style={{ color: SEMANTIC_OLIVE_ACTIVE }}>Preprocessing</b>&nbsp;and&nbsp;
                                        <b style={{ color: SEMANTIC_OLIVE_ACTIVE }}>Training</b>.
                                    </span>
                                }
                                trigger={<span>Training Duration</span>}
                            />
                        </Table.Cell>
                        <Table.Cell style={{ borderTop: "none" }}>{TrainingDurationComponent}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell style={{ borderTop: "none", color: SEMANTIC_GREY }}>Author</Table.Cell>
                        <Table.Cell style={{ borderTop: "none" }}>{modelVersion.user?.username}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell style={{ borderTop: "none", color: SEMANTIC_GREY }}>Dataset</Table.Cell>
                        <Table.Cell style={{ borderTop: "none" }}>
                            <Link to={"/data/datasets/" + modelVersion.datasetID}>{modelVersion.dataset?.name}</Link>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell style={{ borderTop: "none", color: SEMANTIC_GREY }}>Engine</Table.Cell>
                        <Table.Cell style={{ borderTop: "none" }}>{modelVersion.engine?.name}</Table.Cell>
                    </Table.Row>
                    {modelStatusFilter(modelVersion) && (
                        <Table.Row>
                            <Table.Cell style={{ borderTop: "none", color: SEMANTIC_GREY }}>Default for</Table.Cell>
                            <Table.Cell style={{ borderTop: "none" }}>
                                <Targets
                                    modelVersion={modelVersion}
                                    getModelVersion={getModelVersion}
                                    setErrorMessage={setErrorMessage}
                                />
                            </Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>
        </div>
    );
};

const ModelSummaryGrid = (props: {
    getModelVersion: (startInterval?: boolean, initializeStream?: boolean) => void;
    modelRepo: ModelRepo | undefined;
    modelVersion: Model;
    errorMessage: string | null;
    setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>;
    refreshFunc: Function;
    timeline?: ModelTimeline;
}) => {
    // User state:
    const [user] = useRecoilState(USER_STATE);

    // Parent state:
    const { modelRepo, modelVersion, errorMessage, setErrorMessage, refreshFunc, timeline } = props;

    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    // Local state:
    const [retrainModelOpen, setRetrainModelOpen] = useState(false);
    const [exporting, setExporting] = useState(false);
    const [timelineOpen, setTimelineOpen] = useState(!isTerminalStatus(modelVersion.status));

    useEffect(() => {
        if (isTrainingStatus(modelVersion.status)) {
            updateFavicon("processing");
        } else {
            switch (modelVersion.status) {
                case ModelStatus.STOPPING:
                case ModelStatus.CANCELED:
                case ModelStatus.FAILED:
                    updateFavicon("failed");
                    break;
                case ModelStatus.READY:
                case ModelStatus.DEPLOYED:
                    updateFavicon("success");
                    break;
                case ModelStatus.EXPLAINING:
                case ModelStatus.VISUALIZING:
                case ModelStatus.DEPLOYING:
                case ModelStatus.UNDEPLOYING:
                    updateFavicon("processing");
                    break;
            }
        }
    }, [modelVersion.status]);

    // Query state:
    const queryClient = useQueryClient();
    const { mutate: mutationFn } = useMutation({
        mutationFn: () => retrainModel(modelVersion, auth0TokenOptions),
        onSuccess: (res) => {
            window.open(`/models/version/${res.model.id}`, "_blank");
            queryClient.invalidateQueries({ queryKey: GET_USER_CREDITS_QUERY_KEY });
            queryClient.invalidateQueries({ queryKey: GET_MODEL_REPO_QUERY_KEY(modelRepo!.id) });
        },
        onError: (error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            setErrorMessage(errorMsg);
        },
    });

    const [apiServer, setAPIServer] = useState<AxiosInstance | null>(null);
    useEffect(() => {
        const getAPIServer = async () => {
            const v1APIServer = await createV1APIServer(auth0TokenOptions);
            // NOTE: Whoever wrote the axios typings is a moron because the return type of axios.create is not
            // AxiosInstance -- it's a wrap function. And React will see that and treat it as a callback that
            // setState should directly call. FML.
            // See: [1], [2]:
            // [1]: https://github.com/axios/axios/issues/4365
            // [2]: https://stackoverflow.com/questions/64427195/calling-setstate-will-execute-the-function-value-instead-of-passing-it
            setAPIServer(() => v1APIServer);
        };
        getAPIServer();
    }, []);

    if (!modelVersion) {
        return null;
    }

    return (
        <>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <div style={{ display: "flex" }}>
                    <Header as="h2">
                        <span>
                            Version {modelVersion.repoVersion}
                            {checkIfHyperoptEnabled(modelVersion.config) ? ": Hyperopt Run" : null}
                            {modelVersion.description ? (
                                <>
                                    &ensp;
                                    <span style={{ color: SEMANTIC_GREY, fontWeight: "normal" }}>
                                        {modelVersion.description}
                                    </span>
                                </>
                            ) : null}
                            {modelVersion.starred ? (
                                <>
                                    &nbsp;
                                    <Icon style={{ display: "inline-block" }} name={"star"} color={"yellow"} fitted />
                                </>
                            ) : null}
                        </span>
                        {getStatusChipForModelsAndQueries(modelVersion.status as ModelStatus, `${120 / 14}rem`)}
                    </Header>
                </div>
                <div style={{ display: "flex", marginLeft: "auto", maxHeight: `${36 / 14}rem` }}>
                    {modelStatusFilter(modelVersion) && (
                        <>
                            <Button
                                icon="download"
                                style={{ marginRight: `${8 / 14}rem`, minWidth: `${42 / 14}rem` }}
                                loading={exporting}
                                onClick={() => {
                                    // TODO: Move this to the BE process?
                                    // Purposely updated to match PostHog's suggested naming convention
                                    metrics.capture("model_exported", { export_type: modelDownloadFramework });
                                    user && track(user, "export_model", { export_type: modelDownloadFramework });
                                    downloadModel(apiServer, setExporting, setErrorMessage, modelVersion?.id);
                                }}
                            />
                            <Deployment
                                getModelVersion={props.getModelVersion}
                                modelRepo={modelRepo}
                                modelVersion={modelVersion}
                                setErrorMessage={setErrorMessage}
                            />
                            {isLLMModel(modelVersion.config) && (
                                <Link
                                    to={`/prompt?version=${modelVersion.repoVersion}&name=${encodeURIComponent(
                                        modelRepo?.modelName ?? "",
                                    )}&model=${encodeURIComponent(modelVersion.llmBaseModelName ?? "")}`}
                                    onClick={() => metrics.capture("Link.Prompt", { source: "model_version_page" })}
                                >
                                    <Button
                                        icon="terminal"
                                        content="Prompt"
                                        style={{
                                            marginRight: `${8 / 14}rem`,
                                            whiteSpace: "nowrap",
                                        }}
                                    />
                                </Link>
                            )}
                        </>
                    )}
                    <Dropdown
                        text="New Model"
                        button
                        loading={exporting}
                        direction="left"
                        style={{ color: SEMANTIC_WHITE, backgroundColor: SEMANTIC_BLUE, whiteSpace: "nowrap" }}
                    >
                        <Dropdown.Menu>
                            <Dropdown.Item
                                onClick={() => {
                                    metrics.capture("new_version_from_kebab");
                                    user && track(user, "new_version_from_kebab");
                                    window.open(`/models/edit/train/version/${modelVersion.id}`, "_blank");
                                }}
                            >
                                New model from #{modelVersion.repoVersion}
                            </Dropdown.Item>
                            {!(isLLMModel(modelVersion.config) && user?.isComputeLimited) && (
                                <Dropdown.Item onClick={() => setRetrainModelOpen(true)}>Retrain</Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                    {isCancelableStatus(modelVersion.status) && (
                        <CancelModelButtonWithModal
                            model={modelVersion}
                            setErrorMessage={setErrorMessage}
                            callback={refreshFunc}
                            trigger={<Button color={"black"} content={"Stop"} style={{ marginRight: 0 }} />}
                        />
                    )}
                </div>
            </div>
            {Boolean(errorMessage) && <Message error>{errorMessage}</Message>}
            <Divider hidden />
            <Accordion
                styled
                fluid
                className={"black-accordion"}
                defaultActiveIndex={-1}
                style={{ backgroundColor: "rgba(0, 0, 0, 0.03)" }}
                panels={[
                    {
                        key: "overview",
                        title: "Overview",
                        content: {
                            content: (
                                <OverviewTable
                                    modelVersion={modelVersion}
                                    getModelVersion={props.getModelVersion}
                                    setErrorMessage={setErrorMessage}
                                    refreshFunc={refreshFunc}
                                />
                            ),
                        },
                    },
                ]}
            />
            <Divider hidden style={{ margin: "8px" }} />
            <Accordion styled fluid className={"black-accordion"} style={{ backgroundColor: "rgba(0, 0, 0, 0.03)" }}>
                <Accordion.Title active={timelineOpen} index={0} onClick={() => setTimelineOpen((x) => !x)}>
                    <Icon name="dropdown" />
                    Training Timeline
                </Accordion.Title>
                <Accordion.Content active={timelineOpen}>
                    {timelineOpen && <ModelProgressView model={modelVersion} timeline={timeline} />}
                </Accordion.Content>
            </Accordion>
            <RetrainModelModal
                header={`Retrain Model #${modelVersion.repoVersion}`}
                engineID={modelVersion.engineID}
                mutationFn={mutationFn}
                open={retrainModelOpen}
                setOpen={setRetrainModelOpen}
            />
        </>
    );
};

export default ModelSummaryGrid;
