function updateFavicon(icon?: string) {
    const faviconElement = document.getElementById("favicon") as HTMLAnchorElement | null;

    if (faviconElement === null) {
        return;
    }

    switch (icon) {
        case "failed":
            faviconElement.href = "/favicon-failed.png";
            break;
        case "processing":
            faviconElement.href = "/favicon-processing.png";
            break;
        case "success":
            faviconElement.href = "/favicon-success.png";
            break;
        default:
            faviconElement.href = "/favicon.ico";
            break;
    }
}

export default updateFavicon;
