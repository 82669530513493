export const getTraceId = (error: any) => {
    const headers = error.response?.headers;

    if (!headers) {
        return "";
    }

    if (!("b3" in headers)) {
        return "";
    }

    const trace_id = headers["b3"];
    const split_trace_id = trace_id?.split("-");
    if (split_trace_id?.length > 0) {
        return split_trace_id[0];
    }

    return "";
};
