import type { JSONSchema7 } from "json-schema";
import _ from "lodash";
import React, { useMemo, useRef, useState } from "react";
import { Accordion, AccordionTitleProps, Grid, Header, Icon, Ref, Segment, Sticky } from "semantic-ui-react";
import { JSONSchemaForm } from "../../../components/forms";
import { ModelTypes } from "../../../types/model/modelTypes";
import { SEMANTIC_BLACK, SEMANTIC_GREY } from "../../../utils/colors";
import { findAllOfSchema, findAllOfSchemaIndex } from "../../../utils/jsonSchema";
import { getModelType } from "../../util";
import { useConfigState, useDispatch } from "../store";
import { filterParams, getLLMFormSchema } from "../utils";

import "./ParameterGuidance.css";

const filterFeatureSchemaOptions = (schema: any) => {
    const newSchema = _.cloneDeep(schema);
    delete newSchema?.properties?.column;
    delete newSchema?.properties?.name;
    delete newSchema?.properties?.type;
    delete newSchema?.properties?.tied;

    return newSchema;
};

export const generateDataStructure = (config?: CreateModelConfig, expectedImpactFilter?: number, schema?: any) => {
    const modelType = getModelType(config);
    let featureForms: Array<any> = [];

    /**
     * Commonly Used
     */
    if (modelType === ModelTypes.DECISION_TREE || modelType === ModelTypes.NEURAL_NETWORK) {
        // @TODO: Recursively filter through Schema
        // to generate commonly used
        const commonlyUsedSchema = {
            type: "object",
            properties: {
                trainer_learning_rate: {
                    ...schema?.properties?.trainer?.properties?.learning_rate,
                    parameter_metadata: {
                        ...schema?.properties?.trainer?.properties?.learning_rate?.parameter_metadata,
                        prefix: "Trainer",
                    },
                },
            },
        } as any;

        if (modelType === ModelTypes.NEURAL_NETWORK) {
            commonlyUsedSchema.properties.trainer_batch_size = {
                ...schema?.properties?.trainer?.properties?.batch_size,
                parameter_metadata: {
                    ...schema?.properties?.trainer?.properties?.batch_size?.parameter_metadata,
                    prefix: "Trainer",
                },
            };
            commonlyUsedSchema.properties.combiner_type = {
                ...schema?.properties?.combiner?.properties?.type,
                parameter_metadata: {
                    ...schema?.properties?.combiner?.properties?.type?.parameter_metadata,
                    prefix: "Combiner",
                },
            };
        }

        featureForms.push({
            title: "Commonly Used",
            path: "",
            schema: filterParams(commonlyUsedSchema, expectedImpactFilter),
            schemaPathPrefix: "",
        });
    }

    /**
     * LLM Specifically
     */
    // filterParams(getLLMFormSchema(), expectedImpactFilter),
    if (modelType === ModelTypes.LARGE_LANGUAGE_MODEL) {
        featureForms.push({
            title: "Large Language Model",
            path: "",
            schema: filterParams(getLLMFormSchema(), expectedImpactFilter),
            schemaPathPrefix: "",
        });
    }

    /**
     * Preprocessing
     */
    // @TODO: Pull Split out from Preprocessing?
    if (schema?.properties?.preprocessing) {
        //Remove Global Preprocessing Title Dataset Preprocessing Section
        schema.properties.preprocessing.title = undefined;

        featureForms.push({
            title: "Dataset Preprocessing",
            path: "preprocessing",
            schema: filterParams(schema?.properties?.preprocessing, expectedImpactFilter),
            schemaPathPrefix: "preprocessing",
        });
    }

    /**
     * Defaults Section
     */
    const featureTypeDefaults = {
        title: "Feature Type Defaults",
        path: "",
        children: [],
    };

    const featureTypes = new Map();
    config?.input_features?.forEach((feature) => {
        const featureType = featureTypes.has(feature.type) ? featureTypes.get(feature.type) : new Set();
        featureType.add("input");
        featureTypes.set(feature.type, featureType);
    });
    config?.output_features?.forEach((feature) => {
        const featureType = featureTypes.has(feature.type) ? featureTypes.get(feature.type) : new Set();
        featureType.add("output");
        featureTypes.set(feature.type, featureType);
    });

    featureTypes.forEach((featureType, name) => {
        let defaultFeatureSchema = _.get(schema, `properties.defaults.properties.${name}`, {
            properties: {},
        }) as JSONSchema7;

        if (featureType.has("input")) {
            if (_.has(defaultFeatureSchema, "properties.preprocessing.title")) {
                // @ts-expect-error may be undefined
                defaultFeatureSchema.properties.preprocessing.title = "preprocessing_options_for_input_features";
            }
            if (_.has(defaultFeatureSchema, "properties.encoder.title")) {
                // @ts-expect-error may be undefined
                defaultFeatureSchema.properties.encoder.title = "encoder_options_for_input_features";
            }
        } else {
            delete defaultFeatureSchema?.properties?.encoder;
            // Preprocessing Defaults only affect input features
            delete defaultFeatureSchema?.properties?.preprocessing;
        }

        if (featureType.has("output")) {
            if (_.has(defaultFeatureSchema, "properties.decoder.title")) {
                // @ts-expect-error may be undefined
                defaultFeatureSchema.properties.decoder.title = "decoder_options_for_output_features";
            }
            if (_.has(defaultFeatureSchema, "properties.loss.title")) {
                // @ts-expect-error may be undefined
                defaultFeatureSchema.properties.loss.title = "loss_options_for_output_features";
            }
        } else {
            delete defaultFeatureSchema?.properties?.decoder;
            delete defaultFeatureSchema?.properties?.loss;
        }

        const cleanedSchema = filterFeatureSchemaOptions(defaultFeatureSchema);

        const parameterGuidanceEntry: any = {
            prefix: "Feature Type Defaults",
            title: name,
            path: `defaults.${name}`,
            schema: filterParams(cleanedSchema, expectedImpactFilter),
            schemaPathPrefix: `defaults.properties.${name}`,
        };

        // @ts-expect-error
        featureTypeDefaults.children.push(parameterGuidanceEntry);
    });

    featureForms.push(featureTypeDefaults);

    /**
     * Input Features
     */
    const inputFeatures: any = {
        title: "Input Features",
        path: "",
        children: [],
    };

    config?.input_features?.forEach((inputFeature, index) => {
        const inputSchema = findAllOfSchema(schema?.properties?.input_features, inputFeature.type);
        const inputSchemaIndex = findAllOfSchemaIndex(schema?.properties?.input_features, inputFeature.type);
        if (!inputSchema) return;

        inputFeatures.children.push({
            prefix: "Input Features",
            title: inputFeature.name,
            featureType: inputFeature.type,
            path: `input_features[${index}]`,
            schema: filterParams(filterFeatureSchemaOptions(inputSchema), expectedImpactFilter),
            schemaPathPrefix: `input_features.items.allOf[${inputSchemaIndex}]`,
            featureIndex: index,
        });
    });

    featureForms.push(inputFeatures);

    /**
     * Output Features
     */
    const outputFeatures: any = {
        title: "Output Features",
        path: "",
        children: [],
    };
    config?.output_features?.forEach((outputFeature, index) => {
        const outputSchema = findAllOfSchema(schema?.properties?.output_features, outputFeature.type);
        const outputSchemaIndex = findAllOfSchemaIndex(schema?.properties?.output_features, outputFeature.type);
        if (!outputSchema) return;

        outputFeatures.children.push({
            prefix: "Output Features",
            title: outputFeature.name,
            featureType: outputFeature.type,
            path: `output_features[${index}]`,
            schema: filterParams(filterFeatureSchemaOptions(outputSchema), expectedImpactFilter),
            schemaPathPrefix: `output_features.items.allOf[${outputSchemaIndex}]`,
            featureIndex: index,
        });
    });
    featureForms.push(outputFeatures);

    /**
     * Combiner
     */
    if (schema?.properties?.combiner && modelType === ModelTypes.NEURAL_NETWORK) {
        featureForms.push({
            title: "Combiner",
            path: "combiner",
            schema: filterParams(schema?.properties?.combiner, expectedImpactFilter),
            schemaPathPrefix: `combiner`,
        });
    }

    /**
     * Trainer
     */
    const trainerForms: any = {
        title: "Trainer",
        children: [],
    };

    const {
        optimizer = undefined,
        learning_rate_scheduler = undefined,
        ...restOfTrainerSchema
    } = schema?.properties?.trainer.properties;
    if (restOfTrainerSchema) {
        trainerForms.children.push({
            prefix: "Trainer",
            title: "General",
            path: "trainer.general",
            schema: filterParams({ properties: restOfTrainerSchema }, expectedImpactFilter),
            schemaPathPrefix: `trainer`,
        });
    }
    if (learning_rate_scheduler) {
        trainerForms.children.push({
            prefix: "Trainer",
            title: "Learning Rate Scheduler",
            path: "trainer.learning_rate_scheduler",
            schema: filterParams(learning_rate_scheduler, expectedImpactFilter),
            schemaPathPrefix: `trainer.properties.learning_rate_scheduler`,
        });
    }
    if (optimizer) {
        trainerForms.children.push({
            prefix: "Trainer",
            title: "Optimizer",
            path: "trainer.optimizer",
            schema: filterParams(optimizer, expectedImpactFilter),
            schemaPathPrefix: `trainer.properties.optimizer`,
        });
    }

    featureForms.push(trainerForms);

    return featureForms;
};

const TreeNavigation = (props: {
    featureForms: any[];
    onUpdateActiveForm: (activeForm: any) => void;
    activeFormPath: string;
}) => {
    const [activeIndex, setActiveIndex] = useState(-1);
    const fontStyle = (childPath: string) =>
        childPath.includes("input_features") || childPath.includes("output_features") ? "italic" : "normal";
    const handleAccordionTitleClick = (event: React.MouseEvent<HTMLDivElement>, data: AccordionTitleProps) => {
        const { index } = data;
        const newIndex = activeIndex === index ? -1 : Number(index);

        setActiveIndex(newIndex);
    };
    const Title = (title: string, idx: number) => (
        <Accordion.Title
            style={{ paddingLeft: "1.714rem" }}
            active={activeIndex === idx}
            index={idx}
            onClick={handleAccordionTitleClick}
        >
            <Icon name={idx === activeIndex ? "chevron down" : "chevron right"} />
            {title}
        </Accordion.Title>
    );

    const PanelButton = (contentSource: any, style?: any) => (
        <button
            className="button-reset tree-navigation-button"
            style={{
                ...style,
                width: "100%",
                textAlign: "left",
                backgroundColor: contentSource.path === props.activeFormPath ? "rgb(33, 133, 208, 0.2)" : undefined,
                fontWeight: contentSource.path === props.activeFormPath ? "bold" : "normal",
                fontStyle: fontStyle(contentSource.path),
                color: SEMANTIC_BLACK,
            }}
            onClick={() => props.onUpdateActiveForm(contentSource)}
        >
            {contentSource.title}
        </button>
    );

    const rootPanels = props.featureForms.map((formFeature, idx) => {
        if (!formFeature.children) {
            return PanelButton(formFeature, { padding: "0.3572rem 1.7857rem 0.3572rem 1.714rem" });
        }

        return {
            key: formFeature.title,
            title: Title(formFeature.title, idx),
            content: (
                <Accordion.Content style={{ padding: "0 0 0" }}>
                    <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
                        {formFeature.children.map((child: any) => (
                            <li key={child.title}>
                                {child.schema
                                    ? PanelButton(child, { padding: "0.3572rem 1.7857rem 0.3572rem 4.2857rem" })
                                    : child.title}
                            </li>
                        ))}
                    </ul>
                </Accordion.Content>
            ),
        };
    });

    return <Accordion defaultActiveIndex={0} panels={rootPanels} />;
};

const isInViewport = (element: HTMLElement) => {
    const rect = element.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
};

const ParameterGuidance = (props: {
    expectedImpactFilter: number;
    setExpectedImpactFilter: (expectedImpactFilter: number) => void;
}) => {
    const dispatch = useDispatch();
    const { config, configKey, invalidFields, schema } = useConfigState();
    const navigationRef = useRef(null);
    const featureForms = useMemo(
        () => generateDataStructure(config, props.expectedImpactFilter, schema),
        [config, schema, props.expectedImpactFilter],
    ); // eslint-disable-line react-hooks/exhaustive-deps
    const [activeForm, setActiveForm] = useState(featureForms[0]);
    const formTopRef = useRef(null);

    const updateActiveForm = (activeForm: any) => {
        setActiveForm(activeForm);
        // @ts-expect-error
        if (formTopRef !== null && !isInViewport(formTopRef.current)) {
            // @ts-expect-error
            formTopRef.current.scrollIntoView();
        }
    };

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={3} style={{ paddingRight: `${16 / 14}rem` }}>
                    <Ref innerRef={navigationRef}>
                        <Segment
                            style={{
                                borderRadius: 0,
                                height: "100%",
                                padding: "1.714rem 0 0 0",
                                justifyContent: "flex-start",
                            }}
                            placeholder
                        >
                            <Sticky context={navigationRef} pushing>
                                <TreeNavigation
                                    featureForms={featureForms}
                                    onUpdateActiveForm={updateActiveForm}
                                    activeFormPath={activeForm.path}
                                />
                            </Sticky>
                        </Segment>
                    </Ref>
                </Grid.Column>
                <Grid.Column width={13} style={{ paddingLeft: 0 }}>
                    <Segment raised style={{ padding: `${24 / 14}rem` }}>
                        <div ref={formTopRef}></div>
                        <Header
                            as="h2"
                            style={{
                                marginTop: 0,
                                marginBottom: `${2 / 14}rem`,
                                fontSize: `${22 / 14}rem`,
                            }}
                        >
                            {activeForm.prefix && `${activeForm.prefix} > `}
                            {activeForm.title}
                        </Header>
                        {(activeForm.prefix === "Input Features" || "Output Features") && (
                            <p
                                style={{
                                    marginTop: 0,
                                    marginBottom: `${24 / 14}rem`,
                                    fontSize: `${16 / 14}rem`,
                                    color: SEMANTIC_GREY,
                                }}
                            >
                                {activeForm.featureType}
                            </p>
                        )}
                        <JSONSchemaForm
                            data={config}
                            key={configKey}
                            setConfig={(path, value) =>
                                dispatch({ type: "UPDATE_CONFIG_PROPERTY", field: path, value: value })
                            }
                            invalidFields={invalidFields}
                            schema={activeForm.schema}
                            schemaPathPrefix={activeForm.schemaPathPrefix}
                            featureIndex={activeForm.featureIndex}
                        />
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default ParameterGuidance;
