/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum deploymentQuantization {
    NONE = 'none',
    BITSANDBYTES = 'bitsandbytes',
    BITSANDBYTES_NF4 = 'bitsandbytes-nf4',
    BITSANDBYTES_FP4 = 'bitsandbytes-fp4',
    FP8 = 'fp8',
    GPTQ = 'gptq',
    AWQ = 'awq',
}
