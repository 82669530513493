import { SettingsFlow } from "@ory/kratos-client";
import { UpdateSettingsFlowBody } from "@ory/kratos-client/api";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Grid, Header, Loader, Message } from "semantic-ui-react";
import { kratosSdk, useSDKError } from "../../utils/kratos";
import KratosSigninForm from "./form/KratosSigninForm";

const KratosAccountSettingsView = () => {
    const [flow, setFlow] = useState<SettingsFlow | null>(null);
    const { search } = useLocation();
    const params = useMemo(() => new URLSearchParams(search), [search]);

    const navigate = useNavigate();

    // Get the flow based on the flowId in the URL (.e.g redirect to this page after flow initialized)
    const getFlow = useCallback(
        (flowId: string) =>
            kratosSdk
                // the flow data contains the form fields, error messages and csrf token
                .getSettingsFlow({ id: flowId })
                .then(({ data: flow }) => setFlow(flow))
                .catch(sdkErrorHandler),
        [],
    );

    // initialize the sdkError for generic handling of errors
    const sdkErrorHandler = useSDKError(getFlow, setFlow, "/settings", true);

    const createFlow = () => {
        kratosSdk
            // create a new settings flow
            // the flow contains the form fields, error messages and csrf token
            // depending on the Ory Network project settings, the form fields returned may vary
            .createBrowserSettingsFlow()
            .then(({ data: flow }) => {
                // Update URI query params to include flow id
                params.set("flow", flow.id);
                navigate({ search: [params].toString() }, { replace: true });
                // Set the flow data
                setFlow(flow);
            })
            .catch(sdkErrorHandler);
    };

    // submit any of the settings form data to Ory
    const submitFlow = (body: UpdateSettingsFlowBody) => {
        // something unexpected went wrong and the flow was not set
        if (!flow) return navigate("/account/settings", { replace: true });

        return (
            kratosSdk
                // submit the form data the user provided to Ory
                .updateSettingsFlow({ flow: flow.id, updateSettingsFlowBody: body })
                .then(({ data: flow }) => {
                    setFlow(flow);
                })
                .catch(sdkErrorHandler)
        );
    };

    useEffect(() => {
        // we might redirect to this page after the flow is initialized, so we check for the flowId in the URL
        const flowId = params.get("flow");
        // the flow already exists
        if (flowId) {
            getFlow(flowId).catch(createFlow); // if for some reason the flow has expired, we need to get a new one
            return;
        }
        createFlow();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <Grid textAlign="center" style={{ height: "100vh" }} verticalAlign="middle">
                <Grid.Column style={{ maxWidth: 450 }}>
                    <Header as="h2" color="grey" textAlign="center">
                        Change password
                    </Header>
                    {flow ? (
                        <KratosSigninForm
                            submitLabel="Save"
                            action={flow.ui.action}
                            fields={flow.ui.nodes.filter(
                                (field) => field.group === "default" || field.group === "password",
                            )}
                            messages={flow.ui.messages}
                            onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                                event.preventDefault();
                                const data = new FormData(event.currentTarget);
                                const flowBody = {} as UpdateSettingsFlowBody;

                                for (const pair of data.entries()) {
                                    // @ts-expect-error
                                    flowBody[pair[0]] = pair[1];
                                }
                                flowBody.method = "password";
                                return submitFlow(flowBody);
                            }}
                        />
                    ) : (
                        <Loader active inline="centered" />
                    )}
                    <Message>
                        <Link to="/">Return to Home</Link>
                    </Message>
                </Grid.Column>
            </Grid>
        </div>
    );
};

export default KratosAccountSettingsView;
