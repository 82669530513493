import { useQueryClient } from "@tanstack/react-query";
import { AxiosInstance, AxiosResponse } from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
    Button,
    Divider,
    DropdownProps,
    Form,
    Header,
    Icon,
    Input,
    Loader,
    Message,
    Modal,
    Popup,
    Select,
} from "semantic-ui-react";
import { getTraceId } from "../../../api/trace";
import Chip from "../../../components/Chip";
import CopyButton from "../../../components/CopyButton";
import { getStatusChipForModelsAndQueries } from "../../../components/statusChip";
import { useAuth0TokenOptions } from "../../../data";
import { track } from "../../../metrics/june";
import metrics, { BLOCK_POSTHOG_AUTO_CAPTURE } from "../../../metrics/metrics";
import { GET_USER_CREDITS_QUERY_KEY } from "../../../query";
import { USER_STATE } from "../../../state/global";
import { CONTACT_MODAL_STATE } from "../../../state/subscription";
import { DeploymentStatus } from "../../../types/deployment/deploymentStatus";
import { EngineServiceType } from "../../../types/engineServiceType";
import { ModelTypes } from "../../../types/model/modelTypes";
import { createV1APIServer, getDocsHome, redirectIfSessionInvalid } from "../../../utils/api";
import {
    SEMANTIC_BLUE,
    SEMANTIC_GREY,
    SEMANTIC_GREY_DISABLED,
    SEMANTIC_RED,
    SEMANTIC_RED_ACTIVE,
} from "../../../utils/colors";
import {
    isModelVersionLatestFailedAttempt,
    isTerminalStatus as isTerminalDeploymentStatus,
} from "../../../utils/deployments";
import { getErrorMessage } from "../../../utils/errors";
import { FeatureFlagsContext } from "../../../utils/feature-flags";
import { getComputeSubscriptionStatus } from "../../../utils/subscription";
import { GET_CLASSIC_DEPLOYMENT_QUERY_KEY, useClassicDeploymentByNameQuery } from "../../query";
import { getModelType, isLLMModel } from "../../util";

import { CurrentUser, UserContext } from "../../../types/user";
import "./ModelVersionDeployment.css";

// TODO: omg...
const createModelDeployment = (
    apiServer: AxiosInstance | null,
    getModelVersion: (startInterval?: boolean, initializeStream?: boolean) => void,
    setDeployment: React.Dispatch<React.SetStateAction<ModelDeployment | undefined>>,
    setDeploying: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorMessage: React.Dispatch<React.SetStateAction<string | undefined>>,
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
    deploymentName: string,
    engineName: string,
    comment?: string,
    modelVersionId?: number,
) => {
    const endpoint = "deployments";
    const properties = {
        modelID: modelVersionId,
        deploymentName: deploymentName,
        // For Serving V0, we only show the button if a model is not deployed.
        // It's possible that user has already deployed another model using
        // the same deployment name. For now, replace it. With V1, let them decide.
        replace: true,
        ifNotExists: false,
        engineName: engineName,
        comment: comment,
    };

    setDeploying(true);
    return apiServer
        ?.post(endpoint, properties, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        })
        .then((res) => {
            if (res.data.errorMessage) {
                setErrorMessage(res.data.errorMessage);
                metrics.captureError("api_error", res.data.errorMessage, {
                    method: "POST",
                    endpoint,
                    properties,
                    trace_id: getTraceId(res),
                });
                setDeploying(false);
                return;
            }
            setShowModal(false);
            getModelVersion(false, false);
            setDeployment(res.data);
            // TODO: WTF:
            // Deploying models uses credits; invalidate the credit cache
            // noinspection JSIgnoredPromiseFromCall
            useQueryClient().invalidateQueries({ queryKey: GET_USER_CREDITS_QUERY_KEY });
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            setErrorMessage(errorMsg);
            metrics.captureError("api_error", errorMsg, {
                method: "POST",
                endpoint,
                properties,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            setDeploying(false);
        });
};

// TODO: omg...
const deleteModelDeployment = (
    apiServer: AxiosInstance | null,
    getModelVersion: (startInterval?: boolean, initializeStream?: boolean) => void,
    setDeleting: React.Dispatch<React.SetStateAction<boolean>>,
    setDeployment: React.Dispatch<React.SetStateAction<ModelDeployment | undefined>>,
    setDeploymentError: React.Dispatch<React.SetStateAction<string | undefined>>,
    setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>,
    deploymentName?: string,
    modelVersionId?: number,
) => {
    const endpoint = "deployments/" + deploymentName;
    const properties = {
        modelID: modelVersionId,
        deploymentName: deploymentName,
    };

    return apiServer
        ?.delete(endpoint)
        .then((res: AxiosResponse<ModelDeployment | any>) => {
            if (res.data.errorMessage) {
                setErrorMessage(res.data.errorMessage);
                metrics.captureError("api_error", res.data.errorMessage, {
                    method: "DELETE",
                    endpoint,
                    properties,
                    trace_id: getTraceId(res),
                });
            } else {
                setDeployment(res.data);
                setDeploymentError(undefined);
                setDeleting(true);
            }
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            setErrorMessage(errorMsg);
            metrics.captureError("api_error", errorMsg, {
                method: "DELETE",
                endpoint,
                properties,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
        })
        .finally(() => {
            getModelVersion(false, false);
        });
};

const LORAXLink = (props: { userContext?: UserContext | CurrentUser }) => {
    const url = "https://predibase.github.io/lorax/";

    return (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a
            href={url}
            target="_blank"
            rel="noopener"
            onClick={() => {
                metrics.capture("lorax", {
                    url,
                    clickSource: "deployment-modal",
                });
                props.userContext &&
                    track(props.userContext, "lorax", {
                        url,
                        clickSource: "deployment-modal",
                    });
            }}
        >
            LoRAX
        </a>
    );
};

const PromptSDKLink = (props: { userContext?: UserContext | CurrentUser }) => {
    const url = getDocsHome() + "/user-guide/inference/serverless_endpoints#prompt-fine-tuned-models-with-lorax";

    return (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a
            href={url}
            target="_blank"
            rel="noopener"
            onClick={() => {
                metrics.capture("docs", {
                    url,
                });
                props.userContext &&
                    track(props.userContext, "docs", {
                        url,
                    });
            }}
        >
            docs
        </a>
    );
};

const DeploySDKLink = (props: { userContext?: UserContext | CurrentUser }) => {
    const url = getDocsHome() + "/user-guide/inference/dedicated_deployments";

    return (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a
            href={url}
            target="_blank"
            rel="noopener"
            onClick={() => {
                metrics.capture("docs", {
                    url,
                });
                props.userContext &&
                    track(props.userContext, "docs", {
                        url: url,
                    });
            }}
        >
            docs
        </a>
    );
};

const DeployLLMCreateModal = (props: {
    modelRepo: ModelRepo | undefined;
    model: Model;
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const [userContext] = useRecoilState(USER_STATE);
    const [, setContactModal] = useRecoilState(CONTACT_MODAL_STATE);

    return (
        <Modal open={props.showModal} onClose={() => props.setShowModal(false)}>
            <Modal.Header>Run Inference on this model version</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Header as="h3">Serverless Inference</Header>
                    <p>
                        Ready to prompt your model instantly? With Predibase Serverless Endpoints, you don't need to
                        create a new model deployment. From the{" "}
                        <Link
                            to={`/prompt?version=${props.model.repoVersion}&name=${encodeURIComponent(
                                props.modelRepo?.modelName ?? "",
                            )}&model=${encodeURIComponent(props.model.llmBaseModelName ?? "")}`}
                        >
                            Prompt
                        </Link>{" "}
                        page, run inference on your fine-tuned model immediately using serverless{" "}
                        <LORAXLink userContext={userContext} />.
                    </p>
                    <p>
                        You can also use the Predibase SDK to prompt your model programmatically. Read our{" "}
                        <PromptSDKLink userContext={userContext} />
                        &nbsp;to learn more.
                    </p>
                    <Header as="h3">Private Serverless Deployment</Header>
                    <p>
                        Want to provision a private serverless deployment for this model?
                        {userContext?.isComputeLimited && (
                            <>
                                <button className="button-reset" onClick={() => setContactModal(true)}>
                                    Upgrade to the SaaS developer
                                </button>
                                tier to deploy this model.
                            </>
                        )}
                        &nbsp;Read our <DeploySDKLink userContext={userContext} /> to learn how you can deploy your LLM
                        on a private serverless instance.
                    </p>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button content="Close" onClick={() => props.setShowModal(false)} />
            </Modal.Actions>
        </Modal>
    );
};

const DeploymentECDCreateModal = (props: {
    getModelVersion: (startInterval?: boolean, initializeStream?: boolean) => void;
    setDeployment: React.Dispatch<React.SetStateAction<ModelDeployment | undefined>>;
    setDeploying: React.Dispatch<React.SetStateAction<boolean>>;
    modelVersion: Model;
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const [formError, setFormError] = useState<string>();
    const [deploymentName, setDeploymentName] = useState("");
    // TODO: Should this be set to a default based on the Helm charts?
    const [servingEngine, setServingEngine] = useState("default_serving_engine");
    const [servingEngines, setServingEngines] = useState([]);
    const [defaultEngineSchema, setDefaultEngineSchema] = useState("");
    const [defaultEngineIsCPU, setDefaultEngineIsCPU] = useState(true);
    const [loading, setLoading] = useState(true);
    const [comment, setComment] = useState("");
    const [userContext] = useRecoilState(USER_STATE);
    const { featureFlags } = useContext(FeatureFlagsContext);
    const restricted =
        getComputeSubscriptionStatus(userContext?.isComputeLimited, featureFlags["Free Trial Testing"]) ||
        userContext?.isExpired;

    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    const [apiServer, setAPIServer] = useState<AxiosInstance | null>(null);
    useEffect(() => {
        const getAPIServer = async () => {
            const v1APIServer = await createV1APIServer(auth0TokenOptions);
            // NOTE: Whoever wrote the axios typings is a moron because the return type of axios.create is not
            // AxiosInstance -- it's a wrap function. And React will see that and treat it as a callback that
            // setState should directly call. FML.
            // See: [1], [2]:
            // [1]: https://github.com/axios/axios/issues/4365
            // [2]: https://stackoverflow.com/questions/64427195/calling-setstate-will-execute-the-function-value-instead-of-passing-it
            setAPIServer(() => v1APIServer);
        };
        getAPIServer();
    }, []);

    const createDeployment = () => {
        if (!deploymentName || deploymentName.length === 0) {
            setFormError("Please enter a deployment name.");
            return;
        }

        var deploymentNameRegex = /^[\w\-]+$/;
        if (!deploymentNameRegex.test(deploymentName)) {
            setFormError(
                "Deployment names can only contain lowercase letters, numbers, dashes (-), and underscores (_).",
            );
            return;
        }

        createModelDeployment(
            apiServer,
            props.getModelVersion,
            props.setDeployment,
            props.setDeploying,
            setFormError,
            props.setShowModal,
            deploymentName,
            servingEngine,
            comment,
            props.modelVersion?.id,
        );
    };

    useEffect(() => {
        setFormError("");
        setDeploymentName("");
        setServingEngine("default_serving_engine");
        setComment("");
    }, [props.showModal]);

    // TODO: omg...
    const getAvailableServingEngines = async () => {
        try {
            const engineTemplates = await apiServer?.get("engines/schema").then((res) => {
                let schema = res.data;

                let templates = schema.templates.reduce((r: Map<number, string>, a: EngineTemplate) => {
                    r.set(a.id, a.name);
                    return r;
                }, new Map());

                return templates;
            });

            return await apiServer?.get("engines").then((res: AxiosResponse<GetEnginesResponse>) => {
                const defaultEngine = res.data?.currentEngines?.query;
                const defaultEngineSchema = engineTemplates.get(defaultEngine?.templateID);
                const defaultEngineIsCPU = defaultEngineSchema.indexOf("cpu") > -1;
                // TODO: Show the selected engine dropdown so the user
                // can change their selected engine.
                const servingEngines: Engine[] = res.data?.engines || [];
                let servingEngineOptions = servingEngines
                    .filter((engine: Engine) => {
                        if (engine.serviceType !== EngineServiceType.SERVING) return false;

                        // Filter this to match the type of the user's selected default engine.
                        const engineSchema = engineTemplates.get(engine.templateID) || "";
                        const engineIsCPU = engineSchema.indexOf("cpu") > -1;
                        return defaultEngineIsCPU === engineIsCPU;
                    })
                    .map((engine: Engine) => ({ key: engine.name, value: engine.name, text: engine.name }));

                return {
                    servingEngines: servingEngineOptions,
                    defaultEngineSchema,
                    defaultEngineIsCPU,
                };
            });
        } catch (error: AxiosResponse | any) {
            const errorMsg = getErrorMessage(error) ?? "";
            redirectIfSessionInvalid(errorMsg);
            return [];
        }
    };

    useEffect(() => {
        getAvailableServingEngines()
            .then((engineInfo: any) => {
                if (!Array.isArray(engineInfo?.servingEngines) || engineInfo?.servingEngines.length === 0) {
                    return;
                }

                setServingEngine(engineInfo?.servingEngines[0]?.value);
                setServingEngines(engineInfo?.servingEngines);
                setDefaultEngineSchema(engineInfo.defaultEngineSchema);
                setDefaultEngineIsCPU(engineInfo.defaultEngineIsCPU);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return (
        <Modal open={props.showModal} onClose={() => props.setShowModal(false)}>
            <Modal.Header>Deploy this model version (Beta)</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <p>
                        Deploying a model generates an endpoint for accessing your model for real-time predictions.
                        You'll be able to manage aspects of your model deployments such as the deployment versions,
                        serving engines, and other valuable metadata.
                    </p>
                    {getComputeSubscriptionStatus(
                        userContext?.isComputeLimited,
                        featureFlags["Free Trial Testing"],
                    ) && (
                        <Message warning color={"yellow"}>
                            <p className={"message-text"}>
                                <Icon name={"lock"} />
                                <b>Deployments are locked during the trial period. </b>
                                <a href="https://predibase.com/contact-us" className={"contact-link"}>
                                    Contact us{" "}
                                </a>
                                to upgrade or if you would like a demo.
                            </p>
                        </Message>
                    )}
                    <Divider />
                    {formError && <Message error>{formError}</Message>}
                    {loading ? (
                        <Loader />
                    ) : (
                        <Form onSubmit={createDeployment}>
                            <Form.Field>
                                <label
                                    style={{
                                        fontSize: `${15 / 14}rem`,
                                        marginTop: `${24 / 14}rem`,
                                        marginBottom: `${5 / 14}rem`,
                                    }}
                                >
                                    Deployment name
                                </label>
                                <p style={{ color: SEMANTIC_GREY, fontSize: "0.9em" }}>
                                    Choose an identifier for your model deployment (ex. "loans-deployment") that will
                                    also be used in the URL.
                                </p>
                                <Input
                                    className={
                                        metrics.BLOCK_AUTO_CAPTURE +
                                        (restricted ? " restricted-input" : " default-input")
                                    }
                                    readOnly={restricted}
                                    placeholder="Name"
                                    value={deploymentName}
                                    onChange={(e) => setDeploymentName(e.target.value)}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label
                                    style={{
                                        fontSize: `${15 / 14}rem`,
                                        marginTop: `${24 / 14}rem`,
                                        marginBottom: `${5 / 14}rem`,
                                    }}
                                >
                                    Serving engine
                                </label>
                                <p style={{ color: SEMANTIC_GREY, fontSize: "0.9em" }}>
                                    Choose the serving infrastructure your model deployment will be deployed to.
                                </p>
                                <Select
                                    options={servingEngines}
                                    className={restricted ? "restricted-dropdown" : undefined}
                                    style={{ maxWidth: `${285 / 14}rem` }}
                                    disabled={restricted}
                                    value={servingEngine}
                                    onChange={(e: React.SyntheticEvent<HTMLElement>, data: DropdownProps) =>
                                        setServingEngine(data.value as string)
                                    }
                                />
                            </Form.Field>
                            <Message info>
                                <p>
                                    <b>Note:</b> Because the "Default General Engine" selected on the Engines page is{" "}
                                    <strong>{defaultEngineSchema}</strong> which is a{" "}
                                    {defaultEngineIsCPU ? "CPU" : "GPU"} engine, you may only choose from{" "}
                                    {defaultEngineIsCPU ? "CPU" : "GPU"} serving engines. If you would like to deploy to
                                    a {defaultEngineIsCPU ? "GPU" : "CPU"} serving engine, go to{" "}
                                    <Link
                                        to="/engines"
                                        style={{
                                            color: "inherit",
                                            textDecoration: "underline",
                                        }}
                                        onClick={() =>
                                            metrics.capture("Engines.Navigate", { method: "deployment-modal" })
                                        }
                                    >
                                        Engines
                                    </Link>{" "}
                                    and change the "Default General Engine" to a {defaultEngineIsCPU ? "GPU" : "CPU"}{" "}
                                    general engine.
                                </p>
                            </Message>
                            <Form.Field>
                                <label
                                    style={{
                                        fontSize: `${15 / 14}rem`,
                                        marginTop: `${24 / 14}rem`,
                                        marginBottom: `${5 / 14}rem`,
                                    }}
                                >
                                    Comment (optional)
                                </label>
                                <p style={{ color: SEMANTIC_GREY, fontSize: "0.9em" }}>
                                    Leave a short description of this release (ex. “First release”)
                                </p>
                                <Input
                                    className={
                                        metrics.BLOCK_AUTO_CAPTURE +
                                        (restricted ? " restricted-input" : " default-input")
                                    }
                                    readOnly={restricted}
                                    placeholder="Comment"
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                />
                            </Form.Field>
                        </Form>
                    )}
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button content="Cancel" onClick={() => props.setShowModal(false)} />
                <Button
                    style={{ whiteSpace: "nowrap" }}
                    content="Deploy"
                    labelPosition="right"
                    icon="rocket"
                    onClick={(e) => {
                        e.preventDefault();

                        // Purposely updated to match PostHog's suggested naming convention
                        metrics.capture("deployment_attempted");
                        userContext && track(userContext, "attempt_deploy");
                        createDeployment();
                    }}
                    positive
                />
            </Modal.Actions>
        </Modal>
    );
};

const DeploymentDeleteModal = (props: {
    getModelVersion: (startInterval?: boolean, initializeStream?: boolean) => void;
    deleting?: boolean;
    setDeleting: React.Dispatch<React.SetStateAction<boolean>>;
    setDeployment: React.Dispatch<React.SetStateAction<ModelDeployment | undefined>>;
    setDeploymentError: React.Dispatch<React.SetStateAction<string | undefined>>;
    setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>;
    deployment?: ModelDeployment;
    deploymentName?: string;
    modelVersionId?: number;
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    const [apiServer, setAPIServer] = useState<AxiosInstance | null>(null);
    useEffect(() => {
        const getAPIServer = async () => {
            const v1APIServer = await createV1APIServer(auth0TokenOptions);
            // NOTE: Whoever wrote the axios typings is a moron because the return type of axios.create is not
            // AxiosInstance -- it's a wrap function. And React will see that and treat it as a callback that
            // setState should directly call. FML.
            // See: [1], [2]:
            // [1]: https://github.com/axios/axios/issues/4365
            // [2]: https://stackoverflow.com/questions/64427195/calling-setstate-will-execute-the-function-value-instead-of-passing-it
            setAPIServer(() => v1APIServer);
        };
        getAPIServer();
    }, []);

    return (
        <Modal open={props.showModal} onClose={() => props.setShowModal(false)} size="tiny">
            <Modal.Header>Delete this deployment</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <p>
                        Are you sure you'd like to delete model deployment <strong>{props.deploymentName}</strong>
                    </p>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button content="Cancel" onClick={() => props.setShowModal(false)} />
                <Button
                    content="Delete"
                    onClick={(e) => {
                        e.preventDefault();
                        // Prevents double click
                        if (props.deleting) return;

                        deleteModelDeployment(
                            apiServer,
                            props.getModelVersion,
                            props.setDeleting,
                            props.setDeployment,
                            props.setDeploymentError,
                            props.setErrorMessage,
                            props.deploymentName,
                            props.modelVersionId,
                        )?.then(() => {
                            props.setShowModal(false);
                        });
                    }}
                    negative
                />
            </Modal.Actions>
        </Modal>
    );
};

const checkIsModelDeployable = (model: CreateModelConfig) => {
    const errors = new Map<string, JSX.Element>();

    // TorchScript export limitations:
    const unsupportedFeatureTypes = ["image", "audio", "date", "vector"];
    if (Array.isArray(model?.input_features)) {
        model.input_features.forEach((feature) => {
            if (unsupportedFeatureTypes.includes(feature.type)) {
                errors.set(
                    `feature-${feature.type}`,
                    <span>
                        <strong>{feature.type}</strong> feature
                    </span>,
                );
            }

            const supportedEncoders = [
                "passthrough",
                "embed",
                "parallel_cnn",
                "stacked_cnn",
                "stacked_parallel_cnn",
                "rnn",
                "cnnrnn",
                "transform",
            ];
            if (
                feature.type === "text" &&
                feature?.encoder?.type &&
                !supportedEncoders.includes(feature?.encoder?.type)
            ) {
                errors.set(
                    `coder-${feature.encoder.type}`,
                    <span>
                        HuggingFace model, <strong>{feature.encoder.type}</strong>
                    </span>,
                );
            }

            // This tokeninzer can be used on many feature types
            if (feature?.preprocessing?.tokenizer === "hf_tokenizer") {
                errors.set(
                    `tokenizer-${feature.preprocessing.tokenizer}`,
                    <span>
                        tokenizer <strong>{feature.preprocessing.tokenizer}</strong>.
                    </span>,
                );
            }
        });
    }

    if (Array.isArray(model?.output_features)) {
        model.output_features.forEach((feature) => {
            if (unsupportedFeatureTypes.includes(feature.type)) {
                errors.set(
                    `feature-${feature.type}`,
                    <span>
                        <strong>{feature.type}</strong> feature
                    </span>,
                );
            }

            // This tokeninzer can be used on many feature types
            if (feature.preprocessing?.tokenizer === "hf_tokenizer") {
                errors.set(
                    `tokenizer-${feature.preprocessing.tokenizer}`,
                    <span>
                        tokenizer <strong>{feature.preprocessing.tokenizer}</strong>.
                    </span>,
                );
            }
        });
    }

    // TODO: SER-57 - Triton Serving Limitations

    if (errors.size === 0) {
        return null;
    }

    const errorsList: JSX.Element[] = [];
    for (let error of errors) {
        errorsList.push(<li key={error[0]}>{error[1]}</li>);
    }

    if (getModelType(model) === ModelTypes.LARGE_LANGUAGE_MODEL) {
        return (
            <span>
                To deploy your fine-tuned LLM, check out our <b>"Serving Fine-Tuned LLMs"</b> docs page for instructions
                on how to serve your LLM via the Predibase SDK.
            </span>
        );
    } else {
        return (
            <span>
                Deploying this model version is currently not supported because it is using the following:
                <ul style={{ marginBottom: 0 }}>{errorsList}</ul>
            </span>
        );
    }
};

const getDeploymentFromModelVersion = (model: Model) => {
    if (!Array.isArray(model.deployments)) {
        return;
    }

    // Iterate through the list of deployments
    // Find the first deployment whose head version matches the model version
    // If this deployment doesn't exist, then return the last deployment
    for (let i = 0; i < model.deployments.length; i++) {
        const deployment = model.deployments[i];
        if (deployment?.HeadVersion.ModelVersion === model.repoVersion) {
            return deployment;
        }
    }
    return model.deployments[model.deployments.length - 1];
};

const DeploymentStatusInfo = (props: {
    deleting: boolean;
    deployment?: ModelDeployment;
    deploymentError?: string;
    modelVersion: number;
    setShowDeploymentDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
    setShowErrorModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const { deleting, deployment, modelVersion, setShowDeploymentDeleteModal, setShowErrorModal } = props;
    if (!deployment) {
        return null;
    }

    switch (deployment.Status) {
        case DeploymentStatus.QUEUED:
        case DeploymentStatus.INITIALIZING:
        default:
            return getStatusChipForModelsAndQueries(deployment.Status as DeploymentStatus, `${83 / 14}rem`);
        case DeploymentStatus.UPDATING:
            return (
                <>
                    {getStatusChipForModelsAndQueries(deployment.Status, `${83 / 14}rem`)}
                    <p
                        style={{
                            display: "block",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            padding: `${7 / 14}rem`,
                            marginBottom: 0,
                            maxWidth: `${260 / 14}rem`,
                            backgroundColor: "rgba(33, 133, 208, 0.1)",
                        }}
                    >
                        <span style={{ color: SEMANTIC_BLUE }}>{deployment?.URL}</span>
                    </p>
                    <CopyButton text={deployment?.URL} />
                </>
            );
        case DeploymentStatus.FAILED:
            return (
                <>
                    <button
                        onClick={() => setShowErrorModal(true)}
                        style={{ border: "none", padding: 0, cursor: "pointer" }}
                        aria-label="View deployment error message"
                    >
                        {getStatusChipForModelsAndQueries(deployment.Status, `${83 / 14}rem`)}
                    </button>
                    <Button
                        aria-label="Delete deployment"
                        className={`${BLOCK_POSTHOG_AUTO_CAPTURE} button-reset`}
                        style={{ color: SEMANTIC_GREY_DISABLED, padding: `${7 / 14}rem`, marginRight: 0 }}
                        loading={deleting}
                        icon="trash alternate"
                        onClick={() => setShowDeploymentDeleteModal(true)}
                    />
                </>
            );
        case DeploymentStatus.ACTIVE:
            // When the head and latest versions match, the latest deployment version was successful
            // and we only need to show a message to user on the latest model that was deployed.
            // OR when a new version of a deployment fails, the head version
            // is still available and deployed.
            if (!isModelVersionLatestFailedAttempt(deployment, modelVersion)) {
                return (
                    <>
                        <p
                            style={{
                                display: "block",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                padding: `${7 / 14}rem`,
                                marginBottom: 0,
                                maxWidth: `${260 / 14}rem`,
                                backgroundColor: "rgba(33, 133, 208, 0.1)",
                            }}
                        >
                            <span style={{ color: SEMANTIC_BLUE }}>{deployment?.URL}</span>
                        </p>
                        <CopyButton text={deployment?.URL} />
                        <Button
                            aria-label="Delete deployment"
                            className={`${BLOCK_POSTHOG_AUTO_CAPTURE} button-reset`}
                            style={{ color: SEMANTIC_GREY_DISABLED, padding: `${7 / 14}rem`, marginRight: 0 }}
                            loading={deleting}
                            icon="trash alternate"
                            onClick={() => setShowDeploymentDeleteModal(true)}
                        />
                    </>
                );
            }

            // When a version fails, we need to show the user what went wrong with that version
            // TODO: Add the hover with the link to the Head Model
            // Attempt to deploy this model version failed. Test-deployment with Respiratory Issue Detection: #4 is still up and running. Click to view error message and try re-deploying.
            return (
                <button
                    onClick={() => setShowErrorModal(true)}
                    style={{ border: "none", padding: 0, cursor: "pointer" }}
                    aria-label="View deployment error message"
                >
                    <Chip
                        color={SEMANTIC_RED}
                        text="Attempt Failed"
                        textColor={SEMANTIC_RED_ACTIVE}
                        opacity={0.1}
                        width={`${120 / 14}rem`}
                    />
                </button>
            );
    }
};

const Deployment = (props: {
    getModelVersion: (startInterval?: boolean, initializeStream?: boolean) => void;
    modelRepo: ModelRepo | undefined;
    modelVersion: Model;
    setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>;
}) => {
    const { modelVersion, getModelVersion } = props;
    const [deployment, setDeployment] = useState<ModelDeployment | undefined>(() =>
        getDeploymentFromModelVersion(modelVersion),
    );
    const [deploying, setDeploying] = useState(() => {
        const previousDeployment = getDeploymentFromModelVersion(modelVersion);
        if (previousDeployment === undefined) return false;
        const status = previousDeployment.Status;
        return status === DeploymentStatus.INITIALIZING ||
            status === DeploymentStatus.QUEUED ||
            status === DeploymentStatus.UPDATING
            ? true
            : false;
    });
    const [deleting, setDeleting] = useState(() => {
        const previousDeployment = getDeploymentFromModelVersion(modelVersion);
        if (previousDeployment === undefined) return false;
        const status = previousDeployment.Status;
        return status === DeploymentStatus.TERMINATING ? true : false;
    });
    const [deploymentError, setDeploymentError] = useState<string | undefined>(() => {
        const previousDeployment = getDeploymentFromModelVersion(modelVersion);
        if (previousDeployment === undefined) return;
        return previousDeployment?.LatestVersion?.ErrorText;
    });
    const [showDeploymentCreateModal, setShowDeploymentCreateModal] = useState(false);
    const [showDeploymentDeleteModal, setShowDeploymentDeleteModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const modelHasDeployableIssues = checkIsModelDeployable(modelVersion.config);

    // TODO: Who tf set this up
    // DO NOT COPY
    // This component should be rewritten to take full advantage of React Query!
    // This creates a loop to check the status of the deployment until it
    // either fails or deploys:
    const { data: modelDeploymentDeploying, error: modelDeploymentDeployingError } = useClassicDeploymentByNameQuery(
        deployment?.Name ?? "",
        {
            queryKey: [...GET_CLASSIC_DEPLOYMENT_QUERY_KEY(deployment?.Name ?? ""), "getting status"],
            refetchInterval: 5000,
            // Based on React lifecycles, we change deploying to true before making the API call.
            // So initially the deployment is undefined. We need to wait until we have a deployment object
            // from the creation API call so that we don't start pinging for a failed deployment.
            // Finally, once a deployment is either complete or failed, we no longer want to keep
            // pinging the server.
            enabled: deploying && Boolean(deployment) && !isTerminalDeploymentStatus(deployment?.Status),
        },
    );
    useEffect(() => {
        if (modelDeploymentDeployingError) {
            setDeploymentError(String(modelDeploymentDeployingError));
            if (String(modelDeploymentDeployingError).includes("record not found")) {
                setDeployment(undefined);
            } else {
                setDeployment(() => ({ ...deployment, status: DeploymentStatus.FAILED }) as ModelDeployment);
            }
            setDeploying(false);
        } else if (modelDeploymentDeploying) {
            setDeployment(modelDeploymentDeploying);
            let error;
            if (modelDeploymentDeploying?.Status === DeploymentStatus.FAILED) {
                // Include a fallback when we don't get errorText back due to a programming error:
                error = modelDeploymentDeploying?.LatestVersion.ErrorText
                    ? modelDeploymentDeploying?.LatestVersion.ErrorText
                    : "Unable to deploy model.";
            } else if (modelDeploymentDeploying?.Status === DeploymentStatus.ACTIVE) {
                // If the deployment is active but the model version is not the head version,
                // then this model version failed to deploy. We need to populate the error text.
                if (isModelVersionLatestFailedAttempt(modelDeploymentDeploying, modelVersion.repoVersion)) {
                    error = modelDeploymentDeploying?.LatestVersion.ErrorText
                        ? modelDeploymentDeploying?.LatestVersion.ErrorText
                        : "Unable to deploy model.";
                } else if (modelDeploymentDeploying?.HeadVersion.ModelVersion !== modelVersion.repoVersion) {
                    // If the deployment is active and neither the head nor latest versions are
                    // tied to the model version, set the deployment to undefined.
                    setDeployment(undefined);
                }
            }
            setDeploymentError(error);

            // Only change deploying once the process is complete
            if (isTerminalDeploymentStatus(modelDeploymentDeploying?.Status)) {
                setDeploying(false);
                getModelVersion(false, false);
            }
        }
        // TODO: holy shit, props itself...
    }, [deployment, modelDeploymentDeploying, modelDeploymentDeployingError, getModelVersion, modelVersion]);

    // DO NOT COPY
    // This component should be rewritten to take full advantage of React Query!
    // This creates a loop to check the status of the deployment until it
    // has fully been deleted
    // NOTE(geoffrey): this useQuery needs to have a different query key
    // than the above useQuery call to prevent this from being executed
    const { data: modelDeploymentDeleting, error: modelDeploymentDeletingError } = useClassicDeploymentByNameQuery(
        deployment?.Name ?? "",
        {
            queryKey: [...GET_CLASSIC_DEPLOYMENT_QUERY_KEY(deployment?.Name ?? ""), "deleting status"],
            refetchInterval: 5000,
            enabled: deleting,
        },
    );
    useEffect(() => {
        if (modelDeploymentDeletingError) {
            setDeploymentError(String(modelDeploymentDeletingError));
            if (String(modelDeploymentDeletingError).includes("record not found")) {
                setDeployment(undefined);
            } else {
                setDeployment(() => ({ ...deployment, status: DeploymentStatus.FAILED }) as ModelDeployment);
            }
            setDeleting(false);
            getModelVersion(false, false);
        } else if (modelDeploymentDeleting) {
            setDeployment(modelDeploymentDeleting);
            let error;
            if (modelDeploymentDeleting?.Status === DeploymentStatus.FAILED) {
                // Include a fallback when we don't get errorText back due to a programming error:
                error = modelDeploymentDeleting?.LatestVersion.ErrorText
                    ? modelDeploymentDeleting?.LatestVersion.ErrorText
                    : "Unable to delete model.";
            }
            setDeploymentError(error);

            // Only change deploying once the process is complete
            if (isTerminalDeploymentStatus(modelDeploymentDeleting?.Status)) {
                setDeleting(false);
                getModelVersion(false, false);
            }
        }
    }, [deployment, modelDeploymentDeleting, modelDeploymentDeletingError, getModelVersion]);

    // Because deployments are independent of model versions, we no longer rely on the
    // Model status to determine what to show. We simply rely on the presence of a
    // deployment associated with the Model and its status to determine what to do.
    if (!Boolean(deployment) || typeof deployment === undefined) {
        return (
            <DeployButton
                modelHasDeployableIssues={modelHasDeployableIssues}
                getModelVersion={getModelVersion}
                setDeployment={setDeployment}
                setDeploying={setDeploying}
                modelRepo={props.modelRepo}
                modelVersion={modelVersion}
                showDeploymentCreateModal={showDeploymentCreateModal}
                setShowDeploymentCreateModal={setShowDeploymentCreateModal}
            />
        );
    }

    return (
        <>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: `${4 / 14}rem ${8 / 14}rem`,
                    marginRight: `${8 / 14}rem`,
                    backgroundColor: "rgba(0, 0, 0, 0.03)",
                    borderRadius: "0.28571429rem",
                }}
            >
                <span style={{ fontWeight: "bold", marginRight: `${8 / 14}rem` }}>Deployment</span>
                <DeploymentStatusInfo
                    deleting={deleting}
                    deployment={deployment}
                    deploymentError={deploymentError}
                    modelVersion={modelVersion.repoVersion}
                    setShowDeploymentDeleteModal={setShowDeploymentDeleteModal}
                    setShowErrorModal={setShowErrorModal}
                />
                <DeploymentDeleteModal
                    getModelVersion={getModelVersion}
                    setDeployment={setDeployment}
                    deleting={deleting}
                    setDeleting={setDeleting}
                    setDeploymentError={setDeploymentError}
                    setErrorMessage={props.setErrorMessage}
                    deployment={deployment}
                    deploymentName={deployment?.Name}
                    modelVersionId={modelVersion?.id}
                    showModal={showDeploymentDeleteModal}
                    setShowModal={setShowDeploymentDeleteModal}
                />
            </div>
            {(deployment?.Status === DeploymentStatus.FAILED ||
                isModelVersionLatestFailedAttempt(deployment, modelVersion.repoVersion)) && (
                <DeployButton
                    modelHasDeployableIssues={modelHasDeployableIssues}
                    getModelVersion={getModelVersion}
                    setDeployment={setDeployment}
                    setDeploying={setDeploying}
                    modelRepo={props.modelRepo}
                    modelVersion={modelVersion}
                    showDeploymentCreateModal={showDeploymentCreateModal}
                    setShowDeploymentCreateModal={setShowDeploymentCreateModal}
                />
            )}
            <Modal open={showErrorModal} onClose={() => setShowErrorModal(false)}>
                <Modal.Header>Deployment Error Message</Modal.Header>
                <Modal.Content>
                    {deployment?.HeadVersion.VersionNumber !== 0 && (
                        <Message info>
                            The latest attempt to update deployment <strong>{deployment?.Name}</strong> failed with the
                            following error message. It is still active with model{" "}
                            <strong>#{deployment?.HeadVersion.ModelVersion} </strong>
                            from the <strong> {deployment?.HeadVersion.RepoName}</strong> model repository.
                        </Message>
                    )}
                    <Message error>{deploymentError}</Message>
                </Modal.Content>
                <Modal.Actions>
                    <Button content="Close" onClick={() => setShowErrorModal(false)} />
                </Modal.Actions>
            </Modal>
        </>
    );
};

const DeployButton = (props: {
    modelHasDeployableIssues: JSX.Element | null;
    getModelVersion: (startInterval?: boolean, initializeStream?: boolean) => void;
    setDeployment: React.Dispatch<React.SetStateAction<ModelDeployment | undefined>>;
    setDeploying: React.Dispatch<React.SetStateAction<boolean>>;
    modelRepo: ModelRepo | undefined;
    modelVersion: Model;
    showDeploymentCreateModal: boolean;
    setShowDeploymentCreateModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const {
        modelHasDeployableIssues,
        setDeployment,
        setDeploying,
        modelVersion,
        showDeploymentCreateModal,
        setShowDeploymentCreateModal,
    } = props;
    const isLLM = isLLMModel(modelVersion.config);

    if (Boolean(modelHasDeployableIssues)) {
        return (
            <>
                <Popup
                    className="transition-scale"
                    content={modelHasDeployableIssues}
                    position={"right center"}
                    wide={true}
                    trigger={
                        <div style={{ display: "inline-block" }}>
                            <Button
                                style={{
                                    minHeight: `${36 / 14}rem`,
                                    marginRight: `${8 / 14}rem`,
                                    whiteSpace: "nowrap",
                                }}
                                icon="rocket"
                                content="Deploy"
                                disabled
                            />
                        </div>
                    }
                ></Popup>
            </>
        );
    }

    return (
        <>
            <Button
                icon="rocket"
                content="Deploy"
                style={{
                    marginRight: `${8 / 14}rem`,
                    whiteSpace: "nowrap",
                }}
                onClick={() => setShowDeploymentCreateModal(true)}
            />
            {isLLM ? (
                <DeployLLMCreateModal
                    modelRepo={props.modelRepo}
                    model={modelVersion}
                    showModal={showDeploymentCreateModal}
                    setShowModal={setShowDeploymentCreateModal}
                />
            ) : (
                <DeploymentECDCreateModal
                    getModelVersion={props.getModelVersion}
                    setDeployment={setDeployment}
                    setDeploying={setDeploying}
                    modelVersion={modelVersion}
                    showModal={showDeploymentCreateModal}
                    setShowModal={setShowDeploymentCreateModal}
                />
            )}
        </>
    );
};

export default Deployment;
