import React, { useEffect, useMemo, useState } from "react";
import { Link, useMatch } from "react-router-dom";
import { Breadcrumb, Divider, Dropdown, Grid, Icon, Tab } from "semantic-ui-react";
import { DropdownItemProps } from "semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem";
import LiveTimeAgo from "../../components/LiveTimeAgo";
import metrics from "../../metrics/metrics";
import { SEMANTIC_GREY } from "../../utils/colors";
import { useModelRepoWithVersionsQuery } from "../query";
import ModelDiffView from "../repo/ModelDiffView";
import { getLatestModel, modelArchivedFilter, modelStatusFilter, rocDisabledCriteria } from "../util";
import CalibrationPlotsViewer from "../viz/CalibrationPlotsViewer";
import ConfusionMatrixCompareViewer from "../viz/ConfusionMatrixCompareViewer";
import F1Viewer from "../viz/F1Viewer";
import FeatureImportanceCompareViewer from "../viz/FeatureImportanceCompareViewer";
import ROCCompareViewer from "../viz/ROCCompareViewer";
import ModelCompareLearningCurves from "./ModelCompareLearningCurves";
import ModelCompareTable from "./ModelCompareTable";

const noModelSelected = (
    <span
        style={{
            verticalAlign: "middle",
            textAlign: "center",
            display: "block",
            lineHeight: "200px",
            color: SEMANTIC_GREY,
        }}
    >
        No model selected
    </span>
);

enum CompareSubmenuTab {
    ConfigTab,
    LearningCurvesTab,
    ConfusionMatrixTab,
    RocTab,
    F1Tab,
    CalibrationTab,
    FeatureImportanceTab,
}

const disabledMap: Record<number, (model: Model) => React.ReactNode> = {
    3: rocDisabledCriteria,
};

const modelSearch = (options: DropdownItemProps[], value: string) => {
    return options.filter(
        (x) =>
            x.rawtext.toLowerCase().includes(value.toLowerCase()) ||
            x.modelDescription.toLowerCase().includes(value.toLowerCase()) ||
            x.author.toLowerCase().includes(value.toLowerCase()),
    );
};

const getModelOptions = (models: Model[]) => {
    return models
        .filter(modelStatusFilter)
        .filter(modelArchivedFilter)
        .map((x) => {
            return {
                key: x.id,
                text: (
                    <span>
                        {x.repoVersion}&ensp;<span style={{ color: SEMANTIC_GREY }}>{x.description}</span>
                    </span>
                ),
                rawtext: x.repoVersion,
                modelDescription: x.description,
                description: (
                    <span>
                        {x.user?.username} (
                        <span style={{ fontSize: "0.9em" }}>
                            <LiveTimeAgo fromDate={x.created} />
                        </span>
                        )
                    </span>
                ),
                value: x.id,
                author: x.user?.username,
            };
        });
};

function ModelCompareView() {
    const match = useMatch("/models/repo/:id/compare/:rootID?");
    // TODO: Unsafe bang access:
    const modelRepoID = parseInt(match!.params.id!);
    // TODO: Umm, what? lmao
    const rootID = match?.params.rootID && parseInt(match.params.rootID);

    const [modelRepo, setModelRepo] = useState<ModelRepo>();

    const [tabIndex, setTabIndex] = useState(CompareSubmenuTab.LearningCurvesTab);

    // Includes models of all statuses
    const [allModels, setAllModels] = useState<Model[]>([]);
    // Max 2 models
    const [selectedModels, setSelectedModels] = useState<(Model | null)[]>([null, null]);
    // Only includes succeeded models for ROC + learning curves, no max
    const [multiModels, setMultiModels] = useState<(Model | null)[]>([]);

    const { data: modelRepoWithVersionsResponse } = useModelRepoWithVersionsQuery(modelRepoID, {
        refetchInterval: false,
    });
    useEffect(() => {
        if (!modelRepoWithVersionsResponse) {
            return;
        }

        if (modelRepoWithVersionsResponse.modelRepo) {
            setModelRepo(modelRepoWithVersionsResponse.modelRepo);
        }

        if (modelRepoWithVersionsResponse.modelRepo.models) {
            const modelVersions = modelRepoWithVersionsResponse.modelRepo.models || [];
            setAllModels(modelVersions);

            if (rootID) {
                const rootModel = modelVersions.find((x) => x.id === rootID);
                if (rootModel && modelStatusFilter(rootModel)) {
                    let parent = null;
                    if (rootModel.parentID) {
                        parent = modelVersions.filter(modelStatusFilter).find((x) => x.id === rootModel.parentID);
                    }
                    setSelectedModels([parent || null, rootModel]);
                    setMultiModels([parent || null, rootModel]);
                }
            } else {
                const latest = getLatestModel(modelVersions, true);
                if (latest) {
                    let parent = null;
                    if (latest.parentID) {
                        parent = modelVersions.filter(modelStatusFilter).find((x) => x.id === latest.parentID);
                    }
                    setSelectedModels([parent || null, latest]);
                    setMultiModels([parent || null, latest]);
                }
            }
        }
    }, [modelRepoWithVersionsResponse]);

    const modelDropdownOptions = useMemo(() => getModelOptions(allModels), [allModels]);

    const panes = [
        {
            key: "configDiffViewer",
            menuItem: "Config",
            render: () => (
                <Tab.Pane className={`model-tab ${metrics.BLOCK_AUTO_CAPTURE}`} key={"confusionMatrixViewer"}>
                    <ModelDiffView
                        selectedModels={selectedModels}
                        options={allModels.map((x) => {
                            return {
                                id: String(x.id),
                                data: {
                                    name: String(x.repoVersion),
                                    author: x.user?.username,
                                    description: x.description,
                                    status: x.status,
                                    created: String(x.created),
                                    completed: String(x.completed),
                                } as ModelLineageAttributes,
                                position: 0,
                            } as ModelLineageNode;
                        })}
                        setModels={setSelectedModels}
                        allModels={allModels}
                    />
                </Tab.Pane>
            ),
        },
        {
            key: "learningCurvesViewer",
            menuItem: "Learning Curves",
            render: () => (
                <Tab.Pane className={`model-tab ${metrics.BLOCK_AUTO_CAPTURE}`} key={"learningCurvesViewer"}>
                    <div style={{ overflowY: "auto" }}>
                        {multiModels.filter((x) => x !== null).length > 0 ? (
                            <ModelCompareLearningCurves
                                // @ts-ignore
                                models={multiModels.filter((x) => x !== null)}
                            />
                        ) : (
                            <div
                                style={{
                                    height: "200px",
                                    textAlign: "center",
                                    lineHeight: "100px",
                                    color: SEMANTIC_GREY,
                                }}
                            >
                                Select models below to display learning curves
                            </div>
                        )}
                    </div>
                </Tab.Pane>
            ),
        },
        {
            key: "confusionMatrixViewer",
            menuItem: "Confusion Matrix",
            render: () => (
                <Tab.Pane className={`model-tab ${metrics.BLOCK_AUTO_CAPTURE}`} key={"confusionMatrixViewer"}>
                    <div style={{ height: "80vh", overflowY: "auto" }}>
                        <ConfusionMatrixCompareViewer
                            modelDropdownOptions={modelDropdownOptions}
                            modelSearch={modelSearch}
                            allModels={allModels}
                            selectedModels={selectedModels}
                            setSelectedModels={setSelectedModels}
                            noModelSelected={noModelSelected}
                        />
                    </div>
                </Tab.Pane>
            ),
        },
        {
            key: "rocViewer",
            menuItem: "ROC",
            render: () => (
                <Tab.Pane className={`model-tab ${metrics.BLOCK_AUTO_CAPTURE}`} key={"rocViewer"}>
                    <div style={{ height: "510px", overflowY: "auto" }}>
                        <ROCCompareViewer
                            // @ts-ignore
                            models={multiModels.filter((x) => x !== null)}
                        />
                    </div>
                </Tab.Pane>
            ),
        },
        {
            key: "f1Viewer",
            menuItem: "F1",
            render: () => (
                <Tab.Pane className={`model-tab ${metrics.BLOCK_AUTO_CAPTURE}`} key={"f1Viewer"}>
                    <div style={{ height: "80vh", overflowY: "auto" }}>
                        <Grid columns={2} divided style={{ height: "100%", width: "100%" }}>
                            <Grid.Column style={{ overflowX: "auto" }}>
                                <Dropdown
                                    fluid
                                    search={modelSearch}
                                    selection
                                    options={modelDropdownOptions}
                                    onChange={(event, data) => {
                                        const selected = allModels.find((x) => x.id === data.value);
                                        if (selected) {
                                            setSelectedModels((x) => [selected, x[1]]);
                                        }
                                    }}
                                    value={selectedModels[0]?.id || ""}
                                />
                                {selectedModels[0] ? <F1Viewer model={selectedModels[0]} /> : noModelSelected}
                            </Grid.Column>
                            <Grid.Column style={{ overflowX: "auto" }}>
                                <Dropdown
                                    fluid
                                    search={modelSearch}
                                    selection
                                    options={modelDropdownOptions}
                                    onChange={(event, data) => {
                                        const selected = allModels.find((x) => x.id === data.value);
                                        if (selected) {
                                            setSelectedModels((x) => [x[0], selected]);
                                        }
                                    }}
                                    value={selectedModels[1]?.id || ""}
                                />
                                {selectedModels[1] ? <F1Viewer model={selectedModels[1]} /> : noModelSelected}
                            </Grid.Column>
                        </Grid>
                    </div>
                </Tab.Pane>
            ),
        },
        {
            key: "calibrationViewer",
            menuItem: "Calibration Plots",
            render: () => (
                <Tab.Pane className={`model-tab ${metrics.BLOCK_AUTO_CAPTURE}`} key={"calibrationViewer"}>
                    <div style={{ height: "80vh", overflowY: "auto" }}>
                        <Grid columns={2} divided style={{ height: "100%", width: "100%" }}>
                            <Grid.Column style={{ overflowX: "auto" }}>
                                <Dropdown
                                    fluid
                                    search={modelSearch}
                                    selection
                                    options={modelDropdownOptions}
                                    onChange={(event, data) => {
                                        const selected = allModels.find((x) => x.id === data.value);
                                        if (selected) {
                                            setSelectedModels((x) => [selected, x[1]]);
                                        }
                                    }}
                                    value={selectedModels[0]?.id || ""}
                                />
                                {selectedModels[0] ? (
                                    <CalibrationPlotsViewer model={selectedModels[0]} plotsPerRow={2} />
                                ) : (
                                    noModelSelected
                                )}
                            </Grid.Column>
                            <Grid.Column style={{ overflowX: "auto" }}>
                                <Dropdown
                                    fluid
                                    search={modelSearch}
                                    selection
                                    options={modelDropdownOptions}
                                    onChange={(event, data) => {
                                        const selected = allModels.find((x) => x.id === data.value);
                                        if (selected) {
                                            setSelectedModels((x) => [x[0], selected]);
                                        }
                                    }}
                                    value={selectedModels[1]?.id || ""}
                                />
                                {selectedModels[1] ? (
                                    <CalibrationPlotsViewer model={selectedModels[1]} plotsPerRow={2} />
                                ) : (
                                    noModelSelected
                                )}
                            </Grid.Column>
                        </Grid>
                    </div>
                </Tab.Pane>
            ),
        },
        {
            key: "featureImportanceViewer",
            menuItem: "Feature Importance",
            render: () => (
                <Tab.Pane className={`model-tab ${metrics.BLOCK_AUTO_CAPTURE}`} key={"featureImportanceViewer"}>
                    <div style={{ height: "80vh", overflowY: "auto" }}>
                        <FeatureImportanceCompareViewer
                            modelDropdownOptions={modelDropdownOptions}
                            modelSearch={modelSearch}
                            allModels={allModels}
                            selectedModels={selectedModels}
                            setSelectedModels={setSelectedModels}
                            noModelSelected={noModelSelected}
                        />
                    </div>
                </Tab.Pane>
            ),
        },
    ];

    if (modelRepo) {
        return (
            <div style={{ padding: "20px" }}>
                <Breadcrumb>
                    <Breadcrumb.Section>
                        <Link
                            to="/models"
                            className={metrics.BLOCK_AUTO_CAPTURE}
                            onClick={() => metrics.capture("Model.Navigate", { method: "breadcrumb" })}
                        >
                            Models
                        </Link>
                    </Breadcrumb.Section>
                    <Breadcrumb.Divider />
                    <Breadcrumb.Section>
                        <Link className={metrics.BLOCK_AUTO_CAPTURE} to={"/models/repo/" + modelRepo.id}>
                            <Icon name={"folder outline"} />
                            {modelRepo.modelName}
                        </Link>
                    </Breadcrumb.Section>
                    <Breadcrumb.Divider />
                    <Breadcrumb.Section className={"no-bold"}>Metrics and Comparison</Breadcrumb.Section>
                </Breadcrumb>
                <Divider hidden />
                <Tab
                    menu={{ secondary: true, pointing: true }}
                    panes={panes}
                    activeIndex={tabIndex}
                    onTabChange={(e, { activeIndex }) => setTabIndex(activeIndex as number)}
                />
                {tabIndex === CompareSubmenuTab.LearningCurvesTab || tabIndex === CompareSubmenuTab.RocTab ? (
                    <>
                        <Divider hidden />
                        <ModelCompareTable
                            modelVersions={allModels.filter(modelStatusFilter)}
                            repo={modelRepo}
                            checkedModels={multiModels}
                            setCheckedModels={setMultiModels}
                            disabledCriteria={disabledMap[tabIndex]}
                        />
                    </>
                ) : null}
            </div>
        );
    }

    return null;
}

export default ModelCompareView;
