import React, { CSSProperties, useState } from "react";
import { Table } from "semantic-ui-react";

const ShowMoreTableFooter = (props: {
    howManyMore: number;
    cols: number;
    collapsed: boolean;
    setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    return (
        <Table.Row>
            {Array(props.cols - 1)
                .fill(0)
                .map((_, i) => (
                    <Table.Cell key={i} style={{ borderTop: "none" }} />
                ))}
            <Table.Cell
                style={{ borderTop: "none", paddingLeft: 0, paddingTop: 0, paddingBottom: 0, fontSize: "0.9em" }}
            >
                {props.collapsed ? (
                    <button className={"button-reset"} onClick={() => props.setCollapsed(false)}>
                        {props.howManyMore} more
                    </button>
                ) : (
                    <button
                        className={"button-reset"}
                        style={{ textAlign: "left" }}
                        onClick={() => props.setCollapsed(true)}
                    >
                        Collapse
                    </button>
                )}
            </Table.Cell>
        </Table.Row>
    );
};

const ShowMoreTable = (props: {
    data: any[];
    limit: number;
    cols: number;
    RowComponent: (x: any) => React.ReactNode;
    style?: CSSProperties;
}) => {
    const [collapsed, setCollapsed] = useState(true);
    return (
        <Table className={"no-hover default-padding"} basic={"very"} compact style={props.style}>
            <Table.Body>
                {props.data.slice(0, collapsed ? props.limit : undefined).map((x) => props.RowComponent(x))}
                {props.data.length > props.limit ? (
                    <ShowMoreTableFooter
                        howManyMore={props.data.length - props.limit}
                        cols={props.cols}
                        collapsed={collapsed}
                        setCollapsed={setCollapsed}
                    />
                ) : null}
            </Table.Body>
        </Table>
    );
};

export default ShowMoreTable;
