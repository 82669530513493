import { AxiosResponse } from "axios";
import { getTraceId } from "../api/trace";
import { Auth0TokenOptions } from "../data";
import metrics from "../metrics/metrics";
import { createV1APIServer, redirectIfSessionInvalid } from "../utils/api";
import { getErrorMessage } from "../utils/errors";

export const getHyperoptTrials = async (modelID: number, auth0TokenOptions: Auth0TokenOptions) => {
    const endpoint = "models/hyperopt/" + modelID;
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .get(endpoint)
        .then((res: AxiosResponse<Trial[]>) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const getClassicDeploymentByName = async (deploymentName?: string, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `deployments/${deploymentName}`;
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .get(endpoint)
        .then((res: AxiosResponse<ModelDeployment>) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                properties: {
                    deploymentName,
                },
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

// TODO: Ideally shouldn't take optional arg:
export const getRecommendedNextExperiments = async (model?: Model, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = "config/recommend_experiments_for_version";
    const apiServer = await createV1APIServer(auth0TokenOptions);

    if (!model) {
        throw new Error("Missing model");
    }

    return (
        apiServer
            .post(endpoint, model, {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            })
            //  TODO: Needs a type...
            .then((res) => {
                return res.data;
            })
            .catch((error) => {
                const errorMsg = getErrorMessage(error) ?? "";
                metrics.captureError("api_error", errorMsg, {
                    method: "POST",
                    endpoint,
                    properties: {
                        modelID: model.id,
                    },
                    trace_id: getTraceId(error),
                });
                redirectIfSessionInvalid(errorMsg);
                throw errorMsg;
            })
    );
};

// TODO: Really this is a general update function, not just star or archive:
export const starOrArchiveModel = async (
    modelID: number,
    description: string,
    star: boolean,
    archive: boolean,
    auth0TokenOptions?: Auth0TokenOptions,
) => {
    const endpoint = `models/${modelID}/${star ? "star" : "archive"}`;
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .put<Model>(endpoint, { id: modelID, description, starred: star, archived: archive } as Partial<Model>, {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "PUT",
                endpoint,
                properties: {
                    modelID,
                    star,
                    archive,
                },
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const cancelModelVersion = async (modelVersion: number, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `models/version/${modelVersion}/cancel`;
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .post<undefined>(
            endpoint,
            {},
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            },
        )
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "POST",
                endpoint,
                properties: {
                    modelVersion,
                },
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const retrainModel = async (model: Model, auth0TokenOptions: Auth0TokenOptions) => {
    const endpoint = "models/train";
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .post(endpoint, model, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        })
        .then((res: AxiosResponse<StartTrainModelResponse>) => {
            // TODO: Why is this possible lol:
            if (res.data.errorMessage) {
                // setErrorMessage?.(res.data.errorMessage);
                metrics.captureError("api_error", res.data.errorMessage, {
                    method: "POST",
                    endpoint,
                    properties: {
                        modelID: model.id,
                    },
                    trace_id: getTraceId(res),
                });
            }
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "POST",
                endpoint,
                properties: {
                    modelID: model.id,
                },
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

type getModelRepoOverload = {
    (
        repoID: number,
        withVersions: true,
        auth0TokenOptions?: Auth0TokenOptions,
    ): Promise<GetModelRepoWithVersionsResponse>;
    (repoID: number, withVersions: false, auth0TokenOptions?: Auth0TokenOptions): Promise<ModelRepo>;
};
export const getModelRepo: getModelRepoOverload = async (
    repoID: number,
    withVersions: boolean,
    auth0TokenOptions?: Auth0TokenOptions,
) => {
    const endpoint = `models/repo/${repoID}`;
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .get(endpoint, { params: { withVersions } })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                properties: {
                    repoID,
                    withVersions,
                },
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const deleteModelRepo = async (repoID: number, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `models/repo/${repoID}`;
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .delete(endpoint, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "DELETE",
                endpoint,
                properties: {
                    repoID,
                },
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const detectDatasetConfig = async (datasetID: number, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `config/detect/${datasetID}`;
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .get(endpoint)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "POST",
                endpoint,
                properties: {
                    datasetID,
                },
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const detectAutoMLConfig = async (
    datasetID: number | undefined,
    config: CreateModelConfig | undefined,
    auth0TokenOptions?: Auth0TokenOptions,
) => {
    const endpoint = "config/detect/automl";
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .post(endpoint, { datasetID, config }, { headers: { "Content-Type": "application/x-www-form-urlencoded" } })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "POST",
                endpoint,
                properties: {
                    datasetID,
                    config,
                },
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const createModelRepo = async (
    modelName: string,
    description: string,
    retrainCadence: string,
    retrainConfig: any,
    modelType: string,
    parentID: number | undefined,
    auth0TokenOptions?: Auth0TokenOptions,
) => {
    const endpoint = "models/repo";
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .post(
            endpoint,
            {
                modelName,
                description,
                retrainCadence,
                retrainConfig,
                modelType,
                parentID,
            },
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            },
        )
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "POST",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const getVizExplanations = async (
    modelID: number,
    outputFeature: string | undefined,
    auth0TokenOptions?: Auth0TokenOptions,
) => {
    const endpoint = "visualize/explanations";
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .get(endpoint, { params: { modelID, outputFeature } })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                properties: {
                    modelID,
                    outputFeature,
                },
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};
