import type { JSONSchema7 } from "json-schema";
import _ from "lodash";
import { useMemo } from "react";
import JSONSchemaForm from "../../../components/forms/json-schema-form";
import { findAllOfSchema } from "../../../utils/jsonSchema";
import { filterParams } from "../utils";

const CoderForm = (props: {
    config: CreateModelConfig;
    setConfig: (path: string, value: any) => void;
    invalidFields: any;
    metricRoot: string;
    featureIndex: number;
    featureType: string;
    readOnly?: boolean;
    schema: JSONSchema7;
    expectedImpactFilter?: number;
}) => {
    const { config, schema } = props;

    // @ts-expect-error
    const feature = config[props.featureType][props.featureIndex];
    const featureSchema = _.get(schema, `properties.${props.featureType}`) as JSONSchema7;
    const formSchema = findAllOfSchema(featureSchema, feature?.type);
    const schemaType = props.featureType === "input_features" ? "encoder" : "decoder";

    const setConfig = (path: string, value: any) => {
        const typePath = ".type";
        // Wipe previous parameters when changing the type:
        if (path.endsWith(typePath)) {
            // example: encoder.type
            props.setConfig(path.substring(0, path.length - typePath.length), { type: value });
        } else {
            // Make sure, if a sibling property is type, it's set
            // example: encoder.dropout
            props.setConfig(path, value);
        }
    };

    const filteredSchema = useMemo(() => {
        if (!formSchema?.properties?.[schemaType]) {
            return {};
        }

        if (props.featureType === "input_features") {
            return filterParams(formSchema.properties.encoder, props.expectedImpactFilter);
        }

        if (props.featureType === "output_features") {
            const forms = {
                properties: {
                    decoder: _.get(formSchema, "properties.decoder", {}),
                    loss: _.get(formSchema, "properties.loss", {}),
                    calibration: _.get(formSchema, "properties.calibration", {}),
                },
            };
            return filterParams(forms, props.expectedImpactFilter);
        }

        return {};
    }, [formSchema, props.featureType, props.expectedImpactFilter]);

    return (
        <JSONSchemaForm
            data={config}
            schema={filteredSchema}
            invalidFields={props.invalidFields}
            setConfig={setConfig}
            featureIndex={props.featureIndex}
            schemaPathPrefix={props.featureType}
            readOnly={props.readOnly}
        />
    );
};

export const EncoderForm = (props: any) => {
    return <CoderForm {...props} featureType={"input_features"} />;
};

export const DecoderForm = (props: any) => {
    return <CoderForm {...props} featureType={"output_features"} />;
};
