import { useQuery, useQueryClient, UseQueryOptions } from "@tanstack/react-query";
import { useEffect } from "react";
import Sockette from "sockette";
import { deployment, deploymentEventUpdate, deploymentStatusUpdate } from "../../api_generated";
import metrics from "../../metrics/metrics";
import { DEPLOYMENTS_CONSTANT } from "../../query";
import { getWebSocketEndpointV2, useAuth0Token } from "../../utils/api";
import { deploymentUpdate, isDeploymentEventUpdate } from "./data-utils";
import { GET_DEPLOYMENT_QUERY_KEY } from "./query";

export const GET_DEPLOYMENT_EVENT_UPDATES_QUERY_KEY = (deploymentUUID: string) => [
    DEPLOYMENTS_CONSTANT,
    deploymentUUID,
    "events",
];

/**
 * WARNING: this query is reliant on a connection already being established with the deployment updates websocket!
 */
export const useDeploymentEventUpdatesQuery = (
    deploymentUUID: string,
    options?: Partial<UseQueryOptions<deploymentEventUpdate[]>>,
) => {
    return useQuery<deploymentEventUpdate[]>({
        queryKey: GET_DEPLOYMENT_EVENT_UPDATES_QUERY_KEY(deploymentUUID),
        ...options,
    });
};

/**
 * Connects to the (general) deployment updates websocket. WARNING: updates the data for multiple queries!
 */
export const useDeploymentUpdatesWebsocket = (deploymentUUID: string) => {
    const queryClient = useQueryClient();
    const deploymentEventsQueryKey = GET_DEPLOYMENT_EVENT_UPDATES_QUERY_KEY(deploymentUUID);
    const deploymentQueryKey = GET_DEPLOYMENT_QUERY_KEY(deploymentUUID);
    const websocketServerAddress = getWebSocketEndpointV2();
    const endpoint = websocketServerAddress + `/deployments/${deploymentUUID}/events`;
    const bearerToken = useAuth0Token();

    useEffect(() => {
        const captureError = (e: Event, type: string) => {
            const code = e instanceof CloseEvent ? e.code : undefined;
            metrics.captureError("ws_error", String(code), {
                type,
                deploymentUUID,
                endpoint,
            });
        };

        const handleDeploymentUpdate = (e: MessageEvent<string>) => {
            const data: deploymentUpdate = JSON.parse(e.data);
            if (isDeploymentEventUpdate(data)) {
                queryClient.setQueryData<deploymentEventUpdate[]>(
                    deploymentEventsQueryKey,
                    (prev?: deploymentEventUpdate[]) => {
                        if (prev === undefined) {
                            // The first message will not have a previous value
                            return [data];
                        }
                        return [...prev, data];
                    },
                );
            } else {
                // Otherwise, is a deployment status update:
                queryClient.setQueryData<deployment>(deploymentQueryKey, (prev?: deployment) => {
                    if (prev === undefined) {
                        // TODO: We should come up with something better:
                        // This should never happen in practice - we should always have a deployment cached before we start
                        // streaming updates - but we need to keep the type checker happy...
                        return {} as deployment;
                    } else {
                        return {
                            ...prev,
                            // TODO: is this the right type here?
                            status: (data as deploymentStatusUpdate).deploymentStatus,
                        };
                    }
                });
            }
        };

        // Set the cache for event-type updates to an empty array to avoid duplicate events:
        queryClient.setQueryData(deploymentEventsQueryKey, () => []);

        const websocket = new Sockette(endpoint, {
            protocols: bearerToken ? ["predibase", bearerToken] : ["predibase"],
            timeout: 5e3,
            maxAttempts: 3,
            onmaximum: (e) => captureError(e, "onmaximum"),
            onerror: (e) => captureError(e, "onerror"),
            onreconnect: (e) => {
                // Clear the cache on reconnect to avoid duplicate events
                queryClient.setQueryData(deploymentEventsQueryKey, () => []);
                captureError(e, "onreconnect");
            },
            onmessage: handleDeploymentUpdate,
        });

        return () => {
            websocket.close();
        };
    }, [deploymentUUID, bearerToken]); // eslint-disable-line react-hooks/exhaustive-deps
};

// Custom hooks:

/**
 * WARNING: Sets up the general updates websocket and returns the query for event updates!
 */
export const useDeploymentEventUpdates = (
    deploymentUUID: string,
    options?: Partial<UseQueryOptions<deploymentEventUpdate[]>>,
) => {
    const query = useDeploymentEventUpdatesQuery(deploymentUUID, options);
    useDeploymentUpdatesWebsocket(deploymentUUID);
    // TODO: Not sure if you need to declare the query before you can set data for it:
    return query;
};
