import { CSSProperties } from "react";
import { Popup } from "semantic-ui-react";
import { formatValueToNumericString } from "../utils/numbers";

const FormattedNumberTooltip = (props: {
    value: any;
    maxFractionDigits?: number;
    maxSignificantDigits?: number;
    showInvalid?: boolean;
    style?: CSSProperties;
    truncatedStyle?: CSSProperties;
}) => {
    const convValue = formatValueToNumericString(
        props.value,
        props.maxFractionDigits,
        props.maxSignificantDigits,
        props.showInvalid,
    );
    const truncatedStyle = props.truncatedStyle ? props.truncatedStyle : undefined;
    const style = props.style ? props.style : undefined;

    if (String(props.value) !== convValue) {
        return (
            <Popup
                className="transition-scale"
                position="top center"
                trigger={<span style={truncatedStyle}>{convValue}</span>}
                content={props.value}
            />
        );
    }

    return <span style={style}>{convValue}</span>;
};

export default FormattedNumberTooltip;
