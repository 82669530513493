import { Divider } from "semantic-ui-react";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";

function NoResultsTableRow(props: { colSpan: number }) {
    return (
        <Table.Row>
            <Table.Cell
                colSpan={props.colSpan}
                textAlign={"center"}
                verticalAlign={"middle"}
                style={{ pointerEvents: "none" }}
            >
                <Divider hidden />
                <img src={"/components/table-no-results.svg"} style={{ marginBottom: "10px" }} alt="" />
                <br />
                No Results Found
                <Divider hidden />
            </Table.Cell>
        </Table.Row>
    );
}

export default NoResultsTableRow;
