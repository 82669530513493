import { Auth0ContextInterface, useAuth0 } from "@auth0/auth0-react";
import { AxiosResponse } from "axios";
import { useContext } from "react";
import { getTraceId } from "./api/trace";
import { currentUser, deployment } from "./api_generated";
import { EnvironmentStatus } from "./auth/EnvironmentViewRouter";
import metrics from "./metrics/metrics";
import { UserContext } from "./types/user";
import { createV1APIServer, createV2APIServer, redirectIfSessionInvalid } from "./utils/api";
import { getErrorMessage } from "./utils/errors";
import { FeatureFlagsContext, getPosthogFeatureFlag, POSTHOG_FEATURE_FLAG } from "./utils/feature-flags";

// TODO: We should decide whether we'll un-nest for every API call or not...
export interface APIResponse<T> {
    data: T;
}

// Auth0 utils:
export interface Auth0TokenOptions {
    auth0Enabled: boolean;
    getAccessTokenSilently?: Auth0ContextInterface["getAccessTokenSilently"];
    getAccessTokenWithPopup?: Auth0ContextInterface["getAccessTokenWithPopup"];
    loginWithPopup?: Auth0ContextInterface["loginWithPopup"];
}
export const useAuth0TokenOptions = () => {
    const { posthogFeatureFlags } = useContext(FeatureFlagsContext);
    const auth0Enabled = getPosthogFeatureFlag(POSTHOG_FEATURE_FLAG.Auth0, posthogFeatureFlags);
    const { getAccessTokenSilently, getAccessTokenWithPopup, loginWithPopup } = useAuth0();
    return { auth0Enabled, getAccessTokenSilently, getAccessTokenWithPopup, loginWithPopup } as Auth0TokenOptions;
};

// GET operations:
export const getUserContext = async (auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = "/users/current";
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .get(endpoint)
        .then((res: AxiosResponse<UserContext>) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                trace_id: getTraceId(error),
            });
            throw errorMsg;
        });
};

export const getUserContextV2 = async (auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = "/users/current";
    const apiServer = await createV2APIServer(auth0TokenOptions);

    return apiServer
        .get(endpoint)
        .then((res: AxiosResponse<currentUser>) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                trace_id: getTraceId(error),
            });
            throw errorMsg;
        });
};

export const getEnvironment = async (shortCode: string, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = `/environments/${shortCode}`;
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .get(endpoint)
        .then((res: AxiosResponse<EnvironmentResponse>) => {
            return res.data;
        })
        .catch((err) => {
            throw err.message;
        });
};

// * NOTE: Transferred from the top-level types folder because that folder should be rethought and they are no longer
// pure/declarative (dependent on generated EnvironmentStatus enum).
// TODO: At the same time, these types are out of date, we're just adding missing fields by hand. They should be
// autogenerated too...
export interface Environment {
    id: number;
    name: string;
    uuid: string;
    type: string;
    cloud: string;
    region: string;
    shortCode: string; // TODO: temporary, idk, this whole thing should be deleted.
    status: EnvironmentStatus; // TODO: temporary, idk, this whole thing should be deleted.
}

export interface EnvironmentResponse {
    id: number;
    uuid: string;
    shortCode: string;
    name: string;
    type: string;
    status: string;
    provisioningSteps: string[];
    Tenants: object;
    cloud: string;
    region: string;
    created: string;
    updated: string;
    cloudRoleName: string;
}

// TODO: Should be autogenerated:
export interface ListEnvironmentsResponse {
    environments: Environment[];
}
export const getEnvironments = async (auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = "/environments";
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .get<ListEnvironmentsResponse>(endpoint)
        .then((res) => {
            return res.data.environments;
        })
        .catch((err) => {
            metrics.captureError("api_error", err.message, {
                method: "GET",
                endpoint,
                trace_id: getTraceId(err),
            });
            // Don't redirect if the user is not logged in
            throw err.message;
        });
};

// TODO: Replace above when ready
// TODO: fix case for autogenerated types
// TODO: wrapping respones with `data`?
// TODO: Rework vars/langauge around this API to "deployments" or "llm deployments" instead of just "llms"
export interface ListDeploymentsResponse {
    data: deployment[] | null; // TODO: Remove null when we fix gateway middleware!
}
export const getDeployments = async (auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = "deployments";
    const v2APIServer = await createV2APIServer(auth0TokenOptions);

    return v2APIServer
        .get<ListDeploymentsResponse>(endpoint)
        .then((res) => {
            return res.data.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

export const getModelRepos = async (params?: PaginationParams, auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = "models";
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .get<FetchModelReposResponse>("models", { params: { limit: 100, ...params } }) // TODO: large limit...
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                trace_id: getTraceId(error),
            });
            redirectIfSessionInvalid(errorMsg);
            throw errorMsg;
        });
};

// TODO: Should be autogenerated:
export interface UserCredits {
    balance: number;
}
export const getUserCredits = async (auth0TokenOptions?: Auth0TokenOptions) => {
    const endpoint = "/users/current/credits";
    const apiServer = await createV1APIServer(auth0TokenOptions);

    return apiServer
        .get(endpoint)
        .then((res: AxiosResponse<UserCredits>) => {
            return res.data;
        })
        .catch((error: any) => {
            const errorMsg = getErrorMessage(error) ?? "";
            metrics.captureError("api_error", errorMsg, {
                method: "GET",
                endpoint,
                trace_id: getTraceId(error),
            });
            throw errorMsg;
        });
};
