import { useMutation } from "@tanstack/react-query";
import _ from "lodash";
import React, { memo, useCallback, useEffect, useState } from "react";
import JSONPretty from "react-json-pretty";
import nl2br from "react-newline-to-break";
import { Link } from "react-router-dom";
import { Header, Image, Loader, Modal, Segment, Table } from "semantic-ui-react";
import { useAuth0TokenOptions } from "../../../data";
import { getDatasetThumbnails } from "../../../data/data";
import metrics from "../../../metrics/metrics";
import { DatasetProfile } from "../../../predibase_api/profiles/v1/dataset_profile";
import { AUDIO_EXTENSIONS_REGEX, IMAGE_EXTENSIONS_REGEX } from "../../../utils/constants";
import { formatValueToNumericString } from "../../../utils/numbers";
import { noFunctionCompare } from "../../../utils/reactUtils";
import { alphabeticalCollator } from "../../../utils/sort";
import { isJSONString } from "../../../utils/strings";
import ExplanationArrayModal from "./ExplanationArrayModal";
import ExplanationModal from "./ExplanationModal";
import PredictionCell from "./PredictionCell";
import ProfileCell from "./ProfileCell";
import { ProfileVizCell } from "./ProfileVizCell";

import "./ResultsTable.css";

function isFloat(n: any) {
    return Number(n) === n && n % 1 !== 0;
}

export const isImagePath = (v: any) => {
    if (typeof v === "string" || v instanceof String) {
        return v.toLowerCase().match(IMAGE_EXTENSIONS_REGEX) !== null;
    }
    return false;
};

export const isAudioPath = (v: any) => {
    if (typeof v === "string" || v instanceof String) {
        return v.toLowerCase().match(AUDIO_EXTENSIONS_REGEX) !== null;
    }
    return false;
};

function valueToString(value: any, numCols: number) {
    if (value === null || value === undefined) {
        return "";
    }

    if (isFloat(value)) {
        value = formatValueToNumericString(value, 2);
    }

    return String(value);
}

function valueToCell(value: any, flaggedValue: any, metadata: any, numCols: number, callback: (value: any) => void) {
    if (metadata.type === "prediction") {
        return <PredictionCell value={valueToString(value, numCols)} flaggedValue={flaggedValue} callback={callback} />;
    } else if (metadata.type === "explanation") {
        return (
            <button className="button-reset-table">
                <ExplanationModal data={value} metadata={metadata} />
            </button>
        );
    } else if (metadata.type === "model_config") {
        return (
            <Link to={"/models/repo/" + value.id} style={{ color: "#1890ff" }}>
                {value.text}
            </Link>
        );
    } else if (metadata.type === "model_version") {
        return (
            <Link to={"/models/version/" + value.id} style={{ color: "#1890ff" }}>
                {value.text}
            </Link>
        );
    }

    return valueToString(value, numCols);
}

function getTableCell(
    value: any,
    index: number,
    flaggedValue: any,
    rowIndex: number,
    column: string,
    metadata: any,
    isImage: boolean,
    isAudio: boolean,
    thumbnails: ThumbnailsMap | null,
    callback: (value: any) => void,
    numCols: number,
) {
    let cellValue: any = "";
    const sValue = value;
    if (metadata) {
        const isFlagged = flaggedValue && flaggedValue !== value;
        return (
            <Table.Cell warning={isFlagged} key={index}>
                {valueToCell(value, flaggedValue, metadata, numCols, callback)}
            </Table.Cell>
        );
    } else {
        cellValue = valueToString(value, numCols);
        const ogCellValue = cellValue;
        if (isImage && cellValue !== "") {
            const imgPath = rowIndex + "," + index;
            // TODO(Kabir): Investigate why gateway sends back empty thumbnail response.
            if (_.has(thumbnails, imgPath)) {
                cellValue = <img src={thumbnails?.[imgPath]} title={sValue} alt={ogCellValue} />;
            } else {
                cellValue = <Loader active inline />;
            }
            value = <Image src={value} centered />;
        } else if (isAudio && cellValue !== "") {
            cellValue = (
                <>
                    {ogCellValue}
                    <audio style={{ height: "40px" }} controls loop src={value} title={sValue} />
                </>
            );
            value = (
                <>
                    <Header size={"small"}>{value}</Header>
                    <Segment>
                        <audio controls loop src={value} />
                    </Segment>
                </>
            );
        }

        if (typeof value === "boolean") {
            value = value.toString();
        }
        if (typeof value === "object") {
            cellValue = JSON.stringify(value);
        }

        const content =
            isJSONString(value) || typeof value === "object" ? (
                <JSONPretty
                    className="content"
                    mainStyle="font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen'; overflow: auto;"
                    data={value}
                />
            ) : typeof value === "string" ? (
                nl2br(value)
            ) : (
                String(value)
            );

        return (
            <Table.Cell selectable key={index}>
                <Modal
                    className={metrics.BLOCK_AUTO_CAPTURE}
                    trigger={
                        <button className={`${metrics.BLOCK_AUTO_CAPTURE} button-reset-table`}>
                            {typeof value === "string" || typeof cellValue === "string" ? (
                                <span className="text-cell-truncation">{cellValue}</span>
                            ) : (
                                cellValue
                            )}
                        </button>
                    }
                    header={
                        isImage || isAudio ? `Row ${rowIndex} : ${column} (${sValue})` : `Row ${rowIndex} : ${column}`
                    }
                    content={content}
                    actions={["Close"]}
                />
            </Table.Cell>
        );
    }
}

function valueToHeaderCell(column: string, index: number, metadata: any, data: any) {
    if (metadata.type === "llm_prediction") {
        return <>{column}</>;
    }
    if (metadata.type === "explanation") {
        return <ExplanationArrayModal name={column} index={index} metadata={metadata} data={data} />;
    } else {
        return column;
    }
}

function getMetadata(dataset: ResultsDataset, index: number) {
    const column = dataset?.columns?.[index];
    return column ? dataset.metadata[column] : undefined;
}

const ResultsHeaderCell = (props: { column: string; index: number; metadata: any; data: any }): JSX.Element => {
    if (props.metadata?.type === "llm_prediction") {
        return (
            <Table.HeaderCell selectable singleLine key={props.index}>
                <span>{props.column}</span>
            </Table.HeaderCell>
        );
    }
    if (props.metadata && props.metadata["header"]) {
        return (
            <Table.HeaderCell selectable singleLine key={props.index}>
                {valueToHeaderCell(props.column, props.index, props.metadata, props.data)}
            </Table.HeaderCell>
        );
    } else {
        return (
            <Table.HeaderCell singleLine key={props.index}>
                {props.column}
            </Table.HeaderCell>
        );
    }
};

const ResultsTable = (props: {
    query?: Query;
    datasetID?: number;
    datasetProfile?: DatasetProfile;
    dataset: ResultsDataset;
    flags: any;
    onCellChanged?: (row: number, valueIndex: number, value: any) => void;
    mutableColumns?: MutableDatasetColumn[];
    setMutableColumns?: React.Dispatch<React.SetStateAction<MutableDatasetColumn[] | undefined>>;
}) => {
    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    // Local state:
    const [thumbnails, setThumbnails] = useState<ThumbnailsMap | null>(null);

    const { mutate: mutateGetThumbnails } = useMutation({
        mutationFn: (data: any) => getDatasetThumbnails(props.query?.id, props.datasetID, data, auth0TokenOptions),
        onSuccess: (data) => {
            setThumbnails(data);
        },
    });

    const getThumbnails = useCallback(() => {
        const imageMap = new Map();
        props.dataset.data?.forEach((row: any[], rowIndex: number) => {
            row.forEach((value, valueIndex) => {
                const column = props.dataset.columns[valueIndex];
                if (isImage(column, value)) {
                    imageMap.set([rowIndex, valueIndex], value);
                }
            });
        });

        mutateGetThumbnails(Object.fromEntries(imageMap));
    }, [props.dataset.columns, props.dataset.data, props.datasetID, props.query?.id]);

    useEffect(() => {
        setThumbnails(null);
        getThumbnails();
    }, [props.dataset, getThumbnails]);

    const isImage = (column: string, value: any) => isImagePath(value);
    const isAudio = (column: string, value: any) => isAudioPath(value);

    return (
        <Table celled padded className="freeze-header">
            <Table.Header>
                <Table.Row>
                    {props.dataset?.columns?.map((column: string, columnIndex: number) => {
                        return (
                            <ResultsHeaderCell
                                column={column}
                                index={columnIndex}
                                metadata={props.dataset.metadata[column]}
                                data={props.dataset.data}
                            />
                        );
                    })}
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {/* Legacy datasets may not have dataset profiles. */}
                {props.datasetProfile && (
                    <Table.Row>
                        {// Generate a profile cell for each column.
                        props.dataset?.columns?.map((column: string, columnIndex: number) => {
                            return (
                                <ProfileVizCell
                                    columnName={column}
                                    columnIndex={columnIndex}
                                    datasetProfile={props.datasetProfile}
                                    key={"datasetProfileViz:" + columnIndex}
                                />
                            );
                        })}
                    </Table.Row>
                )}
                {props.datasetProfile && (
                    <Table.Row>
                        {// Generate a profile cell for each column.
                        props.dataset?.columns?.map((column: string, columnIndex: number) => {
                            return (
                                <ProfileCell
                                    columnName={column}
                                    columnIndex={columnIndex}
                                    datasetProfile={props.datasetProfile}
                                    key={"datasetProfile:" + columnIndex}
                                />
                            );
                        })}
                    </Table.Row>
                )}
                {props.dataset?.data?.map((row: any[], rowIndex: number) => {
                    props.dataset.columns
                        .map((_, idx) => idx)
                        .sort((a, b) =>
                            alphabeticalCollator.compare(props.dataset.columns[a], props.dataset.columns[b]),
                        )
                        .filter((idx) =>
                            props.dataset?.metadata.input_rows
                                ? props.dataset?.metadata.input_rows.includes(props.dataset.columns[idx])
                                : true,
                        )
                        .map((idx) => ({
                            [props.dataset.columns[idx]]: row[idx],
                        }));
                    return (
                        <Table.Row key={rowIndex}>
                            {row.map((value, valueIndex) => {
                                let flaggedValue = null;
                                const column = props.dataset.columns[valueIndex];
                                if (props.flags[column]) {
                                    flaggedValue = props.flags[column][rowIndex];
                                }

                                return getTableCell(
                                    value,
                                    valueIndex,
                                    flaggedValue,
                                    rowIndex,
                                    column,
                                    getMetadata(props.dataset, valueIndex),
                                    isImage(column, value),
                                    isAudio(column, value),
                                    thumbnails,
                                    (newValue: any) => props.onCellChanged?.(rowIndex, valueIndex, newValue),
                                    row.length,
                                );
                            })}
                        </Table.Row>
                    );
                })}
            </Table.Body>
        </Table>
    );
};

export default memo(ResultsTable, noFunctionCompare);
