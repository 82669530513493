import React from "react";
import { Divider, Icon, Label, List, Popup } from "semantic-ui-react";

export const defaultValueMessage = (defaultValue: any) => {
    return "Default: " + defaultValue;
};

const getTooltipOptions = (lst: string[], link: any = null) => {
    return (
        <React.Fragment>
            Options: {link}
            <Divider />
            <List horizontal celled>
                {lst.map((x) => (
                    <List.Item key={x}>{x}</List.Item>
                ))}
            </List>
        </React.Fragment>
    );
};

const kerasInitializers = [
    "constant",
    "identity",
    "zeros",
    "ones",
    "orthogonal",
    "normal",
    "uniform",
    "truncated_normal",
    "variance_scaling",
    "glorot_normal",
    "glorot_uniform",
    "xavier_normal",
    "xavier_uniform",
    "he_normal",
    "he_uniform",
    "lecun_normal",
    "lecun_uniform",
];
const kerasLink = (
    <a href="https://www.tensorflow.org/api_docs/python/tf/keras/initializers" target="_blank" rel="noreferrer">
        Initializers
    </a>
);
const optimizersLink = (
    <a href="https://www.tensorflow.org/api_docs/python/tf/keras/optimizers" target="_blank" rel="noreferrer">
        Optimizers
    </a>
);

export const PreprocessingTooltipMap: Record<string, React.ReactNode> = {
    default_data_type: (
        <React.Fragment>
            <List bulleted>
                <List.Item>
                    Category
                    <List.List>
                        Category features are transformed into an integer valued vector of size n (where n is the size
                        of the dataset) and added to the HDF5 with a key that reflects the name of column in the
                        dataset. The way categories are mapped into integers consists in first collecting a dictionary
                        of all the different category strings present in the column of the dataset, then ranking them by
                        frequency and then assigning them an increasing integer ID from the most frequent to the most
                        rare (with 0 being assigned to a UNK token).
                    </List.List>
                </List.Item>
                <List.Item>
                    Binary
                    <List.List>
                        Binary features are directly transformed into a binary valued vector of length n (where n is the
                        size of the dataset) and added to the HDF5 with a key that reflects the name of column in the
                        dataset.
                    </List.List>
                </List.Item>
                <List.Item>
                    Number
                    <List.List>
                        Number features are directly transformed into a float valued vector of length n (where n is the
                        size of the dataset) and added to the HDF5 with a key that reflects the name of column in the
                        dataset.
                    </List.List>
                </List.Item>
            </List>
        </React.Fragment>
    ),
    missing_value_strategy: (
        <React.Fragment>
            What strategy to follow when there's a missing value in a binary column. Options:
            <Divider />
            <List bulleted>
                <List.Item>
                    fill_with_const
                    <List.List>
                        replaces the missing value with a specific value specified with the fill_value parameter.
                    </List.List>
                </List.Item>
                <List.Item>
                    fill_with_mode
                    <List.List>replaces the missing values with the most frequent value in the column.</List.List>
                </List.Item>
                <List.Item>
                    fill_with_mean
                    <List.List>replaces the missing values with the mean of the values in the column.</List.List>
                </List.Item>
                <List.Item>
                    backfill
                    <List.List>replaces the missing values with the next valid value).</List.List>
                </List.Item>
            </List>
        </React.Fragment>
    ),
    fill_value: "The value to replace the missing values with in case the missing_value_strategy is fill_with_const.",
    most_common:
        "The maximum number of most common tokens to be considered. if the data contains more than this amount, the most infrequent tokens will be treated as unknown.",
    lowercase: "If the string has to be lowercased before being handled by the tokenizer.",
    normalization: (
        <React.Fragment>
            Technique to be used when normalizing the number feature types. The available options are:
            <br />
            <List bulleted>
                <List.Item>
                    null
                    <List.List>No normalization is performed.</List.List>
                </List.Item>
                <List.Item>
                    zscore
                    <List.List>
                        The mean and standard deviation are computed so that values are shifted to have zero mean and 1
                        standard deviation.
                    </List.List>
                </List.Item>
                <List.Item>
                    minmax
                    <List.List>
                        Minimum and maximum values are computed and the minimum is subtracted from values and the result
                        is divided by difference between maximum and minimum.
                    </List.List>
                </List.Item>
                <List.Item>
                    log1p
                    <List.List>
                        The value returned is the natural log of 1 plus the original value. Note: log1p is defined only
                        for positive values.
                    </List.List>
                </List.Item>
            </List>
        </React.Fragment>
    ),
    force_split:
        "If true the split column in the dataset file is ignored and the dataset is randomly split. If false the split column is used if available.",
    split_probabilities:
        "The proportion of the dataset data to end up in training, validation and test, respectively. The three values have to sum up to one.",
    stratify:
        "If null the split is random, otherwise you can specify the name of a category feature and the split will be stratified on that feature.",
};

export const ArchitectureTooltipMap = {
    preprocessing:
        "What strategy to follow when there's a missing value in a binary column. The value should be one of fill_with_const (replaces the missing value with a specific value specified with the fill_value parameter), fill_with_mode (replaces the missing values with the most frequent value in the column), fill_with_mean (replaces the missing values with the mean of the values in the column), backfill (replaces the missing values with the next valid value).",
    fc_size: "Output size of a fully connected layer.",
    use_bias: "Whether the layer uses a bias vector.",
    weights_initializer: getTooltipOptions(kerasInitializers, kerasLink),
    bias_initializer: getTooltipOptions(kerasInitializers, kerasLink),
    flatten_inputs: "Flatten input tensors to a vector.",
    residual:
        "Adds a residual connection to each fully connected layer block. All fully connected layers must have the same size.",
    hidden_size:
        "The size of the hidden representation within the transformer block. It is usually the same of the embedding_size, but if the two values are different, a projection layer will be added before the first transformer block.",
    num_heads: "Number of heads of the self attention in the transformer block.",
    transformer_fc_size:
        "Size of the fully connected layer after self attention in the transformer block. This is usually the same as hidden_size and embedding_size.",
    dropout: "Dropout rate for the transformer block",
    num_fc_layers: "The number of stacked fully connected layers (only applies if reduce_output is not null).",

    num_steps:
        "Number of steps / repetitions of the the attentive transformer and feature transformer computations. N_steps in the paper.",
    num_total_blocks: "Total number of feature transformer block at each step.",
    num_shared_blocks: "Number of shared feature transformer blocks across the steps.",
    relaxation_factor:
        "Factor that influences how many times a feature should be used across the steps of computation. a value of 1 implies it each feature should be use once, a higher value allows for multiple usages. gamma in the paper.",
    bn_epsilon: "Epsilon to be added to the batch norm denominator.",
    bn_momentum: "Momentum of the batch norm. m_B in the paper.",
    bn_virtual_bs:
        "Size of the virtual batch size used by ghost batch norm. If null, regular batch norm is used instead. B_v from the paper.",
    sparsity: "Multiplier of the sparsity inducing loss. lambda_sparse in the paper.",
};

export const TrainingTooltipMap: Record<string, string> = {
    epochs: "Number of epochs for training.",
    regularization_lambda: "Lambda parameter used for adding a l2 regularization loss to the overall loss.",
    eval_batch_size:
        "Size of the batch used for evaluating the model. If it is 0, the same value of batch_size is used. This is useful to speedup evaluation with a much bigger batch size than training, if enough memory is available, or to decrease the batch size when sampled_softmax_cross_entropy is used as loss for sequential and categorical features with big vocabulary sizes (evaluation needs to be performed on the full vocabulary, so a much smaller batch size may be needed to fit the activation tensors in memory).",
    early_stop:
        "If there's a validation set, number of epochs of patience without an improvement on the validation measure before the training is stopped.",
    reduce_learning_rate_on_plateau:
        "If there's a validation set, how many times to reduce the learning rate when a plateau of validation measure is reached.",
    reduce_learning_rate_on_plateau_patience:
        "If there's a validation set, number of epochs of patience without an improvement on the validation measure before reducing the learning rate.",
    reduce_learning_rate_on_plateau_rate: "If there's a validation set, the reduction rate of the learning rate.",
    increase_batch_size_on_plateau:
        "If there's a validation set, how many times to increase the batch size when a plateau of validation measure is reached.",
    increase_batch_size_on_plateau_patience:
        "If there's a validation set, number of epochs of patience without an improvement on the validation measure before increasing the learning rate.",
    increase_batch_size_on_plateau_rate: "If there's a validation set, the increase rate of the batch size.",
    increase_batch_size_on_plateau_max: "If there's a validation set, the maximum value of batch size.",
    validation_field:
        "When there is more than one output feature, which one to use for computing if there was an improvement on validation. The measure to use to determine if there was an improvement can be set with the validation_measure parameter. Different datatypes have different available measures, refer to the datatype-specific section for more details. combined indicates the use the combination of all features. For instance the combination of combined and loss as measure uses a decrease in the combined loss of all output features to check for improvement on validation, while combined and accuracy considers on how many datapoints the predictions for all output features were correct (but consider that for some features, for instance numeric there is no accuracy measure, so you should use accuracy only if all your output features have an accuracy measure).",
    validation_metric:
        "The metric to use to determine if there was an improvement. The metric is considered for the output feature specified in validation_field. Different datatypes have different available metrics, refer to the datatype-specific section for more details.",
    decay: "Turn on exponential decay of the learning rate.",
    staircase: "Decays the learning rate at discrete intervals.",
};

export const OptimizerTooltipMap = {
    type: optimizersLink,
};

export const getTooltip = (
    metricName: string,
    name: string,
    info: React.ReactNode,
    defaultValue?: CreateModelValue,
) => {
    let defaultMsg = defaultValueMessage(defaultValue);
    const defaultLabel = <Label size="tiny">{defaultMsg}</Label>;
    const data = info ? (
        <span>
            {info}
            <Divider />
            {defaultLabel}
        </span>
    ) : (
        defaultLabel
    );

    return (
        <Popup
            className="transition-scale"
            hoverable
            wide={"very"}
            position={"right center"}
            trigger={<Icon name={"question circle"} color={"blue"} />}
            content={data}
        />
    );
};
