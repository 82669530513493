import { keepPreviousData, useQueryClient } from "@tanstack/react-query";
import React, { ReactElement, useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Button, Divider, Header, Icon, Loader, Message, Pagination, Segment } from "semantic-ui-react";
import { PaginationProps } from "semantic-ui-react/dist/commonjs/addons/Pagination/Pagination";
import SimpleSearch from "../../components/SimpleSearch";
import { useDatasetsQuery } from "../../data/query";
import { GET_MODEL_REPOS_QUERY_KEY, useModelReposQuery } from "../../query";
import {
    MODELS_OFFSET_STATE,
    MODELS_SCOPE_USER_STATE,
    MODELS_SEARCH_VAL_STATE,
    MODELS_TOTAL_STATE,
} from "../../state/models";
import { getErrorMessage } from "../../utils/errors";
import { generateParamsNoRef, getActivePage, getTotalPages } from "../../utils/pagination";
import CreateModelRepoModal from "./CreateModelRepoModal";
import ModelRepoTableBase from "./ModelRepoTableBase";

const MODELS_PER_PAGE = 10;

const ListModelReposTemplate = (props: { body?: ReactElement; error?: ReactElement; removePadding?: boolean }) => (
    <div style={{ padding: props.removePadding ? undefined : "20px" }}>
        {props.body}
        <Divider hidden />
        {props.error && <div className="row">{props.error}</div>}
    </div>
);

const ListModelReposView = (props: {
    indexKeys?: string;
    indexVals?: number;
    ignoreRecoilState?: boolean;
    removePadding?: boolean;
}) => {
    const [modelsEmpty, setModelsEmpty] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);

    const [modelsOffset, setModelsOffset] = useRecoilState(MODELS_OFFSET_STATE);
    const [modelsTotal, setModelsTotal] = useRecoilState(MODELS_TOTAL_STATE);
    const [scopeUser] = useRecoilState(MODELS_SCOPE_USER_STATE);
    const [modelsSearchVal, setModelsSearchVal] = useRecoilState(MODELS_SEARCH_VAL_STATE);
    // SimpleSearch mutates the searchVal since using recoil state alone isn't sufficient
    const searchValRef = useRef(modelsSearchVal);

    // TODO: I hate this, but it's the simplest fix. The ref and timer usage nonsense here is so stupid.
    const [debouncedSearchVal, setDebouncedSearchValRef] = useState<string>(searchValRef.current);
    const params = useMemo(() => {
        return generateParamsNoRef(
            modelsOffset,
            MODELS_PER_PAGE,
            scopeUser,
            ["modelName", "description"],
            debouncedSearchVal,
            props.indexVals,
            props.indexKeys,
        );
    }, [debouncedSearchVal, modelsOffset, props.indexKeys, props.indexVals, scopeUser]);

    // Query (paginated) state:
    const queryClient = useQueryClient();
    const modelReposQuery = useModelReposQuery(params, {
        placeholderData: keepPreviousData,
        staleTime: 5 * 1000, // 5 seconds
        refetchInterval: 5 * 1000, // 5 seconds
    });
    const { data: modelReposResponse } = modelReposQuery;
    useEffect(() => {
        if (modelReposResponse) {
            setModelsOffset(modelReposResponse.offset);
            setModelsTotal(modelReposResponse.total);

            const models = modelReposResponse.modelRepos || [];
            if (
                initialLoad &&
                models.length === 0 &&
                params.indexKeys === undefined &&
                params.searchKeys === undefined
            ) {
                setModelsEmpty(true);
            } else if (models.length !== 0) {
                setModelsEmpty(false);
            }

            setInitialLoad(false);
        }
    }, [initialLoad, modelReposResponse, params.indexKeys, params.searchKeys, setModelsOffset, setModelsTotal]);

    const datasetsQuery = useDatasetsQuery(undefined, {
        staleTime: 5000,
    });

    const getActiveModelsPage = () => getActivePage(modelsOffset, MODELS_PER_PAGE);
    const getTotalModelsPage = () => getTotalPages(modelsTotal, MODELS_PER_PAGE);
    const handleModelsPageChange = (e: React.MouseEvent<HTMLAnchorElement>, { activePage }: PaginationProps) => {
        const offset = ((activePage as number) - 1) * MODELS_PER_PAGE;
        setModelsOffset(offset);
        queryClient.invalidateQueries({ queryKey: GET_MODEL_REPOS_QUERY_KEY(params) });
    };

    // Reset the Recoil state when removing this component
    useEffect(() => {
        if (props.indexVals) {
            setModelsOffset(0);
            setModelsSearchVal("");
        }
    }, []);

    if (datasetsQuery.status === "pending" || modelReposQuery.status === "pending") {
        return <ListModelReposTemplate body={<Loader active />} removePadding={props.removePadding} />;
    }

    if (datasetsQuery.status === "error" || typeof datasetsQuery.data === "undefined") {
        return (
            <ListModelReposTemplate
                error={
                    <Message negative>
                        <Message.Header>Unable to retrieve datasets.</Message.Header>
                        <p>{getErrorMessage(datasetsQuery.error)}</p>
                    </Message>
                }
                removePadding={props.removePadding}
            />
        );
    }

    if (modelReposQuery.status === "error" || typeof modelReposResponse === "undefined") {
        return (
            <ListModelReposTemplate
                error={
                    <Message negative>
                        <Message.Header>Unable to retrieve models.</Message.Header>
                        <p>{getErrorMessage(modelReposQuery.error)}</p>
                    </Message>
                }
                removePadding={props.removePadding}
            />
        );
    }

    const { datasets } = datasetsQuery.data;
    const { modelRepos } = modelReposResponse;

    if (datasets?.length === 0) {
        return (
            <ListModelReposTemplate
                body={
                    <Segment placeholder>
                        <Header icon>
                            <Icon name="exclamation triangle" />
                            No datasets have been configured. Go to Data {">"} Connect Data.
                        </Header>
                        <Link to="/data">
                            <Button primary>Connect Data</Button>
                        </Link>
                    </Segment>
                }
                removePadding={props.removePadding}
            />
        );
    }

    return (
        <ListModelReposTemplate
            body={
                <div>
                    <div>
                        <CreateModelRepoModal />
                    </div>
                    <ModelRepoTableBase
                        models={modelRepos}
                        searchbar={
                            <SimpleSearch
                                searchValRef={searchValRef}
                                searchValRecoilState={MODELS_SEARCH_VAL_STATE}
                                setOffset={setModelsOffset}
                                placeholder={"Search..."}
                                ignoreRecoilState={props.ignoreRecoilState}
                                debouncedFunction={(debouncedParams: PaginationParams) => {
                                    setDebouncedSearchValRef(debouncedParams.searchVals[0]);
                                }}
                            />
                        }
                        empty={modelsEmpty}
                    />
                    <div style={{ justifyContent: "center", display: "flex" }}>
                        <Pagination
                            activePage={getActiveModelsPage()}
                            totalPages={getTotalModelsPage()}
                            onPageChange={handleModelsPageChange}
                            firstItem={{ content: <Icon name="angle double left" />, icon: true }}
                            lastItem={{ content: <Icon name="angle double right" />, icon: true }}
                            prevItem={{ content: <Icon name="angle left" />, icon: true }}
                            nextItem={{ content: <Icon name="angle right" />, icon: true }}
                        />
                    </div>
                </div>
            }
            removePadding={props.removePadding}
        />
    );
};

export default ListModelReposView;
