import { Dispatch, SetStateAction, useState } from "react";
import { useRecoilState } from "recoil";
import { Header, Modal } from "semantic-ui-react";
import EngineSelector from "../../engines/EngineSelector";
import { track } from "../../metrics/june";
import metrics from "../../metrics/metrics";
import { USER_STATE } from "../../state/global";
import { EngineServiceType } from "../../types/engineServiceType";

const RetrainModelModal = (props: {
    header: string;
    engineID: number;
    mutationFn: () => void;
    open?: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
}) => {
    // User state:
    const [user] = useRecoilState(USER_STATE);

    // Parent state:
    const { header, engineID: initEngineID, mutationFn, open, setOpen } = props;

    // Local state:
    const [engineID, setEngineID] = useState(initEngineID);

    return (
        <Modal
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            header={header}
            size={"mini"}
            content={
                <Modal.Content>
                    <Header as={"h2"} size={"tiny"}>
                        Engine to retrain with
                    </Header>
                    <EngineSelector
                        label={null}
                        engineID={engineID}
                        onEngineSelect={(e) => setEngineID(e.id)}
                        width={"100%"}
                        serviceTypes={[EngineServiceType.BATCH, EngineServiceType.RAY]}
                    />
                </Modal.Content>
            }
            actions={[
                { key: "close", content: "Close", color: "black" },
                {
                    key: "retrain",
                    content: "Retrain",
                    positive: true,
                    icon: "check",
                    labelPosition: "right",
                    onClick: () => {
                        metrics.capture("attempt_retrain");
                        user && track(user, "attempt_retrain");
                        mutationFn();
                        // callback({
                        //     repoID: model.repoID,
                        //     datasetID: model.datasetID,
                        //     config: model.config,
                        //     engineID: engineID,
                        //     parentID: model.id,
                        //     description: `Retrain of #${model.repoVersion}`,
                        // } as Model);
                    },
                },
            ]}
        />
    );
};

export default RetrainModelModal;
