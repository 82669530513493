import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AxiosInstance, AxiosResponse } from "axios";
import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { Button, Message, Modal } from "semantic-ui-react";
import { getTraceId } from "../../api/trace";
import { useAuth0TokenOptions } from "../../data";
import { track } from "../../metrics/june";
import metrics from "../../metrics/metrics";
import {
    ArtifactType,
    PresignedUrlForUploadRequest,
    PresignedUrlForUploadResponse,
    RegisterUploadedFileAsDatasetRequest,
} from "../../predibase_api/artifacts/v1/artifacts";
import { USER_STATE } from "../../state/global";
import { createV1APIServer, getDocsHome, redirectIfSessionInvalid } from "../../utils/api";
import { getErrorMessage } from "../../utils/errors";
import { Connector } from "./util";

function ConnectorPanel(props: {
    isEditView?: boolean;
    onSubmit: (connectionID: number) => void;
    isDisabled: () => boolean;
    trigger?: ReactNode;
    header: string;
    modalHeader: string;
    editModalHeader: string;
    submitLabel: string;
    name: string;
    errorHeader: string;
    fileUploadData?: FileUploadData;
    getUpdateRequest: () => any;
    getAddRequest?: () => any;
    getModalContent: () => ReactNode;
    reset: () => void;

    connection?: Connection;
    setConnection?: Dispatch<SetStateAction<Connection | undefined>>;

    connectorConfig: ConnectorConfig;
    setConnectorConfig: Dispatch<SetStateAction<ConnectorConfig>>;

    onStepChange?: Dispatch<SetStateAction<number>>;

    setOpen: Dispatch<SetStateAction<boolean>>;
    getBreadcrumb?: () => ReactNode;
}) {
    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [user] = useRecoilState(USER_STATE);
    const [apiServer, setAPIServer] = useState<AxiosInstance | null>(null);

    const isConnectFlow = props.getBreadcrumb !== undefined;

    useEffect(() => {
        const getAPIServer = async () => {
            const v1APIServer = await createV1APIServer(auth0TokenOptions);
            // NOTE: Whoever wrote the axios typings is a moron because the return type of axios.create is not
            // AxiosInstance -- it's a wrap function. And React will see that and treat it as a callback that
            // setState should directly call. FML.
            // See: [1], [2]:
            // [1]: https://github.com/axios/axios/issues/4365
            // [2]: https://stackoverflow.com/questions/64427195/calling-setstate-will-execute-the-function-value-instead-of-passing-it
            setAPIServer(() => v1APIServer);
        };
        getAPIServer();
    }, []);

    // TODO: omg...
    const add = () => {
        const properties = { connector: props.header };

        setLoading(true);
        let postRequest = props.getAddRequest ? props.getAddRequest() : undefined;

        if (props.fileUploadData && apiServer) {
            const file = props.fileUploadData.file;
            const fileName = props.fileUploadData.fileName;
            const mimeType = file?.type || "text/plain";

            const req: PresignedUrlForUploadRequest = {
                mimeType: mimeType,
                expirySeconds: 3600,
                artifactType: ArtifactType.DATASET,
                objectName: "", // Let a name be autogenerated
            };

            const createPresignedEndpoint = "datasets/get_presigned_url";
            apiServer
                .post(
                    createPresignedEndpoint,
                    PresignedUrlForUploadRequest.toJsonString(req, { useProtoFieldName: true }),
                    {
                        responseType: "json",
                    },
                )
                .then((res: AxiosResponse) => {
                    let resp = PresignedUrlForUploadResponse.fromJson(res.data);
                    const objectName = resp.objectName;
                    let headers: Record<string, string> = { "Content-Type": mimeType };
                    for (const [header, value] of Object.entries(resp.requiredHeaders || {})) {
                        headers[header] = value;
                    }

                    fetch(resp.url, {
                        method: "PUT",
                        body: file,
                        headers: headers,
                    })
                        .then((res) => {
                            if (!res.ok) {
                                throw new Error("Error uploading file to blob storage.");
                            }
                            const req: RegisterUploadedFileAsDatasetRequest = {
                                objectName: objectName,
                                datasetName: fileName.trim(),
                                fileName: file.name,
                            };

                            const registerEndpoint = "datasets/register_uploaded_file";
                            apiServer
                                .post(
                                    registerEndpoint,
                                    RegisterUploadedFileAsDatasetRequest.toJsonString(req, { useProtoFieldName: true }),
                                    {
                                        responseType: "json",
                                    },
                                )
                                .then(() => {
                                    setErrorMessage("");
                                    setLoading(false);

                                    props.setOpen(false);
                                    props.onSubmit(0);

                                    return;
                                })
                                .catch((error) => {
                                    const errorMsg = getErrorMessage(error) ?? "";
                                    setErrorMessage(errorMsg);
                                    setLoading(false);
                                    metrics.captureError("api_error", errorMsg, {
                                        method: "POST",
                                        endpoint: registerEndpoint,
                                        properties,
                                        trace_id: getTraceId(error),
                                    });
                                    redirectIfSessionInvalid(errorMsg);
                                });
                        })
                        .catch((error) => {
                            const errorMsg = getErrorMessage(error) ?? "";
                            setErrorMessage(errorMsg);
                            setLoading(false);
                            metrics.captureError("api_error", errorMsg, {
                                method: "PUT",
                                endpoint: resp.url,
                                properties,
                                trace_id: getTraceId(error),
                            });
                            redirectIfSessionInvalid(errorMsg);
                        });
                })
                .catch((error) => {
                    const errorMsg = getErrorMessage(error) ?? "";
                    setErrorMessage(errorMsg);
                    setLoading(false);
                    metrics.captureError("api_error", errorMsg, {
                        method: "POST",
                        endpoint: createPresignedEndpoint,
                        properties,
                        trace_id: getTraceId(error),
                    });
                    redirectIfSessionInvalid(errorMsg);
                });

            return; // Early exit for file uploads to the regular dataset connection flow
        }

        props.setConnectorConfig({ connectionName: postRequest.body.name, ...postRequest.body.config });

        if (apiServer) {
            apiServer
                .post(postRequest.endpoint, postRequest.body, postRequest.headers)
                .then((res: AxiosResponse<Connection>) => {
                    setErrorMessage("");
                    setLoading(false);

                    // Immediately return since we create connection/dataset in a single step for files and public datasets
                    if (props.name === Connector.FILE || props.name === Connector.PUBLIC_DATASETS) {
                        props.setOpen(false);
                        props.onSubmit(0);
                        return;
                    }
                    props.setConnection?.(res.data);
                    props.onStepChange?.((x) => x + 1);
                })
                .catch((error) => {
                    const errorMsg = getErrorMessage(error) ?? "";
                    setErrorMessage(errorMsg);
                    setLoading(false);
                    metrics.captureError("api_error", errorMsg, {
                        method: "POST",
                        endpoint: postRequest.endpoint,
                        properties,
                        trace_id: getTraceId(error),
                    });
                    redirectIfSessionInvalid(errorMsg);
                });
        }
    };

    const update = () => {
        setLoading(true);
        let postRequest = props.getUpdateRequest();
        const properties = { connector: props.header };

        if (apiServer) {
            apiServer
                .post(postRequest.endpoint, postRequest.body, postRequest.headers)
                .then((res) => {
                    const errorMsg = res.data.errorMessage || "";
                    setErrorMessage(errorMsg);
                    setLoading(false);
                    props.setOpen(errorMsg !== "");

                    // TODO: Why is there error handling code here?
                    if (errorMsg === "") {
                        props.onSubmit(props.connection?.id as number);
                    } else {
                        metrics.captureError("api_error", errorMsg, {
                            method: "POST",
                            endpoint: postRequest.endpoint,
                            properties,
                            // TODO: This error doesn't even exist:
                            // trace_id: getTraceId(error),
                        });
                    }
                })
                .catch((error) => {
                    const errorMsg = getErrorMessage(error) ?? "";
                    setErrorMessage(errorMsg);
                    setLoading(false);
                    metrics.captureError("api_error", errorMsg, {
                        method: "POST",
                        endpoint: postRequest.endpoint,
                        properties,
                        trace_id: getTraceId(error),
                    });
                    redirectIfSessionInvalid(errorMsg);
                });
        }
    };

    let modalHeader = props.modalHeader;
    let buttonName = props.submitLabel;
    const disabled = props.isDisabled();
    let onSubmit = add;

    if (props.connection && props.connection.id && props.connection.type) {
        modalHeader = props.editModalHeader;
        buttonName = "Update";
        onSubmit = update;
    }

    const getSubmitButton = () => {
        // Connection already exists, we can skip to createDatasets
        if (props.connection !== undefined) {
            return (
                <Button
                    content={"Next"}
                    labelPosition="right"
                    icon="arrow alternate circle right"
                    onClick={(event, data) => props.onSubmit(props.connection?.id as number)}
                    loading={loading}
                    positive
                />
            );
        } else {
            return (
                <Button
                    content={buttonName}
                    labelPosition="right"
                    icon="checkmark"
                    onClick={onSubmit}
                    loading={loading}
                    disabled={disabled}
                    positive
                />
            );
        }
    };

    if (apiServer === null) {
        return null;
    }

    return (
        <>
            <Modal.Header>
                {modalHeader}
                {props.getBreadcrumb ? (
                    <>
                        <br />
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                paddingTop: "0.5rem",
                            }}
                        >
                            {props.getBreadcrumb()}
                            {
                                // eslint-disable-next-line react/jsx-no-target-blank
                                <a
                                    style={{ fontSize: "1rem" }}
                                    onClick={() => {
                                        user &&
                                            track(user, "docs", {
                                                url: getDocsHome() + "/user-guide/fine-tuning/prepare-data",
                                                clickSource: "connector-panel-breadcrumb",
                                            });
                                    }}
                                    href={getDocsHome() + "/user-guide/fine-tuning/prepare-data"}
                                    target="_blank"
                                    rel="noopener"
                                >
                                    What should my data look like? <FontAwesomeIcon icon="arrow-up-right-from-square" />
                                </a>
                            }
                        </div>
                    </>
                ) : null}
            </Modal.Header>
            <Modal.Content>
                {props.getModalContent()}
                {errorMessage ? (
                    <Message negative>
                        <Message.Header>{props.errorHeader}</Message.Header>
                        <p>{errorMessage}</p>
                    </Message>
                ) : null}
            </Modal.Content>
            <Modal.Actions>
                {isConnectFlow ? (
                    <Button
                        className={metrics.BLOCK_AUTO_CAPTURE}
                        style={{ float: "left" }}
                        onClick={() => {
                            props.onStepChange?.((x) => x - 1);
                        }}
                    >
                        Back
                    </Button>
                ) : null}

                <Button
                    className={metrics.BLOCK_AUTO_CAPTURE}
                    onClick={() => {
                        props.reset();
                    }}
                >
                    Cancel
                </Button>
                {getSubmitButton()}
            </Modal.Actions>
        </>
    );
}

export default ConnectorPanel;
