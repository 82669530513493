import { Icon } from "semantic-ui-react";
import { SEMANTIC_GREY_DISABLED } from "../../utils/colors";

const VerboseOption = (props: { option: string; description: string | undefined; disabled?: boolean }) => (
    <>
        <b
            style={{
                display: "block",
                fontSize: "14px",
                fontWeight: 400,
                marginBottom: "4px",
            }}
        >
            {props.disabled ? (
                <p>
                    {props.option}&nbsp;
                    <Icon name="lock" size="small" />
                </p>
            ) : (
                String(props.option)
            )}
        </b>
        <span
            style={{
                color: SEMANTIC_GREY_DISABLED,
                fontSize: "12px",
                fontWeight: 400,
            }}
        >
            {props.description}
        </span>
    </>
);

export default VerboseOption;
