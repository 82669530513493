import { useContext } from "react";
import { Checkbox, Icon, Popup } from "semantic-ui-react";

import { useRecoilState } from "recoil";
import { SubscriptionLink } from "../../../components/GlobalHeader/SubscriptionButtons";
import { USER_STATE } from "../../../state/global";
import { FeatureFlagsContext } from "../../../utils/feature-flags";
import { getComputeSubscriptionStatus } from "../../../utils/subscription";
import styles from "../FieldsTable/FieldsTable.module.css";
import { useDispatch, useIsHyperoptEnabled } from "../store";

const HyperoptCheckbox = () => {
    const [userContext] = useRecoilState(USER_STATE);
    const { featureFlags } = useContext(FeatureFlagsContext);
    const dispatch = useDispatch();
    const hyperoptEnabled = useIsHyperoptEnabled();

    return getComputeSubscriptionStatus(userContext?.isComputeLimited, featureFlags["Free Trial Testing"]) ? (
        <Popup
            content={
                <p>
                    Hyperopt is locked during the free trial period. &nbsp;
                    <SubscriptionLink />
                </p>
            }
            position="right center"
            flowing={true}
            hoverable={true}
            trigger={
                <div style={{ display: "flex" }}>
                    <Checkbox toggle disabled checked={false} />
                    <Icon name="lock" className={styles.Icon} />
                </div>
            }
        />
    ) : (
        <Checkbox toggle onClick={() => dispatch({ type: "TOGGLE_HYPEROPT" })} checked={hyperoptEnabled} />
    );
};

export default HyperoptCheckbox;
