import { Message, Segment } from "semantic-ui-react";
import EngineNodeTable from "../../engines/EngineNodeTable";

function ModelComputeViewer(props: { model: Model }) {
    if (!props.model.engineTemplate) {
        return <Message>No engine template associated with this training run.</Message>;
    }

    return (
        <div>
            <Segment raised style={{ padding: "1.75rem" }}>
                <EngineNodeTable template={props.model.engineTemplate} />
            </Segment>
        </div>
    );
}

export default ModelComputeViewer;
