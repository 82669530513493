import type { JSONSchema7 } from "json-schema";
import { SyntheticEvent, useState } from "react";
import { DropdownProps, Form } from "semantic-ui-react";
import { snakeToTitle } from "../../../../utils/config";
import Markdown from "../../../Markdown";
import { Field, FieldTitle } from "../fields";
import { genericOfSchemaPath, getFieldType, getMatchingSchemaForValue } from "../utils";

export const GenericOf = (props: {
    selector: string;
    path: any;
    ofSchemas: JSONSchema7[];
    title?: string;
    titlePrefix?: string;
    error: boolean;
    value: any;
    defaultValue: any;
    description?: string;
    config: any;
    setConfig: (path: string, typedValue: any) => void;
    localState: any;
    setLocalState: (localState: any, path: string) => void;
    invalidFields: any;
    schemaPath: string;
    featureIndex?: number;
}) => {
    const {
        selector,
        path,
        ofSchemas,
        title,
        titlePrefix,
        error,
        value,
        defaultValue,
        description,
        config,
        setConfig,
        localState,
        setLocalState,
        invalidFields,
        schemaPath,
        featureIndex,
    } = props;
    const [selectedOptionSchema, setSelectedOptionSchema] = useState(getMatchingSchemaForValue(ofSchemas, value));
    const selectOptions: any[] = ofSchemas.map((ofSchemaOption) => ({
        text: ofSchemaOption.title ? snakeToTitle(ofSchemaOption.title) : undefined,
        key: ofSchemaOption.title,
        value: ofSchemaOption.title,
    }));

    return (
        <>
            <FieldTitle title={title ? snakeToTitle(title) : undefined} prefix={titlePrefix} />
            <Markdown children={description} secondary={true} />
            <Form.Select
                key={path + "_dropdown"}
                name={title}
                error={error}
                options={selectOptions}
                value={selectedOptionSchema?.title}
                description={description}
                placeholder={defaultValue ? defaultValue : title}
                style={{
                    width: "inherit",
                    minWidth: `${160 / 14}rem`,
                    marginRight: "8px",
                    marginBottom: `${24 / 14}rem`,
                }}
                onChange={(event: SyntheticEvent, data: DropdownProps) => {
                    setSelectedOptionSchema(ofSchemas.filter((optionSchema) => optionSchema.title === data.value)[0]);
                }}
            />

            {selectedOptionSchema ? (
                <Field
                    fieldType={getFieldType(selectedOptionSchema?.type)}
                    schema={selectedOptionSchema}
                    config={config}
                    setConfig={setConfig}
                    localState={localState}
                    setLocalState={setLocalState}
                    invalidFields={invalidFields}
                    featureIndex={featureIndex}
                    path={path}
                    schemaPath={genericOfSchemaPath(
                        selector,
                        schemaPath,
                        ofSchemas.findIndex((optionSchema) => optionSchema.title === selectedOptionSchema.title),
                    )}
                    hideTitle={true}
                />
            ) : null}
        </>
    );
};
