import { RegistrationFlow, UpdateRegistrationFlowBody } from "@ory/kratos-client";
import { UiNodeInputAttributes } from "@ory/kratos-client/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { get } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Button, Divider, Header, Loader } from "semantic-ui-react";
import { BadLink } from "../../components/BadLink/BadLink";
import { useAuth0TokenOptions } from "../../data";
import metrics from "../../metrics/metrics";
import { GET_USER_CONTEXT_QUERY_KEY } from "../../query";
import { validateInviteToken } from "../../settings/admin/data";
import { SESSION_STATE } from "../../state/global";
import { TenantMetadata } from "../../types/user";
import { PREDIBASE_LIGHT_GRAY } from "../../utils/colors";
import { getErrorMessage } from "../../utils/errors";
import { kratosSdk, useSDKError } from "../../utils/kratos";
import { FORM_TYPE_OIDC, FORM_TYPE_PASSWORD } from "./constants";
import KratosRegisterForm from "./form/KratosRegisterForm";

const KratosSignupView = () => {
    // Auth0 state:
    const { auth0Enabled } = useAuth0TokenOptions();

    // URL state:
    const navigate = useNavigate();

    // For Auth0 Mode, send the user to the invite page with any Query Params
    useEffect(() => {
        if (!auth0Enabled) {
            return;
        }

        const params = new URLSearchParams(window.location.search);
        const token = params.get("inviteToken") || "";

        const inviteUrl = "/invite" + (token ? `?inviteToken=${token}` : "");
        navigate(inviteUrl, { replace: true });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (auth0Enabled) {
        return <Loader active inline="centered" />;
    }

    return <KratosSignupViewContent />;
};

const KratosSignupViewContent = () => {
    // URL state:
    const params = new URLSearchParams(window.location.search);
    const navigate = useNavigate();
    const token = params.get("token") || "";

    // Local state:
    const signInSubmitButton = useRef<Button>(null);
    const [flow, setFlow] = useState<RegistrationFlow>();
    const [linkErrorMessage, setLinkErrorMessage] = useState<string | null>(null);
    const [tenant, setTenant] = useState<TenantMetadata>();
    const [loading, setLoading] = useState<boolean>(true);
    const [, setSession] = useRecoilState(SESSION_STATE);

    // Derived state:
    const form = flow?.ui;
    const isOidcContinuation =
        form?.nodes?.some(
            (x) => (x.attributes as UiNodeInputAttributes).name === "traits.email" && x.group === FORM_TYPE_OIDC,
        ) || false;
    const oidcContinuationProvider = form?.nodes?.find(
        (x) => (x.attributes as UiNodeInputAttributes).name === "provider",
        // @ts-expect-error - Property 'value' does not exist on type 'UiNodeAttributes'?
    )?.attributes?.value;

    // Query state:
    const queryClient = useQueryClient();
    const { mutate: mutateValidateInviteToken } = useMutation({
        mutationFn: () => validateInviteToken(token),
        onSuccess: (tenant) => {
            setLinkErrorMessage("");
            setTenant(tenant);
            metrics.group("company", tenant.uuid, {
                name: tenant.name,
                shortCode: tenant.shortCode,
                subscription: tenant.subscription.tier,
                status: tenant.status,
            });
            setLoading(false);
        },
        onError: (error) => {
            setLinkErrorMessage(getErrorMessage(error));
            setLoading(false);
        },
    });

    // Get the flow based on the flowId in the URL (.e.g redirect to this page after flow initialized)
    const getFlow = useCallback(
        (flowId: string) =>
            kratosSdk
                // the flow data contains the form fields, error messages and csrf token
                .getRegistrationFlow({ id: flowId })
                .then(({ data: flow }) => {
                    setFlow(flow);

                    // If the flow has expired, automatically re-submit the form to sign the user in:
                    const messages = flow?.ui?.messages;
                    if (get(messages, "[0].id") === 4010001) {
                        // @ts-expect-error
                        signInSubmitButton?.current?.ref?.current.click();
                    }
                })
                .catch(sdkErrorHandler),
        [],
    );

    // initialize the sdkError for generic handling of errors
    const sdkErrorHandler = useSDKError(getFlow, setFlow, "/auth/signup", true);

    // create a new registration flow
    const createFlow = () => {
        kratosSdk
            // we don't need to specify the return_to here since we are building an SPA. In server-side browser flows we would need to specify the return_to
            .createBrowserRegistrationFlow()
            .then(({ data: flow }) => {
                // Update URI query params to include flow id
                params.set("flow", flow.id);
                navigate({ search: [params].toString() }, { replace: true });
                // Set the flow data
                setFlow(flow);
            })
            .catch(sdkErrorHandler);
    };

    // submit the registration form data to Ory
    const submitFlow = (body: UpdateRegistrationFlowBody) => {
        // something unexpected went wrong and the flow was not set
        if (!flow) return navigate("/auth/signup", { replace: true });

        return kratosSdk
            .updateRegistrationFlow({
                flow: flow.id,
                updateRegistrationFlowBody: body,
            })
            .then(({ data }) => {
                // we successfully submitted the login flow, so lets redirect to the dashboard
                if (data.session) {
                    // Why wouldn't we have a session?
                    setSession(data.session);
                    queryClient.invalidateQueries({ queryKey: GET_USER_CONTEXT_QUERY_KEY });
                }
                navigate("/", { replace: true });
            })
            .catch(sdkErrorHandler);
    };

    useEffect(() => {
        // Fetch the name of the Tenant so the user can see it on the registration page
        if (!tenant) {
            mutateValidateInviteToken();
        }

        // we might redirect to this page after the flow is initialized, so we check for the flowId in the URL
        const flowId = params.get("flow");
        // the flow already exists
        if (flowId) {
            getFlow(flowId).catch(createFlow); // if for some reason the flow has expired, we need to get a new one
            return;
        }
        // we assume there was no flow, so we create a new one
        createFlow();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div
            style={{
                minHeight: "100vh",
                background: PREDIBASE_LIGHT_GRAY,
                display: "flex",
                justifyContent: "center",
                padding: "20px",
            }}
        >
            <div
                style={{
                    textAlign: "center",
                    width: "50vw",
                    maxWidth: "400px",
                    alignSelf: "center",
                }}
            >
                <img src={"/logos/predibase/predibase.svg"} width={70} height={70} alt="" />
                {flow && !loading ? (
                    <>
                        {isOidcContinuation ? (
                            <>
                                &emsp;<span style={{ fontSize: "32px" }}>+</span>&emsp;
                                <img
                                    src={`/logos/icon/${oidcContinuationProvider}.png`}
                                    width={70}
                                    height={70}
                                    alt=""
                                />
                            </>
                        ) : null}
                        {linkErrorMessage ? (
                            <>
                                <BadLink authenticated={false} />
                            </>
                        ) : (
                            <>
                                <Header as="h2" color="black" textAlign="center">
                                    Create your account
                                </Header>
                                {tenant && (
                                    <>
                                        <span>
                                            Join your team, <b style={{ textTransform: "capitalize" }}>{tenant.name}</b>
                                            , on Predibase!
                                        </span>
                                        <Divider hidden />
                                    </>
                                )}
                                {form && (
                                    <KratosRegisterForm
                                        submitLabel="Create Account"
                                        action={form.action}
                                        fields={form.nodes}
                                        messages={form.messages}
                                        formType={FORM_TYPE_PASSWORD}
                                        token={token}
                                        isOIDCContinuation={isOidcContinuation}
                                        onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                                            event.preventDefault();
                                            const data = new FormData(event.currentTarget);
                                            const flowBody = {} as UpdateRegistrationFlowBody;

                                            for (const pair of data.entries()) {
                                                // @ts-expect-error
                                                flowBody[pair[0]] = pair[1];
                                            }

                                            // Force password method until we support OIDC
                                            flowBody.method = "password";

                                            // @ts-expect-error
                                            const email = flowBody["traits.email"];
                                            // Make sure the user always has a way to recover their account
                                            // @ts-expect-error

                                            flowBody["traits.recovery_email"] = email;
                                            // Make sure the username matches the email
                                            // @ts-expect-error
                                            flowBody["traits.username"] = email;
                                            return submitFlow(flowBody);
                                        }}
                                        ref={signInSubmitButton}
                                    />
                                )}
                                <Divider hidden />
                                Already have an account? <Link to={"/auth/signin"}>Sign In</Link>
                            </>
                        )}
                    </>
                ) : (
                    <Loader active inline="centered" />
                )}
            </div>
        </div>
    );
};

export default KratosSignupView;
