import { Dispatch, SetStateAction } from "react";
import { Divider, Header, Icon, Segment } from "semantic-ui-react";

export const getAlreadyConnectedMessage = (
    alreadyConnected?: boolean,
    isEditView?: boolean,
    setStep?: Dispatch<SetStateAction<number>>,
) => {
    if (alreadyConnected) {
        let backMessage = null;
        if (!isEditView) {
            const backLink = (
                <button className="button-reset" onClick={(event) => setStep?.(0)}>
                    <b>Choose Connector</b>
                </button>
            );
            backMessage = <span>To create a new connection, go back to the {backLink} step</span>;
        }

        return (
            <>
                <Segment>
                    <Header>
                        <Icon name="checkmark" color={"green"} /> This connection is already saved.
                    </Header>
                    {backMessage}
                </Segment>
                <Divider hidden />
            </>
        );
    }
    return null;
};
