import type { JSONSchema7 } from "json-schema";
import { JSONSchemaForm } from "../../../components/forms";
import { filterParams } from "../utils";

const CombinerForm = (props: {
    config?: CreateModelConfig;
    setConfig: (path: string, value: any) => void;
    invalidFields: any;
    readOnly?: boolean;
    schema?: JSONSchema7;
    expectedImpactFilter?: number;
}) => {
    const setConfig = (path: string, value: any) => {
        const typePath = ".type";
        // Wipe previous parameters when changing the type:
        if (path.endsWith(typePath)) {
            // example: combiner.type
            props.setConfig(path.substring(0, path.length - typePath.length), { type: value });
        } else {
            // example: combiner.dropout
            props.setConfig(path, value);
        }
    };

    return (
        <JSONSchemaForm
            data={props.config}
            schema={filterParams(props.schema?.properties?.combiner, props.expectedImpactFilter) as JSONSchema7}
            setConfig={setConfig}
            invalidFields={props.invalidFields}
            schemaPathPrefix="combiner"
            readOnly={props.readOnly}
        />
    );
};

export default CombinerForm;
