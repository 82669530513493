import { Route, Routes } from "react-router-dom";
import EditModelView from "./EditModelView";
import ModelCompareView from "./compare/ModelCompareView";
import CreateModelView from "./create/CreateModelView/CreateModelView";
import { ConfigProvider } from "./create/store";
import ListModelReposView from "./listrepos/ListModelReposView";
import ModelRepoView from "./repo/ModelRepoView/ModelRepoView";
import ModelVersionView from "./version/ModelVersionView/ModelVersionView";

const ModelsViewRouter = () => {
    return (
        <Routes>
            <Route
                path="/create/*"
                element={
                    <ConfigProvider>
                        <CreateModelView />
                    </ConfigProvider>
                }
            />
            <Route path="/repo/:repoID/compare/:rootID?" element={<ModelCompareView />} />
            <Route path="/repo/:repoID" element={<ModelRepoView />} />
            <Route
                path="/edit/*"
                element={
                    <ConfigProvider>
                        <EditModelView />
                    </ConfigProvider>
                }
            />
            <Route path="/version/*" element={<ModelVersionView />} />
            <Route
                path="/"
                element={
                    <div style={{ overflowX: "auto" }}>
                        <ListModelReposView />
                    </div>
                }
            />
        </Routes>
    );
};

export default ModelsViewRouter;
