import type { JSONSchema7 } from "json-schema";
import _ from "lodash";
import { CSSProperties } from "react";
import Dropdown from "../../../Dropdown";
import Markdown from "../../../Markdown";
import { FieldAnnotations, FieldTitle } from "../fields";
import { dropDownWidths } from "../styling";

/**
 * Custom component for selecting a feature from the list of available input features
 *
 * @param props.path - path to the value in the config
 * @param props.title - Optional title of the column selector field
 * @param props.error - whether the field is in an error state
 * @param props.value - the current value of the field
 * @param props.description - Optional description
 * @param props.defaultValue - the default value of the field
 * @param props.config - the current config
 * @param props.setConfig - function to set the config
 * @param props.setLocalState - function to set the local state of the form being edited
 * @param props.schema - Schema for the model type in use
 * @param props.schemaPath - path to the value in the schema
 * @param props.style - custom css to apply to the component
 * @param props.readOnly - whether the field is read only disabled
 */
export const ColumnSelector = (props: {
    path: string;
    title?: string;
    error: boolean;
    value: string;
    description?: string;
    defaultValue: any;
    config: any;
    setConfig: (path: string, typedValue: any) => void;
    setLocalState: (localState: any, path: string) => void;
    schema: JSONSchema7;
    schemaPath: string;
    style?: CSSProperties;
    readOnly?: boolean;
}) => {
    const { defaultValue, description, error, schema, schemaPath, setLocalState, setConfig, title, path, value } =
        props;

    const extractFeatures = (featureList: any[]) => {
        return featureList.map((feature: any) => ({ key: feature.name, text: feature.name, value: feature.name }));
    };

    const features = extractFeatures(props.config.input_features).concat(extractFeatures(props.config.output_features));
    const titlePrefix = _.get(schema, "parameter_metadata.prefix", "");

    return (
        <div style={{ marginBottom: `${24 / 14}rem` }} key={`${path}_string`}>
            <FieldTitle title={title} prefix={titlePrefix} />
            {description && description.length && <Markdown children={description} secondary={true} />}
            <div style={{ display: "flex", flexDirection: "inherit" }}>
                {
                    <Dropdown
                        path={path}
                        error={error}
                        fluid={true}
                        options={features}
                        value={value}
                        defaultValue={defaultValue}
                        setConfig={setConfig}
                        setLocalState={setLocalState}
                        size={dropDownWidths.normal}
                        readOnly={props.readOnly}
                    />
                }
                <FieldAnnotations path={path} defaultValue={defaultValue} schema={schema} />
            </div>
        </div>
    );
};
