import * as Sentry from "@sentry/react";
import _ from "lodash";
import { useMemo } from "react";
import { Popup, Segment, Table } from "semantic-ui-react";
import { formatValueToNumericString } from "../../../utils/numbers";

const getBackgroundColor = (value: number, maxTokenValue: number, minTokenValue: number) => {
    if (value > 0) {
        const opacity = value / maxTokenValue;
        return opacity > 0.5 ? `rgba(5,206,8, ${opacity})` : "rgba(0,0,0, 0)";
    }
    const opacity = value / minTokenValue;
    return opacity > 0.5 ? `rgba(206,5,8, ${opacity})` : "rgba(0,0,0, 0)";
};

export const getTotalFeaturesFromShapResults = (results: ShapResult[]): string[] => {
    return results.length > 0
        ? Array.from(
              new Set(
                  results[0].data
                      .flatMap((explanation) => Object.values(explanation.features))
                      .filter((feature) => feature.token_attributions !== null)
                      .map((feature) => feature.name),
              ),
          )
        : [];
};

export const getStr2Idx = (metadata: ShapMetadata): Record<string, number> => {
    const idxMap: Record<any, number> = {};
    (metadata.targetIdx2str || []).forEach((str, idx) => (idxMap[str] = idx));
    return idxMap;
};

const Explanation = (props: { feature: ShapFeature; noStyling?: boolean }) => {
    const { feature, noStyling } = props;
    let maxTokenValue = 0;
    let minTokenValue = 0;

    feature.token_attributions.forEach(([, value]) => {
        if (value > maxTokenValue) {
            maxTokenValue = value;
        } else if (value < minTokenValue) {
            minTokenValue = value;
        }
    });

    const content = feature.token_attributions
        .filter(([token, _]) => !token.match("[<\\[].*?[>\\]]"))
        .map(([token, value], i) => (
            <Popup
                key={`${token}-${i}`}
                trigger={
                    <span>
                        <span style={{ backgroundColor: getBackgroundColor(value, maxTokenValue, minTokenValue) }}>
                            {token}
                        </span>{" "}
                    </span>
                }
                content={formatValueToNumericString(value, 3)}
            />
        ));

    if (noStyling) {
        return <>{content}</>;
    }

    return (
        <Segment key={feature.name}>
            <h4 style={{ fontStyle: "italic" }}>{feature.name}</h4>
            <div className="ui divider" style={{ marginTop: "1.0rem" }}></div>
            {content}
        </Segment>
    );
};

const Legend = () => {
    return (
        <Segment>
            <div style={{ whiteSpace: "nowrap" }}>
                <h4
                    style={{
                        fontWeight: "bold",
                        display: "inline-block",
                        marginRight: "1rem",
                    }}
                >
                    Legend:{" "}
                </h4>
                <div style={{ display: "inline-block", marginRight: "1.0rem" }}>
                    <div className="tle-legend-box" style={{ backgroundColor: "rgba(5,206,8, 0.8)" }}></div>
                    True
                </div>
                <div style={{ display: "inline-block", marginRight: "1.0rem" }}>
                    <div className="tle-legend-box" style={{ backgroundColor: "rgba(206,5,8, 0.8)" }}></div>
                    False
                </div>
            </div>
        </Segment>
    );
    // TODO: Add legend for category targets
};

export function TextExplanationsSegment(props: {
    explanations: ShapData[];
    metadata: ShapMetadata;
    asTable?: boolean;
}) {
    return (
        <>
            {props.explanations.map((explanation) => {
                const features = Object.values(explanation.features).filter(
                    (feature) => feature.token_attributions !== null,
                );
                return (
                    <div>
                        {props.metadata.targetBool && <Legend />}
                        {features.map((feature) => (
                            <Explanation feature={feature} />
                        ))}
                    </div>
                );
            })}
        </>
    );
}

export function TextExplanationsTable(props: {
    results: ShapResult[];
    metadata: ShapMetadata;
    outputFeatures: string[];
}) {
    const totalFeatures = getTotalFeaturesFromShapResults(props.results);

    const str2idx = useMemo(() => getStr2Idx(props.metadata), [props.metadata]);

    const predictionColumn = props.outputFeatures.join(",") + " prediction";
    return (
        <>
            {props.metadata.targetBool && <Legend />}
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>
                            <i>{predictionColumn}</i>
                        </Table.HeaderCell>
                        {totalFeatures.map((feature) => (
                            <Table.HeaderCell key={feature}>{feature}</Table.HeaderCell>
                        ))}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {props.results.map((result, i) => {
                        // By default, assume explanations are for categorical predictions:
                        let explanationIdx = str2idx[result.prediction];
                        if (props.metadata.targetBool) {
                            // For binary/boolean predictions the index will always be 1.
                            explanationIdx = 1;
                        } else if (_.isEmpty(explanationIdx)) {
                            // For numerical predictions, we will only have one explanation.
                            explanationIdx = 0;
                        }

                        const explanation: ShapData = result.data[explanationIdx];
                        let resultHasMissingTokenAttributions = false;
                        const features = Object.values(explanation.features).filter((feature) => {
                            if (Array.isArray(feature.token_attributions)) {
                                return true;
                            }

                            // Log error in Sentry so we can track frequency when it's completely missing
                            if (!_.has(feature, "token_attributions")) {
                                resultHasMissingTokenAttributions = true;
                            }

                            return false;
                        });
                        if (resultHasMissingTokenAttributions) {
                            Sentry.captureMessage("Result has missing token attributions");
                        }
                        return (
                            <Table.Row key={`results-row-${i}`}>
                                <Table.Cell key={`prediction-cell-${i}`}>{String(result.prediction)}</Table.Cell>
                                {features.map((feature, j) => (
                                    <Table.Cell key={`results-cell-${i}-${j}`}>
                                        <Explanation feature={feature} noStyling />
                                    </Table.Cell>
                                ))}
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
        </>
    );
}
