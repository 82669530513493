import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Edge, Node, ReactFlowProvider } from "react-flow-renderer";
import { useRecoilState } from "recoil";
import { Button, Icon, Label, Popup, Segment } from "semantic-ui-react";
import LiveTimeAgo from "../../components/LiveTimeAgo";
import { getStatusChipForModelsAndQueries } from "../../components/statusChip";
import { track } from "../../metrics/june";
import metrics from "../../metrics/metrics";
import { USER_STATE } from "../../state/global";
import { ModelStatus } from "../../types/model/modelStatus";
import { SEMANTIC_GREY } from "../../utils/colors";
import { truncateStringWithPopup } from "../../utils/overflow";
import ModelLineageFlow from "./ModelLineageFlow";

function ModelLineageView(props: {
    isRepo?: boolean;
    modelDiffs: (ModelDiffNode | null)[];
    setModelDiffs: React.Dispatch<React.SetStateAction<(ModelDiffNode | null)[]>>;
    rootID: string;
    repoID: number;
    repoHead?: number;
    nodes?: ModelLineageNode[];
    edges?: ModelLineageEdge[];
    setTabIndex: () => void;
}) {
    const [user] = useRecoilState(USER_STATE);

    const formatNodes = (nodes: ModelLineageNode[]) => {
        const newNodes = JSON.parse(JSON.stringify(nodes));
        for (let node of newNodes) {
            node.className = "model-lineage";
            if (node.data.archived) {
                node.className += " archived";
            }
            node.data = { label: <div>{formatNodeLabel(node)}</div> };
            node.connectable = false;
        }
        return newNodes;
    };

    const formatEdges = (edges: Edge[]) => {
        const newEdges = JSON.parse(JSON.stringify(edges));
        for (let edge of newEdges) {
            edge.markerEnd = { type: "arrow" };
        }
        return newEdges;
    };

    const NodeTimestamp = (key: string, value: string, archived?: boolean) => {
        return (
            <>
                <br />
                <strong style={{ color: archived ? SEMANTIC_GREY : undefined }}>{key}</strong>:{" "}
                <LiveTimeAgo fromDate={new Date(value)} color={archived ? SEMANTIC_GREY : undefined} />
            </>
        );
    };

    const formatNodeLabel = (node: Node) => {
        const data = node.data as ModelLineageAttributes;
        const id = node.id;
        const name = data.name;
        const archived = data.archived;

        const aria = props.isRepo ? `View ${name} repo` : `View ${name} version`;
        const url = props.isRepo ? "/models/repo/" + id : "/models/version/" + id;
        return (
            <>
                <div style={{ paddingBottom: "3px" }}>
                    <Button
                        className={metrics.BLOCK_AUTO_CAPTURE}
                        color={props.isRepo && String(props.repoID) === id ? "blue" : undefined}
                        aria-label={aria}
                        size={"mini"}
                        compact
                        onClick={() => window.open(url, "_blank")}
                    >
                        {truncateStringWithPopup(name, 18)}
                    </Button>
                    <Button
                        className={metrics.BLOCK_AUTO_CAPTURE}
                        style={{ textAlign: "right" }}
                        compact
                        icon="pin"
                        aria-label={"Add to model diff"}
                        basic
                        size={"mini"}
                        onClick={() => {
                            user && track(user, "model_lineage_diff");
                            const model: ModelDiffNode = { name: name, id: id, author: data.author };
                            setModelForDiff(model!);
                        }}
                    />
                    {props.repoHead && parseInt(id) === props.repoHead ? (
                        <Popup
                            className={"transition-scale"}
                            position={"top center"}
                            trigger={<FontAwesomeIcon icon="crown" color={"gold"} />}
                            content={
                                <span>
                                    This is the <b>HEAD</b> of this model repo. Forking this repo will use this version
                                    config
                                </span>
                            }
                        />
                    ) : null}
                </div>
                <span style={{ color: SEMANTIC_GREY }}>{truncateStringWithPopup(data.description, 22)}</span>
                <br />
                {data.author ? (
                    <span style={{ color: archived ? SEMANTIC_GREY : undefined }}>
                        <b>Author</b>: {truncateStringWithPopup(data.author, 20)}
                    </span>
                ) : null}
                {data.created ? NodeTimestamp("Created", data.created, archived) : null}
                {data.updated ? NodeTimestamp("Updated", data.updated, archived) : null}
                {data.completed ? NodeTimestamp("Completed", data.completed, archived) : null}
                {data.status ? getStatusChipForModelsAndQueries(data.status as ModelStatus) : null}
            </>
        );
    };

    const setModelForDiff = (model: ModelDiffNode) => {
        props.setModelDiffs((x) => {
            if (x[0] == null) {
                if (x[1] != null) {
                    props.setTabIndex();
                }
                return [model, x[1]];
            } else {
                if (x[0] != null) {
                    props.setTabIndex();
                }
                return [x[0], model];
            }
        });
    };

    const getModelDiffsLabels = () => {
        const first = (
            <>
                <strong>Left:</strong>&ensp;
                {props.modelDiffs[0] ? (
                    <Label
                        as="a"
                        className={"model-diff-button"}
                        onClick={() => props.setModelDiffs((x) => [null, x[1]])}
                        aria-label={`delete ${props.modelDiffs[0].name} diff`}
                    >
                        {props.modelDiffs[0].name} <Icon name="delete" color="red" />
                    </Label>
                ) : (
                    <span style={{ opacity: "0.5" }}>(none)</span>
                )}
            </>
        );

        const second = (
            <>
                <strong>Right:</strong>&ensp;
                {props.modelDiffs[1] ? (
                    <Label
                        as="a"
                        className={"model-diff-button"}
                        onClick={() => props.setModelDiffs((x) => [x[0], null])}
                        aria-label={`delete ${props.modelDiffs[1].name} diff`}
                    >
                        {props.modelDiffs[1].name} <Icon name="delete" color="red" />
                    </Label>
                ) : (
                    <span style={{ opacity: "0.5" }}>(none)</span>
                )}
            </>
        );

        return (
            <>
                &emsp;{first}&emsp;{second}
            </>
        );
    };

    if (!Array.isArray(props.nodes) || !Array.isArray(props.edges)) {
        return null;
    }

    return (
        <>
            <Segment>
                <strong>Selected models for diff</strong> (add with <Icon bordered name="pin" />
                ): {getModelDiffsLabels()}
            </Segment>
            <div style={{ width: "100%", height: "70vh" }}>
                <ReactFlowProvider>
                    <ModelLineageFlow
                        rootID={props.rootID}
                        repoID={props.repoID}
                        nodes={formatNodes(props.nodes)}
                        edges={formatEdges(props.edges)}
                        setTabIndex={props.setTabIndex}
                        setModelDiffs={props.setModelDiffs}
                    />
                </ReactFlowProvider>
            </div>
        </>
    );
}

export default ModelLineageView;
