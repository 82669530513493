import { useState } from "react";
import { Form } from "semantic-ui-react";
import { SingularField } from "../../../components/StackedForm";
import { SEMANTIC_GREY } from "../../../utils/colors";

import ConnectorPanel from "../ConnectorPanel";
import { Connector, CreateConnectionEndpoint } from "../util";

function GCSConnector(props: ConnectorProps) {
    const [connectionName, setConnectionName] = useState(
        props.connectorConfig.connectionName ? props.connectorConfig.connectionName : "",
    );

    const [token, setToken] = useState(props.connectorConfig?.token ? props.connectorConfig.token : "");

    const alreadyConnected = props.connection !== undefined;

    const reset = () => {
        setToken("");
    };

    const getAddRequest = () => {
        const config = {
            protocol: "gs",
            token: token.trim(),
        };

        return {
            endpoint: CreateConnectionEndpoint,
            body: {
                connection: {
                    type: "gcs",
                    name: connectionName.trim(),
                },
                secret: {
                    secret: config,
                },
            },
            headers: {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            },
        };
    };

    const getUpdateRequest = () => {
        const config = {
            protocol: "gs",
            token,
        };

        return {
            endpoint: "sources/update",
            body: {
                connection: {
                    id: props.connection!.id,
                    type: props.connection!.type,
                    name: connectionName,
                },
                secret: {
                    secret: config,
                },
            },
            headers: {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            },
        };
    };

    const isDisabled = () => {
        return !token || !connectionName;
    };

    const getModalContent = () => {
        return (
            <Form>
                <SingularField
                    autoFocus={true}
                    disabled={alreadyConnected && !props.isEditView}
                    name="name"
                    label="Connection Name"
                    placeholder="gcs_datasets"
                    description="Choose a name for your connection in Predibase"
                    value={connectionName}
                    setState={setConnectionName}
                />
                <Form.Field>
                    <label>Service Account Key</label>
                    <p style={{ color: SEMANTIC_GREY, fontSize: "0.9em", marginBottom: "10px" }}>
                        Paste the JSON associated with a service account that has GCS privileges.{" "}
                        <a
                            href="https://cloud.google.com/iam/docs/creating-managing-service-account-keys#iam-service-account-keys-create-console"
                            target="_blank"
                            rel="noreferrer"
                        >
                            See Google Cloud documentation for further details
                        </a>
                        .
                    </p>
                    <Form.TextArea
                        disabled={alreadyConnected && !props.isEditView}
                        autoComplete="off"
                        rows={8}
                        name="token"
                        placeholder={
                            '{\n  "type": "service_account",\n  "project_id": "yourProjectID",\n  "private_key_id": "yourPrivateKeyId",\n  "private_key": "-----BEGIN PRIVATE KEY-----999999999999999999999999-----END PRIVATE KEY-----",\n  "client_email": "predibase-admin-email@example.iam.gserviceaccount.com",\n  "client_id": "111111111111111111111",\n  "auth_uri": "https://accounts.google.com/o/oauth2/auth",\n  "token_uri": "https://oauth2.googleapis.com/token",\n  "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",\n  "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/predibase-admin-email%40example.iam.gserviceaccount.com"\n}'
                        }
                        value={token}
                        onChange={(event, data) => setToken(event.target.value)}
                    />
                </Form.Field>
            </Form>
        );
    };

    return (
        <ConnectorPanel
            modalHeader="Connect to Google Cloud Storage"
            header="Google Cloud Storage"
            editModalHeader="Edit Google Cloud Storage Connection"
            submitLabel="Connect"
            name={Connector.GCS}
            errorHeader="Error in Google Cloud Storage connection"
            isDisabled={isDisabled}
            getModalContent={getModalContent}
            getAddRequest={getAddRequest}
            getUpdateRequest={getUpdateRequest}
            reset={reset}
            {...props}
        />
    );
}

export default GCSConnector;
