import { capitalize } from "lodash";
import { role } from "../../api_generated";

export const generateUserRoleDropdownOptions = (userRole?: string) => {
    if (userRole === undefined) {
        return [];
    }

    const roleKeys = Object.keys(role).filter((key) => isNaN(Number(key)));
    if (userRole !== role.SUPPORT) {
        roleKeys.splice(roleKeys.indexOf("SUPPORT"), 1);
    }
    if (userRole !== role.ADMIN) {
        roleKeys.splice(roleKeys.indexOf("ADMIN"), 1);
    }
    return roleKeys.map((key) => ({
        text: capitalize(key.toLowerCase()),
        value: key.toUpperCase(),
    }));
};

export const cleanAuthMethods = (authMethods: string[]) => {
    return authMethods.map((method) => {
        switch (method) {
            case "auth0":
                return "Email/Password";
            case "google-oauth2":
                return "Google";
            case "github":
                return "Github";
            default:
                return capitalize(method.toLowerCase());
        }
    });
};
