import { useState } from "react";
import { Form, Message } from "semantic-ui-react";
import { SideBySideField, SingularField, StackedFormGroup } from "../../../components/StackedForm";

import ConnectorPanel from "../ConnectorPanel";
import { Connector, CreateConnectionEndpoint } from "../util";
import { getAlreadyConnectedMessage } from "./util";

function DatabricksConnector(props: ConnectorProps) {
    const [connectionName, setConnectionName] = useState("");

    const [serverHostname, setServerHostname] = useState(
        props.connectorConfig.serverHostname ? props.connectorConfig.serverHostname : "",
    );
    const [httpPath, setHttpPath] = useState(props.connectorConfig.httpPath ? props.connectorConfig.httpPath : "");
    const [token, setToken] = useState(props.connectorConfig.token ? props.connectorConfig.token : "");
    const [schema, setSchema] = useState(props.connectorConfig.schema ? props.connectorConfig.schema : "");

    const alreadyConnected = props.connection !== undefined;

    const reset = () => {
        setConnectionName("");
        setServerHostname("");
        setHttpPath("");
        setToken("");
        setSchema("");
    };

    const getAddRequest = () => {
        const config = {
            serverHostname: serverHostname.trim(),
            httpPath: httpPath.trim(),
            token: token.trim(),
            schema: schema.trim(),
        };

        return {
            endpoint: CreateConnectionEndpoint,
            body: {
                connection: {
                    type: "databricks",
                    name: connectionName.trim(),
                },
                secret: {
                    secret: config,
                },
            },
            headers: {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            },
        };
    };

    const getUpdateRequest = () => {
        const config = {
            serverHostname: serverHostname,
            httpPath: httpPath,
            token: token,
            schema: schema,
        };

        return {
            endpoint: "sources/update",
            body: {
                id: props.connection!.id,
                uri: schema,
                type: props.connection!.type,
                name: connectionName,
                config: config,
            },
            headers: {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            },
        };
    };

    const isDisabled = () => {
        return !serverHostname || !httpPath || !token || !schema || !connectionName;
    };

    const getModalContent = () => {
        return (
            <>
                {getAlreadyConnectedMessage(alreadyConnected, !props.isEditView)}
                <Form>
                    <Message info>
                        <b>Note</b>: Please ensure that your Databricks compute cluster is running before moving to the
                        next step.
                    </Message>
                    <SingularField
                        autoFocus={true}
                        disabled={alreadyConnected && !props.isEditView}
                        name="name"
                        label="Connection Name"
                        placeholder="databricks_datasets"
                        description="Choose a name for your connection in Predibase"
                        value={connectionName}
                        setState={setConnectionName}
                    />
                    <StackedFormGroup>
                        <SideBySideField
                            disabled={alreadyConnected && !props.isEditView}
                            name="serverHostname"
                            label="Server Hostname"
                            placeholder="dbc-1a1a1a1a-2b2b.cloud.databricks.com"
                            description={
                                <>
                                    Found{" "}
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://docs.databricks.com/integrations/jdbc-odbc-bi.html#odbc-configuration-and-connection-parameters"
                                    >
                                        here
                                    </a>{" "}
                                    under Cluster &gt; Advanced &gt; JDBC/ODBC.
                                </>
                            }
                            value={serverHostname}
                            setState={setServerHostname}
                        />
                        <SideBySideField
                            disabled={alreadyConnected && !props.isEditView}
                            name="httpPath"
                            label="HTTP Path"
                            placeholder="sql/protocolv1/o/123456789"
                            description={
                                <>
                                    Found{" "}
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://docs.databricks.com/integrations/jdbc-odbc-bi.html#odbc-configuration-and-connection-parameters"
                                    >
                                        here
                                    </a>{" "}
                                    under Cluster &gt; Advanced &gt; JDBC/ODBC.
                                </>
                            }
                            value={httpPath}
                            setState={setHttpPath}
                        />
                    </StackedFormGroup>
                    <StackedFormGroup>
                        <SideBySideField
                            disabled={alreadyConnected && !props.isEditView}
                            name="token"
                            label="Access Token"
                            placeholder="abcdefgh123456789"
                            description={
                                <>
                                    The{" "}
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://docs.databricks.com/administration-guide/users-groups/service-principals.html#manage-access-tokens-for-a-service-principal"
                                    >
                                        access token
                                    </a>{" "}
                                    of a service account created within Databricks.
                                </>
                            }
                            value={token}
                            setState={setToken}
                        />
                        <SideBySideField
                            disabled={alreadyConnected && !props.isEditView}
                            name="schema"
                            label="Schema Name"
                            placeholder="datasets"
                            description={
                                <>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://docs.databricks.com/data-governance/unity-catalog/create-schemas.html"
                                    >
                                        Database
                                    </a>{" "}
                                    in Databricks that contains the tables you'd like to use.
                                </>
                            }
                            value={schema}
                            setState={setSchema}
                        />
                    </StackedFormGroup>
                </Form>
            </>
        );
    };

    return (
        <ConnectorPanel
            modalHeader="Connect to Databricks"
            header="Databricks"
            editModalHeader="Edit Databricks Connection"
            submitLabel="Connect"
            name={Connector.DATABRICKS}
            errorHeader="Error in Databricks connection"
            isDisabled={isDisabled}
            getModalContent={getModalContent}
            getAddRequest={getAddRequest}
            getUpdateRequest={getUpdateRequest}
            reset={reset}
            {...props}
        />
    );
}

export default DatabricksConnector;
