import { AxiosInstance, AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { Tab, TabProps } from "semantic-ui-react";
import { useAuth0TokenOptions } from "../../data";
import metrics from "../../metrics/metrics";
import { createV1APIServer, redirectIfSessionInvalid } from "../../utils/api";
import { getErrorMessage } from "../../utils/errors";
import ModelLineageView from "../lineage/ModelLineageView";
import ModelDiffView from "./ModelDiffView";

enum SubmenuTab {
    VersionLineage,
    VersionDiff,
}

function ModelRepoAndVersionLineage(props: {
    modelRepo?: ModelRepo;
    schema?: CreateModelSchema;
    leftModelID?: number;
    rightModelID?: number;
}) {
    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    const [apiServer, setAPIServer] = useState<AxiosInstance | null>(null);
    useEffect(() => {
        const getAPIServer = async () => {
            const v1APIServer = await createV1APIServer(auth0TokenOptions);
            // NOTE: Whoever wrote the axios typings is a moron because the return type of axios.create is not
            // AxiosInstance -- it's a wrap function. And React will see that and treat it as a callback that
            // setState should directly call. FML.
            // See: [1], [2]:
            // [1]: https://github.com/axios/axios/issues/4365
            // [2]: https://stackoverflow.com/questions/64427195/calling-setstate-will-execute-the-function-value-instead-of-passing-it
            setAPIServer(() => v1APIServer);
        };
        getAPIServer();
    }, []);

    const [tabIndex, setTabIndex] = useState(SubmenuTab.VersionLineage);

    const [versionDiffs, setVersionDiffs] = useState<(ModelDiffNode | null)[]>([null, null]);
    const [versionLineageNodes, setVersionLineageNodes] = useState<ModelLineageNode[]>([]);
    const [versionLineageEdges, setVersionLineageEdges] = useState<ModelLineageEdge[]>([]);

    useEffect(() => {
        if (props.leftModelID && props.rightModelID) {
            setTabIndex(SubmenuTab.VersionDiff);
        }
    }, [props.leftModelID, props.rightModelID]);

    useEffect(() => {
        if (
            (tabIndex === SubmenuTab.VersionLineage || tabIndex === SubmenuTab.VersionDiff) &&
            props.modelRepo?.id &&
            versionLineageNodes.length === 0
        ) {
            fetchVersionTree(props.modelRepo.id, props.leftModelID, props.rightModelID);
        }
    }, [tabIndex, apiServer]);

    const fetchVersionTree = (repoID: number, leftModelID?: number, rightModelID?: number) => {
        apiServer
            ?.get("models/lineage/" + repoID)
            .then((res: AxiosResponse<ModelConfigurationLineage>) => {
                const nodes: ModelLineageNode[] = res.data.nodes || [];
                setVersionLineageNodes(nodes.sort((a, b) => parseInt(a.id) - parseInt(b.id)));
                setVersionLineageEdges(res.data.edges || []);

                let leftModel = null;
                let rightModel = null;
                if (nodes.length > 0) {
                    if (leftModelID && rightModelID) {
                        leftModel = nodes.find((x) => x.id === String(leftModelID));
                        rightModel = nodes.find((x) => x.id === String(rightModelID));
                    } else {
                        // Select latest model and its parent by default
                        rightModel = nodes.reduce((prev, current) => (+prev.id > +current.id ? prev : current));
                        const parent = rightModel.data.parent;

                        if (parent) {
                            leftModel = nodes.find((x) => x.id === String(parent));
                        }
                        setVersionDiffs([
                            leftModel
                                ? ({
                                      id: leftModel.id,
                                      name: leftModel.data.name,
                                      author: leftModel.data.author,
                                  } as ModelDiffNode)
                                : null,
                            rightModel
                                ? ({
                                      id: rightModel.id,
                                      name: rightModel.data.name,
                                      author: rightModel.data.author,
                                  } as ModelDiffNode)
                                : null,
                        ]);
                    }
                    setVersionDiffs([
                        leftModel
                            ? ({
                                  id: leftModel.id,
                                  name: leftModel.data.name,
                                  author: leftModel.data.author,
                              } as ModelDiffNode)
                            : null,
                        rightModel
                            ? ({
                                  id: rightModel.id,
                                  name: rightModel.data.name,
                                  author: rightModel.data.author,
                              } as ModelDiffNode)
                            : null,
                    ]);
                }
                // setErrorMessage(null);
            })
            .catch((error) => {
                const errorMsg = getErrorMessage(error) ?? "";
                // setErrorMessage(errorMsg);
                redirectIfSessionInvalid(errorMsg);
            });
    };

    const versionLineageViewer = props.modelRepo?.id ? (
        <Tab.Pane className={metrics.BLOCK_AUTO_CAPTURE} key={"versionLineageViewer"} attached={false}>
            <ModelLineageView
                rootID={""}
                repoID={props.modelRepo?.id}
                repoHead={props.modelRepo.head}
                nodes={versionLineageNodes}
                edges={versionLineageEdges}
                setTabIndex={() => setTabIndex(SubmenuTab.VersionDiff)}
                modelDiffs={versionDiffs}
                setModelDiffs={setVersionDiffs}
            />
        </Tab.Pane>
    ) : null;

    const versionDiffViewer = (
        <Tab.Pane className={metrics.BLOCK_AUTO_CAPTURE} key={"versionDiffViewer"} attached={false}>
            <ModelDiffView
                selectedModels={versionDiffs}
                setModelDiffs={setVersionDiffs}
                options={versionLineageNodes}
            />
        </Tab.Pane>
    );

    const panes = [
        { key: "versionLineageViewer", menuItem: "Version Lineage", pane: versionLineageViewer },
        { key: "versionDiffViewer", menuItem: "Version Diff", pane: versionDiffViewer },
    ];

    const onTabChange = (e: React.MouseEvent<HTMLDivElement>, { activeIndex }: TabProps) => {
        const idx = Number(activeIndex);
        setTabIndex(idx);
    };

    return (
        <Tab
            panes={panes}
            activeIndex={tabIndex}
            onTabChange={onTabChange}
            renderActiveOnly={false}
            menu={{ secondary: true, pointing: true }}
        />
    );
}

export default ModelRepoAndVersionLineage;
