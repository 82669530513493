import _ from "lodash";
import React, { SyntheticEvent, useMemo, useState } from "react";
import { Form, Grid, Icon, Segment } from "semantic-ui-react";
import Chip from "../../../components/Chip";
import metrics from "../../../metrics/metrics";
import { FieldType } from "../../../types/model/fieldType";
import { SearchSpace } from "../../../types/model/searchSpace";
import { SEMANTIC_GREY, SEMANTIC_GREY_DISABLED, SEMANTIC_PINK, SEMANTIC_WHITE } from "../../../utils/colors";
import { convertUserInputToType, getFieldState } from "../forms/utils";
import { getTooltip } from "../HoverInfo";
import { useConfigState } from "../store";

const InputRow = (props: { label: String; inputs: React.ReactNode }) => {
    const inputRowStyle = {
        display: "flex",
        justifyContent: "center",
        flexDirection: "row" as "row",
        alignItems: "flex-start",
        marginBottom: "18px",
    };

    const labelStyle = {
        fontWeight: 700,
        minWidth: "132px",
        marginTop: "6px",
        marginRight: "24px",
        textAlign: "right" as "right",
    };

    return (
        <div style={inputRowStyle}>
            <label style={labelStyle}>{props.label}</label>
            <Form.Group inline style={{ marginRight: "auto", marginBottom: 0 }}>
                {props.inputs}
            </Form.Group>
        </div>
    );
};

const DisabledOptions = (props: { title: string; options: number[] | string[] | boolean[] | null[] | undefined }) => (
    <ul
        style={{
            display: "flex",
            flexDirection: "row",
            listStyle: "none",
            paddingInlineStart: 0,
            background: SEMANTIC_WHITE,
            padding: "4px",
            border: "1px solid rgba(34,36,38,.15)",
            borderRadius: "4px",
        }}
    >
        {props?.options?.map((option) => (
            <li style={{ marginRight: "4px" }} key={`${props.title}-${option}`}>
                <Chip
                    color={SEMANTIC_GREY}
                    text={String(option)}
                    textColor={SEMANTIC_WHITE}
                    style={{ fontSize: "1em", padding: "8px 11px" }}
                />
            </li>
        ))}
    </ul>
);

const generateParam = (space: SearchSpace, existingParameter: HyperoptParameter, previousData: HyperoptParameter) => {
    // All params have a space property:
    let updatedParameter: HyperoptParameter = {
        space,
    };

    // When switching between choice and grid_search; transfer values to choices; vice versa
    if (space === SearchSpace.GRID_SEARCH) {
        updatedParameter.values = existingParameter?.categories
            ? existingParameter.categories
            : previousData?.values
              ? previousData.values
              : previousData?.categories
                ? previousData.categories
                : [];
        return updatedParameter;
    }

    if (space === SearchSpace.CHOICE) {
        updatedParameter.categories = existingParameter?.values
            ? existingParameter.values
            : previousData?.categories
              ? previousData.categories
              : previousData?.values
                ? previousData.values
                : [];
        return updatedParameter;
    }

    const fieldsToTransfer: Array<keyof HyperoptParameter> = [];
    const transferFields = (fieldNames: Array<keyof HyperoptParameter>) => {
        fieldNames.forEach((fieldName) => {
            updatedParameter[fieldName] = updatedParameter?.[fieldName]
                ? updatedParameter[fieldName]
                : previousData?.[fieldName]
                  ? previousData[fieldName]
                  : undefined;

            if (updatedParameter[fieldName] === undefined) {
                switch (fieldName) {
                    case "base":
                        updatedParameter[fieldName] = 10;
                        break;
                    case "mean":
                        updatedParameter[fieldName] = 0;
                        break;
                    case "sd":
                        updatedParameter[fieldName] = 1;
                        break;
                }
            }
        });
    };

    if (space === SearchSpace.RANDN || space === SearchSpace.QRANDN) {
        fieldsToTransfer.push("mean");
        fieldsToTransfer.push("sd");
        if (space === SearchSpace.QRANDN) {
            fieldsToTransfer.push("q");
        }

        transferFields(fieldsToTransfer);
        return updatedParameter;
    }

    // Everything else has a lower and upper
    fieldsToTransfer.push("lower");
    fieldsToTransfer.push("upper");
    if (space.indexOf("q") === 0) {
        fieldsToTransfer.push("q");
    }
    if (space.includes("log")) {
        fieldsToTransfer.push("base");
    }

    transferFields(fieldsToTransfer);
    return updatedParameter;
};

const optionDescriptions = (option: SearchSpace) => {
    let description;

    switch (option) {
        case SearchSpace.UNIFORM:
            description = "Sample a float value uniformly";
            break;
        case SearchSpace.QUNIFORM:
            description = "Sample a quantized float value uniformly, rounding to increments of q";
            break;
        case SearchSpace.LOGUNIFORM:
            description = "Sample a float uniformly in a log space";
            break;
        case SearchSpace.QLOGUNIFORM:
            description = "Sample a quantized float uniformly in a log space, rounding to increments of q";
            break;
        case SearchSpace.RANDN:
            description = "Sample a random float from a normal distribution given a mean and standard deviation";
            break;
        case SearchSpace.QRANDN:
            description =
                "Sample a random float from a normal distribution given a mean and standard deviation, rounding to increments of q";
            break;
        case SearchSpace.RANDINT:
            description = "Sample an integer value uniformly";
            break;
        case SearchSpace.QRANDINT:
            description = "Sample an integer value uniformly, rounding to increments of q";
            break;
        case SearchSpace.LOGRANDINT:
            description = "Sample an integer uniformly in a log space";
            break;
        case SearchSpace.QLOGRANDINT:
            description = "Sample an integer uniformly in a log space, rounding to increments of q";
            break;
        case SearchSpace.CHOICE:
            description = "Sample uniformly at random from a set of values";
            break;
        case SearchSpace.GRID_SEARCH:
            description =
                "Exhaustively try all the values from the set. Note: increases number of samples by the size of the set of values";
            break;
        default:
            break;
    }

    return description;
};

const mapSpaceOptions = (option: SearchSpace) => ({
    text: String(option),
    value: option,
    children: (
        <div>
            <strong style={{ display: "block", fontSize: "14px", fontWeight: 400, marginBottom: "4px" }}>
                {String(option)}
            </strong>
            <span style={{ color: SEMANTIC_GREY_DISABLED, fontSize: "12px", fontWeight: 400 }}>
                {optionDescriptions(option)}
            </span>
        </div>
    ),
});

const mapOption = (option: number | string | boolean | null, text?: string) => ({
    text: text && text.length ? text : String(option),
    value: option,
});

const configFieldName = (parameterValue: string, propertyName: string) =>
    `hyperopt/parameters/${parameterValue}/${propertyName}`;

const ParameterOption = (props: {
    parameter: HyperoptParameterOption;
    updateParameter: Function;
    removeParameter: Function;
}) => {
    const { invalidFields } = useConfigState();
    const [localState, setLocalState] = useState(() => props.parameter.config);
    const updateLocalState = (path: string, value: string | (string | number | boolean | undefined | null)[]) => {
        setLocalState(_.set(localState, path, value));
    };

    // Create a local copy of all the data the user has entered so that
    // when they switch between search space options their previously
    // entered values are shown again. We have to do this because we're
    // updating the ludwig config on the fly and only set the config object
    // with the current selection.
    const [previousData, setPreviousData] = useState(() => props.parameter.config);

    // @TODO: Convert to Reducer
    const configProperty = props.parameter.config.space === SearchSpace.CHOICE ? "categories" : "values";
    const targetsError = invalidFields[`${configFieldName(props.parameter.value, configProperty)}.values`];
    const lowerError = invalidFields[configFieldName(props.parameter.value, "lower")];
    const upperError = invalidFields[configFieldName(props.parameter.value, "upper")];
    const qError = invalidFields[configFieldName(props.parameter.value, "q")];
    const baseError = invalidFields[configFieldName(props.parameter.value, "base")];
    const meanError = invalidFields[configFieldName(props.parameter.value, "mean")];
    const sdError = invalidFields[configFieldName(props.parameter.value, "sd")];

    const spaceOptions = useMemo(() => {
        let availableSpaces: Array<SearchSpace> = [SearchSpace.CHOICE, SearchSpace.GRID_SEARCH];
        props.parameter?.fieldTypes.forEach((fieldType) => {
            switch (fieldType) {
                case FieldType.FLOAT:
                case FieldType.NUMBER:
                    availableSpaces = [
                        ...[
                            SearchSpace.UNIFORM,
                            SearchSpace.QUNIFORM,
                            SearchSpace.LOGUNIFORM,
                            SearchSpace.QLOGUNIFORM,
                            SearchSpace.RANDN,
                            SearchSpace.QRANDN,
                        ],
                        ...availableSpaces,
                    ];
                    break;
                case FieldType.INT:
                    availableSpaces = [
                        ...[SearchSpace.RANDINT, SearchSpace.QRANDINT, SearchSpace.LOGRANDINT, SearchSpace.QLOGRANDINT],
                        ...availableSpaces,
                    ];
                    break;
                case FieldType.BOOLEAN:
                case FieldType.STRING:
                case FieldType.NULL:
                    break;
                default:
                    console.warn(`Unknown field type "${fieldType}" for parameter ${props.parameter.title}`);
                    break;
            }
        });

        return availableSpaces.map(mapSpaceOptions);
    }, [props.parameter.fieldTypes, props.parameter.title]);

    const selectOptions = useMemo(() => {
        const options = props.parameter.config?.categories
            ? props.parameter.config.categories.map((option, index) => {
                  const localValue = localState?.categories?.[index] ? localState.categories[index] : option;
                  return mapOption(option, String(localValue));
              })
            : props.parameter.config?.values
              ? props.parameter.config.values.map((option, index) => {
                    const localValue = localState?.values?.[index] ? localState.values[index] : option;
                    return mapOption(option, String(localValue));
                })
              : [];

        if (props.parameter?.enum.length) {
            props.parameter.enum.forEach((enumOption) => {
                // @TODO: Remove once this is cleaned up Ludwig
                // https://github.com/ludwig-ai/ludwig-docs/issues/191
                if (enumOption === "ffill" || enumOption === "bfill") return;
                if (enumOption === "pad") {
                    options.push({
                        text: "pad / ffill",
                        value: "ffill",
                    });
                    return;
                }
                if (enumOption === "backfill") {
                    options.push({
                        text: "backfill / bfill",
                        value: "bfill",
                    });
                    return;
                }

                // Make sure we don't add the same option twice!
                if (options.some((option) => option.value === enumOption)) {
                    return;
                }

                options.push(mapOption(enumOption));
            });
        }

        if (props.parameter.fieldTypes.includes(FieldType.BOOLEAN)) {
            [true, false].forEach((option) => {
                options.push(mapOption(option));
            });
        }

        return options;
    }, [
        props.parameter.config.categories,
        props.parameter.config.values,
        props.parameter.enum,
        props.parameter.fieldTypes,
    ]);

    // If the only field type supported is a string and we have enum,
    // the user must select from the enum.
    const targetsAllowAdditions = useMemo(() => {
        const cleanedFieldTypes = props.parameter.fieldTypes.filter((fieldType) => fieldType !== "null");
        // null is not included in the definition of the function called by filter.
        const cleanedEnum = props.parameter.enum.filter((option) => option !== null);
        if (cleanedFieldTypes.length === 1 && cleanedFieldTypes.includes(FieldType.STRING) && cleanedEnum.length > 0) {
            return false;
        }

        return true;
    }, [props.parameter.fieldTypes, props.parameter.enum]);

    const lastPeriodInTitle = props.parameter.title.lastIndexOf(".");
    const splitTitle = {
        prepend: props.parameter.title.substring(0, lastPeriodInTitle + 1),
        property: props.parameter.title.substring(lastPeriodInTitle + 1),
    };

    const placeHolderStyle = {
        minHeight: "inherit",
        position: "relative",
        padding: "24px 24px 6px 24px",
        marginTop: 0,
    };

    const noPadding = {
        padding: 0,
    };

    const noMargin = {
        margin: 0,
    };

    const inputStyle = {
        width: "80px",
    };

    return (
        <Segment placeholder style={placeHolderStyle} className="parameter-option">
            <Grid style={noMargin}>
                <Grid.Row as="fieldset" style={noPadding}>
                    <Grid.Column
                        mobile={16}
                        tablet={4}
                        computer={6}
                        style={{
                            ...noPadding,
                            display: "flex",
                            flexDirection: "row" as "row",
                            justifyContent: "flex-start",
                        }}
                    >
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div className="flexItem">
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <legend
                                        className="parameter-option-legend"
                                        tabIndex={0}
                                        style={{
                                            fontWeight: 700,
                                            fontSize: "1rem",
                                            width: "fit-content",
                                            marginRight: "8px",
                                        }}
                                    >
                                        {splitTitle.prepend}
                                        <span style={{ color: SEMANTIC_PINK }}>{splitTitle.property}</span>
                                    </legend>
                                    {props.parameter.description
                                        ? getTooltip(
                                              "",
                                              props.parameter.title,
                                              props.parameter.description,
                                              props.parameter?.default,
                                          )
                                        : null}
                                </div>
                            </div>
                            <div className="flexItem">
                                {props.parameter.hasOwnProperty("default") ? (
                                    <p style={{ fontSize: "12px", color: SEMANTIC_GREY_DISABLED }}>
                                        Default: {String(props.parameter.default)}
                                    </p>
                                ) : null}
                            </div>
                        </div>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={12} computer={10} style={noPadding}>
                        <InputRow
                            label="Search Space"
                            inputs={
                                <Form.Select
                                    className={`${metrics.BLOCK_AUTO_CAPTURE} search-space-input`}
                                    placeholder=""
                                    fluid
                                    options={spaceOptions}
                                    value={props.parameter.config?.space ? props.parameter.config.space : ""}
                                    onChange={(event: SyntheticEvent, { value }) => {
                                        // @ts-expect-error Semantic React Defintion includes undefined; this is a dropdown
                                        const newParam = generateParam(value, props.parameter.config, previousData);
                                        props.updateParameter(props.parameter.value, newParam);
                                    }}
                                />
                            }
                        />
                        {[SearchSpace.CHOICE, SearchSpace.GRID_SEARCH].includes(props.parameter.config?.space) ? (
                            <InputRow
                                label="Values"
                                inputs={
                                    props.parameter.fieldTypes.length === 1 &&
                                    props.parameter.fieldTypes.includes(FieldType.BOOLEAN) ? (
                                        <DisabledOptions
                                            title={props.parameter.title}
                                            options={
                                                props.parameter.config.space === SearchSpace.CHOICE
                                                    ? props.parameter.config?.categories
                                                    : props.parameter.config?.values
                                            }
                                        />
                                    ) : (
                                        <Form.Select
                                            className={`${metrics.BLOCK_AUTO_CAPTURE} targets-input`}
                                            placeholder="Enter value + use 'enter' to add another"
                                            style={{ width: "100%" }}
                                            fluid
                                            multiple
                                            search
                                            selection
                                            // React Semantic UI does not allow for a value of `null` in the schema
                                            // But it does work and we need it for hyperopting over null parameters.
                                            // @ts-expect-error nulls work but are not supported according to React Semantic UI
                                            options={selectOptions}
                                            allowAdditions={targetsAllowAdditions}
                                            noResultsMessage="Enter custom value."
                                            // React Semantic UI does not allow for a value of `null` in the schema
                                            // But it does work and we need it for hyperopting over null parameters.
                                            // React Semantic UI needs the `value` to match the Ludwig config object so it can select the active options.
                                            // @ts-expect-error nulls work but are not supported according to React Semantic UI
                                            value={
                                                props.parameter.config.space === SearchSpace.CHOICE
                                                    ? props.parameter.config?.categories
                                                    : props.parameter.config?.values
                                            }
                                            disabled={
                                                props.parameter.fieldTypes.includes(FieldType.BOOLEAN) ? true : false
                                            }
                                            renderLabel={(option) => ({ content: String(option.text), color: "blue" })}
                                            error={targetsError}
                                            onChange={(event: SyntheticEvent, { value }) => {
                                                if (!value) {
                                                    return;
                                                }

                                                // Value will always be an Array since we allow multiple values for choice and grid
                                                let arrayValues = value as (
                                                    | string
                                                    | string[]
                                                    | number
                                                    | boolean
                                                    | undefined
                                                    | null
                                                )[];
                                                const localValues: (string | number | boolean | undefined | null)[] =
                                                    [];
                                                const updatedParameter = { ...props.parameter.config };
                                                const configProperty =
                                                    updatedParameter.space === SearchSpace.CHOICE
                                                        ? "categories"
                                                        : "values";

                                                arrayValues = arrayValues.map((value) => {
                                                    const [textFieldValue, convertedValue] =
                                                        convertUserInputToType(value);
                                                    // @ts-expect-error Semantic React Defintion includes (string[])[]; our values are always just (string)[]
                                                    localValues.push(textFieldValue);
                                                    return convertedValue;
                                                });

                                                updateLocalState(`${configProperty}`, localValues);

                                                // TODO: should we sort?

                                                // @ts-expect-error Semantic React Defintion includes (string[])[]; our values are always just (string)[]
                                                updatedParameter[configProperty] = [...arrayValues];
                                                props.updateParameter(props.parameter.value, updatedParameter);

                                                setPreviousData({
                                                    ...previousData,
                                                    [configProperty]: updatedParameter[configProperty],
                                                });
                                            }}
                                        />
                                    )
                                }
                            />
                        ) : null}
                        {[
                            SearchSpace.UNIFORM,
                            SearchSpace.QUNIFORM,
                            SearchSpace.LOGUNIFORM,
                            SearchSpace.QLOGUNIFORM,
                            SearchSpace.RANDINT,
                            SearchSpace.QRANDINT,
                            SearchSpace.LOGRANDINT,
                            SearchSpace.QLOGRANDINT,
                        ].includes(props.parameter.config?.space) ? (
                            <InputRow
                                label="Range"
                                inputs={
                                    <>
                                        <Form.Input
                                            className={metrics.BLOCK_AUTO_CAPTURE}
                                            placeholder="min"
                                            style={inputStyle}
                                            value={getFieldState("lower", localState, props.parameter.config)}
                                            error={lowerError}
                                            onChange={(event, { value }) => {
                                                const [textFieldValue, convertedValue] = convertUserInputToType(value);
                                                updateLocalState("lower", String(textFieldValue));

                                                props.updateParameter(props.parameter.value, {
                                                    ...props.parameter.config,
                                                    lower: convertedValue,
                                                });

                                                setPreviousData({
                                                    ...previousData,
                                                    // @ts-expect-error need error handling for NaNs
                                                    lower: convertedValue,
                                                });
                                            }}
                                        />
                                        <p style={{ marginBottom: 0, marginRight: "1em" }}>to</p>
                                        <Form.Input
                                            className={metrics.BLOCK_AUTO_CAPTURE}
                                            placeholder="max"
                                            style={inputStyle}
                                            value={getFieldState("upper", localState, props.parameter.config)}
                                            error={upperError}
                                            onChange={(event, { value }) => {
                                                const [textFieldValue, convertedValue] = convertUserInputToType(value);
                                                updateLocalState("upper", String(textFieldValue));

                                                props.updateParameter(props.parameter.value, {
                                                    ...props.parameter.config,
                                                    upper: convertedValue,
                                                });

                                                setPreviousData({
                                                    ...previousData,
                                                    // @ts-expect-error need error handling for NaNs
                                                    upper: convertedValue,
                                                });
                                            }}
                                        />
                                    </>
                                }
                            />
                        ) : null}
                        {[
                            SearchSpace.QUNIFORM,
                            SearchSpace.QLOGUNIFORM,
                            SearchSpace.QRANDN,
                            SearchSpace.QRANDINT,
                            SearchSpace.QLOGRANDINT,
                        ].includes(props.parameter.config?.space) ? (
                            <InputRow
                                label="Quantization (q)"
                                inputs={
                                    <Form.Input
                                        className={metrics.BLOCK_AUTO_CAPTURE}
                                        placeholder="q"
                                        style={inputStyle}
                                        value={getFieldState("q", localState, props.parameter.config)}
                                        error={qError}
                                        onChange={(event, { value }) => {
                                            const [textFieldValue, convertedValue] = convertUserInputToType(value);
                                            updateLocalState("q", String(textFieldValue));

                                            props.updateParameter(props.parameter.value, {
                                                ...props.parameter.config,
                                                q: convertedValue,
                                            });

                                            setPreviousData({
                                                ...previousData,
                                                // @ts-expect-error need error handling for NaNs
                                                q: convertedValue,
                                            });
                                        }}
                                    />
                                }
                            />
                        ) : null}
                        {[SearchSpace.LOGUNIFORM, SearchSpace.QLOGUNIFORM].includes(props.parameter.config?.space) ? (
                            <InputRow
                                label="Base"
                                inputs={
                                    <Form.Input
                                        className={metrics.BLOCK_AUTO_CAPTURE}
                                        placeholder="base"
                                        style={inputStyle}
                                        value={getFieldState("base", localState, props.parameter.config)}
                                        error={baseError}
                                        onChange={(event, { value }) => {
                                            const [textFieldValue, convertedValue] = convertUserInputToType(value);
                                            updateLocalState("base", String(textFieldValue));

                                            props.updateParameter(props.parameter.value, {
                                                ...props.parameter.config,
                                                base: convertedValue,
                                            });

                                            setPreviousData({
                                                ...previousData,
                                                // @ts-expect-error need error handling for NaNs
                                                base: convertedValue,
                                            });
                                        }}
                                    />
                                }
                            />
                        ) : null}
                        {[SearchSpace.RANDN, SearchSpace.QRANDN].includes(props.parameter.config?.space) ? (
                            <InputRow
                                label="Mean"
                                inputs={
                                    <Form.Input
                                        className={metrics.BLOCK_AUTO_CAPTURE}
                                        placeholder="mean"
                                        style={inputStyle}
                                        value={getFieldState("mean", localState, props.parameter.config)}
                                        error={meanError}
                                        onChange={(event, { value }) => {
                                            const [textFieldValue, convertedValue] = convertUserInputToType(value);
                                            updateLocalState("mean", String(textFieldValue));

                                            props.updateParameter(props.parameter.value, {
                                                ...props.parameter.config,
                                                mean: convertedValue,
                                            });

                                            setPreviousData({
                                                ...previousData,
                                                // @ts-expect-error need error handling for NaNs
                                                mean: convertedValue,
                                            });
                                        }}
                                    />
                                }
                            />
                        ) : null}
                        {[SearchSpace.RANDN, SearchSpace.QRANDN].includes(props.parameter.config?.space) ? (
                            <InputRow
                                label="Standard Deviation"
                                inputs={
                                    <Form.Input
                                        className={metrics.BLOCK_AUTO_CAPTURE}
                                        placeholder="sd"
                                        style={inputStyle}
                                        value={getFieldState("sd", localState, props.parameter.config)}
                                        error={sdError}
                                        onChange={(event, { value }) => {
                                            const [textFieldValue, convertedValue] = convertUserInputToType(value);
                                            updateLocalState("sd", String(textFieldValue));

                                            props.updateParameter(props.parameter.value, {
                                                ...props.parameter.config,
                                                sd: convertedValue,
                                            });

                                            setPreviousData({
                                                ...previousData,
                                                // @ts-expect-error need error handling for NaNs
                                                sd: convertedValue,
                                            });
                                        }}
                                    />
                                }
                            />
                        ) : null}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <button
                aria-label={`Remove ${props.parameter.title} parameter`}
                style={{
                    position: "absolute",
                    top: "24px",
                    right: "24px",
                    display: "inline-block",
                    border: "none",
                    cursor: "pointer",
                    background: "none",
                }}
                onClick={() => {
                    props.removeParameter(props.parameter.value);
                }}
            >
                <Icon name="close" />
            </button>
        </Segment>
    );
};

export default ParameterOption;
