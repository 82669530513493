import { useMemo } from "react";
import { CartesianGrid, Label, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { max, min, sampleVariance } from "simple-statistics";
import stc from "string-to-color";
import { formatValueToNumericString } from "../../../../utils/numbers";
import { LINE_AXIS_NAME, MetricsPayloadData, MetricsPayloadDataKey } from "./util";

const MetricsLineGraph = (props: {
    selectedMetrics: MetricsPayloadDataKey[];
    chartData: MetricsPayloadData[] | undefined;
}) => {
    // Parent state:
    const { selectedMetrics, chartData } = props;

    // Set y-axis domain using variance:
    const yDomain = useMemo(() => {
        let allDatapoints: number[] = [];
        selectedMetrics.forEach((metric) => {
            const metricData = chartData?.reduce((accumulator: number[], current) => {
                const d = current[metric];
                if (d !== undefined) {
                    accumulator.push(d);
                }
                return accumulator;
            }, []);
            if (metricData !== undefined) {
                allDatapoints.push(...metricData);
            }
        });

        // If there are fewer than 2 data points, let recharts set the domain:
        if (allDatapoints.length < 2) {
            return undefined;
        }
        const svar = sampleVariance(allDatapoints);
        const minY = min(allDatapoints);
        const maxY = max(allDatapoints);
        const varMultiplier = 0.1;

        // NOTE: With current metrics the values should never be negative, so floor the bottom of y-axis at 0:
        const low = Math.max(0, minY - svar * varMultiplier);
        const high = maxY + svar * varMultiplier;

        return [low, high];
    }, [chartData, selectedMetrics]);

    if (chartData === undefined || chartData.length === 0) {
        return null;
    }

    // https://github.com/recharts/recharts/issues/172#issuecomment-307858843
    return (
        <ResponsiveContainer width={"99.8%"} aspect={2}>
            <LineChart data={chartData} style={{ overflow: "none" }}>
                <XAxis dataKey={LINE_AXIS_NAME}>
                    <Label value={"Checkpoint Number"} position={"bottom"} />
                </XAxis>
                <YAxis tickFormatter={(val) => formatValueToNumericString(val, 2)} domain={yDomain} />
                {selectedMetrics.map((name) => {
                    // If the data for the selected metric is undefined, don't show the line (note that we assume it
                    //  would then be undefined for the whole data series):
                    const latestValue = chartData.at(-1)?.[name];
                    if (latestValue === undefined) {
                        return null;
                    }

                    return (
                        <Line
                            key={`line-${name}`}
                            type="monotone"
                            dataKey={name}
                            name={name}
                            // TODO: Generate better colors?
                            stroke={stc(name)}
                            strokeWidth={2}
                            dot={false}
                        />
                    );
                })}
                <Tooltip />
                <Legend wrapperStyle={{ bottom: -20, paddingBottom: "20px" }} />
                <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
            </LineChart>
        </ResponsiveContainer>
    );
};

export default MetricsLineGraph;
