import { Icon, Popup } from "semantic-ui-react";

const EngineTooltipMap: Record<string, string> = {
    autoSuspendSeconds:
        "How long the engine can be idle (have no active jobs) before resources are scaled down. " +
        "Suspended engines take 8-10 minutes to start up again.",
    autoResume: "Allows the engine to automatically resume from a suspended state if given a job (query or model).",
};

export const EngineTooltip = (props: { name: string; label: string }) => {
    const { name, label } = props;

    return (
        <label>
            {label}&ensp;
            <Popup
                className="transition-scale"
                hoverable
                wide={"very"}
                position={"right center"}
                trigger={<Icon name={"question circle"} color={"blue"} />}
                content={EngineTooltipMap[name]}
            />
        </label>
    );
};
