const safeJsonParse = (str: string) => {
    try {
        return [null, JSON.parse(str)];
    } catch (err) {
        return [err];
    }
};
export const loadResultsDataset = (resDataset: ResultsDataset) => {
    let metadata = resDataset.metadata;
    if (!metadata) {
        metadata = {};
    }

    let data = undefined;
    if (resDataset.data) {
        data = resDataset.data.map((row) => {
            return row.map((value, valueIndex) => {
                const column = resDataset.columns?.[valueIndex];
                const columnMeta = metadata[column!];
                if (columnMeta && columnMeta["format"] === "json") {
                    const [err, jsonData] = safeJsonParse(value);
                    if (err) {
                        throw new Error("Failed to parse JSON field " + column + ": " + err);
                    }
                    return jsonData;
                }
                return value;
            });
        });
    }

    return {
        ...resDataset,
        data,
        metadata,
    };
};
