import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Grid, Header, Menu, Modal, Segment, Table } from "semantic-ui-react";

// @ts-ignore
import { AdditiveForceVisualizer } from "shapjs";

import { MenuItemProps } from "semantic-ui-react/dist/commonjs/collections/Menu/MenuItem";
import metrics from "../../../metrics/metrics";
import { formatValueToNumericString } from "../../../utils/numbers";
import { TextExplanationsSegment } from "./ExplanationText";
import SummaryChart from "./SummaryChart";

const ExplanationMetricRoot = "Query.QueryResults.Explanation";

const SUMMARY = "summary";
const TABLE = "table";
const FORCE = "force";
const TLE = "text explanation";

function isFloat(n: any) {
    return Number(n) === n && n % 1 !== 0;
}

function valueToString(value: any) {
    if (value == null) {
        return "";
    }

    if (isFloat(value)) {
        value = formatValueToNumericString(value, 2);
    }

    return value.toString();
}

function topK(features: Record<string, ShapFeature>, k: number): ShapFeature[] {
    return Object.values(features)
        .sort((a, b) => Math.abs(b.effect || 0) - Math.abs(a.effect || 0))
        .slice(0, k);
}

function renderFeatureList(features: ShapFeature[]) {
    return features.map((feature) => feature.name).join(", ");
}

function ExplanationModal(props: { metadata: ShapMetadata; data: ShapResult }) {
    const [open, setOpen] = useState(false);
    const [activeItem, setActiveItem] = useState<string | undefined>(SUMMARY);
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const idx2str = props.metadata.targetIdx2str;
        if (idx2str) {
            const prediction = props.data.prediction;
            const idx = idx2str.indexOf(prediction);
            if (idx >= 0) {
                setActiveIndex(idx);
            }
        } else if (props.metadata.targetBool) {
            // Always display the "positive" probability
            setActiveIndex(1);
        }
    }, [props.data.prediction, props.metadata.targetBool, props.metadata.targetIdx2str]);

    const getVisualizer = (activeItem: string | undefined, data: any) => {
        if (activeItem === SUMMARY) {
            let summaryProps = {
                width: 650,
                explanations: [data],
                useAbs: false,
                ...{ plot_cmap: ["rgb(255, 13, 87)", "rgb(30, 136, 229)"] },
            };
            return <SummaryChart {...summaryProps} />;
        } else if (activeItem === FORCE) {
            let props = {
                ...data,
                ...{ plot_cmap: ["rgb(30, 136, 229)", "rgb(255, 13, 87)"] },
            };
            const copiedObject = JSON.parse(JSON.stringify(props));
            return <AdditiveForceVisualizer {...copiedObject} />;
        } else if (activeItem === TABLE) {
            return (
                <div className="row" style={{ overflowX: "auto" }}>
                    <Table celled definition>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell />
                                {data.featureNames.map((column: string) => {
                                    return (
                                        <Table.HeaderCell singleLine key={column}>
                                            {column}
                                        </Table.HeaderCell>
                                    );
                                })}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            <Table.Row key={"value"}>
                                <Table.Cell key={"type"}>{"Value"}</Table.Cell>
                                {data.featureNames.map((column: string, i: number) => {
                                    // https://linear.app/predibase/issue/PUX-845/fe-bug-when-clicking-table-in-explanation-modal
                                    // Bug where split output feature can appear in featureNames
                                    // so add ?. operator for undefined.

                                    // Remove when the ludwig side is updated
                                    return (
                                        <Table.Cell key={column}>
                                            {valueToString(data.features[i.toString()]?.value)}
                                        </Table.Cell>
                                    );
                                })}
                            </Table.Row>
                            <Table.Row key={"effect"}>
                                <Table.Cell key={"type"}>{"Effect"}</Table.Cell>
                                {data.featureNames.map((column: string, i: number) => {
                                    return (
                                        <Table.Cell key={column}>
                                            {formatValueToNumericString(data.features[i.toString()]?.effect, 2)}
                                        </Table.Cell>
                                    );
                                })}
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </div>
            );
        } else if (activeItem === TLE) {
            return <TextExplanationsSegment explanations={[data]} metadata={props.metadata} />;
        }
    };

    const handleItemClick = (e: React.MouseEvent<HTMLAnchorElement>, { name }: MenuItemProps) => {
        setActiveItem(name);
    };

    const target = props.metadata.target;
    const data = props.data.data[activeIndex];
    const summary = renderFeatureList(topK(data.features, 3));
    const idx2str: string[] = props.metadata.targetIdx2str || (props.metadata.targetBool && ["false", "true"]) || [];
    const displayTLE = Object.values(data.features).some((feature) => {
        return feature.token_attributions;
    });

    const menu = (
        <Menu secondary pointing>
            <Menu.Item key={SUMMARY} name={SUMMARY} active={activeItem === SUMMARY} onClick={handleItemClick} />
            <Menu.Item key={TABLE} name={TABLE} active={activeItem === TABLE} onClick={handleItemClick} />
            <Menu.Item key={FORCE} name={FORCE} active={activeItem === FORCE} onClick={handleItemClick} />
            {displayTLE && <Menu.Item key={TLE} name={TLE} active={activeItem === TLE} onClick={handleItemClick} />}
        </Menu>
    );

    const indexSelector = idx2str ? (
        <>
            <Header textAlign={"center"} as="h5">
                Class
            </Header>
            <Form>
                {idx2str.map((label: string, labelIndex: number) => (
                    <Form.Field key={label}>
                        <Checkbox
                            radio
                            label={label}
                            checked={labelIndex === activeIndex}
                            onChange={(e, data) => {
                                setActiveIndex(labelIndex);
                                metrics.captureClick(ExplanationMetricRoot + ".Label", { labelIndex: labelIndex });
                            }}
                        />
                    </Form.Field>
                ))}
            </Form>
        </>
    ) : null;

    let content = null;
    if (indexSelector) {
        content = (
            <Grid>
                <Grid.Column computer={4}>
                    <Segment style={{ backgroundColor: "rgba(0, 0, 0, 0.03)" }}>{indexSelector}</Segment>
                </Grid.Column>
                <Grid.Column computer={12}>
                    <Segment>
                        {menu}
                        {getVisualizer(activeItem, data)}
                    </Segment>
                </Grid.Column>
            </Grid>
        );
    } else {
        content = (
            <Segment>
                {menu}
                {getVisualizer(activeItem, data)}
            </Segment>
        );
    }

    return (
        <Modal
            size={"large"}
            onClose={() => {
                setOpen(false);
            }}
            onOpen={() => {
                setOpen(true);
            }}
            open={open}
            trigger={<button className={`${metrics.BLOCK_AUTO_CAPTURE} button-reset-table`}>{summary}</button>}
        >
            <Modal.Header>Explanation: {target}</Modal.Header>
            <Modal.Content style={{ overflowX: "auto", paddingTop: 10 }}>{content}</Modal.Content>
            <Modal.Actions>
                <Button
                    className={metrics.BLOCK_AUTO_CAPTURE}
                    color="black"
                    onClick={() => {
                        setOpen(false);
                    }}
                >
                    Close
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

export default ExplanationModal;
