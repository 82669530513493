import { CSSProperties, SyntheticEvent } from "react";
import { Checkbox as SemanticCheckbox, CheckboxProps as SemanticCheckboxProps } from "semantic-ui-react";

import { SEMANTIC_RED_LIGHT } from "../utils/colors";

const Checkbox = (props: {
    path: string;
    label?: string | JSX.Element;
    error: boolean;
    value: boolean;
    onChange: (checked?: boolean) => void;
    style?: CSSProperties;
    readOnly?: boolean;
}) => (
    <SemanticCheckbox
        disabled={props.readOnly}
        style={{
            verticalAlign: "middle",
        }}
        key={props.path + "_checkbox"}
        label={<label style={{ color: props.error ? SEMANTIC_RED_LIGHT : "inherit" }}>{props.label}</label>}
        indeterminate={props.error ? true : false}
        checked={props.value}
        onChange={(event: SyntheticEvent, data: SemanticCheckboxProps) => {
            props.onChange(data.checked);
        }}
    />
);

export default Checkbox;
