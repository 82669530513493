import { atom } from "recoil";

export const MODELS_OFFSET_STATE = atom({
    key: "MODELS_OFFSET_STATE",
    default: 0,
});

export const MODELS_TOTAL_STATE = atom({
    key: "MODELS_TOTAL_STATE",
    default: 0,
});

export const MODELS_SEARCH_VAL_STATE = atom({
    key: "MODELS_SEARCH_VAL_STATE",
    default: "",
});

export const MODELS_SCOPE_USER_STATE = atom({
    key: "MODELS_SCOPE_USER",
    default: false,
});
