import { ValidateFunction } from "ajv";
import type { JSONSchema7 } from "json-schema";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Button, Divider, Modal } from "semantic-ui-react";
import metrics from "../../../metrics/metrics";
import { validateConfigAgainstSchema } from "../../../utils/ajv";
import { setSiblingTypeProperty } from "../../../utils/jsonSchema";
import { removeEmptyConfigProperties } from "../utils";

const MetricEventScope = ".Modal";

const ConfigSetModal = (props: {
    config?: CreateModelConfig;
    schema?: JSONSchema7;
    validator?: ValidateFunction;
    applyConfig: (config?: CreateModelConfig) => void;
    modalOpen: boolean;
    setModalOpen: (arg0: boolean) => void;
    featureIndex: number;
    modalHeader: string;
    metricRoot: string;
    ModalComponent: React.ComponentType<any>;
    readOnly: boolean;
    expectedImpactFilter: number;
    setExpectedImpactFilter: (expectedImpactFilter: number) => void;
}) => {
    const { config, schema, validator } = props;
    const [configState, setConfigState] = useState(_.cloneDeep(config));
    const [localInvalidFields, setLocalInvalidFields] = useState({});

    const setConfigProperty = (path: string, value: any) => {
        let updatedState = _.cloneDeep(configState) as CreateModelConfig;

        // Clean up the config object when a user removes a previous
        // value in an input field:
        if (value === "" || value === undefined) {
            _.unset(updatedState, path);
            removeEmptyConfigProperties(path, updatedState);
        } else {
            _.set(updatedState, path, value);
            // @ts-expect-error
            updatedState = setSiblingTypeProperty(path, updatedState, schema);
        }

        setConfigState(updatedState);
        const invalidFields = validateConfigAgainstSchema(validator, updatedState);
        setLocalInvalidFields(invalidFields);
    };

    // Update initial state when modal opens:
    useEffect(() => {
        const configCopy = _.cloneDeep(config);
        setConfigState(configCopy);
        const invalidFields = validateConfigAgainstSchema(validator, configCopy);
        setLocalInvalidFields(invalidFields);
    }, [config, props.modalOpen]);

    const applyConfig = () => {
        props.applyConfig(configState);
        props.setModalOpen(false);
    };

    const modalComponent = (
        <props.ModalComponent
            readOnly={props.readOnly}
            metricRoot={props.metricRoot}
            config={configState}
            setConfig={setConfigProperty}
            invalidFields={localInvalidFields}
            featureIndex={props.featureIndex}
            schema={schema}
            expectedImpactFilter={props.expectedImpactFilter}
        />
    );

    return (
        <Modal
            onClose={() => {
                props.setModalOpen(false);
            }}
            open={props.modalOpen}
        >
            <Modal.Header>{"Edit " + props.modalHeader}</Modal.Header>
            <Modal.Content>
                <Divider hidden />
                <Modal.Description>{modalComponent}</Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    className={metrics.BLOCK_AUTO_CAPTURE}
                    color="black"
                    onClick={() => {
                        props.setModalOpen(false);
                    }}
                >
                    Cancel
                </Button>
                {!props.readOnly ? (
                    <Button content="Apply" labelPosition="right" icon="checkmark" onClick={applyConfig} positive />
                ) : null}
            </Modal.Actions>
        </Modal>
    );
};

export default ConfigSetModal;
