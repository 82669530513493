import { CSSProperties } from "react";
import { Form } from "semantic-ui-react";

import { handleLocalState } from "../models/create/forms/utils";

const TextArea = (props: {
    path: string;
    title?: string;
    error: boolean;
    value?: string;
    description?: string;
    defaultValue: any;
    setConfig: (path: string, typedValue: any) => void;
    setLocalState: (localState: any, path: string) => void;
    schemaPath: string;
    style?: CSSProperties;
    readOnly?: boolean;
}) => (
    <Form.TextArea
        readOnly={props.readOnly}
        key={props.path + "_input"}
        name={props.title}
        style={{
            width: `${413 / 14}rem`,
            marginRight: "8px",
            ...props.style,
        }}
        placeholder={props.defaultValue ? props.defaultValue : props.title}
        error={props.error}
        value={typeof props.value === "undefined" ? "" : String(props.value)}
        onChange={(event, { value }) => {
            handleLocalState(props.path, value, props.setLocalState, props.setConfig);
        }}
    />
);

export default TextArea;
