import { Header } from "semantic-ui-react";
import { SubscriptionButton } from "./SubscriptionButtons";

import { CurrentUser, UserContext } from "../../types/user";
import { isKratosUserContext } from "../../utils/kratos";
import "./GlobalHeader.css";

function GlobalHeader(props: { headerName: string; component: JSX.Element; userContext?: UserContext | CurrentUser }) {
    const { headerName, component, userContext } = props;

    let tenantTier;
    if (userContext) {
        const isKratosContext = isKratosUserContext(userContext);
        tenantTier = isKratosContext ? userContext?.tenant.subscription.tier : userContext?.tenant.tier;
    }

    return (
        <>
            <div className="global-header">
                <Header as="h2" block>
                    {headerName}
                </Header>
                <SubscriptionButton isExpired={userContext?.isExpired} currentTier={tenantTier} />
            </div>
            <div className={"component"}>{component}</div>
        </>
    );
}

export default GlobalHeader;
