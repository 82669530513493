import { CSSProperties, SyntheticEvent } from "react";
import { DropdownItemProps, DropdownProps, Form } from "semantic-ui-react";
import { handleLocalState } from "../models/create/forms/utils";
import { dropDownWidths } from "./forms/json-schema-form/styling";

// TODO(ksbrar): This is a hacky method aimed at number values and specifically for the 08/02/23 release. Clean this up after!
const matchValueToOption = (value: any, options: any[], defaultValue: any) => {
    if (Number.isInteger(value)) {
        return value;
    }

    return value ? (Array.isArray(value) ? value.map((v) => String(v)) : value) : defaultValue;
};

const Dropdown = (props: {
    path: string;
    title?: string;
    error: boolean;
    multiple?: boolean;
    fluid?: boolean;
    options: DropdownItemProps[];
    value: number | string | string[];
    defaultValue: any;
    setConfig: (path: string, typedValue: any) => void;
    setLocalState: (localState: any, path: string) => void;
    style?: CSSProperties;
    size?: dropDownWidths;
    readOnly?: boolean;
}) => {
    const {
        path,
        title,
        error,
        multiple,
        fluid,
        options,
        value,
        defaultValue,
        setConfig,
        setLocalState,
        style,
        size,
        readOnly,
    } = props;

    return (
        <Form.Select
            search
            fluid={fluid}
            multiple={multiple}
            selection={multiple}
            disabled={readOnly}
            key={path + "_dropdown"}
            name={title}
            error={error}
            options={options}
            value={matchValueToOption(value, options, defaultValue)}
            style={{
                opacity: 1.0,
                width: "inherit",
                minWidth: props?.size ? `${size}rem` : "100%",
                marginRight: "8px",
                ...style,
            }}
            placeholder={defaultValue ? String(defaultValue) : title}
            renderLabel={
                multiple ? (option: DropdownItemProps) => ({ color: "blue", content: option.text }) : undefined
            }
            onChange={(event: SyntheticEvent, data: DropdownProps) => {
                // @ts-expect-error
                handleLocalState(path, data.value, setLocalState, setConfig);
            }}
        />
    );
};

export default Dropdown;
