import React from "react";
import { Image, Popup } from "semantic-ui-react";
import { getImageForConnector } from "../utils/connectors";

const ConnectionImagePopup = (props: { connectionType?: string; style?: React.CSSProperties }) => {
    if (!props.connectionType) {
        return null;
    }

    return (
        <Popup
            className={"transition-scale"}
            position={"top center"}
            content={props.connectionType}
            trigger={
                <Image
                    src={getImageForConnector(props.connectionType as string)}
                    style={{ borderRadius: 0, ...props.style }}
                    avatar
                    alt={props.connectionType}
                />
            }
        />
    );
};

export default ConnectionImagePopup;
