// TODO: Ideally we could autogenerate these too:
// Types below generated by ChatGPT to mirror Lorax structs, see:
// https://github.com/predibase/lorax/blob/0b9117fdc98bac36c8f64a954d7892f276c107b9/router/src/lib.rs#L448

interface LoraxStreamAlternativeToken {
    id: number;
    text: string;
    logprob?: number | null;
}

interface LoraxStreamToken {
    id: number;
    text: string;
    logprob?: number | null;
    special: boolean;
    alternative_tokens?: LoraxStreamAlternativeToken[] | null;
}

type LoraxStreamFinishReason = "length" | "eos_token" | "stop_sequence";

interface LoraxStreamDetails {
    finish_reason: LoraxStreamFinishReason;
    prompt_tokens: number;
    generated_tokens: number;
    seed?: number | null;
}

interface LoraxStreamResponse {
    token: LoraxStreamToken;
    generated_text?: string | null;
    details?: LoraxStreamDetails | null;
}

// Customizations:

export type PredibaseStreamFinishReason = LoraxStreamFinishReason | "early_cancellation";

export interface PredibaseStreamDetails extends Omit<LoraxStreamDetails, "finish_reason"> {
    finish_reason: PredibaseStreamFinishReason;
}

export interface PredibaseStreamResponse extends Omit<LoraxStreamResponse, "details"> {
    details?: PredibaseStreamDetails | null;
    error: any; // TODO: Not sure if this actually exists? Only added it because of generate_stream usage
}

export const predibaseStreamHasFinished = (details?: PredibaseStreamDetails | null) => {
    return Boolean(details?.finish_reason);
};
