export const alphabeticalCollator = new Intl.Collator("en", { numeric: true, sensitivity: "base" });
export const modelStatusValueMap: Record<string, number> = {
    queued: 1,
    preprocessing: 2,
    training: 3,
    evaluating: 4,
    visualizing: 5,
    explaining: 6,
    ready: 7,
    deploying: 8,
    deployed: 9,
    undeploying: 10,
    canceling: 11,
    canceled: 12,
    failed: 13,
};
