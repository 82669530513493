import { useRecoilState } from "recoil";
import { USER_STATE } from "../state/global";
import { isKratosUserContext } from "../utils/kratos";

const InternalBanner = () => {
    const [userCtx] = useRecoilState(USER_STATE);
    let shortCode;

    if (userCtx) {
        const isKratosContext = isKratosUserContext(userCtx);
        shortCode = isKratosContext ? userCtx.tenant.shortCode : userCtx.tenant.shortcode;
    }

    return (
        <>
            {userCtx && userCtx.username === `support+${shortCode}` && (
                <div
                    style={{
                        backgroundColor: "rgb(219, 40, 40)",
                        color: "#ffffff",
                        textAlign: "center",
                        padding: "10px",
                    }}
                >
                    <p
                        style={{
                            fontSize: 20,
                            fontWeight: "bold",
                        }}
                    >
                        ⚠️ YOU ARE IN A SUPPORT ACCOUNT ⚠️
                    </p>
                </div>
            )}
        </>
    );
};

export default InternalBanner;
