/**
 * Get file type and extension
 *
 * @param name Name of the file
 * @param type Type (MIME type) of the file
 *
 * @returns {fileType, fileExt}
 *
 * @example
 * const {fileType, fileExt} = getFileTypeAndExtension("myFile.txt", "text/plain");
 *
 * // fileType = "plain"
 * // fileExt = "txt"
 *
 * @example
 * const {fileType, fileExt} = getFileTypeAndExtension("myFile.csv", "text/csv");
 *
 * // fileType = "csv"
 * // fileExt = "csv"
 */
export const getFileTypeAndExtension = (name: string, type: string) => {
    // Get file type - empty if not found
    let fileType = type || "";
    if (fileType) {
        fileType = fileType.split("/").pop() || "";
    }

    // Get file extension - empty if not found
    let fileExt = "";
    const splitArr = name?.split(".");
    if (splitArr.length > 1) {
        fileExt = splitArr[splitArr.length - 1];
    }

    return {
        fileType,
        fileExt,
    };
};
