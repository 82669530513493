import { Route, Routes } from "react-router-dom";
import DeploymentView from "./views/deployment/DeploymentView";
import DeploymentsView from "./views/deployments/DeploymentsView";

const DeploymentsViewRouter = () => {
    return (
        <Routes>
            <Route path="/:uuid" element={<DeploymentView />} />
            <Route path="/" element={<DeploymentsView />} />
        </Routes>
    );
};

export default DeploymentsViewRouter;
