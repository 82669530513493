import { AxiosInstance } from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { Divider, Grid, Loader, Message } from "semantic-ui-react";
import TargetSelector from "../../components/TargetSelector";
import { useAuth0TokenOptions } from "../../data";
import { createV1APIServer } from "../../utils/api";
import { alphabeticalCollator } from "../../utils/sort";
import { confusionMatrixDisabledCriteria, getConfigOutputFeatureNames, modelStatusDisabledCriteria } from "../util";
import { getStaticViz, VizType } from "./util";

function ConfusionMatrix(props: {
    model: Model;
    displayMatrixOnly?: boolean;
    target?: string;
    setTarget?: React.Dispatch<React.SetStateAction<string>>;
    errorMessage?: string;
}) {
    const [links, setLinks] = useState<VizLink[]>();
    const [errorMessage, setErrorMessage] = useState<string | null>(props.errorMessage || null);
    const [internalTarget, setInternalTarget] = useState<string>("");

    // Target can either be passed in as a prop (for ModelCompareView) or managed internally (for ModelVersionView)
    const [target, setTarget] =
        props.target && props.setTarget ? [props.target, props.setTarget] : [internalTarget, setInternalTarget];

    const statusDisabledMsg = modelStatusDisabledCriteria(props.model);
    const specificDisabledMsg = confusionMatrixDisabledCriteria(props.model);

    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    const [apiServer, setAPIServer] = useState<AxiosInstance | null>(null);
    useEffect(() => {
        const getAPIServer = async () => {
            const v1APIServer = await createV1APIServer(auth0TokenOptions);
            // NOTE: Whoever wrote the axios typings is a moron because the return type of axios.create is not
            // AxiosInstance -- it's a wrap function. And React will see that and treat it as a callback that
            // setState should directly call. FML.
            // See: [1], [2]:
            // [1]: https://github.com/axios/axios/issues/4365
            // [2]: https://stackoverflow.com/questions/64427195/calling-setstate-will-execute-the-function-value-instead-of-passing-it
            setAPIServer(() => v1APIServer);
        };
        getAPIServer();
    }, []);

    useEffect(() => {
        if (target && !(statusDisabledMsg || specificDisabledMsg) && !props.errorMessage && apiServer) {
            getStaticViz(apiServer, setLinks, setErrorMessage, props.model, VizType.CONFUSION_MATRIX, target);
        }
    }, [props.model, target, props.errorMessage, apiServer]);

    const output_features = useMemo(() => {
        const res = Array.from(new Set<string>(props.model.config.output_features.map((x: any) => x.name))).sort(
            (a: string, b: string) => alphabeticalCollator.compare(a, b),
        );

        // Set a default target for the model if we are managing the target internally
        if (res.length > 0 && !res.includes(target) && !props.setTarget) {
            setTarget(res[0]);
        }
        return res;
    }, [props.model]);

    if (statusDisabledMsg) {
        return <Message info content={statusDisabledMsg} />;
    } else if (specificDisabledMsg) {
        return <Message info content={specificDisabledMsg} />;
    } else if (props.model && !getConfigOutputFeatureNames(props.model.config).includes(target)) {
        return (
            <Message
                info
                content={
                    <span>
                        Model does not contain output feature: [{target}]
                        <Divider hidden />
                        Model contains output features: [{getConfigOutputFeatureNames(props.model.config).join(", ")}]
                    </span>
                }
            />
        );
    }

    if (errorMessage) {
        return <Message negative>{errorMessage}</Message>;
    }

    if (!links) {
        return <Loader active />;
    }

    const LinksComp = (
        <>
            {links.slice(0, props.displayMatrixOnly ? links.length / 2 : undefined).map((link) => (
                <img key={link.name} src={link.url} alt={link.name} style={{ maxWidth: "100%" }} />
            ))}
        </>
    );

    if (output_features.length > 0) {
        const body =
            links.length > 0 ? (
                LinksComp
            ) : (
                <>
                    <Divider hidden />
                    <Message negative>
                        No confusion matrix found for target {target}. This model was likely trained before this feature
                        was added.
                        <br />
                        <br />
                        To view, train a new version.
                    </Message>
                </>
            );

        return (
            <div style={{ width: "90%" }}>
                {!setTarget && output_features.length > 1 ? (
                    <Grid>
                        <Grid.Column computer={3}>
                            <TargetSelector outputFeatures={output_features} target={target} setTarget={setTarget} />
                        </Grid.Column>
                        <Grid.Column computer={13}>{body}</Grid.Column>
                    </Grid>
                ) : (
                    <div>{body}</div>
                )}
            </div>
        );
    }
    return LinksComp;
}

export default ConfusionMatrix;
