import { atom } from "recoil";

export const DATASETS_OFFSET_STATE = atom({
    key: "DATASETS_OFFSET_STATE",
    default: 0,
});

export const DATASETS_SEARCH_VAL_STATE = atom({
    key: "DATASETS_SEARCH_VAL_STATE",
    default: "",
});

export const DATASETS_TOTAL_STATE = atom({
    key: "DATASETS_TOTAL_STATE",
    default: 0,
});

export const DATASETS_SCOPE_USER_STATE = atom({
    key: "DATASETS_SCOPE_USER",
    default: false,
});

export const CONNECTIONS_OFFSET_STATE = atom({
    key: "CONNECTIONS_OFFSET_STATE",
    default: 0,
});

export const CONNECTIONS_SEARCH_VAL_STATE = atom({
    key: "CONNECTIONS_SEARCH_VAL_STATE",
    default: "",
});

export const CONNECTIONS_TOTAL_STATE = atom({
    key: "CONNECTIONS_TOTAL_STATE",
    default: 0,
});

export const CONNECTIONS_SCOPE_USER_STATE = atom({
    key: "CONNECTIONS_SCOPE_USER",
    default: false,
});

export const DATA_TAB_STATE = atom({
    key: "DATA_TAB_STATE",
    default: 1,
});
