import { Label, SemanticSIZES } from "semantic-ui-react";
import {
    SEMANTIC_BLUE,
    SEMANTIC_BROWN,
    SEMANTIC_GREEN,
    SEMANTIC_GREY,
    SEMANTIC_OLIVE,
    SEMANTIC_ORANGE,
    SEMANTIC_PINK,
    SEMANTIC_PURPLE,
    SEMANTIC_RED,
    SEMANTIC_TEAL,
    SEMANTIC_VIOLET,
    SEMANTIC_YELLOW,
} from "../utils/colors";

type ChipProps = {
    color: string;
    text: string | JSX.Element;
    textColor?: string;
    opacity?: number;
    width?: string;
    size?: SemanticSIZES;
    style?: React.CSSProperties;
};

const defaultProps = {
    color: SEMANTIC_BLUE,
    text: "",
    size: "medium" as const,
};

const Chip = (props: ChipProps = defaultProps) => {
    let rgbColor = undefined;
    const opacity = props.opacity || 1;
    switch (props.color) {
        case SEMANTIC_RED:
            rgbColor = `rgb(219, 40, 40, ${opacity})`;
            break;
        case SEMANTIC_ORANGE:
            rgbColor = `rgb(242, 113, 28, ${opacity})`;
            break;
        case SEMANTIC_YELLOW:
            rgbColor = `rgb(251, 189, 8, ${opacity})`;
            break;
        case SEMANTIC_OLIVE:
            rgbColor = `rgb(181, 204, 24, ${opacity})`;
            break;
        case SEMANTIC_GREEN:
            rgbColor = `rgb(33, 186, 69, ${opacity})`;
            break;
        case SEMANTIC_TEAL:
            rgbColor = `rgb(0, 181, 173, ${opacity})`;
            break;
        case SEMANTIC_BLUE:
            rgbColor = `rgb(33, 133, 208, ${opacity})`;
            break;
        case SEMANTIC_PURPLE:
            rgbColor = `rgb(100, 53, 201, ${opacity})`;
            break;
        case SEMANTIC_VIOLET:
            rgbColor = `rgb(163, 51, 200, ${opacity})`;
            break;
        case SEMANTIC_PINK:
            rgbColor = `rgb(224, 57, 151, ${opacity})`;
            break;
        case SEMANTIC_BROWN:
            rgbColor = `rgb(165, 103, 63, ${opacity})`;
            break;
        case SEMANTIC_GREY:
            rgbColor = `rgb(118, 118, 118, ${opacity})`;
            break;
    }
    return (
        <Label
            style={{
                backgroundColor: rgbColor,
                width: props.width ? props.width : "100%",
                textAlign: "center",
                ...props.style,
            }}
            size={props.size}
        >
            <span style={{ color: props.textColor ? props.textColor : props.color }}>{props.text}</span>
        </Label>
    );
};

export default Chip;
