import { Address } from "@stripe/stripe-js";

export enum MetronomeDashboardType {
    INVOICES = "invoices",
    USAGE = "usage",
    CREDITS = "credits",
}

export interface BillingInfo {
    name: string | null | undefined;
    phone?: string | null | undefined;
    address?: Address | null | undefined;
}

export interface TaxIDData {
    type: string;
    value: string;
}
