import type { JSONSchema7 } from "json-schema";
import _ from "lodash";
import React from "react";
import { Divider } from "semantic-ui-react";
import { CurrentUser, UserContext } from "../../../../types/user";
import { snakeToTitle } from "../../../../utils/config";
import { findAllOfSchema, findAllOfSchemaIndex } from "../../../../utils/jsonSchema";
import { Properties } from "../keywords";
import { titleStyling } from "../styling";
import { getLudwigPath } from "../utils";

export const ObjectField = (props: {
    schema: JSONSchema7;
    featureIndex?: number;
    config: any;
    setConfig: (parameterPath: string, parameterValue: any) => void;
    localState: any;
    setLocalState: (parameterPath: string, parameterValue: any) => void;
    invalidFields: any;
    // We use the level prop to keep track of how many layers deep this object
    // is in the schema. This allows us to decide when to render dividers and
    // (potentially) show different heading types for deeply nested objects.
    level: number;
    // Keep track of our path in the config object
    path: string;
    // Keep track of our path in the schema
    schemaPath: string;
    readOnly?: boolean;
    userContext?: UserContext | CurrentUser;
    skipDivider?: boolean;
    hideTitle?: boolean;
}) => {
    const { schema, config, featureIndex, level, schemaPath } = props;
    let elements: JSX.Element[] = [];

    if (!_.has(schema, "properties") || typeof schema.properties === "undefined") {
        return null;
    }

    elements = Properties(
        schema,
        config,
        props.setConfig,
        props.localState,
        props.setLocalState,
        props.invalidFields,
        level,
        props.path,
        `${schemaPath}`,
        featureIndex,
        props.readOnly,
        props.userContext,
    );

    // For now, we can assume that our All Ofs
    // are driven by a "type" field. We can expand
    // this to be more generic if the need arises.
    if (_.has(schema, "allOf")) {
        // Get the value for the `type`
        // TODO: Replace typePath with props.path?
        // @ts-expect-error
        const typePath = getLudwigPath(schema?.properties?.type?.parameter_metadata?.ludwig_path, featureIndex);
        // @ts-expect-error
        const typeValue = _.get(config, typePath, schema?.properties?.type?.default);
        // Filter schema by it
        const allOfSchema = findAllOfSchema(schema, typeValue);
        const allOfSchemaIndex = findAllOfSchemaIndex(schema, typeValue);
        // Add to elements
        if (_.has(allOfSchema, "properties")) {
            const allOfElements = Properties(
                allOfSchema,
                config,
                props.setConfig,
                props.localState,
                props.setLocalState,
                props.invalidFields,
                level,
                props.path,
                `${schemaPath}.allOf[${allOfSchemaIndex}].then`,
                featureIndex,
                props.readOnly,
                props.userContext,
            );

            if (Array.isArray(allOfElements)) {
                elements = [...elements, ...allOfElements];
            } else if (allOfElements !== null) {
                elements.push(allOfElements);
            }
        }
    }

    // If there's no title for this Object, then most likely
    // we don't need to render a divider or group the inputs.
    if (!schema.title) {
        return (
            <div key={JSON.stringify(Object.keys(schema.properties))}>
                {props.level > 1 && (
                    <Divider style={{ marginTop: 0, marginBottom: `${24 / 14}rem` }} hidden={props.skipDivider} />
                )}
                {elements}
            </div>
        );
    }

    return (
        <React.Fragment key={JSON.stringify(Object.keys(schema.properties))}>
            {props.level > 1 && (
                <Divider style={{ marginTop: 0, marginBottom: `${24 / 14}rem` }} hidden={props.skipDivider} />
            )}
            <fieldset>
                {props.hideTitle
                    ? null
                    : schema.title && (
                          <legend
                              style={{
                                  ...titleStyling,
                                  fontSize: `${18 / 14}rem`,
                                  marginBottom: `${24 / 14}rem`,
                              }}
                          >
                              {snakeToTitle(schema.title)}
                          </legend>
                      )}
                {elements}
            </fieldset>
        </React.Fragment>
    );
};
