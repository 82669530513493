import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { useEffect } from "react";
import { Button, Form, Popup, TextArea } from "semantic-ui-react";
import { templateVariableRegex } from "../utils/strings";
import { injectVariablesIntoPromptTemplate } from "./utils/data-utils";
import { Action, ActionType, TemplateVariable, useDispatch, usePromptState } from "./utils/reducer";

const placeholder = "Enter your prompt";

const TemplateField = (props: { name: string; pingDeploymentToScaleUp: () => void }) => {
    const dispatch = useDispatch();

    // ! NOTE: By design, react-tracked will prevent changes inside of a nested object from causing re-renders.
    // Practically, this means that a change in one instance of a TemplateField will not, by default, be
    // reflected in the proxy state held by another instance of a TemplateField and as a result a snowball
    // effect of state management problems will ensue as the user switches between fields. Take the following
    // example:
    //
    // TemplateField A, value proxy: {A: "", B: ""} | TemplateField B, value proxy: {A: "", B: ""}
    // > User types "dog" into field A...
    // >> In the onChange handler for A local edits are being reflected and B is still empty...
    // TemplateField A, value proxy: {A: "dog", B: ""} | TemplateField B, value proxy: {A: "", B: ""}
    // >> By the end of it, the proxy in B is still not showing the latest edits for A
    // > User begins to type "cat" into field B, so on "c"
    // TemplateField A, value proxy: {A: "", B: "c"} | TemplateField B, value proxy: {A: "", B: "c"}
    // >> In the onChange handler for B the local proxy shows A as empty so when it updates the reducer state
    //    it completely wipes out the value for A
    //
    // The solution is to directly clone the nested object from the top-level state (which is up-to-date) at
    // render time so that in the onChange handler every TemplateField's value is also up-to-date.
    const promptState = usePromptState();
    const promptTemplateVariables = { ...promptState.promptTemplateVariables };

    return (
        <div style={{ display: "block", padding: "0rem", marginBottom: "1rem" }}>
            <label style={{ display: "block", fontWeight: "bold" }}>{props.name}</label>
            <TextArea
                key={props.name}
                style={{ width: "100%", height: `${92 / 14}rem` }}
                placeholder={`Enter ${props.name}`}
                value={promptTemplateVariables[props.name].value}
                onChange={(__, data) => {
                    const newValue = data.value;

                    dispatch({
                        type: ActionType.UPDATE,
                        promptTemplateVariables: {
                            ...promptTemplateVariables,
                            [props.name]: {
                                ...promptTemplateVariables[props.name],
                                value: newValue,
                            } as TemplateVariable,
                        },
                    });
                    props.pingDeploymentToScaleUp();
                }}
            />
        </div>
    );
};

const RawPromptButton = () => {
    const dispatch = useDispatch();
    const promptState = usePromptState();
    const promptTemplate = promptState.promptTemplate;
    const promptTemplateVariables = { ...promptState.promptTemplateVariables };

    if (_.isEmpty(promptTemplateVariables)) {
        return (
            <Popup
                className="transition-scale"
                hoverable
                wide={"very"}
                position={"right center"}
                trigger={
                    <Button
                        style={{
                            display: "flex",
                            float: "right",
                            marginLeft: "auto",
                            padding: 0,
                            marginBottom: "1rem",
                        }}
                        onClick={() => {
                            matchAndDispatch(promptTemplate!, dispatch);
                            dispatch({ type: ActionType.UPDATE, prompt: "" });
                        }}
                    >
                        <FontAwesomeIcon icon="wand-magic-sparkles" />
                    </Button>
                }
                content={<span>Auto-detect fields in prompt template</span>}
            />
        );
    }

    return (
        <Popup
            className="transition-scale"
            hoverable
            wide={"very"}
            position={"right center"}
            trigger={
                <Button
                    style={{
                        display: "flex",
                        float: "right",
                        backgroundColor: "inherit",
                        marginLeft: "auto",
                        padding: 0,
                        marginBottom: "1rem",
                        position: "relative",
                        zIndex: 100,
                    }}
                    onClick={() => {
                        dispatch({
                            type: ActionType.UPDATE,
                            promptTemplateVariables: {},
                            prompt: injectVariablesIntoPromptTemplate(promptTemplateVariables, promptTemplate),
                        });
                    }}
                >
                    <FontAwesomeIcon icon="pen" />
                </Button>
            }
            content={<span>Edit prompt as a raw string</span>}
        />
    );
};

const matchAndDispatch = (promptTemplate: string, dispatch: React.Dispatch<Action>) => {
    const templateVariables = [...promptTemplate.matchAll(templateVariableRegex)];
    // TODO: Multiple variables with the same name?
    const ptv: { [key: string]: TemplateVariable } = {};
    for (const match of templateVariables) {
        const variableName = match[1];
        ptv[variableName] = {
            index: match.index!,
            skipLength: match[0].length,
            value: "",
        };
    }
    dispatch({ type: ActionType.UPDATE, promptTemplateVariables: ptv });
};

const Editor = (props: { submitPrompt: () => void; canSubmit: boolean; pingDeploymentToScaleUp: () => void }) => {
    // Reducer state:
    const dispatch = useDispatch();
    const { prompt, promptTemplate, promptTemplateVariables } = usePromptState();

    // If there is a prompt template, start tracking template variables:
    useEffect(() => {
        if (promptTemplate) {
            matchAndDispatch(promptTemplate, dispatch);
        }
    }, [dispatch, promptTemplate]);

    return (
        <>
            {promptTemplate && <RawPromptButton />}
            {_.isEmpty(promptTemplateVariables) ? (
                <TextArea
                    style={{
                        width: "100%",
                        height: "95%",
                        maxHeight: "100%",
                        border: "none",
                        outline: "none",
                        boxSizing: "border-box",
                        resize: "none",
                    }}
                    placeholder={placeholder}
                    value={prompt}
                    onChange={(__, data) => {
                        const newValue = data.value;
                        dispatch({
                            type: ActionType.UPDATE,
                            prompt: newValue?.toString(),
                        });
                        props.pingDeploymentToScaleUp();
                    }}
                />
            ) : (
                <Form>
                    {Object.entries(promptTemplateVariables).map(([variableName]) => {
                        return (
                            <TemplateField
                                key={variableName}
                                name={variableName}
                                pingDeploymentToScaleUp={props.pingDeploymentToScaleUp}
                            />
                        );
                    })}
                </Form>
            )}
        </>
    );
};

export default Editor;
