import { distance } from "fastest-levenshtein";
import { adapter, deployment, deploymentType } from "../../api_generated";
import { DeploymentUUIDLookup, DeploymentUUIDToSupportedAdapterReposMap } from "./dropdown-utils";

// ! WARNING: Order matters. The deployment is the "big spoon" and the adapter is the "little spoon" in this check:
export const deploymentModelSupportsAdapterModel = (deploymentModel: string, adapterModel: string): boolean => {
    deploymentModel = deploymentModel.toLowerCase();
    adapterModel = adapterModel.toLowerCase();

    // Strip leading prefixes (before slashes) in full base model paths:
    let deploymentModelRoot = deploymentModel.split("/").at(-1)!;
    let adapterModelRoot = adapterModel.split("/").at(-1)!;

    // Check if equivalent:
    if (deploymentModelRoot === adapterModelRoot) {
        return true;
    }

    // Strip trailing "-hf" substrings from root of both model names for a cleaner comparison:
    if (deploymentModelRoot.slice(-3) === "-hf") {
        deploymentModelRoot = deploymentModelRoot.substring(0, deploymentModelRoot.indexOf("-hf"));
    }
    if (adapterModelRoot.slice(-3) === "-hf") {
        adapterModelRoot = adapterModelRoot.substring(0, adapterModelRoot.indexOf("-hf"));
    }

    // Remove "-quantized" and "-dequantized" for similar reason:
    deploymentModelRoot = deploymentModelRoot.replace("-quantized", "");
    adapterModelRoot = adapterModelRoot.replace("-quantized", "");
    deploymentModelRoot = deploymentModelRoot.replace("-dequantized", "");
    adapterModelRoot = adapterModelRoot.replace("-dequantized", "");

    // Strip trailing "-it" substrings:
    if (deploymentModelRoot.slice(-3) === "-it") {
        deploymentModelRoot = deploymentModelRoot.substring(0, deploymentModelRoot.indexOf("-it"));
    }
    if (adapterModelRoot.slice(-3) === "-it") {
        adapterModelRoot = adapterModelRoot.substring(0, adapterModelRoot.indexOf("-it"));
    }

    // Replace periods with dashes:
    deploymentModelRoot = deploymentModelRoot.replace(".", "-");
    adapterModelRoot = adapterModelRoot.replace(".", "-");

    // Again, check if equivalent:
    if (deploymentModelRoot === adapterModelRoot) {
        return true;
    }

    // Hack targeted at mistral models:

    // Remove "-v0.1" suffix from adapter base model so that it will match against 0.1 and 0.2 deployments. (For now,
    // mistral models are backwards-compatible, i.e. a 0.2 deployment works with a 0.1-trained adapter but not
    // vice-versa.)
    if (adapterModelRoot.includes("-v0.1")) {
        adapterModelRoot = adapterModelRoot.substring(0, adapterModelRoot.indexOf("-v0.1"));
    }

    // Equivalent hacks for our dash-based shortname syntax, lol:
    // https://predibase.slack.com/archives/C050RPL8HNF/p1715711828633639?thread_ts=1715668025.356809&cid=C050RPL8HNF
    if (adapterModelRoot.includes("-v0-1")) {
        adapterModelRoot = adapterModelRoot.substring(0, adapterModelRoot.indexOf("-v0-1"));
    }
    if (adapterModelRoot.includes("-v0-2")) {
        const versionString = "-v0-2";
        const insertionPoint = adapterModelRoot.indexOf(versionString);
        adapterModelRoot = `${adapterModelRoot.substring(0, insertionPoint)}-v0.2${adapterModel.substring(
            insertionPoint + versionString.length,
        )}`;
    }

    return deploymentModelRoot.includes(adapterModelRoot);
};

// Strips everything except the version number:
const stripModelIdentifier = (modelIdentifier: string) => {
    // Strip leading prefixes (before slashes) in full base model paths:
    let modelRoot = modelIdentifier.split("/").at(-1)!;

    // Strip trailing "-hf" substrings from root of both model names for a cleaner comparison:
    if (modelRoot.includes("-hf")) {
        modelRoot = modelRoot.substring(0, modelRoot.indexOf("-hf"));
    }

    return modelRoot;
};

// ! WARNING: Order doesn't matter:
export const modelsAreLooselyCompatible = (model1: string, model2: string) => {
    return deploymentModelSupportsAdapterModel(model1, model2) || deploymentModelSupportsAdapterModel(model2, model1);
};

const matchAdapterToDeployment = (
    params: PromptViewQueryStringParams,
    deploymentUUIDToSupportedAdapterReposMap: DeploymentUUIDToSupportedAdapterReposMap,
    deployment: deployment,
) => {
    let fallbackMatch: { adapter: adapter; matchType: string } | null = null;

    const supportedAdapterReposMap = deploymentUUIDToSupportedAdapterReposMap[deployment.uuid];
    const supportedAdapters = supportedAdapterReposMap[params.adapterRepoName!];
    if (supportedAdapters) {
        for (const adapter of supportedAdapters) {
            const adapterPropertiesMatch =
                adapter.baseModel === params.modelIdentifier &&
                adapter.versionTag === params.versionTag &&
                adapter.repo === params.adapterRepoName;

            // First try to find an exact match, that is one where all adapter properties match *and* wherein
            // the deployment's base model is the exact one the adapter was trained on:
            if (deployment.model.name === adapter.baseModel && adapterPropertiesMatch) {
                return { adapter, matchType: "exact" };
            }

            // Otherwise, find a fallback:
            if (adapterPropertiesMatch) {
                fallbackMatch = { adapter, matchType: "fallback" };
            }
        }
    }
    return fallbackMatch;
};

export type PromptViewQueryStringParams = {
    versionTag?: number;
    adapterRepoName?: string;
    modelIdentifier?: string;
};

// TODO: This is one of the worst functions I've ever written so if any intrepid coder reading this in the future wants
// to refactor it, by all means...
export const matchQueryString = (
    params: PromptViewQueryStringParams,
    deploymentUUIDToSupportedAdapterReposMap: DeploymentUUIDToSupportedAdapterReposMap,
    deploymentUUIDLookup: DeploymentUUIDLookup,
): [deployment | null, adapter | null] => {
    // Assume that a query string always contains at least a model identifier:
    if (params.modelIdentifier === undefined) {
        return [null, null];
    }

    let deploymentMatch: deployment | null = null;
    let adapterMatch: adapter | null = null;

    // TODO: `canonicalName` is always empty right now, so we ONLY check the name and deployment.model.name:
    // First try to exactly match the model identifier to a deployment, either by UUID or (model) name:
    for (const [, deployment] of Object.entries(deploymentUUIDLookup)) {
        if (
            deployment.uuid === params.modelIdentifier ||
            deployment.name === params.modelIdentifier ||
            deployment.model.name === params.modelIdentifier
        ) {
            deploymentMatch = deployment;
            break;
        }
    }
    // If we succeeded, then it is trivial to match the adapter:
    if (deploymentMatch && params.adapterRepoName) {
        for (const [repoName, versions] of Object.entries(
            deploymentUUIDToSupportedAdapterReposMap[deploymentMatch.uuid],
        )) {
            if (repoName === params.adapterRepoName && versions.length > 0) {
                // And if a version tag was provided, pick that one:
                if (params.versionTag) {
                    for (const adapter of versions) {
                        if (adapter.versionTag === params.versionTag) {
                            adapterMatch = adapter;
                            break;
                        }
                    }
                }
                // Otherwise pick the latest version:
                if (adapterMatch === null) {
                    adapterMatch = versions
                        .sort((a1, a2) => {
                            if (a1.versionTag < a2.versionTag) {
                                return -1;
                            }
                            if (a1.versionTag > a2.versionTag) {
                                return 1;
                            }
                            return 0;
                        })
                        .at(-1)!;
                }
            }
        }
    }

    // Otherwise, perform a fuzzy search. Model identifier must be some sort of path (HF or shortname) and
    // there is no guarantee that an absolute match can be found.
    if (adapterMatch === null && params.adapterRepoName) {
        const potentialMatches: [deployment, adapter][] = [];

        // Try to match a deployment again, this time using fuzzier logic:
        for (const [, deployment] of Object.entries(deploymentUUIDLookup)) {
            if (deploymentModelSupportsAdapterModel(deployment.model.name, params.modelIdentifier)) {
                for (const [repoName, versions] of Object.entries(
                    deploymentUUIDToSupportedAdapterReposMap[deployment.uuid],
                )) {
                    if (repoName === params.adapterRepoName && versions.length > 0) {
                        // Tag the deployment match:
                        deploymentMatch = deployment;

                        // And if a version tag was provided, pick that one:
                        if (params.versionTag) {
                            for (const adapter of versions) {
                                if (adapter.versionTag === params.versionTag) {
                                    adapterMatch = adapter;
                                    break;
                                }
                            }
                        }
                        // Otherwise pick the latest version:
                        if (adapterMatch === null) {
                            adapterMatch = versions
                                .sort((a1, a2) => {
                                    if (a1.versionTag < a2.versionTag) {
                                        return -1;
                                    }
                                    if (a1.versionTag > a2.versionTag) {
                                        return 1;
                                    }
                                    return 0;
                                })
                                .at(-1)!;
                        }
                        potentialMatches.push([deploymentMatch, adapterMatch]);
                    }
                }
            }
        }

        // Consider the following scenario:
        //
        // User trains an adapter using some existing mistral-7b deployment (which could be hosted anywhere).
        // User then tries to prompt it via the UI and the model matching logic is invoked. Multiple deployments
        // can support this adapter: serverless mistral-7b (which it was trained with), serverless mistral-7b 0.2,
        // serverless mistral-7b-instruct, and any number of the user's dedicated mistral deployments.
        //
        // The only way to dynamically pick the "right" deployment is to compute a distance between a potential
        // choice and the query string's model identifier and then pick the deployment "closest" to the source.
        //
        // Because the full model path string for a deployment may include a lot of crap (like quantization info),
        // it ends up being more stable to compute this distance against the model's (short) name if its a
        // serverless deployment and against the (long) path if its dedicated (since we can make no assumptions
        // about what the user may name their deployment - they could name it "albatross" for all they care).
        if (deploymentMatch && adapterMatch && potentialMatches.length > 0) {
            const strippedModelIdentifier = stripModelIdentifier(params.modelIdentifier);

            for (const [dmatch, amatch] of potentialMatches) {
                const currentMatchName =
                    deploymentMatch?.type === deploymentType.SERVERLESS
                        ? stripModelIdentifier(deploymentMatch.name)
                        : stripModelIdentifier(deploymentMatch.model.name);
                const potentialMatchName =
                    dmatch?.type === deploymentType.SERVERLESS
                        ? stripModelIdentifier(dmatch.name)
                        : stripModelIdentifier(dmatch.model.name);

                const currentMatchDistance = distance(currentMatchName, strippedModelIdentifier);
                const potentialMatchDistance = distance(potentialMatchName, strippedModelIdentifier);

                if (potentialMatchDistance < currentMatchDistance) {
                    deploymentMatch = dmatch;
                    adapterMatch = amatch;
                }
            }
        }

        // If there still isn't a match...
        // TODO: I actually cannot conceive of a scenario in which this would happen, but just in case, this is the
        // total free-for-all search:
        if (adapterMatch === null && params.adapterRepoName) {
            // Iterate through all possible combinations of deployments and adapters and return a pair that works:
            for (const deploymentUUID in deploymentUUIDToSupportedAdapterReposMap) {
                const deployment = deploymentUUIDLookup[deploymentUUID];
                const match = matchAdapterToDeployment(params, deploymentUUIDToSupportedAdapterReposMap, deployment);
                if (match?.matchType === "exact") {
                    return [deploymentMatch, match.adapter];
                }
                if (match?.adapter) {
                    deploymentMatch = deployment;
                    adapterMatch = match.adapter;
                }
            }
        }
    }

    return [deploymentMatch, adapterMatch];
};
