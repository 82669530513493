import { User } from "@auth0/auth0-react";
import { Session } from "@ory/kratos-client";
import { atom } from "recoil";
import { CurrentUser, UserContext } from "../types/user";

export const SESSION_STATE = atom<Session | User | undefined>({
    key: "SESSION_STATE",
    default: undefined,
});

export const USER_STATE = atom<UserContext | CurrentUser | undefined>({
    key: "USER_STATE",
    default: undefined,
});
