import { Message } from "semantic-ui-react";

const DismissibleMessage = (props: {
    message: string;
    isVisible: boolean;
    setIsVisible: (visibility: boolean) => void;
}) => {
    const handleDismiss = () => {
        props.setIsVisible(false);
    };

    if (!props.isVisible) {
        return null;
    }

    return (
        <Message
            info
            style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", marginTop: 0 }}
        >
            <p style={{ marginBottom: 0 }}>{props.message}</p>
            <button
                onClick={handleDismiss}
                style={{ display: "inline-block", border: "none", cursor: "pointer", background: "none" }}
            >
                DISMISS
            </button>
        </Message>
    );
};

export default DismissibleMessage;
