import React, { useState } from "react";
import { Label } from "semantic-ui-react";

function ShowMoreLabelsFooter(props: {
    howManyMore: number;
    collapsed: boolean;
    setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    if (props.collapsed) {
        return (
            <button className={"button-reset"} style={{ fontSize: "0.9em" }} onClick={() => props.setCollapsed(false)}>
                {props.howManyMore} more
            </button>
        );
    }
    return (
        <button className={"button-reset"} style={{ fontSize: "0.9em" }} onClick={() => props.setCollapsed(true)}>
            Collapse
        </button>
    );
}

function ShowMoreLabels(props: { data: any[]; limit: number; RowComponent: (x: any) => React.ReactNode }) {
    const [collapsed, setCollapsed] = useState(true);

    return (
        <Label.Group>
            {props.data.slice(0, collapsed ? props.limit : undefined).map((x) => props.RowComponent(x))}
            {props.data.length > props.limit ? (
                <ShowMoreLabelsFooter
                    howManyMore={props.data.length - props.limit}
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                />
            ) : null}
        </Label.Group>
    );
}

export default ShowMoreLabels;
