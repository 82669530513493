import { useState } from "react";
import { Accordion, Divider, Header, Icon } from "semantic-ui-react";

import SamplerForm from "./SamplerForm";
import SchedulerForm from "./SchedulerForm";

const MetricRoot = "Model.Config.Hyperopt";
const InputGroupSampler = ".Sampler";

function SamplerSchedulerForm() {
    const [open, setOpen] = useState(false);

    return (
        <Accordion
            styled
            fluid
            style={{ boxShadow: "0 2px 4px 0 rgb(34 36 38 / 12%), 0 2px 10px 0 rgb(34 36 38 / 15%)", margin: "1rem 0" }}
            defaultActiveIndex={-1}
        >
            <Accordion.Title
                index={0}
                onClick={() => setOpen(!open)}
                style={{ display: "flex", justifyContent: "space-between", alignItems: "stretch", padding: "1.75rem" }}
            >
                <Header className="header" as="h3" style={{ marginBottom: 0 }}>
                    Sampler
                </Header>
                <Icon name={open ? "chevron up" : "chevron down"} />
            </Accordion.Title>
            <Accordion.Content active={open} style={{ padding: "1.75rem", paddingTop: 0 }}>
                <SamplerForm />
                <Divider />
                <SchedulerForm />
            </Accordion.Content>
        </Accordion>
    );
}

export default SamplerSchedulerForm;
