import yaml from "js-yaml";
import { Icon, List } from "semantic-ui-react";

const keyOrdering = [
    "name",
    "type",
    "encoder",
    "model_type",
    "model_name",
    "input_features",
    "output_features",
    "prompt",
    "preprocessing",
    "defaults",
    "adapter",
    "combiner",
    "generation",
    "trainer",
    "hyperopt",
];

/**
 * Return a number indicating if the item should be before or after the next element.
 * @param a The left key to compare
 * @param b The right key to compare
 */
export const sortConfigKeys = (a: string, b: string) => {
    let orderA = keyOrdering.indexOf(a);
    orderA = orderA === -1 ? 100 : orderA;
    let orderB = keyOrdering.indexOf(b);
    orderB = orderB === -1 ? 100 : orderB;

    return orderA - orderB;
};

export const toYAML = (config?: CreateModelConfig) => {
    if (config === undefined) {
        return "";
    }
    return yaml.dump(config, { sortKeys: sortConfigKeys });
};

// @TODO: MLX- Remove once Hyperopt forms use JSON Schema.
export function convertAtomicToNumber(s: any) {
    if (s === "") {
        return null;
    }
    if (typeof s !== "number" && typeof s !== "string") {
        return s;
    }
    const num = Number(s);
    if (isNaN(num)) {
        return s;
    }
    return num;
}

export function renderValidationErrorList(metaValidationErrors: any[], configValidationErrors: any) {
    return (
        <List>
            <List.Content>
                {metaValidationErrors.map((x: any) => (
                    <List.Item key={x}>
                        <Icon name="warning sign" />
                        {x}
                    </List.Item>
                ))}
                {Object.keys(configValidationErrors).map((x) => (
                    <List.Item key={x}>
                        <Icon name="warning sign" />
                        Invalid input: {x}: [{configValidationErrors[x]?.["errorMessages"]?.join(", ")}]
                    </List.Item>
                ))}
            </List.Content>
        </List>
    );
}

export function hasConfigurableFieldMode(field: CreateModelField) {
    return field.mode === "input" || field.mode === "output";
}

export function snakeToTitle(text: string) {
    if (text === "") {
        return "";
    }

    // Match space or underscore:
    let sentence = text.toLowerCase().split(/\s|_/);
    for (let i = 0; i < sentence.length; i++) {
        sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }
    return sentence.join(" ");
}
