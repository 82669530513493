import posthog from "posthog-js";

export const BLOCK_POSTHOG_AUTO_CAPTURE: string = "ph-no-capture";

interface MetricProperties {
    [key: string]: string | any;
}

class metrics {
    // ============ Actions ============ //
    static CLICK = ".click";
    static TOGGLE = ".toggle";

    static REMOVE = ".remove";
    static ADD = ".add";

    // Block auto-capture when we manually capture to avoid duplicate events
    static BLOCK_AUTO_CAPTURE: string = BLOCK_POSTHOG_AUTO_CAPTURE;

    static enableMetrics = () => {
        // Note - since we are compiling w/ webpack before serving, this will be injected from the env
        // variables at compile time.
        return process.env.REACT_APP_ENABLE_METRICS === "true";
    };

    static disableSessionRecording = () => {
        // Note - since we are compiling w/ webpack before serving, this will be injected from the env
        // variables at compile time.
        const disablePostHogSessionRecording = process.env.REACT_APP_DISABLE_SESSION_RECORDING;
        return disablePostHogSessionRecording !== "false";
    };

    /**
     * Associate metrics with a user. Posthog can track users across devices and browsers.
     * @param id User's UUID
     * @param email User's email
     * @param username User's username
     * @param subscription Tenant's subscription type
     * @param tenantShortcode Tenant's shortcode
     */
    static identify = (id: string, email: string, username: string, subscription: string, tenantShortcode: string) => {
        if (this.enableMetrics()) {
            posthog.identify(id, {
                email,
                username,
                subscription,
                tenantShortcode,
            });
        }
    };

    /**
     * Associate a user with a group. This is useful for associating a user with a company, organization, or team.
     * @param type The type of group to use. Posthog allows up to 5.
     * @param id The ID of the group. Should be unique.
     * @param properties Any properties to attach to the group. name is a special property which is used in the PostHog UI for the name of the Group.
     */
    static group = (type: string, id: string, properties: MetricProperties) => {
        if (this.enableMetrics()) {
            posthog.group(type, id, properties);
        }
    };

    static capture = (event: string, properties?: MetricProperties) => {
        if (this.enableMetrics()) {
            posthog.capture(event, properties);
        }
    };

    static captureRemove = (event: string, properties?: MetricProperties) =>
        this.capture(event + metrics.REMOVE, properties);

    static captureAdd = (event: string, properties?: MetricProperties) => this.capture(event + metrics.ADD, properties);

    static captureClick = (event: string, properties?: MetricProperties) =>
        this.capture(event + metrics.CLICK, properties);

    static captureToggle = (event: string, properties?: MetricProperties) =>
        this.capture(event + metrics.TOGGLE, properties);

    static captureError = (event: string, errorMsg: string, properties?: MetricProperties) => {
        const errorProperties = this.copyPropertiesWithKV(properties, "error", errorMsg);
        this.capture(event, errorProperties);
    };

    static copyPropertiesWithKV = (oldProperties: MetricProperties | undefined, key: string, value: string) => {
        const properties = oldProperties != null ? { ...oldProperties } : {};
        properties[key] = value;
        return properties;
    };

    static reset = () => {
        if (this.enableMetrics()) {
            posthog.reset();
        }
    };
}

export default metrics;
