import { upperFirst } from "lodash";
import { useContext } from "react";
import { Tab, Table } from "semantic-ui-react";
import { deployment, deploymentQuantization, deploymentType } from "../../../../api_generated";
import { SEMANTIC_GREY } from "../../../../utils/colors";
import { FeatureFlagsContext, getPosthogFeatureFlag, POSTHOG_FEATURE_FLAG } from "../../../../utils/feature-flags";

const DeploymentConfigTab = (props: { deployment?: deployment }) => {
    const { deployment } = props;
    const headerCellStyle = { border: 0, color: SEMANTIC_GREY, paddingRight: "40px" };
    const standardCellStyle = { border: 0 };

    // Context state:
    const { posthogFeatureFlags } = useContext(FeatureFlagsContext);
    const showReplicasCount = getPosthogFeatureFlag(POSTHOG_FEATURE_FLAG.ShowReplicasCount, posthogFeatureFlags);

    return (
        <Tab.Pane key={"deploymentConfig"} as={"div"}>
            <Table basic="very" collapsing compact>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell style={headerCellStyle}>Name</Table.Cell>
                        <Table.Cell style={standardCellStyle}>{deployment?.name}</Table.Cell>
                    </Table.Row>
                    {deployment?.type === deploymentType.DEDICATED && (
                        <Table.Row>
                            <Table.Cell style={headerCellStyle}>Base model</Table.Cell>
                            <Table.Cell style={standardCellStyle}>{deployment?.model?.name}</Table.Cell>
                        </Table.Row>
                    )}
                    {deployment?.type === deploymentType.DEDICATED && (
                        <Table.Row>
                            <Table.Cell style={headerCellStyle}>Accelerator</Table.Cell>
                            <Table.Cell style={standardCellStyle}>{deployment?.accelerator?.name}</Table.Cell>
                        </Table.Row>
                    )}
                    {deployment?.type === deploymentType.DEDICATED && deployment?.quantization && (
                        <Table.Row>
                            <Table.Cell style={headerCellStyle}>Quantization</Table.Cell>
                            <Table.Cell style={standardCellStyle}>
                                {deployment?.quantization === deploymentQuantization.NONE
                                    ? "-"
                                    : upperFirst(deployment.quantization)}
                            </Table.Cell>
                        </Table.Row>
                    )}
                    {deployment?.type === deploymentType.DEDICATED && showReplicasCount && (
                        <Table.Row>
                            <Table.Cell style={headerCellStyle}>Min replicas</Table.Cell>
                            <Table.Cell style={standardCellStyle}>{deployment?.config?.minReplicas}</Table.Cell>
                        </Table.Row>
                    )}
                    {deployment?.type === deploymentType.DEDICATED && showReplicasCount && (
                        <Table.Row>
                            <Table.Cell style={headerCellStyle}>Max Replicas</Table.Cell>
                            <Table.Cell style={standardCellStyle}>{deployment?.config?.maxReplicas}</Table.Cell>
                        </Table.Row>
                    )}
                    {deployment?.type === deploymentType.DEDICATED && (
                        <Table.Row>
                            <Table.Cell style={headerCellStyle}>Scale up threshold</Table.Cell>
                            <Table.Cell style={standardCellStyle}>
                                {deployment?.config?.scaleUpRequestThreshold}
                            </Table.Cell>
                        </Table.Row>
                    )}
                    <Table.Row>
                        <Table.Cell style={headerCellStyle}>Cooldown time (s)</Table.Cell>
                        <Table.Cell style={standardCellStyle}>{deployment?.config?.cooldownTime}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell style={headerCellStyle}>Max input length</Table.Cell>
                        <Table.Cell style={standardCellStyle}>{deployment?.model?.maxInputLength}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell style={headerCellStyle}>Max total tokens</Table.Cell>
                        <Table.Cell style={standardCellStyle}>{deployment?.model?.maxTotalTokens}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell style={headerCellStyle}>Max batch prefill tokens</Table.Cell>
                        <Table.Cell style={standardCellStyle}>{deployment?.model?.maxBatchPrefillTokens}</Table.Cell>
                    </Table.Row>
                    {deployment?.type === deploymentType.DEDICATED &&
                        Array.isArray(deployment?.config?.customArgs) &&
                        deployment?.config?.customArgs.length > 0 && (
                            <Table.Row>
                                <Table.Cell style={headerCellStyle}>Custom Args</Table.Cell>
                                <Table.Cell style={standardCellStyle}>
                                    {deployment?.config?.customArgs?.join(" ")}
                                </Table.Cell>
                            </Table.Row>
                        )}
                </Table.Body>
            </Table>
        </Tab.Pane>
    );
};

export default DeploymentConfigTab;
