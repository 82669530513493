import _ from "lodash";
import { useMemo, useState } from "react";
import { VegaLite } from "react-vega";
import { Checkbox, Dropdown, Form, Header, Segment } from "semantic-ui-react";
import "./ModelRepoPerformanceGraph.css";
import { ModelOptimizationStrategy, ModelRepoPerformanceGraphSpec } from "./ModelRepoPerformanceGraphSpec";
import {
    collectMetrics,
    collectModelPerformanceData,
    featureAndMetricNameLabel,
    getOptimizationBehavior,
} from "./util";

const ModelRepoPerformanceGraph = (props: { modelVersions: Model[]; defaultRepoMetric: string }) => {
    const { modelVersions, defaultRepoMetric } = props;

    const [metricName, setMetricName] = useState<string>(defaultRepoMetric);
    const [useCreatedTimeScale, setUseCreatedTimeScale] = useState<boolean>(true);
    const [maximizeMetric, setMaximizeMetric] = useState<boolean>(
        getOptimizationBehavior(defaultRepoMetric) === ModelOptimizationStrategy.MAXIMIZE,
    );

    const metricOptions = useMemo(() => collectMetrics(modelVersions), [modelVersions]);
    const chartData = useMemo(
        () => ({ table: collectModelPerformanceData(modelVersions, metricName) }),
        [modelVersions, metricName, maximizeMetric],
    );

    const minMetricValue = _.nth(chartData.table, -1)?.runningMin || 0;
    const maxMetricValue = _.nth(chartData.table, -1)?.runningMax || 0;

    if (!Array.isArray(chartData.table) || chartData.table.length < 2) {
        return null;
    }

    return (
        <div className={"model-performance-view"}>
            <Segment className={"model-performance-selections"}>
                <div className={"metric-selection"}>
                    <Header as={"h3"} size={"tiny"}>
                        Metric
                    </Header>
                    <Dropdown
                        fluid
                        selection
                        options={metricOptions}
                        onChange={(e, { value }) => {
                            const metric = value as string;
                            setMetricName(metric);
                            if (getOptimizationBehavior(metric) === ModelOptimizationStrategy.MINIMIZE) {
                                setMaximizeMetric(false);
                            } else {
                                setMaximizeMetric(true);
                            }
                        }}
                        value={metricName}
                    />
                </div>
                <div className={"axis-selection"}>
                    <Header as={"h3"} size={"tiny"}>
                        Horizontal Axis
                    </Header>
                    <Form>
                        <Form.Field key={"createdTime"}>
                            <Checkbox
                                radio
                                label={"Model Creation Time"}
                                checked={useCreatedTimeScale}
                                onChange={(e, data) => {
                                    setUseCreatedTimeScale(true);
                                }}
                            />
                        </Form.Field>
                        <Form.Field key={"modelID"}>
                            <Checkbox
                                radio
                                label={"Model Version Number"}
                                checked={!useCreatedTimeScale}
                                onChange={(e, data) => {
                                    setUseCreatedTimeScale(false);
                                }}
                            />
                        </Form.Field>
                    </Form>
                </div>
            </Segment>
            <Segment className={"model-performance-graph"}>
                <VegaLite
                    // @ts-ignore
                    spec={ModelRepoPerformanceGraphSpec(
                        useCreatedTimeScale,
                        featureAndMetricNameLabel(metricName),
                        maximizeMetric,
                        chartData.table.length,
                        [
                            minMetricValue - 0.1 * (maxMetricValue - minMetricValue),
                            maxMetricValue + 0.1 * (maxMetricValue - minMetricValue),
                        ],
                    )}
                    data={chartData}
                    actions={false}
                />
            </Segment>
        </div>
    );
};

export default ModelRepoPerformanceGraph;
