import { Dispatch, SetStateAction } from "react";
import { CheckboxProps, DropdownProps } from "semantic-ui-react";
import { Environment } from "../data";
import { EngineServiceType } from "../types/engineServiceType";

export const generateTemplateDropdownOptionsForServiceType = (
    templates: EngineTemplate[],
    engineServiceType: EngineServiceType,
) => {
    return templates
        .filter((template) => template.serviceType === engineServiceType)
        .map((template) => {
            return { text: template.name, value: template.id };
        });
};

export const generateEnvironmentDropdownOptions = (environments: Environment[]) => {
    return environments.map((environment) => {
        return { text: environment.name, value: environment.id };
    });
};

export const getTemplateForSpec = (schemaTemplates: EngineTemplate[], engineSpec: EngineSpec) => {
    let selectedTemplate = null;
    const templates = schemaTemplates.reduce((r: Map<number, EngineTemplate>, a: EngineTemplate) => {
        r.set(a.id, a);
        return r;
    }, new Map());
    const templateID = engineSpec?.templateID;
    if (templateID != null) {
        selectedTemplate = templates.get(templateID);
    }
    return selectedTemplate;
};

export const onChangeForGenericFormInput = (
    event: React.ChangeEvent<HTMLInputElement>,
    engineSpec: EngineSpec,
    setEngineSpec: Dispatch<SetStateAction<EngineSpec | undefined | null>>,
) => {
    const newEngine = {
        ...engineSpec,
        [event.target.name]: event.target.value,
    } as EngineSpec;
    setEngineSpec(newEngine);
};

export const onTemplateChange = (
    data: DropdownProps,
    engineSpec: EngineSpec,
    setEngineSpec: Dispatch<SetStateAction<EngineSpec | undefined | null>>,
) => {
    const newEngine = {
        ...engineSpec,
        templateID: data.value,
    } as EngineSpec;
    setEngineSpec(newEngine);
};

export const onEnvironmentChange = (
    data: DropdownProps,
    engineSpec: EngineSpec,
    setEngineSpec: Dispatch<SetStateAction<EngineSpec | undefined | null>>,
) => {
    const newEngine = {
        ...engineSpec,
        environmentID: data.value,
    } as EngineSpec;
    setEngineSpec(newEngine);
};

export const onAutoSuspendChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    engineSpec: EngineSpec,
    setEngineSpec: Dispatch<SetStateAction<EngineSpec | undefined | null>>,
) => {
    const value = event.target.value ? parseInt(event.target.value) : "";
    const newEngine = {
        ...engineSpec,
        autoSuspendSeconds: value,
    } as EngineSpec;
    setEngineSpec(newEngine);
};

export const onAutoSuspendBlur = (
    event: React.ChangeEvent<HTMLInputElement>,
    engineSpec: EngineSpec,
    setEngineSpec: Dispatch<SetStateAction<EngineSpec | undefined | null>>,
) => {
    const value = event.target.value ? parseInt(event.target.value) : 0;
    const newEngine = {
        ...engineSpec,
        autoSuspendSeconds: value,
    } as EngineSpec;
    setEngineSpec(newEngine);
};

export const onAutoResumeChange = (
    data: CheckboxProps,
    engineSpec: EngineSpec,
    setEngineSpec: Dispatch<SetStateAction<EngineSpec | undefined | null>>,
) => {
    const newEngine = {
        ...engineSpec,
        autoResume: data.checked,
    } as EngineSpec;
    setEngineSpec(newEngine);
};
