import { AxiosInstance, AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { Checkbox, Loader, Modal, Popup, Table } from "semantic-ui-react";
import { useAuth0TokenOptions } from "../../../data";
import { AvailableDatasetsResponse, LudwigDataset } from "../../../predibase_api/artifacts/v1/artifacts";
import { createV1APIServer, redirectIfSessionInvalid } from "../../../utils/api";
import { getErrorMessage } from "../../../utils/errors";
import ConnectorPanel from "../ConnectorPanel";
import { Connector } from "../util";

function PublicDatasetsConnector(props: React.PropsWithChildren<any> & ConnectorProps) {
    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    const [apiServer, setAPIServer] = useState<AxiosInstance | null>(null);
    useEffect(() => {
        const getAPIServer = async () => {
            const v1APIServer = await createV1APIServer(auth0TokenOptions);
            // NOTE: Whoever wrote the axios typings is a moron because the return type of axios.create is not
            // AxiosInstance -- it's a wrap function. And React will see that and treat it as a callback that
            // setState should directly call. FML.
            // See: [1], [2]:
            // [1]: https://github.com/axios/axios/issues/4365
            // [2]: https://stackoverflow.com/questions/64427195/calling-setstate-will-execute-the-function-value-instead-of-passing-it
            setAPIServer(() => v1APIServer);
        };
        getAPIServer();
    }, []);

    const [fullPublicDatasets, setFullPublicDatasets] = useState<Set<FullPublicDataset>>(new Set());
    const [selectedDataset, setSelectedDataset] = useState<string>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [loading, setLoading] = useState(true);

    interface FullPublicDataset extends PublicDataset {
        imported: boolean;
    }

    const reset = () => {
        setSelectedDataset(undefined);
    };

    const getUpdateRequest = () => {
        return {
            endpoint: "datasets/upload_ludwig_dataset",
            body: {
                selectedDataset,
            },
            headers: {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            },
        };
    };

    const getAddRequest = () => {
        return {
            endpoint: "datasets/upload_ludwig_dataset",
            body: {
                selectedDataset,
            },
            headers: {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            },
        };
    };

    const isDisabled = () => {
        return !selectedDataset;
    };

    useEffect(() => {
        getFullPublicDatasets().then((response) => {
            if (response) {
                setFullPublicDatasets(response);
                setLoading(false);
            }
        });
    }, []);

    const getFullPublicDatasets = async () => {
        try {
            // Get Connection ID
            const connectionID = await apiServer
                ?.get("connections", {
                    params: { searchKeys: ["name"], searchVals: ["public_datasets"] },
                })
                .then((response: AxiosResponse) => {
                    if (response.data.connections.length === 0) {
                        setErrorMessage("No connection found for public datasets");
                    }
                    return response.data.connections[0].id;
                });

            // Get list of already imported datasets
            const importedDatasets = await apiServer
                ?.get("datasets", {
                    params: { indexVals: [connectionID], indexKeys: ["connectionID"], limit: 999 },
                })
                .then((response: AxiosResponse) => {
                    if (response.data.datasets.length === 0) {
                        setErrorMessage("No datasets found for public datasets");
                    }
                    return new Set(response.data.datasets?.map((dataset: Dataset) => dataset.name));
                });

            // Get list of available ludwig datasets and create full dataset objects which contain whether imported or not
            return await apiServer
                ?.get(`/datasets/get_ludwig_datasets`, {
                    responseType: "json",
                })
                .then((response: AxiosResponse) => {
                    let fullPublicDatasets = new Set<FullPublicDataset>();
                    let resp = AvailableDatasetsResponse.fromJson(response.data);

                    resp.datasets.forEach((dataset: LudwigDataset) => {
                        fullPublicDatasets.add({
                            name: dataset.name,
                            tasks: Array.from(new Set(dataset.tasks)),
                            modalities: dataset.dataModalities,
                            imported: Boolean(importedDatasets?.has(dataset.name)),
                        });
                    });
                    return fullPublicDatasets;
                });
        } catch (error: AxiosResponse | any) {
            const errorMsg = getErrorMessage(error) ?? "";
            setErrorMessage(errorMsg);
            redirectIfSessionInvalid(errorMsg);
            return new Set<FullPublicDataset>();
        }
    };

    const getModalContent = () => {
        return (
            <>
                <p>
                    Import public datasets used in these{" "}
                    <a href="https://ludwig.ai/0.6/user_guide/datasets/dataset_zoo/">Ludwig Examples</a>
                </p>
                <Modal.Content scrolling>
                    <Table padded className="freeze-header">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell aria-label="Select Dataset"></Table.HeaderCell>
                                <Table.HeaderCell>Dataset</Table.HeaderCell>
                                <Table.HeaderCell>Type of task</Table.HeaderCell>
                                <Table.HeaderCell>Data Modalities</Table.HeaderCell>
                                <Table.HeaderCell aria-label="Already Imported"></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {loading ? (
                                <Loader />
                            ) : (
                                Array.from(fullPublicDatasets).map((obj) => {
                                    return (
                                        <Table.Row>
                                            <Table.Cell collapsing>
                                                <Checkbox
                                                    radio
                                                    checked={selectedDataset === obj.name}
                                                    disabled={obj.imported}
                                                    onChange={() => {
                                                        if (selectedDataset === obj.name) {
                                                            setSelectedDataset(undefined);
                                                        } else {
                                                            setSelectedDataset(obj.name);
                                                        }
                                                    }}
                                                />
                                            </Table.Cell>
                                            <Table.Cell style={{ opacity: obj.imported ? "50%" : undefined }}>
                                                {obj.imported ? (
                                                    <Popup
                                                        position={"top center"}
                                                        trigger={<span>{obj.name}</span>}
                                                        content={"Already imported"}
                                                    />
                                                ) : (
                                                    obj.name
                                                )}
                                            </Table.Cell>
                                            <Table.Cell style={{ opacity: obj.imported ? "50%" : undefined }}>
                                                {[...obj.tasks].join(", ")}
                                            </Table.Cell>
                                            <Table.Cell style={{ opacity: obj.imported ? "50%" : undefined }}>
                                                {[...obj.modalities].join(", ")}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {obj.imported ? (
                                                    <span style={{ opacity: "40%" }}>(Already imported)</span>
                                                ) : (
                                                    Array(16).fill("\xa0").join("")
                                                )}
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })
                            )}
                        </Table.Body>
                    </Table>
                </Modal.Content>
            </>
        );
    };

    return (
        <ConnectorPanel
            modalHeader="Import Public Dataset"
            header="Public Dataset"
            editModalHeader="Import Public Dataset"
            submitLabel="Import"
            name={Connector.PUBLIC_DATASETS}
            errorHeader={errorMessage}
            isDisabled={isDisabled}
            getModalContent={getModalContent}
            getAddRequest={getAddRequest}
            getUpdateRequest={getUpdateRequest}
            reset={reset}
            {...props}
        />
    );
}

export default PublicDatasetsConnector;
