import { useFeatureFlagPayload } from "posthog-js/react";

const PosthogBanner = () => {
    // TODO: Need a better, more restrictive type:
    const flagPayload = useFeatureFlagPayload("site-banner") as undefined | { [key: string]: any };
    return (
        <>
            {flagPayload && (
                <div
                    style={{
                        backgroundColor: flagPayload.color,
                        color: "#ffffff",
                        textAlign: "center",
                        padding: "10px",
                    }}
                >
                    <p
                        style={{
                            fontSize: flagPayload.fontSize,
                            fontWeight: "bold",
                        }}
                    >
                        {flagPayload.message}
                    </p>
                </div>
            )}
        </>
    );
};

export default PosthogBanner;
