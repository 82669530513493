import { useMutation } from "@tanstack/react-query";
import React, { useState } from "react";
import { Button, Divider, Form, Header, Message, Modal, Popup } from "semantic-ui-react";
import { SingularField } from "../../components/StackedForm";
import { useAuth0TokenOptions } from "../../data";
import metrics from "../../metrics/metrics";
import { getErrorMessage } from "../../utils/errors";
import { createDatasets } from "../data";
import { Connector } from "./util";

const safelyFormatDatasetURI = (str: string) => {
    if (str.endsWith(".parquet/")) {
        return str.slice(0, -1).trim();
    }
    return str.trim();
};

const nameFieldPlaceholder = (connectionType?: string) => {
    switch (connectionType) {
        case Connector.S3:
        case Connector.GCS:
        case Connector.ADLS:
            return "Titanic";
        default:
            return undefined;
    }
};

const urlFieldLabel = (connectionType?: string) => {
    switch (connectionType) {
        case Connector.S3:
            return "S3 URI";
        case Connector.GCS:
            return "GCS URI";
        case Connector.ADLS:
            return "Azure URI";
        default:
            return "URL";
    }
};

const urlFieldPlaceholder = (connectionType?: string) => {
    switch (connectionType) {
        case Connector.S3:
            return "s3://datasets/titanic.csv";
        case Connector.GCS:
            return "gs://your-bucket-name/titanic.csv";
        case Connector.ADLS:
            return "az://mydatasets/titanic.csv";
        default:
            return undefined;
    }
};

const urlFieldDescription = (connectionType?: string) => {
    switch (connectionType) {
        case Connector.S3:
            return "The URI from S3";
        case Connector.GCS:
            return "The gsutil URI in GCS";
        case Connector.ADLS:
            return "The URI for your dataset in ADLS";
        default:
            return undefined;
    }
};

function CreateFileDatasetsView(props: {
    connection: Connection;
    onSubmit: () => void;
    connectionSchema?: ConnectionSchema | null;
    getBreadcrumb?: () => React.ReactNode;
    onStepChange?: React.Dispatch<React.SetStateAction<number>>;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const [name, setName] = useState("");
    const [url, setUrl] = useState("");
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    const { mutate: mutateCreateDatasets } = useMutation({
        mutationFn: (
            datasetNames: {
                objectName: string;
                datasetName: any;
            }[],
        ) => createDatasets(props.connection.id, datasetNames, auth0TokenOptions),
        onSuccess: () => {
            setErrorMessage(null);
            props.onSubmit();
        },
        onError: (error) => {
            const errorMsg = getErrorMessage(error) ?? "";
            setErrorMessage(errorMsg);
        },
        onSettled: () => {
            setLoading(false);
        },
    });

    const createDatasetsFn = () => {
        setLoading(true);
        const createDatasetsNames = [
            {
                objectName: safelyFormatDatasetURI(url),
                datasetName: name,
            },
        ];
        mutateCreateDatasets(createDatasetsNames);
    };

    return (
        <>
            <Modal.Header>
                Create Datasets
                {props.getBreadcrumb ? (
                    <>
                        <br />
                        {props.getBreadcrumb()}
                    </>
                ) : null}
            </Modal.Header>
            <Modal.Content scrolling>
                <Header>Add files from connection [{props.connection?.name}] to import as datasets</Header>
                <Divider hidden />
                {errorMessage && (
                    <>
                        <Message negative>
                            <Message.Header>Error</Message.Header>
                            <p>{errorMessage}</p>
                        </Message>
                        <Divider hidden />
                    </>
                )}
                <Form>
                    <SingularField
                        name="name"
                        label="Dataset Name"
                        placeholder={nameFieldPlaceholder(props.connection.type)}
                        value={name}
                        setState={setName}
                        description={"Choose a name for your dataset in Predibase"}
                    />
                    <SingularField
                        name="url"
                        label={urlFieldLabel(props.connection.type)}
                        placeholder={urlFieldPlaceholder(props.connection.type)}
                        value={safelyFormatDatasetURI(url)}
                        setState={setUrl}
                        description={urlFieldDescription(props.connection.type)}
                    />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                {props.getBreadcrumb && (
                    <Button
                        className={metrics.BLOCK_AUTO_CAPTURE}
                        style={{ float: "left" }}
                        onClick={() => {
                            props.onStepChange?.((x) => x - 1);
                        }}
                    >
                        Back
                    </Button>
                )}
                <Popup
                    className={"transition-scale"}
                    position={"top center"}
                    trigger={
                        <Button content={"Skip"} onClick={(event, data) => props.setOpen(false)} loading={loading} />
                    }
                    content={"You can skip this step and create datasets from the connections table later"}
                />
                <Button
                    content={"Finish"}
                    labelPosition="right"
                    icon="checkmark"
                    onClick={(event, data) => createDatasetsFn()}
                    loading={loading}
                    disabled={!name || !url}
                    positive
                />
            </Modal.Actions>
        </>
    );
}

export default CreateFileDatasetsView;
