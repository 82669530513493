import { JSONSchema7, JSONSchema7Type } from "json-schema";
import RadioGroup from "../../../RadioGroup";
import { defaultTextStyling } from "../styling";

export const BooleanField = (props: {
    path: string;
    title?: string;
    error: boolean;
    value: string;
    description: string | undefined;
    defaultValue?: JSONSchema7Type;
    onChange: (checked?: boolean) => void;
    schema: JSONSchema7;
    readOnly?: boolean;
}) => {
    const { path, title, error, value, description, defaultValue, onChange } = props;
    const checkedValue = typeof value === "boolean" ? value : defaultValue;

    return (
        <div style={{ marginBottom: `${24 / 14}rem` }} key={`${path}_boolean`}>
            <RadioGroup
                path={path}
                error={error}
                label={title}
                description={description}
                // @ts-expect-error
                value={checkedValue}
                onChange={onChange}
                readOnly={props.readOnly}
            />
            <p style={{ ...defaultTextStyling, marginLeft: "28px", marginTop: 0 }}>
                {defaultValue ? "Default: True" : "Default: False"}
            </p>
        </div>
    );
};
