import React, { memo, useState } from "react";
import { Menu, Segment } from "semantic-ui-react";
import { MenuItemProps } from "semantic-ui-react/dist/commonjs/collections/Menu/MenuItem";
import SummaryChart from "./SummaryChart";

// @ts-ignore
import { AdditiveForceArrayVisualizer } from "shapjs";
import { noFunctionCompare } from "../../../utils/reactUtils";

function renderFeatureList(features: ShapFeature[]) {
    return features.map((feature) => feature.name).join(", ");
}

const ExplanationMetricRoot = "Query.QueryResults.Explanation";

// (Arnav): Temporarily disable since force plots are not working properly
// Flag to enable or disable force plots
const ForcePlotEnabled = false;

const SUMMARY = "summary";
const FORCE = "force";

function ExplanationArray(props: {
    metadata: ShapMetadata;
    data: (any | ShapResult)[];
    colIndex: number;
    activeIndex: number;
    name: React.ReactNode;
    insideModal?: boolean;
    target: string;
}) {
    const [activeItem, setActiveItem] = useState(SUMMARY);

    const getVisualizer = (activeItem: string, baseProperties: any) => {
        if (activeItem === SUMMARY) {
            let summaryProps = {
                ...baseProperties,
                useAbs: true,
                ...{ plot_cmap: ["rgb(255, 13, 87)", "rgb(30, 136, 229)"] },
            };
            return <SummaryChart {...summaryProps} />;
        } else if (activeItem === FORCE && ForcePlotEnabled) {
            let forceProps = {
                ...baseProperties,
                ...{ plot_cmap: ["rgb(30, 136, 229)", "rgb(255, 13, 87)"] },
            };
            const copiedObject = JSON.parse(JSON.stringify(forceProps));
            return <AdditiveForceArrayVisualizer {...copiedObject} />;
        }
    };

    const getChart = (properties: any) => {
        if (ForcePlotEnabled) {
            return (
                <div style={{ width: "100%" }}>
                    <Segment style={{ overflowX: "auto" }}>
                        {menu(ForcePlotEnabled)}
                        {getVisualizer(activeItem, properties)}
                    </Segment>
                </div>
            );
        }
        return <div style={{ width: "100%" }}>{getVisualizer(activeItem, properties)}</div>;
    };

    const handleItemClick = (e: React.MouseEvent<HTMLAnchorElement>, { name }: MenuItemProps) => {
        setActiveItem(name as string);
    };

    const metadata = props.metadata;

    const columnIndex = props.colIndex;
    const explanations = props.data?.map((row) => {
        return row[columnIndex].data[props.activeIndex];
    });

    const baseProperties = {
        explanations,
        featureNames: metadata.featureNames,
        baseValue: metadata.baseValues[props.activeIndex],
        link: "identity",
        outNames: ["predicted value"],
        width: props.insideModal ? 650 : 900,
        barHeight: props.insideModal ? 35 : 55,
    };

    const menu = (ForcePlotEnabled: Boolean) => (
        <Menu secondary pointing>
            <Menu.Item key={SUMMARY} name={SUMMARY} active={activeItem === SUMMARY} onClick={handleItemClick} />
            {ForcePlotEnabled && (
                <Menu.Item key={FORCE} name={FORCE} active={activeItem === FORCE} onClick={handleItemClick} />
            )}
        </Menu>
    );

    return getChart(baseProperties);
}

export default memo(ExplanationArray, noFunctionCompare);
