import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { useAuth0TokenOptions } from "../data";
import { DEPLOYMENTS_CONSTANT, MODEL_REPOS_CONSTANT } from "../query";
import {
    getClassicDeploymentByName,
    getHyperoptTrials,
    getModelRepo,
    getRecommendedNextExperiments,
    getVizExplanations,
} from "./data";

// CONSTANTS:
export const MODELS_CONSTANT = "models";

export const GET_HYPEROPT_TRIALS_QUERY_KEY = (modelID: number) => ["trialParameterConfigs", modelID];
export const useHyperoptTrialsQuery = (modelID: number, options?: Partial<UseQueryOptions<Trial[]>>) => {
    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<Trial[]>({
        queryKey: GET_HYPEROPT_TRIALS_QUERY_KEY(modelID),
        queryFn: () => getHyperoptTrials(modelID, auth0TokenOptions),
        ...options,
    });
};

export const GET_CLASSIC_DEPLOYMENT_QUERY_KEY = (deploymentName: string) => [
    DEPLOYMENTS_CONSTANT,
    "classic",
    deploymentName,
];
export const useClassicDeploymentByNameQuery = (
    deploymentName: string,
    options?: Partial<UseQueryOptions<ModelDeployment>>,
) => {
    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<ModelDeployment>({
        queryKey: GET_CLASSIC_DEPLOYMENT_QUERY_KEY(deploymentName),
        queryFn: () => getClassicDeploymentByName(deploymentName, auth0TokenOptions),
        ...options,
    });
};

export const GET_RECOMMENDED_NEXT_EXPERIMENTS_QUERY_KEY = (model?: Model) => ["recommendedNextExperiments", model];
export const useRecommendedNextExperimentsQuery = (model?: Model, options?: Partial<UseQueryOptions<any>>) => {
    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<any>({
        queryKey: GET_RECOMMENDED_NEXT_EXPERIMENTS_QUERY_KEY(model),
        queryFn: () => getRecommendedNextExperiments(model, auth0TokenOptions),
        ...options,
    });
};

export const GET_MODEL_REPO_QUERY_KEY = (repoID: number) => [MODEL_REPOS_CONSTANT, repoID];
export const useModelRepoQuery = (repoID: number, options?: Partial<UseQueryOptions<ModelRepo>>) => {
    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<ModelRepo>({
        queryKey: ["modelRepo", repoID],
        queryFn: () => getModelRepo(repoID, false, auth0TokenOptions),
        ...options,
    });
};

export const GET_MODEL_REPO_WITH_VERSIONS_QUERY_KEY = (repoID: number) => [
    MODEL_REPOS_CONSTANT,
    repoID,
    "withVersions",
];
export const useModelRepoWithVersionsQuery = (
    repoID: number,
    options?: Partial<UseQueryOptions<GetModelRepoWithVersionsResponse>>,
) => {
    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery<GetModelRepoWithVersionsResponse>({
        queryKey: GET_MODEL_REPO_WITH_VERSIONS_QUERY_KEY(repoID),
        queryFn: () => getModelRepo(repoID, true, auth0TokenOptions),
        ...options,
    });
};

export const GET_VIZ_EXPLANATIONS_QUERY_KEY = (modelID: number, outputFeature: string) => [
    MODELS_CONSTANT,
    "viz",
    "explanations",
    modelID,
    outputFeature,
];
export const useVizExplanationsQuery = (
    modelID: number,
    outputFeature: string | undefined,
    options?: Partial<UseQueryOptions<any>>,
) => {
    // Auth0 state:
    const auth0TokenOptions = useAuth0TokenOptions();

    return useQuery({
        queryKey: GET_VIZ_EXPLANATIONS_QUERY_KEY(modelID, outputFeature ?? ""),
        queryFn: () => getVizExplanations(modelID, outputFeature, auth0TokenOptions),
        ...options,
    });
};
