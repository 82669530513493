import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Divider, Header, Icon, Input, Pagination, PaginationProps, Table } from "semantic-ui-react";
import { adapterVersionListItem, repo } from "../../../api_generated";
import ConnectionImagePopup from "../../../components/ConnectionImagePopup";
import LiveTimeAgo from "../../../components/LiveTimeAgo";
import ShowMoreTable from "../../../components/ShowMoreTable";
import metrics from "../../../metrics/metrics";
import { SEMANTIC_GREY } from "../../../utils/colors";
import { cssTruncate } from "../../../utils/overflow";
import { getActivePage, getTotalPages } from "../../../utils/pagination";
import { filterListOnSelectedAttributeWithSearchText } from "../../../utils/search";
import AdapterStatusChip from "../../misc/AdapterStatusChip";
import JobStatusChip from "../../misc/JobStatusChip";
import RepoContributors from "../../misc/RepoContributors";
import { getlatestAdapterInRepo } from "../../misc/utils";

const ITEMS_PER_PAGE = 10;
interface dataset {
    id: number;
    name: string;
    connection: {
        type: string;
    };
}

const rowComponentFunc = (dataset: dataset) => {
    return (
        <Table.Row key={dataset.id}>
            <Table.Cell collapsing style={{ paddingRight: 0, borderTop: "none" }}>
                <ConnectionImagePopup
                    connectionType={dataset.connection.type}
                    style={{ marginRight: 0, width: "1.5em", height: "1.5em" }}
                />
            </Table.Cell>
            <Table.Cell style={{ borderLeft: "none", paddingLeft: 5, borderTop: "none" }}>
                <Link
                    className={metrics.BLOCK_AUTO_CAPTURE + " black-link"}
                    style={{ ...cssTruncate, verticalAlign: "middle", maxWidth: "300px" }}
                    to={`/data/datasets/${dataset.id}`}
                >
                    {dataset.name}
                </Link>
            </Table.Cell>
        </Table.Row>
    );
};

const DatasetsForRepo = (props: { repo: repo }) => {
    // Parent props:
    const { repo } = props;

    // TODO: Remove null check when we fix the backend:
    if (repo.versions === null) {
        return null;
    }

    const datasetsMap = new Map<number, any>();
    for (const adapterVersion of repo.versions) {
        if (adapterVersion.datasetId === undefined || adapterVersion.datasetId === null) {
            continue;
        }

        datasetsMap.set(adapterVersion.datasetId, {
            id: adapterVersion.datasetId,
            name: adapterVersion.datasetName,
            connection: {
                type: adapterVersion.datasetType,
            },
        });
    }
    const repoDatasetsSorted = Array.from(datasetsMap.values()).sort((a, b) =>
        a.name.localeCompare(b.name, undefined, { sensitivity: "base" }),
    );

    return (
        <ShowMoreTable
            data={repoDatasetsSorted}
            limit={2}
            cols={2}
            // TODO: Not actually a component:
            RowComponent={rowComponentFunc}
            style={{ paddingTop: "16px", paddingBottom: "16px" }}
        />
    );
};

const LatestStatusAndVersion = (props: {
    repoUUID: string;
    latestAdapterVersion: adapterVersionListItem | undefined;
}) => {
    // Parent state:
    const { repoUUID, latestAdapterVersion } = props;

    if (latestAdapterVersion === undefined) {
        return null;
    }

    // TODO: Why is this a table?
    return (
        <Table basic={"very"} compact className={"no-hover default-padding"}>
            <Table.Body>
                <Table.Row>
                    <Table.Cell collapsing>
                        <AdapterStatusChip adapterPath={latestAdapterVersion.adapterPath} />
                    </Table.Cell>
                    <Table.Cell style={{ paddingLeft: 0 }}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            {/* TODO: Another dup;licate of TruncatedText? */}
                            <span style={{ ...cssTruncate, display: "block", maxWidth: "200px" }}>
                                <Link
                                    to={`/adapters/repo/${repoUUID}/version/${latestAdapterVersion.tag}`}
                                    className={"blue-link"}
                                >
                                    #{latestAdapterVersion.tag}
                                    {latestAdapterVersion.description ? (
                                        <>
                                            &ensp;
                                            <span className={"blue-link"} style={{ color: SEMANTIC_GREY }}>
                                                {latestAdapterVersion.description}
                                            </span>
                                        </>
                                    ) : null}
                                </Link>
                            </span>
                            <span style={{ color: SEMANTIC_GREY, fontSize: "0.9em" }}>
                                Training Status: <JobStatusChip status={latestAdapterVersion.status} />
                            </span>
                        </div>
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    );
};

// TODO: Can possibly harden undefined:
const AdapterReposTable = (props: {
    repos?: repo[];
    paginationOffset?: number;
    setPaginationOffset: React.Dispatch<React.SetStateAction<number>>;
    paginationTotal?: number;
    searchState: string;
    setSearchState: React.Dispatch<React.SetStateAction<string>>;
}) => {
    // Parent state:
    const { repos, paginationOffset, setPaginationOffset, paginationTotal, searchState, setSearchState } = props;

    // Computed state:
    const filteredRepos = useMemo(() => {
        if (repos) {
            return filterListOnSelectedAttributeWithSearchText<repo>(repos, "name", searchState);
        }
        return null;
    }, [repos, searchState]);

    return (
        <>
            <Table selectable={Boolean(filteredRepos?.length)} className={"padding-sixteen"}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>
                            <div style={{ display: "flex" }}>
                                <span style={{ marginTop: "0.5rem", marginRight: "1rem" }}>Adapter Repo Name </span>
                                <Input
                                    autoFocus
                                    size={"small"}
                                    icon={"search"}
                                    onChange={(event, data) => {
                                        setSearchState(data.value);
                                        setPaginationOffset(0);
                                    }}
                                />
                            </div>
                        </Table.HeaderCell>
                        <Table.HeaderCell>Dataset(s)</Table.HeaderCell>
                        <Table.HeaderCell>Contributor(s)</Table.HeaderCell>
                        <Table.HeaderCell>Latest version</Table.HeaderCell>
                        <Table.HeaderCell>Last Modified</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {filteredRepos?.length && filteredRepos.length > 0 ? (
                        filteredRepos.map((repo) => {
                            const latestAdapterVersion = getlatestAdapterInRepo(repo);

                            return (
                                <Table.Row key={repo.uuid}>
                                    <Table.Cell style={{ maxWidth: "30vw", wordBreak: "break-all" }}>
                                        <Header
                                            as={"h2"}
                                            size={"small"}
                                            className={"blue-link"}
                                            style={{
                                                margin: 0,
                                                whiteSpace: "nowrap",
                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                            }}
                                        >
                                            <Link
                                                className={`${metrics.BLOCK_AUTO_CAPTURE} blue-link`}
                                                to={"/adapters/repo/" + repo.uuid}
                                            >
                                                {repo.name}
                                            </Link>
                                        </Header>
                                        <span style={{ color: SEMANTIC_GREY }}>{repo.description}</span>
                                    </Table.Cell>
                                    <Table.Cell style={{ paddingTop: 5, paddingBottom: 5 }}>
                                        <DatasetsForRepo repo={repo} />
                                    </Table.Cell>
                                    <Table.Cell>
                                        <RepoContributors repo={repo} />
                                    </Table.Cell>
                                    <Table.Cell>
                                        <LatestStatusAndVersion
                                            repoUUID={repo.uuid}
                                            latestAdapterVersion={latestAdapterVersion}
                                        />
                                    </Table.Cell>
                                    <Table.Cell style={{ whiteSpace: "nowrap" }}>
                                        <>
                                            {/* TODO: Technically this should be the last "updated" timestamp: */}
                                            <LiveTimeAgo fromDate={new Date(repo.lastActivity)} />
                                            <br />
                                        </>
                                        {/* TODO: If there is no latest "ready" version, should we have a fallback updated time? */}
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })
                    ) : (
                        <Table.Row>
                            <Table.Cell colSpan={7} textAlign={"center"} verticalAlign={"middle"}>
                                <Divider hidden />
                                <img src={"/model/emptyRepos.svg"} alt="" />
                                <Header as="h2" size={"medium"} style={{ marginBottom: "0.5rem" }}>
                                    Looks like you don't have any adapters yet!
                                </Header>
                                <span style={{ fontSize: "0.9em" }}> to begin training your first adapter.</span>
                                <Divider hidden />
                            </Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>
            <div style={{ justifyContent: "center", display: "flex" }}>
                <Pagination
                    activePage={getActivePage(paginationOffset!, ITEMS_PER_PAGE)}
                    totalPages={getTotalPages(paginationTotal!, ITEMS_PER_PAGE)}
                    onPageChange={(e: React.MouseEvent<HTMLAnchorElement>, { activePage }: PaginationProps) => {
                        const offset = ((activePage as number) - 1) * ITEMS_PER_PAGE;
                        setPaginationOffset(offset);
                    }}
                    firstItem={{ content: <Icon name="angle double left" />, icon: true }}
                    lastItem={{ content: <Icon name="angle double right" />, icon: true }}
                    prevItem={{ content: <Icon name="angle left" />, icon: true }}
                    nextItem={{ content: <Icon name="angle right" />, icon: true }}
                />
            </div>
        </>
    );
};

export default AdapterReposTable;
