import { useEffect, useState } from "react";
import { EnvironmentStatus } from "../auth/EnvironmentViewRouter";
import { useEnvironmentsQuery } from "../query";

export const useGetEnvironments = (loggedInToPredibase: boolean) => {
    // Local state:
    const [envReady, setEnvReady] = useState(true);
    const [envShortCode, setEnvShortCode] = useState("");
    const [envSetup, setEnvSetup] = useState(true);

    // Environments state:
    const { data: environments } = useEnvironmentsQuery({
        enabled: loggedInToPredibase,
    });
    useEffect(() => {
        if (environments) {
            let ready = true;
            let setup = true;

            // For now, we only support one environment per tenant. Grab the last one.
            const latestEnv = environments[environments.length - 1];
            // Set the status vars
            switch (latestEnv.status) {
                // Show the setup page
                case EnvironmentStatus.CREATED:
                    ready = false;
                    setup = false;
                    break;
                // Show the App
                case EnvironmentStatus.READY:
                case EnvironmentStatus.UPDATING:
                case EnvironmentStatus.ERRORED:
                    ready = true;
                    setup = true;
                    break;
                // Show the status page
                default:
                    ready = false;
                    setup = true;
            }
            setEnvShortCode(latestEnv.shortCode);
            setEnvReady(ready);
            setEnvSetup(setup);
        }
    }, [environments]);

    return {
        envReady,
        envShortCode,
        envSetup,
    };
};
