import { Checkbox, Icon, Popup } from "semantic-ui-react";

import { SubscriptionLink } from "../../../components/GlobalHeader/SubscriptionButtons";
import metrics from "../../../metrics/metrics";
import {
    GBMInputFeatures,
    GBMOutputFeatures,
    LLMInputFeatures,
    LLMOutputFeatures,
} from "../../../types/model/featureTypes";
import { ModelTypes } from "../../../types/model/modelTypes";
import { capitalize } from "../../../utils/strings";
import styles from "./FieldsTable.module.css";
import { computeRestrictedFeature, isFieldDisabled } from "./util";

const CheckBoxIcon = (props: { field: CreateModelField; isDisabled: boolean; isComputeLimited?: boolean }) => {
    const { field, isDisabled, isComputeLimited } = props;

    if (isComputeLimited && computeRestrictedFeature(field.config.type)) {
        return <Icon name="lock" className={styles.Icon} />;
    }
    if (isDisabled) {
        return <Icon name="dont" className={styles.Icon} />;
    }
    return null;
};

const listSupportedFieldTypes = (features: string[]) => {
    if (!Array.isArray(features)) {
        return "";
    }

    if (features.length === 1) {
        return features[0];
    }

    return `${features.slice(0, -1).join(", ")} and ${features.at(-1)}`;
};

const PopupMessage = (props: { field: CreateModelField; modelType: ModelTypes; isComputeLimited?: boolean }) => {
    const { field, modelType, isComputeLimited } = props;

    // Handle Free Trial message
    if (isComputeLimited) {
        return (
            <p>
                {capitalize(`${field.config.type} features are locked during the free trial period. `)}
                <SubscriptionLink />
            </p>
        );
    }

    // Model Type specific messages
    const inputField = field.mode === "input";
    const fieldTypeLabel = inputField ? "input" : "output";
    switch (modelType) {
        case ModelTypes.DECISION_TREE:
            if (field.mode === "split") {
                break;
            }

            return (
                <p>
                    Tree models only support{" "}
                    {listSupportedFieldTypes(inputField ? GBMInputFeatures : GBMOutputFeatures)} {fieldTypeLabel}{" "}
                    features
                </p>
            );
        case ModelTypes.NEURAL_NETWORK:
            if (field.config.type === "category_distribution") {
                return <p>Neural Network models do not support category distribution as an input feature</p>;
            }
            break;
        case ModelTypes.LARGE_LANGUAGE_MODEL:
            if (field.mode === "split") {
                break;
            }

            return (
                <p>
                    LLMs models only support{" "}
                    {listSupportedFieldTypes(inputField ? LLMInputFeatures : LLMOutputFeatures)} {fieldTypeLabel}{" "}
                    features
                </p>
            );
    }

    return null;
};

const FieldCheckbox = (props: {
    field: CreateModelField;
    fieldIndex: number;
    toggleEnabled: (field: CreateModelField, fieldIndex: number, checked: boolean) => void;
    modelType: ModelTypes;
    isComputeLimited?: boolean;
    isFeatureLimited?: boolean;
}) => {
    const { field, fieldIndex, isComputeLimited, modelType, toggleEnabled } = props;

    const isDisabled = isFieldDisabled(modelType, field?.config?.type, field.mode);

    return (
        <div className={styles.Checkbox}>
            {isFieldDisabled(modelType, field?.config?.type, field.mode) ||
            (isComputeLimited && computeRestrictedFeature(field?.config?.type)) ? (
                <Popup
                    content={<PopupMessage field={field} modelType={modelType} isComputeLimited={isComputeLimited} />}
                    position="right center"
                    flowing={true}
                    hoverable={true}
                    trigger={
                        <Checkbox
                            className={metrics.BLOCK_AUTO_CAPTURE}
                            toggle
                            name={`${field.name}-toggle`}
                            checked={false}
                        />
                    }
                />
            ) : (
                <Checkbox
                    className={metrics.BLOCK_AUTO_CAPTURE}
                    toggle
                    name={`${field.name}-toggle`}
                    onClick={(event, data) => {
                        toggleEnabled(field, fieldIndex, data.checked as boolean);
                        metrics.captureToggle("Model.Config.Features.Active", {
                            field: field,
                            fieldIndex: fieldIndex,
                            value: data.checked,
                        });
                    }}
                    checked={!field.excluded}
                />
            )}
            <CheckBoxIcon field={field} isDisabled={isDisabled} isComputeLimited={isComputeLimited} />
        </div>
    );
};

export default FieldCheckbox;
