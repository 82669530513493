import { Form } from "semantic-ui-react";

import metrics from "../../../metrics/metrics";
import { SEMANTIC_GREY_DISABLED } from "../../../utils/colors";
import { convertAtomicToNumber } from "../../../utils/config";
import { useConfigState, useDispatch } from "../store";

const MetricRoot = "Model.Config.Hyperopt";
const InputGroupSampler = ".Sampler";

const rayDocsLink = (
    <a
        href={
            "https://docs.ray.io/en/latest/tune/api_docs/suggestion.html#hyperopt-tune-suggest-hyperopt-hyperoptsearch"
        }
        target="_blank"
        rel="noreferrer"
    >
        {"Ray Tune's HyperOpt"}
    </a>
);
const hyperoptLink = (
    <a href={"https://github.com/hyperopt/hyperopt"} target="_blank" rel="noreferrer">
        {"HyperOpt"}
    </a>
);

function SamplerForm() {
    const dispatch = useDispatch();
    const { config, invalidFields } = useConfigState();
    const hyperoptConfig = config?.hyperopt;

    return (
        <>
            <p style={{ marginBottom: "28px", color: SEMANTIC_GREY_DISABLED }}>
                Modify basic settings for search sampling, such as how many samples to take. Predibase uses{" "}
                {rayDocsLink} search algorithm, which is a wrapper around the open-source optimization library,{" "}
                {hyperoptLink}, for more efficient hyperparameter selection.
            </p>

            <Form>
                <Form.Input
                    className={metrics.BLOCK_AUTO_CAPTURE}
                    error={invalidFields["hyperopt/search_alg/random_state_seed"]}
                    name="randomStateSeed"
                    label="Random State Seed"
                    style={{ width: "197px" }}
                    placeholder="Random State Seed"
                    value={hyperoptConfig?.search_alg?.random_state_seed}
                    onClick={() => metrics.captureClick(MetricRoot + InputGroupSampler + ".RandomStateSeed")}
                    onChange={(event) => {
                        const newValue = event.target.value;
                        const convertedValue = convertAtomicToNumber(newValue);

                        dispatch({
                            type: "UPDATE_CONFIG_PROPERTY",
                            field: "hyperopt.search_alg.random_state_seed",
                            value: convertedValue,
                        });
                    }}
                />
            </Form>
        </>
    );
}

export default SamplerForm;
