import { Link } from "react-router-dom";
import { Header, Image } from "semantic-ui-react";
import "./BadLink.css";

export const BadLink = (props: { authenticated: boolean }) => {
    return (
        <div className={"bad-link"}>
            <Image src={"/components/bad-link.svg"} role={"presentation"} centered />
            <Header as="h2">Oops!</Header>
            <Header as="p" size={"medium"}>
                This page doesn't exist.
            </Header>
            {!props.authenticated && (
                <p>
                    Take me to <Link to={"/auth/signin"}>Sign In</Link>
                </p>
            )}
        </div>
    );
};
