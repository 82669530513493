import React, { useCallback, useEffect } from "react";
import ReactFlow, {
    Connection,
    ConnectionLineType,
    ControlButton,
    Controls,
    Edge,
    Node,
    Position,
    addEdge,
    isNode,
    useEdgesState,
    useNodesState,
} from "react-flow-renderer";

// @ts-ignore
import dagre from "dagre";

import { Icon } from "semantic-ui-react";

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const getLayoutedElements = (nodes: Node[], edges: Edge[], direction = "LR") => {
    const isHorizontal = direction === "LR";
    let nodeWidth = isHorizontal ? 160 : 120;
    let nodeHeight = isHorizontal ? 120 : 140;

    dagreGraph.setGraph({ rankdir: direction });

    nodes.forEach((node) => dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight }));
    edges.forEach((edge) => dagreGraph.setEdge(edge.source, edge.target));
    dagre.layout(dagreGraph);

    nodes.forEach((node) => {
        if (isNode(node)) {
            const nodeWithPosition = dagreGraph.node(node.id);
            node.targetPosition = isHorizontal ? Position.Left : Position.Top;
            node.sourcePosition = isHorizontal ? Position.Right : Position.Bottom;

            node.position = {
                x: nodeWithPosition.x - nodeWidth / 2,
                y: nodeWithPosition.y - nodeHeight / 2,
            };
            return node;
        }
    });
    return { nodes, edges };
};

function ModelLineageFlow(props: {
    rootID: string;
    nodes: ModelLineageNode[];
    edges: ModelLineageEdge[];
    setModelDiffs: React.Dispatch<React.SetStateAction<(ModelDiffNode | null)[]>>;
    setTabIndex: (x: number) => void;
    repoID: number;
}) {
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    useEffect(() => {
        const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(props.nodes, props.edges);
        setNodes(layoutedNodes);
        setEdges(layoutedEdges);
    }, [props.nodes, props.edges]);

    const onLayout = useCallback(
        (direction: string) => {
            const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(nodes, edges, direction);

            setNodes([...layoutedNodes]);
            setEdges([...layoutedEdges]);
        },
        [nodes, edges],
    );

    const onConnect = useCallback(
        (params: Edge | Connection) =>
            setEdges((eds) => addEdge({ ...params, type: "smoothstep", animated: true }, eds)),
        [],
    );

    return (
        <React.Fragment>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                connectionLineType={ConnectionLineType.SmoothStep}
                zoomOnScroll={false}
                preventScrolling={false}
                minZoom={0.1}
                fitView
            >
                <Controls showInteractive={false}>
                    <ControlButton onClick={() => onLayout("TB")}>
                        <Icon name={"resize vertical"} />
                    </ControlButton>
                    <ControlButton onClick={() => onLayout("LR")}>
                        <Icon name={"resize horizontal"} />
                    </ControlButton>
                </Controls>
            </ReactFlow>
        </React.Fragment>
    );
}

export default ModelLineageFlow;
