import { JSONSchema7, JSONSchema7TypeName } from "json-schema";
import _ from "lodash";

import { createAJVValidator } from "../../../utils/ajv";
import { snakeToTitle } from "../../../utils/config";

export const syncStates = (localState: any, config?: CreateModelConfig, path?: string) => {
    for (var property in localState) {
        if (localState.hasOwnProperty(property)) {
            const propertyPath = path && path.length > 0 ? `${path}.${property}` : property;
            if (typeof localState[property] === "object") {
                syncStates(localState[property], config, propertyPath);
            } else {
                if (!_.has(config, propertyPath)) delete localState[property];
            }
        }
    }
};

export const getAlternativeTitle = (path?: string) => {
    if (!path) return "";
    if (!path.includes(".")) return path;

    const splitPath = path.split(".");
    return snakeToTitle(splitPath[splitPath.length - 1]);
};

// TODO: Add custom attribute to schema indicating that a field
// is not supported in the UI.
export const hiddenNames = [
    "norm_params",
    "vocab",
    "computed_fill_value",
    "dependencies",
    "fc_layers",
    "conv_layers",
    "tied",
];

export const getFieldType = (type?: JSONSchema7TypeName | JSONSchema7TypeName[]) => {
    if (Array.isArray(type)) {
        const fieldType = type.filter((type: string) => type !== "null" && type !== "object");
        return fieldType[0];
    }

    return type;
};

export const getErrorPath = (path: string, config: any) => {
    let translatedParameterPath: string | null = path;

    if (translatedParameterPath.includes("_features[")) {
        // Use regex to match where the array slicing appears in the name:
        const regexMatchForFeature = /features\[[0-9]+\]/.exec(path) as RegExpExecArray;
        const indexOfMatch = regexMatchForFeature.index;
        const indexAfterMatch = indexOfMatch + regexMatchForFeature[0].length;

        // Grab the feature's name
        const feature_name = (_.get(config, path.slice(0, indexAfterMatch)) as any)?.name;

        // Create a new path to the parameter with the array slice replaced with the feature name:
        const indexOfArraySlice = indexOfMatch + "features".length;
        translatedParameterPath = `${path.slice(0, indexOfArraySlice)}.${feature_name}${path.slice(indexAfterMatch)}`;
    }

    return translatedParameterPath;
};

export const getLudwigPath = (path: string, featureIndex?: number) => {
    if (typeof path !== "string") return "";

    // For config options outside of the input and output
    // features; we can use the path as is.
    if (!path.includes("_features") || featureIndex === undefined) return path;

    // For input and output features, we need to add in the
    // the index of the item in the path.
    const index = path.indexOf("_features");
    const offset = "_features".length;
    return `${path.substring(0, index + offset)}[${featureIndex}]${path.substring(index + offset)}`;
};

export const genericOfSchemaPath = (selector: string, schemaPath: string, index: number) =>
    `${schemaPath}.${selector}[${index}]`;

export const getMatchingSchemaForValue = (ofSchemas: JSONSchema7[], value: any) => {
    const ajv = createAJVValidator();
    for (let i = 0; i < ofSchemas.length; i++) {
        if (ajv.validate(ofSchemas[i], value)) {
            return ofSchemas[i];
        }
    }

    return undefined;
};
