import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Icon, Menu } from "semantic-ui-react";
import { tier } from "./api_generated/models/tier";
import { track } from "./metrics/june";
import metrics from "./metrics/metrics";
import { USER_STATE } from "./state/global";
import { getDocsHome } from "./utils/api";
import { PREDIBASE_ORANGE } from "./utils/colors";
import { isKratosUserContext } from "./utils/kratos";

const MainMenu = (props: { activeItem: string }) => {
    const [user] = useRecoilState(USER_STATE);
    const isViewerAdmin = user?.isSystemUser;
    let subscriptionTier;
    let shortCode;
    if (user) {
        const isKratosContext = isKratosUserContext(user);
        subscriptionTier = isKratosContext ? user.tenant.subscription.tier : user?.tenant.tier;
        shortCode = isKratosContext ? user.tenant.shortCode : user.tenant.shortcode;
    }
    const isVPCAccount = subscriptionTier === tier.ENTERPRISE_VPC;

    return (
        <Menu
            style={{ position: "sticky", top: "0px", height: "100vh", borderRadius: 0 }}
            icon="labeled"
            vertical
            fluid
            inverted
        >
            <Menu.Item name="logo" style={{ paddingTop: "1.14285714em", paddingBottom: "1.14285714em" }}>
                {/* Slight hack to treat svg as icon for consistency, since SUI icons cannot be scaled during browser resize */}
                <i className="predibase-icon icon noMinWidth">
                    <a
                        onClick={() => {
                            user &&
                                track(user, "docs", { url: getDocsHome(), clickSource: "main-menu-predibase-logo" });
                        }}
                        href={getDocsHome()}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={"/logos/predibase/predibase.svg"} alt={""} width={35} height={35} />
                    </a>
                </i>
            </Menu.Item>

            <Link to="/" onClick={() => metrics.capture("Source.Navigate", { method: "sidebar" })}>
                <Menu.Item className={"noMinWidth"} name="home" active={props.activeItem === "home"}>
                    <Icon name="home" style={{ color: props.activeItem === "home" ? PREDIBASE_ORANGE : undefined }} />
                    Home
                </Menu.Item>
            </Link>

            <Link to="/prompt" onClick={() => metrics.capture("Prompt.Navigate", { method: "sidebar" })}>
                <Menu.Item className={"noMinWidth"} name="prompt" active={props.activeItem === "prompt"}>
                    <Icon
                        name="terminal"
                        style={{ color: props.activeItem === "prompt" ? PREDIBASE_ORANGE : undefined }}
                    />
                    Prompt
                </Menu.Item>
            </Link>

            <Link to="/data" onClick={() => metrics.capture("Source.Navigate", { method: "sidebar" })}>
                <Menu.Item className={"noMinWidth"} name="data" active={props.activeItem === "data"}>
                    <Icon
                        name="database"
                        style={{ color: props.activeItem === "data" ? PREDIBASE_ORANGE : undefined }}
                    />
                    Data
                </Menu.Item>
            </Link>
            {/* Models page is only support for one legacy client. */}
            {isVPCAccount && shortCode === "bb7077" && (
                <Link to="/models" onClick={() => metrics.capture("Model.Navigate", { method: "sidebar" })}>
                    <Menu.Item className={"noMinWidth"} name="models" active={props.activeItem === "models"}>
                        <Icon
                            name="sitemap"
                            style={{ color: props.activeItem === "models" ? PREDIBASE_ORANGE : undefined }}
                        />
                        Models
                    </Menu.Item>
                </Link>
            )}

            {/* // TODO: These metrics make no sense now: */}
            <Link to="/adapters" onClick={() => metrics.capture("Model.Navigate", { method: "sidebar" })}>
                <Menu.Item className={"noMinWidth"} name="adapters" active={props.activeItem === "adapters"}>
                    {/* TODO: Standardize styles for icons (switching between semantic and font-awesome icons which are in
                    fact, slightly different sets) */}
                    <FontAwesomeIcon
                        icon="layer-group"
                        style={{
                            height: "2rem",
                            color: props.activeItem === "adapters" ? PREDIBASE_ORANGE : undefined,
                        }}
                    />
                    Adapters
                </Menu.Item>
            </Link>

            <Link to="/deployments" onClick={() => metrics.capture("Deployments.Navigate", { method: "sidebar" })}>
                <Menu.Item className={"noMinWidth"} name="deployments" active={props.activeItem === "deployments"}>
                    <Icon
                        name="rocket"
                        style={{ color: props.activeItem === "deployments" ? PREDIBASE_ORANGE : undefined }}
                    />
                    Deploy
                </Menu.Item>
            </Link>

            {isViewerAdmin && (
                <Link to="/engines" onClick={() => metrics.capture("Engines.Navigate", { method: "sidebar" })}>
                    <Menu.Item className={"noMinWidth"} name="engines" active={props.activeItem === "engines"}>
                        <Icon
                            name="computer"
                            style={{ color: props.activeItem === "engines" ? PREDIBASE_ORANGE : undefined }}
                        />
                        Engines
                    </Menu.Item>
                </Link>
            )}

            <Link to="/settings" onClick={() => metrics.capture("Settings.Navigate", { method: "sidebar" })}>
                <Menu.Item className={"noMinWidth"} name="settings" active={props.activeItem === "settings"}>
                    <Icon
                        name="settings"
                        style={{ color: props.activeItem === "settings" ? PREDIBASE_ORANGE : undefined }}
                    />
                    Settings
                </Menu.Item>
            </Link>

            <div style={{ width: "100%", position: "absolute", bottom: "1.14285714em" }}>
                <Link
                    onClick={() => {
                        user && track(user, "docs", { url: getDocsHome(), clickSource: "main-menu-book-icon" });
                    }}
                    to={getDocsHome()}
                    target="_blank"
                    rel="noreferrer"
                >
                    <Menu.Item name="docs" className={"noMinWidth"}>
                        <Icon name="book" />
                        Docs
                    </Menu.Item>
                </Link>
            </div>
        </Menu>
    );
};

export default MainMenu;
