import _ from "lodash";
import { memo, useContext } from "react";
import { useRecoilState } from "recoil";
import { Form, Header, Label, Table } from "semantic-ui-react";

import metrics from "../../../metrics/metrics";
import { USER_STATE } from "../../../state/global";
import { FeatureFlagsContext } from "../../../utils/feature-flags";
import { formatValueToNumericString } from "../../../utils/numbers";
import { getComputeSubscriptionStatus } from "../../../utils/subscription";
import { getModelType } from "../../util";
import { FeaturesDocsMessage } from "../CreateModelDocs";
import { useConfigState, useDispatch } from "../store";
import FieldCheckbox from "./FieldCheckbox";
import FieldDropdown from "./FieldDropdown";

/**
 * Transform a number to a formatted percentage.
 * @param value The value to be calcuated.
 *
 * @returns String of the calculated value with a % operator.
 */
const percentage = (value: any) => {
    if (!_.isNumber(value)) {
        return null;
    }

    let percent = formatValueToNumericString(value * 100, 0);
    if (percent === "0") {
        percent = "<1";
    }
    return percent + "%";
};

const FieldsTable = (props: { loading?: boolean }) => {
    const dispatch = useDispatch();
    const { config, fields } = useConfigState();
    const [userContext] = useRecoilState(USER_STATE);
    const { featureFlags } = useContext(FeatureFlagsContext);

    const setFieldInfo = (field: CreateModelField, fieldIndex: number) => {
        dispatch({ type: "UPDATE_FIELD", index: fieldIndex, value: field });
    };

    const toggleEnabled = (sourceField: CreateModelField, fieldIndex: number, checked: boolean) => {
        let field = { ...sourceField };
        field.excluded = !checked;

        // We only allow enabled features to be targets now:
        if (field.mode === "output" && field.excluded) {
            field.mode = "input";
        }
        setFieldInfo(field, fieldIndex);
    };

    const toggleAll = (checked: boolean) => {
        dispatch({ type: "UPDATE_FIELDS", checked });
    };

    const setType = (sourceField: CreateModelField, fieldIndex: number, value: string) => {
        let field = { ...sourceField };
        let config = { ...field.config };
        config.type = value;
        field.config = config;
        setFieldInfo(field, fieldIndex);
    };

    if (!fields || props.loading) {
        return null;
    }

    return (
        <div>
            <FeaturesDocsMessage />
            <Table striped>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Column Name</Table.HeaderCell>
                        <Table.HeaderCell>Data Type</Table.HeaderCell>
                        <Table.HeaderCell>Missing Values</Table.HeaderCell>
                        <Table.HeaderCell style={{ display: "flex", columnGap: "3px" }}>
                            Active
                            <Form.Checkbox
                                className={metrics.BLOCK_AUTO_CAPTURE}
                                toggle
                                name="enabled"
                                onClick={(event, data) => {
                                    toggleAll(data.checked as boolean);
                                    metrics.captureToggle("Model.Config.Features.Active", {
                                        value: data.checked,
                                    });
                                }}
                                defaultChecked={fields
                                    .map((check: { excluded: boolean }) => !check.excluded)
                                    .every((check: boolean) => check === true)}
                            />
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {fields.map((field, fieldIndex) => {
                        return (
                            <Table.Row key={fieldIndex}>
                                <Table.Cell>
                                    {field.mode === "output" ? (
                                        <Label ribbon color="blue">
                                            {field.name}
                                        </Label>
                                    ) : (
                                        <Header as="h4" disabled={field.excluded}>
                                            {field.name}
                                        </Header>
                                    )}
                                </Table.Cell>
                                <Table.Cell>
                                    {field.mode !== "split" ? (
                                        <FieldDropdown
                                            field={field}
                                            fieldIndex={fieldIndex}
                                            modelType={getModelType(config)}
                                            isComputeLimited={getComputeSubscriptionStatus(
                                                userContext?.isComputeLimited,
                                                featureFlags["Free Trial Testing"],
                                            )}
                                            isFeatureLimited={userContext?.isFeatureLimited}
                                            setType={setType}
                                        />
                                    ) : null}
                                </Table.Cell>
                                <Table.Cell>{percentage(field.missingValues)}</Table.Cell>
                                <Table.Cell>
                                    <FieldCheckbox
                                        field={field}
                                        fieldIndex={fieldIndex}
                                        toggleEnabled={toggleEnabled}
                                        modelType={getModelType(config)}
                                        isComputeLimited={getComputeSubscriptionStatus(
                                            userContext?.isComputeLimited,
                                            featureFlags["Free Trial Testing"],
                                        )}
                                        isFeatureLimited={userContext?.isFeatureLimited}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
        </div>
    );
};

export default memo(FieldsTable);
