import { AppState as Auth0AppState } from "@auth0/auth0-react";

// TODO: What are the known pieces of state we may track through redirects?
export interface PredibaseURLState extends Auth0AppState {
    returnTo?: string;
    inviteToken?: string;
    tenantName?: string;
}

export const welcomeUrl = "/auth/welcome";
