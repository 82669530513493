import { UiNode, UiText } from "@ory/kratos-client";
import { UiNodeInputAttributes } from "@ory/kratos-client/api";
import { AxiosError } from "axios";
import React, { useState } from "react";
import { Button, Divider, Form, Message, Segment } from "semantic-ui-react";
import metrics from "../../../metrics/metrics";
import { FORM_TYPE_LINK } from "../constants";
import { KratosErrorMessage, renderFormFields } from "./KratosFormUtils";

const KratosRecoveryForm = (props: {
    action: string;
    messages?: UiText[];
    fields: UiNode[];
    submitLabel: string;
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void | Promise<void | AxiosError<unknown, any>>;
}) => {
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [formError, setFormError] = useState<string>("");

    const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        setSubmitting(true);
        props
            .onSubmit(e)
            ?.catch((error) => {
                setFormError(error?.message || "Looks like something went wrong. Try again later.");
            })
            ?.finally(() => setSubmitting(false));
    };

    const submitType = "Recover";
    return (
        <>
            <KratosErrorMessage messages={props.messages} />
            {formError && <Message error>{formError}</Message>}
            <Form onSubmit={onFormSubmit} size="large">
                <Segment>
                    {renderFormFields(
                        props.fields.filter((field) => (field.attributes as UiNodeInputAttributes)?.type !== "submit"),
                    )}
                    <Button
                        loading={submitting}
                        disabled={submitting}
                        className={metrics.BLOCK_AUTO_CAPTURE}
                        onClick={() => metrics.captureClick("Auth." + submitType)}
                        color="grey"
                        fluid
                        size="large"
                        name="method"
                        type="submit"
                        value={FORM_TYPE_LINK}
                    >
                        {props.submitLabel}
                    </Button>
                </Segment>
            </Form>
            <Divider />
        </>
    );
};

export default KratosRecoveryForm;
