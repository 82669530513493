// @ts-nocheck -- This file is so fucked up
import _get from "lodash/get";
import { Bar, BarChart, CartesianGrid, Legend, ReferenceLine, Tooltip, XAxis, YAxis } from "recharts";
import { Message } from "semantic-ui-react";
import { formatValueToNumericString } from "../../../utils/numbers";
import { truncateString } from "../../../utils/overflow";

const lowerColor = "#ff0d57";
const higherColor = "#1e88e5";

const SummaryTooltip = ({ active, payload, label }) => {
    if (!payload) return null;
    if (!active) return null;

    const options = {
        tooltip: {
            style: {
                padding: "10px 10px",
                minWidth: "150px",
                backgroundColor: "white",
                borderRadius: 10,
                boxShadow: "0 10px 30px 0 rgba(151,167,183,0.3)",
                textTransform: "capitalize",
                fontSize: 13,
            },
            styleLabel: {
                // To style the label
            },
            styleContent: {
                display: "flex",
                justifyContent: "space-between",
            },
            styleName: {
                // To style key of the tooltip
            },
            styleValue: {
                fontWeight: 600,
            },
        },
    };

    const effect = payload[0].value;
    const color = payload[0].fill;
    const value = payload[0].payload.value;

    return (
        <div className="tooltip" style={_get(options, "tooltip.style")}>
            <p className="label">
                <b>{`${label}`}</b>
            </p>
            <p className="intro" style={{ color: color }}>{`effect: ${formatValueToNumericString(effect, 5)}`}</p>
            {value !== undefined ? <p className="intro" style={{ color: "black" }}>{`value: ${value}`}</p> : null}
        </div>
    );
};

function SummaryChart(props) {
    const sums = {};
    const counts = {};
    const values = {};
    if (props.explanations === undefined || props.explanations.length === 0) {
        return <Message negative>There are no rows in query results</Message>;
    }
    for (let i = 0; i < props.explanations.length; i++) {
        const features = props.explanations[i].features;
        for (const feature of Object.values(features)) {
            if (sums.hasOwnProperty(feature.name)) {
                sums[feature.name] += props.useAbs ? Math.abs(feature.effect) : feature.effect;
                counts[feature.name] += 1;
            } else {
                sums[feature.name] = props.useAbs ? Math.abs(feature.effect) : feature.effect;
                counts[feature.name] = 1;
                if (!props.useAbs) {
                    values[feature.name] = feature.value;
                }
            }
        }
    }

    let data = Object.entries(sums).map(([name, sum]) => {
        const value = sum / counts[name];
        let key = "effect";
        if (!props.useAbs) {
            key = value < 0 ? "lower" : "higher";
        }
        return { name: name, effect: value, [key]: value, value: values[name] };
    });
    data = data.sort((a, b) => Math.abs(b.effect) - Math.abs(a.effect));

    const barHeight = props.barHeight ? props.barHeight : 35;
    const height = data.length === 1 ? barHeight * 2 + barHeight : data.length * barHeight + barHeight;

    return (
        <BarChart
            width={props.width ? props.width : 900}
            height={height}
            data={data}
            layout="vertical"
            barSize={15}
            margin={{
                top: 5,
                right: 30,
                left: 50,
                bottom: 5,
            }}
        >
            <CartesianGrid strokeDasharray="3 3" horizontal={false} />
            <XAxis type="number" />
            <YAxis dataKey="name" type="category" tickFormatter={(x) => truncateString(x, 15)} />
            <Tooltip content={<SummaryTooltip />} />
            <Legend />
            {!props.useAbs ? <ReferenceLine x={0} stroke="#000" /> : null}
            {!props.useAbs ? <Bar dataKey="higher" stackId="a" fill={higherColor} /> : null}
            {!props.useAbs ? <Bar dataKey="lower" stackId="a" fill={lowerColor} /> : null}
            {props.useAbs ? <Bar dataKey="effect" stackId="a" fill={higherColor} /> : null}
        </BarChart>
    );
}

export default SummaryChart;
