import { useMatch } from "react-router-dom";
import CreateModelView from "./create/CreateModelView/CreateModelView";

const EditModelView = () => {
    const match = useMatch("/models/edit/:editType/:versionOrRepo/:id");
    const modelID = match?.params.id ? parseInt(match.params.id) : undefined;
    const editType = match?.params.editType;
    const isFromVersion = match!.params.versionOrRepo === "version";

    return (
        <CreateModelView
            modelID={isFromVersion ? modelID : undefined}
            modelRepoID={!isFromVersion ? modelID : undefined}
            editType={editType}
        />
    );
};

export default EditModelView;
