import type { JSONSchema7 } from "json-schema";
import _ from "lodash";
import { useState } from "react";
import { Accordion, AccordionTitleProps, Icon } from "semantic-ui-react";
import { CurrentUser, UserContext } from "../../../../types/user";
import { Field, ObjectField } from "../fields";
import { getFieldType } from "../utils";
import { AnyOf } from "./AnyOf";
import { OneOf } from "./OneOf";

const AdvancedParameters = (props: { elements: JSX.Element[]; length: number }) => {
    const [active, setActive] = useState(false);
    const handleAccordionClick = (event: React.MouseEvent<HTMLDivElement>, data: AccordionTitleProps) => {
        setActive((active) => !active);
    };
    return (
        <Accordion className="advancedParams">
            <Accordion.Title active={active} onClick={handleAccordionClick}>
                <Icon name={active ? "chevron down" : "chevron right"} />
                {`Show ${props.length} Advanced Parameter${props.length > 1 ? "s" : ""}`}
            </Accordion.Title>
            <Accordion.Content active={active}>{props.elements}</Accordion.Content>
        </Accordion>
    );
};

export const Properties = (
    schema: JSONSchema7,
    config: any,
    setConfig: (parameterPath: string, parameterValue: any) => void,
    localState: any,
    setLocalState: (parameterPath: string, parameterValue: any) => void,
    invalidFields: any,
    level: number,
    path: string,
    schemaPath: string,
    featureIndex?: number,
    readOnly?: boolean,
    userContext?: UserContext | CurrentUser,
) => {
    const elements: JSX.Element[] = [];
    let advancedParams: JSX.Element[] = [];

    /**
     * Adds advanced parameters into an accordion, pushes accordion to list of elements to be rendered, then clears out
     * list of advanced params.
     */
    const pushAdvancedParams = () => {
        elements.push(<AdvancedParameters elements={[...advancedParams]} length={advancedParams.length} />);

        advancedParams = [];
    };

    /**
     * Adds a field to the list of elements to be rendered. If the field is an advanced parameter, it is added to the
     * list of advanced parameters. If the field is not an advanced parameter, the list of advanced parameters is pushed
     * as an accordion dropdown to the list of elements to be rendered, and the field is added to the list of elements
     * to be rendered.
     * @param advanced: boolean - whether the field is an advanced parameter
     * @param field: any - the field to be added to the list of elements to be rendered
     */
    const addFields = (advanced: Boolean, field: any) => {
        // Add advanced parameter to accordion
        if (advanced) {
            advancedParams.push(field);
            return;
        }

        // This element is not advanced.
        // Step 1. Render the accordion and reset the array:
        if (advancedParams.length > 0) {
            pushAdvancedParams();
        }

        // Step 2. Render this field:
        elements.push(field);
        return;
    };

    if (!schema || !schema.properties) {
        // @todo: should this be null?
        return elements;
    }

    const localSchemaPath = `${schemaPath}.properties`;
    const localLevel = level + 1;

    Object.keys(schema.properties).forEach((propertyName: string) => {
        const propertySchema = schema.properties ? (schema.properties[propertyName] as JSONSchema7) : {};
        const propertyPath = `${path}.${propertyName}`;
        const propertySchemaPath = `${localSchemaPath}.${propertyName}`;
        // @ts-expect-error(2339) yes, parameter_metadata does exist on the schema
        const advanced = propertySchema?.parameter_metadata?.expected_impact === 2;

        // A Schema must either have properties (aka fields) or a grouped
        // list of fields to render (aka oneOf):
        if (_.has(propertySchema, "oneOf")) {
            const defaultValue =
                typeof propertySchema.default !== "undefined" ? String(propertySchema.default) : undefined;

            const field = (
                <OneOf
                    description={propertySchema?.description}
                    defaultValue={defaultValue}
                    schema={propertySchema}
                    config={config}
                    setConfig={setConfig}
                    localState={localState}
                    setLocalState={setLocalState}
                    invalidFields={invalidFields}
                    featureIndex={featureIndex}
                    level={localLevel}
                    path={propertyPath}
                    schemaPath={`${propertySchemaPath}`}
                    readOnly={readOnly}
                    userContext={userContext}
                />
            );

            addFields(advanced, field);
            return;
        }

        if (_.has(propertySchema, "anyOf")) {
            const defaultValue =
                typeof propertySchema.default !== "undefined" ? String(propertySchema.default) : undefined;

            const field = (
                <AnyOf
                    description={propertySchema?.description}
                    defaultValue={defaultValue}
                    schema={propertySchema}
                    config={config}
                    setConfig={setConfig}
                    localState={localState}
                    setLocalState={setLocalState}
                    invalidFields={invalidFields}
                    featureIndex={featureIndex}
                    level={localLevel}
                    path={propertyPath}
                    schemaPath={`${propertySchemaPath}`}
                    readOnly={readOnly}
                    userContext={userContext}
                />
            );

            addFields(advanced, field);
            return;
        }

        // Type is an optional property for a JSONSchema7 object. Nothing to render if we don't have a type.
        if (!_.has(propertySchema, "type")) {
            return;
        }

        let fieldType = getFieldType(propertySchema.type);
        if (fieldType === "object") {
            const field = (
                <ObjectField
                    schema={propertySchema}
                    config={config}
                    setConfig={setConfig}
                    localState={localState}
                    setLocalState={setLocalState}
                    invalidFields={invalidFields}
                    featureIndex={featureIndex}
                    level={localLevel}
                    path={propertyPath}
                    schemaPath={propertySchemaPath}
                    readOnly={readOnly}
                    userContext={userContext}
                />
            );

            addFields(advanced, field);
            return;
        }

        const field = (
            <Field
                fieldType={fieldType}
                schema={propertySchema}
                config={config}
                setConfig={setConfig}
                localState={localState}
                setLocalState={setLocalState}
                invalidFields={invalidFields}
                featureIndex={featureIndex}
                path={propertyPath}
                schemaPath={propertySchemaPath}
                readOnly={readOnly}
                userContext={userContext}
            />
        );
        addFields(advanced, field);
    });

    // If there are any advanced parameters left, render them now:
    if (advancedParams.length > 0) {
        pushAdvancedParams();
    }

    return elements;
};
