import { CSSProperties } from "react";
import Ellipsis from "react-ellipsis-component";
import { Popup, StrictPopupProps } from "semantic-ui-react";
import TruncatedText from "../components/TruncatedText";

export const cssTruncate: CSSProperties = {
    display: "inline-block",
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
};

export const truncateStringOnly = (text: any, limit: number) => {
    if (text === null || text === undefined) {
        return null;
    }

    if (text.length > limit) {
        return text.substring(0, limit - 3);
    }
    return text;
};

export const truncateString = (text: any, limit: number) => {
    if (text === null || text === undefined) {
        return null;
    }

    if (text.length > limit) {
        return text.substring(0, limit - 3) + "...";
    }
    return text;
};

export const truncateStringWithPopup = (
    text: any,
    limit: number,
    position: StrictPopupProps["position"] = "top center",
) => {
    if (text === null || text === undefined) {
        return null;
    }

    if (text.length > limit) {
        const first = text.substring(0, limit - 3);
        // @ts-ignore
        return (
            <Popup className="transition-scale" position={position} trigger={<span>{first}...</span>} content={text} />
        );
    }
    return text;
};

export const getDropdownOverflowSelection = (text: any) => {
    if (text === null || text === undefined) {
        return undefined;
    }

    return (
        <div style={{ width: "80%" }}>
            <Ellipsis
                ellipsis
                text={text}
                maxLine={1}
                ellipsisNode={
                    <Popup
                        className="transition-scale"
                        position={"right center"}
                        trigger={<span>...</span>}
                        content={text}
                    />
                }
            />
        </div>
    );
};

export const getDropdownOverflowItem = (text: any) => {
    if (text === null || text === undefined) {
        return null;
    }

    return (
        <div style={{ width: "90%", height: "100%", whiteSpace: "nowrap" }}>
            <TruncatedText text={text} />
        </div>
    );
};

// TODO: These all need to be components, not functions:
export const getOverflowItem = (text: any, current = false, width = 80, display?: string) => {
    if (text === null || text === undefined) {
        return null;
    }

    // Because current selected item is bolded in the dropdown options, we need to subtract width so that
    // it doesn't overflow, as this truncation library doesn't resize on JSX, only text.
    const widthStr = current ? width - 10 + "%" : width + "%";

    return (
        <div style={{ width: widthStr, height: "100%", whiteSpace: "nowrap", display: display }}>
            <TruncatedText text={text} />
        </div>
    );
};

export const getEngineSelectorDropdownSelection = (text: any) => {
    return getOverflowItem(text, false, 80, "inline-block");
};

export const getEngineSelectorDropdownItem = (text: any, selected = false) => {
    return getOverflowItem(text, selected, 70, "inline-block");
};
