import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import { ReactNode } from "react";
import { Message } from "semantic-ui-react";

export const ErrorBoundaryMessage = () => {
    return (
        <div style={{ padding: "10px" }}>
            <Message
                icon="paper plane"
                header="It looks like something went wrong."
                content="We're not sure what happened, but we just logged a bug and will look into it shortly."
            />
        </div>
    );
};

const ErrorBoundary = (props: { children: ReactNode }) => {
    return <SentryErrorBoundary fallback={<ErrorBoundaryMessage />}>{props.children}</SentryErrorBoundary>;
};

export default ErrorBoundary;
