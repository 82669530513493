import type { JSONSchema7 } from "json-schema";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { Form } from "semantic-ui-react";
import { USER_STATE } from "../../../state/global";
import { CurrentUser, UserContext } from "../../../types/user";
import { ObjectField } from "./fields";
import { syncStates } from "./utils";

const DyanmicSchemaDrivenForm = (props: {
    config: any;
    setConfig: (parameterPath: string, parameterValue: any) => void;
    localState: any;
    setLocalState: (parameterPath: string, parameterValue: any) => void;
    invalidFields: any;
    schema?: JSONSchema7;
    schemaPathPrefix: string;
    featureIndex?: number;
    readOnly?: boolean;
    userContext?: UserContext | CurrentUser;
}) => {
    const { schema } = props;
    // The top level of a JSONSchema7 must be a type of Object with properties:
    if (!_.has(schema, "properties") || typeof schema?.properties === "undefined") {
        return null;
    }

    return (
        <Form className="predibase-form">
            <ObjectField
                schema={props.schema ?? {}}
                config={props.config ?? {}}
                setConfig={props.setConfig}
                localState={props.localState}
                setLocalState={props.setLocalState}
                invalidFields={props.invalidFields ?? {}}
                featureIndex={props.featureIndex}
                level={1}
                path=""
                schemaPath={props.schemaPathPrefix}
                readOnly={props.readOnly}
                userContext={props.userContext}
            />
        </Form>
    );
};

export const JSONSchemaForm = (props: {
    data: any;
    setConfig: (parameterPath: string, parameterValue: any) => void;
    invalidFields: any;
    schema?: JSONSchema7;
    schemaPathPrefix: string;
    featureIndex?: number;
    readOnly?: boolean;
}) => {
    const [localState, setLocalState] = useState({});
    const [userContext] = useRecoilState(USER_STATE);
    // React does not run the useEffect when props.data changes.
    // Creating a piece of state to represent that change.
    const [localStateChanged, setLocalStateChanged] = useState(Math.random());

    useEffect(() => {
        // When we change types (Combiner, Features, Trainer, etc)
        // we (usually) remove options that have been set previously
        // so that the user does not get into an error state. For example,
        // if a user changes their encoder from embed to passthrough
        // fc_layers is no longer parameter to set.
        const newLocalState = _.cloneDeep(localState);
        syncStates(newLocalState, props.data);
        setLocalState(newLocalState);
        // Only update the effect when local state is changed. Including localState as a dependency causes an infinite loop of re-rendering.
    }, [localStateChanged]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <DyanmicSchemaDrivenForm
            config={props.data}
            setConfig={props.setConfig}
            localState={localState}
            setLocalState={(path: string, value: string) => {
                setLocalState(_.set(localState, path, value));
                setLocalStateChanged(Math.random());
            }}
            invalidFields={props.invalidFields}
            schema={props.schema}
            schemaPathPrefix={props.schemaPathPrefix}
            featureIndex={props.featureIndex}
            readOnly={props.readOnly}
            userContext={userContext}
        />
    );
};
